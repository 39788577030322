(function() {
    'use strict';

    selectStatusFrpController.$inject = ['ipmConstants'];

    function selectStatusFrpController(constants) {
        var vm = this; //jshint ignore:line
        vm.frpStatus = constants.FRP_STATUS;
        init();

        function init() {
        }

        vm.changeStatusFrpSelected = function () {
            vm.onChangeStatus({data: vm.patientFrp});
        }

    }

    angular.module('ipmApp').component('selectStatusFrp', {
        bindings: {
            isView: '<',
            patientFrp: '=',
            onChangeStatus: '&'
        },
        templateUrl: 'app/frp-mde/tab/select-status-frp/select-status-frp.html',
        controller: selectStatusFrpController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
