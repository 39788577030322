(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportHomeController', MDEReportHomeController);

    MDEReportHomeController.$inject = ['$scope', '$state', '$q', 'Principal', 'ipmConstants', 'MdeReportService', '$stateParams'];

    function MDEReportHomeController($scope, $state, $q, Principal, ipmConstants, MdeReportService, $stateParams) {
        var vm = this;
        vm.mdeReport = {};
        vm.patient = {};
        vm.reports = [];
        vm.isView = false;
        vm.checkCompletedAllReport = checkCompletedAllReport;
        vm.dataHeaderReport = {};

        init();

        function init() {
        	MdeReportService.get($stateParams.id).then(function(response) {
                vm.mdeReport = response.data ? response.data : {};
                setIsView();
                vm.patient = response.data.patient;
                vm.patient.dateOfBirth =  moment(vm.patient.dateOfBirth).format('MM/DD/YYYY');
                vm.reports =  response.data.reports;
                vm.dataHeaderReport = buildDataHeaderReport(vm.mdeReport.patientFRPMDE);
            });
        }

        function setIsView() {
            vm.isView = vm.mdeReport && ((vm.mdeReport.statusFinal && vm.mdeReport.statusFinal === 'FINALIZED') || vm.mdeReport.status === 'MDE_ABANDONED' || (vm.mdeReport.patientFRPMDE && vm.mdeReport.patientFRPMDE.isViewFrpProgram)) ? true : false;
        }

        function checkCompletedAllReport() {
            if (vm.mdeReport && vm.mdeReport.reports && vm.mdeReport.reports.length > 0) {
                return _.isEmpty(_.filter(vm.mdeReport.reports, function (item) {
                    return item.status !== 'COMPLETE' && item.role !== 'MEDICAL' && item.role !== 'ADDENDUM';
                }));
            }
            return false;
        }

        vm.editPage = function(name){
            if(name)
                $state.go('mde-report.' + name);
        }

        vm.viewPage = function (name) {
            if (name) {
                $state.go('mde-report.' + name + '.VIEW');
                return;
            }
        }

        vm.finalAndSignOffClick = function () {
            if (checkCompletedAllReport()) {
                MdeReportService.finalizeMdeReport(vm.mdeReport.id).then(function(response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        vm.preview = function() {
            $state.go('mde-report-preview');
        }
        
        function onSaveSuccess (response) {
            vm.isSaving = false;
            goToMdeTab();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        function goToMdeTab() {
            $state.go('frp-mde', {tab:1});
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    mdeOfficeName: patientFRPMDE.mdeOfficeName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };
    }
})();
