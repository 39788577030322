(function() {
    'use strict';


    PresentationPatientResponseManagementController.$inject = ['$scope', '$state', '$window', 'ipmConstants'];

    function PresentationPatientResponseManagementController($scope, $state, $window, constants) {
        var vm = this; //jshint ignore:line

        vm.accordionStatus = {};
        vm.patientPresentations = [];
        vm.expandAllClick = expandAllClick;
        vm.onChangePatientPresentation = onChangePatientPresentation;
        vm.isExpandAll = true;
        vm.isChangePatientPresentation = false;
        vm.countPP = 0;
        vm.isShowingAbsentPatients = false;
        vm.sortableOptions = {
            items: '.patient-card-drag-area',
            update: function (e, ui) {

            },
            stop: function (e, ui) {
               var patientPresentations = ui.item.sortable.sourceModel;
               //  patientPresentations.forEach(function (presentationPatient, index) {
               //      presentationPatient.orderNumber = (index + 1);
               //  });
               // vm.patientPresentations = patientPresentations;
                emitOnchangeDrapDrop(patientPresentations);
            }
        };
        init();

        function init() {
            vm.isManagerTemplate = vm.isManagerTemplate ? vm.isManagerTemplate : false;
            vm.isSummaryTemplate = vm.isSummaryTemplate ? vm.isSummaryTemplate : false;
            vm.accordionStatus = {};
            vm.countPP = vm.countPP ? vm.countPP : 0;
            vm.isShowResponseOpen = vm.isShowResponseOpen ? vm.isShowResponseOpen : false;
            vm.slides = vm.slides ? vm.slides : [];
            filterPatientPresentations();
            if (vm.patientPresentations.length > 0) {
                setcountPP();
                // default expand all for presentationStatus PRESENT
                if (vm.presentationStatus === 'PRESENT') {
                    expandAllList(vm.isExpandAll);
                }
            }
        }

        function setcountPP() {
            vm.countPP = vm.patientPresentations ? vm.patientPresentations.length : 0;
        }

        function filterPatientPresentations() {
            if (vm.presentationStatus
                && vm.wellnessClass.instructorPatientPresentation
                && vm.wellnessClass.instructorPatientPresentation.patientPresentations) {
                vm.patientPresentations = _.filter(vm.wellnessClass.instructorPatientPresentation.patientPresentations, function (item) {
                    return item.presentationStatus == vm.presentationStatus;
                });
            }
        }
        function expandAllClick() {
            expandAllList(vm.isExpandAll);
        }

        function expandAllList(isExpand) {
            vm.patientPresentations.forEach(function (patientPresentation) {
                vm.accordionStatus[patientPresentation.patient.id] = isExpand;
            });
            vm.isExpandAll = !isExpand;
        }

        function emitOnchangeDrapDrop(patientPresentations) {
            vm.onChange({patientPresentations: patientPresentations});
        }

        function emitOnChangeReload(value) {
            vm.reloadChangeList({value: value});
        }

        function onChangePatientPresentation(patientPresentation) {
            if (patientPresentation && vm.wellnessClass.instructorPatientPresentation && vm.wellnessClass.instructorPatientPresentation.patientPresentations) {
                var indexPP = findIndexPatientPresentations(patientPresentation);
                // update patientPresentations list
                if (indexPP > -1) {
                    vm.wellnessClass.instructorPatientPresentation.patientPresentations[indexPP] = patientPresentation;
                }
            }
        }

        function findIndexPatientPresentations(patientPresentation) {
            return _.findIndex(vm.wellnessClass.instructorPatientPresentation.patientPresentations, function(item) {
                return item && item.id === patientPresentation.id;
            });
        }

        $scope.$watch('vm.isChangePatientPresentation', function (newValue) {
            emitOnChangeReload(true);
        });

        $scope.$watch('vm.isReloadChange', function (newValue) {
            filterPatientPresentations();
            setcountPP();
        });
    }

    angular.module('ipmApp').component('presentationPatientResponseManagement', {
        bindings: {
            wellnessClass: '=',
            deck: '=',
            slide: '=',
            isReloadChange: '<',
            presentationStatus: '<',
            showExpandAll: '<',
            showPatientNotPresentsLabel: '<',
            isDisableDrapDrop: '<',
            isShowResponseOpen: '<',
            isManagerTemplate: '<',
            isSummaryTemplate: '<',
            isSavingDrapChange : '<',
            slides: '<',
            onChange: '&',
            reloadChangeList: '&',
            isShowAttendanceOpen: '<'
        },
        templateUrl: 'app/presentation-controls-instructor/presentation-patient-response/presentation-patient-response-management.html',
        controller: PresentationPatientResponseManagementController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
