(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('ClassSessionService', ClassSessionService);

        ClassSessionService.$inject = ['$resource', '$http', '$q'];

    function ClassSessionService ($resource, $http, $q) {
        var resourceUrl = "api/class-sessions";
        var service = $resource(resourceUrl, {}, {});
        service.get = get;
        service.getAllUpcommingSessionByCourseId = getAllUpcommingSessionByCourseId;
        service.createOrUpdate = createOrUpdate;
        service.deleteSesion = deleteSesion;
        service.doAutoGenerate = doAutoGenerate;

        function get(id) {
            var deferred = $q.defer();
            $http.get(resourceUrl + '/' + id).then(function (response) {
                deferred.resolve(response.data);
            });
            return deferred.promise;
        };

        function getAllUpcommingSessionByCourseId(pageable, courseId, instructorId) {
            return $http.get(resourceUrl, {
                params: {
                    courseId: courseId,
                    instructorId: instructorId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).then(function (response) {
                return response;
            });
        };

        function createOrUpdate(data) {
            return $http.post(resourceUrl, data).then(function (response) {
                return response;
            });
        };

        function deleteSesion(id) {
            return $http.delete(resourceUrl + '/' + id).then(function (response) {
                return response;
            });
        };

        function doAutoGenerate(courseId, days, timeZone) {
            return $http.get(resourceUrl + "/do-auto-generate", {
                params: {
                    courseId: courseId,
                    days: days,
                    clientTimeZone: timeZone
                }
            }).then(function (response) {
                return response;
            });
        };

        return service;
    };
})();
