(function () {
    'use strict';

    TimeSpentWorkerComponent.$inject = ['$scope'];

    function TimeSpentWorkerComponent($scope) {
        var vm = this; //jshint ignore:line
        vm.updateUI = updateUI;
        vm.displayNumber = displayNumber;
        vm.isDisplayHour = isDisplayHour;

        init();

        function init() {
            // calculate
            vm.updateUI(vm.startTime);
        }

        function updateUI(seconds) {
            $scope.hour = vm.displayNumber(parseInt(seconds / 3600));
            if (vm.isDisplayHour() && seconds >= 3600) {
                $scope.minute = vm.displayNumber(parseInt((seconds - (3600 * Number($scope.hour))) / 60));
            } else {
                $scope.minute = vm.displayNumber(parseInt(seconds / 60));
            }
            $scope.second = vm.displayNumber(seconds % 60);
        }

        $scope.isDisplayHour = function () {
            return vm.isDisplayHour()
        }

        function isDisplayHour() {
            return vm.maxTimeUnit == 'hour';
        }

        function displayNumber(number) {
            return number < 10 ? "0" + number : number;
        }

        $scope.$watch('vm.startTime', function (newValue) {
            if (vm.startTime) {
                updateUI(vm.startTime);
                // $scope.$digest();
            }

        });

    }

    angular.module('ipmApp').component('timeSpentWorker', {
        bindings: {
            startTime: '=', // in seconds
            maxTimeUnit: '<', // max is 'hour', min is 'minute', default is minute
            onChange: '&'
        },
        template: '<span> <span ng-if="isDisplayHour()">{{hour}} hours</span> {{minute}} minutes, {{second}} seconds </span>',
        controller: TimeSpentWorkerComponent,
        controllerAs: 'vm'
    })
})();
