(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('HaqsBatteryService', HaqsBatteryService);

    HaqsBatteryService.$inject = ['$resource', '$q', 'halClient', '$http'];

    function HaqsBatteryService($resource, $q, halClient, $http) {
        var baseUrl = 'api/batteries';

        var service = $resource(baseUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
        });

        service.findById = findById;
        service.query= query;
        service.saveOrUpdate = saveOrUpdate;
        service.batteryDuplicate = batteryDuplicate;

        function query(pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/get-all-haqs-battery';
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function findById(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/find-by-id/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function saveOrUpdate(battery) {
            var url = baseUrl + "/save-or-update";
            return $http.post(url, battery).then(function (resp) {
                return resp.data;
            });
        }

        function batteryDuplicate(battery) {
            var url = baseUrl + "/battery-duplicate";
            return $http.post(url, battery).then(function (resp) {
                return resp.data;
            });
        }

        return service;
    };
})();
