(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientDetailController', PatientDetailController);

        PatientDetailController.$inject = ['$state','$stateParams', '$uibModal', 'PatientService', 'SurveyResultService','NPQQuestionnaireService','$window', 'Principal','ipmConstants', 'InvitationService'];

    function PatientDetailController ($state, $stateParams, $uibModal, PatientService, SurveyResultService, NPQQuestionnaireService, $window, Principal, constants, InvitationService) {
        var vm = this;
        vm.load = load;
        vm.isPatient = null;
        vm.patient = null;
        vm.id = null;

        vm.pageSize = 6;
        vm.totalItems = 0;
        vm.page = 1;

        vm.predicate = 'id';
        vm.reverse = true;
        vm.predicateQn = 'dateRequested';
        vm.surveyResult = null;
        vm.surveyResults = [];
        vm.mostRecentHAQ = null;
        vm.questionnaires = [];
        vm.loadAllResults = loadAllResults;
        vm.loadQuestionnaires = loadQuestionnaires;
        vm.notAllowCreateQuestionnaire = false;
        vm.isManager = false;
        vm.isBiller = false;
        vm.isSendingInvitation = false;
        vm.checkPermission = checkPermission;
        vm.hideWellnessClasses = constants.HIDE_WELLNESS_CLASSES;

        vm.checkDisableLaunchResendHAQ = checkDisableLaunchResendHAQ;

        init();

        getAccount(true);

        function getAccount(force) {
            Principal.identity(force).then(function(res) {
                vm.account = res;
                vm.isManager = Principal.hasAnyAuthority([constants.ROLE_MANAGER, constants.ROLE_ADMIN]);
                vm.isBiller = Principal.hasAnyAuthority([constants.ROLE_BILLER]);
            });
       }

        function load (id) {
            PatientService.get(id).then( function(response) {
            	if (response !== undefined) {
                    vm.patient = response;
				} else {
					vm.patient = null;
				}
            }).catch(function (error) {
                // IPMproject-577: Duplicate CPR bug -  hide deactivated user
                // IPMproject-B1947: Patient details page should not show when accessing by URL with deactivated patient id
                $state.go('error');
            });
        }

        function init() {
            vm.id = $stateParams.id;
            vm.load(vm.id);
            vm.pageableQn = {
                page: 1,
                size: 6,
                totalItems: 0
            }
            loadAllResults();
            loadQuestionnaires();
        }

        function loadAllResults() {
        	var pageable = {page: vm.page > 0 ? vm.page - 1 : vm.page,
					size: vm.pageSize,
					sort: sort()};

        	SurveyResultService.findByPatientPaganition($stateParams.id, pageable).then( function(response) {
        		vm.totalItems =  response.totalElements;
        		if (response.content !== undefined) {
		    		vm.surveyResults = response.content;
		    		// filterMostRecentHAQ
                    filterMostRecentHAQ(vm.surveyResults);
				} else {
					vm.surveyResults = [];
				}
		    });
        }

        function loadQuestionnaires() {
        	var pageable = {
                    page: vm.pageableQn.page > 0 ? vm.pageableQn.page - 1 : vm.pageableQn.page,
					size: vm.pageSize,
                    sort: sortQn()
                };

                NPQQuestionnaireService.getNPQQuestionnaireByPatientId($stateParams.id, pageable).then( function(response) {
                vm.pageableQn.totalItems = response.totalElements;
        		if (response.content !== undefined) {
		    		vm.questionnaires = response.content;
				} else {
					vm.questionnaires = [];
				}
		    });
        }
        function sortQn() {
            return [vm.predicateQn + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.getDisposition = function() {
        	return vm.surveyResult.disposition;
        }

        function sort() {
            if (vm.predicate === 'battery.id') {
                return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            }
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.getIndex = function(index) {
        	var result = (index + 1) + (vm.page - 1)*vm.pageSize;
        	return result;
        }

        vm.downloadQuestionnaire = function(id) {
            NPQQuestionnaireService.downloadQuestionnaireById(id).then(function(res) {
                vm.isProcessing = false;
                loadQuestionnaires();
            });
        }
        vm.openSendRequest = function() {
            vm.notAllowCreateQuestionnaire = false;
            $uibModal.open({
                templateUrl: 'app/patient/patient-request-dialog.html',
                controller: 'PatientRequestDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            caseNo: null,
                            patientId: null,
                            active: true
                        };
                    },
                },
                params: {
                    errorQn: null
                }
            }).result.then(function(result) {
                if (result.notAllowCreateQuestionnaire === true){
                    vm.notAllowCreateQuestionnaire = true;
                }
                loadQuestionnaires();
            }, function() {
            });
        }
        vm.openAccountEdit = function() {
            if (vm.patient && vm.patient.userId) {
                $uibModal.open({
                    templateUrl: 'app/admin/user-management/user-management-dialog.html',
                    controller: 'UserManagementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('user-management');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                userId: vm.patient.userId
                            };
                        },
                        action: function() { return "PATIENT_DETAIL";},
                        supportedDataList: ['Auth', function(Auth) {
                            return Auth.getSupportedListRegister();
                        }],
                        patientCases: ['User', function (User) {
                            return User.getAllPatientCaseByUserId( vm.patient.userId);
                        }]
                    },
                    params: {
                        errorQn: null
                    }
                }).result.then(function(result) {
                    vm.load(vm.patient.id);
                    vm.loadAllResults();
                }, function() {
                });
            }
        }
        vm.backToList = function() {
        	var paramsInfo = $stateParams.searchPatientParamsInfo;
            var surveyResultParamsInfo = $stateParams.searchSurveyResultParamsInfo;
            var _typeSearch = $stateParams.type;

            // search type = haq
            if (paramsInfo && _typeSearch && _typeSearch === constants.SEARCH_TYPE_HAQ) {
                $state.go('survey-result-list', {searchSurveyResultParamsInfo: surveyResultParamsInfo});
            } else if (paramsInfo) {
                $state.go('patient-list', {searchPatientParamsInfo: paramsInfo});
            } else {
                // $window.history.back();
                $state.go('patient-list');
            }
        }

        vm.goToAppointmentAddDialog = function() {
            $state.go('appointment-new', {id: vm.patient.id});
        }

        function filterMostRecentHAQ(surveyResults) {
            if ((!surveyResults && !(surveyResults.length > 0)) || vm.page != 1) {
                return vm.mostRecentHAQ = null;
            }
            var surveyResultsOrder = _.orderBy(_.filter(surveyResults, function (sr) {
                return sr.status === 'NOT_STARTED' || sr.status === 'STARTED' || sr.status === 'INCOMPLETE' || sr.status === 'NO_RESULT';
            }), ['id'], ['desc']);
            return vm.mostRecentHAQ = surveyResultsOrder && surveyResultsOrder.length > 0 ? surveyResultsOrder[0] : null;
        }

        vm.goToHAQBattery = function ($event, surveyResult) {
            $event.preventDefault();
            if (surveyResult && surveyResult.patientCase && surveyResult.patientCase.billableConfig && surveyResult.patientCase.billableConfig.haqTestServiceExclusion) {
                openWarningHAQExcluded();
                return;
            }
            if (checkDisableLaunchResendHAQ(surveyResult)) {
                return;
            }
            if (surveyResult && surveyResult.id && surveyResult.patientId) {
                if (surveyResult && surveyResult.caseSelected) {
                    lunchHAQPatientAutoLogin(surveyResult);
                } else {
                    openInputPatientCase(surveyResult, surveyResult.patientId);
                }
                // lunchHAQPatientAutoLogin(surveyResult);
                // PatientService.getPatientByIdAndAutoLogin(surveyResult.patientId, surveyResult.id).then(function (res) {
                //     $window.localStorage.setItem(constants.SURVEY_RESULT_ID, surveyResult.id);
                //     $window.localStorage.setItem(constants.PASS_RESTRICTION, true);
                //     $state.go('home');
                // });
                //
            }
        };

        function lunchHAQPatientAutoLogin(surveyResult) {
            PatientService.getPatientByIdAndAutoLogin(surveyResult.patientId, surveyResult.id).then(function (res) {
                $window.localStorage.setItem(constants.SURVEY_RESULT_ID, surveyResult.id);
                $window.localStorage.setItem(constants.PASS_RESTRICTION, true);
                $window.localStorage.setItem(constants.LAUNCH_FROM, constants.HAQ);
                $state.go('home');
            });
        }

        function openInputPatientCase(surveyResult, patientId) {
            openDialogInputPatientCase(surveyResult, patientId);
        }

        function openWarningHAQExcluded() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.patient.patientCase.haqExcluded"},
                    title: function () {return "ipmApp.patient.patientCase.warning"}
                }
            }).result.then(function() {
            });
        }

        function openDialogInputPatientCase(surveyResult, patientId) {
            $uibModal.open({
                templateUrl: 'app/patient/input-patient-case-dialog/input-patient-case-dialog.html',
                controller: 'InputPatientCaseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    patientId: function () {
                        return patientId;
                    },
                    surveyResultId: function () {
                        return surveyResult.id;
                    }
                }
            }).result.then(function (result) {
                if (result && result.data && result.data.id) { // sure save surveyResult
                    lunchHAQPatientAutoLogin(surveyResult);
                }
            }, function () {
            });
        }

        vm.goToNPQ = function ($event, questionnair) {
            $event.preventDefault();
            if (questionnair && questionnair.id && questionnair.patientId) {
                PatientService.getPatientByIdAndAutoLogin(questionnair.patientId, null).then(function (res) {
                    $window.localStorage.setItem(constants.NPQ_QUESTIONNAIRE_ID, questionnair.id);
                    $window.localStorage.setItem(constants.PASS_RESTRICTION, true);
                    $state.go('home');
                });
            }
        }

        vm.resendHAQ = function(sr) {
            if (vm.isSendingInvitation === true || checkDisableLaunchResendHAQ(sr)) {
                return;
            }
            var surveyResultId = sr.id;

            vm.isSendingInvitation = true;
        	InvitationService.resendHAQ(surveyResultId).then(function (res) {
        	    vm.isSendingInvitation = false;
			    if (res.statusCode === 200) {
				    _.forEach(vm.surveyResults, function(s) {
	                    if (s.id === surveyResultId && s.status === 'INCOMPLETE') {
	                        s.status = 'STARTED';
	                    } else if (s.id === surveyResultId && s.status === 'NO_RESULT') {
	                        s.status = 'NOT_STARTED';
	                    }
	                });
	                openResendInvitationDialog();
			    } else if (res.statusCode === 400 && res.message === 'missing_email_phone') {
			    	openWarningMissingEmailPhone();
			    }
            });
        }

        vm.resendNPQ = function(nPQQuestionnaireId) {
            if (vm.isSendingInvitation === true) {
                return;
            }
            vm.isSendingInvitation = true;
        	InvitationService.resendNPQ(nPQQuestionnaireId).then(function (res) {
                vm.isSendingInvitation = false;
                if (res.statusCode === 200) {
	                openResendInvitationDialog();
                } else if (res.statusCode === 400 && res.message === 'missing_email_phone') {
			    	openWarningMissingEmailPhone();
			    }
                
            });
        }

        vm.openNotificationHistory = function (patientId) {
            if (patientId) {
                $state.go('notification-history', {patientId: patientId});
            }
        };

        function checkPermission() {
            return vm.account && vm.account.authorities && (vm.account.authorities.indexOf('ROLE_STAFF') !== -1
                || vm.account.authorities.indexOf('ROLE_MANAGER') !== -1
                || vm.account.authorities.indexOf('ROLE_ADMIN') !== -1
                || vm.account.authorities.indexOf('ROLE_BILLER') !== -1)
        }

        function checkDisableLaunchResendHAQ(sr) {
            return (sr && sr.patientCase && sr.patientCase.billableConfig && sr.patientCase.billableConfig.haqTestServiceExclusion) || vm.isBiller;
        }

        vm.getCaseNoNPQ = function (npq) {
            return npq.patientCase ? npq.patientCase.caseNumber : npq.caseNo;
        };

        function openWarningMissingEmailPhone() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.patient.resendInvitation.missing_email_phone"},
                    title: function () {return "ipmApp.patient.patientCase.warning"}
                }
            }).result.then(function() {
            });
        }

        function openResendInvitationDialog() {
        	$uibModal.open({
                templateUrl: 'app/patient/resend-invitation-dialog.html',
                controller: 'ResendInvitationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center'
            }).result.then(function() {});
        }
    }
})();
