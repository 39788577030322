(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('TestDetailController', TestDetailController);

    TestDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Test', 'BatteryTest', 'Section'];

    function TestDetailController($scope, $rootScope, $stateParams, entity, Test, BatteryTest, Section) {
        var vm = this;
        vm.test = entity;
        vm.load = function (id) {
            Test.get({id: id}, function(result) {
                vm.test = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:testUpdate', function(event, result) {
            vm.test = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
