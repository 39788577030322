(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadAdhocTestingController', DownloadAdhocTestingController);

    DownloadAdhocTestingController.$inject = ['$filter', 'AdhocTestingService', '$uibModal', '$interval', '$scope', 'ipmConstants', 'tests', 'DateUtils'];

    function DownloadAdhocTestingController ($filter, AdhocTestingService, $uibModal, $interval, $scope, ipmConstants, tests, DateUtils) {
        var vm = this;
        vm.download = download;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.limitTimeMillisSecondsDownload = 1000*30; // 30 seconds
        vm.limitTimeStart = null;
        vm.hasSubmitedRequestDownloadCsv = false;
        vm.hasOpenningDialogRequest = false;
        vm.tests = tests;
        vm.selectedTests = [];
        vm.invalidDates = false;

        init();

        function init() {
            vm.fromDate.setDate(vm.fromDate.getDate() - 30);
        }

        function download() {
            vm.isProcessing = true;
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            var selectedTestIds = [];
            _.forEach(vm.selectedTests, function (test) {
                selectedTestIds.push(test.id);
            })
            downloadCsvAdhocTesting(fromDate, toDate, selectedTestIds);
        }

        function invalidDate() {
            var fromDate = vm.fromDate;
            var toDate = vm.toDate;
            if (!fromDate || !toDate) {
                return true;
            }
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);

            return fromDate > toDate;
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };

        function downloadCsvAdhocTesting(fromDate, toDate, selectedTestIds) {
            vm.noPdf = false;
            AdhocTestingService.downloadCsvAdhocTesting(fromDate, toDate, selectedTestIds).then(function(res) {
                vm.isProcessing = false;
                if (res === 204) { // SC_NO_CONTENT
                    vm.noPdf = true;
                }
            });
        }

    }
})();
