(function() {
    'use strict';

    angular
        .module('ipmApp')
        .directive('confirmPasswordCheck', passwordCheck);

    function passwordCheck() {
    	 var directive = {            
            require: 'ngModel',            
            link: linkFunc
    	 };

        return directive;

        function linkFunc(scope, elem, attrs, ctrl) {
        	var firstPassword = '#' + attrs.confirmPasswordCheck;
            elem.add(firstPassword).on('keyup blur', function () {
            	scope.$apply(function () {
               		ctrl.$setValidity('pwmatch', ($(firstPassword).val() === $(elem).val()));
            	});
            });
        }
    }
})();