
'use strict';

angular.module('rorymadden.date-dropdowns', [])


.factory('rsmdateutils', function () {
  // reduce the day count if not a valid date (e.g. 30 february)
  function changeDate(date){
    if(date.day > 28) {
      date.day--;
      return date;
    }
    // this case should not exist with a restricted input
    // if a month larger than 11 is entered
    else if (date.month > 11) {
      date.day = 31;
      date.month--;
      return date;
    }
  }

  var self = this;
  this.days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  this.months = [
    { value: 0, name: 'global.text.january' },
    { value: 1, name: 'global.text.february' },
    { value: 2, name: 'global.text.march' },
    { value: 3, name: 'global.text.april' },
    { value: 4, name: 'global.text.may' },
    { value: 5, name: 'global.text.june' },
    { value: 6, name: 'global.text.july' },
    { value: 7, name: 'global.text.august' },
    { value: 8, name: 'global.text.september' },
    { value: 9, name: 'global.text.october' },
    { value: 10, name: 'global.text.november' },
    { value: 11, name: 'global.text.december' }
  ];

  return {
	  checkDate: function (date) {
	        var d;
	        if (!date || !date.day || date.month === null || date.month === undefined || !date.year) return false;

	        d = new Date(date.year, date.month, date.day);

	        if (d && (d.getMonth() === date.month && d.getDate() === Number(date.day))) {
	          return d;
	        }

	        return this.checkDate(changeDate(date));
	      },
    get: function(name) {
      return self[name];
    }
  };
})

.directive('rsmdatedropdowns', ['rsmdateutils', function (rsmdateutils){
  return {
    restrict: 'A',
    replace: true,
    require: 'ngModel',
    scope: {
      model: '=ngModel',
      dayName: '@',
      monthName: '@',
      yearName: '@',
      decrementYear: '<',
      minYear: '<',
      formatDate: '<',
    },
    controller: ['$scope', 'rsmdateutils', function ($scope, rsmDateUtils) {
      // set up arrays of values
      $scope.days = rsmDateUtils.get('days');
      $scope.months = rsmDateUtils.get('months');

      // split the current date into sections
      $scope.dateFields = {};

      if(!$scope.model) {
    	  $scope.dateFields.day = "";
          $scope.dateFields.month = "";
          $scope.dateFields.year = "";
      } else {
          $scope.dateFields.day = $scope.formatDate === true ? new Date($scope.model).getDate() : new Date($scope.model).getUTCDate();
          $scope.dateFields.month = $scope.formatDate === true ? new Date($scope.model).getMonth() : new Date($scope.model).getUTCMonth();
          $scope.dateFields.year = $scope.formatDate === true ? new Date($scope.model).getFullYear() : new Date($scope.model).getUTCFullYear();
      }

      // validate that the date selected is accurate
      $scope.checkDate = function(){
        // update the date or return false if not all date fields entered.
        var date = rsmDateUtils.checkDate($scope.dateFields);
        if(date){
        	$scope.model = date;
        }
      };
      $scope.isRequired = function() {
        return !$scope.disableFields && ($scope.required
          || ($scope.dateFields.month != undefined && $scope.dateFields.month  != '')
          || ($scope.dateFields.year != undefined && $scope.dateFields.year != '')
          || ($scope.dateFields.day != undefined && $scope.dateFields.day != ''));
      };
    }],
    template:
    '<div class="row-v4">' +
    '  <div class="col-v4-5">' +
    '    <select id="{{monthName}}" name="{{monthName}}" data-ng-model="dateFields.month" placeholder="{{\'global.text.month\' | translate}}" class="form-control" ng-options="month.value as month.name | translate for month in months" value="{{dateField.month}}" ng-change="checkDate()" ng-disabled="disableFields" ng-required="isRequired()"><option value="" selected> {{"global.text.month" | translate}} </option></select>' +
    '  </div>' +
    '<div class="col-v4 col-day">' +
    '    <select id="{{dayName}}" name="{{dayName}}" data-ng-model="dateFields.day" placeholder="{{\'global.text.day\' | translate}}" class="form-control" ng-options="day for day in days" ng-change="checkDate()" ng-disabled="disableFields" ng-required="isRequired()"><option value="" selected> {{"global.text.day" | translate}} </option></select>' +
    '  </div>' +
    '  <div class="col-v4 col-day">' +
    '    <select ng-if="!yearText" id="{{yearName}}" name="{{yearName}}" data-ng-model="dateFields.year" placeholder="{{\'global.text.year\' | translate}}" class="form-control" ng-options="year for year in years" ng-change="checkDate()" ng-disabled="disableFields" ng-required="isRequired()"><option value="" selected> {{"global.text.year" | translate}} </option></select>' +
    '    <input ng-if="yearText" type="text" id="{{yearName}}" name="{{yearName}}" data-ng-model="dateFields.year" placeholder="Year" class="form-control" ng-disabled="disableFields">' +
    '  </div>' +
    '</div>',
    link: function(scope, element, attrs, ctrl){
      if(attrs.ngRequired =='true') {
        scope.required = true;
      } else {
        scope.required = false;
      }
      if(attrs.yearText) {
        scope.yearText = true;
      }
      // allow overwriting of the
      if(attrs.dayDivClass){
        angular.element(element[0].children[0]).removeClass('form-group col-xs-3');
        angular.element(element[0].children[0]).addClass(attrs.dayDivClass);
      }
      if(attrs.dayClass){
        angular.element(element[0].children[0].children[0]).removeClass('form-control');
        angular.element(element[0].children[0].children[0]).addClass(attrs.dayClass);
      }
      if(attrs.monthDivClass){
        angular.element(element[0].children[1]).removeClass('form-group col-xs-5');
        angular.element(element[0].children[1]).addClass(attrs.monthDivClass);
      }
      if(attrs.monthClass){
        angular.element(element[0].children[1].children[0]).removeClass('form-control');
        angular.element(element[0].children[1].children[0]).addClass(attrs.monthClass);
      }
      if(attrs.yearDivClass){
        angular.element(element[0].children[2]).removeClass('form-group col-xs-4');
        angular.element(element[0].children[2]).addClass(attrs.yearDivClass);
      }
      if(attrs.yearClass){
        angular.element(element[0].children[2].children[0]).removeClass('form-control');
        angular.element(element[0].children[2].children[0]).addClass(attrs.yearClass);
      }

      // set the years drop down from attributes or defaults
      var currentYear = parseInt(attrs.startingYear,10) || new Date().getFullYear();
      // add decrementYear
        if (currentYear && scope.decrementYear) {
            currentYear = currentYear + scope.decrementYear;
        }
      var numYears = parseInt(attrs.numYears,10) || 100;
      var oldestYear = currentYear - numYears;

      scope.years = [];
      for(var i = currentYear; i >= oldestYear; i-- ){
          if (scope.minYear && scope.minYear > 0) {
              if (i >= scope.minYear) {
                  scope.years.push(i);
              }
          } else {
              scope.years.push(i);
          }
      }

      // pass down the ng-disabled property
      scope.$parent.$watch(attrs.ngDisabled, function(newVal){
        scope.disableFields = newVal;
      });

      // ensure that fields are entered
      /*if(attrs.required) {
    	  var required = attrs.required.split(' ');
          ctrl.$parsers.push(function(value) {
            angular.forEach(required, function (elem) {
              if(!angular.isNumber(elem)) {
                ctrl.$setValidity('required', false);
              }
            });
            ctrl.$setValidity('required', true);
          });
      }*/
      // var validator = function(){
      //   var valid = true;
      //   // all fields entered
      //   angular.forEach(required, function (elem) {
      //     if(!angular.isNumber(elem)) {
      //       valid = false;
      //     }
      //   });
      //   // if(isNaN(scope.dateFields.day) && isNaN(scope.dateFields.month) && isNaN(scope.dateFields.year)){
      //   //   valid = true;
      //   // }
      //   // else if(!isNaN(scope.dateFields.day) && !isNaN(scope.dateFields.month) && !isNaN(scope.dateFields.year)){
      //   //   valid = true;
      //   // }
      //   // else valid = false;

      //   ctrl.$setValidity('rsmdatedropdowns', valid);
      // };

      // scope.$watch('dateFields.day', function(){
      //   validator();
      // });

      // scope.$watch('dateFields.month', function(){
      //   validator();
      // });

      // scope.$watch('dateFields.year', function(){
      //   validator();
      // });
    }
  };
}]);
