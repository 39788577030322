(function() {
    'use strict';

    PatientCprReportsController.$inject = ['$scope', 'CarePlanReviewService', '$translate', 'ipmConstants'];

    function PatientCprReportsController($scope, CarePlanReviewService, $translate, constants) {
        var vm = this; //jshint ignore:line

        vm.carePlanReviews = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'id';
        vm.loadAll = loadAll;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            CarePlanReviewService.findAllByPatientId(vm.patientId, pageable).then(function (response) {
                if (response) {
                    vm.carePlanReviews = response.data;
                    vm.pageableAP.totalItems = response.totalItems;
                    vm.isLoading = false;
                }
            });
        }

        vm.loadPage = function (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.formatAndUpdateStatus = function (cpr) {
            if (cpr && cpr.isSkippingReview) { // status SKIPPED
                return $translate.instant('ipmApp.carePlanReviewStatus.' + 'SKIPPED');
            } else if (cpr && cpr.status) {
                return $translate.instant('ipmApp.carePlanReviewStatus.' + cpr.status);
            } else if (cpr.completedDatePrevious) {
                var lastCompletedDateMinus = new moment().subtract(constants.DAYS_PREVIOUS_CPR_COMPLETED, 'days');
                if (lastCompletedDateMinus.isAfter(cpr.completedDatePrevious)) {
                    cpr.status = 'NOT_STARTED'; // update temp status
                    return $translate.instant('ipmApp.carePlanReviewStatus.NOT_STARTED');
                } else {
                    cpr.status = 'NOT_READY'; // update temp status
                    return $translate.instant('ipmApp.carePlanReviewStatus.NOT_READY');
                }
            } else if (!cpr.status && !cpr.completedDatePrevious) {
                cpr.status = 'NOT_STARTED'; // update temp status
                return $translate.instant('ipmApp.carePlanReviewStatus.NOT_STARTED');
            }
            return '';
        }
    }

    angular.module('ipmApp').component('patientCprReports', {
        bindings: {
            patientId: '<'
        },
        templateUrl: 'app/patient/cpr-reports/cpr-reports.html',
        controller: PatientCprReportsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                return $translate.refresh();
            }]
        }
    })
})();
