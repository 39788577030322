(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('OfficeDialogController', OfficeDialogController);

    OfficeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Office', 'ipmConstants'];

    function OfficeDialogController ($scope, $stateParams, $uibModalInstance, entity, Office, constants) {
        var vm = this;
        vm.office = entity;
        vm.addPhone = addPhone;
        vm.removePhone = removePhone;
        vm.errorPhoneNumber = false;
        vm.duplicatePhoneNumber = false;
        vm.errorEmailAddress = false;
        vm.duplicatePhoneNumber = null;
        vm.load = function(id) {
            Office.get({id : id}, function(result) {
                vm.office = result;
                vm.errorContactNumberExists = null;
                vm.errorOfficeCodeExists = null;
            });
        };
        
        vm.testAdminBy = [
           { name: "Computer", value: "COMPUTER" },
           { name: "Technician", value: "TECHNICIAN" }
        ];
        
        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:officeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function (error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if(error.status === 400 ) {
            	if (errorMessage === 'phone already in use') {
            		vm.errorContactNumberExists = 'ERROR';
            	} else if (errorMessage === 'office code is exist') {
                    vm.errorOfficeCodeExists = 'ERROR';
                } else {
            		vm.error = 'ERROR';
            	}
            }
        };
        
        function addPhone () {
        	var newItem = {
        		    'phoneNumber':'',
        		    'id':'',
        		    'emailAddress':''
        		 }
        	vm.office.contacts.push(newItem);
        };
        
        function removePhone (index) {
        	vm.office.contactRemoves.push(vm.office.contacts[index]);
        	vm.office.contacts.splice(index, 1);
        };
        
        vm.save = function () {
        	vm.errorContactNumberExists = null;
        	vm.errorOfficeCodeExists = null;
        	vm.error = null;
            
            for (var i = 0; i < vm.office.contacts.length; i++) {
            	var contact = vm.office.contacts[i];
            	var count = 0;
            	angular.forEach(vm.office.contacts, function(value, key) {
            		if (contact.phoneNumber != undefined && value.phoneNumber != undefined 
            				&& contact.phoneNumber.length > 0 && value.phoneNumber.length > 0 
            				&& value.phoneNumber === contact.phoneNumber) {
            			count += 1;
            		}
            	});
            	
            	if (count > 1) {
    	  			vm.isSaving = false;
    	  			vm.duplicatePhoneNumber = 'ERROR';
    	  			return;
    			}
            }
            
  			vm.isSaving = true;
  			vm.duplicatePhoneNumber = null;
  			if (vm.office.id !== null) {
                Office.update(vm.office, onSaveSuccess, onSaveError);
            } else {
                Office.save(vm.office, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
