(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('NPQQuestionnaireService', NPQQuestionnaireService);

        NPQQuestionnaireService.$inject = ['$http', '$resource', '$q', 'halClient', '$state', 'Auth', '$uibModal', 'DateUtils', 'DownloadBlobService', 'ShowUnexpectedErrorDialogService', '$rootScope', 'ClearLocalStorageService'];

    function NPQQuestionnaireService ($http, $resource, $q, halClient, $state, Auth, $uibModal, DateUtils, DownloadBlobService, ShowUnexpectedErrorDialogService, $rootScope, ClearLocalStorageService) {
        var baseURL = 'api';

    	var service = {
            'getNPQQuestionnaire': getNPQQuestionnaire,
            'getMasterData': getMasterData,
            'viewWorkflow': viewWorkflow,
            'submitWorkflow': submitWorkflow,
            'getNPQQuestionnaireByPatientId': getNPQQuestionnaireByPatientId,
            'saveNPQQuestionnaire': saveNPQQuestionnaire,
            'downloadQuestionnaire': downloadQuestionnaire,
            'downloadQuestionnaireById': downloadQuestionnaireById,
            'getSupportedStatusFilter': getSupportedStatusFilter,
            'getTheMostRecentNPQQuestionnaireOrById': getTheMostRecentNPQQuestionnaireOrById,
            'resumeNPQQuestionnaire': resumeNPQQuestionnaire,
            'getAllNPQQuestionnaireNotComplete': getAllNPQQuestionnaireNotComplete,
            'checkingStatusPatient': checkingStatusPatient
        };

        function convertLocalDateFromServerForWorkflow(workflow) {
            if(workflow && workflow.answer) {
                if(workflow.answer.theApproximateDateOfTheInjury) {
                    workflow.answer.theApproximateDateOfTheInjury = DateUtils.convertLocalDateFromServer(workflow.answer.theApproximateDateOfTheInjury);
                }
                // if(workflow.answer.dateOfBirth) {
                //     workflow.answer.dateOfBirth = DateUtils.convertLocalDateFromServer(workflow.answer.dateOfBirth);
                // }
                if(workflow.answer.dateOfInjury) {
                    workflow.answer.dateOfInjury = DateUtils.convertLocalDateFromServer(workflow.answer.dateOfInjury);
                }
                if(workflow.answer.lastDayWorked) {
                    workflow.answer.lastDayWorked = DateUtils.convertLocalDateFromServer(workflow.answer.lastDayWorked);
                }
                if(workflow.answer.prescriptionsLastFilled) {
                    workflow.answer.prescriptionsLastFilled = DateUtils.convertLocalDateFromServer(workflow.answer.prescriptionsLastFilled);
                }
                if (workflow.answer.diagnostics) {
                    for(var i = 0 ; i < workflow.answer.diagnostics.length; i++) {
                         workflow.answer.diagnostics[i].dateOfExam = DateUtils.convertLocalDateFromServer(workflow.answer.diagnostics[i].dateOfExam);
                    }
                }
                if(workflow.answer.dateOfAccident) {
                    workflow.answer.dateOfAccident = DateUtils.convertLocalDateFromServer(workflow.answer.dateOfAccident);
                }
                if(workflow.answer.medicalSoughtDate) {
                    workflow.answer.medicalSoughtDate = DateUtils.convertLocalDateFromServer(workflow.answer.medicalSoughtDate);
                }
            }
        }
        function getNPQQuestionnaire(npqQuestionairId) {
            var url = baseURL + '/npq-questionnaire';
            return $http.get(url, { params: { npqQuestionairId: npqQuestionairId }}).then(function (resp) {
                return resp.data;
            });
        };

        function getMasterData() {
            var url = baseURL + '/npq-questionnaire/master-data';
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        };

        function viewWorkflow(npqQuestionairId) {
            var url = baseURL + '/npq-questionnaire/workflow';
            return $http.get(url, { params: { npqQuestionairId: npqQuestionairId }}).then(function (resp) {
                convertLocalDateFromServerForWorkflow(resp.data);
                return resp.data;
            });
        };

        function submitWorkflow(workflow) {
	        if(workflow && workflow.answer) {
	            if(workflow.answer.theApproximateDateOfTheInjury) {
	                workflow.answer.theApproximateDateOfTheInjury = DateUtils.convertLocalDateToServer(workflow.answer.theApproximateDateOfTheInjury);
	            }
	            if(workflow.answer.dateOfBirth) {
	                workflow.answer.dateOfBirth = DateUtils.convertLocalDateToServer(workflow.answer.dateOfBirth);
	            }
	            if(workflow.answer.dateOfInjury) {
	                workflow.answer.dateOfInjury = DateUtils.convertLocalDateToServer(workflow.answer.dateOfInjury);
	            }
	            if(workflow.answer.dateOfAccident) {
	                workflow.answer.dateOfAccident = DateUtils.convertLocalDateToServer(workflow.answer.dateOfAccident);
	            }
	            if(workflow.answer.medicalSoughtDate) {
	                workflow.answer.medicalSoughtDate = DateUtils.convertLocalDateToServer(workflow.answer.medicalSoughtDate);
	            }
	            if(workflow.answer.lastDayWorked) {
	                workflow.answer.lastDayWorked = DateUtils.convertLocalDateToServer(workflow.answer.lastDayWorked);
	            }
	            
	            if(workflow.answer.prescriptionsLastFilled) {
	                workflow.answer.prescriptionsLastFilled = DateUtils.convertLocalDateToServer(workflow.answer.prescriptionsLastFilled);
	            }
	            if (workflow.answer.diagnostics) {
	                for(var i = 0 ; i < workflow.answer.diagnostics.length; i++) {
	                    workflow.answer.diagnostics[i].dateOfExam = DateUtils.convertLocalDateToServer(workflow.answer.diagnostics[i].dateOfExam);
	                }
	            }
            }
            var submitSection = workflow.section.template.replace(/-/g, '');
            return $http.post(baseURL + '/npq-questionnaire/workflow/submit-' + submitSection , workflow).then(function (resp) {
                convertLocalDateFromServerForWorkflow(resp.data);
                return resp.data;
            }, function errorCallback(response) {
                ClearLocalStorageService.clearWorkflow();
                ShowUnexpectedErrorDialogService.open(response, 'saveNPQQuestionnaire', null)
                    .result.then(function (result) {
                    $rootScope.$emit('ipmApp.httpError', response);
                });
                return response;
            });
        }

        function getNPQQuestionnaireByPatientId(id, pageable) {
            var url = baseURL + '/npq-questionnaire/patient';
            return $http.get(url, {
                params: {
                    patientId: id,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).then(function (resp) {
                return resp.data;
            });
        }

        function saveNPQQuestionnaire(data) {
            var url = baseURL + '/npq-questionnaire/create';
            return $http.post(url, data).then(function (resp) {
                return resp.data;
            });
        }

        function downloadQuestionnaire(fromDate, toDate) {
            var deferred = $q.defer();
            var url = baseURL + '/npq-questionnaire/download-questionnaire';
            $http({
                url: url,
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {                
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadQuestionnaireById(id) {
            var deferred = $q.defer();
            var url = baseURL + '/npq-questionnaire/download/' + id;
            $http({
                url: url,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                var contentType = headers('content-type');
                var blob = new Blob([data], {type: contentType});
                DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                deferred.resolve(true);
            }).error(function (data, status, headers, config) {                
                deferred.resolve(false);
            });
            return deferred.promise;
        }

        function getSupportedStatusFilter() {
            var deferred = $q.defer();

            var url = baseURL + '/npq-questionnaire/supported-data-list';

            $http.get(url).success(function(data) {
                deferred.resolve(data);
            })
                .error(function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function getTheMostRecentNPQQuestionnaireOrById(npqQuestionairId) {
            if (!npqQuestionairId) {
                npqQuestionairId = 0;
            }
            // if have npqQuestionairId we will get by id, else we get by most recent
            var url = baseURL + '/npq-questionnaire/most-recent' + '?npqQuestionairId=' + npqQuestionairId;
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        };

        function resumeNPQQuestionnaire() {
            var url = baseURL + '/npq-questionnaire/resume';
            return $http.get(url).then(function (resp) {
                convertLocalDateFromServerForWorkflow(resp.data);
                return resp.data;
            });
        };

        function getAllNPQQuestionnaireNotComplete() {
            var url = baseURL + '/npq-questionnaire/all-not-complete';
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        };

        function checkingStatusPatient() {
            var url = baseURL + '/npq-questionnaire/checking-status-patient';
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        };

        function showUnexpectedErrorDialog(res, functionName, currentQuestionObj) {
            $uibModal.open({
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.currentQuestionObj = currentQuestionObj;
                    vm.functionName = functionName;
                    vm.statusCode = res.status;
                    if (_.isObject(res.data)) {
                        vm.errorDetails = res.data.description;
                    } else {
                        vm.errorDetails = res.data;
                    }

                    vm.close = function() {
                        $uibModalInstance.close(true);
                        if (vm.statusCode === 401) { // HttpStatus.UNAUTHORIZED
                            $state.go('home');
                        }
                    }

                    vm.getResponse = function() {
                        if (vm.currentQuestionObj) {
                            var answer = vm.currentQuestionObj.answer;
                            if (answer) {
                                var questionOptions = _.filter(vm.currentQuestionObj.questionOptions, function(o) {
                                    return o.value == answer;
                                });
                                if (!_.isEmpty(questionOptions)) {
                                    return questionOptions[0].name;
                                }
                            }
                        }
                        return null;
                    }
                },
                templateUrl: 'app/survey-workflow/partial/unexpected-error-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                keyboard  : false,
                windowClass: 'absolute-center error-dialog'
            });
        }

    	return service;
    };
})();
