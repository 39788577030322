(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AppointmentDeleteController', AppointmentDeleteController);

    AppointmentDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppointmentService'];

    function AppointmentDeleteController($uibModalInstance, entity, AppointmentService) {
        var vm = this;

        vm.appointmentId = entity ? entity.appointmentId : null;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isDeleting = false;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(appointmentId) {
            vm.isDeleting = true;
            if (appointmentId) {
                AppointmentService.delete({id: appointmentId},
                    function () {
                        $uibModalInstance.close(true);
                    });
            }
        }

    }
})();
