(function() {
    'use strict';


    CPRTreatmentHistoryTableController.$inject = ['ipmConstants', '$scope', '$uibModal'];

    function CPRTreatmentHistoryTableController(constants, $scope, $uibModal) {
        var vm = this; //jshint ignore:line
        vm.emitData = emitData;
        vm.datePickerOpenStatus = {};
        vm.dateFormat = 'MM/DD/YYYY';
        
        init();

        function init() {
        }

        function emitData(treatmentType) {
            vm.onValueChange(
                { data: 
                    {
                        orderNumber: treatmentType.orderNumber,
                        sessionsCompleted: treatmentType.sessionsCompleted,
                        lastDate: treatmentType.lastDate ? moment(treatmentType.lastDate).format(vm.dateFormat) : '',
                        authResponseDate: treatmentType.authResponseDate ? moment(treatmentType.authResponseDate).format(vm.dateFormat) : '',
                        status: treatmentType.status,
                        treatment: treatmentType.value
                    }
                }
            );
        }

        vm.handleLastDateChange = function(treatmentType) {
            vm.emitData(treatmentType);
        }

        vm.handleAuthResponseDateChange = function(treatmentType) {
            vm.emitData(treatmentType);
        }

        vm.handleStatusChange = function(treatmentType) {
            vm.emitData(treatmentType);
        }

        vm.handleSessionsCompletedChange = function(treatmentType) {
            vm.emitData(treatmentType);
        }

        vm.openCalendar = function(treatmentType, date) {
            treatmentType.datePickerOpenStatus[date] = true;
        };

        vm.clearTreatmentRowData = function(treatmentType, index) {

            $uibModal.open({
                templateUrl: 'app/components/dialog/common-question/common-question.modal.html',
                controller: 'CommonQuestionModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    tests: function () {
                        return {
                            title: 'ipmApp.qhpWorkflow.treatmentHistory.clearModal.title',
                            question: 'ipmApp.qhpWorkflow.treatmentHistory.clearModal.question',
                            actionConfirm: 'entity.action.ok',
                            actionCancel: 'entity.action.cancel',
                        };
                    }
                }
            }).result.then(function() {
                // clear validation
                $scope.evaluationForm['sessionsCompleted_' + index].$setPristine();
                $scope.evaluationForm['sessionsCompleted_' + index].$setValidity('invalidRange', true);

                if (treatmentType) {
                    treatmentType.sessionsCompleted = undefined;
                    treatmentType.lastDate = null;
                    treatmentType.status = null;
                    treatmentType.authResponseDate = null;

                    vm.emitData(treatmentType);
                }
            }, function() {
            });
        }

        vm.isButtonClearTreamentDataDisable = function(treatmentType, index) {
            if ($scope.evaluationForm['lastDate_' + index].$invalid
            || $scope.evaluationForm['authResponseDate_' + index].$invalid) {
                return false;
            }
            return (treatmentType.sessionsCompleted === undefined || treatmentType.sessionsCompleted === '')
                && (treatmentType.lastDate === undefined || treatmentType.lastDate === null)
                && (treatmentType.status === undefined || treatmentType.status === null)
                && (treatmentType.authResponseDate === undefined || treatmentType.authResponseDate === null);
        }

        vm.isAuthResponseDateRequired = function(treatmentType) {
            return false;
        }

        vm.isLastDateRequired = function(treatmentType) { // completed

            if (treatmentType.status === 'approvedInProgress') {
                return isSessionCompletedHaveValue(treatmentType) || treatmentType.authResponseDate;
            }

            if (isMDEWithStatusInProgress(treatmentType)) { // MDE and status In Progress
                return true;
            }

            if (isSessionCompletedHaveValue(treatmentType)) {
                return true;
            }

            return false;
        }

        vm.isStatusRequired = function(treatmentType) {
            if (isSessionCompletedIsEqualZero(treatmentType)) {
                return true;
            }
            return false
        }

        vm.isSessionCompletedRequired = function(treatmentType) {
            if (treatmentType.status === 'approvedInProgress') {
                return treatmentType.lastDate || treatmentType.authResponseDate;
            }
            return false;
        }

        function isSessionCompletedHaveValue(treatmentType) {
            return treatmentType.sessionsCompleted && Number(treatmentType.sessionsCompleted) > 0;
        }

        function isSessionCompletedIsEqualZero(treatmentType) {
            return treatmentType.sessionsCompleted && Number(treatmentType.sessionsCompleted) === 0;
        }

        function isMDEWithStatusInProgress(treatmentType) {
            return treatmentType.value === constants.TREATMENT_HISTORY_TYPE[4] && treatmentType.status === 'inProgress';
        }
    }

    angular.module('ipmApp').component('cprTreatmentHistoryTable', {
        bindings: {
            section: '<',
            treatmentTypes: '<',
            onValueChange: '&',
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/treatment-history-table/treatment-history-table.html',
        controller: CPRTreatmentHistoryTableController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
