(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('care-plan-review', {
            parent: 'app',
            url: '/care-plan-review',
            data: {
                authorities: ['ROLE_QHP', 'ROLE_ADMIN'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/care-plan-review/care-plan-review.html',
                    controller: 'CarePlanReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                cprAssignmentEnable: ['CarePlanReviewService', function(CarePlanReviewService) {
                    return CarePlanReviewService.getConfig();
                }],
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('carePlanReview');
                    $translatePartialLoader.addPart('carePlanReviewStatus');
                    $translatePartialLoader.addPart('qhpWorkflow');
                    $translatePartialLoader.addPart('callLog');
                    $translatePartialLoader.addPart('partyType');

                    return $translate.refresh();
                }]
            }
        }).state('care-plan-review.edit', {
                parent: 'care-plan-review',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/care-plan-review/care-plan-review-dialog.html',
                        controller: 'CarePlanReviewDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        windowClass: 'center-dialog-message',
                        size: 'sm',
                        resolve: {
                            entity: ['CarePlanReviewService', function (CarePlanReviewService) {
                                return CarePlanReviewService.findById($stateParams.id);
                            }]
                        }
                    }).result.then(function () {
                        $state.go('care-plan-review', null, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });

    }

})();
