(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('addEnrollPatientsController', addEnrollPatientsController);

    addEnrollPatientsController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$q',
        'frpMdeService',
        'Patient',
        'frpPrograms',
        'providers',
        'selectedFrpProgram',
        'mdeOffices'
    ];

    function addEnrollPatientsController(
        $scope,
        $uibModalInstance,
        $q,
        frpMdeService,
        Patient,
        frpPrograms,
        providers,
        selectedFrpProgram,
        mdeOffices
    ) {
        var vm = this;
        var searchEle = null,
            searchFormGroupEle = null
            ;
        // vm.validPatient = true,
        vm.searchResultEle = null;
        vm.isShowCalendar = false;
        vm.searchPatient = searchPatient;
        vm.setPatientNameAndChartNumber = setPatientNameAndChartNumber;
        vm.modalLoaded = modalLoaded;
        vm.patient = {
            frpProgramId: selectedFrpProgram
        };
        vm.frpPrograms = frpPrograms;
        vm.providers = providers;
        vm.mdeOffices = mdeOffices;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.clear = clear;
        vm.dateOptions = {maxDate: new Date()};
        vm.dateFormat = "MM/dd/yyyy";
        vm.checkValidPatient = checkValidPatient;
        vm.isExistsEnroll = false;
        init();

        function openCalendar() {
            vm.isShowCalendar = true;
        }

        function init() {
            setDefaultMdeOfficeId();
        }

        function setDefaultMdeOfficeId() {
            if (vm.mdeOffices && vm.frpPrograms) {
                var frpProgramSelected = _.first(_.filter(vm.frpPrograms, function (frp) {
                    return frp && frp.id === selectedFrpProgram;
                }));
                var mdeOfficeSelected = _.first(_.filter(vm.mdeOffices, function (item) {
                    return item && frpProgramSelected && item.officeCode === frpProgramSelected.officeCode;
                }));
                if (mdeOfficeSelected) {
                    vm.patient.mdeOfficeId = mdeOfficeSelected.id;
                }
            }
        }

        function searchPatient($item, $model, $label, $event) {
            var inputData = $item ? $item : '';
            return $q(function (resolve, reject) {
                Patient.findByNameOrChartNumber(inputData, null, 0).then(
                    function (response) {
                        resolve(response.data);
                        // vm.validPatient = response.data.length !== 0 ;
                    },
                    function () {
                        reject([]);
                        // vm.validPatient = false;
                    }
                );

            });
        }


        function setPatientNameAndChartNumber() {
            // vm.validPatient = true;
            vm.patient.patientId = vm.searchValue.id;
            vm.patient.patientName = vm.searchValue.firstName + ' ' + vm.searchValue.lastName;
            vm.patient.chartNumber = vm.searchValue.chartNumber;
        }

        function modalLoaded() {
            vm.searchResultEle = angular.element(
                '#search-patient-auto-complete-modal'
            );
            searchEle = angular.element('#modalPatientSearch');
            searchFormGroupEle =
                searchEle && searchEle.parents('.form-group.typeahead');
        }

        function fixSearchGroupValidateStyle(invalid) {
            searchFormGroupEle.toggleClass('has-error', invalid);
        }

        function checkValidPatient() {
            vm.isExistsEnroll = false;
            // vm.validPatient = vm.patient.patientId !== undefined;
        }

        // $scope.$watch('vm.validPatient', function(newVal) {
        //     vm.addEnrollPatientForm.searchPatient.$setValidity(
        //         'required',
        //         newVal
        //     );
        //     fixSearchGroupValidateStyle(newVal);
        // });
        $scope.$watch('vm.patient.injuryDate', function(newVal) {
            if (newVal) {
                vm.addEnrollPatientForm.injuryDate.$setValidity(
                    'wrongDate',
                    newVal <= new Date()
                );
            }
        });


        function save() {
            vm.patient.providerId = vm.patient.provider.id;
            frpMdeService.enrollPatient(vm.patient).then(
                function (response) {
                    vm.isExistsEnroll = false;
                    $uibModalInstance.close();
                },
                function (error) {
                    vm.isExistsEnroll = true;
                }
            );
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
