(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('AppointmentService', AppointmentService);

    AppointmentService.$inject = ['$resource','$q', 'halClient', '$http', 'DateUtils'];

    function AppointmentService($resource, $q, halClient, $http, DateUtils) {
        var baseUrl = 'api/appointments';
        var resourceUrl = "api/appointments/:id";
        var service = $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateOfAppointment = DateUtils.convertLocalDateToServer(data.dateOfAppointment);
                    data.timeOfAppointment = DateUtils.convertLocalDateTimeToServer(data.timeOfAppointment);
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateOfAppointment = DateUtils.convertLocalDateToServer(data.dateOfAppointment);
                    data.timeOfAppointment = DateUtils.convertLocalDateTimeToServer(data.timeOfAppointment);
                    return angular.toJson(data);
                }
            },
            'delete': {method: 'DELETE'}
        });
        service.findAllByPatientId = findAllByPatientId;
        service.findById = findById;

        function findAllByPatientId(patientId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/find-all-by-patient-id';
            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function findById(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/" + id;
            halClient.$get(url).then(function success(result) {
                result.dateOfAppointment = DateUtils.convertDateTimeToDateFromServerAndUpdateHours(result.timeOfAppointment);
                result.timeOfAppointment = DateUtils.convertDateTimeFromServer(result.timeOfAppointment);
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;

    }
})();
