(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultDetailsController', SurveyResultDetailsController);

    SurveyResultDetailsController.$inject = ['$scope', '$state', 'SurveyResultService', 'surveyResult', '$window', '$stateParams', 'Principal', 'ipmConstants'];

    function SurveyResultDetailsController ($scope, $state, SurveyResultService, surveyResult, $window, $stateParams, Principal, constants) {
    	var vm = this;
    	vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = true;
        vm.pageSize = 6;
        vm.totalItems = 0;
    	vm.loadPage = loadPage;
    	vm.surveyResults = [];
        vm.surveyResult = surveyResult;
        vm.loadAllResults = loadAllResults;
        vm.isManager = Principal.hasAnyAuthority([constants.ROLE_MANAGER]);
        
        loadAllResults();
        
        vm.getDisposition = function() {
        	return vm.surveyResult.disposition;
        }
        
        vm.getIndex = function(index) {
        	var result = (index + 1) + (vm.page - 1)*vm.pageSize;
        	return result;
        }
        
        vm.backToList = function() {
        	var paramsInfo = $stateParams.searchSurveyResultParamsInfo;
        	if(paramsInfo) {
        		$state.go('survey-result-list', {searchSurveyResultParamsInfo: paramsInfo});
        	} else {
        		$window.history.back();
        	}
        }
        
        function loadAllResults() {
        	
        	var pageable = {page: vm.page > 0 ? vm.page - 1 : vm.page,
					size: vm.pageSize,
					sort: sort()};
	
        	SurveyResultService.findByPatientPaganition(surveyResult.patientId, pageable).then( function(response) {
        		vm.totalItems =  response.totalElements;
        		if (response.content !== undefined) {
		    		vm.surveyResults = response.content;
				} else {
					vm.surveyResults = [];
				}
		    });
        	
        }
        
        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }
        
        function loadPage () {
            vm.page = page;
            vm.loadAllResults();
        }
    }
})();
