'use strict';
angular.module('signature', [])
    .directive('signaturePad', ['$timeout', function ($timeout) {
        return {
            restrict: 'EA',
            replace: true,
            templateUrl: 'app/directives/signature-pad/signature-pad.html',
            scope: {
                disabled: '=?',
                dataurl: '@',
                height: '@',
                width: '@',
                notifyDrawing: '&onDrawing',
                get: '=?',
                clear: '=?',
                removeBlank: '@',
                onChange: '&',
            },
            controller: ['$scope', function ($scope) {
                $scope.onMouseup = function () {
                    $scope.updateModel();
                    // notify that drawing has ended
                    $scope.notifyDrawing({ drawing: false });
                };

                $scope.updateModel = function () {
                    /*
                     defer handling mouseup event until $scope.signaturePad handles
                     first the same event
                     */
                    $timeout().then(function () {
                        $scope.dataurl = $scope.signaturePad.isEmpty() ? null : $scope.signaturePad.toDataURL();
                        $scope.updateData();
                    });
                };

                $scope.updateData = function () {
                    var dataSave = $scope.getDataSave();
                    $scope.onChange({data: dataSave});
                };

                $scope.$watch("dataurl", function (dataUrl) {
                    if (!dataUrl || $scope.signaturePad.toDataURL() === dataUrl) {
                        return;
                    }

                    $scope.setDataUrl(dataUrl);
                });

                $scope.onMouseup = function () {
                    $scope.updateModel();
                    // notify that drawing has ended
                    $scope.notifyDrawing({ drawing: false });
                };

                $scope.onMouseleave = function () {
                    $scope.updateModel();
                    // notify that drawing has ended
                    $scope.notifyDrawing({ drawing: false });
                };

                $scope.getDataURL = function () {
                    if ($scope.signaturePad) {
                        console.log("getDataURL: {}", $scope.signaturePad.toDataURL());
                    }
                }

                $scope.clearDataURL = function () {
                    if ($scope.signaturePad) {
                        $scope.signaturePad.clear();
                        $scope.updateModel();
                    }
                }

                $scope.showGet = function () {
                    return $scope.get === true;
                }

                $scope.showClear = function () {
                    return $scope.clear === true;
                }
            }],
            link: function (scope, element, attrs) {
                var canvas = element.find('canvas')[0];
                var scale = 0;
                var ctx = canvas.getContext('2d');

                var width = parseInt(scope.width, 10);
                var height = parseInt(scope.height, 10);

                // canvas.width = "auto";
                canvas.height = height;

                scope.signaturePad = new SignaturePad(canvas, {
                    minWidth: 1.2,
                    maxWidth: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0)', // default: black, transparent 100%
                    penColor: 'rgb(0, 0, 0)'
                });

                scope.setDataUrl = function (dataUrl) {
                    var ratio = Math.max(window.devicePixelRatio || 1, 1);

                    ctx.setTransform(1, 0, 0, 1, 0, 0);
                    ctx.scale(ratio, ratio);

                    scope.signaturePad.clear();
                    scope.signaturePad.fromDataURL(dataUrl);

                    $timeout().then(function () {
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        ctx.scale(1 / scale, 1 / scale);
                    });
                };

                scope.$watch('disabled', function (val) {
                    val ? scope.signaturePad.off() : scope.signaturePad.on();
                });

                element.on('touchstart', onTouchstart);
                element.on('touchend', onTouchend);

                function onTouchstart(event) {
                    scope.$apply(function () {
                        // notify that drawing has started
                        scope.notifyDrawing({ drawing: true });
                    });
                    event.preventDefault();
                }

                function onTouchend(event) {
                    scope.$apply(function () {
                        // notify that drawing has ended
                        if (event.target instanceof HTMLCanvasElement) { // touch canvas
                            // updateModel
                            scope.updateModel();
                            scope.notifyDrawing({ drawing: false });
                        } else if (event.target instanceof HTMLButtonElement) { // touch clear button
                            scope.clearDataURL();
                        }
                    });
                    event.preventDefault();
                }

                scope.getDataSave = function () {
                    if (scope.signaturePad.isEmpty()) {
                        return null;
                    }
                    if (scope.removeBlank !== undefined && scope.removeBlank === 'true') {
                        // copy the canvas
                        var copy = document.createElement('canvas');
                        copy.width = canvas.width;
                        copy.height = canvas.height;
                        copy.getContext('2d').drawImage(canvas, 0, 0);
                        // then trim it
                        return trimCanvas(copy).toDataURL();
                    } else {
                        return scope.signaturePad.toDataURL();
                    }
                };

                function trimCanvas(canvas) {
                    var context = canvas.getContext('2d');

                    var imgWidth = canvas.width;
                    var imgHeight = canvas.height;

                    var imgData = context.getImageData(0, 0, imgWidth, imgHeight).data;

                    // get the corners of the relevant content (everything that's not white)
                    var cropTop = scanY(true, imgWidth, imgHeight, imgData);
                    var cropBottom = scanY(false, imgWidth, imgHeight, imgData);
                    var cropLeft = scanX(true, imgWidth, imgHeight, imgData);
                    var cropRight = scanX(false, imgWidth, imgHeight, imgData);

                    // + 1 is needed because this is a difference, there are n + 1 pixels in
                    // between the two numbers inclusive
                    var cropXDiff = (cropRight - cropLeft) + 1;
                    var cropYDiff = (cropBottom - cropTop) + 1;

                    // get the relevant data from the calculated coordinates
                    var trimmedData = context.getImageData(cropLeft, cropTop, cropXDiff,
                        cropYDiff);

                    // set the trimmed width and height
                    canvas.width = cropXDiff;
                    canvas.height = cropYDiff;
                    // clear the canvas
                    context.clearRect(0, 0, cropXDiff, cropYDiff);
                    // place the trimmed data into the cleared canvas to create
                    // a new, trimmed canvas
                    context.putImageData(trimmedData, 0, 0);
                    return canvas; // for chaining
                }

                // returns the RGBA values of an x, y coord of imgData
                function getRGBA(x, y, imgWidth, imgData) {
                    return {
                        red: imgData[(imgWidth * y + x) * 4],
                        green: imgData[(imgWidth * y + x) * 4 + 1],
                        blue: imgData[(imgWidth * y + x) * 4 + 2],
                        alpha: imgData[(imgWidth * y + x) * 4 + 3]
                    };
                }

                function getAlpha(x, y, imgWidth, imgData) {
                    return getRGBA(x, y, imgWidth, imgData).alpha;
                }

                // finds the first y coord in imgData that is not white
                function scanY(fromTop, imgWidth, imgHeight, imgData) {
                    var offset = fromTop ? 1 : -1;
                    var firstCol = fromTop ? 0 : imgHeight - 1;

                    // loop through each row
                    for (var y = firstCol; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
                        // loop through each column
                        for (var x = 0; x < imgWidth; x++) {
                            // if not white, return col
                            if (getAlpha(x, y, imgWidth, imgData)) {
                                return y;
                            }
                        }
                    }

                    // the whole image is white already
                    return null;
                }

                // finds the first x coord in imgData that is not white
                function scanX(fromLeft, imgWidth, imgHeight, imgData) {
                    var offset = fromLeft ? 1 : -1;
                    var firstRow = fromLeft ? 0 : imgWidth - 1;

                    // loop through each column
                    for (var x = firstRow; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
                        // loop through each row
                        for (var y = 0; y < imgHeight; y++) {
                            // if not white, return row
                            if (getAlpha(x, y, imgWidth, imgData)) {
                                return x;
                            }
                        }
                    }

                    // the whole image is white already
                    return null;
                }
            }
        }
    }]);
// Backward compatibility
angular.module('ngSignaturePad', ['signature']);
