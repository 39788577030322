(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FrpMdeReportsController', FrpMdeReportsController);

    FrpMdeReportsController.$inject = ['$scope', '$filter', 'FrpMdeReportsService'];

    function FrpMdeReportsController ($scope, $filter, FrpMdeReportsService) {
        var vm = this;

        vm.isProcessing = false;
        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};

        vm.inValidDates = false;
        vm.noPdf = false;
        function init() {
        	// set to Monday of this week
        	vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
        	// set to previous Monday
        	vm.fromDate.setDate(vm.fromDate.getDate() - 7);

        	// set to previous Friday
        	vm.toDate = new Date(vm.fromDate);
        	vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.report = function () {
            vm.isProcessing = true;
            vm.noPdf = false;
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            FrpMdeReportsService.downloadReports(fromDate, toDate).then(function (res) {
                vm.isProcessing = false;
                if (res && res.status === 204) {
                    vm.noPdf = true;
                }
            });
        };

        vm.onChangeDate = function () {
            vm.inValidDates = vm.fromDate !== undefined && vm.toDate !== undefined;
            var fromDate = vm.fromDate;
            var toDate = vm.toDate;
            if (!fromDate || !toDate) {
                return;
            }
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);
            vm.inValidDates = vm.inValidDates && fromDate > toDate;
        };

        init();
    }
})();
