(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'paginationConstants', 'JhiLanguageService', 'UserSearch', '$stateParams', '$state'];

    function UserManagementController(Principal, User, ParseLinks, paginationConstants, JhiLanguageService, UserSearch, $stateParams, $state) {
        var vm = this;

        vm.clear = clear;
        vm.currentAccount = null;
        vm.languages = null;
        vm.links = null;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.page = 1;
        vm.predicate = 'u.firstName';
        vm.predicateDefault = 'u.firstName';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.setActive = setActive;
        vm.totalItems = 0;
        vm.users = [];
        vm.checkDisableDeleted = checkDisableDeleted;

        init();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function init() {
            var paramsInfo = $stateParams.searchUserParamsInfo ? $stateParams.searchUserParamsInfo : null;
            if(paramsInfo) {
                vm.searchQuery = paramsInfo.searchQuery;
                vm.page = paramsInfo.page;
                vm.totalItems = paramsInfo.totalItems;
                vm.pageSize = paramsInfo.pageSize;
                vm.predicate = paramsInfo.predicate;
                vm.reverse = paramsInfo.reverse;
            } else {
                vm.clear();
            }
            vm.loadAll();
        }

        function loadAll () {

        	var pageable = {page: vm.page > 0 ? vm.page - 1 : vm.page,
					size: vm.pageSize,
					sort: sort()};

        	UserSearch.searchUsers(vm.searchQuery, pageable).then( function(response) {
		    	if (response.content !== undefined) {
		    		vm.users = response.content;
				} else {
					vm.surveyResults = [];
				}

		    	vm.totalItems =  response.totalElements;
				if(vm.searchQuery != null && !_.isEmpty(vm.searchQuery)) {
					vm.isShowClearSearchButton = true;
				}else {
					vm.isShowClearSearchButton = false;
				}
		    });

        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.search = function () {
            vm.page = 0;
            vm.predicate = vm.predicateDefault;
            vm.reverse = false;
            vm.loadAll();
        }

        vm.clear = function () {
            vm.surveyResults = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = vm.predicateDefault;
            vm.reverse = true;
            vm.searchQuery = null;
            vm.loadAll();
        };

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            if(vm.editForm !== null && vm.editForm !== undefined) {
            	vm.editForm.$setPristine();
            	vm.editForm.$setUntouched();
            }
        }

        function getSearchUserParamsInfo() {
            var paramsInfo = {
                searchQuery: vm.searchQuery,
                page: vm.page,
                totalItems: vm.totalItems,
                pageSize: vm.pageSize,
                predicate: vm.predicate,
                reverse: vm.reverse
            };
            return paramsInfo;
        }

        function checkDisableDeleted(user) {
            if (isQHPRole(user)) {
                if (user.qhpUserDetail && user.qhpUserDetail.tasks && user.qhpUserDetail.tasks.length > 0) {
                    return true;
                }
                return false;
            }
            return false;
        }

        function isQHPRole(user) {
            if (user && user.authorities && user.authorities.indexOf('ROLE_QHP') !== -1) {
                return true;
            }
            return false;
        };

        vm.goToUserDetails = function(id) {
            var paramsInfo = getSearchUserParamsInfo();
            $state.go('user-management-detail', {id: id, searchUserParamsInfo: paramsInfo});
        }
    }
})();
