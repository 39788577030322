(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('NPQQuestionnaireController', NPQQuestionnaireController);

        NPQQuestionnaireController.$inject = ['$scope', '$state', '$timeout', '$translate', '$location', 'Auth', '$uibModal', '$window', 'npqQuestionnaire', 'masterData', 'ipmConstants'];

    function NPQQuestionnaireController($scope, $state, $timeout, $translate, $location, Auth, $uibModal, $window, npqQuestionnaire, masterData, ipmConstants) {
        var vm = this;

        vm.npqQuestionnaire = npqQuestionnaire;
        vm.masterData = masterData;
        vm.percent = 0;
        
        vm.init = function() {
            vm.percent = vm.getPercent();
        }

        vm.getLanguage = function() {
            return $translate.storage().get('NG_TRANSLATE_LANG_KEY');
        }

        vm.widthStyle = function() {
            vm.percent = vm.getPercent();
            if (vm.getPercent() > 0) {
                return { width: vm.getPercent() + '%' };
            } else {
                return { width: vm.getPercent() + '%', color: 'transparent' };
            }           
        }
        
        vm.getPercent = function() {
            return Math.ceil((vm.npqQuestionnaire.sectionIdsCompleted.length/ipmConstants.TOTAL_NPQ_SECTION)*100);
        }
        
        vm.init();
    }
})();
