(function() {
    'use strict';

    ProgressTrackerItemController.$inject = ['ipmConstants'];

    function ProgressTrackerItemController(constants) {
        var vm = this; //jshint ignore:line
        vm.constants = constants;

        init();

        function init() {
        }

        vm.getClass = function() {
            if (vm.item.status === 'INPROGRESS') {
                return 'inProgress';
            }

            if (vm.item.status === 'COMPLETE') {
                return 'completed';
            }
        }

        vm.getIcon = function() {
            if (vm.item.status === 'NOT_STARTED') {
                return 'glyphicon-option-horizontal';
            }

            if (vm.item.status === 'INPROGRESS') {
                return 'glyphicon-repeat';
            }

            if (vm.item.status === 'COMPLETE') {
                return 'glyphicon-ok';
            }
        }

        
    }

    angular.module('ipmApp').component('progressTrackerItem', {
        bindings: {
            item: '<'
        },
        templateUrl: 'app/frp-mde/tab/mde/component/progress-tracker-item.html',
        controller: ProgressTrackerItemController,
        controllerAs: 'vm'
    })
})();
