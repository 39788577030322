(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('BatteryDetailController', BatteryDetailController);

    BatteryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Battery', 'BatteryTest'];

    function BatteryDetailController($scope, $rootScope, $stateParams, entity, Battery, BatteryTest) {
        var vm = this;
        vm.battery = entity;
        vm.load = function (id) {
            Battery.get({id: id}, function(result) {
                vm.battery = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:batteryUpdate', function(event, result) {
            vm.battery = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
