(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportAddendumController', MDEReportAddendumController);

    MDEReportAddendumController.$inject = ['$scope', '$state', '$uibModal', 'mdeReport', 'ipmConstants', '$window', 'MdeReportService', 'DataUtils', 'isView'];

    function MDEReportAddendumController($scope, $state, $uibModal, mdeReport, ipmConstants, $window, MdeReportService, DataUtils, isView) {
        var vm = this;
        vm.mdeReport = mdeReport ? mdeReport : {};
        vm.mdeReportOld = {};
        vm.save = save;
        vm.isRequired = false;
        vm.isFinalize = false;
        vm.isDisabled = false;
        vm.isChanged = isChanged;
        vm.isView = isView;
        vm.submitted = false;
        vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';

        init();

        function init() {
            vm.mdeReportOld = DataUtils.deepCopy(vm.mdeReport);
            vm.isFinalize = vm.mdeReport && vm.mdeReport.mdeReportAddendum
            && (vm.mdeReport.mdeReportAddendum.status === 'COMPLETE') ? true : false;
            setIsDisabled();
        }

        function setIsDisabled() {
            if (vm.isView || (vm.mdeReport.patientFRPMDE && (vm.mdeReport.status === 'MDE_ABANDONED' || vm.mdeReport.patientFRPMDE.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        function isChanged() {
            for (var field in vm.mdeReport.mdeReportAddendum) {
                if (!vm.mdeReport.mdeReportAddendum[field]) {
                    vm.mdeReport.mdeReportAddendum[field] = null;
                }
            }
            for (var fieldOld in vm.mdeReportOld.mdeReportAddendum) {
                if (!vm.mdeReportOld.mdeReportAddendum[fieldOld]) {
                    vm.mdeReportOld.mdeReportAddendum[fieldOld] = null;
                }
            }
            return !angular.equals(vm.mdeReport, vm.mdeReportOld);
        }

        function save() {
            vm.isSaving = true;
            if (vm.mdeReport) {
                vm.mdeReport.mdeReportAddendum.status = 'COMPLETE'; // set status COMPLETE when save addendum
                MdeReportService.saveMDEReportAddendum(vm.mdeReport).then(function (response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            goToMdeReport();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                goToMdeReport();
            }
        }

        vm.confirmDialog = function (message) {
            if (vm.isView) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    goToMdeReport();
                }
            });
        }

        function goToMdeReport() {
            $state.go('mde-report', {id: vm.mdeReport.id});
        }
    }
})();
