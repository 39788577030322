(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultListController', SurveyResultListController);

    SurveyResultListController.$inject = ['$scope', '$state', 'SurveyResultService', '$localStorage', 'supportedDataList', '$stateParams', '$window', 'Principal', 'ipmConstants'];

    function SurveyResultListController ($scope, $state, SurveyResultService, $localStorage, supportedDataList, $stateParams, $window, Principal, constants) {
    	var vm = this;
        vm.surveyResults = [];
        vm.statuses = supportedDataList.statuses;
        vm.status = null;
        vm.query = null;
        vm.page = 1;
        vm.totalItems = 0;
        vm.predicate = 'u.firstName';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.isManager = Principal.hasAnyAuthority([constants.ROLE_MANAGER]);
        
        function loadAll() {
        	var pageable = {page: vm.page > 0 ? vm.page - 1 : vm.page,
    						size: vm.pageSize,
    						sort: sort()};
        	
            SurveyResultService.searchSurveyResults(vm.searchQuery, vm.status, pageable).then( function(response) {
            	if (response._embedded !== undefined) {
	        		vm.surveyResults = response._embedded.surveyResultDTOList;
				} else {
					vm.surveyResults = [];
				}
            	
            	vm.totalItems = response.page.totalElements;
	        	vm.totalElements =  response.page ? response.page.totalElements : 0;
        		if(vm.searchQuery != null && !_.isEmpty(vm.searchQuery)) {
        			vm.isShowClearSearchButton = true;
        		}else {
        			vm.isShowClearSearchButton = false;
        		}
            });
            
        };
        
        function init() {
        	var paramsInfo = $stateParams.searchSurveyResultParamsInfo;
        	if(paramsInfo) {
        		vm.status = paramsInfo.status;
        		vm.searchQuery = paramsInfo.searchQuery;
        		vm.page = paramsInfo.page;
        		vm.totalItems = paramsInfo.totalItems;
        		vm.pageSize = paramsInfo.pageSize;
        		vm.predicate = paramsInfo.predicate;
        		vm.reverse = paramsInfo.reverse;
        	}
        	vm.loadAll();
        }
        
        function getSearchSurveyResultParamsInfo() {
        	var paramsInfo = {
        		status: vm.status,
        		searchQuery: vm.searchQuery,
            	page: vm.page,
            	totalItems: vm.totalItems,
            	pageSize: vm.pageSize,
            	predicate: vm.predicate,
            	reverse: vm.reverse
        	}

        	return paramsInfo;
        }

        function getSearchPatientParamsInfo() {
            var paramsInfo = $stateParams.searchPatientParamsInfo;
            if (paramsInfo) {
                vm.status = paramsInfo.status;
                vm.searchQuery = paramsInfo.searchQuery;
                vm.page = paramsInfo.page;
                vm.totalItems = paramsInfo.totalItems;
                vm.pageSize = paramsInfo.pageSize;
                vm.predicate = paramsInfo.predicate;
                vm.reverse = paramsInfo.reverse;
            }
            return paramsInfo;
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.search = function () {
            vm.page = 0;
            vm.predicate = 'u.firstName';
            vm.reverse = false;
            vm.loadAll();
        };

        vm.clear = function () {
            vm.surveyResults = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'u.firstName';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.loadAll();
        };

        vm.downloadAllReport = function() {
        	var url = 'api/survey-results/download-all-completed-report';
        	$window.open(url, '_blank');
        }

        vm.goToSurveyResultDetails = function(surveyResultId) {
        	var paramsInfo = getSearchSurveyResultParamsInfo();
        	$state.go('survey-result-details', {id: surveyResultId, searchSurveyResultParamsInfo: paramsInfo});
        }

        vm.goToPatientDetails = function(patientId) {
            if (patientId) {
                var paramsInfo = getSearchPatientParamsInfo();
                $state.go('patient-detail', {id: patientId, searchPatientParamsInfo: paramsInfo, type: constants.SEARCH_TYPE_HAQ});
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        vm.getDisposition = function(surveyResult) {
        	return surveyResult.disposition;
        }

        vm.changeDisposition = function(surveyResult) {
        	surveyResult.disposition = !surveyResult.disposition;
        	SurveyResultService.changeDisposition(surveyResult.id, surveyResult.disposition);
        }

        init();
    }
})();
