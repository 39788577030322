(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Question', Question);

    Question.$inject = ['$resource', '$q', 'halClient', '$http'];

    function Question ($resource, $q, halClient, $http) {
        var baseUrl = 'api/questions';
        var resourceUrl =  'api/questions/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        service.invertScoring = invertScoring;
        service.updateOrderNumber = updateOrderNumber;

        function invertScoring(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/invert-scoring/" + id;
            halClient.$post(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function updateOrderNumber(testId, sections) {
            var url = baseUrl + "/update-order-number/" + testId;
            return $http.post(url, sections).then(function (resp) {
                return resp.data;
            });
        };

        return service;
    }
})();
