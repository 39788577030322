(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CallLogController', CallLogController);

    CallLogController.$inject = ['$scope', '$filter', '$uibModal'];

    function CallLogController($scope, $filter, $uibModal) {
        var vm = this;
        vm.callLogClick = callLogClick;
        init();

        function init() {
        }

        function callLogClick() {
            openCallLogDialog();
        }

        function openCallLogDialog() {
            $uibModal.open({
                templateUrl: 'app/qhp-workflow/care-plan-review-workflow/call-log/partial/call-log-dialog.html',
                controller: 'CallLogDialogController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                windowClass: 'call-log',
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            patientId: vm.patientId,
                            qhpUserId: vm.qhpUserId,
                            carePlanReviewId: vm.carePlanReviewId,
                            dateLog: Date.now(),
                            party: null,
                            summary: null
                        };
                    },
                    isAdHoc: function () {
                        return vm.isAdHoc;
                    }
                }
            });
        }

    }

    angular.module('ipmApp').component('callLog', {
        bindings: {
            patientId: '<',
            qhpUserId: '<',
            carePlanReviewId: '<',
            isAdHoc: '<',
            onChange: '&'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/call-log/call-log.html',
        controller: CallLogController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                // $translatePartialLoader.addPart('global');
                // $translatePartialLoader.addPart('callLog');
                // $translatePartialLoader.addPart('partyType');
                return $translate.refresh();
            }]
        }
    })
})();
