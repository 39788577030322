(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionDialogController', QuestionDialogController);

    QuestionDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'sectionEntity', 'entity', 'Question', 'Section', 'QuestionOption'];

    function QuestionDialogController ($scope, $stateParams, $uibModalInstance, sectionEntity, entity, Question, Section, QuestionOption) {
        var vm = this;
        vm.question = entity;
        vm.maxOption = 6;
        vm.createQuestions = createQuestions;
        vm.optionRequired = optionRequired;
        //all your init controller goodness in here
        this.$onInit = function () {
            vm.isMiscType = sectionEntity.questionType === 'MISC';
            if (!vm.isMiscType) {
                vm.question.type = sectionEntity.questionType;
                // vm.question.orderNumber = sectionEntity.questions.length + 1;
                if(vm.question.type === 'RANK') {
                    vm.maxOption = 5;
                    vm.addRankOption();
                } else if (vm.question.type === 'MULTIPLE_CHOICE' || vm.question.type === 'MULTI_SELECT') {
                    if (vm.question && !vm.question.id) {
                      vm.question.options = [];
                      vm.createQuestions(2); // 2 question
                    }
                }
            } else {
                if(vm.question && vm.question.id && vm.question.type === 'RANK') {
                    vm.maxOption = 5;
                }
            }

        }
        vm.load = function(id) {
            Question.get({id : id}, function(result) {
                vm.question = result;
            });
        };
        vm.sortableOptions = {
            placeholder: "ui-state-highlight",
            helper: function (event, ui) {
                var table = '<table style="position: absolute;" class="jh-table table table-striped"></table>';
                var tbody = '<tbody>'+ui[0].outerHTML+'</tbody>';
                var draggedItem = $(table).append(tbody);
                return draggedItem;
            },
            update: function(e, ui) {

            },
            stop: function(e, ui) {
                var options = ui.item.sortable.sourceModel;
                options.forEach(function(option, index) {
                    option.orderNumber = (index + 1);
                });
            }
        };

        vm.addOption = function() {
            var d = new Date();
            var n = d.getTime();
            vm.question.options.push({
                id: -n,
                nameEn: null,
                nameEs: null,
                orderNumber: vm.question.options.length+1,
                type: 'NUMERIC',
                deleted: false
            });
        };
        vm.removeOption = function(optionId) {
            vm.question.options = _.reject(vm.question.options, { 'id': optionId});
            var index = 1;
            _.forEach(vm.question.options, function(option) {
                option.orderNumber = index++;
            });
        }
        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:questionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.question.type === 'MULTIPLE_CHOICE' || vm.question.type === 'MULTI_SELECT') {
                // remove options blank
                var newOptionLists = [];
                angular.forEach(vm.question.options, function (option) {
                    if (option.nameEn && option.nameEs && option.value) {
                        newOptionLists.push(option);
                    }
                });
                // re-index
                angular.forEach(newOptionLists, function (option, index) {
                    option.orderNumber = index + 1;
                });
                // re-assign to question object
                vm.question.options = newOptionLists;
            }
            if (vm.question.id !== null) {
                Question.update(vm.question, onSaveSuccess, onSaveError);
            } else {
                Question.save(vm.question, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.questionTypeChange = function() {
            vm.question.options = []; // reset
            if(vm.question.type === 'RANK') {
              vm.maxOption = 5;
                vm.addRankOption();
            } else if (vm.question.type === 'MULTIPLE_CHOICE' || vm.question.type === 'MULTI_SELECT') {
                vm.maxOption = 6;
                // add minimum 2 row
                if(vm.question.id === null) {
                  vm.createQuestions(2); // 2 question
                }
            }
        };

        vm.addRankOption = function() {
            if(vm.question.id === null) {
                vm.question.options = [];
                vm.createQuestions(5); // 5 question
            }
        }

        function createQuestions(numberOfRow) {
            for (var i = 1; i <= numberOfRow; i++) {
                vm.addOption();
            }
        }

        function optionRequired(index) {
            if (vm.question.type === 'RANK') {
                return true;
            }
            if (vm.question.type === 'MULTIPLE_CHOICE' || vm.question.type === 'MULTI_SELECT') {
                if (vm.question.options.length === 2) {
                    return true;
                }
                if (vm.question.options.length > 2) {
                    var l = _.filter(vm.question.options, function(option) { return option.nameEn && option.nameEs && option.value });
                    if (l.length < 2) {
                        return true;
                    }
                    var a = vm.question.options[index];
                    return a.nameEn || a.nameEs || a.value;
                }
            }
            return false;
        }
    }
})();
