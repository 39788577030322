(function() {
    'use strict';
	var module = angular.module('ipmApp')

	module.directive('removeByTag', function($filter, $browser, $timeout) {
	    return {
			require: 'ngModel',
			scope: {
                tagName: '@',
            },
	        link: function($scope, $element, $attrs, ngModelCtrl) {
	            //This runs when the model gets updated on the scope directly and keeps our view in sync
	            ngModelCtrl.$render = function() {
					var regex = '<(\/)?' + $scope.tagName + '([^>]*)>';
					var value = ngModelCtrl.$viewValue.replace(new RegExp(regex, 'g'), '');
	                $element.append($filter('tel')(value, false));
	            };
	        }

	    };
	});

})();