(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ResendInvitationDialogController', ResendInvitationDialogController);

    ResendInvitationDialogController.$inject = ['$uibModalInstance'];

    function ResendInvitationDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.yes = function() {
            $uibModalInstance.close(true);
        };
    }
})();
