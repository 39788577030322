(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('HAQEvaluationWorkflowService', HAQEvaluationWorkflowService);

        HAQEvaluationWorkflowService.$inject = ['$resource', '$http', '$q', 'DownloadBlobService'];

    function HAQEvaluationWorkflowService ($resource, $http, $q, DownloadBlobService) {
        var resourceUrl = "api/haq-evaluation-workflow";
        var service = $resource(resourceUrl, {}, {});
        service.getInQueue = getInQueue;
        service.navigationBatteryTestEvaluationWorkflow = navigationBatteryTestEvaluationWorkflow;
        service.sumbitEvaluation = sumbitEvaluation;
        service.finishEvaluation = finishEvaluation;
        service.downloadHaqEvaluation = downloadHaqEvaluation;
        service.updateTimeSpent = updateTimeSpent;
        service.adhocUpdateTimeSpent = adhocUpdateTimeSpent;
        service.getMostRecentSignature = getMostRecentSignature;
        service.getTimerHasElapsed = getTimerHasElapsed;
        service.getTimeSpent = getTimeSpent;
        service.findByPatientPaganition = findByPatientPaganition;
        service.findReadyForReviewPaginationByPatient = findReadyForReviewPaginationByPatient;
        service.isReviewingHaqEvaluation = isReviewingHaqEvaluation;
        service.startHaqEvaluation = startHaqEvaluation;

        function startHaqEvaluation(haqEvaluationId) {
            return $http.post(resourceUrl + '/start-haq-evaluation/' + haqEvaluationId, {}).then(function (resp) {
                return resp.data;
            });
        }

        function getInQueue(action) {
            return $http.get(resourceUrl + '/get-in-queue', {
                params: {
                    action: action ? action : null
                }
            }).then(function (response) {
                return response.data;
            });
        };

        function navigationBatteryTestEvaluationWorkflow(evaluationRequest) {
            return $http.post(resourceUrl + '/navigation-battery-test-evaluation-workflow', evaluationRequest, {'Content-Type': 'application/x-www-form-urlencoded'}).then(function (resp) {
                return resp.data;
            });
        };

        function sumbitEvaluation(evaluationRequest) {
            return $http.post(resourceUrl + '/submit-evaluation', evaluationRequest, {'Content-Type': 'application/x-www-form-urlencoded'}).then(function (resp) {
                return resp.data;
            });
        };

        function finishEvaluation(finishEvaluationRequest) {
            return $http.post(resourceUrl + '/finish-evaluation', finishEvaluationRequest).then(function (resp) {
                return resp.data;
            });
        };

        function updateTimeSpent(id, timeUpdate, resetTimeLogin, isRefresh) {
            return $http.get(resourceUrl + '/update-time-spent/' + id, { params: { timeUpdate: timeUpdate, resetTimeLogin: resetTimeLogin, isRefresh: isRefresh }}).then(function (resp) {
                return resp.data;
            });
        };

        function adhocUpdateTimeSpent(id, timeUpdate, resetTimeLogin, isRefresh) {
            return $http.get(resourceUrl + '/adhoc-update-time-spent/' + id, { params: { timeUpdate: timeUpdate, resetTimeLogin: resetTimeLogin, isRefresh: isRefresh }}).then(function (resp) {
                return resp.data;
            });
        };

        function getMostRecentSignature(resetTimeLogin) {
            return $http.get(resourceUrl + '/get-most-recent-signature', { params: { resetTimeLogin: resetTimeLogin}}).then(function (response) {
                return response.data;
            });
        };

        function getTimerHasElapsed() {
            return $http.get(resourceUrl + '/get-timer-has-elapsed').then(function (response) {
                return response.data;
            });
        };

        function getTimeSpent() {
            return $http.get(resourceUrl + '/get-time-spent-current').then(function (response) {
                return response.data;
            });
        };

        function downloadHaqEvaluation(fromDate, toDate) {
            var deferred = $q.defer();
            var url = resourceUrl + '/download-haq-evaluation';
            $http({
                url: url,
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function findByPatientPaganition(patientId, pageable) {
            var deferred = $q.defer();
            var url = resourceUrl + '/find-by-patient';

            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            })
                .success(function (data, status, headers) {
                    var result = new Object();
                    result.data = data && data !== undefined ? data : [];
                    result.totalItems = headers('X-Total-Count');
                    deferred.resolve(result);
                }).error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function findReadyForReviewPaginationByPatient(patientId, pageable) {
            var deferred = $q.defer();
            var url = resourceUrl + '/find-ready-for-review';

            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            })
                .success(function (data, status, headers) {
                    var result = new Object();
                    result.data = data && data !== undefined ? data : [];
                    result.totalItems = headers('X-Total-Count');
                    deferred.resolve(result);
                }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function isReviewingHaqEvaluation(id) {
            return $http.get(resourceUrl + '/is-reviewing-haq-evaluation/' + id).then(function (resp) {
                return resp.data;
            });
        };

        return service;
    };
})();
