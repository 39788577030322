(function () {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('samlLogin', {
                parent: 'app',
                url: '/saml/SSO',
                data: {
                    authorities: [],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        template: '<span></span>',
                        controller: 'SAMLLoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    isLogin: function () {
                        return false;
                    }
                }
            })
            .state('samlReLogin', {
                parent: 'app',
                url: '/saml/login',
                data: {
                    authorities: [],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        template: '<span></span>',
                        controller: 'SAMLLoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    isLogin: function () {
                        return true;
                    }
                }
            })
        ;
    }
})();
