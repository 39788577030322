(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {       
        $stateProvider
        .state('section', {
            parent: 'entity',
            url: '/section',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.section.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/section/sections.html',
                    controller: 'SectionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('section');
                    $translatePartialLoader.addPart('questionType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('section-detail', {
            parent: 'entity',
            url: '/section/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.section.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/section/section-detail.html',
                    controller: 'SectionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('section');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Section', function($stateParams, Section) {
                    return Section.get({id : $stateParams.id});
                }]
            }
        })
        .state('section-editor', {
            parent: 'entity',
            url: '/section/{id}/editor',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.section.editor.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/section/section-editor.html',
                    controller: 'SectionEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('section');
                    $translatePartialLoader.addPart('questionType');
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Section', function($stateParams, Section) {
                    return Section.get({id : $stateParams.id});
                }]
            }
        })
        .state('section.new', {
            parent: 'section',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/section/section-dialog.html',
                    controller: 'SectionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        action: function() { return "NEW"},
                        entity: function () {
                            return {
                                nameEn: null,
                                nameEs: null,
                                orderNumber: null,
                                instructionsEn: null,
                                instructionsEs: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('section', null, { reload: true });
                }, function() {
                    $state.go('section');
                });
            }]
        })
        .state('section.edit', {
            parent: 'section',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/section/section-dialog.html',
                    controller: 'SectionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        action: function() { return "EDIT"},
                        entity: ['Section', function(Section) {
                            return Section.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('section', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('section.view', {
            parent: 'section',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/section/section-dialog.html',
                    controller: 'SectionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        action: function() { return "VIEW"},
                        entity: ['Section', function(Section) {
                            return Section.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('section', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('section.delete', {
            parent: 'section',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/section/section-delete-dialog.html',
                    controller: 'SectionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Section', function(Section) {
                            return Section.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('section', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
