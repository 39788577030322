(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AdHocTestingAddDialogController', AdHocTestingAddDialogController);

    AdHocTestingAddDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'entity', 'HAQBatteryAdHocService', 'BatteryTest', 'patientId'];

    function AdHocTestingAddDialogController($stateParams, $state, $uibModalInstance, entity, HAQBatteryAdHocService, BatteryTest, patientId) {
        var vm = this;
        vm.patientId = patientId;
        vm.haqBatteryAdHoc = entity;
        vm.haqBatteryAdHocSeleted = null;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.isInvalidBatteryAdHoc = false;

        init();

        function init() {
            getAllBatteryTestsAdHoc();
        }

        function getAllBatteryTestsAdHoc() {
            vm.isLoading = true;
            BatteryTest.getAllBatteryTestsAdHocBy(vm.patientId).then(function (response) {
                vm.haqBatteryAdHocs = response ? response : [];
                vm.isLoading = false;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.haqBatteryAdHoc.batteryTestId = vm.haqBatteryAdHocSeleted.id ? vm.haqBatteryAdHocSeleted.id : null;
            if (vm.haqBatteryAdHoc && vm.haqBatteryAdHoc.batteryTestId) {
                if (vm.haqBatteryAdHoc.id !== null) {
                    HAQBatteryAdHocService.update(vm.haqBatteryAdHoc, onSaveSuccess, onSaveError);
                } else {
                    HAQBatteryAdHocService.save(vm.haqBatteryAdHoc, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            // var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status !== 200) {
                $uibModalInstance.close(error);
            }
        }

        vm.selectTextOnChange = function () {
            if (!vm.haqBatteryAdHocSeleted || !vm.haqBatteryAdHocSeleted.test || vm.haqBatteryAdHocSeleted.test.countSections < 1) {
                vm.isInvalidBatteryAdHoc = true;
            } else {
                vm.isInvalidBatteryAdHoc = false;
            }
        };
    }
})();
