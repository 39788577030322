(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('BatteryTest', BatteryTest);

    BatteryTest.$inject = ['$resource', '$q', '$http'];

    function BatteryTest ($resource, $q, $http) {
        var baseUrl = 'api/battery-tests';

        var resourceUrl =  'api/battery-tests/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        service.getAllBatteryTests = getAllBatteryTests;
        service.getAllBatteryTestsAdHoc = getAllBatteryTestsAdHoc;
        service.getAllBatteryTestsAdHocBy = getAllBatteryTestsAdHocBy;

        function getAllBatteryTests() {
            var deferred = $q.defer();
            var url = baseUrl;
            $http.get(url)
                .success(function (result) {
                    deferred.resolve(result);
                }).error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getAllBatteryTestsAdHoc() {
            var deferred = $q.defer();
            var url = baseUrl + '/get-all-adhoc';
            $http.get(url)
                .success(function (result) {
                    deferred.resolve(result);
                }).error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getAllBatteryTestsAdHocBy(patientId) {
            var deferred = $q.defer();
            var url = baseUrl + '/get-all-adhoc-by/' + patientId;
            $http.get(url)
                .success(function (result) {
                    deferred.resolve(result);
                }).error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        return service;
    }
})();
