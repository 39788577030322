(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CarePlanReviewWorkflowController', CarePlanReviewWorkflowController);

    CarePlanReviewWorkflowController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'cprEntity', 'CarePlanReviewWorkflowService', 'ClearLocalStorageService', 'ipmConstants', '$interval', '$localStorage', '$timeout', '$uibModal', '$uibModalStack', '$window', 'Principal', '$filter', 'DateUtils', 'WebWorkerService'];

    function CarePlanReviewWorkflowController($rootScope, $scope, $state, Auth, cprEntity, CarePlanReviewWorkflowService, ClearLocalStorageService, constants, $interval, $localStorage, $timeout, $uibModal, $uibModalStack, $window, Principal, $filter, DateUtils, WebWorkerService) {
        var vm = this;
        vm.isSaving = false;
        vm.submit = submit;
        vm.cprEntity = cprEntity;
        vm.timeSpentChange = timeSpentChange;
        vm.saveAndLater = saveAndLater;
        vm.duration = 0; // used to calc display timeSpent
        vm.isShowSignaturePad = true;
        vm.isInvalidTimespentToSubmit = isInvalidTimespentToSubmit;
        vm.isInvalidTimespent = true; // used for faster checking disable submit button
        vm.getCssTimeSpent = getCssTimeSpent;
        vm.isOpenningErrorDialog = false;

        var CONSERVATIVE_CARE = constants.STATUS_INDICATORS[0];
        var INTERVENTIONS = constants.STATUS_INDICATORS[1];
        var EVALUATION_MDE = constants.STATUS_INDICATORS[2];
        var PAIN_PROGRAM = constants.STATUS_INDICATORS[3];

        // auto save and logs people out after 15 mins.  
        vm.minutesLogoffTimer = 15;
        vm.secondsToSectionTimeout = 30;
        vm.remainingSecondsToSectionTimeout = 30;

        vm.statusIndicators = [{ value:  CONSERVATIVE_CARE}, { value:  INTERVENTIONS}, { value:  EVALUATION_MDE}, { value:  PAIN_PROGRAM}];
        vm.isDislayTimer = true;
        vm.submittedCPR = false;
        vm.invalidOpenVisitDateChange = false;
        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.isOpenVisitDate = false;
        vm.datePickerOptions = {
            maxDate: new Date()
        };
        vm.deletedCases = [];
        vm.signatureData = null;
        vm.isShowPatientCaseError = false;
        vm.patientCasesExists = [];

        vm.openVisitDateChange = openVisitDateChange;
        vm.checkInValidBillTo = checkInValidBillTo;

        init();

        function init() {
            initWorkerServiceCPR();

            if (vm.cprEntity) {
                if (vm.cprEntity.adHoc) { // update for addHoc
                    vm.isShowSignaturePad = (vm.cprEntity.signatureAdHoc && vm.cprEntity.signatureAdHoc.data) ? false : true;
                    vm.signatureData = vm.cprEntity.signatureAdHoc && vm.cprEntity.signatureAdHoc.data ? vm.cprEntity.signatureAdHoc.data : null;
                } else {
                    vm.isShowSignaturePad = (vm.cprEntity.signatureId && vm.cprEntity.signatureData) ? false : true
                    vm.signatureData = vm.cprEntity.signatureData;
                }
            }
            vm.submittedCPR = false;
            vm.isOpenningErrorDialog = false;
            var dataCPR = ClearLocalStorageService.getPatientIdForCPRWorkflow();
            vm.patientId = dataCPR ? dataCPR.patientId : null;
            ClearLocalStorageService.setTimeCPRLogin(moment());
            if (!ClearLocalStorageService.getTimeSpentCPR()) {
                ClearLocalStorageService.setTimeSpentCPR(moment()); // set now
                startRunTimeSpent(); // start the first time will set timeSpentLogin = now
            } else {
                updateTimeSpent(constants.TIME_SPENT_TYPE.UPDATE_TIMESPENT, true); // page reload, update timeSpent
            }

            setDuration();
            vm.mapSectionAnswers = new Map();

            if (vm.cprEntity.values) {
                vm.mapSectionValues = new Map(Object.entries(vm.cprEntity.values));
            } else {
                vm.mapSectionValues = new Map();
            }

            vm.allAnswers = new Map(); // to check display question
            _.forEach(vm.cprEntity.sections, function(section) {
                _.forEach(section.answers, function(value, key) {
                    vm.allAnswers.set(key, value);
                })
            });

            vm.allPreviousAnswers = new Map(); // to check display question
            if (vm.cprEntity.previous) {
                _.forEach(vm.cprEntity.previous.sections, function(section) {
                    _.forEach(section.answers, function(value, key) {
                        vm.allPreviousAnswers.set(key, value);
                    })
                });
            }
            if (vm.cprEntity.previous) {
                var previousValues =  new Map(Object.entries(vm.cprEntity.previous.values));
                vm.surgicalCandidacyStatus = previousValues.get(constants.CPR_MAP_KEY_VALUES[1]);
            }


            vm.getCssTimeSpent(vm.duration);
            setLastOfficeVisitDate();
        }

        function setLastOfficeVisitDate() {
            if (!vm.cprEntity.lastOfficeVisitDate) {
                vm.cprEntity.lastOfficeVisitDate = new Date();
            } else {
                vm.cprEntity.lastOfficeVisitDate = DateUtils.convertLocalDateFromServer(vm.cprEntity.lastOfficeVisitDate);
            }
        }

        function openVisitDateChange(formField) {
            if (vm.cprEntity.lastOfficeVisitDate) {
                setValidityLastOfficeVisitDate(formField);
            }
        }

        function filterDate(dateValue) {
            if (dateValue) {
                return $filter('date')(dateValue, vm.dateFormatToServer);
            }
        }

        function setValidityLastOfficeVisitDate(formField) {
            vm.invalidOpenVisitDateChange = moment(vm.cprEntity.lastOfficeVisitDate).isAfter(new moment());
            formField.$setValidity('errorMaxDate', !vm.invalidOpenVisitDateChange);
        }

        function resetLogoffInterval() {
            vm.remainingSecondsToSectionTimeout = vm.secondsToSectionTimeout; // update remainingSeconds to default
            WebWorkerService.resetLogoffInterval();
            WebWorkerService.resetNoticingLogoffInterval();
            ClearLocalStorageService.setTimeCPRLogin(moment());
        }

        function timeSpentChange() {
            vm.duration = vm.cprEntity && vm.cprEntity.timeSpent > vm.duration ? vm.cprEntity.timeSpent : vm.duration;
            vm.duration += 1;
            vm.isInvalidTimespent = isInvalidTimespentToSubmit();
            $scope.$digest();
        }

        function sectionTimeoutDiaglogInterval() {
            vm.openSectionTimeoutDiaglog();
        }
        
        vm.handleSectionAnswerChange = function(data) {
            if (data) {
                resetLogoffInterval();
                var convertMapAnswer = Object.fromEntries(data.answer);
                vm.mapSectionAnswers.set(data.section.id, {id: data.section.id, value: JSON.stringify(convertMapAnswer)});

                _.forEach(convertMapAnswer, function(value, key) {
                    vm.allAnswers.set(key, value);
                })
            }
        }

        vm.handleSectionValuesChange = function(data) {
            if (data) {
                resetLogoffInterval();
                vm.mapSectionValues.set(data.key, data.value);
            }
        }

        vm.onChangeMouseMove = function (position) {
            if (position)
                resetLogoffInterval();
        };

        function clearShowPatientCaseError() {
            vm.isShowPatientCaseError = false;
        }
        function saveAndLater() {
            vm.submit(false);
        }

        function submit(completeAll) {
            if (completeAll === true) {
                checkValidationAndSubmitCPR(completeAll);
            } else {
                submitCPR(completeAll);
            }
            clearShowPatientCaseError();
        }

        function checkValidationAndSubmitCPR(completeAll) {
            vm.isShowPatientCaseError = false;
            vm.patientCasesExists = [];
            var cprWorkflowRequest = {
                id: cprEntity.cprId,
                cases: cprEntity.cases
            };
            CarePlanReviewWorkflowService.checkValidationCPRCase(cprWorkflowRequest).then(function (res) {
                if (res && res.length > 0) {
                    // Handle validation errors or show an error message
                    // showErrorCheckValidationCprCase();
                    vm.isShowPatientCaseError = true;
                    vm.patientCasesExists = res;
                } else {
                    vm.submittedCPR = true;
                    submitCPR(completeAll);
                    vm.isShowPatientCaseError = false;
                }
            });
        }

        function submitCPR(completeAll) {
            if (completeAll && vm.isInvalidTimespentToSubmit()) { // check time-spent > 31m
                return;
            }
            clearIntervalWorkerCPR();
            // push data to server
            pushDataToServer(completeAll, false);
        }

        function pushDataToServer(completeAll, autoSave) {
            if (vm.submittedCPR === true && completeAll === false) { // return if CPR has submitted && completeAll is false
                return;
            }
            if (vm.isSaving === true && completeAll === false && autoSave !== null) { // only check with autoSave without submitted, ignore logout
                return;
            }
            vm.isSaving = true;

            var listSection = [];
            angular.forEach(vm.mapSectionAnswers, function(value, key) {
                listSection.push({ id: value.id, value: value.value });
            });

            var convertMapValues = Object.fromEntries(vm.mapSectionValues);

            var dataRequest = {
                completeAll: completeAll,
                patientId: vm.patientId,
                values: JSON.stringify(convertMapValues),
                cprId: vm.cprEntity.cprId,
                sections: listSection,
                openVisitDate: filterDate(vm.cprEntity.lastOfficeVisitDate),
                signatureData: vm.signatureString,
                timeSpentData: { patientId: vm.patientId,
                    timeSpent: vm.duration,
                    timeSpentType: constants.TIME_SPENT_TYPE.UPDATE_TIMESPENT },
                cases: vm.cprEntity.cases,
                deletedCases: vm.deletedCases,
                evaluatedForMDE: vm.cprEntity.evaluatedForMDE,
                evaluatedForPS: vm.cprEntity.evaluatedForPS
            };
            CarePlanReviewWorkflowService.submit(dataRequest).then(function(res) {
                vm.isSaving = false;
                if (autoSave) {
                    ClearLocalStorageService.setTimeSpentCPR(moment()); // set now
                    if (res && res.data && res.data.timeSpent) {
                        vm.cprEntity.timeSpent = res.data.timeSpent;
                        vm.duration = res.data.timeSpent;

                        vm.cprEntity.cases = res.data.cases;
                        vm.deletedCases = []; // rest deletedCases
                        updateCprCases();
                    }
                } else {
                    if(autoSave === undefined || autoSave === null) {
                        logout('Logoff'); // logoff 15 minute interval
                    } else {
                        goBackToList();
                    }
                }
                }, function error(e) {
                    vm.isSaving = false;
                    clearAndShowOpenErrorSubmitDialog()
                }
            );
        }

        function clearAndShowOpenErrorSubmitDialog() {
            if (!vm.isOpenningErrorDialog) {
                // timerStop();
                clearWorkerServiceCPR();
                openErrorSubmitDialog();
                vm.isOpenningErrorDialog = true;
            }
        }

        function openErrorSubmitDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.qhpWorkflow.error.submitCprError.message"},
                    title: function () {return "ipmApp.qhpWorkflow.error.submitCprError.title"}
                }
            }).result.then(function() {
                logout('ErrorSubmit');
            }, function() {
               // logout();
            });
        }

        vm.back = function() {
            if (vm.submittedCPR === true) {
                goBackToList();
            }
            var requestData = { patientId: vm.patientId};
            CarePlanReviewWorkflowService.updateTimeSpent(requestData).then(function() {
                goBackToList();
            });
        }

        function goBackToList() {
            $state.go('care-plan-review');
        }

        vm.onChangeSignature = function(data) {
            resetLogoffInterval();
            vm.signatureString = data;
        }

        function clearWorkerServiceCPR() {
            WebWorkerService.close();
        }

        function clearIntervalWorkerCPR() {
            WebWorkerService.clearLogoffInterval();
            WebWorkerService.clearNoticingLogoffInterval();
        }

        function autoSaveCprToServe() {
            if (document.hidden) {
                setDuration();
            }
            pushDataToServer(false, true);
        }

        function initWorkerServiceCPR() {
            WebWorkerService.activate();
            WebWorkerService.startTimeSpent();
            // startLogoffInterval
            WebWorkerService.startLogoffInterval();
            WebWorkerService.startNoticingLogoffInterval();
            WebWorkerService.getWebWorker().onmessage = function (event) {
                if (event.data == constants.WEB_WORKER.START) {
                    autoSaveCprToServe();
                } else if (event.data == constants.WEB_WORKER.START_TIME_SPENT) {
                    timeSpentChange();
                } else if (event.data == constants.WEB_WORKER.START_LOGOFF_INTERVAL) {
                    logoff();
                } else if (event.data == constants.WEB_WORKER.START_NOTICING_LOGOFF_INTERVAL) {
                    sectionTimeoutDiaglogInterval();
                }
            };
            WebWorkerService.getWebWorker().addEventListener('error', function(event) {
                console.log('CPR Worker is offline');
                openErrorClearCacheDialog();
            });
        }

        vm.openCalendar = function() {
            vm.isOpenVisitDate = !vm.isOpenVisitDate;
        };

        // clear interval
        $scope.$on('$destroy', function iVeBeenDismissed() {
            $uibModalStack.dismissAll(); // close dialog NoticingLogoffInterval when auto logout
            clearIntervalWorkerCPR();
            clearWorkerServiceCPR();
            // clear localstorage
            ClearLocalStorageService.clearPatientIdForCPRWorkflow();
            ClearLocalStorageService.clearTimeCPRLogin();
            clearDataLocalStorage();
            // timerStop();
            // document.removeEventListener('visibilitychange', handleVisibilityChange);
            Principal.identity(false).then(function (account) {
                if (account) {
                    updateTimeSpent(constants.TIME_SPENT_TYPE.UPDATE_AND_RESET_TIMESPENT_LOGIN);
                }
            });
            clearCprQueue();
          });

        vm.getCssStatusIndicator = function(statusIndicator) {
            /**
             * [IPMproject-Q335]
             *      Conservative Care: code: CPR_013 -> YES
             *      Interventions: code CPR_018 -> NO
             *      Evaluation (MDE): code CPR_015 -> YES OR code CPR_038 -> MDE checked
             *      Pain Program: when the QHP enters 1 in Sessions Completed of the Treatment History table for FRP, or 'Authorized' in the status for FRP in the Treatment History table.
             */

            var css = 'status-indicator';
            var cssActive = 'status-indicator-active';
            if (statusIndicator.value === CONSERVATIVE_CARE) {
                if (vm.allAnswers.get('CPR_013') === 'YES') {
                    return cssActive;
                }
            }
            if (statusIndicator.value === INTERVENTIONS) {
                if (vm.allAnswers.get('CPR_018') === 'NO') {
                    return cssActive;
                }
            }
            if (statusIndicator.value === EVALUATION_MDE) {
                if (vm.allAnswers.get('CPR_015') === 'YES') {
                    return cssActive;
                }
                var a = vm.allAnswers.get('CPR_038');
                if (a) {
                    if (!_.isEmpty(_.filter(a, function(b) { return b.value === 'MDE'}))) {
                        return cssActive;
                    }
                }
            }
            if (statusIndicator.value === PAIN_PROGRAM) {
                var a = vm.mapSectionValues.get(constants.CPR_MAP_KEY_VALUES[0])
                if (a) {
                    var z = _.head(_.filter(a, function(b) { return b.treatment === 'FRP'}));
                    if (z) {
                        if (z.sessionsCompleted === "1" || z.status === 'authSentNoResponse') {
                            return cssActive;
                        }
                    }
                }
            }
            return css;
        }

        function getCssTimeSpent(param) {
            var totalSeconds = param ? param : vm.duration;
            var secondWarning = 1860; // 31 minute
            var secondSuccess = 2100; // 35 minute
            if (totalSeconds) {
                if (totalSeconds < secondWarning) {
                    vm.timeSpentClass = 'text-warning';
                } else if (totalSeconds >= secondWarning && totalSeconds < secondSuccess) {
                    vm.timeSpentClass = 'text-success';
                } else {
                    vm.timeSpentClass = 'text-danger';
                }
            }
            return vm.timeSpentClass;
        }

        function isInvalidTimespentToSubmit() {
            return vm.duration < vm.cprEntity.configuration.timerHasElapsed * 60;
        }

        vm.isDisableSaveAndLater = function(form) {
            var errorCount = 0;
            for (var key in form.$error) {
                errorCount += 1;
            }
            if (errorCount > 1) {
                if (form.$error.required && form.$error.minimumValue) {
                    return false;
                }
                return true;
            }
            if (errorCount === 1 && form.$error) {
                if (form.$error.required || form.$error.minimumValue) {
                    return false;
                }
            }
            return errorCount > 0 ? true : false;
        }

        vm.notHaveSignature = function() {
            if (vm.cprEntity && vm.cprEntity.addHoc) {
                return ((vm.cprEntity.signatureAdHoc && vm.cprEntity.signatureAdHoc.data && !vm.isShowSignaturePad) || vm.signatureString) ? false : true;
            }
            return ((vm.cprEntity.signatureId && vm.cprEntity.signatureData && !vm.isShowSignaturePad) || vm.signatureString) ? false : true;
        }

        vm.downloadLastedCPR = function() {
            CarePlanReviewWorkflowService.downloadLastedCPR(vm.cprEntity.previous.cprId).then();
        }

        vm.isShowTimeSpentErrorMessage = function() {
            return vm.isInvalidTimespentToSubmit();
        }

        function setDuration() {
            vm.isDislayTimer = false;
            vm.duration = vm.cprEntity.timeSpent ? vm.cprEntity.timeSpent : 0;
            $timeout(function(){
                vm.isDislayTimer = true;
            }, 500);
        }

        function startRunTimeSpent() {
            updateTimeSpent(constants.TIME_SPENT_TYPE.START_RUN_TIMESPENT);
        }

        function resetTimeSpentLogin() {
            updateTimeSpent(constants.TIME_SPENT_TYPE.RESET_TIMESPENT_LOGIN);

        }

        function updateResetTimeSpentLoginAndLogout(type) {
            if (vm.submittedCPR === true) {
                logoutEvent(type + '-1');
            }
            var requestData = {
                patientId: vm.patientId,
                timeSpentType: constants.TIME_SPENT_TYPE.UPDATE_AND_RESET_TIMESPENT_LOGIN
            };
            CarePlanReviewWorkflowService.updateTimeSpent(requestData).then(function (res) {
                logoutEvent(type + '-2');
            }, function error(e) {
                logoutEvent(type + '-3');
            });
        }

        function clearCprQueue() {
            var requestData = {
                cprId: vm.cprEntity.cprId,
                patientId: vm.patientId
            };
            CarePlanReviewWorkflowService.clearCprQueue(requestData).then(function (res) {
            });
        }

        function updateTimeSpent(timeSpentType, haveShowErrorDialog) {
            if (vm.submittedCPR === true) {
                return;
            }
            var requestData = {
                cprId: vm.cprEntity.cprId,
                patientId: vm.patientId,
                timeSpent: vm.duration,
                timeSpentType: timeSpentType ? timeSpentType : constants.TIME_SPENT_TYPE.UPDATE_AND_RESET_TIMESPENT_LOGIN
            };
            CarePlanReviewWorkflowService.updateTimeSpent(requestData).then(function (res) {
                if (res && res.data) {
                    vm.duration = Number(res.data);
                }
            }, function error(e) {
                if (haveShowErrorDialog && haveShowErrorDialog === true) {
                    clearAndShowOpenErrorSubmitDialog()
                }
            });
        }

        // function calcSecondsTimeSpent() {
        //     var now = moment();
        //     var dateTimeStartCPR = ClearLocalStorageService.getTimeSpentCPR();
        //     if (dateTimeStartCPR) {
        //         // fix issue which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged
        //         var duration = moment.duration(now.diff(moment(new Date(dateTimeStartCPR))));
        //         var durationSeconds = parseInt(duration.asSeconds());
        //         return vm.cprEntity.timeSpent && vm.cprEntity.timeSpent > 0
        //             ? vm.cprEntity.timeSpent + Number(durationSeconds) : durationSeconds;
        //     }
        //     return vm.cprEntity.timeSpent ? vm.cprEntity.timeSpent : 0;
        // }

        function logoff() {
            if (document.hidden) {
                setDuration();
            }
            pushDataToServer(false, null);
            // resetTimeSpentLogin();
        }

        function logout (type) {
            updateResetTimeSpentLoginAndLogout(type)
            // resetTimeSpentLogin();
            // Auth.logout();
            // $state.go('home');
            // clearDataLocalStorage();
            // timerStop();
            // $rootScope.$broadcast(constants.EVT_LOG_OUT);
        }

        function logoutEvent(typeLogout) {
            var requestData = {
                cprId: vm.cprEntity.cprId,
                typeLogout: typeLogout
            };
            CarePlanReviewWorkflowService.trackLogsCpr(requestData).then(function (res) {});
            Auth.logout();
            clearDataLocalStorage();
            clearWorkerServiceCPR();
            $state.go('home');
            $rootScope.$broadcast(constants.EVT_LOG_OUT);
        }

        function clearDataLocalStorage() {
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            ClearLocalStorageService.clearAll();
            ClearLocalStorageService.clearLandingPage();
            ClearLocalStorageService.clearKeepLandingPage();
            ClearLocalStorageService.clearTimeSpent();
            ClearLocalStorageService.clearTimerHasElapsed();
            ClearLocalStorageService.clearTimeSpentCPR();
            ClearLocalStorageService.clearInitDataCPRWorkflow();
        }

        function checkInValidBillTo() {
            var hasChecked = vm.cprEntity.cases.some(function (caseItem) {
                return caseItem && caseItem.billTo;
            });

            var isValid = vm.cprEntity.cases.every(function (caseItem) {
                return caseItem && caseItem.caseNumber; // Check for valid caseNumber
            });
            return !hasChecked || !isValid;
        }

        vm.skipReview = function() {
            $uibModal.open({
                templateUrl: 'app/qhp-workflow/care-plan-review-workflow/skip-review-dialog/skip-dialog.html',
                controller: 'skipDialogController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message confirm-dialog',
                size: 'sm',
                resolve: {
                    patientId: function() {
                        return vm.patientId;
                    }
                }
            })
        }

        vm.openSectionTimeoutDiaglog = function() {
            var isModalOpen = $uibModalStack.getTop() !== undefined;
            if (isModalOpen) {
                $uibModalStack.dismissAll();
            }
            $uibModal.open({
                templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-timeout-dialog/section-timeout-dialog.html',
                controller: 'sectionTimeoutDialogController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message confirm-dialog',
                size: 'sm',
                backdrop: 'static',
                resolve: {
                    remainingSeconds: function() {
                        return vm.remainingSecondsToSectionTimeout;
                    }
                }
            }).result.then(function(isStayLoggedIn) {
                if (!isStayLoggedIn) {
                    logoutEvent('SectionTimeout' + '-4');
                } else {
                    resetLogoffInterval();
                }
            });
            $window.setTimeout(function () {
                $uibModalStack.dismissAll();
            }, vm.remainingSecondsToSectionTimeout * 1000)
            
        }

        function updateCprCases() {
            if (vm.cprEntity.cases) {
                _.forEach(vm.cprEntity.cases, function (item) {
                    item.datePickerOpenStatus = {};
                    item.doi = item.doi ? DateUtils.convertDateTimeFromServer(item.doi) : null;
                });
            }
        }

        function openErrorClearCacheDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.qhpWorkflow.error.clearCache.message"},
                    title: function () {return "ipmApp.qhpWorkflow.error.title"}
                }
            }).result.then(function() {
                goBackToList();
            }, function() {
            });
        }
    }
})();
