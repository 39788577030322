(function() {
    'use strict';

    MultipleColumnCheckboxController.$inject = [];

    function MultipleColumnCheckboxController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            vm.answers = vm.config.answers;
            vm.code = vm.config.question.code;
            vm.keyOtherMultipleCheckboxColumnOnEvaluationStoreage = vm.code + '-otherMultipleCheckboxColumn';
            vm.ortherCheckboxExcludeIds = [];

            _.forEach(vm.config.question.questionItems, function (q) {
                vm.ortherCheckboxExcludeIds.push(q.id);
            });
            
            // get other checkbox from evaluation store if have selected
            var a = vm.evaluationStorage.get(vm.keyOtherMultipleCheckboxColumnOnEvaluationStoreage) 
            var z = vm.answers.get(vm.config.question.code);
            if (a) {
                _.forEach(a, function (b) {
                    var c = _.find(vm.config.question.questionItems, function(d) { return b.id === d.id; });
                    if (!c) {
                        vm.config.question.questionItems.push(b);
                    }
                    // handle case change parent answer, remove other checkbox selected
                    if (!z) {
                        _.remove(vm.config.question.questionItems, function(d) { return b.id === d.id; });
                    }
                });
            }
        }

        vm.handleItemCheckboxChange = function(data) {
            if (!vm.answers.get(vm.code)) {
                vm.answers.set(vm.code, new Map());
            }
            var a = vm.answers.get(vm.code)
            if (a) {
                a = new Map(Object.entries(a)); // convert back to Map
                if (!a.get(data.column)) {
                    a.set(data.column, []);
                }
                var b = a.get(data.column);
                if (b) {
                    if (data.checked) {
                        b.push({ name: data.checkboxValue.name, value: data.checkboxValue.value })
                    } else {
                        _.remove(b, function(n) {
                            return n.name === data.checkboxValue.name;
                        });
                    }
                }
            }
            // clear another column
            _.forEach(vm.config.columns, function(c) {
                if (c.value !== data.column) {
                    var x = a.get(c.value);
                    if (x) {
                        _.remove(x, function(n) {
                            return n.name === data.checkboxValue.name;
                        });
                    } else {
                        a.set(c.value, []);
                    }
                }
            });
            vm.onAnswerChange({ data: { question: vm.config.question, value: Object.fromEntries(a) } }); // Object.fromEntries -> convert to submit map into server
        }

        vm.handleOrtherCheckboxSelected = function(item) {
            if (item) {
                vm.config.checkboxItems.push(item);
                vm.ortherCheckboxExcludeIds.push(item.id);

                // push into evaluationStorage
                if (vm.evaluationStorage) {
                    
                    var u = vm.evaluationStorage.get(vm.keyOtherMultipleCheckboxColumnOnEvaluationStoreage);
                    if (!u) {
                        u = [];
                        vm.evaluationStorage.set(vm.keyOtherMultipleCheckboxColumnOnEvaluationStoreage, u);
                    }
                    u.push(item);
                }
            }
        }
    }

    angular.module('ipmApp').component('multipleColumnCheckbox', {
        bindings: {
            config: '<',
            evaluationStorage: '<',
            onAnswerChange: '&'
        },
        templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/multiple-column-checkbox-question/multiple-column-checkbox.html',
        controller: MultipleColumnCheckboxController,
        controllerAs: 'vm'
    })
})();
