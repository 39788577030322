(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HAQBriefReviewWorkflowController', HAQBriefReviewWorkflowController);

    HAQBriefReviewWorkflowController.$inject = ['$scope', '$state', 'briefReviewEntity', 'HAQBriefReviewWorkflowService', '$timeout', 'briefReviewStorage', 'SurveyResultService', 'DataUtils', 'ClearLocalStorageService', 'ipmConstants', 'timeSpent', 'Principal'];

    function HAQBriefReviewWorkflowController($scope, $state, briefReviewEntity, HAQBriefReviewWorkflowService, $timeout, briefReviewStorage, SurveyResultService, DataUtils, ClearLocalStorageService, constants, timeSpent, Principal ) {
        var vm = this;
        vm.startTimeSpent = startTimeSpent;
        vm.stopTimeSpent = stopTimeSpent;
        vm.briefReviewEntity = briefReviewEntity;
        vm.haqTest = vm.briefReviewEntity.haqTest;
        vm.briefReviewResponse = vm.briefReviewEntity.briefReviewResponse;
        vm.submitAnswer = submitAnswer;
        vm.init = init;
        vm.downloadResultPdf = downloadResultPdf;
        vm.downloadResponsePdf = downloadResponsePdf;
        vm.timerSeconds = timeSpent ? timeSpent : 0;
        vm.timerTextClass = timerTextClass;
        vm.intervalTimeMillisecond = 1000 * 60 * 5; // 5 minutes

        vm.isSaving = false;

        function init() {
            ClearLocalStorageService.clearActionEvaluationWorkflow();
            if (vm.briefReviewResponse) {
                // update TimeSpent when reload, back or next.
                startTimeSpent();
                refreshTimeSpent();
            }
        }

        function navigationBatteryTestEvaluationWorkflow(haqEvaluation, evaluationAction) {
            var evaluationRequest = {
                haqEvaluation: haqEvaluation.haqEvaluationId,
                evaluationAction: evaluationAction
            };
            // ClearLocalStorageService.setActionEvaluationWorkflow(evaluationAction);
            HAQBriefReviewWorkflowService.navigationBatteryTestEvaluationWorkflow(evaluationRequest).then(function (res) {
                if (res) {
                    $state.go('haq-brief-review.workflow', {timestamp: new Date().getTime()});
                    return;
                }
            });
        }

        function submitAnswer(isNextAction) {
            vm.isSaving = true;
            var briefReviewRequest = {
                haqBriefReview: vm.briefReviewEntity.haqEvaluationId,
                briefReviewResponse: vm.briefReviewEntity.briefReviewResponse };

            HAQBriefReviewWorkflowService.sumbitBriefReview(briefReviewRequest).then(function(res) {
                if (isNextAction && res && vm.briefReviewEntity.navigationHaqEvaluation && vm.briefReviewEntity.navigationHaqEvaluation.navigationNext && vm.briefReviewEntity.navigationHaqEvaluation.nextBatteryTestId) { // next click
                    navigationBatteryTestEvaluationWorkflow(vm.briefReviewEntity, 'NEXT_BATTERY_TEST');
                    return;
                } else if (!isNextAction && res && vm.briefReviewEntity.navigationHaqEvaluation && vm.briefReviewEntity.navigationHaqEvaluation.navigationPrevious && vm.briefReviewEntity.navigationHaqEvaluation.previousBatteryTestId) { // previous click
                    navigationBatteryTestEvaluationWorkflow(vm.briefReviewEntity, 'PREVIOUS_BATTERY_TEST');
                    return;
                } else {
                    $state.go('haq-brief-review.submit');
                }
                $timeout(function(){
                    // re-init
                    vm.briefReviewEntity = res;
                    vm.haqTest = vm.briefReviewEntity.haqTest;
                    vm.briefReviewResponse = vm.briefReviewEntity.briefReviewResponse;
                    vm.isSaving = false;
                    // vm.init();
                }, 10);
            }, function errorSave(error) {
                onSaveError(error);
            });
        };

        function onSaveError(error) {
            if (error.status === 400) {
                // remove answer storage if submit already into server
                vm.briefReviewStorage.delete(vm.mainQuestion.code);
                vm.isStopTimer();
                stopTimeSpent();
                $state.go('home');
            }
        }

        vm.next = function(isFormInvalid) {
            if (isFormInvalid) {
                return;
            }

            if (!vm.isSaving) {
                vm.submitAnswer(true);
            }
        };

        vm.previous = function() {
            if (vm.isDisablePreviousButton()) {
                return;
            }
            if (!vm.isSaving) {
                vm.submitAnswer(false);
            }
        };

        vm.isStopTimer = function () {
            var timeSpent = ClearLocalStorageService.getTimeSpent();
            return !timeSpent;
        };

        function stopTimeSpent() {
            ClearLocalStorageService.clearTimeSpent();
        }

        function startTimeSpent() {
            var timeSpent = ClearLocalStorageService.getTimeSpent();
            if (!timeSpent) {
                ClearLocalStorageService.setTimeSpent();
            }
        }

        function downloadResultPdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResult(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function timerTextClass() {
            return DataUtils.timerTextClass(vm.timerSeconds);
        }

        function downloadResponsePdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResponse(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function refreshTimeSpent() {
            setTimeout(function () {
                updateTimeSpent(true, false);
            }, 500);
        }

        function updateTimeSpent(isRefresh, isReset) {
            isRefresh = isRefresh ? true : false;
            isReset = isReset ? true : false;

            HAQBriefReviewWorkflowService.updateTimeSpent(vm.briefReviewEntity.haqEvaluationId, true, isReset, isRefresh).then(function(res) {
                if (res && res != vm.timerSeconds) {
                    vm.stopTimeSpent();
                    vm.timerSeconds = res;
                    vm.startTimeSpent();
                }
            });
        }

        function isFirstQuestionOfTest() {
            return true; // always true
        }

        vm.onChangeTimer = function (data) {
        };

        vm.isDisablePreviousButton = function() {
            return isFirstQuestionOfTest() && vm.briefReviewEntity.navigationHaqEvaluation && vm.briefReviewEntity.navigationHaqEvaluation.firstBatteryTest ;
        };

        vm.isDisableNextButton = function() {
            return false;
        };

        vm.checkQuestionSkipCommon = function (evaluationTestScores) {
            var typeScore = evaluationTestScores.typeScore;
            var type = evaluationTestScores.type;
            if (typeScore !== 'HIGH_SCORES') {
                return false;
            }
            return type === vm.briefReviewEntity.questionTypeWorkflow;
        };

        // [start] intervalUpdateTimeSpent
        var intervalUpdateTimeSpent = null;
        var runIntervalUpdateTimeSpent = function () {
            intervalUpdateTimeSpent = setInterval(function () {
                if (!vm.isStopTimer()) {
                    updateTimeSpent(false, false);
                }
            }, vm.intervalTimeMillisecond);
        };
        runIntervalUpdateTimeSpent();
        // [end] intervalUpdateTimeSpent

        vm.init();

        $scope.$on('$destroy', function() {
            stopTimeSpent();
            clearInterval(intervalUpdateTimeSpent);
            ClearLocalStorageService.clearActionEvaluationWorkflow();
            Principal.identity(false).then(function (account) {
                if (account) {
                    updateTimeSpent(false, true); // reset timeSpentLogin
                }
            });
        });

    }
})();
