(function() {
    'use strict';


    CPRCasesTableController.$inject = ['ipmConstants', '$scope', '$uibModal', 'DateUtils', 'User', '$window'];

    function CPRCasesTableController(constants, $scope, $uibModal, DateUtils, User, $window) {
        var vm = this;
        vm.dateFormat = 'MM/dd/yyyy';
        vm.caseStatuses = constants.CASE_STATUSES;
        vm.workStatuses = constants.WORK_STATUSES;
        vm.selectedCaseIndex = null;
        vm.selectedIsNotBillable = false;

        vm.caseBillToOnChange = caseBillToOnChange;
        vm.getServiceExclusionReason = getServiceExclusionReason;
        init();

        function init() {
            if (!vm.cases || _.isEmpty(vm.cases)) {
                addNewCase();
            }
            _.forEach(vm.cases, function(item) {
                item.datePickerOpenStatus = {};
                item.doi = item.doi ? DateUtils.convertDateTimeFromServer(item.doi) : null;
            });

            setSelectedBillTo();
            checkSelectedIsNotBillable();
        }

        vm.openCalendar = function(caseItem, date) {
            caseItem.datePickerOpenStatus[date] = true;
        };

        vm.clearCaseRowData = function(caseItem, index) {

            $uibModal.open({
                templateUrl: 'app/components/dialog/common-question/common-question.modal.html',
                controller: 'CommonQuestionModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    tests: function () {
                        return {
                            title: 'ipmApp.qhpWorkflow.cases.removeModal.title',
                            question: 'ipmApp.qhpWorkflow.cases.removeModal.question',
                            actionConfirm: 'entity.action.ok',
                            actionCancel: 'entity.action.cancel'
                        };
                    }
                }
            }).result.then(function() {
                deletedCase(index);
            }, function() {
            });
        };

        function deletedCase(index) {
            var caseItem = vm.cases[index];
            if (caseItem.id || caseItem.patientCaseId) { // Deleted cases have an ID, or cases have a patientCase master
                caseItem.active = false;
                vm.deletedCases.push(caseItem);
            }
            if (caseItem.billTo === true) {
                vm.selectedCaseIndex = null; // reset selectedCaseIndex when deleted
            }
            vm.cases.splice(index, 1);

            setSelectedBillTo();
        }
        function addNewCase() {
            var newCaseItem = {};
            newCaseItem.billTo = false;
            newCaseItem.caseNumber = null;
            newCaseItem.doi = null;
            newCaseItem.caseStatus = null;
            newCaseItem.workStatus = null;
            newCaseItem.ptp = null;
            newCaseItem.carrier = null;
            newCaseItem.bodyParts = null;
            newCaseItem.active = true;
            newCaseItem.datePickerOpenStatus = {};
            newCaseItem.cprId = vm.cprId;
            vm.cases.push(newCaseItem);
        }
        
        vm.addNewCase = function() {
            addNewCase();
        };

        vm.handleCaseNumberOnChange = function (caseItem, $index) {
            var formControlId = 'caseNumber_' + $index;
            if (caseItem.caseNumber && vm.evaluationForm[formControlId].$name) {
                // Check in cases current ignoring the current item
                var existingCase = _.find(vm.cases, function (c) {
                    return c.caseNumber === caseItem.caseNumber && c !== caseItem;
                });

                if (existingCase) {
                    vm.evaluationForm[formControlId].$setValidity('existsValue', false);
                    return;
                }

                User.checkUniquePatientCase(caseItem.caseNumber).then(function (response) {
                    if (response && response === true) {
                        setValidityCaseNumber(vm.evaluationForm[formControlId], true);
                    } else {
                        setValidityCaseNumber(vm.evaluationForm[formControlId], false);
                    }
                }).catch(function (error) {
                    setValidityCaseNumber(vm.evaluationForm[formControlId], false);
                });
            } else {
                setValidityCaseNumber(vm.evaluationForm[formControlId], true);
            }
        };

        function setValidityCaseNumber(formControl, value) {
            if (formControl) {
                formControl.$setValidity('existsValue', value);
            }
        }

        function setSelectedBillTo() {
            var index = vm.cases.findIndex(function (caseItem) {
                return caseItem && caseItem.billTo;
            });
            if (index >= 0) { // update selected radio by index
                vm.selectedCaseIndex = index;
            }
        }

        function caseBillToOnChange() {
            angular.forEach(vm.cases, function (caseItem, i) {
                if (vm.selectedCaseIndex !== i) {
                    caseItem.billTo = false; // update other bill to uncheck
                }
            });
            vm.cases[vm.selectedCaseIndex].billTo = true;
            checkSelectedIsNotBillable();
        }

        function checkSelectedIsNotBillable() {
            var caseSelected = getCaseSelected();
            if (caseSelected && caseSelected.cprServiceExclusion) {
                vm.selectedIsNotBillable = true;
            } else {
                vm.selectedIsNotBillable = false;
            }
        }

        function getCaseSelected() {
            if (vm.selectedCaseIndex >= 0) {
                var caseSelected = vm.cases[vm.selectedCaseIndex];
                return caseSelected;
            }
            return null;
        }

        function getServiceExclusionReason() {
            var caseSelected = getCaseSelected();
            if (caseSelected) {
                return caseSelected.cprServiceExclusionReason;
            }
        }

    }

    angular.module('ipmApp').component('cprCasesTable', {
        bindings: {
            section: '<',
            caseItems: '<',
            onValueChange: '&',
            cases: '=',
            deletedCases: '=',
            cprId: '<'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/cases-table/cases-table.html',
        controller: CPRCasesTableController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
