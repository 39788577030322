(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('PatientImport', PatientImport);

    PatientImport.$inject = ['$resource'];

    function PatientImport ($resource) {
    }
})();
