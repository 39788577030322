(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('EnrollPatientDialogController', EnrollPatientDialogController);

    EnrollPatientDialogController.$inject = ['$uibModalInstance', 'frpProgramId', 'frpMdeService', '$q', 'frpStartDate'];

    function EnrollPatientDialogController($uibModalInstance, frpProgramId, frpMdeService, $q, frpStartDate) {
        var vm = this;
        vm.searchResultEle = null;
        vm.modalLoaded = modalLoaded;
        vm.frpProgramId = frpProgramId ? frpProgramId : null;
        vm.frpStartDate = frpStartDate ? frpStartDate : null;
        vm.clear = clear;
        vm.enrollPatient = enrollPatient;
        vm.patientSearchSelect = '';
        vm.searchPatient = searchPatient;
        vm.predicate ='pfm.id';

        init();

        function init() {
        }

        function modalLoaded() {
            vm.searchResultEle = angular.element("#searchEnrollPatientAutoComplete");
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function enrollPatient() {
            vm.isSaving = true;
            if (vm.patientSearchSelect && vm.patientSearchSelect.id) {
                frpMdeService.enrollPatientById(vm.patientSearchSelect.id, vm.frpStartDate).then(function(res) {
                    onSaveSuccess(res);
                }).catch(function (error) {
                    onSaveError(error);
                });
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            $uibModalInstance.close(error);
        }

        function searchPatient($item) {
            var inputData = $item ? $item : '';
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            var param = {
                patientId: null,
                frpProgramIds: vm.frpProgramId,
                startDate: null,
                query: inputData
            };
            return $q(function (resolve, reject) {
                frpMdeService.searchEnrollPatient(param, pageable).then(function (response) {
                    if (response.data && response.data.length) {
                        resolve(filterAutoCompletedPatientList(response.data));
                    }
                    resolve([]);
                },function () {
                    reject([]);
                });
            })
        }

        function filterAutoCompletedPatientList(data){
            var patients = _.compact(_.map(data, function(obj) {
                if (obj.patient){
                    return {
                        id: obj.id,
                        name: obj.patient.firstName + ' ' + obj.patient.lastName + " (" + obj.patient.patientId + ")"
                    }
                }
                return null;
            }));
            return patients;
        }

        function sort() {
            var  result = [vm.predicate + ',desc'];
            return result;
        }

        vm.selectPatientData = function($item) {
        }

        vm.checkDisabledEnroll = function () {
            if (vm.patientSearchSelect && vm.patientSearchSelect.id) {
                return false;
            }
            return true;
        }

    }
})();
