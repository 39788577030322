(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('medicationsComponent', medicationsComponent);

    medicationsComponent.$inject = [];

    function medicationsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '=',
                medicationGroups: '='                
            },
            link: linkFunc,
            controller: DirectiveMedicationsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/medications.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveMedicationsController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectiveMedicationsController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        function init() {
            if(vm.workflow.answer.haveEverStoppedMedications == 'YES') {
                if(vm.workflow.answer.medicationStoppedsResponse.length == 0) {
                    vm.workflow.answer.medicationStoppedsResponse.push({
                        "id":null, 
                        "discontinuedMedications":"",
                        "sideEffectReasonStopping":""
                    })
                }
            }
        };

        vm.datePickerOptions = {
            maxDate: new Date()
        };

        vm.removeMedicationStopped = function(index) {
            vm.workflow.answer.medicationStoppedsResponse.splice(index, 1);
        };

        vm.addMedicationStopped = function() {
            vm.workflow.answer.medicationStoppedsResponse.push({
                "id":null, 
                "discontinuedMedications":"",
                "sideEffectReasonStopping":""
            });
        };

        vm.haveEverStoppedMedicationsChanged = function() {
            if(vm.workflow.answer.haveEverStoppedMedications === 'YES') {
                if(vm.workflow.answer.medicationStoppedsResponse.length === 0) {
                    vm.workflow.answer.medicationStoppedsResponse.push({
                        "id":null, 
                        "discontinuedMedications":"",
                        "sideEffectReasonStopping":""
                    });
                }
            }
        };
        init();
    }
})();
