(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('User', User);

    User.$inject = ['$resource', 'DateUtils', '$q', 'halClient', '$http'];

    function User ($resource, DateUtils, $q, halClient, $http) {
        var baseUrl = 'api/users';
        var service = $resource('api/users/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateOfBirth = DateUtils.convertLocalDateFromServer(data.dateOfBirth);
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
                    return angular.toJson(data);
                }
            },
            'delete':{ method:'DELETE'}
        });

        service.getAllQHPUsersWithInstructorTask = getAllQHPUsersWithInstructorTask;
        service.getUserByCurrentLogin = getUserByCurrentLogin;
        service.getAllQHPUsers = getAllQHPUsers;
        service.getAllStaffUsers = getAllStaffUsers;
        service.getAllPatientCaseByUserId = getAllPatientCaseByUserId;
        service.checkUniquePatientCase = checkUniquePatientCase;
        service.updatePatientCases = updatePatientCases;
        service.getAzureLogoutUri = getAzureLogoutUri;

        function updatePatientCases(data, onSaveSuccess, onSaveError) {
            var deferred = $q.defer();
            var url = baseUrl + '/patient-cases';
            $http.put(url, data).success(function (result) {
                deferred.resolve(result);
                onSaveSuccess();
            }).error(function (error) {
                deferred.reject(error);
                onSaveError();
            });

            return deferred.promise;
        };

        function getAllQHPUsersWithInstructorTask() {
            var deferred = $q.defer();

            var url = baseUrl + '/qhp-instructor-users';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function getUserByCurrentLogin() {
            var deferred = $q.defer();

            var url = baseUrl + '/get-user-by-current-login';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function getAllQHPUsers() {
            var deferred = $q.defer();

            var url = baseUrl +'/qhp-users';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function getAllStaffUsers() {
            var deferred = $q.defer();

            var url = baseUrl +'/staff-users';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function getAllPatientCaseByUserId(userId) {
            var deferred = $q.defer();
            var url = baseUrl +'/get-all-patient-case-by-user-id/' + userId;
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function checkUniquePatientCase(caseName, patientCaseId) {
            var deferred = $q.defer();

            var url = baseUrl + '/check-unique-patient-case';
            $http.get(url,
                {
                    params: {caseName: caseName, patientCaseId: patientCaseId}
                })
                .success(function (data, status) {
                    if (status === 200) {
                        deferred.resolve(data);
                    } else {
                        deferred.resolve(null);
                    }
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        function getAzureLogoutUri() {
            var url = baseUrl + '/get-azure-logout-url';
            return $http.get(url).then(function (resp) {
                return resp ? resp.data : null;
            });
        }

        return service;
    }
})();
