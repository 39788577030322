(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqsBatteryEditorController', HaqsBatteryEditorController);

    HaqsBatteryEditorController.$inject = ['$stateParams', '$state', 'entity', '$window', 'HaqsBatteryService', '$uibModalInstance'];

    function HaqsBatteryEditorController($stateParams, $state, entity, $window, HaqsBatteryService, $uibModalInstance) {
        var vm = this;
        vm.haqsBattery = entity;
        vm.save = save;
        vm.isSaving = false;
        vm.clear = clear;
        vm.onchangeAssignedBatteryTests = onchangeAssignedBatteryTests;
        vm.testsOnChange = testsOnChange;
        vm.handlePdfDescription = handlePdfDescription;
        vm.batteryTestsDeleted = [];
        vm.errorBatteryIdExists = false;
        vm.batteryTests = [];
        vm.isStateDuplicate = false;
        init();

        function init() {
            if ($state.current && $state.current.name === 'haq-tracks.duplicate') {
                vm.isStateDuplicate = true;
            }
            if (vm.isStateDuplicate && vm.haqsBattery) { // duplicate
                vm.haqsBattery.nameEn = null;
                vm.haqsBattery.nameEs = null;
            }
            if (vm.haqsBattery.batteryTests) {
                vm.batteryTests = removeBatteryTestsInactive(vm.haqsBattery.batteryTests);
            }
        }

        function removeBatteryTestsInactive(batteryTests) {
            var batteryTestsActive = [];
            if (batteryTests) {
                batteryTestsActive = _.remove(batteryTests, function (n) {
                    return n.id && n.test && n.test.activated;
                });
            }
            return batteryTestsActive;
        }

        function save() {
            vm.isSaving = true;
            if (vm.haqsBattery) {
                if (vm.isStateDuplicate) { // duplicate
                    vm.haqsBattery.batteryTests = vm.batteryTests;
                    HaqsBatteryService.batteryDuplicate(vm.haqsBattery).then(function (res) {
                        onSaveSuccess(res);
                    }, function errorSave(error) {
                        onSaveError(error);
                    });
                } else { // save or update
                    if (vm.batteryTestsDeleted) {
                        vm.haqsBattery.batteryTests = _.concat(vm.batteryTests, vm.batteryTestsDeleted);
                    }
                    HaqsBatteryService.saveOrUpdate(vm.haqsBattery).then(function (res) {
                        onSaveSuccess(res);
                    }, function errorSave(error) {
                        onSaveError(error);
                    });
                }
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            vm.errorBatteryIdExists = true;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                $uibModalInstance.close(error);
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onchangeAssignedBatteryTests(batteryTests, batteryTestsDeleted) {
            vm.batteryTests = batteryTests ? batteryTests : [];
            vm.batteryTestsDeleted = batteryTestsDeleted ? batteryTestsDeleted : [];
        }

        function testsOnChange(test) {
            if (test && test.id) {
                $uibModalInstance.close(test); // close dialog, open editor test
            }
        }

        function handlePdfDescription(value, editForm) {
            if (editForm && editForm['pdfDescription'] && !editForm['pdfDescription'].$dirty) {
                editForm['pdfDescription'].$setDirty();
            }
            editForm['pdfDescription'].$setViewValue(value);
        }

    }
})();
