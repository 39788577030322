(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AgreementsSignatureController', AgreementsSignatureController);

    AgreementsSignatureController.$inject = ['$scope', 'AgreementsWorkflowService'];

    function AgreementsSignatureController($scope, AgreementsWorkflowService) {
        var vm = this;
        vm.agreements = [];
        vm.onChangeSignature = onChangeSignature;
        vm.save = save;
        vm.isSaving = false;
        init();

        function init() {
            var pageable = {
                page: 0,
                size: 10,
                sort: ['position,asc']
            };
            AgreementsWorkflowService.findAllByPatientId(null, pageable, null, ['INTERNAL', 'EXTERNAL_NO_DATE_SIGN']).then(function (response) {
                vm.agreements = response && response.data ? response.data : [];
            });
        }

        function onChangeSignature(data) {
            vm.agreement.patientSignature = data;
        }

        function save() {
            vm.isSaving = true;
            agreementsEmit();
        }

        function agreementsEmit() {
            vm.onChange({agreement: vm.agreement ? vm.agreement : null});
        }

    }

    angular.module('ipmApp').component('agreementsSignature', {
        bindings: {
            agreement: '<',
            onChange: '&'
        },
        templateUrl: 'app/agreements-workflow/agreements-signature/agreements-signature.html',
        controller: AgreementsSignatureController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
