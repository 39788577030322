(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientDialogController',PatientDialogController);

    PatientDialogController.$inject = ['$stateParams', '$uibModalInstance', '$translate','PatientService', 'Auth', 'entity', 'supportedDataList', 'ipmConstants', 'Principal'];

    function PatientDialogController ($stateParams, $uibModalInstance, $translate, PatientService, Auth, entity, supportedDataList, constants, Principal) {
        var vm = this;

        vm.authorities = ['ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_PATIENT', 'ROLE_MANAGER'];
        vm.clear = clear;
        vm.save = save;
        vm.disabledRole = null;
        vm.patient = entity;
        vm.errorEmailExists = null;
        vm.errorPatientCodeIdexists = null;
        vm.birthdayInvalid = null;
        vm.error = null;
        vm.patient.langKey = null;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateOfBirth = false;
        vm.maxDate = new Date();
        vm.account = null;
        vm.patientCases = [];
        vm.patientCaseIdsRemove = [];

        getAccount(false);

        function getAccount(force) {
            Principal.identity(force).then(function(res) {
                vm.account = res;
                updateAllowActionPatientCase();
            });
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function clear () {
            clearData();
            $uibModalInstance.dismiss('cancel');
        }

        function clearData() {
            vm.patient.phone = null;
            vm.patient.firstName = null;
            vm.patient.middleName = null;
            vm.patient.lastName = null;
            vm.patient.patientIdCode = null;
            vm.patient.password = null;
            vm.patient.email = null;
            vm.patient.phone = null;
            vm.patient.dateOfBirth = null;
            vm.patient.caseNo = null;
            vm.patient.ignoreSendSms = false;
            vm.patient.ignoreSendEmail = null;
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        vm.requirePhoneAndEmail = function() {
            return !vm.patient.phone && !vm.patient.email;
        }

        function onSaveError (error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if(error.status === 400 ) {
            	if (errorMessage === 'e-mail address already in use'){
            		vm.errorEmailExists = 'ERROR';
            	} else if (errorMessage === 'patientID already in use') {
            		vm.errorPatientCodeIdexists = 'ERROR';
            	} else {
            		vm.error = 'ERROR';
            	}
            }
        }

        function init() {
            vm.patient.sendRequest = true;
        }

        init();

        function save () {
        	vm.errorEmailExists = null;
            vm.errorPatientCodeIdexists = null;
            vm.error = null;
        	vm.birthdayInvalid = null;

            vm.isSaving = true;
            var currentDate = new Date();
            vm.patient.langKey = $translate.use();
            if(vm.patient.dateOfBirth > currentDate) {
                vm.birthdayInvalid = 'ERROR';
                vm.isSaving = false;
                return;
            }
            if (!vm.patient.id) {
                vm.patient.patientCases = vm.patientCases ? vm.patientCases : [];
                PatientService.savePatient(vm.patient).then(function(res) {
                    onSaveSuccess(res);
                    clearData();
                }, function errorSave(error) {
                    onSaveError(error);
                });
            }
        }

        function updateAllowActionPatientCase() {
            vm.isAllowActionPatientCase = vm.account && vm.account.authorities.indexOf('ROLE_ADMIN') !== -1;
        }

        vm.checkUpdateBillablePatientCase = function () {
            if (vm.account && vm.account.authorities) {
                var allowedRoles = [constants.ROLE_ADMIN, constants.ROLE_MANAGER, constants.ROLE_BILLER];
                var hasAllowedRole = _.some(vm.account.authorities, function (role) {
                    return _.includes(allowedRoles, role);
                });
                return !hasAllowedRole;
            }
            return true;
        };

        vm.changeIgnoreEmail = function() {
            vm.patient.ignoreSendEmail = vm.patient.ignoreSendEmail ? false : true;
        }

        vm.changeIgnoreSendSms = function() {
            vm.patient.ignoreSendSms = vm.patient.ignoreSendSms ? false : true;
        }
    }
})();
