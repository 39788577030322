(function () {
    "use strict";

    angular.module("ipmApp").controller("ErrorSSOController", ErrorSSOController);

    ErrorSSOController.$inject = ['$scope', 'logoutUri'];

    function ErrorSSOController($scope, logoutUri) {
        var vm = this;
        vm.logoutUri = logoutUri ? logoutUri : 'saml/logout';
    }
})();
