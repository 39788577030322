(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('CaseExclusionsService', CaseExclusionsService);

    CaseExclusionsService.$inject = ['$http', '$q', 'DownloadBlobService'];

    function CaseExclusionsService ($http, $q, DownloadBlobService) {
        var resourceUrl = "api/case-exclusions";

        var service = {
            'downloadCsvCaseExclusions': downloadCsvCaseExclusions,
            'requestDownloadCsvCaseExclusions': requestDownloadCsvCaseExclusions,
            'downloadReport': downloadReport
        };

        function downloadCsvCaseExclusions(fromDate, toDate, serviceFilters) {
            var deferred = $q.defer();
            var url = resourceUrl + '/download';
            var body = {fromDate: fromDate, toDate: toDate, serviceFilters: serviceFilters};
            $http.post(url, body).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function requestDownloadCsvCaseExclusions(fromDate, toDate, serviceFilters) {
            var deferred = $q.defer();
            var url = resourceUrl + '/request';
            var body = {fromDate: fromDate, toDate: toDate, serviceFilters: serviceFilters}
            $http.post(url, body)
            .success(function (response) {
                deferred.resolve(response);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function downloadReport(fileId) {
            var deferred = $q.defer();
            var url = 'api/case-exclusions/download-report/' + fileId;
            $http({
                url: url,
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        return service;
    }
})();
