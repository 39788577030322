(function() {
    'use strict';

    QuestionDescriptionBottom.$inject = [];

    function QuestionDescriptionBottom() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

        vm.isDisplayDescriptionBottom = function() {
            if (vm.questionCode === 'Q-43' || vm.questionCode === 'Q-65') {
                return true;
            }
            return false;
        }
    }

    angular.module('ipmApp').component('questionDescriptionBottom', {
        bindings: {
            questionCode: '<',
            translateKey: '<'
        },
        templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/question-description-bottom/question-description-bottom.html',
        controller: QuestionDescriptionBottom,
        controllerAs: 'vm'
    })
})();
