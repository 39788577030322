(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patientimport', {
            parent: 'entity',
            url: '/patientimport',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.patient.import.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/patient-import/patient-import.html',
                    controller: 'PatientImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
