(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('CarePlanReviewWorkflowService', CarePlanReviewWorkflowService);

    CarePlanReviewWorkflowService.$inject = ['$resource', '$http', '$q', 'DownloadBlobService'];

    function CarePlanReviewWorkflowService ($resource, $http, $q, DownloadBlobService) {
        var resourceUrl = "api/care-plan-review-workflow";
        var service = $resource(resourceUrl, {}, {});
        service.getData = getData;
        service.updateTimeSpent = updateTimeSpent;
        service.submit = submit;
        service.downloadLastedCPR = downloadLastedCPR;
        service.skipReviewCPR = skipReviewCPR;
        service.clearCprQueue = clearCprQueue;
        service.trackLogsCpr = trackLogsCpr;
        service.checkValidationCPRCase = checkValidationCPRCase;

        function getData(patientId, cprId, notInitialized) {
            var deferred = $q.defer();
            $http.get(resourceUrl + '/get-data', {params: {patientId: patientId,
                    cprId: cprId,
                    notInitialized: notInitialized}})
                .success(function (response) {
                    deferred.resolve(response);
                }).error(function (error, response) {
                    deferred.resolve(response);
                });
            return deferred.promise;
        }

        function updateTimeSpent(data) {
            return $http.post(resourceUrl + '/update-time-spent', data).then(function (response) {
                return response;
            });
        };

        function submit(data) {
            return $http.post(resourceUrl + '/submit', data).then(function (response) {
                return response;
            });
        };

        function clearCprQueue(data) {
            var url = resourceUrl + "/clear-cpr-queue";
            return $http.post(url, data).then(function (response) {
                return response;
            });
        }

        function trackLogsCpr(requestData) {
            var url = resourceUrl + "/track-logs-cpr";
            return $http.post(url, JSON.stringify(requestData)).then(function (response) {
                return response;
            });
        }

        function downloadLastedCPR(previousId) {
            var deferred = $q.defer();
            var url = 'api/care-plan-review/download/' + previousId;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentDisposition = headers('content-disposition');
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    var fileName = DownloadBlobService.getFileName(contentDisposition);
					saveAs(blob, fileName);
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function skipReviewCPR(data){
            return $http.post(resourceUrl + '/skip-review', data).then(function (response) {
                return response;
            });
        }

        function checkValidationCPRCase(cprCase) {
            var deferred = $q.defer();
            $http.post(resourceUrl + '/check-validation-cpr-case', cprCase)
                .success(function (response) {
                    deferred.resolve(response);
                }).error(function (error, response) {
                    deferred.resolve(response);
                });
            return deferred.promise;
        }

        return service;
    };
})();
