(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ScheduleXDayDialogController',ScheduleXDayDialogController);

        ScheduleXDayDialogController.$inject = ['$scope', '$uibModalInstance', 'courseEntity', 'ClassSessionService'];

    function ScheduleXDayDialogController($scope, $uibModalInstance, courseEntity, ClassSessionService) {
        var vm = this;
        vm.course = courseEntity;
        vm.min = 7;
        vm.max = 90;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.doGenerate = function () {
            var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            ClassSessionService.doAutoGenerate(vm.course.id, vm.numberOfDate, timeZone).then(function () {
                $uibModalInstance.close();
            });
        };
    }
})();
