(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('FrpProgramService', FrpProgramService);

        FrpProgramService.$inject = ['$resource','$q', 'halClient', '$http'];

    function FrpProgramService($resource, $q, halClient, $http) {
        var baseUrl = 'api/frp-programs';
        var service = {};

        service.findAll = findAll;

        function findAll() {
            var deferred = $q.defer();
            var url = baseUrl;
            $http.get(url).success(function (data) {
                deferred.resolve(data);
            }).error(function (err) {
                    deferred.reject(err);
                });
            return deferred.promise;
        }

        return service;
    }
})();
