(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('adhoc-haq-evaluation', {
            parent: 'app',
            url: '/adhoc-haq-evaluation',
            data: {
                authorities: ['ROLE_QHP', 'ROLE_ADMIN'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/adhoc-haq-evaluation/adhoc-haq-evaluation.html',
                    controller: 'AdhocHaqEvaluationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('carePlanReview');
                    $translatePartialLoader.addPart('carePlanReviewStatus');
                    $translatePartialLoader.addPart('haqEvaluation');
                    $translatePartialLoader.addPart('qhpWorkflow');
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('partyType');
                    $translatePartialLoader.addPart('downloadHaqEvaluation');
                    return $translate.refresh();
                }]
            }
        });

    }

})();
