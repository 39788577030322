(function() {
    'use strict';

    GoalRowController.$inject = [];

    function GoalRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            vm.dropdownValues = ['Sedentary', 'Light', 'Medium', 'Heavy', 'Very Heavy'];
        }

        vm.checkboxChange = function() {
            vm.item.inputValue = null;
            vm.item.selectedValue = null;
        }

        vm.addNewGoal = function() {
            if (vm.isDisabled) return;
            vm.onClickAddNewGoal();
        }
    }

    angular.module('ipmApp').component('goalRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&',
            onClickAddNewGoal: '&',
            showAddGoal: '<',
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/goal-row.html',
        styleUrls: 'app/frp-mde/mde-report/pt/components/goal-row.scss',
        controller: GoalRowController,
        controllerAs: 'vm'
    })
})();
