(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('WellnessQuestion', WellnessQuestion);

    WellnessQuestion.$inject = ['$resource', '$q', 'halClient', '$http'];

    function WellnessQuestion ($resource, $q, halClient, $http) {
        var resourceUrl =  'api/wellness-questions/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        service.downloadPatientFacingContent = downloadPatientFacingContent;

        function downloadPatientFacingContent(id) {
            var deferred = $q.defer();
            var url = 'api/wellness-questions/' + id + "/download";
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    saveAs(blob, headers('fileName'));
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        };

        return service;
    }
})();
