(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('LandingPageHAQBriefReviewController', LandingPageHAQBriefReviewController);

    LandingPageHAQBriefReviewController.$inject = ['$scope', '$state', 'briefReviewEntity', 'SurveyResultService', 'HAQBriefReviewWorkflowService', 'DataUtils', 'ClearLocalStorageService'];

    function LandingPageHAQBriefReviewController($scope, $state, briefReviewEntity, SurveyResultService, HAQBriefReviewWorkflowService, DataUtils, ClearLocalStorageService) {
        var vm = this;
        vm.briefReviewEntity = briefReviewEntity;
        vm.downloadResultPdf = downloadResultPdf;
        vm.downloadResponsePdf = downloadResponsePdf;
        vm.timerSeconds = vm.briefReviewEntity.timeSpent ? vm.briefReviewEntity.timeSpent : 0;
        vm.timerTextClass = timerTextClass;
        vm.isStopTimer = isStopTimer;
        vm.resetTimeLogin = resetTimeLogin;

        vm.typeScores = {
            highScores: 'HIGH_SCORES'
        };

        init();

        function init() {
            resetTimeLogin();
            ClearLocalStorageService.clearTimeSpent();
            if (!vm.briefReviewEntity.haqTest) {
                vm.noHaveHaqBriefReadyForReview = true;
                return;
            }
            if (vm.briefReviewEntity.status === 'UNDER_REVIEW' || vm.briefReviewEntity.briefReviewResponse || vm.briefReviewEntity.timeSpent > 0) {
                vm.isResume = true;
            }

        }

        vm.start = function () {
            if (vm.briefReviewEntity && vm.briefReviewEntity.haqEvaluationId) {
                if (!vm.isResume) { // start HAQ evaluation
                    HAQBriefReviewWorkflowService.startHaqEvaluation(vm.briefReviewEntity.haqEvaluationId).then(function (res) {
                        ClearLocalStorageService.setTimeSpent();
                        $state.go('haq-brief-review.workflow');
                    });
                } else {
                    HAQBriefReviewWorkflowService.updateTimeSpent(vm.briefReviewEntity.haqEvaluationId, false, false, false).then(function (res) {
                        ClearLocalStorageService.setTimeSpent();
                        $state.go('haq-brief-review.workflow');
                    });
                }
            }
        };

        function setIsResume(resume) {
            vm.isResume = resume ? resume : false;
        }

        function resetTimeLogin() {
            if (vm.briefReviewEntity && vm.briefReviewEntity.haqEvaluationId) {
                HAQBriefReviewWorkflowService.updateTimeSpent(vm.briefReviewEntity.haqEvaluationId, false, true, false).then(function (res) {
                    vm.timerSeconds = res ? res : 0;
                    if (vm.timerSeconds > 0) { // fix bug IPMproject-B1301, update Resume after update TimeSpent
                        setIsResume(true);
                    }
                });
            }
        }

        function isStopTimer() {
            var timeSpent = ClearLocalStorageService.getTimeSpent();
            return vm.isResume || !timeSpent;
        }

        function timerTextClass() {
            return DataUtils.timerTextClass(vm.timerSeconds);
        }

        function downloadResultPdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResult(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        function downloadResponsePdf(id) {
            vm.noPdf = false;
            SurveyResultService.downloadResponse(id).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }
    }
})();
