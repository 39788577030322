(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider
        .state('download-haq-evaluation', {
            parent: 'app',
            url: '/download-haq-evaluation',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'global.menu.entities.haqEvaluationResults'
            },
            views: {
                'content@': {
                    templateUrl: 'app/download-haq-evaluation/download-haq-evaluation.html',
                    controller: 'DownloadHaqEvaluationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadHaqEvaluation');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
        });
    }

})();
