(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('ActivityReportService', ActivityReportService);

    ActivityReportService.$inject = ['$http', '$q', 'DownloadBlobService'];

    function ActivityReportService ($http, $q, DownloadBlobService) {

        var service = {
            'downloadCsvHAQ': downloadCsvHAQ,
            'downloadCsvCPR': downloadCsvCPR,
            'requestDownloadCsvHAQ': requestDownloadCsvHAQ,
            'requestDownloadCsvCPR': requestDownloadCsvCPR,
            'downloadReport': downloadReport
        };

        function downloadCsvCPR(fromDate, toDate) {
            var deferred = $q.defer();
            $http({
                url: 'api/activity-report/cpr',
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadCsvHAQ(fromDate, toDate) {
            var deferred = $q.defer();
            $http({
                url: 'api/activity-report/haq',
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function requestDownloadCsvHAQ(fromDate, toDate) {
            var deferred = $q.defer();
            $http({
                url: 'api/activity-report/request-report-haq',
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate}
            }).success(function (response) {
                deferred.resolve(response);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function requestDownloadCsvCPR(fromDate, toDate) {
            var deferred = $q.defer();
            $http({
                url: 'api/activity-report/request-report-cpr',
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate}
            }).success(function (response) {
                deferred.resolve(response);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function downloadReport(fileId, serviceType) {
            var deferred = $q.defer();
            var url = 'api/activity-report/download-report/' + fileId;
            $http({
                url: url,
                method: "GET",
                params: {serviceType: serviceType},
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        return service;
    }
})();
