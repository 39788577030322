(function () {
    'use strict';

    angular
    .module('ipmApp')
    .controller('FRPReportPTPController', FRPReportPTPController);

    FRPReportPTPController.$inject = ['$scope', '$state', '$stateParams', 'frpReport', 'FrpReportService', '$uibModal', 'DataUtils', 'ipmConstants', 'isView', 'templates'];

    function FRPReportPTPController($scope, $state, $stateParams, frpReport, FrpReportService, $uibModal, DataUtils, constants, isView, templates) {
        var vm = this;
        vm.frpReport = frpReport ? frpReport : {};
        vm.isWeeklyReport = false;
		vm.isFinalize = false;
        vm.isChanged = isChanged;
        vm.isView = isView ? true : frpReport ? frpReport.statusFinal === constants.STATUS_FINAL_FRP_MDE : false;
		vm.cancelMessage = 'ipmApp.frpReport.admin.confirmCancel';
		vm.templates = templates ? templates : [];
		vm.templateInputType = constants.FRP_MDE_TEMPLATE;

        vm.dataHeaderReport = {};

        init();

        function init() {
            if (vm.frpReport && vm.frpReport.frpReportAdmin && vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.WEEKLY_REPORT) {
                vm.isWeeklyReport = true;
            }
            vm.frpReportId = $stateParams.id;
            FrpReportService.getFrpMedicalReport($stateParams.id).then(function (data) {
                vm.report = data;
                vm.reportOld = DataUtils.deepCopy(data);
				vm.isFinalize = vm.report && vm.report.status === 'COMPLETE' ? true : false;
            });
            if (vm.isView || (vm.frpReport.patientFRPMDE && (vm.frpReport.patientFRPMDE.status === 'MDE_ABANDONED' || frpReport.patientFRPMDE.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }

            vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        }

		vm.save = function() {
            submitData(false);
        }

        vm.finalize = function() {
            submitData(true);
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

		vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        function submitData(finalize) {
            vm.isSaving = true;
            vm.report.status = finalize ? 'COMPLETE' : 'INPROGRESS';
            FrpReportService.saveFrpMedicalReport(vm.frpReportId, vm.report).then(function (response) {
                onSaveSuccess(response);
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess(response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        function isChanged() {
            return JSON.stringify(vm.report) !== JSON.stringify(vm.reportOld);
        }

        function back() {
            $state.go('frp-report', {id: vm.frpReportId});
        }
    }
})();
