(function() {
    'use strict';

    FRPGoalRowController.$inject = [];

    function FRPGoalRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            vm.dropdownValues = ['Sedentary', 'Light', 'Medium', 'Heavy', 'Very Heavy'];
        }

        vm.checkboxChange = function() {
            vm.item.inputValue = null;
            vm.item.selectedValue = null;
            resetDefaultChecked();
        }

        vm.addNewGoal = function() {
            if (vm.isDisabled) return;
            vm.onClickAddNewGoal();
        }

        vm.selectGoalStatusOnchange = function(goalStatus) {
            if (!goalStatus) {
                vm.item.goalStatus = null;
            }
            if (goalStatus && goalStatus === 'INPROGRESS') {
                return;
            }
            resetGoalExplanation();
        };

        function resetDefaultChecked() {
            if (vm.item.checked) {
                if (!vm.item.goalStatus) {
                    vm.item.goalStatus = 'INPROGRESS';
                }
            } else {
                vm.item.checked = null;
            }
        }

        function resetGoalExplanation() {
            if (vm.item) {
                vm.item.goalExplanation = null;
            }
        }
    }

    angular.module('ipmApp').component('frpGoalRow', {
        bindings: {
            item: '<',
            type: '<',
            onChange: '&',
            onClickAddNewGoal: '&',
            showAddGoal: '<',
            isDisabled: '<',
            formReference: '<'
        },
        templateUrl: 'app/frp-mde/frp-report/conclusion/components/frp-goal-row.html',
        styleUrls: 'app/frp-mde/frp-report/conclusion/components/frp-goal-row.scss',
        controller: FRPGoalRowController,
        controllerAs: 'vm'
    })
})();
