(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('patientCaseTableController', patientCaseTableController);

    patientCaseTableController.$inject = ['$scope', '$uibModal'];

    function patientCaseTableController($scope, $uibModal) {
        var vm = this;
        vm.addPatientCase = addPatientCase;
        vm.patientCaseIdsRemove = vm.patientCaseIdsRemove ? vm.patientCaseIdsRemove : [];

        init();

        function init() {
            if (!_.isEmpty(vm.patientCases)) {
                vm.patientCases[0].isOpen = true;
            }
        }

        function addPatientCase(caseNumber) {
            if (!vm.patientCases) {
                vm.patientCases = [];
            }
            var patientCase = createNewPatientCase(caseNumber);
            patientCase.isOpen = true;
            vm.patientCases.push(patientCase);
        }

        function editPatientCase(indexPatientCase, caseNumber) {
            if (vm.patientCases) {
                vm.patientCases[indexPatientCase].caseNumber = caseNumber;
            }
        }

        function createNewPatientCase(caseNumber) {
            if (!caseNumber) {
                return null;
            }
            var billableConfig = {
                id: null,
                haqTestServiceExclusion: false,
                haqTestBillingExclusion: false,
                haqEvalServiceExclusion: false,
                haqEvalBillingExclusion: false,
                cprServiceExclusion: false,
                cprBillingExclusion: false,
                pneServiceExclusion: false,
                haqTestServiceExclusionReason: null,
                haqTestBillingExclusionReason: null,
                haqEvalServiceExclusionReason: null,
                haqEvalBillingExclusionReason: null,
                cprServiceExclusionReason: null,
                cprBillingExclusionReason: null,
                pneServiceExclusionReason: null,
                active: true
            };
            var patientCase = {
                id: null,
                caseNumber: caseNumber,
                active: true,
                billableConfig: billableConfig,
                patient: {
                    id: vm.patientId
                }
            };

            return patientCase;
        }

        vm.editCaseNumber = function (idx) {
            if (vm.patientCases) {
                var patientCase = vm.patientCases[idx];
                vm.openPatientCaseEdit(true, patientCase, idx);
            }
        };

        vm.removeCaseNumber = function (idx, event) {
            event.preventDefault();
            if (vm.patientCases) {
                var patientCase = vm.patientCases[idx];
                if (patientCase && patientCase.id) {
                    vm.patientCaseIdsRemove.push(patientCase.id);
                }
                vm.patientCases.splice(idx, 1);
            }
        };

        vm.openPatientCaseEdit = function (isEdit, patientCase, indexPatientCase) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/patient-case-table/patient-case-edit.html',
                controller: 'PatientCaseEditController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    isEdit: function () {
                        return isEdit;
                    },
                    patientCases: function () {
                        return vm.patientCases;
                    },
                    caseNumber: function () {
                        return patientCase && patientCase.caseNumber ? patientCase.caseNumber : '';
                    },
                    patientCaseId: function () {
                        return patientCase && patientCase.id ? patientCase.id : null;
                    }
                }
            }).result.then(function (caseNumber) {
                if (caseNumber) {
                    if (isEdit) {
                        editPatientCase(indexPatientCase, caseNumber);
                    } else {
                        addPatientCase(caseNumber);
                    }
                }
            });
        };
    }

    angular.module('ipmApp').component('patientCaseTable', {
        bindings: {
            isAllowAction: '<',
            isUpdateBillable: '<',
            patientId: '<',
            patientCases: '=',
            patientCaseIdsRemove: '='
        },
        templateUrl: 'app/admin/user-management/patient-case-table/patient-case-table.html',
        controller: patientCaseTableController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                return $translate.refresh();
            }]
        }
    });
})();
