(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SectionController', SectionController);

    SectionController.$inject = ['$scope', '$state', 'Section', 'SectionSearch', 'SectionFilter'];

    function SectionController ($scope, $state, Section, SectionSearch, SectionFilter) {
        var vm = this;
        vm.sections = [];        
        vm.loadAll = function() {
            SectionFilter.search({testId: 2},function(result) {
                vm.sections = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SectionSearch.query({query: vm.searchQuery}, function(result) {
                vm.sections = result;
            });
        };
        vm.loadAll();
        
    }

    angular.module('ipmApp').component('sectionList', {
        templateUrl: 'app/entities/section/sections.html',
        controller: SectionController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('section');
                $translatePartialLoader.addPart('questionType');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
})();
