(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QhpTasksController', QhpTasksController);

    QhpTasksController.$inject = ['$scope', '$filter', 'ipmConstants'];

    function QhpTasksController($scope, $filter, constants) {
        var vm = this;
        vm.tasksInit = [];
        vm.checkRequired = checkRequired;
        vm.isTaskCheckBoxDirty = false;
        init();

        function init() {
            if (vm.role && vm.role === constants.ROLE_STAFF) { // init task for ROLE_STAFF
                vm.tasksInit.push(createQHPTask(constants.FRP_MDE, false));
                vm.tasksInit.push(createQHPTask(constants.CONTINUITY_OF_CARE, false));
            } else if (vm.tasksInit.length === 0) { // default init task for ROLE_QHP
                vm.tasksInit.push(createQHPTask(constants.HAQ_EVALUATION, false));
                vm.tasksInit.push(createQHPTask(constants.HAQ_BRIEF_REVIEW, false));
                vm.tasksInit.push(createQHPTask(constants.CARE_PLAN_REVIEW, false));
                if (!constants.HIDE_WELLNESS_CLASSES) {
                    vm.tasksInit.push(createQHPTask(constants.INSTRUCTOR, false));
                }
                vm.tasksInit.push(createQHPTask(constants.FRP_MDE, false));
            }

            if (vm.tasks && vm.tasks.length > 0) {
                initTask();
            }

            onChangeCheckbox(); // init First Change
        }

        function createQHPTask(qhpTaskType, active) {
            return {type: qhpTaskType, active: active};
        }

        vm.checkboxChange = function () {
            vm.isTaskCheckBoxDirty = true;
            onChangeCheckbox();
        }

        function checkRequired() {
            return vm.tasks && vm.tasks.length > 0;
        }

        function initTask() {
            if (vm.tasksInit.length > 0) {
                vm.tasksInit.forEach(function (item) {
                    item.active = checkExistsTask(item.type);
                });
            }
        }

        function checkExistsTask(task) {
            if (vm.tasks && vm.tasks.length > 0) {
                var countTask = $filter('filter')(vm.tasks, function (item) {
                    return item === task;
                }).length;
                if (countTask > 0) {
                    return true;
                }
            }
            return false;
        }

        function onChangeCheckbox() {
            var taskCheckBox = [];
            if (vm.tasksInit) {
                vm.tasksInit.forEach(function (task) {
                    if (task && task.active) {
                        taskCheckBox.push(task.type);
                    }
                });
            }
            vm.onChange({tasks: taskCheckBox ? taskCheckBox : []});
        }

    }

    angular.module('ipmApp').component('qhpTasks', {
        bindings: {
            tasks: '<',
            role: '<',
            onChange: '&'
        },
        templateUrl: 'app/admin/user-management/qhp-tasks/qhp-tasks.html',
        controller: QhpTasksController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
