(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('haq-brief-review', {
            parent: 'app',
            url: '/haq-brief-review',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-brief-review-workflow/landing-page.html',
                    controller: 'LandingPageHAQBriefReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                briefReviewEntity: ['HAQBriefReviewWorkflowService', function(HAQBriefReviewWorkflowService) {
                    return HAQBriefReviewWorkflowService.getInQueue();
                }],
                briefReviewStorage: [ function () {
                    return new Map();
                }]
            }
        })
        .state('haq-brief-review.workflow', {
            parent: 'haq-brief-review',
            url: '/workflow?timestamp',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-brief-review-workflow/haq-brief-review-workflow.html',
                    controller: 'HAQBriefReviewWorkflowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                timeSpent: ['HAQBriefReviewWorkflowService', function(HAQBriefReviewWorkflowService) {
                    return HAQBriefReviewWorkflowService.getTimeSpent();
                }],
                briefReviewEntity: ['HAQBriefReviewWorkflowService', function(HAQBriefReviewWorkflowService) {
                    var actionEvaluation = null;
                    return HAQBriefReviewWorkflowService.getInQueue(actionEvaluation);
                }]
            }
        })
        .state('haq-brief-review.submit', {
            parent: 'haq-brief-review',
            url: '/submit',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-brief-review-workflow/submit-page.html',
                    controller: 'SubmitPageHAQBriefReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                signature: ['HAQBriefReviewWorkflowService', function(HAQBriefReviewWorkflowService) {
                    return HAQBriefReviewWorkflowService.getMostRecentSignature(true);
                }]
            }
        });
    }

})();
