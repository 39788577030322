(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('HAQBatteryAdHocService', HAQBatteryAdHocService);

    HAQBatteryAdHocService.$inject = ['$resource', '$q', 'halClient', '$http'];

    function HAQBatteryAdHocService($resource, $q, halClient, $http) {
        var baseUrl = 'api/haq-battery-adhoc';
        var resourceUrl = "api/haq-battery-adhoc/:id";
        var service = $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'delete': {method: 'DELETE'}
        });
        service.findAllByPatientId = findAllByPatientId;
        service.findById = findById;
        service.getNextHaqBatteryAdhoc = getNextHaqBatteryAdhoc;

        function findAllByPatientId(patientId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/get-by-patient/' + patientId;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function findById(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function getNextHaqBatteryAdhoc() {
            var deferred = $q.defer();
            var url = baseUrl + "/get-next-haq-battery-adhoc";
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        return service;

    }
})();
