(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPReportAddendumController', FRPReportAddendumController);

    FRPReportAddendumController.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'frpReport', 'ipmConstants', '$window', 'FrpReportService', 'DataUtils', 'isView'];

    function FRPReportAddendumController($scope, $state, $stateParams, $uibModal, frpReport, ipmConstants, $window, FrpReportService, DataUtils, isView) {
        var vm = this;
        vm.frpReport = frpReport ? frpReport : {};
        vm.frpReportOld = {};
        vm.save = save;
        vm.isRequired = false;
        vm.isFinalize = false;
        vm.isDisabled = false;
        vm.isChanged = isChanged;
        vm.isView = isView;
        vm.submitted = false;
        vm.cancelMessage = 'ipmApp.frpReport.admin.confirmCancel';

        init();

        function init() {
            vm.frpReportId = $stateParams.id;
            vm.frpReportOld = DataUtils.deepCopy(vm.frpReport);
            vm.isFinalize = vm.frpReport && vm.frpReport.frpReportAddendum
            && (vm.frpReport.frpReportAddendum.status === 'COMPLETE') ? true : false;
            setIsDisabled();
        }

        function setIsDisabled() {
            if (vm.isView || (vm.frpReport.patientFRPMDE && (vm.frpReport.status === 'MDE_ABANDONED' || vm.frpReport.patientFRPMDE.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        function isChanged() {
            for (var field in vm.frpReport.frpReportAddendum) {
                if (!vm.frpReport.frpReportAddendum[field]) {
                    vm.frpReport.frpReportAddendum[field] = null;
                }
            }
            for (var fieldOld in vm.frpReportOld.frpReportAddendum) {
                if (!vm.frpReportOld.frpReportAddendum[fieldOld]) {
                    vm.frpReportOld.frpReportAddendum[fieldOld] = null;
                }
            }
            return !angular.equals(vm.frpReport, vm.frpReportOld);
        }

        function save() {
            vm.isSaving = true;
            if (vm.frpReport) {
                vm.frpReport.frpReportAddendum.status = 'COMPLETE'; // set status COMPLETE when save addendum
                FrpReportService.saveFRPReportAddendum(vm.frpReport).then(function (response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            goToFRPReport();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        function back() {
            $state.go('frp-report', { id: vm.frpReportId });
        }

        vm.confirmDialog = function (message) {
            if (vm.isView) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    goToFRPReport();
                }
            });
        }

        function goToFRPReport() {
            $state.go('frp-report', {id: vm.frpReport.id});
        }
    }
})();
