(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('TestDeleteController',TestDeleteController);

    TestDeleteController.$inject = ['$uibModalInstance', 'entity', 'Test'];

    function TestDeleteController($uibModalInstance, entity, Test) {
        var vm = this;
        vm.test = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Test.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
