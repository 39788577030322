(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('battery', {
            parent: 'entity',
            url: '/battery',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.battery.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/battery/batteries.html',
                    controller: 'BatteryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('battery');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('battery-detail', {
            parent: 'entity',
            url: '/battery/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.battery.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/battery/battery-detail.html',
                    controller: 'BatteryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('battery');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Battery', function($stateParams, Battery) {
                    return Battery.get({id : $stateParams.id});
                }]
            }
        })
        .state('battery.new', {
            parent: 'battery',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/battery/battery-dialog.html',
                    controller: 'BatteryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nameEn: null,
                                nameEs: null,
                                orderNumber: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('battery', null, { reload: true });
                }, function() {
                    $state.go('battery');
                });
            }]
        })
        .state('battery.edit', {
            parent: 'battery',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/battery/battery-dialog.html',
                    controller: 'BatteryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Battery', function(Battery) {
                            return Battery.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('battery', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('battery.delete', {
            parent: 'battery',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/battery/battery-delete-dialog.html',
                    controller: 'BatteryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Battery', function(Battery) {
                            return Battery.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('battery', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
