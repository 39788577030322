(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('ipmApp')
        .factory('PhoneUtilsService', PhoneUtilsService);

    PhoneUtilsService.$inject = [];

    function PhoneUtilsService() {
        var service = {
            formatPhone: formatPhone
        };

        return service;

        function formatPhone(tel) {
            if (!tel) { return ''; }
	
            var value = tel.toString().trim().replace(/^\+/, '');
    
            if (value.match(/[^0-9]/)) {
                return tel;
            }
    
            var country, city, number;
    
            switch (value.length) {
                case 1:
                case 2:
                case 3:
                    city = value;
                    break;
    
                default:
                    city = value.slice(0, 3);
                    number = value.slice(3);
            }
    
            if(number){
                
                if(number.length>3){
                    number = number.slice(0, 3) + '-' + number.slice(3,7);
                }
                else{
                    number = number;
                }
    
                return ("(" + city + ") " + number).trim();
            }
            else{
                
                return "(" + city;
            }
        }
    }
})();
