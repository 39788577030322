(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patient', {
            parent: 'entity',
            url: '/patient',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.patient.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patient/patients.html',
                    controller: 'PatientController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        // .state('patient-detail', {
        //     parent: 'entity',
        //     url: '/patient/{id}',
        //     data: {
        //         authorities: ['ROLE_ADMIN'],
        //         pageTitle: 'ipmApp.patient.detail.title'
        //     },
        //     views: {
        //         'content@': {
        //             templateUrl: 'app/entities/patient/patient-detail.html',
        //             controller: 'PatientDetailController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     resolve: {
        //         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        //             $translatePartialLoader.addPart('patient');
        //             return $translate.refresh();
        //         }],
        //         entity: ['$stateParams', 'Patient', function($stateParams, Patient) {
        //             return Patient.get({id : $stateParams.id});
        //         }]
        //     }
        // })
        .state('patient.new', {
            parent: 'patient',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-dialog.html',
                    controller: 'PatientDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dateOfBirth: null,
                                patientId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('patient', null, { reload: true });
                }, function() {
                    $state.go('patient');
                });
            }]
        })
        .state('patient.edit', {
            parent: 'patient',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-dialog.html',
                    controller: 'PatientDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Patient', function(Patient) {
                            return Patient.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('patient.delete', {
            parent: 'patient',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-delete-dialog.html',
                    controller: 'PatientDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Patient', function(Patient) {
                            return Patient.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
