(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPReportConclusionController', FRPReportConclusionController);

    FRPReportConclusionController.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'FrpReportService', 'frpReport', 'templates', 'DataUtils', 'ipmConstants', 'isView'];

    function FRPReportConclusionController($scope, $state, $stateParams, $uibModal, FrpReportService, frpReport, templates, DataUtils, constants, isView) {
        var vm = this;
        vm.frpReport = frpReport ? frpReport : {};
        vm.reportOld = {};
        vm.templates = templates ? templates : [];
        vm.ptSortGoalAddNewDeletes = [];
        vm.ptLongGoalAddNewDeletes = [];
        vm.psychSortGoalAddNewDeletes = [];
        vm.psychLongGoalAddNewDeletes = [];
        vm.isSaving = false;
        vm.isFinalize = false;
        vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.isChanged = isChanged;
        vm.isView = isView ? true : frpReport ? frpReport.statusFinal === constants.STATUS_FINAL_FRP_MDE : false;
        vm.fieldRequrieds = [
            'treatment_team_observations',
            // 'input_hours_completed',
            // 'input_hours_requested',
            'checkbox_request_for_treatment',
            'input_m_G5_SORT_TERM',
            'input_m_G6_SORT_TERM',
            'select_m_G9_SORT_TERM',
            'input_m_G5_LONG_TERM',
            'input_m_G6_LONG_TERM',
            'select_m_G9_LONG_TERM',
        ];

        vm.dataHeaderReport = {};
        vm.templateInputType = constants.FRP_MDE_TEMPLATE;
        vm.typeGoalsPT = 'PT';
        vm.typeGoalsPSYCH = 'PSYCH';

        init();

        function init() {
            vm.frpReportId = $stateParams.id;
            vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);
            if (vm.frpReport && vm.frpReport.frpReportAdmin && vm.frpReport.frpReportAdmin.reportType) {
                vm.reportType = vm.frpReport.frpReportAdmin.reportType;
                if (vm.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT){
                    vm.isMidsessionReport = true;
                }
            }
            if (vm.frpReport && vm.frpReport.gender) {
                vm.isMale = vm.frpReport.gender !== 'female';
            }

            FrpReportService.getFrpConclusionReport($stateParams.id).then(function (data) {
                vm.report = data;
                vm.reportOld = DataUtils.deepCopy(data);
                vm.isFinalize = vm.report && vm.report.finalize;
                setIsDisabled();
                updateFieldRequrieds();
            });
        }

        function updateFieldRequrieds() {
            // build accomplished_{G1}_LONG_TERM, checkbox_G1_LONG_TERM
            buildTerm(vm.report.ptLongTermGoalResponses, 'LONG_TERM', vm.typeGoalsPT);
            buildTerm(vm.report.psychLongTermGoalResponses, 'LONG_TERM', vm.typeGoalsPSYCH);

            // build accomplished_{G1}_SORT_TERM, checkbox_G1_SORT_TERM
            buildTerm(vm.report.ptSortTermGoalResponses, 'SORT_TERM', vm.typeGoalsPT);
            buildTerm(vm.report.psychSortTermGoalResponses, 'SORT_TERM', vm.typeGoalsPSYCH);

            if (vm.isMidsessionReport) {
                vm.fieldRequrieds.push('input_hours_completed');
                vm.fieldRequrieds.push('input_hours_requested');
            }
        }

        function buildTerm(termGoalResponses, type, typeGoals) {
            if (termGoalResponses) {
                var goalStatusTerm = "goalStatus_{code}_" + type + "_" + typeGoals; // ex: goalStatus_STG0_SORT_TERM_PT
                var checkboxLong = "checkbox_{code}_" +  type;
                termGoalResponses.forEach(function (item) {
                    if (item && item.code) {
                        vm.fieldRequrieds.push(goalStatusTerm.replace('{code}', item.code));
                        vm.fieldRequrieds.push(checkboxLong.replace('{code}', item.code));
                    }
                });
            }
        }

        function setIsDisabled() {
            if (vm.isView || (vm.frpReport && (vm.frpReport.status === 'MDE_ABANDONED' || (vm.frpReport.patientFRPMDE && vm.frpReport.patientFRPMDE.isViewFrpProgram)))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        vm.save = function () {
            submitData(false);
        }

        vm.finalize = function () {
            submitData(true);
        }

        function isChanged() {
            return !angular.equals(vm.report, vm.reportOld);
        }

        function back() {
            $state.go('frp-report', { id: vm.frpReportId });
        }

        vm.cancel = function () {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        function checkValidation() {
            addValidationTermGoal(vm.report.ptLongTermGoalAddNewResponses, 'LONG_TERM', vm.typeGoalsPT);
            addValidationTermGoal(vm.report.psychLongTermGoalAddNewResponses, 'LONG_TERM', vm.typeGoalsPSYCH);
            addValidationTermGoal(vm.report.ptSortTermGoalAddNewResponses, 'SORT_TERM', vm.typeGoalsPT);
            addValidationTermGoal(vm.report.psychSortTermGoalAddNewResponses, 'SORT_TERM', vm.typeGoalsPSYCH);

            vm.fieldRequrieds.forEach(function (field) {
                if (field && vm.conclusionReportForm[field]) {
                    vm.conclusionReportForm[field].$setDirty();
                }
            });
        }

        function addValidationTermGoal(termGoalAddNewResponses, type, typeGoals) {
            var inputLongShort = "input_m_{code}"; // input_m_LTG0
            var goalStatusTerm = "goalStatus_{code}_" + type + "_" + typeGoals; // ex: goalStatus_STG0_SORT_TERM_PT
            if (termGoalAddNewResponses) {
                termGoalAddNewResponses.forEach(function (item) {
                    if (item.active && item.checked) {
                        vm.fieldRequrieds.push(inputLongShort.replace('{code}', item.code));
                        vm.fieldRequrieds.push(goalStatusTerm.replace('{code}', item.code));
                    }
                });
            }
        }

        function submitData(finalize) {
            if (vm.isView) {
                return;
            }
            if (finalize) {
                checkValidation();
                if (vm.conclusionReportForm.$invalid || !hasTestingChecked()) {
                    return;
                }
            }
            vm.isSaving = true;
            vm.report.finalize = finalize;
            prepairDataForSave();
            FrpReportService.saveFrpConclusionReport(vm.frpReportId, vm.report).then(function (response) {
                onSaveSuccess(response);
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        function prepairDataForSave() {
            // Feedback before the Demo - June 09, 2022 - Feedback #22
            // goal-add-new rule
            // new, checked, not-empty        -> save text and checked state
            // new, checked, empty            -> form invalid
            // new, uncheck, not-empty        -> save text and uncheck state
            // new, uncheck, empty            -> not save
            // old, checked, not-empty        -> save text and checked state
            // old, checked, empty            -> form invalid
            // old, uncheck, not-empty        -> save text and uncheck state
            // old, uncheck, empty            -> term-goal delete (database update to inactive)

            // filter new, checked/checked, not-empty sort-term-goal
            vm.report.ptSortTermGoalAddNewResponses = _.filter(vm.report.ptSortTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            vm.report.psychSortTermGoalAddNewResponses = _.filter(vm.report.psychSortTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // filter new, checked, not-empty long-term-goal
            vm.report.ptLongTermGoalAddNewResponses = _.filter(vm.report.ptLongTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            vm.report.psychLongTermGoalAddNewResponses = _.filter(vm.report.psychLongTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // delete old, uncheck, empty sort-term-goal
            _.forEach(vm.report.ptSortTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            _.forEach(vm.report.psychSortTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            // delete old, uncheck, empty long-term-goal
            _.forEach(vm.report.ptLongTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            _.forEach(vm.report.psychLongTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            // merge add goals add new list
            vm.report.ptSortTermGoalAddNewResponses.push.apply(vm.report.ptSortTermGoalAddNewResponses, vm.ptSortGoalAddNewDeletes);
            vm.report.ptLongTermGoalAddNewResponses.push.apply(vm.report.ptLongTermGoalAddNewResponses, vm.ptLongGoalAddNewDeletes);
            vm.report.psychSortTermGoalAddNewResponses.push.apply(vm.report.psychSortTermGoalAddNewResponses, vm.psychSortGoalAddNewDeletes);
            vm.report.psychLongTermGoalAddNewResponses.push.apply(vm.report.psychLongTermGoalAddNewResponses, vm.psychLongGoalAddNewDeletes);

            // Set data to convert Template content for Treatment Team Observations
            if (vm.report.treatmentTeamObservationsTemplate && vm.report.treatmentTeamObservationsTemplate.id) {
                vm.report.treatmentTeamObservationsTemplate.isGlobalTemplate = true;
                vm.report.treatmentTeamObservationsTemplate.gender = vm.frpReport.gender;
                vm.report.treatmentTeamObservationsTemplate.lastname = vm.frpReport.patientFRPMDE.patient.lastName;
            }
        }

        vm.addNewSortTermGoal = function (type) {
            if (type === 'PT') {
                var orderNumber = _.isEmpty(vm.report.ptSortTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.ptSortTermGoalAddNewResponses, function (o) { return o.orderNumber }).orderNumber + 1;
                var code = 'STG' + orderNumber;
                var newGoal = { code: code, orderNumber: orderNumber, active: true, goalType: 'SORT_TERM', goalGroup: 'PT' };
                vm.report.ptSortTermGoalAddNewResponses.push(newGoal);
            } else if (type === 'PSYCH') {
                var orderNumber = _.isEmpty(vm.report.psychSortTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.psychSortTermGoalAddNewResponses, function (o) { return o.orderNumber }).orderNumber + 1;
                var code = 'STG' + orderNumber;
                var newGoal = { code: code, orderNumber: orderNumber, active: true, goalType: 'SORT_TERM', goalGroup: 'PSYCH' };
                vm.report.psychSortTermGoalAddNewResponses.push(newGoal);
            }
        }

        vm.addNewLongTermGoal = function (type) {
            if (type === 'PT') {
                var orderNumber = _.isEmpty(vm.report.ptLongTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.ptLongTermGoalAddNewResponses, function (o) { return o.orderNumber }).orderNumber + 1;
                var code = 'LTG' + orderNumber;
                var newGoal = { code: code, orderNumber: orderNumber, active: true, goalType: 'LONG_TERM', goalGroup: 'PT' };
                vm.report.ptLongTermGoalAddNewResponses.push(newGoal);
            } else if (type === 'PSYCH') {
                var orderNumber = _.isEmpty(vm.report.psychLongTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.psychLongTermGoalAddNewResponses, function (o) { return o.orderNumber }).orderNumber + 1;
                var code = 'LTG' + orderNumber;
                var newGoal = { code: code, orderNumber: orderNumber, active: true, goalType: 'LONG_TERM', goalGroup: 'PSYCH' };
                vm.report.psychLongTermGoalAddNewResponses.push(newGoal);
            }
        }

        vm.deleteSortTermGoalAddNew = function (deleteItem, type) {
            if (type === 'PT') {
                _.remove(vm.report.ptSortTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

                if (!deleteItem.id && !deleteItem.active) {
                    return;
                }
                vm.ptSortGoalAddNewDeletes.push(deleteItem);
            } else if (type === 'PSYCH') {
                _.remove(vm.report.psychSortTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

                if (!deleteItem.id && !deleteItem.active) {
                    return;
                }
                vm.psychSortGoalAddNewDeletes.push(deleteItem);
            }

        }

        vm.deleteLongTermGoalAddNew = function (deleteItem, type) {
            if (type === 'PT') {
                _.remove(vm.report.ptLongTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

                if (!deleteItem.id && !deleteItem.active) {
                    return;
                }
                vm.ptLongGoalAddNewDeletes.push(deleteItem);
            } else if (type === 'PSYCH') {
                _.remove(vm.report.psychLongTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

                if (!deleteItem.id && !deleteItem.active) {
                    return;
                }
                vm.psychLongGoalAddNewDeletes.push(deleteItem);
            }
        }

        vm.showAddGoal = function($last, isLongTerm, type) {
            // filter active Goal
            var addNewItem;
            if (type === 'PT') {
                if (isLongTerm) {
                    addNewItem = _.filter(vm.report.ptLongTermGoalAddNewResponses, function (i) { return i.active; });
                } else {
                    addNewItem = _.filter(vm.report.ptSortTermGoalAddNewResponses, function (i) { return i.active; });
                }
            } else if (type === 'PSYCH') {
                if (isLongTerm) {
                    addNewItem = _.filter(vm.report.psychLongTermGoalAddNewResponses, function (i) { return i.active; });
                } else {
                    addNewItem = _.filter(vm.report.psychSortTermGoalAddNewResponses, function (i) { return i.active; });
                }
            }
            return $last && _.isEmpty(addNewItem);
        }

        vm.checkValidationShortTermGoals = function () {
            if (!vm.isMidsessionReport) return true;
            return !_.isEmpty(_.filter(vm.report.ptSortTermGoalResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.ptSortTermGoalAddNewResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.psychSortTermGoalResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.psychSortTermGoalAddNewResponses, function (i) {
                    return i.checked;
                }));
        }

        vm.checkValidationLongTermGoals = function () {
            if (!vm.isMidsessionReport) return true;
            return !_.isEmpty(_.filter(vm.report.ptLongTermGoalResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.ptLongTermGoalAddNewResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.psychLongTermGoalResponses, function (i) {
                    return i.checked;
                })) ||
                !_.isEmpty(_.filter(vm.report.psychLongTermGoalAddNewResponses, function (i) {
                    return i.checked;
                }));
        }

        function hasTestingChecked() {
            // At least 1 Short Term Goal is required
            var isChecked = vm.hasTermGoalChecked(true);
            if (!isChecked) return false;
            // At least 1 Long Term Goal is required
            isChecked = vm.hasTermGoalChecked(false);
            if (!isChecked) return false;
            return true;
        }

        vm.hasItemChecked = function (listItem) {
            if (listItem) {
                return !_.isEmpty(_.filter(listItem, function (s) { return s.checked; }))
            }
            return false;
        }

        vm.hasTermGoalChecked = function(isSort) {
            if (!vm.isMidsessionReport) return true;
            var termGoalChecked;
            var termGoalAddNewChecked
            if (isSort) {
                // At least 1 Short Term Goal is required
                termGoalChecked = vm.hasItemChecked(vm.report.ptSortTermGoalResponses) || vm.hasItemChecked(vm.report.psychSortTermGoalResponses);
                termGoalAddNewChecked = vm.hasItemChecked(vm.report.ptSortTermGoalAddNewResponses) || vm.hasItemChecked(vm.report.psychSortTermGoalAddNewResponses);
            } else {
                // At least 1 Long Term Goal is required
                termGoalChecked = vm.hasItemChecked(vm.report.ptLongTermGoalResponses) || vm.hasItemChecked(vm.report.psychLongTermGoalResponses);
                termGoalAddNewChecked = vm.hasItemChecked(vm.report.ptLongTermGoalAddNewResponses) || vm.hasItemChecked(vm.report.psychLongTermGoalAddNewResponses);
            }
            if (!termGoalChecked && !termGoalAddNewChecked) return false;
            return true;
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };
    }
})();
