(function() {
    'use strict';

    BatteryTestsListController.$inject = ['$scope', '$state'];

    function BatteryTestsListController($scope, $state) {
        var vm = this;//jshint ignore:line
        vm.batteryTestsClick = batteryTestsClick;
        vm.batteryTestsView = [];
        init();

        function init() {
            vm.batteryTestsView = _.cloneDeep(vm.batteryTests);
            vm.batteryTestsView = removeBatteryTestsInactive(vm.batteryTestsView);
        }

        function removeBatteryTestsInactive(batteryTests) {
            var batteryTestsActive = [];
            if (batteryTests) {
                batteryTestsActive = _.remove(batteryTests, function (n) {
                    return n.id && n.test && n.test.activated;
                });
            }
            return batteryTestsActive;
        }

        function batteryTestsClick(batteryTest) {
            if (batteryTest && batteryTest.test && batteryTest.test.id) {
                $state.go('test.edit', {id: batteryTest.test.id, isFromHAQ: true});
            }
        }
    }

    angular.module('ipmApp').component('batteryTestsList', {
        bindings: {
            batteryTests: '<'
        },
        templateUrl: 'app/entities/haqs-battery/battery-tests-list/battery-tests-list.html',
        controller: BatteryTestsListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
})();
