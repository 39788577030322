(function() {
    'use strict';

    FRPGoalAddNewRowController.$inject = [];

    function FRPGoalAddNewRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

        vm.checkboxChange = function() {
            resetDefaultChecked();
        }

        vm.removeGoalAddNew = function() {
            if (vm.isDisabled) return;
            vm.item.active = false;
            vm.onDelete({data: vm.item});
        }

        vm.addNewGoal = function() {
            if (vm.isDisabled) return;
            vm.onClickAddNewGoal();
        }

        vm.selectGoalStatusOnchange = function(goalStatus) {
            if (!goalStatus) {
                vm.item.goalStatus = null;
            }
            if (goalStatus && goalStatus === 'INPROGRESS') {
                return;
            }
            resetGoalExplanation();
        };

        function resetDefaultChecked() {
            if (vm.item.checked) {
                if (!vm.item.goalStatus) {
                    vm.item.goalStatus = 'INPROGRESS';
                }
            } else {
                vm.item.checked = null;
            }
        }

        function resetGoalExplanation() {
            if (vm.item) {
                vm.item.goalExplanation = null;
            }
        }

    }

    angular.module('ipmApp').component('frpGoalAddNewRow', {
        bindings: {
            item: '<',
            type: '<',
            onChange: '&',
            onDelete: '&',
            lastItem: '<',
            onClickAddNewGoal: '&',
            isDisabled: '<',
            formReference: '<'
        },
        templateUrl: 'app/frp-mde/frp-report/conclusion/components/frp-goal-add-new-row.html',
        controller: FRPGoalAddNewRowController,
        controllerAs: 'vm'
    })
})();
