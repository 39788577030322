(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SectionDetailController', SectionDetailController);

    SectionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Section', 'Test', 'Question'];

    function SectionDetailController($scope, $rootScope, $stateParams, entity, Section, Test, Question) {
        var vm = this;
        vm.section = entity;
        vm.load = function (id) {
            Section.get({id: id}, function(result) {
                vm.section = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:sectionUpdate', function(event, result) {
            vm.section = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
