(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ConfirmDownloadController', ConfirmDownloadController);

    ConfirmDownloadController.$inject = ['$uibModalInstance'];

    function ConfirmDownloadController($uibModalInstance) {
        var vm = this;
        
        vm.yes = function () {
            $uibModalInstance.close(true);
            $uibModalInstance.dismiss('cancel');
        };

        vm.no = function () {
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        };
    }

})();
