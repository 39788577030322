(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('frpMdeService', frpMdeService);

    frpMdeService.$inject = ['$http', 'halClient', '$q', 'DateUtils'];

    function frpMdeService ($http, halClient, $q, DateUtils) {
        var baseURL = 'api/frp-mde/';

        var service = {
            'frpMdeService': frpMdeService,
            'enrollPatient': enrollPatient,
            'enrollPatientById': enrollPatientById,
            'update': update,
            'getFPR': getFPR,
            'searchPatientFRPMDE': searchPatientFRPMDE,
            'searchEnrollPatient': searchEnrollPatient,
            'get': get,
            'getMDE': getMDE,
            'getReport': getReport,
            'getAuthorizedHours': getAuthorizedHours,
            'updateReport': updateReport,
            'inactivePatient': inactivePatient,
            'getAuthorizedHoursById': getAuthorizedHoursById,
            'addUpdateRequestAuthorization': addUpdateRequestAuthorization,
            'downloadFile': downloadFile,
            'deleteFRPMDEReport': deleteFRPMDEReport,
            "confirmPassword": confirmPassword,
            'isPatientActivated': isPatientActivated
        };

        /**
         *
         * @param {
         *  patientId, - required
         *  injuryDate, - required
         *  employer, - required
         *  title,
         *  caseInsurance,
         *  claimNumber, - required
         *  nurseCaseManager,
         *  applicantAttorney,
         *  providerId, - required
         *  OfficeId, - required
         *} data
         * @returns
         */
        function enrollPatient(data) {
            var url = baseURL + "add-enroll-patient";
            return $http.post(url, data).then(function (response) {
                return response;
            });
        };

        function enrollPatientById(patientFrpMdeId, frpStartDate) {
            var url = baseURL + patientFrpMdeId + '/enroll-patient';
            var data = { frpStartDate: DateUtils.convertLocalDateToServer(frpStartDate) };
            return $http.post(url, data).then(function (response) {
                return response;
            });
        };

        /**
         *
         * @param {
         *  mdeStatus, - required
         *  frpStatus, - required
         *  providerId, - required
         *  OfficeId, - required
         *} data
         * @returns
         */
        function update(patient) {
            var url = baseURL;
            var data = angular.copy(patient);
            if (data.frpStartDate) {
                data.frpStartDate = DateUtils.convertLocalDateToServer(data.frpStartDate);
            }
            return $http.put(url, data).then(function (response) {
                return response;
            });
        };

        function get(id) {
            var url = baseURL + id;
            return $http.get(url).then(function(response) {
                return response;
            });
        }

        function getFPR(param, pageable) {
            var deferred = $q.defer();
            var url = baseURL + "get-frp";
            return $http.get(url, {
                params: {
                    query: param.query,
                    patientId: param.patientId,
                    frpProgramIds: param.frpProgramIds,
                    startDate: param.startDate,
                    endDate: param.endDate,
                    frpStatus: param.frpStatus,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function searchPatientFRPMDE(param, pageable) {
            var deferred = $q.defer();
            var url = baseURL + "search-patient-frp-mde";
            return $http.get(url, {
                params: {
                    query: param.query,
                    patientId: param.patientId,
                    frpProgramIds: param.frpProgramIds,
                    startDate: param.startDate,
                    endDate: param.endDate,
                    frpStatus: param.frpStatus,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function searchEnrollPatient(param, pageable) {
            var deferred = $q.defer();
            var url = baseURL + "search-enroll-patient";
            return $http.get(url, {
                params: {
                    query: param.query,
                    frpProgramIds: param.frpProgramIds,
                    mdeStatus: param.mdeStatus,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function getMDE(param, pageable) {
            var url = baseURL + "get-mde";
            return $http.get(url, {
                params: {
                    patientId: param.patientId,
                    frpProgramIds: param.frpProgramIds,
                    mdeStatus: param.mdeStatus,
                    query: param.query,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).then(function (response) {
                return response;
            });
        };

        function inactivePatient(param, pageable) {
            var url = baseURL + "get-inactive-patients";
            return $http.get(url, {
                params: {
                    patientId: param.patientId,
                    frpProgramIds: param.frpProgramIds,
                    mdeStatus: param.mdeStatus,
                    frpStatus: param.frpStatus,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort,
                    query: param.query
                }
            }).then(function (response) {
                return response;
            });
        };

        function getReport(patientId, pageable) {
            var deferred = $q.defer();
            var url = baseURL + "get-reports";
            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
             }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function getAuthorizedHours(patientFRPMDEId, pageable) {
            var deferred = $q.defer();
            var url = baseURL + "get-authorized-hours";
            $http.get(url, {
                params: {
                    patientFRPMDEId: patientFRPMDEId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        /**
         *
         * @param  {
         *  patientId, - required
         *  mdeStatus, - required
         *  frpStatus, - required
         *  providerId, - required
         *  OfficeId, - required
         *} data
         *
         * @returns
         */
        function updateReport(data) {
            var url = baseURL + "update-report";
            return $http.post(url, data).then(function (response) {
                return response;
            });
        };

        /**
         *
         * @param  id
         *
         * @returns
         */
        function getAuthorizedHoursById(id) {
            var url = baseURL + "get-authorized-hours";
            return $http.get(url + '/' + id).then(function (response) {
                return response.data;
            });
        };

        /**
         *
         * @param  {
         *  dateRequested, - required
         *  hoursRequested, - required
         *  dateAuthorized, - required
         *  hoursAuthorized, - required
         *  deleteFiles,
         *  newFiles
         *} data
         *
         * @returns
         */
        function addUpdateRequestAuthorization(data) {

            var formData = new FormData();

            formData.append('json', JSON.stringify(data));

            // new file
            _.forEach(data.files, function(file) {
                formData.append('newFiles', file);
            });
            var url = baseURL + "/add-update-request-authorization";
            return $http.post(url, formData,  {headers : {'Content-Type': undefined} }).then(function (response) {
                return response;
            });
        };

        /**
         *
         * @param  fileId
         *
         * @returns
         */
        function downloadFile(attachment) {
            var deferred = $q.defer();
            var url = 'api/frp-mde/files/' + attachment.id + '/download';
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var fileName = attachment.fileName;
                    var blob = new Blob([data], {type: contentType});
					saveAs(blob, fileName);
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        /**
         *
         * @param frpMdeReportListVM
         */
        function deleteFRPMDEReport(frpMdeReportListVM, password) {
            var url = baseURL + "delete-report";
            if (frpMdeReportListVM) {
                frpMdeReportListVM.dateOfWeek = null; // set null dateOfWeek (Oct 10th) to not format
                frpMdeReportListVM.password = password;
            }
            return $http.post(url , JSON.stringify(frpMdeReportListVM)).then(function (response) {
                return response;
            });
        }

        function confirmPassword(password) {
            var url = baseURL + "confirm-password";
            return $http.post(url, JSON.stringify({password: password})).then(function (response) {
                return response;
            });
        }

        function isPatientActivated(id) {
            var deferred = $q.defer();
            var url = baseURL + "is-patient-activated/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    };
})();
