(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CourseDeleteController',CourseDeleteController);

    CourseDeleteController.$inject = ['$uibModalInstance', 'entity', 'Course'];

    function CourseDeleteController($uibModalInstance, entity, Course) {
        var vm = this;
        vm.course = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Course.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
