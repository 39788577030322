(function() {
    'use strict';

    PhysicalDemandLevelRowController.$inject = ['MdeReportService'];

    function PhysicalDemandLevelRowController(MdeReportService) {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            MdeReportService.getPhysicalDemandLevels().then(function(data) {
                vm.dropdownValues = data;
            });
        }
    }

    angular.module('ipmApp').component('physicalDemandLevelRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/physical-demand-level-row.html',
        controller: PhysicalDemandLevelRowController,
        controllerAs: 'vm'
    })
})();
