(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SelectGoalStatusController', SelectGoalStatusController);

    SelectGoalStatusController.$inject = [];

    function SelectGoalStatusController() {
        var vm = this;
        vm.goalsStatus = ['COMPLETE', 'INPROGRESS'];
        init();

        function init() {
            vm.isShowValidation = vm.isShowValidation ? true : false;
            vm.isRequired = vm.isRequired ? true : false;
        }

        vm.goalStatusChange = function () {
            vm.selectOnchange({goalStatus: vm.goalStatus});
        };
    }

    angular.module('ipmApp').component('selectGoalStatus', {
        bindings: {
            isRequired: '<',
            disabled: '<',
            formReference: '<',
            isShowValidation: '<',
            goalStatusName: '<',
            goalStatus: '=',
            selectOnchange: '&'
        },
        templateUrl: 'app/frp-mde/frp-report/conclusion/components/select-goal-status.html',
        controller: SelectGoalStatusController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('goalStatus');
                return $translate.refresh();
            }]
        }
    });
})();
