(function() {
    'use strict';


    HaqBriefReviewOverviewController.$inject = ['$translate'];

    function HaqBriefReviewOverviewController($translate) {
        var vm = this; //jshint ignore:line
        vm.rangeReviewText = '';
        init();

        function init() {
            vm.rangeReviewText = vm.rangeReview ? $translate.instant( 'ipmApp.landingPageHAQ.home.rangeReview.' + vm.rangeReview) : '';
        }
    }

    angular.module('ipmApp').component('haqBriefReviewOverview', {
        bindings: {
            type: '<',
            patientName: '<',
            score: '<',
            scoreInterpretation: '<',
            rangeReview: '<'
        },
        templateUrl: 'app/qhp-workflow/haq-brief-review-workflow/brief-review-overview.html',
        controller: HaqBriefReviewOverviewController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
