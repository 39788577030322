(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('CompletionReportsService', CompletionReportsService);

    CompletionReportsService.$inject = ['$http', '$q', 'DownloadBlobService'];

    function CompletionReportsService ($http, $q, DownloadBlobService) {
    	var deferred = $q.defer();
    	return {
    		exportPatientsCompletedCSV: function(fromDate, toDate) {
    			$http({
                    url: 'api/completion-reports/patients-completed-csv',
                    method: "GET",
                    params: {fromDate: fromDate, toDate: toDate},
                    headers: {
                        'Content-type': 'application/json',
                    },
                    responseType: 'arraybuffer'
                }).success(function (data, status, headers, config) {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    
                    deferred.resolve(true);
                }).error(function (data, status, headers, config) {
//                    console.log('Failed to download Excel');
                    deferred.resolve(false);
                });
    			return deferred.promise;
    		}
    	};
    }
})();
