(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider
        .state('survey-workflow', {
            parent: 'app',
            url: '/survey-workflow/:id',
            data: {
                authorities: ['ROLE_PATIENT'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/survey-workflow/survey-workflow.html',
                    controller: 'SurveyWorkflowController',
                    controllerAs: 'vm'
                }
            },
                resolve: {
				 mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					 $translatePartialLoader.addPart('surveyWorkflow');
					 return $translate.refresh();
				 }],
				 entity: ['$stateParams', 'SurveyWorkflowService', function($stateParams, SurveyWorkflowService) {
					 return SurveyWorkflowService.getBatteryTest($stateParams.id);
				 }],
                 isAdHoc: ['SurveyWorkflowService', function(SurveyWorkflowService) {
					 return SurveyWorkflowService.checkIsAdHocHAQ();
				 }],
                 allBatteryTests: ['$stateParams', 'SurveyWorkflowService', '$q', function($stateParams, SurveyWorkflowService, $q) {
                    var deferred = $q.defer();

                    SurveyWorkflowService.getBatteryTest($stateParams.id)
                        .then(function(data){
                            deferred.resolve(SurveyWorkflowService.getAllBatteryTests(data.batteryId, data.id));
                        });

                    return deferred.promise;
				 }],
            }
        })
        .state('survey-workflow-thankyou', {
        	parent: 'app',
        	url: '/survey-workflow-thankyou',
        	data: {
            },
            views: {
                'content@': {
                    templateUrl: 'app/survey-workflow/partial/thank-you.html',
                    controller: 'ThankYouController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }],
                isAdHoc: ['SurveyWorkflowService', function(SurveyWorkflowService) {
                    return SurveyWorkflowService.checkIsAdHocHAQ();
                }],
                haqBatteryAdHoc: ['HAQBatteryAdHocService', function(HAQBatteryAdHocService) {
                    return HAQBatteryAdHocService.getNextHaqBatteryAdhoc();
                }]
            }
        })
        .state('course-registration-workflow', {
        	parent: 'app',
        	url: '/course-registration-workflow',
        	data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_PATIENT'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/survey-workflow/partial/course-register-workflow/course-register-workflow.html',
                    controller: 'CourseRegisterWorkflowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('surveyWorkflow');
                    return $translate.refresh();
                }]
            }
        });;
    }

})();
