(function() {
    'use strict';


    TestsAssignedsListController.$inject = ['$scope', '$state', 'ipmConstants', 'HaqsBatteryService', 'Test'];

    function TestsAssignedsListController($scope, $state, constants, HaqsBatteryService, Test) {
        var vm = this;//jshint ignore:line
        vm.pageSize = constants.PAGE_SIZE_MAX;
        vm.testsDefault = [];
        vm.batteryTestsDeleted = [];
        vm.assignedBatteryTestsOriginal = [];
        vm.unassignedBatteryTests = [];
        vm.unassignBatteryTestsClick = unassignBatteryTestsClick;
        vm.assignedBatteryTestsClick = assignedBatteryTestsClick;
        vm.batteryTestsClick = batteryTestsClick;
        vm.testClick = testClick;
        vm.sortableOptions = {
            placeholder: "ui-state-highlight",
            helper: function (event, ui) {
                var table = '<table style="position: absolute;" class="jh-table table table-striped"></table>';
                var draggedItem = $(table).append(ui[0].outerHTML);
                return draggedItem;
            },
            update: function(e, ui) {
                assignedBatteryTestsEmit();
            },
            stop: function(e, ui) {
                var batteryTests = ui.item.sortable.sourceModel;
                batteryTests.forEach(function(batteryTest, index) {
                    batteryTest.orderNumber = (index + 1);
                });
            },
            disabled: vm.batteryId && !vm.isStateDuplicate ? true : false
        };

        init();

        function init() {
            vm.batteryTests = vm.batteryTests ? vm.batteryTests : [];
            vm.assignedBatteryTestsOriginal = _.cloneDeep(vm.batteryTests); // init clone data assignedBatteryTests
            initData();
        }

        function initData() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize
            };
            Test.getAllTestsByActive(pageable).then(function (response) {
                vm.testsDefault = response && response.data ? response.data : [];
                if (!vm.batteryTests || vm.batteryTests.length == 0) {
                    vm.unassignedBatteryTests = vm.testsDefault;
                } else {
                   vm.unassignedBatteryTests = _.filter(vm.testsDefault, function(item) {
                        return findExitsTestInBatteryTests(item) == -1; // not found
                    });
                }
            });
        }

        function reOrderNumberBatteryTest() {
            vm.batteryTests.forEach(function(batteryTest, index) {
                batteryTest.orderNumber = (index + 1);
            });
        }

        /**
         *
         * @param battery
         * @returns {number} -1 not found
         */
        function findExitsTestInBatteryTests(tests) {
            return _.findIndex(vm.batteryTests, function(o) { return o && tests && o.testId == tests.id; });
        }

        function findIndexTestInUnassignedBatteryTests(test) {
            return _.findIndex(vm.unassignedBatteryTests, function(o) { return o && test && o.id == test.id; });
        }

        function findIndexBatteryTests(batteryTest) {
            return _.findIndex(vm.batteryTests, function(o) { return o && batteryTest && o.testId && o.testId == batteryTest.testId;});
        }

        function findIndexBatteryTestsDeleted(batteryTest) {
            return _.findIndex(vm.batteryTestsDeleted, function(o) { return o && batteryTest && o.testId && o.testId == batteryTest.testId;});
        }

        function unassignBatteryTestsClick(batteryTest) {
            if (vm.isAdHoc) {
                return;
            }
            var indexBatteryTest = findIndexBatteryTests(batteryTest);
            if (indexBatteryTest != -1) {
                // add batteryTest remove
                if (batteryTest.id) {
                    if (findIndexBatteryTestsDeleted(batteryTest) == -1) {
                        batteryTest.active = false;
                        vm.batteryTestsDeleted.push(batteryTest);
                    }
                }
                // remove batteryTest
                vm.batteryTests.splice(indexBatteryTest, 1);

                // add batteryTest to unassignedBatteryTests
                if (batteryTest.test) {
                    vm.unassignedBatteryTests.push(batteryTest.test);
                }
                assignedBatteryTestsEmit();
                reOrderNumberBatteryTest();
            }
        }

        function assignedBatteryTestsClick(test) {
            if (findExitsTestInBatteryTests(test) == -1) {
                // find batteryTest in batteryTests original if exists add from batteryTests else add new
                var indexBatteryTestOriginal =  _.findIndex(vm.assignedBatteryTestsOriginal, function(o) { return o && test && o.testId == test.id; });
                var batteryTestsAssigned;
                if (indexBatteryTestOriginal != -1) {
                    batteryTestsAssigned = vm.assignedBatteryTestsOriginal.slice(indexBatteryTestOriginal, indexBatteryTestOriginal + 1)[0];
                } else {
                    // add new BatteryTests
                    batteryTestsAssigned = {
                        id: null,
                        test: test,
                        testId: test.id,
                        batteryId: vm.batteryId,
                        active: true
                    };

                }
                // fix bug: IPMproject-B1050 and IPMproject-B1049
                var listOrder = [];
                _.forEach(vm.batteryTests, function(test) {
                    listOrder.push(test.orderNumber);
                });
                var maxOrderNumber = _.isEmpty(listOrder) ? 1 : _.max(listOrder) + 1;
                batteryTestsAssigned.orderNumber = maxOrderNumber;
                vm.batteryTests.push(batteryTestsAssigned);

                // remove test from assignedBatteryTests
                removeUnassignedBatteryTests(test);
                // recheck batteryTestsDeleted
                updateBatteryTestsDeleted(test);
                assignedBatteryTestsEmit();
            }
        }

        function updateBatteryTestsDeleted(test) {
            var indexTest = _.findIndex(vm.batteryTestsDeleted, function (o) {
                return o && test && o.testId == test.id;
            });
            if (indexTest != -1) {
                vm.batteryTestsDeleted.splice(indexTest, 1);
            }
        }

        function removeUnassignedBatteryTests(test) {
            var indexTest = findIndexTestInUnassignedBatteryTests(test);
            if (indexTest != -1) {
                vm.unassignedBatteryTests.splice(indexTest, 1);
            }
        }

        function assignedBatteryTestsEmit() {
            vm.onChange({
                batteryTests: vm.batteryTests ? vm.batteryTests : [],
                batteryTestsDeleted : vm.batteryTestsDeleted ? vm.batteryTestsDeleted : []
            });
        }

        function batteryTestsClick(batteryTest) {
            vm.testsOnChange({test:batteryTest.test});
        }

        function testClick(test) {
            vm.testsOnChange({test:test});
        }
    }

    angular.module('ipmApp').component('testsAssignedsList', {
        bindings: {
            batteryTests: '<',
            batteryId: '<',
            isStateDuplicate: '<',
            isAdHoc: '<',
            onChange: '&',
            testsOnChange: '&'
        },
        templateUrl: 'app/entities/haqs-battery/tests-assigned/tests-assigned-list.html',
        controller: TestsAssignedsListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
})();
