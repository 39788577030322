(function() {
    'use strict';


    mdeController.$inject = ['$state', '$rootScope', '$scope','ipmConstants', 'frpMdeService', '$filter', 'MdeReportService'];

    function mdeController($state, $rootScope, $scope, constants, frpMdeService, $filter, MdeReportService) {
        var vm = this; //jshint ignore:line
        vm.page = 1;
        vm.predicate = 'mder.status';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.totalItems = 0;
        vm.patientFrpMdes = [];
        vm.constants = constants;
        vm.mdeStatus = constants.MDE_STATUS;
        vm.statusDefault = ['MDE_PENDING', 'MDE_IN_PROGRESS'];
        vm.status = [];
        vm.loadAll = loadAll;
        vm.search = search;
        vm.downloadReport = downloadReport;
        vm.isDownLoading = false;
        init();

        function init() {
            if (vm.status && vm.status.length === 0) {
                vm.status = angular.copy(vm.statusDefault);
            }
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            var param = {
                officeId: null,
                query: vm.searchQuery,
                mdeStatus: vm.status && vm.status.length === 0 ? vm.mdeStatus : vm.status,
                frpProgramIds: getFrpProgramIds(),
            };
            frpMdeService.getMDE(param,pageable).then(function(res) {
                vm.patientFrpMdes = res.data;
                vm.totalItems = res.headers('X-Total-Count');
                vm.queryCount = vm.totalItems;

                // implement progress trackers
                _.forEach(vm.patientFrpMdes, function(patientFrpMde) {

                    if (!patientFrpMde.startDate) { // not yet create MDE Report
                        patientFrpMde.displayStartButton = true;
                    }

                    patientFrpMde.processTrackers = [];
                    // Admin
                    patientFrpMde.processTrackers.push({text: 'frpmdeDashboard.mdeTab.processTracker.admin', status: patientFrpMde.allReportStatus[0]});

                    // Medical
                    patientFrpMde.processTrackers.push({text: 'frpmdeDashboard.mdeTab.processTracker.medical', status: patientFrpMde.allReportStatus[1]});

                    // PT
                    patientFrpMde.processTrackers.push({text: 'frpmdeDashboard.mdeTab.processTracker.pt', status: patientFrpMde.allReportStatus[2]});

                    // Psych
                    patientFrpMde.processTrackers.push({text: 'frpmdeDashboard.mdeTab.processTracker.psych', status: patientFrpMde.allReportStatus[3]});

                    // Conclusion
                    patientFrpMde.processTrackers.push({text: 'frpmdeDashboard.mdeTab.processTracker.conclusion', status: patientFrpMde.allReportStatus[4]});
                });
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'mder.id') {
                result.push('mder.id' + ',' + (vm.reverse ? 'asc' : 'desc'));
            }
            return result;
        }

        function search() {
            vm.loadAll();
        }

        function downloadReport(patientFrpMde) {
            if (patientFrpMde && patientFrpMde.mdeReportId && !vm.isDownLoading) {
                vm.isDownLoading = true;
                MdeReportService.downloadMDEReport(patientFrpMde.mdeReportId).then(function (response) {
                    vm.isDownLoading = false;
                });
            }
        }

        vm.startReport = function(patientFrpMdeId) {
            MdeReportService.createMdeReport(patientFrpMdeId).then(function(res) {
                goToMdeReportBuilder(res.data);
            });
        }

        vm.editReport = function(id) {
            goToMdeReportBuilder(id);
        }

        vm.viewReport = function(id) {
            goToMdeReportBuilder(id);
        }

        function goToMdeReportBuilder(id) {
            $state.go('mde-report', { id: id });
        }

        function filterFrpProgramIds() {
            if (vm.frpPrograms) {
                return $filter('filter')(vm.frpPrograms).map(function (item) {
                    return item.id;
                });
            }
            return [];
        }

        function getFrpProgramIds() {
            if (vm.frpProgramId) {
                return [vm.frpProgramId];
            } else {
                return filterFrpProgramIds();
            }
        }

        vm.selectedStatus = function() {
            vm.loadAll();
        }

        $scope.$on("CHANGE_PROGRAM_FRP_MDE_DROPDOWN", function(event, res) {
            vm.frpProgramId = res;
            loadAll();
        });

        $scope.$on(constants.ADD_ENROLL_SUCCESS, function() {
            loadAll();
        });

    }

    angular.module('ipmApp').component('mdeTab', {
        bindings: {
            frpProgramId: '<',
            frpPrograms: '<',
            isView: '<'
        },
        templateUrl: 'app/frp-mde/tab/mde/mde.html',
        controller: mdeController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();
