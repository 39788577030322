(function () {
    'use strict';

    TemplateSelectController.$inject = ['$uibModal', 'ipmConstants'];

    function TemplateSelectController($uibModal, constants) {
        var vm = this; //jshint ignore:line
        vm.filterTemplate = filterTemplate;
        vm.change = change;
        vm.createOrEditTemplate = createOrEditTemplate;
        vm.selectTemplateOption;
        vm.templateType = constants.FRP_MDE_TEMPLATE.USER;

        init();

        function init() {
            _.forEach(vm.templates, function(t) { t.value = t.id ? t.id.toString() : t.value });
            if (vm.option && vm.option.id && vm.option.name) {
                vm.option.value = vm.option.id.toString();
                var template = findTemplate();
                if (!template) { // not its owner
                    vm.option.disabled = true;
                    vm.templates.unshift(vm.option);
                }
            }
            if (vm.isGlobalTemplate) {
                vm.templateType = constants.FRP_MDE_TEMPLATE.GLOBAL;
            }
        }

        function initOption() {
            return { value: "" };
        }

        function findTemplate() {
            if (!vm.option) return null;
            return _.find(vm.templates, function (t) { return t.value === vm.option.value && t.inputType === vm.inputType; });
        }

        function filterTemplate () {
            var result = _.filter(vm.templates, function (t) { return t.inputType === vm.inputType && t.active && t.type === (vm.isGlobalTemplate ? constants.FRP_MDE_TEMPLATE.GLOBAL : constants.FRP_MDE_TEMPLATE.USER); });
            if (!vm.isGlobalTemplate) {
                result.unshift({value: "create_template", name: "Create Template"});
            }
            return result;
        }

        function change() {
            if (!vm.option) {
                vm.report = '';
            } else {
                if (vm.option.value === 'create_template') {
                    createOrEditTemplate(true);
                } else {
                    var template = findTemplate();
                    if (template && template.id) {
                        var content = template.content;
                        if (vm.isGlobalTemplate && content) {
                            if (vm.gender) {
                                var isFemale = vm.gender.toLowerCase() === 'female';
                                var heShe = isFemale ? 'she' : 'he';
                                var heSheFirstUpperCase = isFemale ? 'She' : 'He';
                                var mrMsFirstUpperCase = isFemale ? 'Ms' : 'Mr';
                                var hisHer = isFemale ? 'her' : 'his';
                                content = content.replaceAll('<he/she>', heShe);
                                content = content.replaceAll('<his/her>', hisHer);
                                content = content.replaceAll('<HE/SHE>', heSheFirstUpperCase);
                                content = content.replaceAll('<MR/MS>', mrMsFirstUpperCase);
                            }
                            if (vm.lastname) {
                                content = content.replaceAll('<LASTNAME>', vm.lastname);
                            }
                        }
                        if (vm.isReportType && template.name) {
                            vm.reportType = template.name;
                        }
                        vm.report = content;
                    }
                }
            }

        }

        function createOrEditTemplate(isCreate) {
            var template;
            if (isCreate) {
                template = {
                    type: constants.FRP_MDE_TEMPLATE.USER,
                    inputType: vm.inputType,
                    active: true
                }
            } else {
                template = findTemplate();
            }
            if (template.disabled || vm.isDisabled) return; // reject edit if not its owner or view mode
            $uibModal.open({
                templateUrl: 'app/frp-mde/template/template-dialog.html',
                controller: 'FRPMDETemplateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'center-modal',
                size: 'md',
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        return $translate.refresh();
                    }],
                    titleKey: function() {
                        return vm.controlTitleKey;
                    },
                    template: function() {
                        return template;
                    }
                }
            }).result.then(function (data) {
                // create -> not apply new content, add new template to dropdown, reset dropdown to 'Select Template'
                // edit -> apply new content, keep current dropdown selected
                // delete -> reset dropdown to 'Select Template'
                // cancel create request -> reset dropdown to 'Select Template'
                // cancel edit request -> keep current dropdown selected
                if (isCreate) { // create
                    vm.option = initOption();
                    template = data.toJSON();
                    template.value = template.id.toString();
                    vm.templates.push(template);
                    vm.report = template.content;
                    vm.option = template;
                } else if (template.id) {
                    if (data === 'delete_template_success') {
                        template.active = false;
                    } else {
                        template = data.toJSON();
                        template.value = template.id.toString();
                        vm.report = template.content;
                        vm.option = template;
                    }
                }
            }, function (err) {
                if (template && !template.id) {
                    vm.option = initOption();
                }
            });
        }
    }

    angular.module('ipmApp').component('templateSelect', {
        bindings: {
            templates: '<',
            report: '=',
            inputType: '<',
            controlTitleKey: '<',
            isDisabled: '<',
            option: '=',
            isGlobalTemplate: '<',
            isAllowClearTemplate: '<',
            gender: '<',
            lastname: '<',
            isReportType: '<',
            reportType: '='
        },
        templateUrl: 'app/frp-mde/template/components/template-select.html',
        controller: TemplateSelectController,
        controllerAs: 'vm'
    })
})();
