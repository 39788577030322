// Import Quill using CommonJS syntax
(function() {
    'use strict';


    QuillEditorComponent.$inject = ['$timeout', '$window'];

    function QuillEditorComponent($timeout, $window) {
        var vm = this; //jshint ignore:line
        var Quill = $window.Quill;
        var Delta = Quill.import('delta');

        init();

        function init() {
            var toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                // ['blockquote', 'code-block'],
              
                // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                // [{ 'direction': 'rtl' }],                         // text direction
              
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }]
            ];

            var container = document.getElementById(vm.ids);
            var quill = new Quill(container, {
                modules: {
                    toolbar: vm.hideToolbar ? false : toolbarOptions
                },
                theme: 'snow'
            });
            $timeout(function(){
                var delta = quill.clipboard.convert(vm.value);
                quill.setContents(delta, 'silent');
            }, 100);
            // emit data when change
            quill.on('editor-change', function() {
                if (vm.maxLength && vm.maxLength > 0) {
                    updateMaxTextQuill(quill);
                }
                if (isEmptyQuillContent(quill)) {
                    vm.onEditChange({data: ''});
                } else {
                    vm.onEditChange({data: quill.root.innerHTML});
                }
            });

            // disable if view
            if (vm.view) {
                quill.enable(false);
                var toolbar = quill.getModule('toolbar');
                angular.forEach(toolbar.container.querySelectorAll('select'), function (input) {
                    input.parentElement.className = 'ql-formats quill-select-disabled';
                });
                angular.forEach(toolbar.container.querySelectorAll('button'), function (input) {
                    input.parentElement.className = 'ql-formats quill-select-disabled';
                });
                // change back-ground input
                if (quill.container.classList.contains('ql-container')) {
                    quill.container.classList.add('disabled-background-color');
                }
            }

            // event QuillCopy
            formatPasteEvent(quill);
        }

        function formatPasteEvent(quill) {
            if (vm.enableFormatCopyEvent) {
                quill.container.addEventListener('paste', function (e) {
                    quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
                        // removes any leading and trailing whitespace from the text before replacing the line breaks.
                        // fix the line breaks when paste in firefox
                        var plaintext = node.textContent ? node.textContent.trim() : '';
                        plaintext = plaintext.replace(/\n/g, '');
                        return new Delta().insert(plaintext);
                    });
                });
            }
        }

        function updateMaxTextQuill(quill) {
            var text = quill.getText();
            // Check if the text length exceeds the maximum length
            if (text && text.length > vm.maxLength) {
                // Delete the excess text
                quill.deleteText(vm.maxLength, text.length);
            }
        }

        function isEmptyQuillContent(quill) {
            if (quill) {
                var delta = quill.getContents();
                if (delta && delta.ops && delta.ops.length > 0) {
                    var opsData = delta.ops[0].insert;
                    var plainText = opsData.replace(/\n/g, '').trim();
                    return plainText.length === 0;
                }
            }
            return true;
        }

    }

    angular.module('ipmApp').component('quillEditor', {
        bindings: {
            value: '=',
            ids: '<',
            view: '<',
            hideToolbar: '<',
            enableFormatCopyEvent: '<',
            maxLength: '<',
            onEditChange: '&'
        },
        template: '',
        controller: QuillEditorComponent,
        controllerAs: 'vm'
    });
})();
