(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('ipmApp')
        .factory('FormatUtilsService', FormatUtilsService);

    FormatUtilsService.$inject = [];

    function FormatUtilsService() {
        var service = {
            removeHrefHtml: removeHrefHtml,
            formatMinuteBySeconds: formatMinuteBySeconds,
            formatMinuteToSecond: formatMinuteToSecond
        };

        return service;

        /**
         * formatMinuteBySeconds.
         * to format minutes care plan review
         * @param totalSeconds
         */
        function formatMinuteBySeconds(totalSeconds) {
            if (totalSeconds) {
                var minutes = parseInt(totalSeconds / 60);
                var extraSeconds = totalSeconds % 60;
                if (extraSeconds >= 30) {
                    minutes += 1;
                }
                return minutes;
            }
        }

        function formatMinuteToSecond(minutes) {
            if (!isNaN(minutes))
                return parseInt(minutes * 60);
        }

        function removeHrefHtml(htmlString) {
            if (!htmlString) {
                return '';
            }
            var tagRemove = "a";
            var regex = '<(\/)?' + tagRemove + '([^>]*)>';
            return htmlString.replace(new RegExp(regex, 'g'), '');
        }
    }
})();
