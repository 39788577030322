(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ClassSessionController', ClassSessionController);

        ClassSessionController.$inject = ['$scope', '$stateParams', '$state', '$rootScope', '$uibModal', 'ClassSessionService', 'paginationConstants', 'course'];

    function ClassSessionController ($scope, $stateParams, $state, $rootScope, $uibModal, ClassSessionService, paginationConstants, course) {
        var vm = this;
        vm.classSessions = [];

        vm.totalItems = 0;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.predicate = 'classStart';
        vm.reverse = false;
        vm.course = course;

        vm.loadAll = loadAll;

        init();

        function init() {
            vm.courseId = vm.course.id;
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            ClassSessionService.getAllUpcommingSessionByCourseId(pageable, vm.courseId).then(function (response) {
                // headers('X-Total-Count')
                var headers = response.headers
                vm.totalItems = headers('X-Total-Count');
                vm.classSessions = response && response.data ? response.data : [];
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            return result;
        }

        vm.openAutoGenerateDialog = function() {
            $uibModal.open({
                templateUrl: 'app/entities/class-session/class-session-schedule-day-dialog.html',
                controller: 'ScheduleXDayDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center',
                resolve: {
                    courseEntity: function() {
                        return { id:  vm.courseId}
                    }
                }
            }).result.then(function() {
                vm.loadAll();
            }, function() {
                // $state.go('^');
            });
        }

        var unsubscribe = $rootScope.$on('ipmApp:classSessionUpdate', function(event, result) {
            vm.loadAll();
        });
        $scope.$on('$destroy', unsubscribe);

        vm.back = function () {
            $state.go('course');
        };
    }
})();
