(function() {
    'use strict';


    CPRSingleChoiceQuestionController.$inject = ['$scope', '$state'];

    function CPRSingleChoiceQuestionController($scope, $state) {
        var vm = this; //jshint ignore:line
        vm.isOtherSingleChoiceTextInputRequired = isOtherSingleChoiceTextInputRequired;
        vm.nameQuestionValueUnclear = 'Unclear';
        init();

        function init() {
            // init answer
            if (vm.answers) {
                _.forEach(vm.question.optionNames, function (option) {
                    if (option.value === 'Other') {
                        var otherAnswer = vm.answers.get(vm.question.code);
                        if (otherAnswer) {
                            var parts = otherAnswer.split('---');
                            var otherCheckboxValue = parts[0];
                            vm.valueOtherSingleChoiceTextInputValue = parts[1];
                            vm.answers.set(vm.question.code, otherCheckboxValue);
                        }
                    }
                });

                vm.valueAnswer = vm.answers.get(vm.question.code);
            }
        }

        vm.handleSingleChoiceChange = function(option) {
            vm.onChange({ data: { question: vm.question, value: vm.valueAnswer, clearChilds: true }});
        }

        vm.isDisplayCheckBoxInline = function() {
            if (vm.question.code === 'CPR_013' || vm.question.code === 'CPR_015') { // special case following mockup
                return false;
            }

            if (vm.question.code === 'CPR_018' || vm.question.code === 'CPR_020' || vm.question.code === 'CPR_009' ||  vm.question.code === 'CPR_055') { // special case following mockup
                return true;
            }
            return Object.entries(vm.question.optionNames).length < 3;
        }

        function isOtherSingleChoiceTextInputRequired() {
            if (vm.valueAnswer) {
                return vm.valueAnswer === 'Other' ? true : false
            }
            return false;
        }

        vm.isDisplayFreeTextOtherCheckbox = function() {
            return isOtherCheckboxTextInputRequired();
        }

        vm.handleOtherSingleChoiceTextInput = function () {
            if (vm.valueAnswer === 'Other') {
                vm.onChange({ data: { question: vm.question, value: vm.valueAnswer + '---' + vm.valueOtherSingleChoiceTextInputValue, clearChilds: false } });
            }
        }

        vm.isDisplayTextAreaOtherSingleChoice = function() {
            if (isOtherSingleChoiceTextInputRequired()) {
                if (vm.question.code !== 'CPR_016') {
                    return true;
                }
            }
            return false;
        }

        vm.isDisplayTextFieldOtherSingleChoice = function() {
            if (isOtherSingleChoiceTextInputRequired()) {
                if (vm.question.code === 'CPR_016') {
                    return true;
                }
            }
            return false;
        }

        vm.checkShowLabelRequiredCheckBox = function (option) {
            if (option.value === vm.nameQuestionValueUnclear && vm.valueAnswer == vm.nameQuestionValueUnclear) {
                return true;
            }
            return false;
        }
    }

    angular.module('ipmApp').component('cprSingleChoiceQuestion', {
        bindings: {
            question: '<',
            answers: '<',
            onChange: '&',
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/question-component/single-choice/single-choice.html',
        controller: CPRSingleChoiceQuestionController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
