(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('ipmApp')
        .factory('DownloadBlobService', DownloadBlobService);

    DownloadBlobService.$inject = ['$window'];
    function DownloadBlobService ($window) {
        var service = {
            download : download,
            downloadAndOpen : downloadAndOpen,
            getFileName: getFileName
        };

        return service;

        function download(blob, downloadName, contentDisposition) {
            if(downloadName === null && contentDisposition){
                downloadName = getFileName(contentDisposition);
            }
            if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
                $window.navigator.msSaveOrOpenBlob(blob, downloadName);
            } else {
                var objectUrl = URL.createObjectURL(blob);
                var element = document.createElement('a');
                element.href = objectUrl;
                element.setAttribute('download', downloadName);
                document.body.appendChild(element);
                element.click();
                $(element).remove();
            }
        }

        function downloadAndOpen(blob, downloadName, contentDisposition) {
            if (downloadName === null && contentDisposition) {
                downloadName = getFileName(contentDisposition);
            }
            if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
                $window.navigator.msSaveOrOpenBlob(blob, downloadName);
            } else {
                var file = new Blob([blob], {type: 'application/pdf'});
                var objectUrl = URL.createObjectURL(file);
                $window.open(objectUrl, '_blank');
            }
        }

        function getFileName(contentDisposition){
            var filename = "";
            if (contentDisposition) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                  filename = matches[1].replace(/['"]/g, '');
                }
            }
            return filename;
        }
    }
})();
