(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('pastMedicalHistoryComponent', pastMedicalHistoryComponent);

    pastMedicalHistoryComponent.$inject = [];

    function pastMedicalHistoryComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '=',
                pastMedicalHistoryGroups: '='
            },
            link: linkFunc,
            controller: DirectivePastMedicalHistoryController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/past-medical-history.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectivePastMedicalHistoryController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectivePastMedicalHistoryController($scope, $stateParams, $element, $timeout) {
        
        function init() {
            
        }

        init();
    }
})();
