(function () {
    "use strict";

    angular.module("ipmApp").controller("FooterController", FooterController);

    FooterController.$inject = ["$scope"];

    function FooterController($scope) {
        var vm = this;
        vm.currentYear = new Date().getFullYear();
    }
})();
