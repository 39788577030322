(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DeckEditorController', DeckEditorController);

    DeckEditorController.$inject = ['$scope', '$rootScope','$state', '$stateParams', 'isCreateNew', 'Deck', 'WellnessQuestion', 'Upload'];

    function DeckEditorController($scope, $rootScope, $state, $stateParams, isCreateNew, Deck, WellnessQuestion, Upload) {
        var vm = this;
        vm.isSaving = false;
        vm.isCreateNew = isCreateNew;
        vm.init = init;
        vm.save = save;
        vm.hasChanged = false;
        vm.deck = null;
        vm.slideType = ['PRESENTATION', 'QUESTION'];
        vm.questionRepeatType = ['INITIAL', 'REPEAT'];
        vm.addSlide = addSlide;
        vm.removeSlide = removeSlide;
        init();

        function init() {
            if ($stateParams.id) {
                Deck.get({id : $stateParams.id}, function(res) {
                    vm.deck = res;
                });
            } else {
                vm.deck = {
                    name: null,
                    slides: [{}]
                };
            }
            
            loadQuestion();
        }
        
        function loadQuestion () {
            WellnessQuestion.query({
                searchKey: "",
                page: 0,
                size: 1000,
                sort: "name"
            }, onSuccess, onError);

            
            function onSuccess(data, headers) {
                vm.questions = data;
            }
            function onError(error) {
                //AlertService.error(error.data.message);
            	 vm.isSaving = false;
            }
        }


        function save() {
            vm.isSaving = true;
            Deck.save(vm.deck, onSaveSuccess, onSaveError);
        }
        
        var onSaveSuccess = function (result) {
        	vm.isSaving = false;
        	$state.go('deck');
        };
        
        vm.uploadChanged = function (files, slide) {
            if(files.length > 0) {
                slide = files[0];
                vm.hasChanged = true;
            }
        }

        var onSaveError = function () {
            vm.isSaving = false;
        };


        function addSlide() {
            vm.deck.slides.push({});
        }

        function removeSlide(index) {
            vm.deck.slides = _.reject(vm.deck.slides, function(v, i) { return index === i });
        }
        
        vm.removePdf = function(slide) {
            slide.fileId = null;
            slide.file = null;
        };

        vm.downloadPdf = function (slide) {
            console.log("download pdf by file id: " + slide.id);
            Deck.downloadSlideFile( slide.id);
        };

        vm.back = function () {
            $state.go('deck');
        };
    }
})();
