(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ClassSessionDeleteController',ClassSessionDeleteController);

        ClassSessionDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'ClassSessionService'];

    function ClassSessionDeleteController($scope, $uibModalInstance, entity, ClassSessionService) {
        var vm = this;
        vm.classSession = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            // ClassSessionService.delete({id: id},
            //     function () {
            //         $uibModalInstance.close(true);
            //     });
            ClassSessionService.deleteSesion(id).then(function () {
                $scope.$emit('ipmApp:classSessionUpdate');
                $uibModalInstance.close(true);
            })
        };
    }
})();
