(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ClassSessionRegistrationDeleteController', ClassSessionRegistrationDeleteController);

    ClassSessionRegistrationDeleteController.$inject = ['$uibModalInstance', 'entity', 'ClassSessionRegistrationService'];

    function ClassSessionRegistrationDeleteController($uibModalInstance, entity, ClassSessionRegistrationService) {
        var vm = this;

        vm.classSessionRegistrationId = entity ? entity.id : null;
        vm.wellnessClassName = entity && entity.classSession && entity.classSession.wellnessClass ? entity.classSession.wellnessClass.name : '';

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isDeleting = false;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            vm.isDeleting = true;
            if (vm.classSessionRegistrationId) {
                ClassSessionRegistrationService.delete({id: vm.classSessionRegistrationId},
                    function () {
                        $uibModalInstance.close(true);
                    });
            }
        }

    }
})();
