(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('InvitationService', InvitationService);

    InvitationService.$inject = ['$resource','$q', 'halClient', '$http', 'DateUtils'];

    function InvitationService($resource, $q, halClient, $http, DateUtils) {
        var baseUrl = 'api/invitations';
        var resourceUrl = "api/invitations/:id";
        var service = $resource(resourceUrl, {}, {});
        service.resendHAQ = resendHAQ;
        service.resendNPQ = resendNPQ;
        service.findBySecretKey = findBySecretKey;

        function findBySecretKey(secretKey) {
            return $http.get(baseUrl, { params: { secretKey: secretKey }}).then(function (response) {
                return response;
            });
        };

        function resendHAQ(surveyResultId) {
            return $http.get(baseUrl + '/re-send-haq', { params: { surveyResultId: surveyResultId }}).then(function (response) {
                return response.data;
            });
        };

        function resendNPQ(nPQQuestionnaireId) {
            return $http.get(baseUrl + '/re-send-npq', { params: { nPQQuestionnaireId: nPQQuestionnaireId }}).then(function (response) {
                return response.data;
            });
        };

        return service;

    }
})();
