(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider
        .state('wellness-question', {
            parent: 'entity',
            url: '/wellness-question?page&sort&search',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.wellnessQuestion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wellness-question/wellness-question.html',
                    controller: 'WellnessQuestionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                searchKey: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        searchKey: $stateParams.searchKey
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wellnessQuestion');
                    $translatePartialLoader.addPart('wellnessQuestionType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('wellness-question.new', {
            parent: 'wellness-question',
            url: '/new',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.wellnessQuestion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wellness-question/wellness-question-editor.html',
                    controller: 'WellnessQuestionEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wellnessQuestion');
                    $translatePartialLoader.addPart('wellnessQuestionType');
                    return $translate.refresh();
                }],
                isCreateNew: function () {
                    return true;
                },
            }
        })
        .state('wellness-question.edit', {
            parent: 'wellness-question',
            url: '/:id/edit',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.wellnessQuestion.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wellness-question/wellness-question-editor.html',
                    controller: 'WellnessQuestionEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wellnessQuestion');
                    $translatePartialLoader.addPart('wellnessQuestionType');
                    return $translate.refresh();
                }],
                isCreateNew: function () {
                    return false;
                },
            }
        })
        .state('wellness-question.delete', {
            parent: 'wellness-question',
            url: '/{id}/delete',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wellness-question/wellness-question-delete-dialog.html',
                    controller: 'WellnessQuestionDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass: 'absolute-center',
                    resolve: {
                        entity: ['WellnessQuestion', function(WellnessQuestion) {
                            return WellnessQuestion.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('wellness-question', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
