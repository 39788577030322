(function () {
    'use strict';

    ChatSupportController.$inject = ['$scope', '$window'];

    function ChatSupportController($scope, $window) {
        var vm = this; //jshint ignore:line
        init();

        function init() {
            vm.isShow = vm.isShow ? vm.isShow : false;
            if ($window.icPatronChat && vm.isShow) {
                showOrHidePatronChat(true);
                if (checkShowChat()) {
                    return;
                }
                $window.icPatronChat.init({
                    serverHost: 'https://home-c61.nice-incontact.com',
                    bus_no: 4605429,
                    poc: '627b7874-c36f-45ce-aa5d-cf92c3f30683',
                    params: ['FirstName', 'Last Name', 'first.last@company.com', 555 - 555 - 5555]
                });
            }
        }

        function showOrHidePatronChat(isShow) {
            var customerChatElement = document.querySelector('.ie-div-position-customer-chat')
            if (customerChatElement) {
                customerChatElement.style.display = isShow ? "block" : "none";
            }
        }

        function checkShowChat() {
            var hasElement = document.querySelector('.ie-div-position-customer-chat');
            return hasElement !== null;
        }

        $scope.$on('$destroy', function () {
            if ($window.icPatronChat) {
                $window.icPatronChat.hide();
                showOrHidePatronChat(false);
            }
        });

    }

    angular.module('ipmApp').component('chatSupport', {
        bindings: {
            isShow: '<'
        },
        template: '',
        controller: ChatSupportController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
