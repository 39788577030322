(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('WellnessClassService', WellnessClassService);

    WellnessClassService.$inject = ['$resource', '$q', 'halClient', '$http'];

    function WellnessClassService($resource,$q, halClient, $http) {
        var baseUrl = 'api/wellness-class';
        var resourceUrl = "api/wellness-class/:id";
        var service = $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'delete': {method: 'DELETE'}
        });

        service.findByClassSessionId = findByClassSessionId;
        service.updateInstructorPatientPresentation = updateInstructorPatientPresentation;

        function findByClassSessionId(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/find-by-class-session-id/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function updateInstructorPatientPresentation(wellnessClassSession) {
            var deferred = $q.defer();
            var url = baseUrl + "/update-instructor-patient-presentation";
            halClient.$post(url, null, wellnessClassSession).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    };
})();
