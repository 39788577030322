(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SectionEditorController', SectionEditorController);

    SectionEditorController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'Section', 'Test', 'Question', '$uibModal'];

    function SectionEditorController($scope, $state, $rootScope, $stateParams, entity, Section, Test, Question, $uibModal) {
        var vm = this;
        vm.isSaving = false;
        vm.section = entity;
        vm.invertScoring = invertScoring;
        vm.questionTypeChange = questionTypeChange;
        vm.deleteQuestion = deleteQuestion;
        vm.sortableOptions = {
            placeholder: "ui-state-highlight",
            helper: function (event, ui) {
                var table = '<table style="position: absolute;" class="jh-table table table-striped"></table>';
                var draggedItem = $(table).append(ui[0].outerHTML);
                return draggedItem;
            },
            update: function(e, ui) {
                
            },
            stop: function(e, ui) {
                var questions = ui.item.sortable.sourceModel;
                questions.forEach(function(question, index) {
                    question.orderNumber = (index + 1);
                });
                // call back-end to update
                Question.updateOrderNumber(vm.section.id, questions).then();
            }
        };
        vm.load = function (id) {
            Section.get({id: id}, function(result) {
                vm.section = result;
            });
        };
        vm.save = function () {
            vm.isSaving = true;
            // set test id
            vm.section.testId = $stateParams.id;
            if (vm.section.id !== null) {
                Section.update(vm.section, onSaveSuccess, onSaveError);
            } else {
                Section.save(vm.section, onSaveSuccess, onSaveError);
            }
        };
        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $state.go('test.edit',{id: $stateParams.id});
        };

        var onSaveError = function () {
            vm.isSaving = false;
            vm.load(vm.section.id);
        };

        var unsubscribe = $rootScope.$on('ipmApp:questionUpdate', function(event, result) {
            vm.load(vm.section.id);
        });

        $scope.$on('$destroy', unsubscribe);

        vm.back = function () {
            $state.go('test.edit',{id: $stateParams.id});
        };

        function invertScoring(question) {
            if (question) {
                question.invertScoring = !question.invertScoring
                Question.invertScoring(question.id).then(function(res) {});
            }
        }

        function questionTypeChange() {
            vm.disabledCreateQuestion = true;
        }



        function deleteQuestion(question, index) {
            $uibModal.open({
                templateUrl: 'app/entities/question/question-delete-dialog.html',
                controller: 'QuestionDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: question
                }
            }).result.then(function() {
                vm.section.questions.splice(index, 1);
                // re-index
                angular.forEach(vm.section.questions, function (question, index) {
                    question.orderNumber = index + 1;
                });
                // call back-end to update
                Question.updateOrderNumber(vm.section.id, vm.section.questions).then();
            }, function() {
                // $state.go('^');
            });
        }

    }
})();
