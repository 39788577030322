(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('haq-evaluation', {
            parent: 'app',
            url: '/haq-evaluation',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/landing-page.html',
                    controller: 'LandingPageHAQEvaluationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                evaluationEntity: ['HAQEvaluationWorkflowService', function(HAQEvaluationWorkflowService) {
                    return HAQEvaluationWorkflowService.getInQueue();
                }],
                evaluationStorage: [ function () {
                    return new Map();
                }]
            }
        })
        .state('haq-evaluation.workflow', {
            parent: 'haq-evaluation',
            url: '/workflow?timestamp',
            data: {
                authorities: ['ROLE_QHP']
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/haq-evaluation-workflow.html',
                    controller: 'HAQEvaluationWorkflowController',
                    controllerAs: 'vm'
                }
            },
            params: {
                timestamp: null
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                timeSpent: ['HAQEvaluationWorkflowService', function(HAQEvaluationWorkflowService) {
                    return HAQEvaluationWorkflowService.getTimeSpent();
                }],
                timerHasElapsed: ['HAQEvaluationWorkflowService', 'ClearLocalStorageService', function(HAQEvaluationWorkflowService, ClearLocalStorageService) {
                    var timerHasElapsed = ClearLocalStorageService.getTimerHasElapsed();
                    if (timerHasElapsed) {
                        return parseInt(timerHasElapsed);
                    }
                    return HAQEvaluationWorkflowService.getTimerHasElapsed();
                }],
                evaluationEntity: ['HAQEvaluationWorkflowService', 'ClearLocalStorageService', function(HAQEvaluationWorkflowService, ClearLocalStorageService) {
                    var actionEvaluation = ClearLocalStorageService.getActionEvaluationWorkflow();
                    return HAQEvaluationWorkflowService.getInQueue(actionEvaluation);
                }]
            }
        })
        .state('haq-evaluation.submit', {
            parent: 'haq-evaluation',
            url: '/submit',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/submit-page.html',
                    controller: 'SubmitPageHAQEvaluationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('landingPageHaq');
                    return $translate.refresh();
                }],
                signature: ['HAQEvaluationWorkflowService', function(HAQEvaluationWorkflowService) {
                    return HAQEvaluationWorkflowService.getMostRecentSignature(true);
                }]
            }
        });
    }

})();
