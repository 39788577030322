(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultDetailController', SurveyResultDetailController);

    SurveyResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'SurveyResult', 'Patient', 'Battery'];

    function SurveyResultDetailController($scope, $rootScope, $stateParams, entity, SurveyResult, Patient, Battery) {
        var vm = this;
        vm.surveyResult = entity;
        vm.load = function (id) {
            SurveyResult.get({id: id}, function(result) {
                vm.surveyResult = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:surveyResultUpdate', function(event, result) {
            vm.surveyResult = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
