(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('OptedOutHAQTestDialogController', OptedOutHAQTestDialogController);

    OptedOutHAQTestDialogController.$inject = ['$uibModalInstance', 'patientCase'];

    function OptedOutHAQTestDialogController($uibModalInstance, patientCase) {
        var vm = this;
        vm.exclusionReason = '';

        // Public Functions
        vm.yes = yes;
        vm.no = no;

        function yes() {
            var patientExclusionReason = {
                exclusionReason: vm.exclusionReason,
                patientCase: patientCase
            };
            $uibModalInstance.close(patientExclusionReason);
            $uibModalInstance.dismiss('cancel');
        }

        function no() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
