(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('ShowUnexpectedErrorDialogService', ShowUnexpectedErrorDialogService);

        ShowUnexpectedErrorDialogService.$inject = ['$uibModal'];

    function ShowUnexpectedErrorDialogService ($uibModal) {

        var service = {
            'isOpen': isOpen,
            // 'close': close,
            // 'dismiss': dismiss,
            'open': open,
        };

        var open = false,
        modalInstance;

    function isOpen () {
        return open;
    };

    function open(res, functionName, currentQuestionObj) {
        if (open) {
            return;
        }
        var modal = $uibModal.open({
            controller: function ($uibModalInstance) {
                var vm = this;
                vm.currentQuestionObj = currentQuestionObj;
                vm.functionName = functionName;
                vm.statusCode = res.status;
                if (_.isObject(res.data)) {
                    vm.errorDetails = res.data.description;
                } else {
                    vm.errorDetails = res.data;
                }

                vm.close = function() {
                    $uibModalInstance.close(true);
                }
                
                vm.getResponse = function() {
                    if (vm.currentQuestionObj) {
                        var answer = vm.currentQuestionObj.answer;
                        if (answer) {
                            var questionOptions = _.filter(vm.currentQuestionObj.questionOptions, function(o) { 
                                return o.value == answer;
                            });
                            if (!_.isEmpty(questionOptions)) {
                                return questionOptions[0].name;
                            }
                        }
                    }
                    return null;
                }
            },
            templateUrl: 'app/survey-workflow/partial/unexpected-error-dialog.html',
            controllerAs: 'vm',
            size: 'sm',
            backdrop: 'static',
            keyboard  : false,
            windowClass: 'absolute-center error-dialog'
        });

        //Set open
        open = true;

        //Set modalInstance
        modalInstance = modal;

        //Modal is closed/resolved/dismissed
        modal.result.finally(function () {
            open = false;
        });

        return modal;
    };
    return service;
    };
})();
