(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultController', SurveyResultController);

    SurveyResultController.$inject = ['$scope', '$state', 'SurveyResult', 'SurveyResultSearch'];

    function SurveyResultController ($scope, $state, SurveyResult, SurveyResultSearch) {
        var vm = this;
        vm.surveyResults = [];
        vm.loadAll = function() {
            SurveyResult.query(function(result) {
                vm.surveyResults = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SurveyResultSearch.query({query: vm.searchQuery}, function(result) {
                vm.surveyResults = result;
            });
        };
        vm.loadAll();
        
    }
})();
