(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$location', '$state', 'Auth', 'Principal', 'ENV', 'LoginService', '$scope', 'ipmConstants', '$rootScope', '$uibModal', '$translate', '$translatePartialLoader', '$localStorage', '$window', 'ClearLocalStorageService'];

    function NavbarController ($location, $state, Auth, Principal, ENV, LoginService, $scope, constants, $rootScope, $uibModal, $translate, $translatePartialLoader, $localStorage, $window, ClearLocalStorageService) {
        var vm = this;

        $translatePartialLoader.addPart('surveyWorkflow');
        $translate.refresh();

        vm.navCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.inProduction = ENV === 'prod';
        vm.login = login;
        vm.logout = logout;
        vm.$state = $state;
        vm.checkStateShowCPR = checkStateShowCPR;
        vm.isStateCPR = isStateCPR;
        vm.isStateCPRWorkflow = isStateCPRWorkflow;
        vm.navBarHomeId = 1;
        vm.navBarCPRId = 2;
        vm.isHideWellnessClass = constants.HIDE_WELLNESS_CLASSES;

        setBrowserClass();

        getAccount(true);

        $scope.$on(constants.EVT_AUTHENTICATION_SUCCESS, function() {
            getAccount(false);
        });

        function getAccount(force) {
             Principal.identity(force).then(function(res) {
                vm.account = res;
             });
        }

        function login () {
            $rootScope.sessionTimeOut = null;
            LoginService.open();
        }

        function logout () {
            Auth.logout();
            $state.go('home');
            ClearLocalStorageService.clearAfterLogout();
            $rootScope.$broadcast(constants.EVT_LOG_OUT);
        }

        vm.showSkypeDialog = function() {
            $uibModal.open({
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.close = function() {
                        $uibModalInstance.dismiss('cancel');
                    }
                },
                templateUrl: 'app/survey-workflow/partial/skype-confirm-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        }

        vm.isActive = function (viewLocation) {
            return viewLocation === $location.path();
        };

        vm.isStaffOrManager = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_STAFF') !== -1 || vm.account.authorities.indexOf('ROLE_MANAGER') !== -1)) {
                return true;
            }
            return false;
        };
        vm.carePlanReview = function(navBarId) {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_QHP') !== -1)) {
                var lengthTaskAsignMax = 2;
                var tasks = vm.account.qhpUserDetail && vm.account.qhpUserDetail.tasks ? vm.account.qhpUserDetail.tasks : [];
                if (navBarId === vm.navBarHomeId && tasks && tasks.length > 1
                    && isStateWellnesClass()) {
                    return true;
                }
                // navBar Home id = 1
                if (navBarId === vm.navBarHomeId && tasks && tasks.length < lengthTaskAsignMax
                    && (isStateCPRWorkflow() || isStateCPR())) { // fix bug IPMproject-B1292, IPMproject-B1291
                    return false;
                }
                // navBar carePlanReview id = 2
                if (navBarId === vm.navBarCPRId && isStateCPR()) { // IPMproject-B1295
                    return false;
                }
                // apply only task list carePlanReview
                var isStateShowCarePlanReview = vm.checkStateShowCPR();
                if (isStateShowCarePlanReview && tasks && tasks.length > 0 && (navBarId === vm.navBarHomeId || (navBarId === vm.navBarCPRId && tasks.indexOf('CARE_PLAN_REVIEW') !== -1))) {

                    return true;
                }
            }
            return false;
        };

        vm.checkShowHomeForFRPMDE = function () {
            if (vm.isQHPRole() && (isStateFRPMDE() == true || isStateMDEReport() == true) || isStateMDEReportParent() == true || isStateFRPReport() == true || isStateAdhocHaqEvaluation() || isStateHaqBriefReview()) {
                return true;
            }
            return false;
        };

        vm.isPatient = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_PATIENT') !== -1)) {
                return true;
            }
            return false;
        };

        vm.isStaff = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_STAFF') !== -1)) {
                return true;
            }
            return false;
        };

        vm.isQHPRole = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_QHP') !== -1)) {
                return true;
            }
            return false;
        };

        vm.isShowWellnessClassList = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_QHP') !== -1) && isStatePresentationControls()) {
                return true;
            }
            return false;
        };

        vm.isHaqEvaluationTestMode = function () {
            if (vm.account && (vm.account.authorities.indexOf('ROLE_QHP') !== -1) && vm.account.haqEvaluationTestMode) {
                return true;
            }
            return false;
        }

        function searchString(data) {
            for (var i = 0; i < data.length; i++) {
                var dataString = data[i].string;
                var dataProp = data[i].prop;
                if (dataString) {
                    if (dataString.indexOf(data[i].subString) != -1)
                        return data[i].identity;
                } else if (dataProp)
                    return data[i].identity;
            }
        };

        function checkStateShowCPR () {
            return $state && $state.current && $state.current.url
                && ($state.current.url === '/care-plan-review'
                    || $state.current.url === '/care-plan-review-workflow'
                    || $state.current.url === '/haq-evaluation'
                    || ($state.current.parent === 'haq-evaluation' && ($state.current.url === '/workflow' || $state.current.url === '/submit')));
        };

        function isStateFRPMDE() {
            return $state && $state.current
                && (($state.current.name && $state.current.name === 'frp-mde')
                    || ($state.current.parent && $state.current.parent === 'frp-mde'));
        };

        function isStateMDEReport() {
            return $state && $state.current && $state.current.name
                && ($state.current.name === 'mde-report');
        };

        function isStateMDEReportParent() {
            return $state && $state.current && $state.current.parent
                && ($state.current.parent === 'mde-report');
        };

        function isStateFRPReport() {
            return $state && $state.current && (
                ($state.current.name && $state.current.name === 'frp-report')
                || ($state.current.parent && $state.current.parent === 'frp-report')
            );
        };

        function isStateWellnesClass() {
            return $state && $state.current && $state.current.url
                && ($state.current.url === '/wellness-class-list' || $state.current.parent === 'wellness-class-list');
        };

        function isStateCPR() {
            return $state && $state.current && $state.current.url
                && ($state.current.url === '/care-plan-review');
        };

         function isStateCPRWorkflow() {
            return $state && $state.current && $state.current.url
                && ($state.current.url === '/care-plan-review-workflow');
        };

        function isStatePresentationControls() {
            return $state && $state.current && $state.current.name
                && $state.current.name === 'presentation-controls-instructor';
        };

        function isStateAdhocHaqEvaluation() {
            return $state && $state.current && (
                ($state.current.name && $state.current.name === 'adhoc-haq-evaluation')
                || ($state.current.parent && $state.current.parent === 'adhoc-haq-evaluation')
            );
        };

        function isStateHaqBriefReview() {
            return $state && $state.current && (
                ($state.current.name && $state.current.name === 'haq-brief-review')
                || ($state.current.parent && $state.current.parent === 'haq-brief-review')
            );
        };

        function setBrowserClass() {
            var dataOS = [
                {
                    string: navigator.platform,
                    subString: "Win",
                    identity: "Windows"
                },
                {
                    string: navigator.platform,
                    subString: "Mac",
                    identity: "Mac"
                },
                {
                    string: navigator.userAgent,
                    subString: "iPhone",
                    identity: "iPhone/iPod"
                },
                {
                    string: navigator.platform,
                    subString: "Linux",
                    identity: "Linux"
                }
            ];

            vm.OS = searchString(dataOS) || "unknownOS";
            if (vm.OS == "Mac") {
                angular.element(document.querySelector('body')).addClass(vm.OS.toLowerCase());
            }
        };

        $scope.$on(constants.EVT_AUTHENTICATION_SUCCESS_BY_INVITATION, function() {
            getAccount(true);
        });

        vm.isAdmin = function () {
            if (vm.account && vm.account.authorities.indexOf('ROLE_ADMIN') !== -1) {
                return true;
            }
            return false;
        };

        vm.isBiller = function () {
            if (vm.account && vm.account.authorities.indexOf('ROLE_BILLER') !== -1) {
                return true;
            }
            return false;
        };

        vm.goToHome = function () {
            $state.go('home');
        };
    }
})();