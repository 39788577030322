(function() {
    'use strict';


    HaqEvaluationQuestionController.$inject = ['$timeout', '$state'];

    function HaqEvaluationQuestionController($timeout, $state) {
        var vm = this; //jshint ignore:line
        vm.isSingleChoiceQuestion = isSingleChoiceQuestion;
        vm.isCheckBoxQuestion = isCheckBoxQuestion;
        vm.isNumericalEntryQuestion = isNumericalEntryQuestion;
        vm.findAndOutputParentAnswer = findAndOutputParentAnswer;
        vm.findAndOutputTheSameLevelAnswer = findAndOutputTheSameLevelAnswer;
        vm.isFreeTextQuestion = isFreeTextQuestion;
        vm.handleCheckboxChange = handleCheckboxChange;
        vm.isOtherCheckboxTextInputRequired = isOtherCheckboxTextInputRequired;
        vm.isOtherSingleChoiceTextInputRequired = isOtherSingleChoiceTextInputRequired;
        vm.handleFABQ_TSK_PCS = handleFABQ_TSK_PCS;
        vm.isMultipleCheckBoxColumnQuestion = isMultipleCheckBoxColumnQuestion;
        vm.getStringAnswerFromMultipleColumnCheckboxValue = getStringAnswerFromMultipleColumnCheckboxValue;
        vm.sortQuestionItemByName = sortQuestionItemByName;
        vm.checkClearChildByCheckboxValues = checkClearChildByCheckboxValues;
        vm.isCheckboxQuestionHaveOption = isCheckboxQuestionHaveOption;
        vm.isDisplayQuestionName = isDisplayQuestionName;
        
        vm.keyOtherCheckBoxOnEvaluationStoreage = vm.question.code + '-otherCheckbox';

        init();

        function init() {

            

            if (vm.isCheckBoxQuestion()) {
                vm.checkboxValues = [];
                vm.ortherCheckboxExcludeIds = [];
                // get other checkbox from evaluation store if have check
                var a = vm.evaluationStorage.get(vm.keyOtherCheckBoxOnEvaluationStoreage) 
                var z = vm.answers.get(vm.question.code);
                if (a) {
                    _.forEach(a, function (b) {
                        var c = _.find(vm.question.questionItems, function(d) { return b.id === d.id; });
                        if (!c) {
                            vm.question.questionItems.push(b);
                        }
                        // handle case change parent answer, remove other checkbox selected
                        if (!z) {
                            _.remove(vm.question.questionItems, function(d) { return b.id === d.id; });
                        }
                    });
                }
                // order child by order number
                vm.question.questionItems = _.orderBy(vm.question.questionItems, ['orderNumber'], ['esc']);
                vm.sortQuestionItemByName();
                // init answer
                if (vm.answers) {
                    vm.checkboxValues = vm.answers.get(vm.question.code) ? vm.answers.get(vm.question.code) : [];
                    _.forEach(vm.question.questionItems, function (q) {
                        q.checked = false;
                        var k = _.find(vm.checkboxValues, function(l) { return l.value === q.value});
                        if(k) {
                            q.checked = true;
                        } else if (q.name === 'Other') {
                            var y = _.find(vm.checkboxValues, function(l) { return l.name === q.name});
                            if (y) {
                                q.checked = true;
                                vm.valueOtherCheckboxTextInputValue = y.value;
                            }
                        }
                        vm.ortherCheckboxExcludeIds.push(q.id);
                    });
                    // [Start] IPMproject-Q328: Confirm the logic of item #3
                    if (vm.question.code === "Q-21" || vm.question.code === "Q-39") {
                        var newValueCheckbox = [];
                        _.forEach(vm.checkboxValues, function (q) {
                            var k = _.find(vm.question.questionItems, function(l) { return l.value === q.value});
                            if (k) {
                                newValueCheckbox.push(q);
                            }
                        });
                        vm.checkboxValues = newValueCheckbox;
                        vm.answers.set(vm.question.code, newValueCheckbox);
                        vm.onAnswerChange({ data: { question: vm.question, value: vm.checkboxValues, clearChilds: false }});
                    }
                    // [End] IPMproject-Q328: Confirm the logic of item #3
                }
            } else if (vm.isMultipleCheckBoxColumnQuestion()) {
                vm.sortQuestionItemByName();
                if(vm.question.type === 'CONTROLLED_SUBSTANCES' || vm.question.type === 'PSYCHOTROPIC_MEDICATIONS') {
                    vm.question.questionItems = _.orderBy(vm.question.questionItems, ['name'], ['esc']);
                }
                if (vm.question.code === 'Q-6' || vm.question.code === 'Q-24') { // IPMproject-341 -> feedback #3: Please combine the questions....
                    vm.multipleCheckBoxColumnConfig = { 
                        answers: vm.answers,
                        question: vm.question, 
                        checkboxItems: vm.question.questionItems, 
                        columns: [{value: 'ipm_prescribed'}, {value: 'externally_prescribed'}] }
                        if (!vm.answers.get(vm.question.code)) {
                            vm.newAnswers.set(vm.question.code, {ipm_prescribed: [], externally_prescribed: []});   
                        }
                }
            } else {
                // init for single choice if childs not have parent answer [EPWORTH && ISI]
                var list = [];
                if (vm.isSingleChoiceQuestion()) {
                    _.forEach(vm.question.childs, function (child) {
                        if (!child.parentAnswer) {
                            list.push(child);
                        }
                    });
                    if (!_.isEmpty(list)) {
                        var i = 100;
                        _.mapValues(vm.question.optionNames, function(o) {
                            _.forEach(list, function (l) {
                                var k = _.cloneDeep(l);
                                k.parentAnswer = o.value;
                                k.id = l.id + i; // avoid duplicate on UI
                                // find existing
                                if (_.isEmpty(_.filter(vm.question.childs, function (existingItem) {
                                    return existingItem.id === k.id
                                }))) {
                                    vm.question.childs.push(k);
                                }
                                i = i + 10;
                            });
                            
                        });
                    }
                    // IPMproject-Q320
                    _.forEach(vm.question.optionNames, function (option) {
                        if (option.value === 'Other') {
                            var otherAnswer = vm.answers.get(vm.question.code);
                            if (otherAnswer) {
                                var parts = otherAnswer.split('---');
                                var otherCheckboxValue = parts[0];
                                vm.valueOtherSingleChoiceTextInputValue = parts[1];
                                vm.answers.set(vm.question.code, otherCheckboxValue);
                            }
                        }
                    });
                }
                // order child by order number
                vm.question.childs = _.orderBy(vm.question.childs, ['orderNumber'], ['esc']);
                // init answer
                if (vm.answers) {
                    vm.valueAnswer = vm.answers.get(vm.question.code);
                }
            }
        }

        vm.isDisplaySubQuestion = function(subQuestion) {
            if (vm.question.answerType === 'SINGLE_CHOICE') {
                return subQuestion.parentAnswer === vm.valueAnswer;
            }
            if (!subQuestion.specialCase) {
                return true;
            } else {
                return vm.handleSpecialCase(subQuestion);
            }
        }

        vm.handleYesNoChange = function () {
            vm.valueOtherSingleChoiceTextInputValue = undefined; // clear value
            vm.onAnswerChange({ data: { question: vm.question, value: vm.valueAnswer, clearChilds: true } });
        }

        function handleCheckboxChange(item, checked) {
            var k = undefined;
            if (item.name === 'Other') {
                k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other'});
                if (!checked) {
                    vm.valueOtherCheckboxTextInputValue = undefined;
                }
            } else {
                k = _.find(vm.checkboxValues, function(l) { return l.value === item.value});
            }
            if (k) {
                _.remove(vm.checkboxValues, function(n) {
                    return n.value === item.value || (k.name === 'Other' && n.name === 'Other');
                  });
            } else {
                if (checked) {
                    vm.checkboxValues.push({name: item.name, value: item.value, orderNumber: item.orderNumber});
                }
            }
            var isClearChilds = vm.checkClearChildByCheckboxValues(); // fix bug : IPMproject-B1188
            vm.onAnswerChange({ data: { question: vm.question, value: vm.checkboxValues, clearChilds: isClearChilds }});
        }

        vm.handleSubQuestionAnswerChange = function (data) {
            resetExplanationNA();
            vm.onAnswerChange({ data: data, clearChilds: true});
            vm.findAndOutputParentAnswer(vm.mainQuestion, data.question.parentCode);
        };

        vm.handleNumericalChange = function () {
            vm.onAnswerChange({ data: { question: vm.question, value: vm.valueAnswer, clearChilds: true } });
        }

        vm.handleFreeTextChange = function () {
            vm.onAnswerChange({ data: { question: vm.question, value: vm.valueAnswer, clearChilds: true } });
        }

        vm.handleOtherCheckboxTextInput = function () {
            var k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other' });
            k.value = vm.valueOtherCheckboxTextInputValue;
            var isClearChilds = vm.checkClearChildByCheckboxValues(); // fix bug : IPMproject-B1188
            vm.onAnswerChange({ data: { question: vm.question, value: vm.checkboxValues, clearChilds: isClearChilds } });
        }

        vm.handleOtherSingleChoiceTextInput = function () {
            if (vm.valueAnswer === 'Other') {
                vm.onAnswerChange({ data: { question: vm.question, value: vm.valueAnswer + '---' + vm.valueOtherSingleChoiceTextInputValue, clearChilds: true } });
            }
        }

        vm.handleMultipleColumnCheckboxAnswerChange = function (data) {
            vm.onAnswerChange({ data: { question: data.question, value: data.value, clearChilds: true } });
        }

        vm.getTextForOption = function(key) {
            return vm.optionName.get(key);
        }

        function isSingleChoiceQuestion() {
            return vm.question && vm.question.answerType === 'SINGLE_CHOICE';
        }

        function isCheckBoxQuestion() {
            return vm.question && vm.question.answerType === 'CHECKBOX';
        }

        function isNumericalEntryQuestion() {
            return vm.question && vm.question.answerType === 'NUMERICAL_INPUT';
        }

        function isFreeTextQuestion() {
            return vm.question && vm.question.answerType === 'FREE_TEXT_INPUT';
        }

        function isMultipleCheckBoxColumnQuestion() {
            return vm.question && vm.question.answerType === 'MULTIPLE_COLUMN_CHECKBOX';
        }

        function isCheckboxQuestionHaveOption() {
            return vm.question.questionItems && vm.question.questionItems.length > 0;
        }

        function isDisplayQuestionName() {
            if (vm.isCheckBoxQuestion()) {
                if (!vm.isCheckboxQuestionHaveOption()) {
                    return false;
                }
            }
            return vm.question.name ? true : false;
        }

        vm.checkboxChecked = function(option) {
            if (_.indexOf(vm.checkboxValues, option.value) === -1) {
                return false;
            }
            return true;
        }

        function checkClearChildByCheckboxValues() {
            var isClearChilds = false;
            if (_.isEmpty(vm.checkboxValues)) {
                isClearChilds = true;
            }
            return isClearChilds;
        }

        /**
         * This function Description:
         * - find and output all answer of tree to parent component
         **/
        function findAndOutputParentAnswer(question, parentCode) {
            var parentQuestion;
            if (question.code === parentCode) {
                parentQuestion = question;
            } else {
                _.forEach(question.childs, function(c) {
                    if (c.code === parentCode) {
                        parentQuestion = c;
                    } else {
                        vm.findAndOutputParentAnswer(c, parentCode);// find and output in childs of tree
                    }
                })
            }
            if (parentQuestion) {
                var parentValue = vm.answers.get(parentCode);
                    if (parentValue) {
                        vm.onAnswerChange({ data: { question: { code: parentQuestion.code }, value: parentValue }});
                    }
                if (parentQuestion.parentCode) {
                    vm.findAndOutputParentAnswer(vm.mainQuestion, parentQuestion.parentCode); // find and output parent of tree
                }
                vm.findAndOutputTheSameLevelAnswer(parentQuestion); // find and output the same level and childs of tree
            }
        }

        function findAndOutputTheSameLevelAnswer(question) {
            if (question) {
                _.forEach(question.childs, function(c) {
                    var value = vm.answers.get(c.code);
                    if (value) {
                        vm.onAnswerChange({ data: { question: { code: c.code }, value: value }});
                    }
                    vm.findAndOutputTheSameLevelAnswer(c);
                });
            }
        }

        vm.checkBoxRequired = function() {
            var result = true;
            for(var i = 0; i < vm.question.questionItems.length; i++) {
                var n = vm.question.questionItems[i];
                if (n.checked) {
                    result = false;
                    break;
                }
            }
            return result;
        }

        vm.handleOrtherCheckboxSelected = function(item) {
            if (item) {
                // auto checked
                item.checked = true;
                vm.question.questionItems.push(item);
                vm.handleCheckboxChange(item, item.checked);
                vm.ortherCheckboxExcludeIds.push(item.id);

                // push into evaluationStorage
                if (vm.evaluationStorage) {

                    var u = vm.evaluationStorage.get(vm.keyOtherCheckBoxOnEvaluationStoreage);
                    if (!u) {
                        u = [];
                        vm.evaluationStorage.set(vm.keyOtherCheckBoxOnEvaluationStoreage, u);
                    }
                    u.push(item);
                }
            }
        }

        vm.isFreeTextInputRequired = function() {
            /*if (vm.question.code === 'Q-5') { // is common last
                return false;
            }*/
            if (vm.question.code === 'Q-59' || vm.question.code === 'Q-83') { // [EPWORTH && ISI] Are there other things causing you to wake in the night?
                return false;
            }
            return true;
        }

        /**
         * IPMproject-342
         * requiring 50+ words (tokenize the text using spaces, and count the # of tokens).
         * @returns {number}
         */
        vm.minlengthInputRequired = function () {
            if (vm.isCommonLast()) { // is common last
                return 50;
            }
            return 0;
        }

        vm.isCommonLast = function () {
            if (vm.question.code === 'Q-5' || vm.question.code === 'Q-8' ||  vm.question.code === 'Q-26') { // is common last
                return true;
            }
            return false;
        }

        function isOtherCheckboxTextInputRequired() {
            if (!vm.question.haveAutoComplete) {
                var k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other' });
                return k ? true : false
            }
            return false;
        }

        function isOtherSingleChoiceTextInputRequired() {
            if (vm.valueAnswer) {
                return vm.valueAnswer === 'Other' ? true : false
            }
            return false;
        }

        function resetExplanationNA() {
            var isExplanationShown = checkShowExplanationNA();
            // Reset explanations for "N/A"
            if (!isExplanationShown) {
                resetAnswerIfNotSelectNA('Q-168');
                resetAnswerIfNotSelectNA('Q-169');
            }
        }

        function resetAnswerIfNotSelectNA(questionCode) {
            if (vm.answers.get(questionCode)) {
                vm.answers.set(questionCode, null);
            }
        }

        function checkShowExplanationNA() {
            if (!vm.mainQuestion) {
                return false;
            }

            var Q_26 = 'Q-26';
            var Q_8 = 'Q-8';

            // Check if the main question is Q-8 or Q-26
            if (vm.mainQuestion.code === Q_8 || vm.mainQuestion.code === Q_26) {
                var answers = vm.answers;
                var keysToCheck = ['Q-14', 'Q-15', 'Q-16', 'Q-17', 'Q-32', 'Q-33', 'Q-34', 'Q-35'];
                var countAnswerNa = 0;

                for (var key in keysToCheck) {
                    var keyAnswer = answers.get(keysToCheck[key]);

                    // Check if the answer is "N/A"
                    if (keyAnswer && keyAnswer === "N/A") {
                        countAnswerNa++;
                    }
                }

                // Return true if at least one answer is "N/A"
                return countAnswerNa > 0;
            }

            return false; // Return false if the main question is not Q-8 or Q-26
        }


        vm.isDisplayFreeTextOtherCheckbox = function() {
            return isOtherCheckboxTextInputRequired();
        }

        vm.isDisplayFreeTextOtherSingleChoice = function() {
            return isOtherSingleChoiceTextInputRequired();
        }

        vm.isDisplayCheckBoxInline = function() {
            return Object.entries(vm.question.optionNames).length < 3;
        }

        vm.getPlaceHolderForOtherCheckbox = function() {
            if (vm.question.code === 'Q-91' || vm.question.code === 'Q-91') {
                return 'List any psychological issues identified in the chart';
            }
            return '';
        }

        vm.handleSpecialCase = function(subQuestion) {
            // COMM
            if (subQuestion.code === 'Q-17') {
                var q6 = vm.getStringAnswerFromMultipleColumnCheckboxValue('Q-6');
                 if (q6 === 'YES' || vm.answers.get('Q-7') === 'YES'
                    || (vm.newAnswers.get('Q-22') && vm.newAnswers.get('Q-22').length > 0)
                    || (vm.answers.get('Q-22') && vm.answers.get('Q-22').length > 0)) {
                    return true;
                } else {
                    vm.newAnswers.delete('Q-17');
                    vm.answers.delete('Q-17');
                    vm.findAndOutputParentAnswer(vm.mainQuestion, subQuestion.parentCode); // re-out out parent answer without 'Q-17'
                    return false;
                }
            }
            if (subQuestion.code === 'Q-18') {
                var q6 = vm.getStringAnswerFromMultipleColumnCheckboxValue('Q-6');
                if (q6 === 'YES' || (vm.newAnswers.get('Q-22') && vm.newAnswers.get('Q-22').length > 0)
                    || (vm.answers.get('Q-22') && vm.answers.get('Q-22').length > 0)) {
                    return true;
                } else {
                    vm.newAnswers.delete('Q-18');
                    vm.answers.delete('Q-18');
                    vm.onAnswerChange({ data: { question: { code: subQuestion.code }, value: "" }}); // reset answer with code 'Q-18'
                    return false;
                }
            }
            if (subQuestion.code === 'Q-168') {
                return checkShowExplanationNA();
            }
            // SOAPP_5
            if (subQuestion.code === 'Q-35') {
                var q24 = vm.getStringAnswerFromMultipleColumnCheckboxValue('Q-24');
                if (q24 === 'YES' || vm.answers.get('Q-25') === 'YES'
                    || (vm.newAnswers.get('Q-40') && vm.newAnswers.get('Q-40').length > 0)
                    || (vm.answers.get('Q-40') && vm.answers.get('Q-40').length > 0)) {
                    return true;
                } else {
                    vm.newAnswers.delete('Q-35');
                    vm.answers.delete('Q-35');
                    vm.findAndOutputParentAnswer(vm.mainQuestion, subQuestion.parentCode); // re-out out parent answer without 'Q-35'
                    return false;
                }
            }
            if (subQuestion.code === 'Q-36') {
                var q24 = vm.getStringAnswerFromMultipleColumnCheckboxValue('Q-24');
                if (q24 === 'YES' || (vm.newAnswers.get('Q-40') && vm.newAnswers.get('Q-40').length > 0)
                || (vm.answers.get('Q-40') && vm.answers.get('Q-40').length > 0)) {
                    return true;
                } else {
                    vm.newAnswers.delete('Q-36');
                    vm.answers.delete('Q-36');
                    vm.onAnswerChange({ data: { question: { code: subQuestion.code }, value: "" }}); // reset answer with code 'Q-36'
                    return false;
                }
            }
            if (subQuestion.code === 'Q-169') {
                return checkShowExplanationNA();
            }
            // FAB-Q_TSK_PCS
            if (subQuestion.code === 'Q-112') {
                return vm.handleFABQ_TSK_PCS('Q-109')
            }
            if (subQuestion.code === 'Q-121') {
                return vm.handleFABQ_TSK_PCS('Q-118')
            }
            if (subQuestion.code === 'Q-130') {
                return vm.handleFABQ_TSK_PCS('Q-127')
            }
            if (subQuestion.code === 'Q-139') {
                return vm.handleFABQ_TSK_PCS('Q-136')
            }
            if (subQuestion.code === 'Q-148') {
                return vm.handleFABQ_TSK_PCS('Q-145')
            }
            if (subQuestion.code === 'Q-157') {
                return vm.handleFABQ_TSK_PCS('Q-154')
            }
            if (subQuestion.code === 'Q-166') {
                return vm.handleFABQ_TSK_PCS('Q-163')
            }
        }

        function handleFABQ_TSK_PCS (parentAnswerCode) {
            if (vm.answers.get(parentAnswerCode) && vm.answers.get(parentAnswerCode).length > 0) {
                return true;
            }
            return false;
        }

        function getStringAnswerFromMultipleColumnCheckboxValue(code) {
            if (vm.answers.get(code)) {
                var a = new Map(Object.entries(vm.answers.get(code)));
                if ((a.get('ipm_prescribed') && a.get('ipm_prescribed').length > 0)
                    || (a.get('externally_prescribed') && a.get('externally_prescribed').length > 0)) {
                        return 'YES';
                }
            }
            return 'NO';
        }

        function sortQuestionItemByName() {
            if(vm.question.type === 'CONTROLLED_SUBSTANCES' || vm.question.type === 'PSYCHOTROPIC_MEDICATIONS') {
                vm.question.questionItems = _.orderBy(vm.question.questionItems, ['name'], ['esc']);
            }
        }
    }

    angular.module('ipmApp').component('haqEvaluationQuestion', {
        bindings: {
            question: '<',
            mainQuestion: '<',
            answers: '<',
            newAnswers: '<',
            evaluationStorage: '<',
            onAnswerChange: '&'
        },
        templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/question.html',
        controller: HaqEvaluationQuestionController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
