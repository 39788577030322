(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('InvitationController', InvitationController);

    InvitationController.$inject = ['$state', '$stateParams', '$rootScope', 'ipmConstants', 'InvitationService', '$window', '$localStorage', 'ClearLocalStorageService'];

    function InvitationController ($state, $stateParams, $rootScope, constants, InvitationService, $window, $localStorage, ClearLocalStorageService) {
        var vm = this;
        // if not have secretKey will navigate to error page
        if ($stateParams.secretKey === null || $stateParams.secretKey === '') {
            $state.go('error');
        }
        vm.errorHaqTestService = false;
        vm.secretKey = $stateParams.secretKey;
        vm.errorNotFound = false;
        vm.expiration = false;
        vm.isPageLoadFinish = false;
        vm.isHAQ = false;
        vm.isNPQ = false;
        vm.init = init();

        function init() {
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            ClearLocalStorageService.clearAll();
            
            $window.localStorage.setItem(constants.INVITATION_SECRET_KEY, vm.secretKey);
            InvitationService.findBySecretKey(vm.secretKey).then(function (res) {
                if (res && res.status === 204) {
                    // HAQ_test flag is turned on
                    // If the patient clicks the link in the email they will receive an error message that says, " Looks like something went wrong, you do not need to take this questionaiire at this time. Thank you."
                    vm.errorHaqTestService = true;
                    vm.isPageLoadFinish = true;
                    return;
                }
                var response = res ? res.data : null;
                if (response) {
                    $rootScope.$broadcast(constants.EVT_AUTHENTICATION_SUCCESS_BY_INVITATION);
                    $window.localStorage.setItem('invitationWorkflow', true) // to detect when login to take patient go to npq or haq
                    if (response.invitation.expiration) {
                        vm.expiration = true;
                    } else  if (response.invitation.surveyResult) {
                        vm.isHAQ = true;
                        vm.haqStatus = response.invitation.surveyResult.status;
                        if (vm.haqStatus !== 'COMPLETE' && vm.haqStatus !== 'DOWNLOADED') {
                            $window.localStorage.setItem(constants.SURVEY_RESULT_ID, response.invitation.surveyResult.id);
                            $state.go('home');
                            return;
                        }
                    } else  if (response.invitation.npqQuestionnaire) {
                        vm.isNPQ = true;
                        vm.npqStatus = response.invitation.npqQuestionnaire.status;
                        if (vm.npqStatus !== 'COMPLETE') {
                            $window.localStorage.setItem(constants.NPQ_QUESTIONNAIRE_ID, response.invitation.npqQuestionnaire.id);
                            $state.go('home');
                            return;
                        }
                    }
                    vm.isPageLoadFinish = true;
                }
            }, function(response) {
                vm.isPageLoadFinish = true;
                if (response.status === 404 ) {
                    vm.errorNotFound = true;
                    return;
                }
                $state.go('error');
            });
        }


    }
})();
