(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('survey-result', {
            parent: 'entity',
            url: '/survey-result',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.surveyResult.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/survey-result/survey-results.html',
                    controller: 'SurveyResultController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('surveyResult');
                    $translatePartialLoader.addPart('surveyResultStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('survey-result-detail', {
            parent: 'entity',
            url: '/survey-result/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.surveyResult.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/survey-result/survey-result-detail.html',
                    controller: 'SurveyResultDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('surveyResult');
                    $translatePartialLoader.addPart('surveyResultStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SurveyResult', function($stateParams, SurveyResult) {
                    return SurveyResult.get({id : $stateParams.id});
                }]
            }
        })
        .state('survey-result.new', {
            parent: 'survey-result',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/survey-result/survey-result-dialog.html',
                    controller: 'SurveyResultDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                status: null,
                                createdDate: null,
                                lastModifiedDate: null,
                                disposition: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('survey-result', null, { reload: true });
                }, function() {
                    $state.go('survey-result');
                });
            }]
        })
        .state('survey-result.edit', {
            parent: 'survey-result',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/survey-result/survey-result-dialog.html',
                    controller: 'SurveyResultDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SurveyResult', function(SurveyResult) {
                            return SurveyResult.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('survey-result', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('survey-result.delete', {
            parent: 'survey-result',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/survey-result/survey-result-delete-dialog.html',
                    controller: 'SurveyResultDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SurveyResult', function(SurveyResult) {
                            return SurveyResult.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('survey-result', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
