(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ConfirmYesNoController', ConfirmYesNoController);

    ConfirmYesNoController.$inject = ['$scope', '$uibModalInstance', '$q', '$state', 'isTranslate', 'message', 'title'];

    function ConfirmYesNoController($scope, $uibModalInstance, $q, $state, isTranslate, message, title) {
        var vm = this;
        vm.message = message;
        vm.title = title;
        vm.isTranslate = isTranslate ? isTranslate : false;

        vm.yes = function () {
            $uibModalInstance.close(true);
            $uibModalInstance.dismiss('cancel');
        };

        vm.no = function () {
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        };
    }

})();
