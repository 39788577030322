(function () {
    "use strict";

    angular
        .module("ipmApp")
        .controller(
            "PresentationViewPatientController",
            PresentationViewPatientController
        );

    PresentationViewPatientController.$inject = [
        "$scope",
        "PresentationViewPatientService",
        "ClearLocalStorageService",
        "$window",
        "Deck",
        "ipmConstants"
    ];

    function PresentationViewPatientController(
        $scope,
        PresentationViewPatientService,
        ClearLocalStorageService,
        $window,
        Deck,
        ipmConstants
    ) {
        var vm = this;
        var scaleRate = 1; // presentation view height size / instructor view height size (%)
        var pdfContainer = angular.element(".presentation-view-content");
        vm.zoomToScale = 1;
        vm.pdfCurrentSlide = 1;
        vm.pdfData = null;
        vm.isLoading = false;
        vm.className = ClearLocalStorageService.getNameClassPresentationView();
        vm.init = function () {
            var source = $window.location.origin;
            calculateScaleRate();
            getPDFdata();
            PresentationViewPatientService.listenToOtherSource(source);
            PresentationViewPatientService.addChangePageEvent(changePage);
            PresentationViewPatientService.addChangePDFDataEvent(getPDFdata);
            PresentationViewPatientService.addZoomInEvent(zoomIn);
            PresentationViewPatientService.addZoomOutEvent(zoomOut);
            PresentationViewPatientService.addScrollEvent(scrollView);
            closingWindowEvent();
        };
        vm.init();

        function calculateScaleRate() {
            var pdfViewer = angular.element('.presentation-view-content');
            scaleRate = pdfViewer != undefined ? pdfViewer.width() / ($window.viewerWidth || 1) : 1;
        }

        function closingWindowEvent() {
            $window.onbeforeunload = function (e) {
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                e.returnValue = ''; // Chrome requires returnValue to be set
            };
        }

        function changePage() {
            vm.pdfCurrentSlide =
                ClearLocalStorageService.getCurrentPagePdfViewSlide();
            vm.zoomToScale =

                ClearLocalStorageService.getScaleZoomIn() ||
                ClearLocalStorageService.getScaleZoomOut(); //Task: change to Zoom TO
            $scope.$digest();
        }

        function getPDFdata() {
            var id = ClearLocalStorageService.getCurrentSlideWC();
            if (!id) {
                return;
            }
            vm.pdfData = null;
            Deck.downloadSlideBlobFile(id)
                .then(function (response) {
                    vm.pdfData = response ? response : null;
                    vm.pdfCurrentSlide = 1;
                    vm.isLoading = false;
                    setTimeout(function () {
                        vm.zoomToScale = ipmConstants.PDF_DEFAULT_SCALE * scaleRate;
                    }, 0);
                })
                .catch(function () {
                    vm.isLoading = false;
                });
        }

        function zoomIn() {
            vm.zoomToScale = +ClearLocalStorageService.getScaleZoomIn() * scaleRate;
            $scope.$digest();
        }

        function zoomOut() {
            vm.zoomToScale = +ClearLocalStorageService.getScaleZoomOut() * scaleRate;
            $scope.$digest();
        }

        function scrollView(position) {
            pdfContainer.scrollTop(position.top * scaleRate);
            pdfContainer.scrollLeft(position.left * scaleRate);
        }
    }
})();
