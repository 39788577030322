(function() {
    'use strict';

    angular
        .module('ipmApp')
        .factory('QuestionOptionSearch', QuestionOptionSearch);

    QuestionOptionSearch.$inject = ['$resource'];

    function QuestionOptionSearch($resource) {
        var resourceUrl =  'api/_search/question-options/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
