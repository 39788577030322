(function() {
    'use strict';

    PatientHaqEvaluationsController.$inject = ['$scope', 'HAQEvaluationWorkflowService', '$translate'];

    function PatientHaqEvaluationsController($scope, HAQEvaluationWorkflowService, $translate) {
        var vm = this; //jshint ignore:line

        vm.haqEvaluations = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'surveyResult.id';
        vm.loadAll = loadAll;

        init();

        function init() {
            vm.pageableHaq = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            HAQEvaluationWorkflowService.findByPatientPaganition(vm.patientId, pageable).then(function (response) {
                if (response) {
                    vm.haqEvaluations = response.data;
                    vm.pageableHaq.totalItems = response.totalItems;
                    vm.isLoading = false;
                }
            });
        }

        vm.loadPage = function (page) {
            vm.page = page;
            vm.loadAll();
        }

        vm.getIndex = function(index) {
        	var result = (index + 1) + (vm.page - 1) * vm.pageSize;
        	return result;
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

    }

    angular.module('ipmApp').component('patientHaqEvaluations', {
        bindings: {
            patientId: '<',
            isManager: '<',
            isBiller: '<'
        },
        templateUrl: 'app/patient/haq-evaluations/haq-evaluations.html',
        controller: PatientHaqEvaluationsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                return $translate.refresh();
            }]
        }
    })
})();
