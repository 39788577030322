(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('RequestCaseExclusionsController', RequestCaseExclusionsController);

    RequestCaseExclusionsController.$inject = ['CaseExclusionsService', '$stateParams', '$state', 'User'];

    function RequestCaseExclusionsController (ActivityReportService, $stateParams, $state, User) {
        var vm = this;
        vm.download = download;
        vm.isUserRequest = false;
        vm.noPdf = false;

        init();

        function init() {
            vm.fileId = $stateParams.id;
            User.getUserByCurrentLogin().then(function (response) {
                var currentUser = response;
                vm.isUserRequest = currentUser.id === Number($stateParams.user);
                if (!vm.isUserRequest) {
                    $state.go('home');
                }
            });
        }

        function download() {
            CaseExclusionsService.downloadReport(vm.fileId).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }
    }
})();
