(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('NotesController', NotesController);

    NotesController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$q',
        '$state',
        'CarePlanReviewService',
        'cpr'
    ];

    function NotesController($scope, $uibModalInstance, $q, $state, CarePlanReviewService, cpr) {
        var vm = this;
        vm.cpr = cpr;

        vm.save = function () {
            $uibModalInstance.close(CarePlanReviewService.updateNotes(cpr) ? cpr.notes : null);
            $uibModalInstance.dismiss('cancel');
        }

        vm.cancel = function(){
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
