(function() {
    'use strict';


    HAQBodyPartController.$inject = ['$scope', '$filter', 'Auth', 'Principal', 'ClearLocalStorageService', 'AgreementsWorkflowService', 'ipmConstants'];

    function HAQBodyPartController($scope, $filter, Auth, Principal, ClearLocalStorageService, AgreementsWorkflowService, constants) {
        var vm = this; //jshint ignore:line
        vm.isSaving = false;
        vm.patientInjuredTypes = [];
        vm.injuredTypes = [
            { name: "register.form.injured.legsfeet", value: "LEGS_FEET", checked: false, disabled: false },
            { name: "register.form.injured.backspine", value: "BACK_SPINE", checked: false, disabled: false },
            { name: "register.form.injured.neck", value: "NECK", checked: false, disabled: false },
            { name: "register.form.injured.armhand", value: "ARM_HAND", checked: false, disabled: false },
            { name: "register.form.injured.none", value: "NONE", checked: false, disabled: false }
         ];
         vm.confirmEmailAndInjuredType = confirmEmailAndInjuredType;
         vm.selectedInjuredTypes = selectedInjuredTypes;
        

        init();

        function init() {
            // set default email by email user login
            Principal.identity(false).then(function(account) {
                vm.email = account.email === null ? account.email : account.email.trim();
            });
            vm.injuredTypes.forEach(function(item) {
                if (vm.patientInjuredTypes.length > 0) {
                    item.checked = vm.patientInjuredTypes.includes(item.value);
                    if(vm.patientInjuredTypes[0] && vm.patientInjuredTypes[0] === 'NONE') {
                        if(item.value !== 'NONE') {
                            item.disabled = true;
                        }
                    } else {
                        if(vm.patientInjuredTypes.length > 0) {
                            if(item.value === 'NONE') {
                                item.disabled = true;
                            }
                        }
                    }
                }
            });
        }

        function confirmEmailAndInjuredType() {
            vm.isSaving = true;
            var tempEmail = encodeURIComponent(vm.email);
            Auth.confirmEmailAndInjuredTypes(tempEmail, vm.patientInjuredTypes).then(function(response){
                vm.isSaving = false;
                if(response.data === 'e-mail address already in use'){
                    vm.errorEmailExists = 'ERROR';
                } else {
                    vm.errorEmailExists = null;
                    if(response.status === 200) {
                        vm.onSubmitSuccess();
                    }
                }
            });
        }

        function selectedInjuredTypes(injuredType) {
        	if(injuredType.value === 'NONE') {
        		if(injuredType.checked == true) {
        			vm.patientInjuredTypes = [injuredType.value];
       			 	vm.injuredTypes.forEach(function(item) {
       			 		if(item.value !== 'NONE') {
       			 			item.checked = false;
       			 			item.disabled = true;
       			 		}
                    });
       		 	} else {
       		 		vm.patientInjuredTypes = [];
       		 		vm.injuredTypes.forEach(function(item) {
       		 			if(item.value !== 'NONE') {
       		 				item.disabled = false;
       		 			}
                    });
       		 	}
   		 	} else {
   		 		vm.patientInjuredTypes = $filter('filter')(vm.injuredTypes, {checked: true}).map(function(item) {
   		 			return item.value;
   		 		});
   		 		vm.injuredTypes.forEach(function(item) {
   		 			if(item.value === 'NONE') {
   		 				item.disabled = vm.patientInjuredTypes.length > 0;
   		 			}
   		 		});
   		 	}
        }
        
    }

    angular.module('ipmApp').component('haqBodyPart', {
        bindings: {
            patientInjuredTypes: '<',
            onSubmitSuccess: '&',
        },
        templateUrl: 'app/landing-page/haq-body-part.html',
        controller: HAQBodyPartController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('home');
                $translatePartialLoader.addPart('register');
                return $translate.refresh();
            }]
        }
    })
})();
