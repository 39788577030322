(function() {
    'use strict';

    ClassSessionRegistrationController.$inject = ['$scope', 'ClassSessionRegistrationService'];

    function ClassSessionRegistrationController($scope, ClassSessionRegistrationService) {
        var vm = this; //jshint ignore:line
        vm.classSessionRegistrations = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'course.name';
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            if (vm.patientId) {
                ClassSessionRegistrationService.getRegisteredCourses(vm.patientId, pageable).then(function (response) {
                    vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                    vm.classSessionRegistrations = response ? response.data : [];
                });
            }
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id' + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }
    }

    angular.module('ipmApp').component('classSessionRegistration', {
        bindings: {
            patientId: '<',
            isBiller: '<'
        },
        templateUrl: 'app/patient/wellness-class/class-session-registration.html',
        controller: ClassSessionRegistrationController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('classSessionRegistration');
                return $translate.refresh();
            }]
        }
    })
})();
