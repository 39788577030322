(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ClassSessionEditorController', ClassSessionEditorController);

        ClassSessionEditorController.$inject = ['$scope', '$rootScope','$state', '$stateParams', '$uibModalInstance', 'entity', 'course', 'ClassSessionService', 'isCreateNew', 'isEdit', 'isView'];

    function ClassSessionEditorController($scope, $rootScope, $state, $stateParams, $uibModalInstance, entity, course, ClassSessionService, isCreateNew, isEdit, isView) {
        var vm = this;
        vm.isSaving = false;
        vm.isCreateNew = isCreateNew;
        vm.isEdit = isEdit;
        vm.isView = isView;

        vm.datePickerOpenStatus = {};
        vm.hourStep = 1;
        vm.minuteStep = 15;

        vm.classSession = entity;
        vm.course = course;
        vm.instructors = vm.course.instructors;

        $scope.startDateOptions = {
            minDate: moment().toDate()
        }
        

        init();

        function init() {
            if (vm.isCreateNew) {
                var d = getRangeMinutes() > 45 ? moment().add(1, 'hours').toDate() : moment().toDate();
                d.setMinutes(getRangeMinutes() > 45 ? 0 : getRangeMinutes());
                d.setSeconds(0);
                vm.classSession.date = d;
                vm.classSession.time = d;

                vm.classSession.courseName = vm.course.name;
            } else {
                vm.classSession.date = moment(vm.classSession.classStart)._d;
                vm.classSession.time = moment(vm.classSession.classStart)._d;
                // set object to selected
                vm.classSession.wellnessClass = {id: vm.classSession.classId};

                if (vm.isView) {
                    vm.course.classes = [{id: vm.classSession.classId, name: vm.classSession.wellnessClassName}];
                    vm.instructors = [{ instructorId: vm.classSession.instructorId, instructorName: vm.classSession.instructorName }]
                }
            }
        }

        vm.save = function() {
            var classStart = vm.classSession.date;
            classStart.setHours(vm.classSession.time.getHours(), vm.classSession.time.getMinutes(), 0);
            vm.classSession.classId = vm.classSession.wellnessClass.id;
            var requestDataObject = {
                id: vm.classSession.id,
                classStart: classStart,
                instructorId: vm.classSession.instructorId,
                classId: vm.classSession.classId,
            }
            ClassSessionService.createOrUpdate(requestDataObject).then(function(res) {
                $scope.$emit('ipmApp:classSessionUpdate');
                vm.clear();
            }, function(error) {
                $rootScope.$emit('ipmApp.httpErrorWithOutGotoErrorPage', error);
            });
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.classChange = function() {
            vm.classSession.classSequence = vm.classSession.wellnessClass.sequence; // set class sequence to UI display
        }

        $scope.$watchGroup(['vm.classSession.date', 'vm.classSession.time'], function() {
            checkingTimeValid();
            return;
        });

        function checkingTimeValid() {
            var date = new Date();
            if (vm.classSession.date) {
                if(vm.classSession.date.getDate() < date.getDate()
                    && vm.classSession.date.getMonth() == date.getMonth()
                    && vm.classSession.date.getYear() == date.getYear()) {
                    vm.invalidDate = true;
                } else {
                    vm.invalidDate = false;
                }
            }
            if (vm.classSession.time) {
                if(vm.classSession.time.getHours() < date.getHours()
                    && theSameCurrentDate()) {
                    vm.invalidTime = true;
                } else if (vm.classSession.time.getHours() === date.getHours()
                    && theSameCurrentDate()) {
                    vm.invalidTime = vm.classSession.time.getMinutes() < date.getMinutes();
                } else {
                    vm.invalidTime = false;
                }
            }
        }

        function theSameCurrentDate() {
            var date = new Date();
            return vm.classSession.date.getDate() === date.getDate()
            && vm.classSession.date.getMonth() === date.getMonth()
            && vm.classSession.date.getYear() === date.getYear();
        }

        function getRangeMinutes() {
            var date = new Date();
            if (date.getMinutes() < 15) {
                return 15;
            }
            if (date.getMinutes() < 30) {
                return 30;
            }
            if (date.getMinutes() < 45) {
                return 45;
            }
            return 59;
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
