(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User', '$state'];

    function UserManagementDetailController ($stateParams, User, $state) {
        var vm = this;

        vm.load = load;
        vm.isPatient = null;
        vm.user = {};

        vm.load($stateParams.id);

        function load (id) {
            User.get({id: id}, function(result) {
                vm.user = result;
                if( vm.user.authorities.indexOf('ROLE_PATIENT') !== -1) {
                	vm.isPatient = true;
                } else {
                	vm.isPatient = false;
                }
            });
        }

        vm.backToList = function() {
            var paramsInfo = $stateParams.searchUserParamsInfo;
            if(paramsInfo) {
                $state.go('user-management', {searchUserParamsInfo: paramsInfo});
            } else {
                $state.go('user-management');
            }
        }

    }
})();
