(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('multiFrpProgramsController', multiFrpProgramsController);

    multiFrpProgramsController.$inject = [];

    function multiFrpProgramsController() {
        var vm = this;
        vm.addAnotherFrpProgram = addAnotherFrpProgram;
        vm.removeSelectedFrpProgram = removeSelectedFrpProgram;
        vm.filteredFrpPrograms = filteredFrpPrograms;
        vm.changeSelectedFrpProgram = changeSelectedFrpProgram;
        vm.isShowAddAnotherFrpProgramLink = isShowAddAnotherFrpProgramLink;
        init();

        function init() {
            vm.selectedFrpPrograms = vm.selectedFrpPrograms && vm.selectedFrpPrograms != undefined ? vm.selectedFrpPrograms : [];
            if (vm.selectedFrpPrograms && vm.selectedFrpPrograms.length === 0) {
                addAnotherFrpProgram();
            }
        }

        function addAnotherFrpProgram() {
            vm.selectedFrpPrograms.push({});

        }

        function removeSelectedFrpProgram(index) {
            if (vm.selectedFrpPrograms && vm.selectedFrpPrograms.length === 1) {
                return;
            }
            vm.selectedFrpPrograms.splice(index, 1);
        }

        function changeSelectedFrpProgram(index, frpProgram) {
            vm.selectedFrpPrograms[index] = frpProgram;

        }

        function filteredFrpPrograms(selectedFrpProgram) {
            if (vm.selectedFrpPrograms.length == 0) {
                return vm.frpPrograms;
            }
            var filteredList = [];
            vm.frpPrograms.forEach(function (item) {
                if (item.id == selectedFrpProgram.id || _.findIndex(vm.selectedFrpPrograms, {
                        id: item.id
                    }) === -1) {
                    filteredList.push(item);
                }

            })
            return filteredList;
        }

        function isShowAddAnotherFrpProgramLink() {
            return vm.frpPrograms && vm.selectedFrpPrograms && vm.selectedFrpPrograms.length < vm.frpPrograms.length;
        }
    }

    angular.module('ipmApp').component('multiFrpPrograms', {
        bindings: {
            formReference: '<',
            frpPrograms: '<',
            translateKey: '<',
            selectedFrpPrograms: '='
        },
        templateUrl: 'app/admin/user-management/multi-frp-programs/multi-frp-programs.html',
        controller: multiFrpProgramsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('user-management');
                return $translate.refresh();
            }]
        }
    })
})();
