(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('WellnessClassListService', WellnessClassListService);

    WellnessClassListService.$inject = ['$resource', '$http', '$q'];

    function WellnessClassListService ($resource, $http) {
        var resourceUrl = "api/class-sessions/get-up-comming-session-by-qhp";
        var service = $resource(resourceUrl, {}, {});
        service.getUpComingSessionsByInstructorId = getUpComingSessionsByInstructorId;

        function  getUpComingSessionsByInstructorId(pageable, addedTime) {
            return $http.get(resourceUrl, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort,
                    addedTime: addedTime
                }
            }).then(function (response) {
                return response;
            });
        };
        return service;
    };
})();
