(function() {
    'use strict';


    ItemLandingPageController.$inject = ['$scope', '$state', '$window', 'LandingPageService', 'ClearLocalStorageService', 'AgreementsWorkflowService', 'ipmConstants'];

    function ItemLandingPageController($scope, $state, $window, LandingPageService, ClearLocalStorageService, AgreementsWorkflowService, constants) {
        var vm = this; //jshint ignore:line
        vm.percentCompleted = 100; // completed 100%

        vm.percentTextClass = percentTextClass;
        vm.isShowCompletedText = isShowCompletedText;
        vm.timeRemainingTextClass = timeRemainingTextClass;
        vm.isShowApproxText = isShowApproxText;
        vm.isShowRemainingText = isShowRemainingText;
        vm.isCompleted = isCompleted;
        vm.isStart = isStart;
        vm.isNotYetCompleted = isNotYetCompleted;
        vm.clickAction = clickAction;
        vm.isCursorDefault = isCursorDefault;

        init();

        function init() {
            // setCurrentStep();
        }

        function percentTextClass() {
            if (vm.item && vm.item.percentProgress) {
                if (vm.item.percentProgress === vm.percentCompleted) {
                    return 'text-success';
                } else if (vm.item.percentProgress > 0 && vm.item.percentProgress  < vm.percentCompleted) {
                    return 'text-warning';
                }
            }
            return '';
        }

        function isShowCompletedText() {
            return isCompleted();
        }

        function timeRemainingTextClass() {
            if (vm.item.percentProgress === 0 || vm.item.percentProgress) {
                if (vm.item.percentProgress == vm.percentCompleted) {
                    return '';
                } else if (vm.item.percentProgress > 0 && vm.item.percentProgress < vm.percentCompleted) {
                    return 'text-warning';
                }
                return 'text-success';
            }
            return '';
        }
        function isShowApproxText() {
            return !(vm.item.percentProgress && vm.item.percentProgress > 0);
        }

        function isShowRemainingText() {
            return vm.item.percentProgress && vm.item.percentProgress > 0 && vm.item.percentProgress < vm.percentCompleted
        }

        function isStart() {
            return vm.item && vm.item.percentProgress === 0;
        }

        function isCompleted() {
            return vm.item && vm.item.percentProgress === vm.percentCompleted;
        }

        function isNotYetCompleted() {
            return !isCompleted();
        }

        function setCurrentStep() {
            if (vm.item) {
                if (vm.item.isHAQ && (vm.isHAQDirectLink || !isCompleted())) {
                    vm.item.currentStep = vm.item.stepNumber;
                } else if (vm.item.isAgreement && !isCompleted()) {
                    vm.item.currentStep = vm.item.stepNumber;
                }
            }
        }

        function clickAction() {
            if (vm.item.canDownload && isCompleted()) {
                vm.onClickDownload();
                return;
            }
            vm.onClick();
        }

        function isCursorDefault() {
            return !vm.item.isCurrentStep && !(vm.item.canDownload && isCompleted());
        }
    }

    angular.module('ipmApp').component('itemLandingPage', {
        bindings: {
            item: '<',
            onClick: '&',
            onClickDownload: '&'
        },
        templateUrl: 'app/landing-page/item.html',
        controller: ItemLandingPageController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
