(function() {
    'use strict';
	var module = angular.module('ipmApp')

	module.directive('dragToScroll',['$document', function($document) {
		
	function dragToScrollCtrl($scope, $element, $attrs, ngModelCtrl) {
		var  pos = { top: 0, left: 0, x: 0, y: 0 };
		$element.addClass('scrollContainer');
		$element.on('mousedown', mouseDownHandler);
		$element.on('scroll', scrollHandler);
		function mouseDownHandler (e) {

			$element.css({cursor:'grabbing' , userSelect: 'none'});
			pos = {
				left: $element.scrollLeft(),
				top: $element.scrollTop(),
				x: e.clientX,
				y: e.clientY,
			};
	
			$document.on('mousemove', mouseMoveHandler);
			$document.on('mouseup', mouseUpHandler);

		};
		function scrollHandler(e)  {
			$scope.onScroll({'position': {top:$element.scrollTop(),left: $element.scrollLeft() }});
		};
		function mouseMoveHandler(e)  {
			var dx = e.clientX - pos.x;
			var dy =  e.clientY - pos.y;
			var top = pos.top - dy;
			var left = pos.left - dx
			$element.scrollTop(top);
			$element.scrollLeft(left);
			$scope.onScroll({'position': {top:$element.scrollTop(),left: $element.scrollLeft() }});
		};
		function mouseUpHandler(e) {
			$document.off('mousemove', mouseMoveHandler);
			$document.off('mouseup', mouseUpHandler);
					$scope.onScroll({'position': {top:$element.scrollTop(),left: $element.scrollLeft()}});
		};
	 }
	    return {
	        link: dragToScrollCtrl,
			scope: {
				onScroll: '&',
				onScrollEnd: '&'
			}
	    };
	}]);


})();