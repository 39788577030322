(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('presentComplaintsComponent', presentComplaintsComponent);

    presentComplaintsComponent.$inject = [];

    function presentComplaintsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            require:"^form", //inject parent form as the forth parameter to the link functio
            link: linkFunc,
            controller: DirectivePresentComplaintsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/present-complaints.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs, form) {
            scope.form = form;
        }
    }

    DirectivePresentComplaintsController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectivePresentComplaintsController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        vm.frontBody = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 
            'F11', 'F12', 'F13', 'F14', 'F15', 'F16', 'F17', 'F18', 'F19', 'F20', 'F21', 'F22', 'F23', 'F24', 
            'F25', 'F26', 'F27', 'F28', 'F29', 'F30', 'F31', 
            'F32', 'F33', 'F34', 'F35', 'F36', 'F37', 'F38','F39'];

        vm.backBody = ['B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'B10', 
            'B11', 'B12', 'B13', 'B14', 'B15', 'B16', 'B17', 'B18', 'B19', 'B20', 'B21', 'B22', 'B23', 'B24', 
            'B25', 'B26', 'B27', 'B28', 'B29', 'B30', 'B31', 
            'B32', 'B33', 'B34', 'B35', 'B36', 'B37', 'B38', 'B39'];

        function init() {
            
        }

        vm.optionsResponseRequired = function(pro1, pro2) {
            var find = _.find(_.values(vm.workflow.answer[pro1]), function(checked) {
                return checked;
            });

            if(!find && pro2) {
                find = _.find(_.values(vm.workflow.answer[pro2]), function(checked) {
                    return checked;
                });
            }
            return !find;
        };

        init();
    }
})();
