(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('injuryDetailsComponent', injuryDetailsComponent);

    injuryDetailsComponent.$inject = [];

    function injuryDetailsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectiveInjuryDetailsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/injury-details.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveInjuryDetailsController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectiveInjuryDetailsController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        vm.datePickerOpenStatus = {
            theApproximateDateOfTheInjury: false
        };

        vm.datePickerOptions = {
            maxDate: new Date()
        };

        function init() {
        }

        init();
    }
})();
