(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('demographicsComponent', demographicsComponent);

    demographicsComponent.$inject = [];

    function demographicsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectiveDemographicsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/demographics.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveDemographicsController.$inject = ['$scope', '$stateParams', '$element', '$timeout', 'REGEX_PATTERN', 'ipmConstants'];
    function DirectiveDemographicsController($scope, $stateParams, $element, $timeout, REGEX_PATTERN, constants) {
        var vm = this;
        
        var answerClone = _.cloneDeep(vm.workflow.answer);
        vm.REGEX_PATTERN = REGEX_PATTERN;

        function init() {
        }

        vm.requirePhoneNumbers = function() {
            return !vm.workflow.answer.homeTelephone && !vm.workflow.answer.cellNumber;
        };

        vm.getAge = function () {
            var dateOfBirth = Date.parse(vm.workflow.answer.dateOfBirth);
            var birthDay = new Date(dateOfBirth).getUTCDate();
            var birthMonth = new Date(dateOfBirth).getUTCMonth();
            var birthYear = new Date(dateOfBirth).getUTCFullYear();
            var todayDate = new Date();
            var todayYear = todayDate.getFullYear();
            var todayMonth = todayDate.getMonth();
            var todayDay = todayDate.getDate();
            var age = todayYear - birthYear;
    
            if (todayMonth < birthMonth - 1) {
                age--;
            }
    
            if (birthMonth - 1 === todayMonth && todayDay < birthDay.date) {
                age--;
            }
    
            return age;
        };

        vm.doNotHaveSSNChanged = function() {
            if(vm.workflow.answer.doNotHaveSSN) {
                vm.workflow.answer.socialSecurityNumberEncrypted = '';
            } else {
                vm.workflow.answer.socialSecurityNumberEncrypted = answerClone.socialSecurityNumber;
            }
        };
        init();
    }
})();
