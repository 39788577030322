(function () {
	'use strict';

	angular
	.module('ipmApp')
	.controller('PDFReportPreviewDiaglogController', PDFReportPreviewDiaglogController);

	PDFReportPreviewDiaglogController.$inject = ['$scope', '$state', '$uibModalInstance', 'pdfData'];

	function PDFReportPreviewDiaglogController($scope, $state, $uibModalInstance, pdfData) {
		var vm = this;
        vm.pdfData = pdfData;
        init();

        function init() {
        }
		vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
