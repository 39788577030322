(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Test', Test);

    Test.$inject = ['$resource', '$q', 'halClient', '$http'];

    function Test ($resource, $q, halClient, $http) {
        var baseUrl = 'api/tests';
        var resourceUrl =  'api/tests/:id';

        var service =  $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        service.findAll = findAll;
        service.changeActivated = changeActivated;
        service.getAllTestsByActive = getAllTestsByActive;
        service.getAllActiveAdhocTests = getAllActiveAdhocTests;
        service.duplicate = duplicate;

        function findAll(pageable) {
            var deferred = $q.defer();

            var url = baseUrl;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function changeActivated(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/change-activated/" + id;
            halClient.$post(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };



        function getAllTestsByActive(pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/get-all-by-active';
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function getAllActiveAdhocTests() {
            var deferred = $q.defer();

            var url = baseUrl + '/get-all-adhoc-tests';
            $http.get(url).success(function (data, status, headers) {
                var result = new Object();
                result = data && data !== undefined ? data : [];
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function duplicate(id, testDTO) {
            var deferred = $q.defer();
            var url = baseUrl + "/" + id + "/" + "duplicate";
            halClient.$post(url, null, testDTO).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    }
})();
