(function() {
    'use strict';

    SearchCheckboxAutoCompleteController.$inject = ['QHPMedicationService'];

    function SearchCheckboxAutoCompleteController(QHPMedicationService) {
        var vm = this; //jshint ignore:line

        init();

        function init() {}

        vm.loader = function (name) {
            return QHPMedicationService.searchByName(name, (vm.excludeIds && vm.excludeIds.length > 0) ? vm.excludeIds : [0], vm.type).then(function (res) {
                return res;
            })
        }

        vm.handleSelected = function () {
            vm.onSelected({ data: vm.ortherCheckbox });
            vm.ortherCheckbox = "";
        }
    }

    angular.module('ipmApp').component('searchCheckboxAutoComplete', {
        bindings: {
            excludeIds: '<',
            type: '<',
            onSelected: '&'
        },
        templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/search-checkbox-autocomplete.html',
        controller: SearchCheckboxAutoCompleteController,
        controllerAs: 'vm'
    })
})();
