(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadAgreementController', DownloadAgreementController);

    DownloadAgreementController.$inject = ['$state', '$filter', 'AgreementsWorkflowService', 'DateUtils'];

    function DownloadAgreementController($state, $filter, AgreementsWorkflowService, DateUtils) {
        var vm = this;
        vm.download = download;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPatientOrPdf = false;
        vm.invalidDates = false;

        vm.datePickerOpenStatus = { fromDate: false, toDate: false };
        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        init();

        function init() {
            // set startDate to Monday of this week
            vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
            // set startDate to previous Monday
            vm.fromDate.setDate(vm.fromDate.getDate() - 7);

            // set endDate to previous Friday
            vm.toDate = new Date(vm.fromDate);
            vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        function download() {
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            vm.noPatientOrPdf = false;
            AgreementsWorkflowService.downloadAllAgreements(fromDate, toDate).then(function (res) {
                vm.isProcessing = false;
                if (res) {
                    if (res.status === 204 && (res.reason === 'no-patient' || res.reason === 'no-pdf')) {
                        vm.noPatientOrPdf = true;
                    }
                }
            });
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };
    }
})();
