(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('UploadSignatureDialogController', UploadSignatureDialogController);

    UploadSignatureDialogController.$inject = ['$scope', '$uibModalInstance', 'signature'];

    function UploadSignatureDialogController ($scope, $uibModalInstance, signature) {
        var vm = this;
        vm.signature = signature ? signature : {};
        init();

        function init() {

        }

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            onSaveSuccess(vm.signature);
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
