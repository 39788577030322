(function() {
    'use strict';


    SuggestCourseController.$inject = ['$state', 'SurveyWorkflowService'];

    function SuggestCourseController($state, SurveyWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.loadingFinish = false;
        vm.register = register;
        vm.noThank = noThank;
        vm.handleRecommendationChange = handleRecommendationChange;
        vm.isRegisterDisable = isRegisterDisable;

        init();

        function init() {
            SurveyWorkflowService.getSuggestCourse().then(function(res) {
                if (res.data.recommended && res.data.recommended.length === 0 && res.data.recommended && res.data.consider.length === 0) {
                    $state.go('survey-workflow-thankyou');
                } else {
                    vm.loadingFinish = true;

                    vm.recommendations = [];

                    if (vm.patientCourseRegistration.suggestedCourses) {
                        _.forEach(res.data.recommended, function(o) {
                            if (_.indexOf(vm.patientCourseRegistration.suggestedCourses, o.id) !== -1) {
                                o.checked = true;
                            }
                        });
                    }

                    // High && Strongly && Recommended
                    var recommendedItem = {
                        text: 'surveyWorkflow.courseRegistration.suggestCourse.text2',
                        items: _.orderBy(res.data.recommended, ['score', 'name'], ['desc', 'asc'])
                    }
                    vm.recommendations.push(recommendedItem);
                    // Consider
                    var considerItem = {
                        text: _.isEmpty(res.data.recommended) ? 'surveyWorkflow.courseRegistration.suggestCourse.text4' : 'surveyWorkflow.courseRegistration.suggestCourse.text3',
                        items: _.orderBy(res.data.consider, ['score', 'name'], ['desc', 'asc'])
                    }
                    vm.recommendations.push(considerItem);
                }

            }, function() {
                $state.go('error');
            });


        }

        function register() {
            // add by recommended
            addIdToListData(vm.recommendations[0].items);
            // add by consider
            addIdToListData(vm.recommendations[1].items);

            vm.next();
        }

        function noThank() {
            vm.skip();
        }

        function handleRecommendationChange(data) {
        }

        function addIdToListData(list) {
            _.forEach(list, function(o) { 
                if (o.checked) {
                    vm.patientCourseRegistration.suggestedCourses.push(o.id);
                }
             });
        }

        function isRegisterDisable() {
            return isEmptyCheckedList(vm.recommendations[0].items) && isEmptyCheckedList(vm.recommendations[1].items);
        }

        function isEmptyCheckedList(list) {
            return _.isEmpty(_.filter(list, function(o) { return o.checked }));
        }
    }

    angular.module('ipmApp').component('suggestCourseStep', {
        bindings: {
            patientCourseRegistration: "=",
            next: '&',
            skip: '&'
        },
        templateUrl: 'app/survey-workflow/partial/course-register-workflow/step/suggest-course/suggest-course.html',
        controller: SuggestCourseController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();


(function() {
    'use strict';


    RecommendationItemComponent.$inject = ['$timeout', '$scope'];

    function RecommendationItemComponent($timeout, $scope) {
        var vm = this; //jshint ignore:line
        vm.handleCheckboxChange = handleCheckboxChange;
        init();

        function init() {
        }

        function handleCheckboxChange(item, checked) {
            vm.onChange({ data: { item: item } });
        }
    }

    angular.module('ipmApp').component('recommendationItem', {
        bindings: {
            text: '<',
            items: '<',
            onChange: '&'
        },
        template:   '<div class="col-md-12 h5 primary title mt-2" translate="{{vm.text}}">' +

                    '</div>' +
                    '<form name="evaluationForm" role="form" novalidate>' +
                            '<div class="col-md-12" ng-repeat="item in vm.items track by item.id">' +
                                '<div class="col-v4-12">' +
                                    '<div class="form-check-v4">' +
                                        '<input type="checkbox"' +
                                            'id="checkbox_{{item.id}}"' +
                                            'name="checkbox_{{item.id}}"' +
                                            'class="form-check-input-v4"' +
                                            'value="{{item}}"' +
                                            'ng-model="item.checked"' +
                                            'ng-change="vm.handleCheckboxChange(item, item.checked)">' +
                                            '<label class="form-check-label-v4" for="checkbox_{{item.id}}" >{{item.name}}</label>' +
                                    '</div>' +
                                '</div>' +
                            '</div>' +
                        '</form>',
        controller: RecommendationItemComponent,
        controllerAs: 'vm'
    })
})();

