(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('TestController', TestController);

    TestController.$inject = ['$scope', '$state', 'Test', 'TestSearch'];

    function TestController ($scope, $state, Test, TestSearch) {
        var vm = this;
        vm.tests = [];

        vm.totalItems = 0;
        vm.pageSize = 20;
        vm.page = 1;
        vm.predicate = 'id';
        vm.reverse = true;
        vm.loadAll = loadAll;
        vm.changeActivated = changeActivated;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: 20,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            Test.findAll(pageable).then(function (response) {
                vm.totalItems = response && response.totalItems ? response.totalItems : 0;
                vm.tests = response && response.data ? response.data : [];
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            return result;
        }

        function changeActivated(id) {
            Test.changeActivated(id).then(function(res) {
                vm.loadAll();
            });
        }

        // vm.loadAll = function() {
        //     Test.query(function(result) {
        //         vm.tests = result;
        //     });
        // };

        // vm.search = function () {
        //     if (!vm.searchQuery) {
        //         return vm.loadAll();
        //     }
        //     TestSearch.query({query: vm.searchQuery}, function(result) {
        //         vm.tests = result;
        //     });
        // };
        // vm.loadAll();
        
    }
})();
