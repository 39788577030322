(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AppointmentCreateQuestionnaireController', AppointmentCreateQuestionnaireController);

    AppointmentCreateQuestionnaireController.$inject = ['$uibModalInstance', 'entity', 'NPQQuestionnaireService'];

    function AppointmentCreateQuestionnaireController($uibModalInstance, entity, NPQQuestionnaireService) {
        var vm = this;

        vm.appointment = entity ? entity : null;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.notAllowCreateQuestionnaire = false;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.notAllowCreateQuestionnaire = false;
            vm.isSaving = true;
            if (vm.appointment && vm.appointment.id && vm.appointment.patientId) {
                var data = {
                    'id': null,
                    'caseNo': null,
                    'appointmentId': vm.appointment.id,
                    'patientId': vm.appointment.patientId
                };
                NPQQuestionnaireService.saveNPQQuestionnaire(data).then(function(res) {
                    onSaveSuccess(res);
                }, function errorSave(error) {
                    onSaveError(error);
                });
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                if (errorMessage === 'notAllowCreateQuestionnaire') {
                    vm.notAllowCreateQuestionnaire = true;
                }
            }
        }
    }
})();
