(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyWorkflowController', SurveyWorkflowController);

    SurveyWorkflowController.$inject = ['$scope', '$state', '$timeout', 'SurveyWorkflowService', '$uibModal','entity', 'allBatteryTests', 'ipmConstants', '$window', '$sessionStorage', 'DateUtils', '$sce', 'isAdHoc', 'ClearLocalStorageService'];

    function SurveyWorkflowController($scope, $state, $timeout, SurveyWorkflowService, $uibModal, entity, allBatteryTests, constants, $window, $sessionStorage, DateUtils, $sce, isAdHoc, ClearLocalStorageService) {
        var vm = this;
        vm.isAdHoc = isAdHoc ? isAdHoc : false;
        vm.listBatteryTest = [entity];

        vm.batteryTest = entity;
        vm.isSaving = false;
        vm.currentSection = 0;
        vm.currentQuestion = -1;
        vm.totalSection = 0;
        vm.totalQuestion = 0;
        vm.totalQuestionPerSection = [];
        vm.previousQuestionTempAnswered = 0;
        vm.previousQuestionAnswered = 0;
        vm.previousTestAnswered = [];
        vm.allQuestion = 0;

        vm.currentQuestionIndex = 0;
        vm.totalQuestionAnswered = 0;
        vm.totalQuestionAnsweredArray = [];

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.datetime = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.percent = 0;

        vm.widthStyle = function() {
            if (vm.percent > 0) {
                return { width: vm.percent + '%' };
            } else {
                return { width: vm.percent + '%', color: 'transparent' };
            }
        }

        vm.resumeTest = function() {
            // get list battery test
            var currentBatteryTest = vm.listBatteryTest[0];
            if (vm.isAdHoc) {
                currentBatteryTest.nextBatteryTestId = null;
            }
            while (currentBatteryTest.nextBatteryTestId != null) {
                for(var i=0; i<allBatteryTests.data.length; i++) {
                    if (allBatteryTests.data[i].id == currentBatteryTest.nextBatteryTestId) {
                        vm.listBatteryTest.push(allBatteryTests.data[i]);
                        currentBatteryTest = allBatteryTests.data[i];
                        break;
                    }
                }
            }

            // count total question
            for(var i=0; i<vm.listBatteryTest.length; i++) {
                for(var j=0; j<vm.listBatteryTest[i].test.sections.length; j++) {
                    vm.allQuestion += vm.listBatteryTest[i].test.sections[j].questions.length;
                }
            }

            // resume to latest battery
            var founded = false;
            var countQuestions = 0;
            for(var i=0; i<vm.listBatteryTest.length; i++) {
                countQuestions = 0;
                for(var j=0; j<vm.listBatteryTest[i].test.sections.length; j++) {
                    countQuestions += vm.listBatteryTest[i].test.sections[j].questions.length;
                    for(var k=0; k<vm.listBatteryTest[i].test.sections[j].questions.length; k++) {
                        var question = vm.listBatteryTest[i].test.sections[j].questions[k];
                        if (question.currentQuestion) {
                            founded = true;
                            vm.currentSection = j;
                            vm.currentQuestion = (k !== 0 ? k : -1);
                            vm.batteryTest = vm.listBatteryTest[i];
                            break;
                        }
                    }
                    if (founded) break;
                }
                if (founded) {
                    break;
                } else {
                    if (i != vm.listBatteryTest.length - 1) {
                        vm.previousTestAnswered.push(vm.listBatteryTest[i].id);
                        vm.previousQuestionAnswered += countQuestions;
                    }
                }
            }

            if (founded == false) {
                vm.currentSection = vm.listBatteryTest[vm.listBatteryTest.length - 1].test.sections.length - 1;
                vm.currentQuestion = vm.listBatteryTest[vm.listBatteryTest.length - 1].test.sections[vm.currentSection].questions.length;
                vm.batteryTest = vm.listBatteryTest[vm.listBatteryTest.length - 1];
            }
        }

        vm.initTotalData = function() {
            vm.totalSection = vm.batteryTest.test.sections.length;

            vm.totalQuestion = 0;
            vm.totalQuestionPerSection = [];
            _.forEach(vm.batteryTest.test.sections, function(section) {
                vm.totalQuestion += section.questions.length;
                vm.totalQuestionPerSection.push(section.questions.length);
            });
        }

        vm.calculateData = function() {
            var questionAnswered = 0;
            _.forEach(vm.batteryTest.test.sections, function(section) {
                _.forEach(section.questions, function(question) {
                    var emptyTextAnswer = (question.type === 'SHORT_ANSWER') && (question.answerText === null || question.answerText === undefined);
                    var emptySelectAnwer = (question.type === 'RANK' || question.type === 'MULTIPLE_CHOICE') && (question.answer === null || question.answer === undefined);
                    var emptyDatetimeAnwer = (question.type === 'DATE_TIME') && (question.dateValue === null || question.dateValue === undefined);
                    var emptyMultipleSelectAnwer = (question.type === 'MULTI_SELECT') && (question.multiSelectValue === null || question.multiSelectValue === undefined || question.multiSelectValue.length === 0);
                    if (!emptyTextAnswer && !emptySelectAnwer && !emptyDatetimeAnwer && !emptyMultipleSelectAnwer) {
                        questionAnswered++;
                    }
                });
            });
            vm.totalQuestionAnswered = questionAnswered;
            if (vm.previousTestAnswered.indexOf(vm.batteryTest.id) != -1) {
                vm.percent = Math.ceil((((vm.previousQuestionTempAnswered + vm.previousQuestionAnswered) / vm.allQuestion) * 100).toFixed(2));
            } else {
                vm.percent = Math.ceil((((questionAnswered + vm.previousQuestionAnswered) / vm.allQuestion) * 100).toFixed(2));
            }

            // create list question answered
            vm.totalQuestionAnsweredArray = [];
            for(var i=0; i<=vm.totalQuestionAnswered; i++) {
                if (i != vm.totalQuestion) {
                    vm.totalQuestionAnsweredArray.push(i);
                }
            }
        }

        vm.updateQuestionIndex = function() {
            var tempIdx = 0;
            for(var i=0; i<=vm.currentSection; i++) {
                if (i == vm.currentSection) {
                    tempIdx += vm.currentQuestion;
                    break;
                } else {
                    tempIdx += vm.totalQuestionPerSection[i];
                }
            }
            vm.currentQuestionIndex = String(tempIdx);
        }

        vm.moveQuestionByIndex = function() {
            var tempIdx = 0;
            for(var i=0; i<=vm.totalSection; i++) {
                if (tempIdx + vm.totalQuestionPerSection[i] <= vm.currentQuestionIndex) {
                    tempIdx += vm.totalQuestionPerSection[i];
                } else {
                    vm.currentSection = i;
                    vm.currentQuestion = vm.currentQuestionIndex - tempIdx;
                    break;
                }
            }
        }

        vm.start = function() {
            vm.currentQuestion = 0;
            vm.updateQuestionIndex();
        }

        vm.goto = function(direction) {
            if (vm.isSaving) {return;}
            if (direction == 'First') {
                vm.currentSection = 0;
                vm.currentQuestion = 0;
            } else if (direction == 'Previous') {
                if (vm.currentQuestion > 0) {
                    vm.currentQuestion--;
                } else if (vm.currentSection > 0) {
                    vm.currentSection--;
                    vm.currentQuestion = vm.batteryTest.test.sections[vm.currentSection].questions.length - 1;
                } else if (vm.batteryTest.prevBatteryTestId != null) {
                    vm.previous();
                }
            } else if (direction == 'Next') {
                if (vm.currentQuestionIndex >= vm.totalQuestionAnswered && vm.totalQuestionAnswered != vm.totalQuestion) {
                    return;
                }

                if (vm.currentQuestion < vm.totalQuestionPerSection[vm.currentSection] - 1) {
                    vm.currentQuestion++;
                } else if (vm.currentSection < vm.totalSection - 1) {
                    vm.currentQuestion = 0;
                    vm.currentSection++;
                } else if (vm.batteryTest.nextBatteryTestId != null) {
                    vm.next();
                } else {
                    vm.currentQuestion++;
                }
            } else if (direction == 'Last') {
                if (vm.totalQuestionAnswered == vm.totalQuestion) {
                    vm.currentQuestionIndex = vm.totalQuestion - 1;
                } else {
                    vm.currentQuestionIndex = vm.totalQuestionAnswered;
                }
                vm.moveQuestionByIndex();
            }
            vm.updateQuestionIndex();
            vm.updateSaveButton();
        }

        // use for SHORT_ANSWER type with other answer
        vm.showSaveButton = false;
        var firstQuestion = vm.batteryTest.test.sections[0].questions[0];
        if (firstQuestion.type == "SHORT_ANSWER") {
            _.forEach(firstQuestion.questionOptions, function(questionOption) {
                if (questionOption.optionType==='OTHER' && firstQuestion.answer && firstQuestion.answer.toString()===questionOption.value.toString()) {
                    vm.showSaveButton = true;
                    return;
                }
            });
        }

        vm.checkShowNextTest = function(question, questionOption) {
            if (vm.currentSection == vm.batteryTest.test.sections.length - 1 && vm.currentQuestion == vm.batteryTest.test.sections.length - 1) {
                return true;
            } else {
                return false;
            }
        }

        vm.checkshowOtherText = function(question, questionOption) {
        	if(questionOption.optionType==='OTHER' && question.answer && question.answer.toString()===questionOption.value.toString() ){
        		return true;
        	} else {
        		return false;
        	}
        }

        vm.previous = function() {
            vm.isSaving = true;

            if (vm.previousTestAnswered.indexOf(vm.batteryTest.id) == -1) {
                vm.previousQuestionTempAnswered = vm.totalQuestionAnswered;
            }

            SurveyWorkflowService.saveSurveyResponse(vm.batteryTest.id, vm.batteryTest).then(function(response) {
                SurveyWorkflowService.getBatteryTest(vm.batteryTest.prevBatteryTestId, 'previous').then(function(response) {
                    vm.batteryTest = response;
                    vm.isSaving = false;
                    vm.currentQuestion = 0;
                    vm.currentSection = 0;
                    vm.initTotalData();
                    vm.calculateData();
                    vm.updateQuestionIndex();
                    // load value for multiple checkbox
                    vm.initMultiSelectValueAndDate();
                });
            }).finally(function() {
                scrollTop();
            });
        }

        var checkAnswerSuicideRiskTest = function() {
        	var condition = true;
        	_.forEach(vm.batteryTest.test.sections, function(section) {
        		if (!condition) return;
  			  _.forEach(section.questions, function(question) {
  				 var answerSuicideRiskTest = (question.name === constants.SUICIDE_RISK_TEST && question.answer == 0);
				  if (answerSuicideRiskTest) {
					  condition = false;
					  return;
				  }
  			  });
        	});
        	return condition;
        }

        function errorOnQuestion(currentQuestionObj) {
            currentQuestionObj.answer = null;
            currentQuestionObj.answerText = null;
        }

        function copyQuestionObj(currentQuestionObj) {
            var result = {};
            angular.copy(currentQuestionObj, result);
            return result;
        }

        vm.questionChecked = function(questionOption) {

            if (questionOption) {
                var currentQ = vm.batteryTest.test.sections[vm.currentSection].questions[vm.currentQuestion];
                if (questionOption.optionType==='OTHER' && currentQ.answer.toString()===questionOption.value.toString()) {
                    vm.showSaveButton = true;
                    return;
                } else {
                    vm.showSaveButton = false;
                }
            }

            var currentQuestionObj = vm.batteryTest.test.sections[vm.currentSection].questions[vm.currentQuestion];
            // [start - set multiple value]
            currentQuestionObj.multiSelectValue = [];
            var options = currentQuestionObj.questionOptions;
            angular.forEach(options, function(option) {                  // loop options
                if (option.checked) {
                    currentQuestionObj.multiSelectValue.push(option.id); // push id to fix bug: IPMproject-B1067
                }
            });
            // [end - set multiple value]

            // [start - set data value]
            angular.forEach(vm.batteryTest.test.sections, function(section) {                       // loop sections
                var questions = section.questions;
                angular.forEach(questions, function(question) {                         // loop questions
                    if (question.dateValueDisplay) {
                        question.dateValue = DateUtils.convertLocalDateToServer(question.dateValueDisplay);
                    }
                });
            });
             // [end - set data value]

            if (vm.currentQuestion < vm.batteryTest.test.sections[vm.currentSection].questions.length - 1) {
                vm.isSaving = true;
                SurveyWorkflowService.next(vm.batteryTest.id, vm.batteryTest, false, copyQuestionObj(currentQuestionObj)).then(function(response) {
                    vm.isSaving = false;
                    if (response.status === 200) {
                        vm.calculateData();
                        vm.currentQuestion++;
                        vm.updateQuestionIndex();
                        vm.updateSaveButton();
                    } else {
                        errorOnQuestion(currentQuestionObj);
                    }
                });
            } else if (vm.currentSection < vm.totalSection - 1) {
                vm.isSaving = true;
                SurveyWorkflowService.next(vm.batteryTest.id, vm.batteryTest, false, copyQuestionObj(currentQuestionObj)).then(function(response) {
                    vm.isSaving = false;
                    if (response.status === 200) {
                        vm.calculateData();
                        vm.currentQuestion = 0;
                        vm.currentSection++;
                        vm.updateQuestionIndex();
                        vm.updateSaveButton();
                    } else {
                        errorOnQuestion(currentQuestionObj);
                    }
                });
            } else if (vm.batteryTest.nextBatteryTestId === null) {
                vm.isSaving = true;
                SurveyWorkflowService.next(vm.batteryTest.id, vm.batteryTest, true, copyQuestionObj(currentQuestionObj)).then(function(response) {
                    vm.isSaving = false;
                    if (response.status === 200) {
                        vm.calculateData();
                        vm.currentQuestion++;
                        vm.updateQuestionIndex();
                        vm.updateSaveButton();
                        goToCourseRegistrationWorkflow();
                    } else {
                        errorOnQuestion(currentQuestionObj);
                    }
                });
            } else {
                vm.next();
            }
        }

        vm.next = function() {
            var currentQuestionObj = vm.batteryTest.test.sections[vm.currentSection].questions[vm.currentQuestion];
            vm.isSaving = true;
            SurveyWorkflowService.next(vm.batteryTest.id, vm.batteryTest, false, copyQuestionObj(currentQuestionObj)).then(function(response) {
                vm.isSaving = false;
                if (response.status === 200) {
                    if (vm.batteryTest.test.name === constants.PHQ_9 && checkAnswerSuicideRiskTest()) {
                        SurveyWorkflowService.sendSMS(vm.batteryTest.batteryId);
                    }

                    if (vm.previousTestAnswered.indexOf(vm.batteryTest.id) == -1) {
                        vm.previousTestAnswered.push(vm.batteryTest.id);
                        vm.previousQuestionAnswered += vm.totalQuestion;
                    }

                    SurveyWorkflowService.getBatteryTest(vm.batteryTest.nextBatteryTestId, 'next').then(function(response) {
                        if (!angular.isUndefined(response.result) && response.result === constants.BATTERY_COMPLETE) {
                            goToCourseRegistrationWorkflow();
                        }
                        vm.batteryTest = response;
                        vm.totalSection = vm.batteryTest.test.sections.length;

                        vm.currentQuestion = -1;
                        vm.currentSection = 0;
                        vm.initTotalData();
                        vm.calculateData();
                        vm.updateQuestionIndex();
                        vm.updateSaveButton();
                    });
                    scrollTop();
                } else {
                    errorOnQuestion(currentQuestionObj);
                }
            });
        }

        function goToCourseRegistrationWorkflow() {
            if (constants.HIDE_WELLNESS_CLASSES) {
                $state.go('survey-workflow-thankyou');
                return;
            } else {
                $state.go('course-registration-workflow');
            }
        }

        vm.updateSaveButton = function() {
            vm.showSaveButton = false;
            if(vm.currentQuestion >= 0) {
                var currentQ = vm.batteryTest.test.sections[vm.currentSection].questions[vm.currentQuestion];
                if (currentQ && currentQ.type == "SHORT_ANSWER") {
                    _.forEach(currentQ.questionOptions, function(questionOption) {
                        if (questionOption.optionType==='OTHER' && currentQ.answer && currentQ.answer.toString()===questionOption.value.toString()) {
                            vm.showSaveButton = true;
                            return;
                        }
                    });
                }
            }
        }

        var confirmDialog = function () {
        	$uibModal.open({
                controller: function ($uibModalInstance, $state, Auth) {
                	var vm = this;

                	vm.close = function() {
                		$uibModalInstance.dismiss('cancel');
                	}

                	vm.logout = function () {
                		$uibModalInstance.dismiss('cancel');
                        Auth.logout();
                        $state.go('home');
                	};
                },
                templateUrl: 'app/survey-workflow/partial/confirm-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        }

        var scrollTop = function() {
          	$timeout(function() {
          		$window.scrollTo(0, 0);
          	}, 100);
        }

        vm.noMultipleSelect = function (question) {
            return _.isEmpty(_.filter(question.questionOptions, function(questionOption) { return questionOption.checked }));
        }

        vm.initMultiSelectValueAndDate = function () {
            angular.forEach(vm.batteryTest.test.sections, function(section) {                       // loop sections
                var questions = section.questions;
                angular.forEach(questions, function(question) {                         // loop questions
                    var multiSelectValue = question.multiSelectValue ? question.multiSelectValue : [];
                    var options = question.questionOptions;
                    angular.forEach(options, function(option) {                  // loop options
                        option.checked = multiSelectValue.indexOf(option.id) !== -1 ? true : false;
                    });
                    // convert date:
                    if (question.dateValue) {
                        question.dateValueDisplay = DateUtils.convertLocalDateFromServer(question.dateValue);
                    }
                });
            });
        }

        vm.init = function() {
            vm.resumeTest();
            vm.initTotalData();
            vm.calculateData();
            vm.updateQuestionIndex();
            if(vm.currentQuestion == -1) {
                vm.currentQuestion = 0;
                if(vm.currentQuestionIndex > -1) {
                    vm.currentQuestionIndex++;
                    vm.currentQuestionIndex = String(vm.currentQuestionIndex);
                }
            }
            vm.updateSaveButton();

            vm.batteryTest.test.instructions = $sce.trustAsHtml(vm.batteryTest.test.instructions);

            // load value for multiple checkbox
            vm.initMultiSelectValueAndDate();

            var surveyResultId = $window.localStorage.getItem("surveyResultId");
            if (surveyResultId) {
                $sessionStorage.currentSurveyResultId = surveyResultId;
            } else {
                SurveyWorkflowService.getCurrentSurveyResultId().then(function(res){
                    $sessionStorage.currentSurveyResultId = res;
                });
            }
        }
        vm.init();

        $scope.$on('$destroy', function () {
            var isLaunchingFromHaqAdhoc = ClearLocalStorageService.getLaunchFrom() === constants.HAQ_ADHOC;
            if (isAdHoc && !isLaunchingFromHaqAdhoc) {
                ClearLocalStorageService.clearSurveyResultId();
            }
        });
    }

})();
