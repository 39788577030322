(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('BatteryTestDialogController', BatteryTestDialogController);

    BatteryTestDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BatteryTest', 'Battery', 'Test'];

    function BatteryTestDialogController ($scope, $stateParams, $uibModalInstance, entity, BatteryTest, Battery, Test) {
        var vm = this;
        vm.batteryTest = entity;
        vm.batterys = Battery.query();
        vm.tests = Test.query();
        vm.load = function(id) {
            BatteryTest.get({id : id}, function(result) {
                vm.batteryTest = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:batteryTestUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.batteryTest.id !== null) {
                BatteryTest.update(vm.batteryTest, onSaveSuccess, onSaveError);
            } else {
                BatteryTest.save(vm.batteryTest, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
