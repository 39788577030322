(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('test', {
            parent: 'entity',
            url: '/test',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.test.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/test/tests.html',
                    controller: 'TestController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('test');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('section');
                    $translatePartialLoader.addPart('questionType');
                    return $translate.refresh();
                }]
            }
        })
        .state('test.new', {
            parent: 'test',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.test.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/test/test-editor.html',
                    controller: 'TestEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('test');
                    return $translate.refresh();
                }],
                isDuplicate: function () {
                    return false;
                },
                isCreateNew: function () {
                    return true;
                },
                isView: function () {
                    return false;
                }
            }
        })
        .state('test.edit', {
            parent: 'test',
            url: '/:id/edit?isFromHAQ',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.test.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/test/test-editor.html',
                    controller: 'TestEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('test');
                    return $translate.refresh();
                }],
                isDuplicate: function () {
                    return false;
                },
                isCreateNew: function () {
                    return false;
                },
                isView: function () {
                    return false;
                },
            }
        })
        .state('test.duplicate', {
            parent: 'test',
            url: '/:id/duplicate',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.test.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/test/test-editor.html',
                    controller: 'TestEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('test');
                    return $translate.refresh();
                }],
                isDuplicate: function () {
                    return true;
                },
                isCreateNew: function () {
                    return false;
                },
                isView: function () {
                    return false;
                },
            }
        })
        .state('test.edit.section-editor', {
            parent: 'test.edit',
            url: '/section/{sectionId}/editor',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.section.editor.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/section/section-editor.html',
                    controller: 'SectionEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('section');
                    $translatePartialLoader.addPart('questionType');
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Section', function($stateParams, Section) {
                    return Section.get({id : $stateParams.sectionId});
                }]
            }
        })
        .state('test.edit.section-new', {
            parent: 'test.edit',
            url: '/section/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.section.editor.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/section/section-editor.html',
                    controller: 'SectionEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('section');
                    $translatePartialLoader.addPart('questionType');
                    $translatePartialLoader.addPart('question');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        nameEn: null,
                        nameEs: null,
                        orderNumber: null,
                        instructionsEn: null,
                        instructionsEs: null,
                        id: null
                    };
                }
            }
        })
        .state('test.edit.section-editor.questionNew', {
            parent: 'test.edit.section-editor',
            url: '/question/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question/question-dialog.html',
                    controller: 'QuestionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['$stateParams', function ($stateParams) {
                            var d = new Date();
                            var n = d.getTime();
                            return {
                                nameEn: null,
                                nameEs: null,
                                type: null,
                                orderNumber: null,
                                id: null,
                                sectionId: parseInt($stateParams.sectionId)
                                // options: [
                                //     {
                                //         id: -n,
                                //         nameEn: null,
                                //         nameEs: null,
                                //         orderNumber: 1,
                                //         type: 'NUMERIC',
                                //         deleted: false
                                //     }
                                // ]
                            };
                        }],
                        sectionEntity: ['Section', function(Section) {
                            return Section.get({id : $stateParams.sectionId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('test.edit.section-editor.questionEdit', {
            parent: 'test.edit.section-editor',
            url: '/question/{questionId}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question/question-dialog.html',
                    controller: 'QuestionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Question', function(Question) {
                            return Question.get({id : $stateParams.questionId});
                        }],
                        sectionEntity: ['Section', function(Section) {
                            return Section.get({id : $stateParams.sectionId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    //$state.go('question', null, { reload: true });
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('test.edit.section-editor.questionEditDelete', {
            parent: 'test.edit.section-editor',
            url: '/question/{questionId}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question/question-delete-dialog.html',
                    controller: 'QuestionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Question', function(Question) {
                            return Question.get({id : $stateParams.questionId});
                        }]
                    }
                }).result.then(function() {
                    $state.go('^',  null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('test.delete', {
            parent: 'test',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/test/test-delete-dialog.html',
                    controller: 'TestDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Test', function(Test) {
                            return Test.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('test', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
