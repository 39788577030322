(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('haqs-battery', {
            parent: 'app',
            url: '/haqs-battery',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.haqsBattery.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/haqs-battery/haqs-battery.html',
                    controller: 'HaqsBatteryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('haqs-battery');
                    $translatePartialLoader.addPart('battery');
                    return $translate.refresh();
                }]
            },
            params: {}
        }).state('haqs-battery.edit', {
            parent: 'haqs-battery',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.haqsBattery.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/haqs-battery/haqs-battery-editor.html',
                    controller: 'HaqsBatteryEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function (HaqsBatteryService) {
                            return HaqsBatteryService.findById($stateParams.id);
                        },
                    }
                }).result.then(function(test) {
                    if (test && test.id) {
                        $state.go('test.edit', {id: test.id, isFromHAQ: true});
                    } else {
                        $state.go('haqs-battery', null, {reload: true});
                    }
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('haqs-battery.new', {
            parent: 'haqs-battery',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.haqsBattery.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/haqs-battery/haqs-battery-editor.html',
                    controller: 'HaqsBatteryEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                nameEn: null,
                                nameEs: null,
                                orderNumber: null,
                                batteryId: null,
                                batteryTests: []
                            };
                        }
                    }
                }).result.then(function(test) {
                    if (test && test.id) {
                        $state.go('test.edit', {id: test.id, isFromHAQ: true});
                    } else {
                        $state.go('haqs-battery', null, {reload: true});
                    }
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('haq-tracks.duplicate', {
            parent: 'haqs-battery',
            url: '/{id}/duplicate',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/haqs-battery/haqs-battery-editor.html',
                    controller: 'HaqsBatteryEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function (HaqsBatteryService) {
                            return HaqsBatteryService.findById($stateParams.id);
                        },
                    }
                }).result.then(function(test) {
                    if (test && test.id) {
                        $state.go('test.edit', {id: test.id, isFromHAQ: true});
                    } else {
                        $state.go('haqs-battery', null, {reload: true});
                    }
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
