(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('BatteryTestDeleteController',BatteryTestDeleteController);

    BatteryTestDeleteController.$inject = ['$uibModalInstance', 'entity', 'BatteryTest'];

    function BatteryTestDeleteController($uibModalInstance, entity, BatteryTest) {
        var vm = this;
        vm.batteryTest = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            BatteryTest.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
