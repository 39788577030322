(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CommonQuestionModalController', CommonQuestionModalController);

        CommonQuestionModalController.$inject = ['$uibModalInstance', 'tests'];

    function CommonQuestionModalController ($uibModalInstance, tests) {
        var vm = this;
        vm.tests = tests;
        vm.confirm = confirm;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $uibModalInstance.close(true);
        }
    }
})();
