(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('PatientService', PatientService);

    PatientService.$inject = ['$q', 'halClient', '$http', 'DateUtils'];

    function PatientService ($q, halClient, $http, DateUtils) {
    	var baseUrl = 'api';

        var service = {
            'searchPatients': searchPatients,
            'searchCPRPatients': searchCPRPatients,
            'savePatient': savePatient,
            'get': get,
            'getPatientByIdAndAutoLogin': getPatientByIdAndAutoLogin,
            'searchPatientCase': searchPatientCase
        };


        function searchPatients(searchText, status, pageable) {
    		var deferred = $q.defer();

            var url = baseUrl + '/patients/search';
            $http.get(url, {
				params: {
                    searchText: (searchText ? encodeURIComponent(searchText) : ''),
                    status: status,
					page: pageable.page,
					size: pageable.size,
					sort: pageable.sort
				}
			})
    		.success(function(data) {
    			deferred.resolve(data);
    		})
    		.error(function(error) {
    			deferred.reject(error);
    		});

    		return deferred.promise;
        };

        function searchCPRPatients(searchText, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/patients/cpr/search';
            $http.get(url, {
                params: {
                    query: (searchText ? encodeURIComponent(searchText) : ''),
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            })
                .success(function(data) {
                    deferred.resolve(data);
                })
                .error(function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function get(id) {
            var deferred = $q.defer();
        	var url = baseUrl + '/patients/' + id;
        	halClient.$get(url).then(function success(result) {
        		deferred.resolve(result);
    		}, function error(e) {
    			deferred.reject(error);
    		});
        	return deferred.promise;
        };

        function savePatient(patientQuestionnaireDTO) {
            var url = baseUrl + '/patients/create';
            if(patientQuestionnaireDTO.dateOfBirth) {
                patientQuestionnaireDTO.dateOfBirth = DateUtils.convertLocalDateToServer(patientQuestionnaireDTO.dateOfBirth);
            }
            return $http.post(url, patientQuestionnaireDTO).then(function (resp) {
                return resp.data;
            });
        }

        function getPatientByIdAndAutoLogin(id, surveyResultId) {
            var deferred = $q.defer();

            var url = baseUrl + '/patients/get-patient-and-auto-login/' + id;
            $http.get(url,
                {
                    params: {surveyResultId: surveyResultId}
                })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        /**
         * searchPatientCase
         * testFlag = true only get haqTestServiceExclusion = false
         * @param patientId
         * @param searchText
         * @param testFlag
         * @param pageable
         * @return {*}
         */
        function searchPatientCase(patientId, searchText, testFlag, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/patients/search-patient-case';
            $http.get(url, {
                params: {
                    query: (searchText ? encodeURIComponent(searchText) : ''),
                    patientId: patientId,
                    testFlag: testFlag,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            })
                .success(function(data) {
                    deferred.resolve(data);
                })
                .error(function(error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        return service;
    };
})();
