(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionOptionDialogController', QuestionOptionDialogController);

    QuestionOptionDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'QuestionOption', 'Question'];

    function QuestionOptionDialogController ($scope, $stateParams, $uibModalInstance, entity, QuestionOption, Question) {
        var vm = this;
        vm.questionOption = entity;
        vm.questions = Question.query();
        vm.load = function(id) {
            QuestionOption.get({id : id}, function(result) {
                vm.questionOption = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:questionOptionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.questionOption.id !== null) {
                QuestionOption.update(vm.questionOption, onSaveSuccess, onSaveError);
            } else {
                QuestionOption.save(vm.questionOption, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
