(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('sectionTimeoutDialogController', sectionTimeoutDialogController);

        sectionTimeoutDialogController.$inject = [
        '$interval',
        '$uibModalInstance',
        'remainingSeconds'
    ];

    function sectionTimeoutDialogController($interval, $uibModalInstance,  remainingSeconds) {
        var vm = this;
        vm.remainingSeconds = remainingSeconds;
        $interval(countdown, 1000);
        vm.stayLoggedIn = function () {
            $uibModalInstance.close(true);
        }

        vm.logOff = function(){
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        }

        function countdown() {
            vm.remainingSeconds--;
            if (vm.remainingSeconds == 0) {
                $uibModalInstance.close(true);
            }
        }
    }
})();
