(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('care-plan-review-workflow', {
            parent: 'app',
            url: '/care-plan-review-workflow',
            data: {
                authorities: ['ROLE_QHP'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/care-plan-review-workflow/care-plan-review-workflow.html',
                    controller: 'CarePlanReviewWorkflowController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('qhpWorkflow');
                    $translatePartialLoader.addPart('carePlanReviewStatus');
                    $translatePartialLoader.addPart('carePlanReview');
                    $translatePartialLoader.addPart('callLog');
                    $translatePartialLoader.addPart('partyType');
                    return $translate.refresh();
                }],
                cprEntity: ['CarePlanReviewWorkflowService', 'ClearLocalStorageService', '$state', '$rootScope', function(CarePlanReviewWorkflowService, ClearLocalStorageService, $state, $rootScope) {
                    var notInitialized = !ClearLocalStorageService.getInitDataCPRWorkflow();
                    var dataCPR = ClearLocalStorageService.getPatientIdForCPRWorkflow();
                    var patientId = dataCPR ? dataCPR.patientId : null;
                    var cprId = dataCPR ? dataCPR.cprId : null;
                    return CarePlanReviewWorkflowService.getData(patientId, cprId, notInitialized).then(function (response) {
                        if (response === 400) { // We will redirect to the homepage and attempt to reload the page.
                            $state.go("home");
                            $rootScope.$broadcast("REFRESH");
                            return;
                        } else {
                            ClearLocalStorageService.setInitDataCPRWorkflow(true);
                            return response;
                        }
                    });
                }],
            }
        });

    }

})();
