(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AdhocHaqEvaluationController', AdhocHaqEvaluationController);

    AdhocHaqEvaluationController.$inject = ['$scope', '$state', '$stateParams', 'ipmConstants', '$uibModal', 'HAQEvaluationWorkflowService', '$translate', 'Principal', 'ClearLocalStorageService'];

    function AdhocHaqEvaluationController($scope, $state, $stateParams, constants, $uibModal, HAQEvaluationWorkflowService, $translate, Principal, ClearLocalStorageService) {
        var vm = this; //jshint ignore:line

        vm.haqEvaluations = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'surveyResult.id';
        vm.searchQuery = '';

        vm.loadAll = loadAll;
        vm.search = search;

        init();

        function init() {
            vm.pageableHaq = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            HAQEvaluationWorkflowService.findReadyForReviewPaginationByPatient(vm.searchQuery, pageable).then(function (response) {
                if (response) {
                    vm.haqEvaluations = response.data;
                    vm.pageableHaq.totalItems = response.totalItems;
                    vm.isLoading = false;
                }
            });
        }

        function start(haq) {
            HAQEvaluationWorkflowService.adhocUpdateTimeSpent(haq.haqEvaluationId, false, false, false).then(function (res) {
                ClearLocalStorageService.setTimeSpent();
                // $state.go('haq-evaluation.workflow');
                $state.go('haq-evaluation');
            });
        }

        vm.confirmAndStartHaqEvaluation = function (haq) {
            if (!haq.wholeBatteries) {
                return;
            }
            HAQEvaluationWorkflowService.isReviewingHaqEvaluation(haq.haqEvaluationId).then(function (res) {
                if (res) {
                    openConfirmClearHAQEvaluation(haq);
                } else {
                    start(haq);
                }
            });
        }

         function openConfirmClearHAQEvaluation(haq) {
            $uibModal.open({
                templateUrl: 'app/shared/confirm-dialog/confirm-yes-no-dialog.controller.html',
                controller: 'ConfirmYesNoController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.adhocHaqEvaluation.clearConfirm"},
                    title: function () {return "global.form.confirm"}
                }
            }).result.then(function(result) {
                if (result) {
                    start(haq);
                }
            });
        }

        vm.loadPage = function (page) {
            vm.page = page;
            vm.loadAll();
        }

        vm.getIndex = function(index) {
            var result = (index + 1) + (vm.page - 1) * vm.pageSize;
            return result;
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function search(event) {
            vm.searchQuery = event.target.value;
            vm.page = 0;
            vm.reverse = true;
            vm.loadAll();
        }
    }
})();
