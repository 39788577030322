(function() {
    'use strict';

    angular.module('ipmApp')
        .controller('CallLogDialogController', CallLogDialogController);

    CallLogDialogController.$inject = ['$scope', '$uibModalInstance', 'ipmConstants', 'entity', 'CallLogService', 'isAdHoc', 'User'];

    function CallLogDialogController ($scope, $uibModalInstance, constants, entity, CallLogService, isAdHoc, User) {
        var vm = this;

        vm.partyTypes = constants.PARTY_TYPES;
        vm.patientId = entity.patientId;
        vm.callLogs = [];
        vm.callLog = entity;
        vm.datePickerOpenStatus = {};
        vm.close = close;
        vm.save = save;
        vm.isSaving = false;
        vm.page = 1;
        vm.predicate = 'dateLog';
        vm.reverse = true;
        vm.qhpUserAdHocId = null;

        init();

        function init() {
            if (isAdHoc) {
                User.getUserByCurrentLogin().then(function (response) {
                    vm.qhpUserAdHocId = response ? response.id : null;
                    updateCallLog();
                });
            }
            loadAllCallLogs();
        }

        function updateCallLog() {
            vm.callLog.qhpUserAdHocId = vm.qhpUserAdHocId;
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };


        function createInitCallLog() {
            vm.callLog = {
                id: null,
                patientId: entity.patientId,
                qhpUserId: entity.qhpUserId,
                qhpUserAdHocId: vm.qhpUserAdHocId,
                dateLog: Date.now(),
                party: null,
                summary: null,
                carePlanReviewId: entity.carePlanReviewId
            };
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.callLog) {
                if (vm.callLog.party !== 'OTHER') {
                    vm.callLog.selectedProvide = '';
                }
                if (!vm.callLog.id) {
                    CallLogService.create(vm.callLog, onSaveSuccess, onSaveError);
                } else {
                    CallLogService.update(vm.callLog, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            // reset object callLog
            resetForm();
            // reload list callLogs
            loadAllCallLogs();
        }

        function resetForm() {
            createInitCallLog();
            if ($scope.editForm !== null && $scope.editForm !== undefined) {
                $scope.editForm.$setPristine();
                $scope.editForm.$setUntouched();
            }
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
                $uibModalInstance.close(error);
            }
        }

        function loadAllCallLogs() {
            if (vm.patientId) {
                var pageable = {
                    page: vm.page > 0 ? vm.page - 1 : vm.page,
                    size: constants.PAGE_SIZE_MAX,
                    sort: sort()
                };
                CallLogService.findAllByPatientId(vm.patientId, pageable).then(function (response) {
                    vm.callLogs = response ? response.data : [];
                });
            }
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }
    }
})();
