(function() {
    'use strict';


    AgreementsAcknowledgmentController.$inject = ['$scope', 'AgreementsWorkflowService'];

    function AgreementsAcknowledgmentController($scope, AgreementsWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.save = save;
        vm.isSaving = false;
        init();

        function init() {
            if (vm.agreement) {
                if (vm.agreement.dateSigned === null || vm.agreement.dateSigned === undefined) {
                    vm.agreement.dateSigned = new Date(); // default dateSigned is today
                }
            }
        }

        function save() {
            vm.isSaving = true;
            agreementsEmit();
            vm.isSaving = false;
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.agreement = result ? result : null;
            agreementsEmit();
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        function agreementsEmit() {
            vm.onChange({agreement: vm.agreement ? vm.agreement : null});
        }

    }

    angular.module('ipmApp').component('agreementsAcknowledgment', {
        bindings: {
            agreement: '<',
            onChange: '&'
        },
        templateUrl: 'app/agreements-workflow/agreements-acknowledgment/agreements-acknowledgment.html',
        controller: AgreementsAcknowledgmentController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
