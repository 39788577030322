
(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AppointmentAddDialogController', AppointmentAddDialogController);

        AppointmentAddDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance','entity', 'supportedDataList', 'AppointmentService'];

    function AppointmentAddDialogController ($stateParams, $state, $uibModalInstance, entity, supportedDataList, AppointmentService) {
        var vm = this;
        vm.appointment = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        // load offices, providers
        vm.offices = supportedDataList.officeList ? supportedDataList.officeList : [];
        vm.providers = supportedDataList.providerList ? supportedDataList.providerList : [];

        vm.hourStep = 1;
        vm.minuteStep = 1;
        vm.decrementYear = 3;
        vm.minYear = 1970;
        vm.officeCustom = officeCustom;
        vm.appointmentExist = null;
        vm.appointmentCopy = null;

        init();

        function init() {

        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.appointmentExist = null;
            if (vm.appointment) {
                updateTimeOfAppointment();
                cloneAppointment();
                if (vm.appointment.id !== null) {
                    AppointmentService.update(vm.appointment, onSaveSuccess, onSaveError);
                } else {
                    AppointmentService.save(vm.appointment, onSaveSuccess, onSaveError);
                }
            }
        }

        function cloneAppointment() {
            vm.appointmentCopy = angular.copy(vm.appointment);
        }

        function updateTimeOfAppointment() {
            if (vm.appointment && vm.appointment.dateOfAppointment && vm.appointment.timeOfAppointment) {
                var dateTimeUpdate = vm.appointment.dateOfAppointment;
                // [start] updateTimeOfAppointment by dateOfAppointment
                dateTimeUpdate.setHours(vm.appointment.timeOfAppointment.getHours(), vm.appointment.timeOfAppointment.getMinutes(), 0);
                vm.appointment.timeOfAppointment = dateTimeUpdate;
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                if (errorMessage === 'This Appointment already exists') {
                    vm.appointmentExist = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                    $uibModalInstance.close(error);
                }
                // revert appointment
                vm.appointment = vm.appointmentCopy;
            }
        }

        vm.changedTime = function () {
        };

        function officeCustom(office) {
            return office ? office.name + ' (' + office.officeCode + ')' : '';
        }
    }
})();
