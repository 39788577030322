(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionOptionDetailController', QuestionOptionDetailController);

    QuestionOptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'QuestionOption', 'Question'];

    function QuestionOptionDetailController($scope, $rootScope, $stateParams, entity, QuestionOption, Question) {
        var vm = this;
        vm.questionOption = entity;
        vm.load = function (id) {
            QuestionOption.get({id: id}, function(result) {
                vm.questionOption = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:questionOptionUpdate', function(event, result) {
            vm.questionOption = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
