(function() {
    'use strict';


    CPRSectionController.$inject = ['$scope', '$state', 'ipmConstants'];

    function CPRSectionController($scope, $state, constants) {
        var vm = this; //jshint ignore:line
        vm.getValueTreatment = getValueTreatment;
        vm.getLastDate = getLastDate;
        vm.getAuthResponseDate = getAuthResponseDate;
        vm.getStatus = getStatus;
        vm.getSessionsCompleted = getSessionsCompleted;
        vm.isDisplayQuestionCPR13 = isDisplayQuestionCPR13;
        vm.isDisplayQuestionCPR15 = isDisplayQuestionCPR15;
        vm.surgicalCandidacySectionId = 3;
        vm.treamentHistorySectionId = 2;
        vm.removeChildsNewAnswersByCode = removeChildsNewAnswersByCode;
        vm.handleSurgicalCandidacyStatus = handleSurgicalCandidacyStatus;
        vm.checkInValidBillTo = checkInValidBillTo;

        var PHYSICAL_THERAPY = constants.TREATMENT_HISTORY_TYPE[0];
        var ACUPUNCTURE = constants.TREATMENT_HISTORY_TYPE[1];
        var CHIRO = constants.TREATMENT_HISTORY_TYPE[2];
        var PSYCH = constants.TREATMENT_HISTORY_TYPE[3];
        var MDE = constants.TREATMENT_HISTORY_TYPE[4];
        var FRP = constants.TREATMENT_HISTORY_TYPE[5];
        var INJECTIONS = constants.TREATMENT_HISTORY_TYPE[6];
        var selectStatusOptionForTreatmentTable = [
            'approvedNeedToSchedule',
            'approvedNoCompletionInfo',
            'approvedInProgress',
            'approvedPatientDeclined',
            'completed',
            'deniedURDenial',
            'deniedIMRDenial',
            'appealInProgress',
            'noAuthRequestFound',
            'authSentPending',
            'authSentNoResponse',
            'approvedMedicalHold',
            'deferredNotReviewed',
            'authorizedRetrospective'
        ];

        init();

        function init() {
            vm.answers = new Map(Object.entries(vm.section.answers));
            // vm.surgicalCandidacyStatus = vm.values.get(vm.surgicalCandidacyStatusValueKey);

            vm.treatmentTypes = [
                {
                    orderNumber: 0,
                    value: PHYSICAL_THERAPY,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(PHYSICAL_THERAPY),
                    status: vm.getStatus(PHYSICAL_THERAPY),
                    sessionsCompleted: vm.getSessionsCompleted(PHYSICAL_THERAPY),
                    authResponseDate: vm.getAuthResponseDate(PHYSICAL_THERAPY),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '99',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                },
                {
                    orderNumber: 1,
                    value: ACUPUNCTURE,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(ACUPUNCTURE),
                    status: vm.getStatus(ACUPUNCTURE),
                    sessionsCompleted: vm.getSessionsCompleted(ACUPUNCTURE),
                    authResponseDate: vm.getAuthResponseDate(ACUPUNCTURE),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '99',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                },
                {
                    orderNumber: 2,
                    value: CHIRO,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(CHIRO),
                    status: vm.getStatus(CHIRO),
                    sessionsCompleted: vm.getSessionsCompleted(CHIRO),
                    authResponseDate: vm.getAuthResponseDate(CHIRO),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '99',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                },
                {
                    orderNumber: 3,
                    value: PSYCH,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(PSYCH),
                    status: vm.getStatus(PSYCH),
                    sessionsCompleted: vm.getSessionsCompleted(PSYCH),
                    authResponseDate: vm.getAuthResponseDate(PSYCH),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '99',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                },
                {
                    orderNumber: 4,
                    value: MDE,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(MDE),
                    status: vm.getStatus(MDE),
                    sessionsCompleted: vm.getSessionsCompleted(MDE),
                    authResponseDate: vm.getAuthResponseDate(MDE),
                    sessionsCompletedMin: '1',
                    sessionsCompletedMax: '1',
                    treatmentStatuses: [ // IPMproject-396: CPR Edits: #4 In the Treatment History table, for the Status drop-down for the MDE treatment, please add a new status option "In Progress". This should be added above "Completed" and below "Approved - Patient Declined".
                        'approvedNeedToSchedule',
                        'approvedNoCompletionInfo',
                        'approvedInProgress',
                        'approvedPatientDeclined',
                        'inProgress',
                        'completed',
                        'deniedURDenial',
                        'deniedIMRDenial',
                        'appealInProgress',
                        'noAuthRequestFound',
                        'authSentPending',
                        'authSentNoResponse',
                        'approvedMedicalHold',
                        'deferredNotReviewed',
                        'authorizedRetrospective'
                    ]
                },
                {
                    orderNumber: 5,
                    value: FRP,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(FRP),
                    status: vm.getStatus(FRP),
                    sessionsCompleted: vm.getSessionsCompleted(FRP),
                    authResponseDate: vm.getAuthResponseDate(FRP),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '200',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                },
                {
                    orderNumber: 6,
                    value: INJECTIONS,
                    datePickerOpenStatus: {},
                    lastDate: vm.getLastDate(INJECTIONS),
                    status: vm.getStatus(INJECTIONS),
                    sessionsCompleted: vm.getSessionsCompleted(INJECTIONS),
                    authResponseDate: vm.getAuthResponseDate(INJECTIONS),
                    sessionsCompletedMin: '0',
                    sessionsCompletedMax: '20',
                    treatmentStatuses: selectStatusOptionForTreatmentTable
                }
            ];
        }

        vm.handleTreatmentHistoryTable = function(data) {
            var a = vm.values.get(constants.CPR_MAP_KEY_VALUES[0]);
            if (!a) {
                a = [];
            }
            var existing = _.head(_.filter(a, function(b) { return b.treatment === data.treatment; }));
            if (existing) {
                existing.sessionsCompleted = data.sessionsCompleted;
                existing.lastDate = data.lastDate;
                existing.authResponseDate = data.authResponseDate;
                existing.status = data.status
            } else {
                a.push(data);
            }
            
            vm.onSectionValuesChange({ data: { key: constants.CPR_MAP_KEY_VALUES[0], value: a } });
        }


        vm.onAnswerChange = function(data) {
            if (data.clearChilds) {
                // clear childs
                vm.removeChildsNewAnswersByCode(data.question);
            }
            // update value for this code
            vm.answers.set(data.question.code, data.value);
            vm.allAnswers.set(data.question.code, data.value);
            // emit to parent
            vm.onSectionAnswerChange({data: {section: vm.section, answer: vm.answers}});

            if (vm.section.id === vm.surgicalCandidacySectionId) {
                // handle Surgical Candidacy Status
                var currentSurgicalCandidacyStatus = vm.handleSurgicalCandidacyStatus(data);
                vm.onSectionValuesChange({data: {key: constants.CPR_MAP_KEY_VALUES[1], value: currentSurgicalCandidacyStatus}});
            }
        }

        function removeChildsNewAnswersByCode(question) {
            if (question) {
                _.forEach(question.childs, function(c) {
                    vm.removeChildsNewAnswersByCode(c);
                    vm.answers.delete(question.code);
                });
                vm.answers.delete(question.code);

                _.forEach(question.childs, function(c) {
                    vm.removeChildsNewAnswersByCode(c);
                    vm.allAnswers.delete(question.code);
                });
                vm.allAnswers.delete(question.code);
            }
        }

        function handleSurgicalCandidacyStatus(data) {
            var status = vm.surgicalCandidacyStatus;
            if (data) {
                if (data.question.code === 'CPR_018') {
                    if (data.value === 'NO') {
                        status = 'Not a candidate';
                    } else if (data.value === 'UNCLEAR') {
                        status = 'Unclear, need to update'
                    }
                } else if (data.question.code === 'CPR_019') {
                    if (data.value === 'NO') {
                        status = 'Candidate, Surgery not requested';
                    } else {
                        status = undefined;
                    }
                } else if (data.question.code === 'CPR_020') {
                    if (data.value === 'DENIED') {
                        status = 'Denied';
                    } else if (data.value === 'NO RESPONSE') {
                        status = 'No response to surgery RFA';
                    } else {
                        status = undefined;
                    }
                } else if (data.question.code === 'CPR_024') {
                    status = 'Denied on ' + data.value;
                } else if (data.question.code === 'CPR_021') {
                    if (data.value === 'YES') {
                        status = 'Surgery authorized, but patient declined';
                    } else if (data.value === 'NO') {
                        status = 'Surgery authorized patient accepted';
                    }
                } else if (data.question.code === 'CPR_022') {
                    if (data.value === 'YES') {
                        status = 'Patient had surgery';
                    } else if (data.value === 'NO') {
                        status = 'Authorized, pending surgery';
                    }
                }
            }
            vm.surgicalCandidacyStatus = status; // re-update status
            return status;
        }

        vm.isTreatmentHistorySection = function() {
            return vm.section.id === vm.treamentHistorySectionId;
        }

        function getValueTreatment(key) {
            var a = vm.values.get(constants.CPR_MAP_KEY_VALUES[0]);
            var value = _.head(_.filter(a, function(b) { return b.treatment === key; }));
            return value;
        }

        function getLastDate(key) {
            var a = getValueTreatment(key);
            if (a && a.lastDate) {
                return new Date(a.lastDate);
            }
        }

        function getAuthResponseDate(key) {
            var a = getValueTreatment(key);
            if (a && a.authResponseDate) {
                return new Date(a.authResponseDate);
            }
        }

        function getStatus(key) {
            var a = getValueTreatment(key);
            if (a) {
                return a.status;
            }
        }

        function getSessionsCompleted(key) {
            var a = getValueTreatment(key);
            if (a) {
                return a.sessionsCompleted;
            }
        }

        vm.displayQuestion = function(question) {
            if (question.code === 'CPR_013') {
                return vm.isDisplayQuestionCPR13();
            }
            if (question.code === 'CPR_015') {
                return vm.isDisplayQuestionCPR15();
            }
            return true;
        }

        function isDisplayQuestionCPR13() {

            var acupuncture  = vm.getSessionsCompleted(ACUPUNCTURE) >= 6 || vm.getStatus(ACUPUNCTURE) === 'approvedPatientDeclined' || vm.getStatus(ACUPUNCTURE) === 'deniedURDenial' || vm.getStatus(ACUPUNCTURE) === 'deniedIMRDenial';
            var psych = vm.getSessionsCompleted(PSYCH) >= 6 || vm.getStatus(PSYCH) === 'approvedPatientDeclined' || vm.getStatus(PSYCH) === 'deniedURDenial' || vm.getStatus(PSYCH) === 'deniedIMRDenial';
            var physicalTherapy = vm.getSessionsCompleted(PHYSICAL_THERAPY) >= 6 || vm.getStatus(PHYSICAL_THERAPY) === 'approvedPatientDeclined' || vm.getStatus(PHYSICAL_THERAPY) === 'deniedURDenial' || vm.getStatus(PHYSICAL_THERAPY) === 'deniedIMRDenial';
            var chiro = vm.getSessionsCompleted(CHIRO) >= 6 || vm.getStatus(CHIRO) === 'approvedPatientDeclined' || vm.getStatus(CHIRO) === 'deniedURDenial' || vm.getStatus(CHIRO) === 'deniedIMRDenial';

            if (acupuncture && psych && (physicalTherapy || chiro)) {
                return true;
            }
            clearAnswerValueByCode('CPR_013');
            return false;
        }

        function isDisplayQuestionCPR15() {
            var isValidWorkStatus = false;
            if (vm.allAnswers.get('CPR_009') === 'Part Time' || vm.allAnswers.get('CPR_009') === 'Non-working'
                            || vm.allAnswers.get('CPR_010') === 'Full Time Modified') {
                isValidWorkStatus = true;
            }
            if (!vm.allAnswers.get('CPR_009') && !vm.allAnswers.get('CPR_010')) {
                if (vm.patientWorkStatus === 'Part Time' || vm.patientWorkStatus === 'Part Time Modified' || vm.patientWorkStatus === 'Part Time Unmodified' || vm.patientWorkStatus === 'Non-working'
                            || vm.patientWorkStatus === 'Full Time Modified') {
                    isValidWorkStatus = true;
                }
            }
            if (isValidWorkStatus) {
                if (vm.allAnswers.get('CPR_018') === 'NO' || ((!vm.allAnswers.get('CPR_018') || vm.allAnswers.get('CPR_018') === 'NO') && (vm.allPreviousAnswers.get('CPR_018') === 'NO' || vm.surgicalCandidacyStatus === 'Not a candidate'))) {
                    if (vm.allAnswers.get('CPR_013') === 'YES') {
                        if (vm.isDisplayQuestionCPR13()) {
                            return true;
                        }
                    }
                }
            }
            // clear value if not display question 'CPR_015'
            clearAnswerValueByCode('CPR_015');
        }

        function getWorkStatus() {

        }

        function clearAnswerValueByCode(code) {
            var question = _.head(_.filter(vm.section.questions, function(q) { return q.code === code; }));
            if (question) {
                vm.answers.delete(question.code);
                vm.removeChildsNewAnswersByCode(question);
            }
        }

        vm.isSectionRequired = function() {
            return vm.section && (vm.section.id === 5 || vm.section.id === 6); // 'Case Summary & Notes' and 'Records Reviewed'
        }

        function checkInValidBillTo() {
            var hasCheck = !_.isEmpty(_.filter(vm.cases, function (caseItem) {
                return caseItem && caseItem.billTo;
            }));
            return !hasCheck;
        }
    }

    angular.module('ipmApp').component('cprSection', {
        bindings: {
            section: '<',
            values: '<',
            patientWorkStatus: '<',
            surgicalCandidacyStatus: '<',
            allAnswers: '<',
            allPreviousAnswers: '<',
            onSectionAnswerChange: '&',
            onSectionValuesChange: '&',
            cases: '=',
            deletedCases: '=',
            cprId: '<'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/section.html',
        controller: CPRSectionController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
