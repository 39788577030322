(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('multiOfficeController', multiOfficeController);

    multiOfficeController.$inject = [];

    function multiOfficeController() {
        var vm = this;
        vm.addAnotherOFfice = addAnotherOFfice;
        vm.removeSelectedOffice = removeSelectedOffice;
        vm.filteredOffices = filteredOffices;
        vm.changeSelectedOffice = changeSelectedOffice;
        vm.isShowAddAnotherOfficeLink = isShowAddAnotherOfficeLink;
        init();

        function init() {
            vm.selectedOffices = vm.selectedOffices && vm.selectedOffices != undefined ? vm.selectedOffices : [];
        }

        function addAnotherOFfice() {
            vm.selectedOffices.push({});

        }

        function removeSelectedOffice(index) {
            if (vm.selectedOffices && vm.selectedOffices.length === 1) {
                return;
            }
            vm.selectedOffices.splice(index, 1);
        }

        function changeSelectedOffice(index, office) {
            vm.selectedOffices[index] = office;

        }

        function filteredOffices(selectedOffice) {
            if (vm.selectedOffices.length == 0) {
                return vm.offices;
            }
            var filteredList = [];
            vm.offices.forEach(function (item) {
                if (item.id == selectedOffice.id || _.findIndex(vm.selectedOffices, {
                        id: item.id
                    }) === -1) {
                    filteredList.push(item);
                }

            })
            return filteredList;
        }

        function isShowAddAnotherOfficeLink() {
            return vm.selectedOffices && vm.selectedOffices.length < vm.offices.length;
        }
    }

    angular.module('ipmApp').component('multiOffices', {
        bindings: {
            formReference: '<',
            offices: '<',
            translateKey: '<',
            selectedOffices: '='
        },
        templateUrl: 'app/admin/user-management/multi-offices/multi-offices.html',
        controller: multiOfficeController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('user-management');
                return $translate.refresh();
            }]
        }
    })
})();
