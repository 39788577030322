(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider
        .state('patient-list', {
            parent: 'app',
            url: '/patient-list',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'ipmApp.patient.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/patient/patient-list.html',
                    controller: 'PatientListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('questionnaireStatusFilter');
                    $translatePartialLoader.addPart('questionnaireStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                supportedStatusFilter: ['NPQQuestionnaireService', function(NPQQuestionnaireService) {
                	return NPQQuestionnaireService.getSupportedStatusFilter();
                }]
            },
            params: {
            	searchPatientParamsInfo: null
            }
        })
        .state('patient-list.new', {
            parent: 'patient-list',
            url: '/new',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_BILLER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/patient/patient-dialog.html',
                    controller: 'PatientDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('patient');
                            $translatePartialLoader.addPart('surveyResultStatus');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('register');
                            $translatePartialLoader.addPart('user-management');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                id: null,
                                firstName: null, middleName: null, lastName: null,
                                email: null,
                                password: null,
                                phone: null,
                                patientIdCode: null,
                                sendRequest: true,
                                dateOfBirth: null,
                                providerId: null,
                                caseNo: null,
                                langKey: null,
                                createdBy: null, createdDate: null,
                                lastModifiedBy: null, lastModifiedDate: null, authorities: null
                            };
                        },
                        supportedDataList: ['Auth', function(Auth) {
                        	return Auth.getSupportedListRegister();
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-list', null, { reload: true });
                }, function() {
                    $state.go('patient-list');
                });
            }]
        })
        .state('patient-detail', {
            parent: 'patient-list',
            url: '/{id}',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'ipmApp.patient.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/patient/patient-detail.html',
                    controller: 'PatientDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('surveyResultStatus');
                    $translatePartialLoader.addPart('questionnaireStatus');
                    $translatePartialLoader.addPart('surveyResult');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('agreements');
                    $translatePartialLoader.addPart('classSessionRegistration');
                    $translatePartialLoader.addPart('carePlanReview');
                    $translatePartialLoader.addPart('carePlanReviewStatus');
                    $translatePartialLoader.addPart('downloadHaqEvaluation');
                    $translatePartialLoader.addPart('haqBatteryAdHoc');
                    $translatePartialLoader.addPart('adHocHAQStatus');
                    return $translate.refresh();
                }],
            },
            params: {
                searchSurveyResultParamsInfo: null,
            	searchPatientParamsInfo: null,
                type: null
            }
        }).state('appointment-new', {
                parent: 'patient-detail',
                url: '/new-appointment',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/patient/appointment/appointment-add-dialog.html',
                        controller: 'AppointmentAddDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('patient');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('appointment');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    id: null,
                                    dateOfAppointment: null,
                                    timeOfAppointment: null,
                                    active: true,
                                    officeId: null,
                                    providerId: null,
                                    patientId: $stateParams.id ? $stateParams.id : null
                                };
                            },
                            supportedDataList: ['Auth', function (Auth) {
                                return Auth.getSupportedListRegister();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment-edit', {
                parent: 'patient-detail',
                url: '/appointment/{appointmentId}/edit',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/patient/appointment/appointment-add-dialog.html',
                        controller: 'AppointmentAddDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('patient');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('appointment');
                                return $translate.refresh();
                            }],
                            entity: function (AppointmentService) {
                                return AppointmentService.findById($stateParams.appointmentId);
                            },
                            supportedDataList: ['Auth', function (Auth) {
                                return Auth.getSupportedListRegister();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment-delete', {
                parent: 'patient-detail',
                url: '/appointment/{appointmentId}/delete',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/patient/appointment/appointment-delete-dialog.html',
                        controller: 'AppointmentDeleteController',
                        controllerAs: 'vm',
                        size: 'sm',
                        windowClass: 'absolute-center',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('patient');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('appointment');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    appointmentId: $stateParams.appointmentId ? $stateParams.appointmentId : null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('appointment-create-questionnaire', {
                parent: 'patient-detail',
                url: '/appointment/{appointmentId}/create-questionnaire',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/patient/appointment/appointment-create-questionnaire.html',
                        controller: 'AppointmentCreateQuestionnaireController',
                        controllerAs: 'vm',
                        size: 'md',
                        windowClass: 'absolute-center',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('patient');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: function (AppointmentService) {
                                return AppointmentService.findById($stateParams.appointmentId);
                            }
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', { id: $stateParams.id }, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            }).state('course-add', {
            parent: 'patient-detail',
            url: '/course/add',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES] // ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/patient/wellness-class/class-session-registration-add-dialog.html',
                    controller: 'ClassSessionRegistrationAddDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('patient');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('classSessionRegistration');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                id: null,
                                course: {id: null},
                                classSession: null,
                                patientId: $stateParams.id ? $stateParams.id : null
                            };
                        },
                        courses: ['Course', function(Course) {
                            return Course.findAllCoursesRegistrationsByPatient($stateParams.id);
                        }],
                    }
                }).result.then(function () {
                    $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('course-delete', {
            parent: 'patient-detail',
            url: '/course/{csrId}/delete',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES] // ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/patient/wellness-class/class-session-registration-delete-dialog.html',
                    controller: 'ClassSessionRegistrationDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass: 'absolute-center',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('patient');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('classSessionRegistration');
                            return $translate.refresh();
                        }],
                        entity: ['ClassSessionRegistrationService', function(ClassSessionRegistrationService) {
                            return ClassSessionRegistrationService.findById($stateParams.csrId ? $stateParams.csrId : null);
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('add-adhoc-testing', {
            parent: 'patient-detail',
            url: '/add-adhoc-testing',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/patient/adhoc-testing/adhoc-testing-add-dialog.html',
                    controller: 'AdHocTestingAddDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('patient');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('haqBatteryAdHoc');
                            return $translate.refresh();
                        }],
                        patientId: function () {
                            return $stateParams.id ? $stateParams.id : null;
                        },
                        entity: function () {
                            return {
                                id: null,
                                active: true,
                                surveyResult: null,
                                batteryTestId: null,
                                patientId: $stateParams.id ? $stateParams.id : null
                            };
                        }
                    }
                }).result.then(function () {
                    $state.go('patient-detail', {id: $stateParams.id}, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        }).state('adhoc-testing-delete', {
            parent: 'patient-detail',
            url: '/add-adhoc-testing/{haqBatteryAdHocId}/delete',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/patient/adhoc-testing/adhoc-testing-delete-dialog.html',
                    controller: 'AdhocTestingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass: 'absolute-center',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('patient');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('haqBatteryAdHoc');
                            return $translate.refresh();
                        }],
                        entity: function () {
                            return {
                                id: $stateParams.haqBatteryAdHocId ? $stateParams.haqBatteryAdHocId : null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
