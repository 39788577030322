(function() {
    'use strict';


    CocAgreementNotifyController.$inject = [];

    function CocAgreementNotifyController() {
        var vm = this; //jshint ignore:line
        vm.collapse = true;

        init();

        function init() {}

        vm.changeCollapse = function() {
            vm.collapse = !vm.collapse;
        }
    }

    angular.module('ipmApp').component('cocNotifyComponent', {
        bindings: {
            patientIds: '<',
            textNotify: '<',
            type: '<', // error or success
            values: '<',
        },
        templateUrl: 'app/admin/coc-agreement/notify-component/notify.component.html',
        controller: CocAgreementNotifyController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
