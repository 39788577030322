(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadCaseExclusionsController', DownloadCaseExclusionsController);

    DownloadCaseExclusionsController.$inject = ['$filter', 'CaseExclusionsService', '$uibModal', '$interval', '$scope', 'ipmConstants', 'DateUtils'];

    function DownloadCaseExclusionsController ($filter, CaseExclusionsService, $uibModal, $interval, $scope, ipmConstants, DateUtils) {
        var vm = this;
        vm.download = download;
        vm.loadServiceDetails = loadServiceDetails;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;
        vm.isChangeService = false;
        vm.caseExclusionsTypes = ipmConstants.CASE_EXCLUSIONS_TYPES;
        vm.caseExclusionDetailsTypes = ipmConstants.CASE_EXCLUSION_DETAIL_TYPES;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.limitTimeMillisSecondsDownload = 1000*30; // 30 seconds
        vm.limitTimeStart = null;
        vm.hasSubmitedRequestDownloadCsv = false;
        vm.hasOpenningDialogRequest = false;
        vm.caseExclusionsFilter = [];
        var intervalLimitTimeDownload = null;
        vm.inValidDates = false;

        init();

        function init() {

            vm.fromDate.setDate(vm.fromDate.getDate() - 30);

            _.forEach(vm.caseExclusionDetailsTypes, function (detailType) {
                vm.caseExclusionsFilter.push({type: detailType, isExcluded: null, isHidden: false});
            });
        }

        function download() {
            vm.isProcessing = true;
            resetIntervalLimitTime();
            startIntervalLimitTimeDownload();
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            var serviceFilters= vm.caseExclusionsFilter;
            downloadCsvCaseExclusions(fromDate, toDate, serviceFilters);
        }

        function loadServiceDetails() {
            if (!vm.caseExclusionsType) {
                _.forEach(vm.caseExclusionsFilter, function (detailType) {
                    detailType.isHidden = false;
                })
            } else {
                _.forEach(vm.caseExclusionsFilter, function (detailType) {
                    if (ipmConstants.CASE_EXCLUSIONS_MAP[detailType.type] === vm.caseExclusionsType) {
                        detailType.isHidden = false;
                    } else {
                        detailType.isHidden = true;
                    }
                })
            }
            vm.isChangeService = true;
        }

        function requestDownloadCsvCaseExclusions(fromDate, toDate, caseExclusionsType, isExcluded) {
            if (!vm.hasSubmitedRequestDownloadCsv) {
                vm.isProcessing = true;
                CaseExclusionsService.requestDownloadCsvCaseExclusions(fromDate, toDate, caseExclusionsType, isExcluded).then(function (res) {
                    vm.isProcessing = false;
                    vm.noPdf = false;
                    vm.hasSubmitedRequestDownloadCsv = true;
                });
            }
            stopIntervalLimitTimeDownload();
        }

        function downloadCsvCaseExclusions(fromDate, toDate, serviceFilters) {
            vm.noPdf = false;
            CaseExclusionsService.downloadCsvCaseExclusions(fromDate, toDate, serviceFilters).then(function(res) {
                vm.isProcessing = false;
                vm.isChangeService = false;
                if (res === 204) { // SC_NO_CONTENT
                    vm.noPdf = true;
                }
                if (res === 509) { // BANDWIDTH_LIMIT_EXCEEDED
                    if (!vm.hasOpenningDialogRequest)
                        openDialogRequestDownloadCsvCaseExclusions(fromDate, toDate, serviceFilters);
                }
                // call stop interval when download empty < 30s
                stopIntervalLimitTimeDownload();
            });
        }

        function openDialogRequestDownloadCsvCaseExclusions(fromDate, toDate, serviceFilters) {
            vm.hasOpenningDialogRequest = true;
            $uibModal.open({
                templateUrl: 'app/shared/confirm-download-dialog/confirm-download-dialog.controller.html',
                controller: 'ConfirmDownloadController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                backdrop: 'static',
                size: 'sm'
            }).result.then(function (result) {
                if (result) { // Yes
                    requestDownloadCsvCaseExclusions(fromDate, toDate, serviceFilters);
                }
                // case No
                stopIntervalLimitTimeDownload();
                resetProcessingDownload();
            });
        }

        function resetProcessingDownload() {
            vm.isProcessing = false;
        }

        function resetIntervalLimitTime() {
            vm.hasSubmitedRequestDownloadCsv = false;
            vm.limitTimeStart = moment();
            vm.hasOpenningDialogRequest = false;
        }

        function stopIntervalLimitTimeDownload() {
            if (intervalLimitTimeDownload) {
                clearInterval(intervalLimitTimeDownload);
                $interval.cancel(intervalLimitTimeDownload);
            }
        }

        function startIntervalLimitTimeDownload() {
            intervalLimitTimeDownload = setInterval(function () {
                runIntervalLimitTimeDownload();
            }, 2000); // 2 seconds
        }

        function runIntervalLimitTimeDownload() {
            var millisDiff = moment().diff(vm.limitTimeStart);
            if (millisDiff > vm.limitTimeMillisSecondsDownload) {
                var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
                var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
                if (!vm.hasOpenningDialogRequest) {
                    openDialogRequestDownloadCsvCaseExclusions(fromDate, toDate);
                }
                // stop interval
                stopIntervalLimitTimeDownload();
            }
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };

        $scope.$on('$destroy', function () {
            stopIntervalLimitTimeDownload();
        });
    }
})();
