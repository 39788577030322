(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$rootScope', '$stateParams','$window', 'ipmConstants', '$timeout','$state', 'Auth', 'LoginService'];

    function ResetFinishController ($rootScope, $stateParams, $window, constants, $timeout, $state, Auth, LoginService) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;
        vm.invitation = Boolean;

        $timeout(function (){angular.element('[ng-model="vm.resetAccount.password"]').focus();});

        init();

        function init(){
            vm.invitation = $stateParams.invitation ;
        }

        function finishReset() {
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                    // $window.location.href = '/#/npq-questionnaire/workflow';
                    $rootScope.$broadcast(constants.EVT_AUTHENTICATION_SUCCESS_BY_INVITATION);
                    $state.go('home');
                }).catch(function (response) {
                    vm.success = null;
                    vm.error = response.data;
                });
            }
        }
    }
})();
