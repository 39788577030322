(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqTracksController', HaqTracksController);

    HaqTracksController.$inject = ['$scope', '$state', 'HaqTracksService', 'ipmConstants'];

    function HaqTracksController ($scope, $state, HaqTracksService, constants) {
        var vm = this;
        vm.haqTracks = [];
        vm.totalItems = 0;
        vm.pageSize = constants.PAGE_SIZE;
        vm.page = 1;
        vm.predicate = 'isDefault';
        vm.reverse = true;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: constants.PAGE_SIZE,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };

            HaqTracksService.findAll(pageable).then(function (response) {
                vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                vm.haqTracks = response && response.data ? response.data : [];
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id' + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }
    }
})();
