(function () {
    "use strict";

    ClassSessionDetailDetailListController.$inject = ['$rootScope', '$scope','$uibModal','SurveyWorkflowService'];

    function ClassSessionDetailDetailListController($rootScope, $scope, $uibModal, SurveyWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.register = register;
        vm.noThank = noThank;
        vm.goBack = goBack;
        vm.openClassDialog = openClassDialog;
        init();

        function init() {
            vm.patientCourseRegistration.acceptRegister = false;
            SurveyWorkflowService.registerCourses(
                vm.patientCourseRegistration
            ).then(function (response) {
                $rootScope.$broadcast('UpdateCourseRegistration', response.data);
            });
        }

        function register() {
            vm.patientCourseRegistration.acceptRegister = true;
            SurveyWorkflowService.registerCourses(
                vm.patientCourseRegistration
            ).then(function (response) {
                vm.skip();
            });
        }

        function noThank() {
            vm.skip();
        }

        function goBack() {
            vm.back();
        }

        function openClassDialog($index, classSessionId, classSequence) {
            $uibModal
                .open({
                    templateUrl:
                        "app/survey-workflow/partial/course-register-workflow/step/class-session-detail-list/class-session-modal.html",
                    controller: "classSessionModalController",
                    controllerAs: "vm",
                    backdrop: "static",
                    windowClass: 'absolute-center vertical-center',
                    size: "lg",
                    resolve: {
                        coursename: function () {
                            return  vm.patientCourseRegistration.courseNameSelected;
                        },
                        classsequence: function() {
                            return  classSequence;
                        },
                        classlist: ['SurveyWorkflowService', '$q', function (SurveyWorkflowService, $q) {
                            var deferred = $q.defer();
                            SurveyWorkflowService.getFirstTimeOfClassBySkipClassSessionId(classSessionId, vm.patientCourseRegistration ).then(function (response) {
                                deferred.resolve(response.data);
                            });
                            return deferred.promise;
                        }],
                    },
                })
                .result.then(
                    function (selectedClass) {
                        if (selectedClass !== undefined) {
                            vm.patientCourseRegistration.scheduledClassesSelected[$index] = selectedClass;
                        }
                    },
                    function () {}
                );
        }
    }

    angular.module("ipmApp").component("classSessionDetailListStep", {
        bindings: {
            patientCourseRegistration: "=",
            next: "&",
            skip: "&",
            back: "&",
        },
        templateUrl:
            "app/survey-workflow/partial/course-register-workflow/step/class-session-detail-list/class-session-detail-list.html",
        controller: ClassSessionDetailDetailListController,
        controllerAs: "vm",
        resolve: {
            translatePartialLoader: [
                "$translate",
                "$translatePartialLoader",
                function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart("surveyWorkflow");
                    return $translate.refresh();
                },
            ],
        },
        bindToController: true,
    });
})();
