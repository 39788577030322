(function() {
    'use strict';
	var module = angular.module('ipmApp')

	module.directive('timer', function($filter, $browser, $timeout, ClearLocalStorageService) {
        return {
            restrict: 'A',
            template: "<span>{{minutes}} minutes, {{seconds}} seconds</span>",
            scope: {
                onChange: '&',
                timeSec: '=',
                stop: '<',
                intervalTimeSecond: '<'
            },
            replace: true,
            link: function(scope, element, attrs) {
                scope.hours = "00";
                scope.minutes = "00";
                scope.seconds = "00";
                var interval = null;

                var endTimer = function() {
                    clearInterval(interval);
                }

                var correctInt = function(number) {
                    if (number < 10) {
                        return "0" + number;
                    }
                    return number;
                }

                var run = function() {
                    interval = setInterval(function () {
                        scope.$apply(function () {
                            if (scope.stop && scope.stop == true) {
                                scope.hours = correctInt(parseInt(scope.timeSec / 3600));
                                scope.minutes = correctInt(parseInt(scope.timeSec / 60));
                                scope.seconds = correctInt(scope.timeSec % 60);
                            } else {
                                var timeSpent = ClearLocalStorageService.getTimeSpent();
                                if (!timeSpent) { // not found
                                    scope.timeSec = 0; // reset timeSec
                                    endTimer();
                                }
                                scope.timeSec += 1;
                                scope.hours = correctInt(parseInt(scope.timeSec / 3600));
                                scope.minutes = correctInt(parseInt(scope.timeSec / 60));//scope.minutes = correctInt(parseInt(scope.timeSec % 3600 / 60));
                                scope.seconds = correctInt(scope.timeSec % 60);

                                if (!scope.intervalTimeSecond) {
                                    scope.intervalTimeSecond = 60; // 60second
                                }
                                if (scope.timeSec % scope.intervalTimeSecond === 0) {
                                    scope.onChange({data: scope.minutes});
                                }
                            }
                        })
                    }, 1000);
                };

                run();
            }
        };
	});

})();
