(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Office', Office);

    Office.$inject = ['$resource', '$http'];

    function Office ($resource, $http) {
        var resourceUrl =  'api/offices/:id';

        var service =  $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        service.getAllByLinkedUserLogged = getAllByLinkedUserLogged;

        function getAllByLinkedUserLogged(pageable) {
            return $http.get('api/offices/get-by-linked-user-logged', {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).then(function (response) {
                return response;
            });
        }

        return service;
    }
})();
