(function() {
    'use strict';


    PatientAgreementsController.$inject = ['$scope', 'AgreementsWorkflowService'];

    function PatientAgreementsController($scope, AgreementsWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.PatientAgreementResponse = null;
        vm.patientAgreements = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'position';
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.downloadAll = downloadAll;
        vm.isShowDownloadAll = isShowDownloadAll;
        vm.isAgreementSignedAllByAPI = isAgreementSignedAllByAPI;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            if (vm.patientId) {
                AgreementsWorkflowService.findAllByPatientId(vm.patientId, pageable).then(function (response) {
                    vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                    vm.patientAgreements = response ? response.data : [];
                });
            }
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function downloadAll() {
            if (vm.patientId) {
                AgreementsWorkflowService.downloadAllAgreementsByPatientId(vm.patientId).then(function (response) {
                });
            }
        }

        function isShowDownloadAll(patientAgreements) {
            return patientAgreements && patientAgreements.length > 0 && patientAgreements[0].isFinishAllAgreement;
        }

        function isAgreementSignedAllByAPI(patientAgreements) {
            return patientAgreements && patientAgreements.length > 0 && patientAgreements[0].agreementSignedAllByAPI;
        }
    }

    angular.module('ipmApp').component('patientAgreements', {
        bindings: {
            patientId: '<',
            isBiller: '<'
        },
        templateUrl: 'app/patient/agreements/patient-agreements.html',
        controller: PatientAgreementsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                $translatePartialLoader.addPart('agreements');
                return $translate.refresh();
            }]
        }
    })
})();
