(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('WebWorkerService', WebWorkerService);
    WebWorkerService.$inject = ['ipmConstants'];

    function WebWorkerService(constants) {
        var service = {
            'activate': activate,
            'close': close,
            'startTimeSpent': startTimeSpent,
            'getWebWorker': getWebWorker,
            'startLogoffInterval' : startLogoffInterval,
            'resetLogoffInterval': resetLogoffInterval,
            'clearLogoffInterval': clearLogoffInterval,
            'startNoticingLogoffInterval': startNoticingLogoffInterval,
            'resetNoticingLogoffInterval': resetNoticingLogoffInterval,
            'clearNoticingLogoffInterval': clearNoticingLogoffInterval
        }

        var webWorker = null;
        var versionCprWorker = 'v1';
        var urlCprWorker = 'content/js/workers/cpr-worker-' + versionCprWorker + '.js'; // we will change the version from cpr-worker-v1.js to clear the cache

        // Activation methods.
        function activate(force) {
            if (!webWorker || force) {
                webWorker = new Worker(urlCprWorker);
            }
            webWorker.postMessage({type: constants.WEB_WORKER.START});
            return webWorker;
        }

        function close() {
            if (webWorker) {
                webWorker.postMessage({type: constants.WEB_WORKER.CLOSE});
                webWorker.terminate();
                webWorker = null;
            }
        }

        function startTimeSpent(force) {
            if (!webWorker || force) {
                webWorker = new Worker(urlCprWorker);
            }
            webWorker.postMessage({type: constants.WEB_WORKER.START_TIME_SPENT});
            return webWorker;
        }

        function startLogoffInterval() {
            if (!webWorker) {
                webWorker = new Worker(urlCprWorker);
            }
            webWorker.postMessage({type: constants.WEB_WORKER.START_LOGOFF_INTERVAL});
            return webWorker;
        }

        function resetLogoffInterval() {
            if (webWorker) {
                webWorker.postMessage({type: constants.WEB_WORKER.RESET_LOGOFF_INTERVAL});
            }
        }

        function clearLogoffInterval() {
            if (webWorker) {
                webWorker.postMessage({type: constants.WEB_WORKER.CLEAR_LOGOFF_INTERVAL});
            }
        }

        function startNoticingLogoffInterval() {
            if (!webWorker) {
                webWorker = new Worker(urlCprWorker);
            }
            webWorker.postMessage({type: constants.WEB_WORKER.START_NOTICING_LOGOFF_INTERVAL});
            return webWorker;
        }

        function resetNoticingLogoffInterval() {
            if (webWorker) {
                webWorker.postMessage({type: constants.WEB_WORKER.RESET_NOTICING_LOGOFF_INTERVAL});
            }
        }

        function clearNoticingLogoffInterval() {
            if (webWorker) {
                webWorker.postMessage({type: constants.WEB_WORKER.CLEAR_NOTICING_LOGOFF_INTERVAL});
            }
        }

        function getWebWorker() {
            return webWorker;
        }

        return service;
    }
})();