(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'LANGUAGES', 'tmhDynamicLocale'];

    function JhiLanguageService ($q, $http, $translate, LANGUAGES, tmhDynamicLocale) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent,
            updateLangKey: updateLangKey
        };

        return service;

        function getAll () {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent () {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }
        
        function updateLangKey () {
            var url = 'api/users/update-langkey?langKey=' + tmhDynamicLocale.get();
            return $http.put(url).then(function (resource) {
                return resource;
            }, function (error) {
                return error;
            });
        }
    }
})();
