(function() {
    'use strict';


    PatientResponseDetailsController.$inject = ['$scope', '$state', '$window', 'ipmConstants', 'PatientPresentationService'];

    function PatientResponseDetailsController($scope, $state, $window, constants, PatientPresentationService) {
        var vm = this;

        vm.accordionStatus = {
            responseOpen: vm.isShowResponseOpen,
            attendanceOpen: vm.isShowAttendanceOpen,
        };
        vm.currentSection = 2;
        var submitDebounced = _.debounce(submitResponse, 300);
        vm.currentResponseIndex = -1;
        vm.currentResponseId = 0;
        init();

        function init() {
            vm.isShowResponseOpen = vm.isShowResponseOpen ? vm.isShowResponseOpen : false;
            vm.currentResponseIndex = findIndexResponses();
            if (vm.currentResponseIndex > -1) {
                vm.currentResponseId = vm.patientPresentation.patientPresentationResponses[vm.currentResponseIndex].id;
            }
        }

        function findIndexResponses() {
            return _.findIndex(vm.patientPresentation.patientPresentationResponses, function(o) {
                return o.slide && vm.slide && o.slide.id === vm.slide.id;
            });
        }

        function submitResponse() {
            PatientPresentationService.submitResponse(vm.patientPresentation).then(function(res) {
                if (res) {
                    vm.patientPresentation = res;
                    changePatientPresentation();
                }
            });
        }

        function changePatientPresentation() {
            vm.onChange({patientPresentation: vm.patientPresentation});
            vm.isChangePatientPresentation = !vm.isChangePatientPresentation;
        }

        $scope.$watch('vm.isPatientOpen', function(nval, oval) {
            if(nval === null || angular.equals(nval, oval)) {
                return;
            }
            if(nval === false) {
                vm.accordionStatus.responseOpen = false;
                vm.accordionStatus.attendanceOpen = false;
            } else {
                if(vm.currentSection == 2) {
                    vm.accordionStatus.attendanceOpen = true;
                } else {
                    vm.accordionStatus.responseOpen = true;
                }
            }
        });

        $scope.$watch('vm.accordionStatus.attendanceOpen', function(nval, oval) {
            if(nval === null || angular.equals(nval, oval)) {
                return;
            }
            if(nval == true) {
                vm.currentSection = 2;
                vm.accordionStatus.responseOpen = false;
                if(vm.isPatientOpen == false) {
                    vm.isPatientOpen = true;
                }
            } else {
                if(vm.isPatientOpen && vm.patientPresentation.presentationStatus === 'PRESENT') {
                    vm.accordionStatus.responseOpen = true;
                    vm.currentSection = 1;
                } else {
                    vm.isPatientOpen = false;
                }
            }
        });

        $scope.$watch('vm.patientPresentation.patientPresentationResponses[vm.currentResponseIndex].multiResponse', function(nval, oval) {
            if(nval === null || angular.equals(nval, oval)) {
                return;
            }

            var selected = [];
            var keys = Object.keys(nval);
            for (var i = 0; i < keys.length; i++) {
                if(nval[keys[i]]) {
                    selected.push(parseInt(keys[i]));
                }
            }
            vm.patientPresentation.patientPresentationResponses[vm.currentResponseIndex].response = JSON.stringify(selected);
        }, true);

        // $scope.$watch('vm.currentResponse', function(nval, oval) {
        //     var index = findIndexResponses();
        //     if (index > -1) {
        //         vm.patientPresentation.patientPresentationResponses[index] = vm.currentResponse;
        //     } else {
        //         vm.patientPresentation.patientPresentationResponses.push(vm.currentResponse);
        //     }
        // }, true);

        $scope.$watch('vm.patientPresentation', function(nval, oval) {
            if(nval === null || angular.equals(nval, oval) || vm.isSavingDrapChange) {
                return;
            }
            submitDebounced();
        }, true);
    }

    angular.module('ipmApp').component('patientResponseDetails', {
        bindings: {
            deck: '=',
            slide: '=',
            patientPresentation: '=',
            isPatientOpen: '=',
            isChangePatientPresentation: '=',
            isShowResponseOpen: '<',
            isSavingDrapChange: '<',
            isShowAttendanceOpen: '<',
            onChange: '&'
        },
        templateUrl: 'app/presentation-controls-instructor/presentation-patient-response/patient-response-details/patient-response-details.html',
        controller: PatientResponseDetailsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
