(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportPTPController', MDEReportPTPController);

    MDEReportPTPController.$inject = ['$scope', '$state', '$uibModal', 'mdeReport', 'templates', 'ipmConstants', '$window', 'MdeReportService', 'FRPMDETemplateService', 'DataUtils', 'isView'];

    function MDEReportPTPController($scope, $state, $uibModal, mdeReport, templates, ipmConstants, $window, MdeReportService, FRPMDETemplateService, DataUtils, isView) {
        var vm = this;
        vm.mdeReport = mdeReport ? mdeReport : {};
        vm.templates = templates ? templates : [];
        vm.mdeReportOld = {};
        vm.presentComplaintsSelect = null;
        vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.templateInputType = ipmConstants.FRP_MDE_TEMPLATE;

        vm.save = save;
        vm.isRequired = true;
        vm.isFinalize = false;
        vm.isDisabled = false;
        vm.isChanged = isChanged;
        vm.isView = isView ? true : vm.mdeReport ? vm.mdeReport.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;
        vm.submitted = false;
        vm.fieldRequrieds = [
            'historyofPresentIllness',
            'mechanismOfInjury',
            'presentComplaints',
            'conservativeCare',
            'surgicalHistory',
            'currentMedications',
            'summaryAndRecommendations'
        ];

        vm.dataHeaderReport = {};

        init();

        function init() {
            setTemplateValue();
            vm.mdeReportOld = DataUtils.deepCopy(mdeReport);
            vm.isFinalize = vm.mdeReport && vm.mdeReport.mdeReportMediacal
            && (vm.mdeReport.mdeReportMediacal.status === 'COMPLETE') ? true : false;
            setIsDisabled();
            vm.dataHeaderReport = buildDataHeaderReport(vm.mdeReport.patientFRPMDE);
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    mdeOfficeName: patientFRPMDE.mdeOfficeName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        function setTemplateValue() {
            if (mdeReport && mdeReport.mdeReportMediacal) {
                templateValue(mdeReport.mdeReportMediacal.historyofPresentIllnessTemplate);
                templateValue(mdeReport.mdeReportMediacal.mechanismOfInjuryTemplate);
                templateValue(mdeReport.mdeReportMediacal.presentComplaintsTemplate);
                templateValue(mdeReport.mdeReportMediacal.conservativeCareTemplate);
                templateValue(mdeReport.mdeReportMediacal.surgicalHistoryTemplate);
                templateValue(mdeReport.mdeReportMediacal.summaryAndRecommendationsTemplate);
            }
        }

        function templateValue(template) {
            if (template && template.id) {
                template.value = template.id.toString();
                if (!_.find(vm.templates, function (t) { return t.id === template.id && t.inputType === template.inputType; })) {
                    template.disabled = true;
                }
            }
        }

        function setIsDisabled() {
            if (vm.isView || (vm.mdeReport.patientFRPMDE && (vm.mdeReport.status === 'MDE_ABANDONED' || vm.mdeReport.patientFRPMDE.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        function isChanged() {
            for (var field in vm.mdeReport.mdeReportMediacal) {
                if (!vm.mdeReport.mdeReportMediacal[field]) {
                    vm.mdeReport.mdeReportMediacal[field] = null;
                }
            }
            for (var fieldOld in vm.mdeReportOld.mdeReportMediacal) {
                if (!vm.mdeReportOld.mdeReportMediacal[fieldOld]) {
                    vm.mdeReportOld.mdeReportMediacal[fieldOld] = null;
                }
            }
            return !angular.equals(vm.mdeReport, vm.mdeReportOld);
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                goToMdeReport();
            }
        }

        vm.confirmDialog = function (message) {
            if (vm.isView) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                       return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    goToMdeReport();
                }
            });
        }

        function goToMdeReport() {
            $state.go('mde-report', {id: vm.mdeReport.id});
        }

        vm.finalize = function () {
            vm.isRequired = true;
            if (vm.isView) {
                return;
            }
            checkValidation();
            if (vm.editForm.$invalid) {
                return;
            }
            this.submitted = true;
            vm.isSaving = true;
            if (vm.mdeReport) {
                // vm.mdeReport.mdeReportMediacal.status = 'COMPLETE';
                vm.mdeReport.finalize = true;
                MdeReportService.saveMDEReportPTP(vm.mdeReport).then(function (response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        function checkValidation() {
            vm.fieldRequrieds.forEach(function (field) {
                if (field && vm.editForm[field]) {
                    vm.editForm[field].$setDirty();
                }
            });
        }

        function save() {
            // vm.isRequired = false;
            // checkValidation();
            // if (vm.editForm.$invalid) {
            //     return;
            // }
            vm.isSaving = true;
            if (vm.mdeReport) {
                if (vm.mdeReport.mdeReportMediacal) {
                    if (vm.mdeReport.mdeReportMediacal.historyofPresentIllnessTemplate && vm.mdeReport.mdeReportMediacal.historyofPresentIllnessTemplate.content != vm.mdeReport.mdeReportMediacal.historyofPresentIllness) {
                        vm.mdeReport.mdeReportMediacal.historyofPresentIllnessTemplate = null;
                    }
                    if (vm.mdeReport.mdeReportMediacal.mechanismOfInjuryTemplate && vm.mdeReport.mdeReportMediacal.mechanismOfInjuryTemplate.content != vm.mdeReport.mdeReportMediacal.mechanismOfInjury) {
                        vm.mdeReport.mdeReportMediacal.mechanismOfInjuryTemplate = null;
                    }
                    if (vm.mdeReport.mdeReportMediacal.presentComplaintsTemplate && vm.mdeReport.mdeReportMediacal.presentComplaintsTemplate.content != vm.mdeReport.mdeReportMediacal.presentComplaints) {
                        vm.mdeReport.mdeReportMediacal.presentComplaintsTemplate = null;
                    }
                    if (vm.mdeReport.mdeReportMediacal.conservativeCareTemplate && vm.mdeReport.mdeReportMediacal.conservativeCareTemplate.content != vm.mdeReport.mdeReportMediacal.conservativeCare) {
                        vm.mdeReport.mdeReportMediacal.conservativeCareTemplate = null;
                    }
                    if (vm.mdeReport.mdeReportMediacal.surgicalHistoryTemplate && vm.mdeReport.mdeReportMediacal.surgicalHistoryTemplate.content != vm.mdeReport.mdeReportMediacal.surgicalHistory) {
                        vm.mdeReport.mdeReportMediacal.surgicalHistoryTemplate = null;
                    }
                    if (vm.mdeReport.mdeReportMediacal.summaryAndRecommendationsTemplate && vm.mdeReport.mdeReportMediacal.summaryAndRecommendationsTemplate.content != vm.mdeReport.mdeReportMediacal.summaryAndRecommendations) {
                        vm.mdeReport.mdeReportMediacal.summaryAndRecommendationsTemplate = null;
                    }
                }
                vm.mdeReport.mdeReportMediacal.status = 'INPROGRESS';
                MdeReportService.saveMDEReportPTP(vm.mdeReport).then(function (response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            goToMdeReport();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }
    }
})();
