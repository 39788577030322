(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller(
            'PresentationControlsInstructorController',
            PresentationControlsInstructorController
        );

    PresentationControlsInstructorController.$inject = [
        '$scope',
        '$rootScope',
        'wellnessClass',
        'Deck',
        'ClearLocalStorageService',
        'ipmConstants',
        'PresentationViewPatientService',
        'WellnessClassService',
        '$state',
        '$uibModal',
        '$window'
    ];

    function PresentationControlsInstructorController(
        $scope,
        $rootScope,
        wellnessClass,
        Deck,
        ClearLocalStorageService,
        constants,
        PresentationViewPatientService,
        WellnessClassService,
        $state,
        $uibModal,
        $window
    ) {
        var vm = this;
        vm.wellnessClass = wellnessClass;
        vm.wellnessClassName = '';
        vm.deck = null;
        vm.currentSlideIndex = 0;
        vm.currentSlide = null;
        vm.pdfCurrentData = null;
        vm.backClick = backClick;
        vm.nextClick = nextClick;
        vm.startClick = startClick;
        vm.stopPresentingClick = stopPresentingClick;
        vm.finalizeClick = finalizeClick;
        vm.inValidBack = inValidBack;
        vm.inValidNext = inValidNext;
        vm.isLastSilde = isLastSilde;
        vm.isLoading = false;
        vm.onchangePdfBack = onchangePdfBack;
        vm.onchangePdfNext = onchangePdfNext;
        vm.onchangeZoomOut = onchangeZoomOut;
        vm.onchangeZoomIn = onchangeZoomIn;
        vm.onchangeGoToPage = onchangeGoToPage;
        vm.sendScrollPositionToPresentationView = sendScrollPositionToPresentationView;
        vm.classNotesData = '';
        vm.classNotesMouseleave = classNotesMouseleave;
        vm.classNotesMouseEnter = classNotesMouseEnter;
        vm.classNotesOpen = true;
        vm.dragOnchange = dragOnchange;
        vm.isReloadChangeListPatient = false;
        vm.isSavingDrapChange = false;
        vm.isShowingAbsentPatient= false;

        vm.init = function () {
            setWellnessClassName();
            if (wellnessClass && wellnessClass.id && wellnessClass.deckId) {
                getDeskById(wellnessClass.deckId);
                if (vm.wellnessClass.instructorPatientPresentation && vm.wellnessClass.instructorPatientPresentation.currentStep !== 'FINAL') {
                    openPresentationView();
                }
            }
        };

        function openPresentationView() {
            var pdfViewer = angular.element('.pdf-viewer');
            var viewerWidth = pdfViewer.width();
            PresentationViewPatientService.openPresentationView(
                vm.wellnessClass.id,
                viewerWidth
            );
            ClearLocalStorageService.setNameClassPresentationView(
                vm.wellnessClassName
            );
        }

        function setWellnessClassName() {
            if (wellnessClass) {
                vm.wellnessClassName =
                    wellnessClass.courseName +
                    ' - Session #' +
                    wellnessClass.sequence +
                    ': ' +
                    wellnessClass.name;
            }
        }

        function getDeskById(id) {
            Deck.get({
                id: id
            }, function (response) {
                vm.deck = response ? response : null;
                setCurrentSlide(vm.currentSlideIndex);
                if (vm.currentSlide && vm.currentSlide.id) {
                    setPdfCurrentDataBySlideId(vm.currentSlide.id);
                }
            });
        }

        function setCurrentSlide(index) {
            if (vm.deck.slides) {
                vm.currentSlide = getSlideByIndex(index);
            } else {
                vm.currentSlide = null;
            }
        }

        function getSlideByIndex(index) {
            if (vm.deck && vm.deck.slides) {
                return vm.deck.slides[index];
            }
        }

        function setPdfCurrentDataBySlideId(id) {
            Deck.downloadSlideBlobFile(id)
                .then(function (response) {
                    vm.pdfCurrentData = response ? response : null;
                    vm.isLoading = false;
                    onCurrentSlideWCChange();
                })
                .catch(function () {
                    vm.isLoading = false;
                });
        }

        function backClick() {
            vm.isLoading = true;
            if (vm.deck && vm.currentSlideIndex > 0) {
                vm.currentSlideIndex--;
                clearPdfViewConfig();
                setCurrentSlide(vm.currentSlideIndex);
                if (vm.currentSlide && vm.currentSlide.id) {
                    setPdfCurrentDataBySlideId(vm.currentSlide.id);
                }
            }
        }

        function nextClick() {
            vm.isLoading = true;
            if (vm.deck && vm.currentSlideIndex + 1 < vm.deck.numberOfSlides) {
                vm.currentSlideIndex++;
                clearPdfViewConfig();
                setCurrentSlide(vm.currentSlideIndex);
                if (vm.currentSlide && vm.currentSlide.id) {
                    setPdfCurrentDataBySlideId(vm.currentSlide.id);
                }
            }
        }

        function startClick() {
            vm.isLoading = true;
            // update currentStep to INPROGRESS
            vm.wellnessClass.instructorPatientPresentation.currentStep = 'INPROGRESS';
            WellnessClassService.updateInstructorPatientPresentation(vm.wellnessClass).then(function (response) {
                vm.isLoading = false;
            });
        }

        function stopPresentingClick() {
            $uibModal.open({
                templateUrl: 'app/presentation-controls-instructor/presentation-controls-instructor-final-dialog.html',
                controller:['$uibModalInstance','confirm', function($uibModalInstance,confirm) {
                    this.confirm = function() {
                        $uibModalInstance.close();
                        confirm();
                    }
                    this.clear = function (){
                        $uibModalInstance.dismiss('cancel');
                    }
                }],
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                resolve: {
                    confirm: function() {
                        return stopPresenting;
                    },
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function stopPresenting() {
            vm.isLoading = true;
            // update currentStep to FINAL
            vm.wellnessClass.instructorPatientPresentation.currentStep = 'FINAL';
            WellnessClassService.updateInstructorPatientPresentation(vm.wellnessClass).then(function (response) {
                vm.isLoading = false;
                PresentationViewPatientService.closedPresentationView();
            });
        }

        function finalizeClick() {
            vm.isLoading = true;
            // update status to FINAL
            vm.wellnessClass.instructorPatientPresentation.currentStep = 'FINAL';
            vm.wellnessClass.instructorPatientPresentation.status = 'FINAL';
            WellnessClassService.updateInstructorPatientPresentation(vm.wellnessClass).then(function (response) {
                vm.isLoading = false;
                gotToWellnessClassList();
            });
        }

        function classNotesMouseleave() {
            if (vm.wellnessClass.instructorPatientPresentation.classNotes !== vm.classNotesData) {
                WellnessClassService.updateInstructorPatientPresentation(vm.wellnessClass).then(function (response) {
                });
            }
        }

        function classNotesMouseEnter() {
            vm.classNotesData = vm.wellnessClass.instructorPatientPresentation.classNotes;
        }

        function dragOnchange(patientPresentations) {
            if (patientPresentations) {
                vm.isSavingDrapChange = true;
                vm.wellnessClass.instructorPatientPresentation.patientPresentations = patientPresentations;
                WellnessClassService.updateInstructorPatientPresentation(vm.wellnessClass).then(function (response) {
                    vm.wellnessClass = response ? response : vm.wellnessClass;
                    vm.onChangeReloadPPList();
                    // disable patientPresentation watch update
                    $window.setTimeout(function () {
                        vm.isSavingDrapChange = false;
                    }, 300);
                });
            }
        }

        function inValidBack() {
            if (vm.currentSlideIndex > 0) {
                return false;
            }
            return true;
        }

        function inValidNext() {
            if (
                vm.deck &&
                vm.currentSlideIndex >= 0 &&
                vm.currentSlideIndex + 1 < vm.deck.numberOfSlides
            ) {
                return false;
            }
            return true;
        }

        function isLastSilde() {
            return inValidNext() === true;
        }

        function onchangePdfBack(currentPage) {
            ClearLocalStorageService.setCurrentPagePdfViewSlide(currentPage);
            PresentationViewPatientService.changeCurrentPage();
        }

        function onchangePdfNext(currentPage) {
            ClearLocalStorageService.setCurrentPagePdfViewSlide(currentPage);
            PresentationViewPatientService.changeCurrentPage();
        }

        function onchangeZoomIn(scaleZoom) {
            ClearLocalStorageService.setScaleZoomIn(scaleZoom);
            PresentationViewPatientService.zoomIn();
        }

        function onchangeZoomOut(scaleZoom) {
            ClearLocalStorageService.setScaleZoomOut(scaleZoom);
            PresentationViewPatientService.zoomOut();
        }

        function onchangeGoToPage(currentPage) {
            ClearLocalStorageService.setCurrentPagePdfViewSlide(currentPage);
            PresentationViewPatientService.changeCurrentPage();
        }

        function onCurrentSlideWCChange() {
            if (vm.currentSlide) {
                ClearLocalStorageService.setCurrentSlideWC(vm.currentSlide.id);
                PresentationViewPatientService.changePDFdata();
            }
        }

        vm.onChangeReloadPNPList = function onChangeReloadPNPList() {
            vm.isReloadChangeListPatient = !vm.isReloadChangeListPatient;
        };

        vm.onChangeReloadPPList = function onChangeReloadPPList() {
            vm.isReloadChangeListPatient = !vm.isReloadChangeListPatient;
        };

        function clearPdfViewConfig() {
            vm.pdfCurrentData = null;
            vm.currentSlide = 0;
            ClearLocalStorageService.clearCurrentPagePdfViewSlide();
            ClearLocalStorageService.clearScaleZoomIn();
        }

        function sendScrollPositionToPresentationView(position) {
            PresentationViewPatientService.sendScrollPosition({
                scrollPosition: position,
            });
        }

        function gotToWellnessClassList() {
            $state.go('wellness-class-list');
        }

        $scope.$on('$destroy', function () {
            ClearLocalStorageService.clearCurrentSlideWC();
            ClearLocalStorageService.clearNameClassPresentationView();
            clearPdfViewConfig();
        });

        vm.init();
    }
})();
