(function () {
    'use strict';

    UploadImagesComponent.$inject = ['$scope'];

    function UploadImagesComponent($scope) {
        var vm = this; //jshint ignore:line
        vm.picFile = null;
        vm.hasChanged = false;
        vm.cropSize = 300;

        init();

        function init() {
            vm.hasChanged = false;
            vm.imageData = vm.imageData ? vm.imageData : '';
        }

        vm.onImageSelect = function ($files) {
            if ($files && $files.length > 0) {
                vm.hasChanged = true;
            }
        }

        vm.imgCropOnChange = function () {
        }

        vm.removeImage = function () {
            vm.imageData = null;
            vm.picFile = null;
            vm.hasChanged = false;
        }

    }

    angular.module('ipmApp').component('uploadImages', {
        bindings: {
            imageData: '='
        },
        templateUrl: 'app/components/upload-images/upload-images.component.html',
        controller: UploadImagesComponent,
        controllerAs: 'vm',
        resolve: {}
    });
})();
