(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('UserSearch', UserSearch);

    UserSearch.$inject = ['$q', '$http'];

    function UserSearch ($q, $http) {
    	var baseUrl = 'api';

        var service = {
        	'searchUsers': searchUsers
        };

        function searchUsers(query, pageable) {
    		var deferred = $q.defer();

        	var url = baseUrl + '/users/search';
        	$http.get(url, {
				params: {
					query: ((query != null) ? encodeURIComponent(query) : null),
					page: pageable.page,
					size: pageable.size,
					sort: pageable.sort
				}
			})
			.success(function(data) {
				deferred.resolve(data);
			})
			.error(function(e) {
				deferred.reject(e);
			});

			return deferred.promise;
        }
        
        return service;
    }
})();
