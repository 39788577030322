(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('frp-mde-reports', {
            parent: 'entity',
            url: '/frp-mde-reports',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'ipmApp.patient.report.frpMde.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/frp-mde-reports/frp-mde-reports.html',
                    controller: 'FrpMdeReportsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
