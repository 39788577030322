(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('download-care-plan-review', {
            parent: 'app',
            url: '/download-care-plan-review',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'global.menu.entities.downloadCarePlanReview'
            },
            views: {
                'content@': {
                    templateUrl: 'app/download-care-plan-review/download-care-plan-review.html',
                    controller: 'DownloadCarePlanReviewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadCarePlanReview');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
        });
    }

})();
