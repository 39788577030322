(function() {
    'use strict';

    angular
        .module('ipmApp', [
            'ngStorage', 
            'tmh.dynamicLocale',
            'pascalprecht.translate', 
            'ngResource',
            'ngCookies',
            'ngSanitize',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.sortable',
            'infinite-scroll',
            'ngImgCrop',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'angular-hal',
            'rorymadden.date-dropdowns',
            'signature',
            'pdf',
            'ui.select'
        ])
        .run(run);

    run.$inject = ['$rootScope', '$state', 'stateHandler', 'translationHandler'];

    function run($rootScope, $state, stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
        
        // error page
        $rootScope.$on('ipmApp.httpError', function(response) {
        	$state.go('error');
        });
        
        $rootScope.$on( '$stateChangeStart', function ( event, to, toParams, from, fromParams ) {
        	from.params = fromParams;
        	$state.previous = from;
        });
    }
})();
