(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultDeleteController',SurveyResultDeleteController);

    SurveyResultDeleteController.$inject = ['$uibModalInstance', 'entity', 'SurveyResult'];

    function SurveyResultDeleteController($uibModalInstance, entity, SurveyResult) {
        var vm = this;
        vm.surveyResult = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            SurveyResult.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
