(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('BatteryController', BatteryController);

    BatteryController.$inject = ['$scope', '$state', 'Battery', 'BatterySearch'];

    function BatteryController ($scope, $state, Battery, BatterySearch) {
        var vm = this;
        vm.batteries = [];
        vm.loadAll = function() {
            Battery.query(function(result) {
                vm.batteries = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            BatterySearch.query({query: vm.searchQuery}, function(result) {
                vm.batteries = result;
            });
        };
        vm.loadAll();
        
    }
})();
