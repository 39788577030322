(function () {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('npq-questionnaire', {
                abstract: true,
                parent: 'app',
                url: '/npq-questionnaire',
                data: {
                    authorities: [],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/npq-questionnaire/npq-questionnaire.html',
                        controller: 'NPQQuestionnaireController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('npqWorkflowDemographics');
                        $translatePartialLoader.addPart('npqWorkflowWorkRelatedInjuries');
                        $translatePartialLoader.addPart('npqWorkflowInjuryDetails');
                        $translatePartialLoader.addPart('npqWorkflowDiagnosticStudies');
                        $translatePartialLoader.addPart('npqWorkflowPresentComplaints');
                        $translatePartialLoader.addPart('npqWorkflowTreatmentHistory');
                        $translatePartialLoader.addPart('npqWorkflowPastMedicalHistory');
                        $translatePartialLoader.addPart('npqWorkflowSocialHistory');
                        $translatePartialLoader.addPart('npqWorkflowAllergies');
                        $translatePartialLoader.addPart('npqWorkflowMedications');
                        $translatePartialLoader.addPart('npqWorkflowCurrentIndications');
                        $translatePartialLoader.addPart('npqWorkflowReviewOfSystems');
                        $translatePartialLoader.addPart('npqWorkflowPastMedicalHistoryFinish');
                        $translatePartialLoader.addPart('npqWorkflowPersonalInjury');
                        $translatePartialLoader.addPart('npqQuestionnaire');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    npqQuestionnaire: ['NPQQuestionnaireService', '$window', 'ipmConstants', function(NPQQuestionnaireService, $window, constants) {
                        var id = $window.localStorage.getItem(constants.NPQ_QUESTIONNAIRE_ID);
                        return NPQQuestionnaireService.getNPQQuestionnaire(id);
                    }],
                    masterData: ['NPQQuestionnaireService', function(NPQQuestionnaireService) {
                        return NPQQuestionnaireService.getMasterData();
                    }]
                }
            }).state('npq-questionnaire.workflow', {
                parent: 'npq-questionnaire',
                url: '/workflow',
                data: {
                    authorities: [],
                    pageTitle: ''
                },
                views: {
                    'workflow-view@npq-questionnaire': {
                        templateUrl: 'app/npq-questionnaire/workflow/workflow.html',
                        controller: 'NPQQuestionnaireWorkflowController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    agreement: ['$state', '$q', 'AgreementsWorkflowService', function ($state, $q, AgreementsWorkflowService) {
                        var deferred = $q.defer();
                        AgreementsWorkflowService.getCurrentAgreementWorkflow(null).then(function (res) {
                            if (res && !res.isFinishAllAgreement) {
                                $state.go('agreements-workflow');
                            } else {
                                deferred.resolve(res);
                            }
                        });
                        return deferred.promise;
                    }],
                    dataWorkflow: ['$state', 'npqQuestionnaire', 'NPQQuestionnaireService', function($state, npqQuestionnaire, NPQQuestionnaireService) {
                        if(npqQuestionnaire.id) {
                            return NPQQuestionnaireService.viewWorkflow(npqQuestionnaire.id);
                        } else {
                            return $state.go('home');
                        }
                    }]
                }
            });
    }

})();
