(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('QHPWorkflowService', QHPWorkflowService);

    QHPWorkflowService.$inject = ['$resource', '$http', '$q'];

    function QHPWorkflowService ($resource, $http, $q) {
        var resourceUrl = "api/qhp-workflow/";
        var service = $resource(resourceUrl, {}, {});
        service.getQHPUserDetail = getQHPUserDetail;

        function getQHPUserDetail() {
            return $http.get(resourceUrl + '/get-qhp-user').then(function (response) {
                return response.data;
            });
        };

        return service;
    };
})();
