(function() {
    'use strict';

    ColumnCheckboxItemController.$inject = [];

    function ColumnCheckboxItemController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            vm.columns = _.cloneDeep(vm.config.columns);
            var answer = vm.config.answers.get(vm.config.question.code);
            
            if (answer) {
                answer = new Map(Object.entries((answer)));
                for(var i = 0; i < vm.columns.length; i++) {
                    var c = vm.columns[i];
                    if (answer.get(c.value)) {
                        var d = answer.get(c.value);
                        if (!_.isEmpty(_.filter(d, function(q) {
                            return q.name === vm.item.name;
                        }))) {
                            c.checked = true;
                            break;
                        }
                    }
                }
            }
            
        }

        vm.change = function(item, checked) {
            if (checked) {
                // clear another column
                _.forEach(vm.columns, function(l) {
                    if (l.value !== item.value) {
                        l.checked = false;
                    }
                });
            }
            vm.onChange({ data: { column: item.value, checked: checked, checkboxValue: vm.item } });
        }
    }

    angular.module('ipmApp').component('columnCheckboxItem', {
        bindings: {
            config: '<',
            item: '<',
            onChange: '&'
        },
        templateUrl: 'app/qhp-workflow/haq-evaluation-workflow/multiple-column-checkbox-question/column-checkbox-item.html',
        controller: ColumnCheckboxItemController,
        controllerAs: 'vm'
    })
})();
