(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadDetailedAdhocTestingController', DownloadDetailedAdhocTestingController);

    DownloadDetailedAdhocTestingController.$inject = ['$filter', 'AdhocTestingService', '$uibModal', '$interval', '$scope', 'ipmConstants', 'tests', 'DateUtils'];

    function DownloadDetailedAdhocTestingController ($filter, AdhocTestingService, $uibModal, $interval, $scope, ipmConstants, tests, DateUtils) {
        var vm = this;
        vm.download = download;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.limitTimeMillisSecondsDownload = 1000*30; // 30 seconds
        vm.limitTimeStart = null;
        vm.hasSubmitedRequestDownloadCsv = false;
        vm.hasOpenningDialogRequest = false;
        vm.tests = tests;
        vm.selectedTest = tests[0];
        vm.invalidDates = false;

        init();

        function init() {
            vm.fromDate.setDate(vm.fromDate.getDate() - 30);
        }

        function download() {
            vm.isProcessing = true;
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            downloadCsvDetailedAdhocTesting(fromDate, toDate, vm.selectedTest);
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };

        function downloadCsvDetailedAdhocTesting(fromDate, toDate, selectedTest) {
            vm.noPdf = false;
            AdhocTestingService.downloadCsvDetailedAdhocTesting(fromDate, toDate, selectedTest.id).then(function(res) {
                vm.isProcessing = false;
                if (res === 204) { // SC_NO_CONTENT
                    vm.noPdf = true;
                }
            });
        }

    }
})();
