(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResultDialogController', SurveyResultDialogController);

    SurveyResultDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'SurveyResult', 'Patient', 'Battery'];

    function SurveyResultDialogController ($scope, $stateParams, $uibModalInstance, entity, SurveyResult, Patient, Battery) {
        var vm = this;
        vm.surveyResult = entity;
        vm.patients = Patient.query();
        vm.batterys = Battery.query();
        vm.load = function(id) {
            SurveyResult.get({id : id}, function(result) {
                vm.surveyResult = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:surveyResultUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.surveyResult.id !== null) {
                SurveyResult.update(vm.surveyResult, onSaveSuccess, onSaveError);
            } else {
                SurveyResult.save(vm.surveyResult, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
