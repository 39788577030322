(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('frpMdeController', frpMdeController);

    frpMdeController.$inject = ['$scope', '$rootScope', '$state', '$window', '$uibModal', '$q', 'supportedDataList','Principal', 'ipmConstants', 'userDetails','frpMdeService', 'frpPrograms', '$stateParams', 'ClearLocalStorageService'];

    function frpMdeController($scope, $rootScope, $state, $window, $uibModal, $q, supportedDataList, Principal, ipmConstants, userDetails, frpMdeService, frpPrograms, $stateParams, ClearLocalStorageService) {
        var vm = this;
        vm.frpPrograms = frpPrograms;
        vm.searchPatient = searchPatient;
        vm.predicate ='pfm.id';
        vm.showPatientData = showPatientData;
        vm.searchResultEle = angular.element("#search-patient-auto-complete");
        vm.userDetails = userDetails;
        vm.frpProgramId = null;
        vm.tabFrp = 0;
        vm.tabMde = 1;
        vm.tabInactive = 2;
        vm.activeTabIndex = vm.tabFrp;
        vm.tabSelect = tabSelect;
        vm.patientSearchSelect = '';
        vm.isShowWarningMessagePreviousWeekNotFinalized = false;
        vm.isView = false;

        init();

        function init() {
            var paramTab = $stateParams && $stateParams.tab ? parseInt($stateParams.tab) : null;
            vm.activeTabIndex =  paramTab && paramTab <= vm.tabInactive && paramTab >= vm.tabFrp ? paramTab : vm.tabFrp;

            if (ClearLocalStorageService.getFRPProgram()) {
                vm.frpProgramId = Number(ClearLocalStorageService.getFRPProgram());
                var hasFRPProgramError = checkExistsFrpProgramError(vm.frpProgramId);
                if (hasFRPProgramError) {
                   setFrpProgramId();
                }
            } else if (vm.frpPrograms && vm.frpPrograms.length > 0) {
               setFrpProgramId();
            }

            setIsView();
        }

        function setIsView() {
            var frpProgramCurrent = getFrpProgramCurrent();
            vm.isView = frpProgramCurrent && !frpProgramCurrent.isFrpProgram && frpProgramCurrent.isViewFrpProgram;
        }

        function getFrpProgramCurrent() {
            if (vm.frpProgramId)
                return _.first(_.filter(vm.frpPrograms, function (frpProgram) {
                    return frpProgram && frpProgram.id === vm.frpProgramId;
                }));
        }

        function checkExistsFrpProgramError(frpProgramId) {
            var frpProgramsError = ClearLocalStorageService.getFRPProgramsError();
            if (_.isEmpty(frpProgramsError)) {
                return false;
            }
            return frpProgramsError.includes(frpProgramId);
        }

        function setFrpProgramId() {
            vm.frpProgramId = autoGetFRPProgramId();
            ClearLocalStorageService.setFRPProgram(vm.frpProgramId);
        }

        function autoGetFRPProgramId() {
            if (_.isEmpty(vm.frpPrograms)) {
                return null;
            }

            var frpPrograms = angular.copy(vm.frpPrograms);
            var frpProgramsError = ClearLocalStorageService.getFRPProgramsError();

            if (!_.isEmpty(frpProgramsError)) {
                frpPrograms = _.filter(frpPrograms, function(frpProgram) {
                    return !frpProgramsError.includes(frpProgram.id);
                });
            }
            // If frpPrograms is not empty, return the id of the first frpProgram;
            // otherwise, return the id of the default frpProgram
            return !_.isEmpty(frpPrograms) ? frpPrograms[0].id : vm.frpPrograms[0].id;
        }


        function searchPatient($item) {
            var inputData = $item ? $item : '';
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            var param = {
                patientId: null,
                frpProgramIds: vm.frpProgramId,
                frpStatus:null,
                startDate: null,
                query: inputData
            };
            return $q(function (resolve, reject) {
                frpMdeService.searchPatientFRPMDE(param, pageable).then(function (response) {
                    if (response.data && response.data.length) {
                        resolve(filterAutoCompletedPatientList(response.data));
                    }
                    resolve([]);
                },function () {
                    reject([]);
                });
            })
        }

        function filterAutoCompletedPatientList(data){
            var patients = _.compact(_.map(data, function(obj) {
                if (obj.patient){
                    return {
                        id: obj.id,
                        name: obj.patient.firstName + ' ' + obj.patient.lastName + " (" + obj.patient.patientId + ")"
                    }
                }
                return null;
            }));
            return patients;
        }

        function sort() {
            var  result = [vm.predicate + ',desc'];
            return result;
        }

        function showPatientData($item) {
            $state.go('frp-mde-detail', {'id': $item.id});
        }

        function addEnrollSuccess() {
            $scope.$broadcast(ipmConstants.ADD_ENROLL_SUCCESS);
        }

        function tabSelect(index) {
            vm.activeTabIndex = index;
            transitionTo();
        }

        function transitionTo() {
            $state.transitionTo($state.$current, {
                tab: vm.activeTabIndex,
            }, {notify: false});
        }

        vm.openPatientsDialog = function () {
            $uibModal.open({
                templateUrl: 'app/frp-mde/add-enroll-patients-dialog.html',
                controller: 'addEnrollPatientsController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    frpPrograms: function () {
                        return supportedDataList.frpProgramsList;
                    },
                    selectedFrpProgram: function () {
                        return vm.frpProgramId
                    },
                    providers: function () {
                        return supportedDataList.providerList;
                    },
                    mdeOffices: ['MdeOfficeService', function (MdeOfficeService) {
                        return MdeOfficeService.findAll();
                    }]
                }
            }).result.then(function () {
                addEnrollSuccess();
            }, function () {
                // $state.go('^');
            });
        }

        vm.programChange = function() {
            $rootScope.$broadcast("CHANGE_PROGRAM_FRP_MDE_DROPDOWN", vm.frpProgramId);
            ClearLocalStorageService.setFRPProgram(vm.frpProgramId);
            setIsView();
        }

        $scope.$on(ipmConstants.EVT_LOG_OUT, function () {
            ClearLocalStorageService.clearFRPProgram();
            ClearLocalStorageService.clearFRPProgramsError();
        });

    }
})();
