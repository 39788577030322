(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ConfirmPasswordDialogController', ConfirmPasswordDialogController);

    ConfirmPasswordDialogController.$inject = ['$scope', '$uibModalInstance', '$q', '$state', 'isTranslate', 'message', 'title', 'frpMdeService'];

    function ConfirmPasswordDialogController($scope, $uibModalInstance, $q, $state, isTranslate, message, title, frpMdeService) {
        var vm = this;
        vm.message = message;
        vm.password = null;
        vm.inCorrectPassword = false;
        vm.title = title;
        vm.isTranslate = isTranslate ? isTranslate : false;

        function continuesDelete() {
                $uibModalInstance.close(vm.password);
                $uibModalInstance.dismiss('cancel');
        };

        vm.no = function () {
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDeleteReport = function() {
            if (vm.password) {
                frpMdeService.confirmPassword(vm.password).then(function (response) {
                   if(response.data) {
                        continuesDelete();
                   } else {
                        vm.inCorrectPassword = true;
                   }
                });
            }
        }
    }

})();
