(function () {
    'use strict';

    AdHocTestingController.$inject = ['$scope', 'HAQBatteryAdHocService', '$window', 'PatientService', 'ipmConstants', '$state'];

    function AdHocTestingController($scope, HAQBatteryAdHocService, $window, PatientService, constants, $state) {
        var vm = this; //jshint ignore:line

        vm.haqBatteryAdHocs = [];
        vm.pageSize = 7;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'id';
        vm.loadAll = loadAll;

        init();

        function init() {
            vm.isManager = vm.isManager ? vm.isManager : false;
            vm.pageableHaq = {
                page: 1,
                size: vm.pageSize,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            HAQBatteryAdHocService.findAllByPatientId(vm.patientId, pageable).then(function (response) {
                if (response) {
                    vm.haqBatteryAdHocs = response.data;
                    vm.pageableHaq.totalItems = response.totalItems;
                    vm.isLoading = false;
                }
            });
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function lunchHAQPatientAutoLogin(haqBatteryAdHoc) {
            if (!haqBatteryAdHoc.patientId || !haqBatteryAdHoc.surveyResult || !haqBatteryAdHoc.surveyResult.id) {
                return;
            }
            PatientService.getPatientByIdAndAutoLogin(haqBatteryAdHoc.patientId, haqBatteryAdHoc.surveyResult.id).then(function (res) {
                $window.localStorage.setItem(constants.SURVEY_RESULT_ID, haqBatteryAdHoc.surveyResult.id);
                $window.localStorage.setItem(constants.PASS_RESTRICTION, true);
                $window.localStorage.setItem(constants.LAUNCH_FROM, constants.HAQ_ADHOC);
                $state.go('home');
            });
        }

        vm.loadPage = function (page) {
            vm.page = page;
            vm.loadAll();
        };

        vm.getIndex = function (index) {
            var result = (index + 1) + (vm.page - 1) * vm.pageSize;
            return result;
        };

        vm.goToHAQBattery = function ($event, haqBatteryAdHoc) {
            $event.preventDefault();
            lunchHAQPatientAutoLogin(haqBatteryAdHoc);
        };

    }

    angular.module('ipmApp').component('adHocTestingList', {
        bindings: {
            patientId: '<',
            isManager: '<',
            isBiller: '<'
        },
        templateUrl: 'app/patient/adhoc-testing/adhoc-testing.html',
        controller: AdHocTestingController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                $translatePartialLoader.addPart('haqBatteryAdHoc');
                return $translate.refresh();
            }]
        }
    });
})();
