(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ProgressBarController', ProgressBarController);

    ProgressBarController.$inject = ['$scope', '$state'];

    function ProgressBarController($scope, $state) {
        var vm = this;

        vm.widthStyle = function() {
            if (vm.percent > 0) {
                return { width: vm.percent + '%' };
            } else {
                return { width: vm.percent + '%', color: 'transparent' };
            }
        }
    }

    angular.module('ipmApp').component('progressBar', {
        bindings: {
            percent: '<',
        },
        templateUrl: 'app/agreements-workflow/partial/progress-bar.html',
        controller: ProgressBarController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
