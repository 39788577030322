(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CarePlanReviewDialogController', CarePlanReviewDialogController);

    CarePlanReviewDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'FormatUtilsService', 'CarePlanReviewService'];

    function CarePlanReviewDialogController ($scope, $uibModalInstance, entity, FormatUtilsService, CarePlanReviewService ) {
        var vm = this;
        vm.carePlanReview = entity;
        vm.timeSpentMinute = 0;
        init();

        function init() {
            if (vm.carePlanReview && vm.carePlanReview.timeSpent) {
                vm.timeSpentMinute = FormatUtilsService.formatMinuteBySeconds(vm.carePlanReview.timeSpent);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        };

        function onSaveError() {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.carePlanReview && vm.carePlanReview.id && vm.timeSpentMinute >= 0) {
                vm.carePlanReview.timeSpent = FormatUtilsService.formatMinuteToSecond(vm.timeSpentMinute);
                CarePlanReviewService.updateTimeSpent(vm.carePlanReview).then(function (res) {
                    onSaveSuccess(res);
                }, function error(e) {
                    onSaveError();
                });
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
