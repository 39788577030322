(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('SurveyWorkflowService', SurveyWorkflowService);

    SurveyWorkflowService.$inject = ['$http', 'halClient', 'ShowUnexpectedErrorDialogService', '$rootScope', 'ClearLocalStorageService', '$window'];

    function SurveyWorkflowService ($http, halClient, ShowUnexpectedErrorDialogService, $rootScope, ClearLocalStorageService, $window) {
        var baseURL = 'api';

    	var service = {
    		'getBatteryTest': getBatteryTest,
    		'saveSurveyResponse': saveSurveyResponse,
    		'complete': complete,
    		'next': next,
    		'sendSMS': sendSMS,
            'getAllBatteryTests': getAllBatteryTests,
            'getCurrentSurveyResultId': getCurrentSurveyResultId,
            'saveComment': saveComment,
            'getSuggestCourse': getSuggestCourse,
            'courseRegisterConfirmed': courseRegisterConfirmed,
            'coursesRegistration': coursesRegistration,
            'registerCourses' : registerCourses,
            'getFirstTimeOfClassBySkipClassSessionId': getFirstTimeOfClassBySkipClassSessionId,
            'checkIsAdHocHAQ': checkIsAdHocHAQ,
        };

        function getBatteryTest(id, direction) {
            var surveyResultId = getSurvetResultIdFromLocalStorage();

    		var url = baseURL + '/survey-workflow/battery-test/'+ id + '/' + surveyResultId + "?direction="+direction;
            return halClient.$get(url)
            .then(function successCallback(response) {
                return response;
              }, function errorCallback(response) {
                ClearLocalStorageService.clearWorkflow();
                ShowUnexpectedErrorDialogService.open(response, 'getBatteryTest', null)
                    .result.then(function (result) {
                    $rootScope.$emit('ipmApp.httpError', response);
                });
                return response;
            });
    	};

        function saveSurveyResponse(batteryTestId, batteryTest) {
            var surveyResultId = getSurvetResultIdFromLocalStorage();
    		var config = {
                method: 'POST',
                url: baseURL + '/survey-workflow/' + batteryTestId + '/' + surveyResultId + '/save-response',
                data: batteryTest
            };
            return $http(config)
            .then(function successCallback(response) {
                return response;
              }, function errorCallback(response) {
                ClearLocalStorageService.clearWorkflow();
                ShowUnexpectedErrorDialogService.open(response, 'saveSurveyResponse', null)
                    .result.then(function (result) {
                    $rootScope.$emit('ipmApp.httpError', response);
                });
                return response;
            });
        }

        function next(batteryTestId, batteryTest, isLastQuestion, currentQuestionObj) {
            var surveyResultId = getSurvetResultIdFromLocalStorage();
            var config = {
                method: 'POST',
                url: baseURL + '/survey-workflow/' + batteryTestId + '/' + surveyResultId +'/next?isLastQuestion='+isLastQuestion,
                data: batteryTest
            };
            return $http(config)
            .then(function successCallback(response) {
                return response;
              }, function errorCallback(response) {
                ClearLocalStorageService.clearWorkflow();
                ShowUnexpectedErrorDialogService.open(response, 'next', currentQuestionObj)
                    .result.then(function (result) {
                    $rootScope.$emit('ipmApp.httpError', response);
                });
                return response;
            });
        }

        function complete(batteryId) {
        	var url = baseURL + '/survey-workflow/'+batteryId+'/complete';
    		return $http.get(url).then(function (resp) {
    			return resp;
    		});
        }

        function sendSMS(batteryId) {
        	var url = baseURL + '/survey-workflow/' + batteryId + '/send-sms';
    		return $http.get(url).then(function (resp) {
    			return resp;
    		});
        }

        function getAllBatteryTests(batteryId, batteryTestId) {
            var url = baseURL + '/survey-workflow/' + batteryId + '/get-all-battery-tests?batteryTestId=' + batteryTestId;
            return $http.get(url).then(function (resp) {
                return resp;
            });
        }

        function getCurrentSurveyResultId() {
            var url = baseURL + '/survey-workflow/get-current-survey-result';
            return $http.get(url).then(function (resp) {
                return resp.data;
            });
        };

        function saveComment(id, comment) {
            return $http.post(baseURL + '/survey-workflow/' + id +'/save-comment', comment).then(function (resp) {
                return resp;
            });
        }

        function getSurvetResultIdFromLocalStorage() {
            var surveyResultId = $window.localStorage.getItem("surveyResultId"); // fix issue get wrong HAQ when get BatteryTest [IPMproject-374] - [Production Hotfix October 20, 2021] - Patch request - Issue w/ HAQ Tests on some patients
            if (!surveyResultId) {
                surveyResultId = 0;
            }
            return surveyResultId;
        }

        function getSuggestCourse() {
            var url = baseURL + '/survey-workflow/get-suggest-course';
            return $http.get(url).then(function (resp) {
                return resp;
            });
        }

        function courseRegisterConfirmed() {
            var url = baseURL + '/survey-workflow/course_register_confirmed';
            return $http.post(url).then(function (resp) {
                return resp;
            });
        }

        function coursesRegistration(suggestedCourses) {
            var config = {
                method: 'POST',
                url: baseURL + '/survey-workflow/course_registration',
                data: suggestedCourses
            };
            return $http(config) .then(function successCallback(response) {
                return response;
            }, function errorCallback(response) {
                return response;
            });
        }

        function registerCourses(patientCourseRegistration) {
            var config = {
                method: 'POST',
                url: baseURL + '/survey-workflow/register_courses',
                data: patientCourseRegistration
            };
            return $http(config) .then(function successCallback(response) {
                return response;
            }, function errorCallback(response) {
                return response;
            });
        }

        function getFirstTimeOfClassBySkipClassSessionId(classSessionId, patientCourseRegistration) {
            var config = {
                method: 'POST',
                url: baseURL + '/survey-workflow/register_courses/class-sessions/skip/' + classSessionId,
                data: patientCourseRegistration
            };
            return $http(config) .then(function successCallback(response) {
                return response;
            }, function errorCallback(response) {
                return response;
            });
        }

        function checkIsAdHocHAQ() {
            var surveyResultId = getSurvetResultIdFromLocalStorage();
            var url = baseURL + '/survey-workflow/check-adhoc-haq/' + surveyResultId;
            return $http.get(url).then(function (resp) {
                return resp ? JSON.parse(resp.data)  : false;
            });
        }

    	return service;
    };
})();
