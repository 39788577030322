(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('FrpReportService', FrpReportService);

    FrpReportService.$inject = ['$http', 'halClient', '$q', 'DownloadBlobService'];

    function FrpReportService ($http, halClient, $q, DownloadBlobService) {
        var baseURL = 'api/frp-report/';
        var prefixApiAdmin = '/office';

        var service = {
            'get': get,
            'createFrpReport': createFrpReport,
            'savedAttendanceTracker': savedAttendanceTracker,
            'getFrpConclusionReport': getFrpConclusionReport,
            'saveFrpConclusionReport': saveFrpConclusionReport,
            'getFrpPtReport': getFrpPtReport,
            'saveFrpPtReport': saveFrpPtReport,
            'getFRPReportPsych': getFRPReportPsych,
            'saveFRPReportPsych': saveFRPReportPsych,
            'getAdminReport': getAdminReport,
            'saveAdminReport': saveAdminReport,
            'getFrpMedicalReport': getFrpMedicalReport,
            'saveFrpMedicalReport': saveFrpMedicalReport,
            'findById': findById,
            'updateStatusFRP': updateStatusFRP,
            'finalizeFrpReport': finalizeFrpReport,
            'downloadFrpReport': downloadFrpReport,
            'loadPdfReportPreviewData': loadPdfReportPreviewData,
            'saveFRPReportAddendum': saveFRPReportAddendum,
            'isPatientActivated': isPatientActivated
        };

        function get(id){
            return $http.get(baseURL + id).then( function(response) {
                return response;
            })
        }

        function createFrpReport(frp) {
            var url = baseURL + 'create-frp-report';
            return $http.post(url, frp).then(function (resp) {
                return resp.data;
            });
        }

        function savedAttendanceTracker(frp) {
            var url = baseURL + 'save-attendance-tracker';
            return $http.post(url, frp).then(function (resp) {
                return resp.data;
            });
        }

        function updateStatusFRP(frp) {
            var url = baseURL + 'update-status-frp';
            return $http.post(url, frp).then(function (resp) {
                return resp.data;
            });
        }



        function getFrpConclusionReport(frpReportId) {
            return $http.get(baseURL + frpReportId + '/conclusion').then(function (response) {
                return response.data;
            })
        }

        function saveFrpConclusionReport(frpReportId, data){
            return $http.post(baseURL + frpReportId + '/conclusion', data).then( function(response) {
                return response.data;
            })
        }

        function getFrpPtReport(frpReportId) {
            return $http.get(baseURL + frpReportId + '/pt').then(function (response) {
                return response.data;
            })
        }

        function saveFrpPtReport(frpReportId, data){
            return $http.post(baseURL + frpReportId + '/pt', data).then( function(response) {
                return response.data;
            })
        }

        function getFRPReportPsych(frpReportId) {
            return $http.get(baseURL + frpReportId + '/psych').then(function (response) {
                return response.data;
            })
        }

        function saveFRPReportPsych(frpReport) {
            var url = baseURL + 'save-frp-report-psych';
            return $http.post(url, frpReport).then(function (resp) {
                return resp.data;
            });
        }

        function getAdminReport(id){
            return $http.get(baseURL + id + prefixApiAdmin).then(function (response) {
                return response.data;
            })
        }

        function saveAdminReport(id, data){
            return $http.put(baseURL + id + prefixApiAdmin, data).then(function (response) {
                return response.data;
            })
        }

        function getFrpMedicalReport(frpReportId) {
            return $http.get(baseURL + frpReportId + '/medical').then(function (response) {
                return response.data;
            })
        }

        function saveFrpMedicalReport(frpReportId, data) {
            return $http.post(baseURL + frpReportId + '/medical', data).then( function(response) {
                return response.data;
            })
        }

        function saveFRPReportAddendum(frpReport) {
            var url = baseURL + 'save-frp-report-addendum';
            return $http.post(url, frpReport).then(function (response) {
                return response.data;
            })
        }

        function findById(id) {
            var deferred = $q.defer();
            var url = baseURL + "find-by-id/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function finalizeFrpReport(frpReportId, signature) {
            var url = baseURL + 'finalize-frp-report/' + frpReportId;
            return $http.put(url, signature).then(function (response) {
                return response.data;
            })
        }

        function downloadFrpReport(frpReportId) {
            var deferred = $q.defer();
            var url = baseURL + 'download-frp-report/' + frpReportId;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentDisposition = headers('content-disposition');
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    var fileName = DownloadBlobService.getFileName(contentDisposition);
                    saveAs(blob, fileName);
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function loadPdfReportPreviewData(frpReportId) {
            var deferred = $q.defer();
            var url = baseURL + 'preview-frp-report/' + frpReportId;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    deferred.resolve(data);
                }
            });
            return deferred.promise;
        }

        function isPatientActivated(id) {
            var deferred = $q.defer();
            var url = baseURL + "is-patient-activated/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };
        return service;
    };
})();
