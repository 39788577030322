(function () {
    'use strict';

    InternalReviewController.$inject = ['$scope'];

    function InternalReviewController($scope) {
        var vm = this;

        init();

        function init() {
            vm.cpr.evaluatedForMDE = vm.cpr ? vm.cpr.evaluatedForMDE : false;
            vm.cpr.evaluatedForPS = vm.cpr ? vm.cpr.evaluatedForPS : false;
        }
    }

    angular.module('ipmApp').component('internalReview', {
        bindings: {
            cpr: '='
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/Internal-review/internal-review.html',
        controller: InternalReviewController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    });
})();
