'use strict';

angular.module('ipmApp')
    .directive('minimumValue', function () {
        return {
            restrict: "A",
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                // var value = element.val();
                modelCtrl.$validators.mininumWords = function(modelValue) {
                    if (attrs.minimumValue !== undefined && attrs.minimumValue !== null) {
                        modelValue = modelValue ? modelValue : null;
                        // remove some HTML tags
                        var strippedHtml = modelValue ? modelValue.toString().replace(/<[^>]+>|&nbsp;/g, '').trim() : null;
                        var words  = strippedHtml ? strippedHtml.split(/\s+/g) : null;
                        if (words && words.length < Number(attrs.minimumValue)) {
                            modelCtrl.$setValidity('minimumValue', false);
                            return modelValue;
                        } else {
                            modelCtrl.$setValidity('minimumValue', true);
                            return modelValue;
                        }
                    } else {
                        modelCtrl.$setValidity('minimumValue', false);
                        return modelValue;
                    }
                };
            }
        };
    });
