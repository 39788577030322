(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('QHPMedicationService', QHPMedicationService);

        QHPMedicationService.$inject = ['$resource', '$http'];

    function QHPMedicationService($resource, $http) {
        var resourceUrl = 'api/qhp-medication';
        var service = $resource(resourceUrl, {}, {});
        service.searchByName = searchByName;

        function searchByName(name, excludeIds, type) {
            return $http.get(resourceUrl + '/search-by-name', { params: { name: name, excludeIds: excludeIds, type: type }}).then(function (response) {
                return response.data;
            });
        };

        return service;

    }
})();
