(function() {
    'use strict';

    ObjectiveSelectAllController.$inject = [];

    function ObjectiveSelectAllController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {

        }

        vm.onClick = function() {
            vm.item.childs.forEach(function (child) {
                child.checked = vm.item.checked;
                if(child.checked) {
                    clearValue(child);
                }
            })

            vm.others.forEach(function (other){
                if(other.typeReportId === vm.item.typeReportId) {
                    other.checked = vm.item.checked;
                }
            })
        }

        function clearValue(child) {
            // clear value
            child.value = null;
            child.leftValue = null;
            child.rightValue = null;
        }
    }

    angular.module('ipmApp').component('objectiveSelectAll', {
        bindings: {
            item: '<',
            others: '<',
            isDisabled: '<',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/objective-select-all.html',
        controller: ObjectiveSelectAllController,
        controllerAs: 'vm'
    })
})();
