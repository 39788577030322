(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('notification-history', {
            parent: 'app',
            url: '/notification-history/{patientId}',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_ADMIN'],
                pageTitle: 'ipmApp.notificationHistory.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/notification-history/notification-history-list.html',
                    controller: 'NotificationHistoryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('patient');
                    $translatePartialLoader.addPart('surveyResult');
                    $translatePartialLoader.addPart('notification-history');
                    return $translate.refresh();
                }]
            },
            params: {}
        });
    }

})();
