(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionOptionDeleteController',QuestionOptionDeleteController);

    QuestionOptionDeleteController.$inject = ['$uibModalInstance', 'entity', 'QuestionOption'];

    function QuestionOptionDeleteController($uibModalInstance, entity, QuestionOption) {
        var vm = this;
        vm.questionOption = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            QuestionOption.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
