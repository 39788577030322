(function() {
    'use strict';


    checkBoxVController.$inject = [];

    function checkBoxVController() {
        var vm = this; //jshint ignore:line
        vm.typeUnCheck = 0; // 0 = case check null
        vm.typeCheckV = 1; // 1 = case check v
        vm.typeCheckX = 2; // 2 = case check X
        init();

        function init() {
            vm.isView = vm.isView ? true : false;
        }

        vm.checkboxChange = function (itemValue, itemType) {
                if (itemValue === vm.value) {
                    if (itemType === vm.typeUnCheck) {
                        vm.check = true;
                        vm.type = vm.typeCheckV;
                    } else if (itemType === vm.typeCheckV) {
                        vm.check = true;
                        vm.type = vm.typeCheckX;
                    } else if (itemType === vm.typeCheckX) {
                        vm.check = false;
                        vm.type = vm.typeUnCheck;
                    }
                }
            vm.onChange({value: vm.value, check: vm.check, type: vm.type});
        }
    }

    angular.module('ipmApp').component('checkBoxV', {
        bindings: {
            value: '=',
            check: '=',
            type: '=',
            id: '<',
            isView: '<',
            onChange: '&',
        },
        templateUrl: 'app/frp-mde/tab/attendance-tracker/check-box-v.html',
        controller: checkBoxVController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();
