(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('patientDetailController', patientDetailController);

    patientDetailController.$inject = ['$scope', '$state', '$window', 'Principal', 'ipmConstants', 'userDetails', 'frpMdeService', 'patient', 'supportedDataList', '$stateParams', '$uibModal', 'MdeReportService', 'FrpReportService', '$translate'];

    function patientDetailController($scope, $state, $window, Principal, ipmConstants, userDetails, frpMdeService, patient, supportedDataList, $stateParams, $uibModal, MdeReportService, FrpReportService, $translate) {
        var vm = this;

        vm.pageSize = 15;
        // config Page Report
        vm.predicate = 'f.startDate';
        vm.reverse = true;
        vm.page = 0;
        vm.totalItems = 0;

        // config Page Authorized
        vm.predicateAuthorized = 'id';
        vm.reverseAuthorized = true;
        vm.pageAuthorized = 0;
        vm.totalItemsAuthorized = 0;
        vm.loadAllAuthorizedHours = loadAllAuthorizedHours;

        vm.isFinish = true;
        vm.clear = clear;
        vm.save = save;
        vm.reports = {};
        vm.authorizedHours = {};
        vm.patient = {};
        vm.frpStatus = ipmConstants.FRP_STATUS;
        vm.offices = supportedDataList.officeList;
        vm.providers = supportedDataList.providerList;
        vm.frpPrograms = _.filter(supportedDataList.officeList, function(office) { return office.isFrpProgram || office.isViewFrpProgram });
        vm.mdeOffices = _.filter(supportedDataList.officeList, function(office) { return office.isMde || office.isViewMde });
        vm.isViewFrpProgram = isViewFrpProgram(patient.data.frpProgramId, vm.frpPrograms);
        vm.isViewMde = isViewMde(patient.data.mdeOfficeId, vm.mdeOffices);
        vm.editCaseNumber = false;
        vm.isDownLoading = false;
        vm.datePickerOpenStatus = {};
        vm.isDisabledStartDate = false;
        vm.isDeleting = false;
        vm.hoursAttendedCanNotExceed = ipmConstants.HOURS_ATTENDED_CAN_NOT_EXCEED; // default 160 hours
        vm.isSaving = false;
        init();

        function init() {
            patient.data.frpStartDate = patient.data.frpStartDate ? moment(patient.data.frpStartDate)._d : null;
            vm.patient = angular.copy(patient.data);
            setHoursAttendedCanNotExceed(vm.patient.sumHoursDaysAttended);
            loadAllAuthorizedHours();
            loadReport();
        }

        function loadReport() {
             var pageable = {
                page : vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.page === 0 ? vm.pageSize - 1 : vm.pageSize, //page 0 has 1 row mde report
                sort: sort()
            };

            frpMdeService.getReport($stateParams.id, pageable).then(function(response) {
                vm.reports = updateFormatTimeReport(response.data);
                vm.totalItems = response.totalItems;
                checkDisableStartDate();
            });
        }

        function setHoursAttendedCanNotExceed(sumHoursDaysAttended) {
            if (sumHoursDaysAttended && sumHoursDaysAttended > 0) {
                vm.hoursAttendedCanNotExceed = vm.hoursAttendedCanNotExceed - Number(sumHoursDaysAttended); // calc hours Attended Can Exceed
            }
        }

        function checkDisableStartDate(){
            if(vm.patient.frpStartDate) {
                vm.reports.forEach(function (report){
                    if(report.reportType ==='FRP' && report.status === 'FINALIZED') {
                        vm.isDisabledStartDate = true;
                    }
                })
            }
        }

        vm.loadAllReport = function () {
            loadReport();
        };

        vm.unChange = function (){
            return angular.equals(patient.data,  vm.patient);
        }

        vm.downloadFile = function(attachment){
            frpMdeService.downloadFile(attachment);
        }

        vm.updateEditCaseNumber = function (){
            vm.editCaseNumber = !vm.editCaseNumber;
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.uploadFile = function (files, authorizedHour) {
            var countFileValid = 0;
            var listType = ['tiff', 'docx', 'pdf'];
            files.forEach(function (element) {
                var names = element.name.split('.');
                var fileType = names[names.length - 1];
                listType.forEach(function (type) {
                    if(fileType == type) {
                        countFileValid ++;
                    }
                });
            });
            if(countFileValid != files.length){
                vm.errorDialog();
                return;
            }

            authorizedHour.files = files;
            frpMdeService.addUpdateRequestAuthorization(authorizedHour).then(function () {
                loadAllAuthorizedHours();
            });
        };

        function loadAllAuthorizedHours() {
            var pageable = {
                page: vm.pageAuthorized > 0 ? vm.pageAuthorized - 1 : vm.pageAuthorized,
                size: vm.pageSize,
                sort: sortAuthorized()
            };
            frpMdeService.getAuthorizedHours($stateParams.id, pageable).then(function(response) {
                vm.authorizedHours = response.data;
                vm.totalItemsAuthorized = response.totalItems;
                checkAddAuthorization();
            });
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function sortAuthorized() {
            return [vm.predicateAuthorized + ',' + (vm.reverseAuthorized ? 'asc' : 'desc')];
        }

         function checkAddAuthorization() {
            var isNull = false;
            if(vm.authorizedHours[0]) {
                for (var prop in vm.authorizedHours[0]) {
                    if(!vm.authorizedHours[0][prop]) {
                      isNull = true;
                    }
                }
                vm.isFinish = !isNull;
            }

        }

        vm.onChangeStartDate = function() {
            if(!vm.patient.frpStartDate || !moment(vm.patient.frpStartDate)._d) {
                vm.patient.frpStartDate = null;
            }
        }

        vm.onChangePreviouslyCompletedHours = function () {
            if (!vm.patient.previouslyCompletedHours && vm.patient.previouslyCompletedHours !==  0) {
                vm.patient.previouslyCompletedHours = null; // reset null if empty
            }
        }

        function clear(){
        	vm.patient = angular.copy(patient.data);
        	vm.editCaseNumber = false;
        }

        function save(){
            vm.isSaving = true;
        	frpMdeService.update(vm.patient).then(function(response) {
                loadReport();
                patient.data = angular.copy(vm.patient);
                vm.isSaving = false;
            });

        }

        function updateFormatTimeReport(report) {
            var isFirst = true;
            report.forEach(function(item) {
                if(isFirst) {
                    item.dateOfWeek = moment(item.dateOfWeek).format('MM/DD/YYYY') ;
                    isFirst = false;
                } else {
                    item.dateOfWeek = moment(item.dateOfWeek).format('MMM Do');
                }
            })
            return report;
        }

        vm.openReport = function (report) {
            if(report && report.reportType === 'MDE') {
                $state.go('mde-report', { id: report.id });
            } else if(report && report.reportType === 'FRP'){
                $state.go('frp-report', {id:report.id});
            }
        }

        vm.openViewReport = function(report) {
            if(report && report.status === "FINALIZED") {
                 vm.openReport(report);
            }
        }

        vm.downloadReport = function (report) {
            if (report && report.status === "FINALIZED") {
                if (report.reportType === 'MDE') {
                    downloadMDEReport(report.id);
                } else if (report.reportType === 'FRP') {
                    downloadFRPReport(report.id);
                }
            }
        }

        function downloadMDEReport(mdeReportId) {
            if (mdeReportId && !vm.isDownLoading) {
                vm.isDownLoading = true;
                MdeReportService.downloadMDEReport(mdeReportId).then(function (response) {
                    vm.isDownLoading = false;
                });
            }
        }

        function downloadFRPReport(frpReportId) {
            if (frpReportId && !vm.isDownLoading) {
                vm.isDownLoading = true;
                FrpReportService.downloadFrpReport(frpReportId).then(function (response) {
                    vm.isDownLoading = false;
                });
            }
        }

        function deleteReport(report,  password) {
            if (report) {
                vm.isDeleting = true;
                frpMdeService.deleteFRPMDEReport(report, password).then(function () {
                    vm.isDeleting = false;
                    loadReport();
                });
            }
        }

        vm.deleteReportClick = function (report) {
            vm.confirmDialogDeleteReport(report);
        }

        vm.checkDisableDeletedReport = function (report) {
            if (vm.isDeleting || (report && report.id && report.status === 'FINALIZED')) {
                return true;
            }
            return false;
        }

        vm.confirmDialogDeleteReport = function (report) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-password-dialog.html',
                controller: 'ConfirmPasswordDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('frpmde');
                        return $translate.refresh();
                    }],
                    isTranslate: function () {
                        return false
                    },
                    message: function () {
                        return $translate.instant('ipmApp.frpMde.dialogDeleteReport.confirmDelete');
                    },
                    title: function () {
                        return $translate.instant('ipmApp.frpMde.dialogDeleteReport.title');
                    },
                }
            }).result.then(function (result) {
                if (result) {
                    deleteReport(report, result);
                }
            });
        }

         vm.errorDialog = function () {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/notify-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                size: 'sm',
                resolve: {
                    message: function () {
                        var message = {
                        'content': "ipmApp.patient.authorizedHours.errorMessage",
                         };
                        return message;
                    }
                }
            })
        }

        vm.changeFrpProgram = function() {
            vm.isViewFrpProgram = isViewFrpProgram(vm.patient.frpProgramId, vm.frpPrograms);
        }

        vm.changeMdeOffice = function() {
            vm.isViewMde = isViewMde(vm.patient.mdeOfficeId, vm.mdeOffices);
        }

        function isViewFrpProgram(frpProgramId, frpPrograms) {
            return !_.isEmpty(frpPrograms) && _.some(frpPrograms, function(item) { return item.id === frpProgramId && item.isViewFrpProgram });
        }

        function isViewMde(mdeOfficeId, mdeOffices) {
            return !_.isEmpty(mdeOffices) && _.some(mdeOffices, function(item) { return item.id === mdeOfficeId && item.isViewMde });
        }

        vm.isViewFrpMde = function() {
            return vm.isViewFrpProgram || vm.isViewMde;
        }
    }
})();
