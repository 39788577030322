(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory(
            'PresentationViewPatientService',
            PresentationViewPatientService
        );

    PresentationViewPatientService.$inject = [
        'ipmConstants',
        '$state',
        '$rootScope',
    ];

    function PresentationViewPatientService(ipmConstants, $state, $rootScope) {
        var viewWindow = null,
            dispatchedEvents = {},
            onScrollEventReceive = function () {};
        var presentationViewUrl = $state.href(
            'presentation-view-patient',
            {},
            {
                absolute: true,
            }
        );
        var services = {
            getWindow: getWindow,
            openPresentationView: openPresentationView,
            listenToOtherSource: listenToOtherSource,
            changePDFdata: changePDFdata,
            changeCurrentPage: changeCurrentPage,
            zoomOut: zoomOut,
            zoomIn: zoomIn,
            sendScrollPosition: sendScrollPosition,
            addChangePageEvent: addChangePageEvent,
            addChangePDFDataEvent: addChangePDFDataEvent,
            addZoomInEvent: addZoomInEvent,
            addZoomOutEvent: addZoomOutEvent,
            addScrollEvent: addScrollEvent,
            closedPresentationView: closedPresentationView
        };
        return services;

        function openPresentationView(targetId, _viewerWidth) {
            viewWindow = window.open(
                presentationViewUrl,
                targetId,
                'width=' +
                    document.body.clientWidth +
                    ',height=' +
                    document.body.clientHeight
            );
            viewWindow.viewerWidth = _viewerWidth;
            $rootScope.$on(ipmConstants.EVT_LOG_OUT, function () {
                viewWindow.close();
            });
            $rootScope.$on('$stateChangeStart', function () {
                viewWindow.close();
            });
        }

        function closedPresentationView() {
            if (viewWindow) {
                viewWindow.close();
            }
        }

        function listenToOtherSource(_sourceUrl) {
            window.addEventListener(
                'message',
                function (event) {
                    var data = event.data;
                    if (event.origin === _sourceUrl) {
                        if (data.scrollPosition !== undefined) {
                            onScrollEventReceive(data.scrollPosition);
                            return;
                        }
                        for (var property in dispatchedEvents) {
                            if (data == property) {
                                dispatchedEvents[property]();
                                return;
                            }
                        }
                    }
                },
                false
            );
        }

        function getWindow() {
            return window;
        }
        // Call Event
        function changeCurrentPage() {
            viewWindow &&
                viewWindow.postMessage(
                    ipmConstants.EVT_GO_TO_PAGE_SLIDE_WC,
                    presentationViewUrl
                );
        }

        function changePDFdata() {
            viewWindow &&
                viewWindow.postMessage(
                    ipmConstants.EVT_CURRENT_SLIDE_WC,
                    presentationViewUrl
                );
        }

        function zoomIn() {
            viewWindow &&
                viewWindow.postMessage(
                    ipmConstants.EVT_ZOOM_IN_SLIDE_WC,
                    presentationViewUrl
                );
        }

        function zoomOut() {
            viewWindow &&
                viewWindow.postMessage(
                    ipmConstants.EVT_ZOOM_OUT_SLIDE_WC,
                    presentationViewUrl
                );
        }

        function sendScrollPosition(position) {
            viewWindow && viewWindow.postMessage(position, presentationViewUrl);
        }
        // Add Event Function
        function addEventAction(name, action) {
            if (typeof action === 'function') {
                dispatchedEvents[name] = action;
            }
        }

        function addChangePageEvent(action) {
            addEventAction(ipmConstants.EVT_GO_TO_PAGE_SLIDE_WC, action);
        }

        function addChangePDFDataEvent(action) {
            addEventAction(ipmConstants.EVT_CURRENT_SLIDE_WC, action);
        }

        function addZoomInEvent(action) {
            addEventAction(ipmConstants.EVT_ZOOM_IN_SLIDE_WC, action);
        }

        function addZoomOutEvent(action) {
            addEventAction(ipmConstants.EVT_ZOOM_OUT_SLIDE_WC, action);
        }

        function addScrollEvent(action) {
            onScrollEventReceive = action;
        }
    }
})();
