(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CarePlanReviewController', CarePlanReviewController);

    CarePlanReviewController.$inject = ['$scope', '$state', '$stateParams', 'ipmConstants', 'CarePlanReviewService', '$uibModal', 'ClearLocalStorageService', '$translate', 'Principal', 'cprAssignmentEnable', 'PatientService', '$window', 'User'];

    function CarePlanReviewController($scope, $state, $stateParams, constants, CarePlanReviewService, $uibModal, ClearLocalStorageService, $translate, Principal, cprAssignmentEnable, PatientService, $window, User) {
        var vm = this;
        vm.carePlanReviews = [];
        vm.searchQuery = '';
        vm.statuses = constants.CARE_PLAN_REVIEW_STATUS;
        vm.status = null;
        vm.page = 1;
        vm.predicate = 'completedDateNull';
        vm.reverse = true;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.search = search;
        vm.launchCPR = launchCPR;
        vm.editTimeSpent = editTimeSpent;
        vm.formatAndUpdateStatus = formatAndUpdateStatus;
        vm.addAssignment = addAssignment;
        vm.isSearching = false;
        vm.isLoading = false;
        vm.isLoadingUser = false;
        vm.cprRowTextClass = cprRowTextClass;
        vm.cprAssignmentEnable = cprAssignmentEnable;
        vm.tabAssignedPatients = 0;
        vm.tabAllPatients = 1;
        vm.tabUnassignedPatients = 2;
        vm.activeTabIndex = vm.tabAssignedPatients;
        vm.tabSelect = tabSelect;
        vm.isQHPRole = false;
        vm.patients = [];
        vm.isSaving = false;
        vm.checkCallLogAdHoc = checkCallLogAdHoc;
        vm.qhpUserAdHocId = null;

        init();

        function init() {
            User.getUserByCurrentLogin().then(function (response) {
                vm.qhpUserAdHocId = response ? response.id : null;
            });
            getAccount(false);
            vm.pageableAP = {
                page: 1,
                size: constants.PAGE_SIZE,
                totalItems: 0
            };
            var paramsInfo = $stateParams.searchCPRParamsInfo ? $stateParams.searchCPRParamsInfo : null;
            if (paramsInfo) {
                vm.status = paramsInfo.status;
                vm.searchQuery = paramsInfo.searchQuery;
                vm.pageableAP.page = paramsInfo.page;
                vm.pageableAP.totalItems = paramsInfo.totalItems;
                vm.pageableAP.size = paramsInfo.pageSize;
                vm.predicate = paramsInfo.predicate;
                vm.reverse = paramsInfo.reverse;
            }
            vm.loadAll();
            vm.isSearching = false;
        }

        function getAccount(force) {
            vm.isLoadingUser = true;
            Principal.identity(force).then(function(res) {
                vm.account = res;
                vm.isLoadingUser = false;
                vm.isQHPRole = checkIsQHPRole();
            });
        }

        function sort() {
            var  result = [vm.predicate + ',' +  (vm.reverse ? 'desc' : 'asc')];

            if (vm.predicate === 'status') {
               result.push('completed_date' + ',' + (vm.reverse ? 'asc' : 'desc')); // fix bug IPMproject-B1261
            }
            if (vm.predicate !== 'id') {
               result.push('id' + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }

        function search() {
            setSearching();
            vm.page = 0;
            vm.reverse = true;
            vm.loadAll();
        }

        function setSearching() {
            if (vm.status === 'ALL' && !vm.searchQuery) {
                vm.isSearching = false;
            } else {
                vm.isSearching = true;
            }
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };

            var statusCPR = '';
            if (vm.status && vm.status !== 'ALL') {
                statusCPR = vm.status;
            }

            if (vm.activeTabIndex === vm.tabUnassignedPatients) {
                PatientService.searchCPRPatients(vm.searchQuery, pageable).then(function (response) {
                    if (response) {
                        vm.patients = response.content;
                        vm.pageableAP.totalItems = response.totalElements;
                        vm.isLoading = false;
                    }
                });
            } else {
                CarePlanReviewService.searchCPR(vm.searchQuery, statusCPR, pageable, checkLoadAllPatients()).then(function (response) {
                    if (response) {
                        vm.carePlanReviews = response.data;
                        vm.pageableAP.totalItems = response.totalItems;
                        vm.isLoading = false;
                    }
                });
            }


        }

        function checkLoadAllPatients() {
            if (vm.activeTabIndex === vm.tabAllPatients) {
                return true;
            }
            return false;
        }
        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function addAssignment(patientId) {
            vm.isSaving = true;
            CarePlanReviewService.addAssignment(patientId).then(function (cpr) {
                if (cpr) {
                    launchCPR(cpr);
                    vm.isSaving = false;
                }
            });
        }
        function launchCPR(cpr) {
            if (cpr && !cpr.isSkippingReview && !cpr.addHocInProgress) {
                ClearLocalStorageService.clearTimeSpentCPR();
                if (cpr.status === 'NOT_READY') {
                    confirmDialog(cpr);
                    return;
                } else {
                    goToCPRWorkflow(cpr);
                }
            }
        }

        function editTimeSpent(cpr) {
            if (cpr && cpr.id && cpr.status === 'STARTED' && !cpr.addHocInProgress) {
                $state.go('care-plan-review.edit', {id: cpr.id});
            }
        }

        function goToCPRWorkflow(cpr) {
            if (cpr) {
                if (cpr.status === null || cpr.status === 'NOT_READY' || cpr.status === 'NOT_STARTED') {
                    CarePlanReviewService.startReview(cpr.id).then(function () {
                        goCPRWorkflow(cpr);
                    });
                } else {
                    goCPRWorkflow(cpr);
                }
            }
            return;
        }

        function goCPRWorkflow(cpr) {
            ClearLocalStorageService.setPatientIdForCPRWorkflow(cpr.patient.id, cpr.id);
            ClearLocalStorageService.setInitDataCPRWorkflow(false);
            $state.go('care-plan-review-workflow');
        }

        function formatAndUpdateStatus(cpr) {
            if (cpr && cpr.isSkippingReview) { // status SKIPPED
                return $translate.instant('ipmApp.carePlanReviewStatus.' + 'SKIPPED');
            } else if (cpr && cpr.status) {
                return $translate.instant('ipmApp.carePlanReviewStatus.' + cpr.status);
            } else if (cpr.completedDatePrevious) {
                var lastCompletedDateMinus = new moment().subtract(constants.DAYS_PREVIOUS_CPR_COMPLETED, 'days');
                if (lastCompletedDateMinus.isAfter(cpr.completedDatePrevious)) {
                    cpr.status = 'NOT_STARTED'; // update temp status
                    return $translate.instant('ipmApp.carePlanReviewStatus.NOT_STARTED');
                } else {
                    cpr.status = 'NOT_READY'; // update temp status
                    return $translate.instant('ipmApp.carePlanReviewStatus.NOT_READY');
                }
            } else if (!cpr.status && !cpr.completedDatePrevious) {
                cpr.status = 'NOT_STARTED'; // update temp status
                return $translate.instant('ipmApp.carePlanReviewStatus.NOT_STARTED');
            }
            return '';
        }

        function cprRowTextClass(cpr) {
            if (cpr && (cpr.status === 'NOT_READY' || cpr.isSkippingReview)) {
                return 'mask';
            }
            return cpr && cpr.isHighlight ? 'text-danger' : '';
        }

        function checkCallLogAdHoc(cpr) {
            if (vm.activeTabIndex === vm.tabAllPatients) {
                return cpr.qhpUser && cpr.qhpUser.id !== vm.qhpUserAdHocId;
            }
            return false;
        }

        vm.isAdmin = function () {
            if (vm.account && (vm.account.authorities.indexOf(constants.ROLE_ADMIN) !== -1)) {
                return true;
            }
            return false;
        };

        function checkIsQHPRole() {
            if (vm.account && (vm.account.authorities.indexOf(constants.ROLE_QHP) !== -1)) {
                return true;
            }
            return false;
        };

        vm.isShowTabAllPatients = function () {
            return vm.isQHPRole && vm.cprAssignmentEnable;
        }

        function tabSelect(index) {
            vm.activeTabIndex = index;
            loadAll();
        }

        vm.unskipReview = function(cpr) {
            $uibModal.open({
                controller: function ($uibModalInstance) {
                    var vm = this;
                    vm.message = "unskipReviewMessage";

                    vm.close = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.okClick = function () {
                        $uibModalInstance.dismiss('cancel');
                        CarePlanReviewService.unskipReview(cpr).then(function() {
                            loadAll();
                        });
                    };
                },
                templateUrl: 'app/entities/care-plan-review/partial/confirm-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        }

         function confirmDialog(cpr) {
            $uibModal.open({
                controller: function ($uibModalInstance, $state, Auth) {
                    var vm = this;
                    vm.message = "startReviewMessage";

                    vm.close = function() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.okClick = function () {
                        $uibModalInstance.dismiss('cancel');
                        goToCPRWorkflow(cpr);
                    };
                },
                templateUrl: 'app/entities/care-plan-review/partial/confirm-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        }

        vm.openNotesDialog = function (cpr) {
            if (cpr.addHocInProgress) {
                return;
            }
            $uibModal.open({
                controller: 'NotesController',
                templateUrl: 'app/entities/care-plan-review/partial/notes-dialog.html',
                controllerAs: 'vm',
                size: 'md',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    cpr: function () {
                        var notes = {
                            'id': cpr.id,
                            'notes': cpr.notes,
                         };
                        return notes;
                    }
                }
            }).result.then(function (result) {
              if (result != null) {
                  cpr.notes = result;
              }
          });
        }

        function openErrorReloadDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.qhpWorkflow.error.reloadPage.message"},
                    title: function () {return "ipmApp.qhpWorkflow.error.reloadPage.title"}
                }
            }).result.then(function() {
                $window.location.reload(); // fix reload page to break the cache
            }, function() {
            });
        }

        $scope.$on(constants.REFRESH, function () {
            openErrorReloadDialog();
        });

    }
})();
