(function() {
    "use strict";

    angular.module('ipmApp').controller('WellnessClassListController',WellnessClassListController);

    WellnessClassListController.$inject = ['$scope','WellnessClassListService','paginationConstants', '$state'];

    function WellnessClassListController($scope, WellnessClassListService, paginationConstants, $state) {
        var vm = this;

        vm.classSessions = [];
        vm.totalItems = 0;
        vm.pageSize = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.predicate = 'classStart';
        vm.reverse = true;
        vm.loadAll = loadAll;
        vm.isLoading = false;
        init();
        function init() {
            vm.loadAll();
        }

        function loadAll() {
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            WellnessClassListService.getUpComingSessionsByInstructorId(pageable, 2).then(function (response) {
                var headers = response.headers;
                vm.totalItems = headers('X-Total-Count');
                vm.classSessions = response && response.data ? response.data : [];
                vm.isLoading = false;
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            return result;
        }

        vm.openControlsInterface = function (id) {
            if (id) {
                $state.go('presentation-controls-instructor', {csId: id});
            }
        };

        vm.checkDisabledLaunchClass = function (classSession) {
            if (classSession) {
                if ((classSession.instructorPresentationStatus
                    && classSession.instructorPresentationStatus === 'FINAL')
                    || classSession.numOfRegistered < 1) {
                    return true;
                }
                return false;
            }
        };
    }

})();
