(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('CarePlanReviewService', CarePlanReviewService);

    CarePlanReviewService.$inject = ['$resource', '$http', '$q', 'DownloadBlobService'];

    function CarePlanReviewService ($resource, $http, $q, DownloadBlobService) {
        var resourceUrl = "api/care-plan-review";
        var service = $resource(resourceUrl, {}, {});
        service.searchCPR = searchCPR;
        service.addAssignment = addAssignment;
        service.downloadCarePlanReview = downloadCarePlanReview;
        service.getHighlightDays = getHighlightDays;
        service.findById = findById;
        service.updateTimeSpent = updateTimeSpent;
        service.updateNotes = updateNotes;
        service.unskipReview = unskipReview;
        service.getConfig = getConfig;
        service.findAllByPatientId = findAllByPatientId;
        service.startReview = startReview;

        function findAllByPatientId(patientId, pageable) {
            var deferred = $q.defer();
            var url = resourceUrl + '/find-all-by-patient';
            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function searchCPR(searchQuery, status, pageable, isLoadAllPatients) {
            var deferred = $q.defer();
            var url = resourceUrl + '/search';
            $http.get(url, {
                params: {
                    query: ((searchQuery != null) ? encodeURIComponent(searchQuery) : null),
                    status: status,
                    isLoadAllPatients: isLoadAllPatients,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function addAssignment(patientId) {
            var url = resourceUrl + "/add-assignment";
            return $http.post(url, { "patientId": patientId }).then(function(response) {
                return response.data;
            });
        }

        function downloadCarePlanReview(fromDate, toDate) {
            var deferred = $q.defer();
            var url = resourceUrl + '/download-care-plan-review';
            $http({
                url: url,
                method: "GET",
                params: {fromDate: fromDate, toDate: toDate},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function getHighlightDays() {
            return $http.get(resourceUrl + '/get-highlight-days').then(function (response) {
                return response.data;
            });
        };

        function updateNotes(notes) {
            var url = resourceUrl + "/update-notes";
            return $http.post(url,notes).then(function(response) {
                    return response.data;
            });
        }

        function unskipReview(cpr) {
            var url = resourceUrl + "/unskip-review";
            return $http.post(url,cpr).then(function(response) {
                    return response.data;
            });
        }

        function findById(id) {
            var deferred = $q.defer();
            var url = resourceUrl + "/find-by-id/" + id;
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function updateTimeSpent(carePlanReview) {
            var url = resourceUrl + "/update-time-spent";
            return $http.post(url, carePlanReview).then(function (response) {
                return response;
            });
        }

        function getConfig() {
            var deferred = $q.defer();
            var url = resourceUrl + "/get-config";
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        function startReview(cprId) {
            var url = resourceUrl + "/start-review/" + cprId;
            return $http.post(url, {}).then(function(response) {
                return response.data;
            });
        }

        return service;
    };
})();
