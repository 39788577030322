(function() {
    'use strict';


    PatientResponseSummaryController.$inject = ['$scope', '$state', '$window', 'ipmConstants', 'PatientPresentationService'];

    function PatientResponseSummaryController($scope, $state, $window, constants, PatientPresentationService) {
        var vm = this;
        vm.currentSection = 2;
        var submitDebounced = _.debounce(submitResponse, 300);

        init();

        function init() {
        }

        function submitResponse() {
            PatientPresentationService.submitResponse(vm.patientPresentation).then(function(res) {
                if (res) {
                    vm.patientPresentation = res;
                    changePatientPresentation();
                }
            });
        }

        function changePatientPresentation() {
            vm.onChange({patientPresentation: vm.patientPresentation});
            vm.isChangePatientPresentation = !vm.isChangePatientPresentation;
        }

        $scope.$watch('vm.patientPresentation', function(nval, oval) {
            if (nval === null || angular.equals(nval, oval) || vm.isSavingDrapChange) {
                return;
            }
            vm.patientPresentation.patientPresentationResponses.forEach(function(patientPresentationResponse) {
                if(patientPresentationResponse.slide.type === 'QUESTION') {
                    if(patientPresentationResponse.slide.question.type === 'MULTI_SELECT') {
                        var selected = [];
                        var keys = Object.keys(patientPresentationResponse.multiResponse);
                        for (var i = 0; i < keys.length; i++) {
                            if(patientPresentationResponse.multiResponse[keys[i]]) {
                                selected.push(parseInt(keys[i]));
                            }
                        }
                        patientPresentationResponse.response = JSON.stringify(selected);
                    }
                }
            });
            submitDebounced();
        }, true);
    }

    angular.module('ipmApp').component('patientResponseSummary', {
        bindings: {
            deck: '=',
            patientPresentation: '=',
            accordionStatus: '=',
            isPatientOpen: '=',
            isChangePatientPresentation: '=',
            slides: '<',
            isSavingDrapChange: '<',
            onChange: '&'
        },
        templateUrl: 'app/presentation-controls-instructor/presentation-patient-response/patient-response-summary/patient-response-summary.html',
        controller: PatientResponseSummaryController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
