(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Deck', Deck);

    Deck.$inject = ['$resource', '$q', 'halClient', '$http'];

    function Deck ($resource, $q, halClient, $http) {
        var baseUrl = 'api/decks';
        var resourceUrl =  'api/decks/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function(data) { // data la Deck
                    var formData = new FormData();
                    if(data.id)
                    	formData.append('id', data.id);
                    formData.append('name', data.name);
                    var index = 0;
                    data.slides.forEach(function(slide) {
                    	formData.append('slides['+index+'].number', index + 1);
                        formData.append('slides['+index+'].name', slide.name);

                        if(slide.speakerNotes)
                        	formData.append('slides['+index+'].speakerNotes', slide.speakerNotes);
                        if(slide.id)
                        	formData.append('slides['+index+'].id', slide.id)
                        if(slide.type)
                        	formData.append('slides['+index+'].type', slide.type);

                        if(slide.type === "QUESTION") {
                        	if(slide.questionRepeatType)
                            	formData.append('slides['+index+'].questionRepeatType', slide.questionRepeatType);
                        	 if(slide.question)
                             	formData.append('slides['+index+'].question.id', slide.question.id);
                        }

                        if(slide.type === "PRESENTATION") {
                        	 if(slide.file)
                                 formData.append('slides['+index+'].multFile', slide.file);
                        	 if(slide.fileId)
                             	formData.append('slides['+index+'].fileId', slide.fileId);
                        }



                        index++;
                    });
                    //formData.append('slide1s[]', data.slides);
                    return formData;
                },
                headers: { 'Content-Type': undefined },
                transformResponse: function(result) {
                    return result;
                }
            }
        });

        service.findAllByActive = findAllByActive;

        function findAllByActive() {
            var deferred = $q.defer();

            var url = baseUrl + '/find-all-by-active';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        service.downloadSlideFile = downloadSlideFile;

        function downloadSlideFile(id) {
            var deferred = $q.defer();
            var url = 'api/decks/slides/' + id + "/download";
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    saveAs(blob, headers('fileName'));
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);

            });
            return deferred.promise;
        };

        service.downloadSlideBlobFile = downloadSlideBlobFile;

        function downloadSlideBlobFile(id) {
            var deferred = $q.defer();
            var url = 'api/decks/slides/' + id + "/download-pdf";
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/pdf',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    deferred.resolve(data);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);

            });
            return deferred.promise;
        };
        return service;
    }
})();
