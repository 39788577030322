(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('personalInjuryComponent', personalInjuryComponent);

        personalInjuryComponent.$inject = [];

    function personalInjuryComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectivePersonalInjuryController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/personal-injury.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectivePersonalInjuryController.$inject = ['$scope', '$stateParams', '$element', '$timeout', 'ipmConstants'];
    function DirectivePersonalInjuryController($scope, $stateParams, $element, $timeout, constants) {
        var vm = this;
        
        vm.datePickerOpenStatus = {
            dateOfAccident: false,
            medicalSoughtDate: false,
        };
        
        vm.datePickerOptions = {
            maxDate: new Date()
        };
        
        function init() {
        }

        init();
    }
})();
