(function() {
    'use strict';
	var module = angular.module('ipmApp')

	module.directive('typeaheadShowOnFocus', function () {
        return {
            require: 'ngModel',
            link: function ($scope, element, attrs, ngModel) {
                element.bind('focus', function () {
                    ngModel.$setViewValue();
                    $(element).trigger('input');
                    $(element).trigger('change');
                });
            }
        };
    });

})();
