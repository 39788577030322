(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadResultController', DownloadResultController);

        DownloadResultController.$inject = ['$state', '$filter', '$stateParams', '$window', 'NPQQuestionnaireService', 'DateUtils'];

    function DownloadResultController ($state, $filter, $stateParams, $window, NPQQuestionnaireService, DateUtils) {
        var vm = this;
        vm.downloadResult = downloadResult;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;
        vm.inValidDates = false;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        
        init();
        
        function init() {            

            // set to Monday of this week
        	vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
        	// set to previous Monday
        	vm.fromDate.setDate(vm.fromDate.getDate() - 7);
        	
        	// set to previous Friday
        	vm.toDate = new Date(vm.fromDate);
        	vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        function downloadResult() {
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            vm.noPdf = false;
            NPQQuestionnaireService.downloadQuestionnaire(fromDate, toDate).then(function(res) {
                vm.isProcessing = false;
                if(res === 204) {
                    vm.noPdf = true;
                }
            });
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };
    }
})();
