(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ThankYouController', ThankYouController);

    ThankYouController.$inject = ['$scope', '$state', '$uibModal', '$translate', '$translatePartialLoader', '$rootScope', '$sessionStorage', 'SurveyWorkflowService', 'Auth', 'ClearLocalStorageService', '$localStorage', '$window', 'ipmConstants', 'isAdHoc', 'haqBatteryAdHoc'];

    function ThankYouController($scope, $state, $uibModal, $translate, $translatePartialLoader, $rootScope, $sessionStorage, SurveyWorkflowService, Auth, ClearLocalStorageService, $localStorage, $window, constants, isAdHoc, haqBatteryAdHoc) {
        var vm = this;
        vm.comment = null;
        vm.currentSurveyResultId = $sessionStorage.currentSurveyResultId;
        vm.isAdHoc = isAdHoc ? isAdHoc : false;
        vm.haqBatteryAdHoc = haqBatteryAdHoc ? haqBatteryAdHoc : null;
        vm.hasNextAdHoc = false;
        $translatePartialLoader.addPart('surveyWorkflow');
        $translate.refresh();
        vm.showSkypeDialog = function() {
        	$uibModal.open({
                controller: function ($uibModalInstance) {
                	var vm = this;
                	vm.close = function() {
                		$uibModalInstance.dismiss('cancel');
                	}
                },
                templateUrl: 'app/survey-workflow/partial/skype-confirm-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        };

        init();

        function init() {
            if (!vm.currentSurveyResultId) {
                SurveyWorkflowService.getCurrentSurveyResultId().then(function(res){
                    $sessionStorage.currentSurveyResultId = res;
                    vm.currentSurveyResultId = res;
                });
            }
            vm.hasNextAdHoc = vm.haqBatteryAdHoc && vm.haqBatteryAdHoc.batteryTestId ? true : false;
            if (!vm.hasNextAdHoc && ClearLocalStorageService.getLaunchFrom() === constants.HAQ_ADHOC) {
                ClearLocalStorageService.clearLaunchFrom();
            }
        }

        vm.back = function() {
            $rootScope.sessionTimeOut = null;
            vm.currentSurveyResultId = null;
            $sessionStorage.currentSurveyResultId = null;
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            ClearLocalStorageService.clearAll();
            Auth.logout();
            $state.go('home');
        }

        vm.goToLandingPage = function() {
            vm.currentSurveyResultId = null;
            $sessionStorage.currentSurveyResultId = null;
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            if (getSurveyResultId()) {
                ClearLocalStorageService.setKeepLandingPage(true); // KeepLandingPage
            }
            var launchFrom = ClearLocalStorageService.getLaunchFrom();

            ClearLocalStorageService.clearAll();
            ClearLocalStorageService.clearLandingPage();

            // keep value
            if (!launchFrom || launchFrom === constants.HAQ_ADHOC) {
                ClearLocalStorageService.clearKeepLandingPage();
            } else if (launchFrom === constants.HAQ) {
                ClearLocalStorageService.setLaunchFrom(launchFrom);
            }

            $state.go('landing-page');
        };

        vm.nextAdHoc = function () {
            var batteryTestId = vm.haqBatteryAdHoc.batteryTestId;
            if (batteryTestId) {
                ClearLocalStorageService.clearSurveyResultId();
                ClearLocalStorageService.setSurveyResultId(vm.haqBatteryAdHoc.surveyResult.id);
                goToSurveyWorkflow(batteryTestId);
            }
        };

        function goToSurveyWorkflow(batteryTestId) {
            $state.go('survey-workflow', {id: batteryTestId});
        }

        function getSurveyResultId() {
            return $window.localStorage.getItem(constants.SURVEY_RESULT_ID);
        }
    }
})();
