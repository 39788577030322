(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QHPLandingPageController', QHPLandingPageController);

    QHPLandingPageController.$inject = ['$scope', '$state', 'Principal', 'ipmConstants'];

    function QHPLandingPageController($scope, $state, Principal, constants) {
        var vm = this;
        vm.slides = [];
        

        init();

        function init() {
            Principal.identity(false).then(function (account) {
                vm.tasks = account.qhpUserDetail.tasks;
                initLandingPageItem();
                initLandingPageBackground();
            });
        }

        function initLandingPageItem() {
            // define common variable
            var approxTextTranslate = 'ipmApp.qhpWorkflow.landingPage.approx';

            vm.langdingPageItems = [];

            if (_.indexOf(vm.tasks, constants.CARE_PLAN_REVIEW) !== -1) {
                var CPR_ITEM = {
                    isCPR: true,
                    image: './content/images/assets_agreement.png', 
                    titleTextTranslate: 'ipmApp.qhpWorkflow.landingPage.cpr', 
                    approxTextTranslate: approxTextTranslate,
                    minApprox: 35
                }
                vm.langdingPageItems.push(CPR_ITEM);
            }
            if (_.indexOf(vm.tasks, constants.HAQ_EVALUATION) !== -1) {
                var EVALUATION_ITEM = {
                    isHAQEvaluation: true,
                    image: './content/images/assets_haq.png',
                    titleTextTranslate: 'ipmApp.qhpWorkflow.landingPage.haqEvaluation',
                    approxTextTranslate: approxTextTranslate,
                    minApprox: 35
                }
                vm.langdingPageItems.push(EVALUATION_ITEM);
            }
            if (_.indexOf(vm.tasks, constants.INSTRUCTOR) !== -1 && !constants.HIDE_WELLNESS_CLASSES) {
                var INSTRUCTOR_ITEM = {
                    image: './content/images/assets_haq_wellness_class.png',
                    titleTextTranslate: 'ipmApp.qhpWorkflow.landingPage.wellnessClasses'
                }
                vm.langdingPageItems.push(INSTRUCTOR_ITEM);
            }
            if (_.indexOf(vm.tasks, constants.FRP_MDE) !== -1) {
                var FRP_MDE = {
                    isFRP_MDE: true,
                    image: './content/images/assets_frp_mde.png',
                    titleTextTranslate: 'ipmApp.qhpWorkflow.landingPage.FRP_MDE',
                }
                vm.langdingPageItems.push(FRP_MDE);
            }
            if (_.indexOf(vm.tasks, constants.HAQ_BRIEF_REVIEW) !== -1) {
                var BRIEF_REVIEW_ITEM = {
                    isHAQBriefReview: true,
                    image: './content/images/assets_haq.png',
                    titleTextTranslate: 'ipmApp.qhpWorkflow.landingPage.haqBriefReview',
                    approxTextTranslate: approxTextTranslate,
                    minApprox: 5
                }
                vm.langdingPageItems.push(BRIEF_REVIEW_ITEM);
            }
        }

        function initLandingPageBackground() {
            var currIndex = 0;
            vm.slides.push({
                image: 'content/images/assets_bg.jpg',
                id: currIndex++
            });
        }

        vm.itemLandingPageClick = function(item) {
            if (item) {
                if(item.isCPR) {
                    $state.go('care-plan-review');
                    return;
                } else if (item.isHAQEvaluation) {
                    $state.go('haq-evaluation');
                    return;
                } else if (item.isFRP_MDE) {
                    $state.go('frp-mde');
                    return;
                } else if (item.isHAQBriefReview) {
                    $state.go('haq-brief-review');
                    return;
                } else {
                    $state.go('wellness-class-list');
                    return;
                }
            }
        }

    }
})();
