(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DeckDeleteController', DeckDeleteController);

    DeckDeleteController.$inject = ['$uibModalInstance', 'entity', 'Deck'];

    function DeckDeleteController($uibModalInstance, entity, Deck) {
        var vm = this;
        vm.deck = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Deck.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
