(function() {
    'use strict';

    OtherObjectiveRowController.$inject = [];

    function OtherObjectiveRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

        vm.change = function() {
            if (!vm.item.checked) {
                // clear valua
                vm.item.value = null;
                vm.item.leftValue = null;
                vm.item.rightValue = null;
            }
            vm.onChange();
        }

        vm.removeOtherObjective = function() {
            if (vm.isDisabled) return;
            vm.item.active = false;
            vm.onDelete({data: vm.item});
            vm.onChange();
        }
    }

    angular.module('ipmApp').component('otherObjectiveRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&',
            onDelete: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/other-objective-row.html',
        controller: OtherObjectiveRowController,
        controllerAs: 'vm'
    })
})();
