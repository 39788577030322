(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('allergiesComponent', allergiesComponent);

    allergiesComponent.$inject = [];

    function allergiesComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectiveAllergiesController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/allergies.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveAllergiesController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectiveAllergiesController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        function init() {
            
        }

        vm.optionsResponseRequired = function(pro) {
            var find = _.find(_.values(vm.workflow.answer[pro]), function(checked) {
                return checked;
            });
            return vm.workflow.answer.allergicToAnyMedications === 'YES' && !find;
        };

        init();
    }
})();
