(function () {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('frp-mde', {
			parent: 'app',
			url: '/frp-mde?tab',
			data: {
				authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
				pageTitle: ''
			},
			views: {
				'content@': {
					templateUrl: 'app/frp-mde/frp-mde.html',
					controller: 'frpMdeController',
					controllerAs: 'vm'
				}
			},
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frpmde');
                    $translatePartialLoader.addPart('frpStatus');
                    $translatePartialLoader.addPart('reportStatus');
                    $translatePartialLoader.addPart('attendanceTracker');
					$translatePartialLoader.addPart('patientFrpMdeDetail');
					$translatePartialLoader.addPart('frpReport');
                    return $translate.refresh();
                }],
                supportedDataList: ['Auth', function(Auth) {
                    return Auth.getSupportedListRegister();
                }],
                userDetails: ['User', function (User) {
                    return User.getUserByCurrentLogin();
                }],
				frpPrograms: ['Principal', 'ipmConstants', 'userDetails', 'FrpProgramService', function (Principal, ipmConstants, userDetails, FrpProgramService) {
					var frpPrograms;
                    return Principal.identity(false).then(function(res) {
						if (Principal.hasAnyAuthority([ipmConstants.ROLE_STAFF]) === true) {
							frpPrograms  = userDetails.staffUserDetail ? userDetails.staffUserDetail.frpPrograms : [];
						} else if (Principal.hasAnyAuthority([ipmConstants.ROLE_QHP]) === true) {
							frpPrograms = userDetails.qhpUserDetail ? userDetails.qhpUserDetail.frpPrograms : [];
						} else if (Principal.hasAnyAuthority([ipmConstants.ROLE_ADMIN]) === true) {
							frpPrograms = FrpProgramService.findAll();
						}
						return frpPrograms;
					});
                }]

            },
            params: {
                tab: null
            }
		})
		.state('frp-mde-detail', {
			parent: 'frp-mde',
			url: '/{id}/detail',
			data: {
				authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
				pageTitle: ''
			},
			views: {
				'content@': {
					templateUrl: 'app/frp-mde/patient-frp-mde-detail.html',
					controller: 'patientDetailController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('patientFrpMdeDetail');
					return $translate.refresh();
				}],
				patient: ['frpMdeService', '$stateParams', function(frpMdeService, $stateParams) {
	                return frpMdeService.get($stateParams.id);
	            }],
	            supportedDataList: ['Auth', function(Auth) {
                    return Auth.getSupportedListRegister();
                }],
                userDetails: ['User', function (User) {
                    return User.getUserByCurrentLogin();
                }],
				isPatientActivated: ['frpMdeService',  '$stateParams', '$state' , function (frpMdeService, $stateParams, $state) {
                    frpMdeService.isPatientActivated($stateParams.id).then(function() {});
                 }]
			}

		})
		.state('frp-mde-detail.newRequest', {
			parent: 'frp-mde-detail',
			url: '/new-request',
			data: {
				authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
				pageTitle: ''
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/frp-mde/add-request-patient-detail-dialog.html',
					controller: 'addRequestPatientDetailController',
					controllerAs: 'vm',
					backdrop: 'static',
					windowClass: 'center-modal',
					size: 'md',
					resolve: {
						translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('patientFrpMdeDetail');
							return $translate.refresh();
						}],
						entity:  function() {
                            return {id: null, patientFRPMDEId : Number($stateParams.id), hoursRequested: null, hoursAuthorized: null, dateRequested: null, dateAuthorized: null, attachments: []};
                        }
					}
				}).result.then(function() {
					$state.go('frp-mde-detail', {searchUserParamsInfo: null}, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]
		})
		.state('frp-mde-detail.editRequest', {
			parent: 'frp-mde-detail',
			url: '/{authorizedId}/edit-request',
			data: {
				authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
				pageTitle: ''
			},
			onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				$uibModal.open({
					templateUrl: 'app/frp-mde/add-request-patient-detail-dialog.html',
					controller: 'addRequestPatientDetailController',
					controllerAs: 'vm',
					backdrop: 'static',
					windowClass: 'center-modal',
					size: 'md',
					resolve: {
						translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('patientFrpMdeDetail');
							return $translate.refresh();
						}],
						entity: ['frpMdeService', function(frpMdeService) {
                            return frpMdeService.getAuthorizedHoursById($stateParams.authorizedId);
                        }]
					}
				}).result.then(function() {
					$state.go('frp-mde-detail', {searchUserParamsInfo: null}, { reload: true });
				}, function() {
					$state.go('^');
				});
			}]
		})
    }
})();
