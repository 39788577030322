(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPMDETemplateDialogController', FRPMDETemplateDialogController);

    FRPMDETemplateDialogController.$inject = ['$scope', '$stateParams', '$uibModal', '$uibModalInstance', 'FRPMDETemplateService', 'titleKey', 'template', 'DataUtils'];

    function FRPMDETemplateDialogController($scope, $stateParams, $uibModal, $uibModalInstance, FRPMDETemplateService, titleKey, template, DataUtils) {
        var vm = this;
        vm.titleKey = titleKey ? titleKey : 'createOrEditLabel';
        vm.template = template ? template : null;
        vm.name = template.name;
        vm.content = template.content;
        vm.save = save;
        vm.clear = clear;
        vm.deleteTemplate = deleteTemplate;
        vm.cancelCreatingMessage = 'ipmApp.frpMdeTemplate.confirmCancelCreating';
        vm.cancelEditingMessage = 'ipmApp.frpMdeTemplate.confirmCancelEditing';
        vm.deleteMessage = 'ipmApp.frpMdeTemplate.confirmDelete';

        init();
        function init() {
        }

        function isChanged() {
            var isEqual = true;
            // Template Name
            isEqual = DataUtils.isEqualText(vm.name, vm.template.name);
            if (!isEqual) return true;
            // Template Content
            isEqual = DataUtils.isEqualText(vm.content, vm.template.content);
            if (!isEqual) return true;
            return !isEqual;
        }

        function clear() {;
            if (isChanged()) {
                if (vm.template.id) {
                    vm.confirmDialog(vm.cancelEditingMessage, false);
                } else {
                    vm.confirmDialog(vm.cancelCreatingMessage, false);
                }
            } else {
                $uibModalInstance.dismiss('cancel'); // close modal with reason 'cancel'
            }
        }

        vm.confirmDialog = function (message, isDelete) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) { // select 'Yes'
                    if (isDelete) {
                        doDeleteTemplate();
                    } else {
                        $uibModalInstance.dismiss('cancel'); // close modal with reason 'cancel'
                    }
                }
            });
        }

        function save() {
            vm.isSaving = true;
            prepairDataForSave();
            if (vm.template.id) {
                FRPMDETemplateService.update(vm.template, onSaveSuccess, onSaveError);
            } else {
                FRPMDETemplateService.save(vm.template, onSaveSuccess, onSaveError);
            }
        }

        function deleteTemplate() {
            vm.confirmDialog(vm.deleteMessage, true);
        }

        function doDeleteTemplate()  {
            vm.isSaving = true;
            FRPMDETemplateService.delete({id: vm.template.id}, function () {
                vm.isSaving = false;
                $uibModalInstance.close('delete_template_success'); // close modal with reason 'delete_template_success'
            });
        }

        function prepairDataForSave() {
            vm.template.name = vm.name;
            vm.template.content = vm.content;
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result); // close modal with reason 'template object'
        }

        function onSaveError(error) {
            vm.isSaving = false;
        }

        $scope.$on('modal.closing', function(event, reason, closed) {
            if (isChanged() && reason && reason === 'escape key press') { // close modal with reason 'escape key'
                event.preventDefault(); // reject dialog closing process
                if (vm.template.id) {
                    vm.confirmDialog(vm.cancelEditingMessage, false);
                } else {
                    vm.confirmDialog(vm.cancelCreatingMessage, false);
                }
            }
        });
    }
})();

