(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('haq-tracks', {
            parent: 'entity',
            url: '/haq-tracks',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.haqTracks.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/haq-tracks/haq-tracks.html',
                    controller: 'HaqTracksController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('haq-tracks');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('haq-tracks.new', {
                parent: 'haq-tracks',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/haq-tracks/haq-tracks-dialog.html',
                        controller: 'HaqTracksDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    name: null,
                                    id: null,
                                    active: true,
                                    isDefault: false,
                                    code: null,
                                    tracksAssigneds: []
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('haq-tracks', null, { reload: true });
                    }, function() {
                        $state.go('haq-tracks');
                    });
                }]
            })
            .state('haq-tracks.edit', {
                parent: 'haq-tracks',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/haq-tracks/haq-tracks-dialog.html',
                        controller: 'HaqTracksDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function (HaqTracksService) {
                                return HaqTracksService.findById($stateParams.id);
                            },
                        }
                    }).result.then(function() {
                        $state.go('haq-tracks', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('haq-tracks.delete', {
                parent: 'haq-tracks',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/haq-tracks/haq-tracks-delete-dialog.html',
                        controller: 'HaqTracksDeleteController',
                        controllerAs: 'vm',
                        size: 'sm',
                        windowClass: 'absolute-center',
                        resolve: {
                            entity: ['HaqTracksService', function(HaqTracksService) {
                                return HaqTracksService.findById($stateParams.id);
                            }]
                        }
                    }).result.then(function() {
                        $state.go('haq-tracks', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
