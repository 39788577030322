(function() {
	'use strict';

	angular
	.module('ipmApp')
	.controller('DeckController', DeckController);

	DeckController.$inject = ['$scope', '$state', 'Deck', 'ParseLinks', 'pagingParams', 'paginationConstants'];

	function DeckController($scope, $state, Deck, ParseLinks, pagingParams, paginationConstants) {
			var vm = this;

			vm.currentAccount = null;
			vm.languages = null;
			vm.links = null;
			vm.loadAll = loadAll;
			vm.loadPage = loadPage;
			vm.page = 1;
			vm.predicate = 'd.id';
			vm.predicateDefault = 'd.id';
			vm.reverse = false;
			vm.pageSize = 15;
			vm.totalItems = 0;
			vm.deck = [];

			vm.clear = clear;
			vm.search = search;

			vm.loadAll();

			function loadAll () {
				Deck.query({
					searchKey: pagingParams.searchKey,
					page: pagingParams.page - 1,
					size: paginationConstants.itemsPerPage,
					sort: sort()
				}, onSuccess, onError);

				function sort() {
					var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
					if (vm.predicate !== 'name') {
						result.push('name');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					vm.decks = data;
					vm.page = pagingParams.page;
				}
				function onError(error) {
					//AlertService.error(error.data.message);
				}
			}

			function loadPage (page) {
				vm.page = page;
				vm.transition();
			}

			function transition () {
				$state.transitionTo($state.$current, {
					page: vm.page,
					sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
					searchKey: vm.currentSearch
				});
			}

			function search (searchKey) {
				if (!searchKey){
					return vm.clear();
				}
				vm.links = null;
				vm.page = 1;
				vm.predicate = 'name';
				vm.reverse = false;
				vm.currentSearch = searchKey;
				vm.transition();
			}

			function clear () {
				vm.links = null;
				vm.page = 1;
				vm.predicate = 'name';
				vm.reverse = true;
				vm.currentSearch = '';
				vm.transition();
			}
		}
	})();