(function() {
    'use strict';


    inactivePatientsController.$inject = ['$rootScope', '$scope', 'frpMdeService', 'ipmConstants'];

    function inactivePatientsController($rootScope, $scope, frpMdeService, constants) {
        var vm = this; //jshint ignore:line
        vm.page = 1;
        vm.predicate = 'us.firstName';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.totalItems = 0;
        vm.inactivePatients = [];
        vm.inactivePatientsOld = [];
        vm.inactivePatientsStatus = constants.INACTIVE_PATIENTS_STATUS;
        vm.loadAll = loadAll;
        vm.search = search;
        init();

        function init() {
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            var param = { officeId: null, query: vm.searchQuery,
                mdeStatus: filterStatusMDE(vm.status),
                frpStatus: filterStatusFRP(vm.status),
                frpProgramIds: getFrpProgramIds() };
            frpMdeService.inactivePatient(param, pageable).then(function (res) {
                vm.inactivePatientsOld = res.data;
                vm.inactivePatients = Object.assign({}, vm.inactivePatientsOld);
                vm.totalItems = res.headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
            });
        }

        function filterStatusFRP(status) {
            if (status) {
                var statusFRPInactive = ['FRP_GRADUATED', 'FRP_EARLY_DISCHARGE', 'FRP_INACTIVE_HOLD'];
                return _.filter(statusFRPInactive, function (item) {
                    return item && item === status;
                });
            }
        }


        function filterStatusMDE(status) {
            if (status) {
                var statusMDEInactive = ['MDE_COMPLETED_NOT_RECOMMENDED','MDE_ABANDONED'];
                return _.filter(statusMDEInactive, function (item) {
                    return item && item === status;
                });
            }
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        function search() {
            vm.loadAll();
        }

        function filterFrpProgramIds() {
            if (vm.frpPrograms) {
                return $filter('filter')(vm.frpPrograms).map(function (item) {
                    return item.id;
                });
            }
            return [];
        }

        function getFrpProgramIds() {
            if (vm.frpProgramId) {
                return [vm.frpProgramId];
            } else {
                return filterFrpProgramIds();
            }
        }

        vm.filterStatus = function () {
            vm.loadAll();
        }

        $scope.$on("CHANGE_PROGRAM_FRP_MDE_DROPDOWN", function(event, res) {
            vm.frpProgramId = res;
            loadAll();
        });
    }

    angular.module('ipmApp').component('inactivePatientsTab', {
        bindings: {
            frpProgramId: '<',
            frpPrograms: '<'
        },
        templateUrl: 'app/frp-mde/tab/inactive-patients/inactive-patients.html',
        controller: inactivePatientsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();
