(function() {
    'use strict';

    AgreementsNoticePatientsController.$inject = ['$scope', 'AgreementsWorkflowService'];

    function AgreementsNoticePatientsController($scope, AgreementsWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.isSaving = false;
        init();

        function init() {

        }
    }

    angular.module('ipmApp').component('agreementsNoticePatients', {
        bindings: {
            agreement: '<',
            onChange: '&'
        },
        templateUrl: 'app/agreements-workflow/agreements-notice-patients/agreements-notice-patients.html',
        controller: AgreementsNoticePatientsController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
