(function() {
    'use strict';

    reportFinalizedViewController.$inject = [];

    function reportFinalizedViewController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

        vm.reportFinalizedStyle = function (type) {
            if (type === 'GREEN_DOT') {
                return 'report bg-success';
            } else if (type === 'GREY_DOT') {
                return 'report bg-info';
            } else { //  (type === 'ORANGE_DOT')
                return 'report bg-warning';
            }
        }
    }

    angular.module('ipmApp').component('reportFinalizedView', {
        bindings: {
            reportFinalizedType: '<'
        },
        templateUrl: 'app/frp-mde/tab/report-finalized-view/report-finalized-view.html',
        controller: reportFinalizedViewController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
