(function () {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('mde-report', {
			parent: 'app',
			url: '/mde-report/{id}',
			data: {
				authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
				pageTitle: ''
			},
			views: {
				'content@': {
					templateUrl: 'app/frp-mde/mde-report/home/mde-report-home.html',
					controller: 'MDEReportHomeController',
					controllerAs: 'vm'
				}
			},
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('mdeReport');
                    $translatePartialLoader.addPart('frpmde');
                    return $translate.refresh();
                }],
                isPatientActivated: ['MdeReportService',  '$stateParams', '$state' , function (MdeReportService, $stateParams, $state) {
                    MdeReportService.isPatientActivated($stateParams.id).then(function() {});
                 }]
            }
		})
        .state('mde-report.ADMIN', {
            parent: 'mde-report',
            url: '/office',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/frp-mde/mde-report/admin/mde-report-admin.html',
                    controller: 'MDEReportAdminController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('mdeReport');
                    $translatePartialLoader.addPart('frpmde');
                    return $translate.refresh();
                }],
                 supportedDataList: ['Auth', function(Auth) {
                    return Auth.getSupportedListRegister();
                }],
                 userDetails: ['User', function (User) {
                    return User.getUserByCurrentLogin();
                }],
                qhpUsers: ['User', function (User) {
                    return User.getAllQHPUsers();
                }],
                staffUsers: ['User', function (User) {
                    return User.getAllStaffUsers();
                }],
                isView: function () {
                    return false;
                }
            }
        })
            .state('mde-report.ADMIN.VIEW', {
                parent: 'mde-report',
                url: '/office/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/admin/mde-report-admin.html',
                        controller: 'MDEReportAdminController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('mdeReport');
                        $translatePartialLoader.addPart('frpmde');
                        return $translate.refresh();
                    }],
                    supportedDataList: ['Auth', function(Auth) {
                        return Auth.getSupportedListRegister();
                    }],
                    userDetails: ['User', function (User) {
                        return User.getUserByCurrentLogin();
                    }],
                    qhpUsers: ['User', function (User) {
                        return User.getAllQHPUsers();
                    }],
                    staffUsers: ['User', function (User) {
                        return User.getAllStaffUsers();
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
        .state('mde-report.MEDICAL', {
            parent: 'mde-report',
            url: '/medical',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/frp-mde/mde-report/ptp/mde-report-ptp.html',
                    controller: 'MDEReportPTPController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frpmde');
                    $translatePartialLoader.addPart('mdeReport');
                    return $translate.refresh();
                }],
                mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                    return MdeReportService.findById($stateParams.id);
                }],
                templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                    return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_MEDIAL);
                }],
                isView: function () {
                    return false;
                }
            }
        })
            .state('mde-report.MEDICAL.VIEW', {
                parent: 'mde-report',
                url: '/medical/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/ptp/mde-report-ptp.html',
                        controller: 'MDEReportPTPController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        $translatePartialLoader.addPart('mdeReport');
                        return $translate.refresh();
                    }],
                    mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                        return MdeReportService.findById($stateParams.id);
                    }],
                    templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                        return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_MEDIAL);
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
        .state('mde-report.PT', {
            parent: 'mde-report',
            url: '/physical-functional',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/frp-mde/mde-report/pt/mde-report-pt.html',
                    controller: 'MDEReportPTController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frpmde');
                    return $translate.refresh();
                }],
                mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                    return MdeReportService.findById($stateParams.id);
                }],
                templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                    return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_PT);
                }],
                isView: function () {
                    return false;
                }
            }
        })
            .state('mde-report.PT.VIEW', {
                parent: 'mde-report',
                url: '/physical-functional/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/pt/mde-report-pt.html',
                        controller: 'MDEReportPTController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        return $translate.refresh();
                    }],
                    mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                        return MdeReportService.findById($stateParams.id);
                    }],
                    templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                        return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_PT);
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
        .state('mde-report.PSYCH', {
            parent: 'mde-report',
            url: '/psych',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/frp-mde/mde-report/psych/mde-report-psych.html',
                    controller: 'MDEReportPsychController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frpmde');
                    return $translate.refresh();
                }],
                templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                    return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_PSYCH);
                }],
                isView: function () {
                    return false;
                }
            }
        })
            .state('mde-report.PSYCH.VIEW', {
                parent: 'mde-report',
                url: '/psych/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/psych/mde-report-psych.html',
                        controller: 'MDEReportPsychController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        return $translate.refresh();
                    }],
                    templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                        return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_PSYCH);
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
        .state('mde-report.CONCLUSION', {
            parent: 'mde-report',
            url: '/conclusion',
            data: {
                authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                pageTitle: ''
            },
            views: {
                'content@': {
                    templateUrl: 'app/frp-mde/mde-report/conclusion/mde-report-conclusion.html',
                    controller: 'MDEReportConclusionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('frpmde');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                    return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_CONCLUSION);
                }],
                isView: function () {
                    return false;
                }
            }
        })
            .state('mde-report.CONCLUSION.VIEW', {
                parent: 'mde-report',
                url: '/conclusion/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/conclusion/mde-report-conclusion.html',
                        controller: 'MDEReportConclusionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    templates: ['FRPMDETemplateService', 'ipmConstants', function(FRPMDETemplateService, constants) {
                        return FRPMDETemplateService.getAllTemplateByInputTypes(constants.FRP_MDE_TEMPLATE.MDE_CONCLUSION);
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
            .state('mde-report.ADDENDUM', {
                parent: 'mde-report',
                url: '/addendum',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/addendum/mde-report-addendum.html',
                        controller: 'MDEReportAddendumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                        return MdeReportService.findById($stateParams.id);
                    }],
                    isView: function () {
                        return false;
                    }
                }
            })
            .state('mde-report.ADDENDUM.VIEW', {
                parent: 'mde-report',
                url: '/addendum/view',
                data: {
                    authorities: ['ROLE_STAFF', 'ROLE_QHP', 'ROLE_ADMIN'],
                    pageTitle: ''
                },
                views: {
                    'content@': {
                        templateUrl: 'app/frp-mde/mde-report/addendum/mde-report-addendum.html',
                        controller: 'MDEReportAddendumController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('frpmde');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    mdeReport: ['MdeReportService', '$stateParams', function(MdeReportService, $stateParams) {
                        return MdeReportService.findById($stateParams.id);
                    }],
                    isView: function () {
                        return true;
                    }
                }
            })
            .state('mde-report-preview', {
                parent: 'mde-report',
                url: '/preview',
                data: {
                    pageTitle: ''
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/frp-mde/pdf-report-preview-dialog/pdf-report-preview-dialog.html',
                        controller: 'PDFReportPreviewDiaglogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        windowClass: 'absolute-center',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            pdfData: function(MdeReportService) {
                                return MdeReportService.loadPdfReportPreviewData($stateParams.id);
                            }
                        }
                    }).result.then(function() {
                        // $state.go('patient-detail', {id:$stateParams.id}, {reload: true});
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
    }
})();
