(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('PatientPresentationService', PatientPresentationService);

    PatientPresentationService.$inject = ['$resource', '$http', '$q'];

    function PatientPresentationService ($resource, $http, $q) {
        var baseURL = "api/patient-presentation";

        var service = {
            submitResponse: submitResponse,
        };

        function submitResponse(patientPresentation) {
            return $http.post(baseURL + '/submit-response', patientPresentation).then(function (resp) {
                return resp.data;
            });
        };

        return service;
    };
})();
