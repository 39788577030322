(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ProviderDialogController', ProviderDialogController);

    ProviderDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Provider', 'ipmConstants'];

    function ProviderDialogController ($scope, $stateParams, $uibModalInstance, entity, Provider, constants) {
        var vm = this;
        vm.provider = entity;

        vm.load = function(id) {
            Provider.get({id : id}, function(result) {
                vm.provider = result;
                vm.errorContactNumberExists = null;
                vm.errorEmailExists = null;
                vm.errorProviderIdExists = null;
                if (!vm.provider.signature) {
                    vm.provider.signature = {};
                }
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('ipmApp:providerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function (error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if(error.status === 400 ) {
            	if (errorMessage === 'e-mail address already in use'){
            		vm.errorEmailExists = 'ERROR';
            	} else if (errorMessage === 'phone already in use') {
            		vm.errorContactNumberExists = 'ERROR';
            	} else if (errorMessage === 'provider id already in use') {
            		vm.errorProviderIdExists = 'ERROR';
            	} else {
            		vm.error = 'ERROR';
            	}
            }
        };

        vm.save = function () {
            vm.errorContactNumberExists = null;
            vm.errorEmailExists = null;
            vm.errorProviderIdExists = null;
            vm.error = null;

            vm.isSaving = true;
            vm.provider.credentials = vm.provider.credentials ? vm.provider.credentials : null;
            if (vm.provider.id !== null) {
                Provider.update(vm.provider, onSaveSuccess, onSaveError);
            } else {
                Provider.save(vm.provider, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
