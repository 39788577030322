(function() {
    'use strict';


    attendanceTrackerController.$inject = ['$rootScope', '$scope', 'ipmConstants'];

    function attendanceTrackerController($rootScope, $scope, constants) {
        var vm = this; //jshint ignore:line
        // vm.attendanceTrackers = [];
        vm.typeUnCheck = 0; // case check null
        vm.typeCheckV = 1; // case check V
        vm.typeCheckX = 2; // case check X
        vm.checkDisableInFuture = checkDisableInFuture;

        init();

        function init() {
            if (!vm.attendanceTrackers || vm.attendanceTrackers.length === 0) { // init data first
                vm.attendanceTrackers = [];
                _.forEach(constants.ATTENDANCE_TRACKERS, function (i) {
                    vm.attendanceTrackers.push(createAttendanceTracker(i, 0, false));
                });
            }
            updateAttendanceTracker(vm.attendanceTracker);
            // } else {
            //     vm.attendanceTracker = initAttendanceTrackerDefault();
            // }
        }

        function updateAttendanceTracker(attendanceTracker) {
            if (attendanceTracker) {
                _.forEach(vm.attendanceTrackers, function (item) {
                    return item = attendanceTrackerToCheckBox(attendanceTracker, item);
                });
            } else {
                vm.attendanceTracker = initAttendanceTrackerDefault();
            }
        }

        function initAttendanceTrackerDefault() {
            var attendanceTracker = {
                id: null,
                mon: null,
                tue: null,
                wed: null,
                thu: null,
                fri: null,
                sat: null,
                sun: null
            };
            return attendanceTracker;
        }

        function attendanceTrackerToCheckBox(attendanceTracker, checkBoxV) {
            var atSelect;
            if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[0]) { // sat
                atSelect = attendanceTracker.sat;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[1]) { // sun
                atSelect = attendanceTracker.sun;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[2]) { // M mon
                atSelect = attendanceTracker.mon;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[3]) { // tue
                atSelect = attendanceTracker.tue;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[4]) { // wed
                atSelect = attendanceTracker.wed;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[5]) { // thu
                atSelect = attendanceTracker.thu;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[6]) { // fri
                atSelect = attendanceTracker.fri;
            }

            if (atSelect && atSelect === true) {
                checkBoxV.type = vm.typeCheckV;
                checkBoxV.check = true;
            } else if (atSelect === false) {
                checkBoxV.type = vm.typeCheckX;
                checkBoxV.check = true;
            } else {
                checkBoxV.type = vm.typeUnCheck;
                checkBoxV.check = false;
            }
            return checkBoxV;
        }

        function checkBoxToAttendanceTracker(checkBoxV, attendanceTracker) {
            if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[0]) { // sat
                attendanceTracker.sat = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[1]) { // sun
                attendanceTracker.sun = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[2]) { // M mon
                attendanceTracker.mon = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[3]) { // tue
                attendanceTracker.tue = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[4]) { // wed
                attendanceTracker.wed = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[5]) { // thu
                attendanceTracker.thu = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } else if (checkBoxV.value === constants.ATTENDANCE_TRACKERS[6]) { // fri
                attendanceTracker.fri = checkBoxV.type == vm.typeUnCheck ? null : checkBoxV.type == vm.typeCheckV ? true : false;
            } 
            return attendanceTracker;
        }

        function convertCheckBoxsToAttendanceTracker() {
            _.forEach(vm.attendanceTrackers, function (item) {
                vm.attendanceTracker = checkBoxToAttendanceTracker(item, vm.attendanceTracker);
            });
        }

        function createAttendanceTracker(value, type, check) {
            return {value: value, type: type, check: check};
        }

        function updateAttendanceTrackers(value, check, type) {
            _.forEach(vm.attendanceTrackers, function (item) {
                if (item.value === value) {
                    item.check = check;
                    item.type = type;
                }
            });
        }

        function checkDisableInFuture(at) {
            var now = new moment();
            // case week future
            if (moment(vm.startDate).isAfter(now)) {
                return true;
            }
            // only check week current
            var valueOfDate = moment().day(at.value).subtract(2, 'days');
            if (vm.startDate
                && vm.endDate
                && now.isAfter(vm.startDate)
                && now.isBefore(vm.endDate)
                && now.isBefore(valueOfDate)) {
                return true;
            }
            return false;
        }

        vm.checkboxChange = function (value, check, type) {
            updateAttendanceTrackers(value, check, type);
            convertCheckBoxsToAttendanceTracker();
            vm.onChange({attendanceTracker: vm.attendanceTracker});
        }

        $scope.$watch('vm.attendanceTracker', function (newValue) {
            updateAttendanceTracker(vm.attendanceTracker);
        });
    }

    angular.module('ipmApp').component('attendanceTracker', {
        bindings: {
            attendanceTracker: '=',
            isView: '<',
            disableInFuture: '<',
            startDate: '<',
            endDate: '<',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/tab/attendance-tracker/attendance-tracker.html',
        controller: attendanceTrackerController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();
