(function() {
    'use strict';

    ObjectiveRowController.$inject = [];

    function ObjectiveRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
            vm.isAverage = vm.item.code === 'O40';
        }

        vm.change = function() {
            if(vm.isAverage){
                return;
            }
            if (!vm.item.checked) {
                // clear valua
                vm.item.value = null;
                vm.item.leftValue = null;
                vm.item.rightValue = null;
            }
            vm.onChange();
        }

        vm.changeLR = function() {
            vm.onChange();
        }

        vm.updateSelectAll = function(){
            vm.onClick();
        }
    }

    angular.module('ipmApp').component('objectiveRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&',
            onClick: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/objective-row.html',
        controller: ObjectiveRowController,
        controllerAs: 'vm'
    })
})();
