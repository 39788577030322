(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'supportedDataList', 'Principal', 'ipmConstants', '$rootScope', '$state', '$scope', 'action', 'patientCases'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService, supportedDataList, Principal, constants, $rootScope, $state, $scope, action, patientCases) {
        var vm = this;

        vm.title = "userManagement.home.createOrEditLabel";
        vm.authorities = ['ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_PATIENT', 'ROLE_MANAGER', 'ROLE_QHP', 'ROLE_BILLER'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.disabledRole = null;
        vm.user = entity;
        vm.patientCases = patientCases ? patientCases : [];
        vm.patientCaseIdsRemove = [];
        vm.isAllowActionPatientCase = false;
        vm.isPatientRole = false;
        vm.isAllowEdit = false;
        vm.isBiller = false;
        // load offices, providers
        vm.offices = supportedDataList.officeList;
        vm.providers = supportedDataList.providerList;
        vm.frpPrograms = supportedDataList.frpProgramsList;
        vm.account = null;

        vm.errorEmailExists = null;
        vm.errorPhoneExists = null;
        vm.errorPatientID = null;
        vm.birthdayInvalid = null;
        vm.error = null;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dateOfBirth = false;
        vm.isQHPRole = false;
        vm.isStaffRole = false;
        vm.roleStaff = constants.ROLE_STAFF;
        vm.qhpUserDetail = {};
        vm.staffUserDetail = {id: null, offices:[], tasks: []};
        vm.patientDetailEditState = action && action === 'PATIENT_DETAIL' ? true : false;
        vm.isSaving = false;
        vm.checkTaskFRP = checkTaskFRP;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        getAccount(false);

        function getAccount(force) {
            Principal.identity(force).then(function(res) {
                vm.account = res;
                vm.isBiller = vm.account && vm.account.authorities.indexOf('ROLE_BILLER') !== -1;
                updateAllowActionPatientCase();
            });
        }

        function updateAllowActionPatientCase() {
            vm.isAllowActionPatientCase = vm.account && vm.account.authorities.indexOf('ROLE_ADMIN') !== -1;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError (error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if(error.status === 400 ) {
            	if (errorMessage === 'e-mail address already in use'){
            		vm.errorEmailExists = 'ERROR';
            	} else if (errorMessage === 'phone already in use') {
            		vm.errorPhoneExists = 'ERROR';
            	} else if (errorMessage === 'patientID already in use') {
            		vm.errorPatientID = 'ERROR';
            	} else {
            		vm.error = 'ERROR';
            	}
            }
        }

        vm.disabled = function () {
        	return vm.user.id != 0;
        }

        vm.roleChange = function(){
        	if (vm.user.authorities) {
        		var authority = vm.user.authorities;
            	if( authority === 'ROLE_PATIENT') {
            		vm.isPatientRole = true;
            	} else {
            		vm.isPatientRole = false;
            	}
            	vm.isQHPRole = authority === constants.ROLE_QHP ? true : false;
            	vm.isStaffRole = authority === constants.ROLE_STAFF ? true : false;
            	vm.user.authorities = [authority];
                setUserDetailDefaultByChangeRole(authority);
        	}

        }


        function init() {
        	if(typeof entity.$promise === 'object') {
        		entity.$promise.then(function (result) {
            	    entity = result;
                    setQHPUserDetail();
                    setUserDetail();
            	    if( entity.authorities.length === 1 ) {
            	    	if (entity.authorities.indexOf('ROLE_PATIENT') !== -1 ) {
            	    		vm.disabledRole = true;
            	    		vm.isPatientRole = true;
            	    	} else {
            	    	    setQHPRole(entity);
                            setStaffRole(entity);
            	    		vm.authorities = ['ROLE_ADMIN', 'ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_QHP', 'ROLE_BILLER'];
            	    	}
                    }
            	});
        	} else if(entity.userId > 0) {
                vm.title = "userManagement.home.editLabel";
                User.get({id: entity.userId}, function (result) {
                    vm.user = result;
                    if (vm.user && vm.user.authorities.indexOf('ROLE_PATIENT') !== -1) {
                        vm.disabledRole = true;
                    }
                });
                getRolePermission();
            }
        }

        init();

        function save () {
        	vm.errorEmailExists = null;
            vm.errorPhoneExists = null;
            vm.errorPatientID = null;
            vm.error = null;
        	vm.birthdayInvalid = null;
            // vm.user.qhpUserDetail = vm.qhpUserDetail ? vm.qhpUserDetail : {};
            // update for save vm.user.userDetail
            updateUserDetail();
            // update patient case
            vm.user.patientCases = vm.patientCases;
            vm.user.patientCaseIdsRemove = vm.patientCaseIdsRemove;
            vm.isSaving = true;
            if (vm.user.id !== null) {
                if (vm.isBiller) {
                    vm.user = User.updatePatientCases( { id: vm.user.id, patientCases: vm.patientCases, patientCaseIdsRemove: vm.patientCaseIdsRemove }, onSaveSuccess, onSaveError);
                } else {
                    vm.user = User.update(vm.user, onSaveSuccess, onSaveError);
                }
            } else {
                vm.user = User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function getRolePermission () {
            if (Principal.hasAnyAuthority([constants.ROLE_STAFF]) === true || Principal.hasAnyAuthority([constants.ROLE_MANAGER]) === true
                || Principal.hasAnyAuthority([constants.ROLE_ADMIN]) === true || Principal.hasAnyAuthority([constants.ROLE_BILLER]) === true) {
                vm.isAllowEdit = true;
            } else {
                vm.isAllowEdit = false;
            }
        }

        $scope.$watch("vm.user.dateOfBirth", function(newValue, oldValue) {
            var currentDate = new Date();
            if (!vm.user.dateOfBirth) { return }
            if(vm.user.dateOfBirth > currentDate) {
                vm.birthdayInvalid = 'ERROR';
            } else {
                vm.birthdayInvalid = null;
            }
            vm.isSaving = false;
            return;
        });

        $rootScope.$on( '$stateChangeSuccess', function ( event, to, toParams, from, fromParams ) {
            $uibModalInstance.dismiss('cancel');
        });

        vm.changeIgnoreEmail = function() {
            vm.user.ignoreSendEmail = vm.user.ignoreSendEmail ? false : true;
        }

        vm.changeIgnoreSendSms = function() {
            vm.user.ignoreSendSms = vm.user.ignoreSendSms ? false : true;
        }

        vm.qhpTasksOnChange = function (tasks) {
            vm.qhpUserDetail.tasks = tasks ? tasks : [];
        }

        vm.staffTasksOnChange = function (tasks) {
            vm.staffUserDetail.tasks = tasks ? tasks : [];
        }

        vm.selectedOfficesOnChange = function (selectedOffices) {
            vm.staffUserDetail.offices = selectedOffices ? selectedOffices : [];
        }

        function setQHPRole(entity) {
            if (entity && entity.authorities.indexOf(constants.ROLE_QHP) !== -1) {
                vm.isQHPRole = true;
            } else {
                vm.isQHPRole = false;
            }
        }

        function setStaffRole(entity) {
            if (entity && entity.authorities.indexOf(constants.ROLE_STAFF) !== -1) {
                vm.isStaffRole = true;
            } else {
                vm.isStaffRole = false;
            }
        }

        function setUserDetailDefaultByChangeRole(authority) {
            if (authority && authority === constants.ROLE_QHP ) {
                vm.qhpUserDetail.tasks = entity.qhpUserDetail && entity.qhpUserDetail.id && entity.qhpUserDetail.tasks ? _.cloneDeep(entity.qhpUserDetail.tasks) : [];
                vm.staffUserDetail.tasks = [];
            } else if (authority && authority === constants.ROLE_STAFF ){
                vm.staffUserDetail.tasks = entity.staffUserDetail && entity.staffUserDetail.id && entity.staffUserDetail.tasks ? _.cloneDeep(entity.staffUserDetail.tasks) : [];
                vm.qhpUserDetail.tasks = [];
            }
        }

        function setQHPUserDetail() {
            vm.qhpUserDetail = entity && entity.qhpUserDetail ? entity.qhpUserDetail : {};
        }
        function setUserDetail() {
            vm.staffUserDetail = entity && entity.staffUserDetail ? entity.staffUserDetail : {};
        }

        function updateUserDetail() {
            filterAndUpdateOffices();
            // update UserDetail for User
            vm.user.qhpUserDetail = vm.qhpUserDetail ? vm.qhpUserDetail : {};
            vm.user.staffUserDetail = vm.staffUserDetail ? vm.staffUserDetail : {};
        }

        function filterAndUpdateOffices() {
            if (vm.isQHPRole) {
                vm.qhpUserDetail.frpPrograms = filterFRpPrograms(vm.qhpUserDetail.frpPrograms);
            } else if (vm.isStaffRole) {
                vm.staffUserDetail.frpPrograms = filterFRpPrograms(vm.staffUserDetail.frpPrograms);
            }
        }

        function filterOffice(offices) {
            if (offices && offices.length > 0) {
                return _.filter(offices, function (office) {
                    return office && office.id;
                });
            }
            return [];
        }

        function filterFRpPrograms(frpPrograms) {
            if (frpPrograms && frpPrograms.length > 0) {
                return _.filter(frpPrograms, function (frpProgram) {
                    return frpProgram && frpProgram.id;
                });
            }
            return [];
        }

        function validateFRpProgramsStaff() {
            return filterFRpPrograms(vm.staffUserDetail.frpPrograms).length > 0;
        }

        function validateFRpProgramsQhp() {
            return filterFRpPrograms(vm.qhpUserDetail.frpPrograms).length > 0;
        }

        function checkTaskFRP(tasks) {
            if (tasks && tasks.length > 0) {
                return !_.isEmpty(_.filter(tasks, function (task) {
                    return task === constants.FRP_MDE;
                }));
            }
            return false;
        }

        vm.validateRoleTasks = function () {
            if (vm.isQHPRole) {
                if (vm.qhpUserDetail && vm.qhpUserDetail.tasks && vm.qhpUserDetail.tasks.length > 0) {
                    if (checkTaskFRP(vm.qhpUserDetail.tasks) === true) {
                        return validateFRpProgramsQhp();
                    }
                    return true;
                }
                return false;
            } else if (vm.isStaffRole) {
                if (vm.staffUserDetail && vm.staffUserDetail.tasks && vm.staffUserDetail.tasks.length > 0) {
                    if (checkTaskFRP(vm.staffUserDetail.tasks) === true) {
                        return validateFRpProgramsStaff();
                    }
                    return true;
                }
                return false;
            }
            return true;
        };

        vm.checkUpdateBillablePatientCase = function () {
            if (vm.account && vm.account.authorities) {
                var allowedRoles = [constants.ROLE_ADMIN, constants.ROLE_MANAGER, constants.ROLE_BILLER];
                var hasAllowedRole = _.some(vm.account.authorities, function (role) {
                    return _.includes(allowedRoles, role);
                });
                return !hasAllowedRole;
            }
            return true;
        };

    }
})();
