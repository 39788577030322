(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('MdeReportService', MdeReportService);

    MdeReportService.$inject = ['$http', 'halClient', '$q', 'DownloadBlobService'];

    function MdeReportService ($http, halClient, $q, DownloadBlobService) {
        var baseURL = 'api/mde-report/';
        var prefixApiAdmin = '/office';

        var service = {
            'get': get,
            'getMdeReport': getMdeReport,
            'getMdePtReport': getMdePtReport,
            'getMdePsychReport': getMdePsychReport,
            'findById': findById,
            'saveMdePtReport': saveMdePtReport,
            'saveMdePsychReport': saveMdePsychReport,
            'saveMdeConclusionReport': saveMdeConclusionReport,
            'getMdeAdminReport': getMdeAdminReport,
            'getMdeConclusionReport': getMdeConclusionReport,
            'saveMDEReportPTP': saveMDEReportPTP,
            'finalizeMDEReportPTP': finalizeMDEReportPTP,
            'saveMdeAdminReport': saveMdeAdminReport,
            'createMdeReport': createMdeReport,
            'updateAdminAbandoned': updateAdminAbandoned,
            'getPhysicalDemandLevels': getPhysicalDemandLevels,
            'finalizeMdeReport': finalizeMdeReport,
            'downloadMDEReport': downloadMDEReport,
            'loadPdfReportPreviewData': loadPdfReportPreviewData,
            'saveMDEReportAddendum': saveMDEReportAddendum,
            'isPatientActivated': isPatientActivated
        };

        function finalizeMDEReportPTP(mdeReport) {
            var url = baseURL + 'finalize-mde-report-ptp';
            return $http.post(url, mdeReport).then(function (resp) {
                return resp.data;
            });
        }

        function saveMDEReportPTP(mdeReport) {
            var url = baseURL + 'save-mde-report-ptp';
            return $http.post(url, mdeReport).then(function (resp) {
                return resp.data;
            });
        }

        function saveMDEReportAddendum(mdeReport) {
            var url = baseURL + 'save-mde-report-addendum';
            return $http.post(url, mdeReport).then(function (resp) {
                return resp.data;
            });
        }

        function get(id){
            return $http.get(baseURL + id).then( function(response) {
                return response;
            })
        }

        function getMdeReport(id){
            return $http.get(baseURL + id + '/get-mde-report').then( function(response) {
                return response;
            })
        }

        function getMdePtReport(mdeReportId){
            return $http.get(baseURL + mdeReportId + '/pt').then( function(response) {
                return response.data;
            })
        }

        function saveMdePtReport(mdeReportId, data){
            return $http.post(baseURL + mdeReportId + '/pt', data).then( function(response) {
                return response.data;
            })
        }

        function getMdePsychReport(mdeReportId){
            return $http.get(baseURL + mdeReportId + '/psych').then( function(response) {
                return response.data;
            })
        }

        function saveMdePsychReport(mdeReportId, data){
            return $http.post(baseURL + mdeReportId + '/psych', data).then( function(response) {
                return response.data;
            })
        }

        function getMdeConclusionReport(mdeReportId){
            return $http.get(baseURL + mdeReportId + '/conclusion').then( function(response) {
                return response.data;
            })
        }

        function saveMdeConclusionReport(mdeReportId, data){
            return $http.post(baseURL + mdeReportId + '/conclusion', data).then( function(response) {
                return response.data;
            })
        }

        function getMdeAdminReport(id){
            return $http.get(baseURL + id + prefixApiAdmin).then( function(response) {
                return response.data;
            })
        }

        function findById(id) {
            var deferred = $q.defer();
            var url = baseURL + "find-by-id/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };


        function saveMdeAdminReport(id, data) {
            return $http.put(baseURL + id + prefixApiAdmin, data).then( function(response) {
                return response.data;
            })
        }

        /**
         *
         * @param  mdeId
         *
         * @returns
         */
        function createMdeReport(id){
            var url = baseURL + id;
             return $http.post(url);
         }

        /**
         *
         * @param  mdeId
         * @param searchKey
         * @returns
         */
        function updateAdminAbandoned(id, caseNumber){
            return $http.put(baseURL + id + prefixApiAdmin + "/update-abandoned").then(function (response) {
                 return response.data;
             })
         }

        function finalizeMdeReport(mdeReportId) {
            var url = baseURL + 'finalize-mde-report/' + mdeReportId;
            return $http.put(url).then(function (response) {
                return response.data;
            })
        }

        function downloadMDEReport(mdeReportId) {
            var deferred = $q.defer();
            var url = baseURL + 'download-mde-report/' + mdeReportId;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                }else {
                    var contentDisposition = headers('content-disposition');
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    var fileName = DownloadBlobService.getFileName(contentDisposition);
                    saveAs(blob, fileName);
                    deferred.resolve(true);
                }

            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function loadPdfReportPreviewData(mdeReportId) {
            var deferred = $q.defer();
            var url = baseURL + 'preview-mde-report/' + mdeReportId;
            $http({
                url: url,
                method: "GET",
                params: {},
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    deferred.resolve(data);
                }
            });
            return deferred.promise;
        }

        function getPhysicalDemandLevels() {
            return $http.get(baseURL + '/physical-demand-levels').then( function(response) {
                return response.data;
            })
        }

        function isPatientActivated(id) {
            var deferred = $q.defer();
            var url = baseURL + "is-patient-activated/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    };
})();
