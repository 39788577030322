(function() {
    'use strict';


    CPRStatusIndicatorController.$inject = ['$scope', '$state'];

    function CPRStatusIndicatorController($scope, $state) {
        var vm = this; //jshint ignore:line
        
        init();

        function init() {
        }

        
    }

    angular.module('ipmApp').component('cprStatusIndicator', {
        bindings: {
            statusIndicator: '<',
            css: '<',
            onSectionAnswerChange: '&',
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/status-indicator/status-indicator.html',
        controller: CPRStatusIndicatorController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
