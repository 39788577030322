(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientListController', PatientListController);

        PatientListController.$inject = ['$scope', '$state', 'PatientService', '$localStorage' , 'supportedStatusFilter', '$stateParams', '$window', 'Principal', 'ipmConstants'];

    function PatientListController ($scope, $state, PatientService, $localStorage, supportedStatusFilter, $stateParams, $window, Principal, constants) {
    	var vm = this;
        vm.patients = [];
        vm.statuses = supportedStatusFilter.statuses;
        vm.status = null;
        vm.query = null;
        vm.page = 1;
        vm.totalItems = 0;
        vm.predicate = 'p.user.firstName';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.isBiller = false;

        getAccount(true);

        function getAccount(force) {
            Principal.identity(force).then(function(res) {
                vm.account = res;
                vm.isBiller = Principal.hasAnyAuthority([constants.ROLE_BILLER]);
            });
        }

        function loadAll() {
        	var pageable = {page: vm.page > 0 ? vm.page - 1 : vm.page,
    						size: vm.pageSize,
                            sort: sort()};

            PatientService.searchPatients(vm.searchQuery, vm.status, pageable).then( function(response) {
            	if (response.content !== undefined) {
	        		vm.patients = response.content;
				} else {
					vm.patients = [];
				}

                vm.totalItems =  response.totalElements;
                vm.totalElements =  response.page ? response.page.totalElements : 0;
            });

        };

        function init() {
        	var paramsInfo = $stateParams.searchPatientParamsInfo;
        	if(paramsInfo) {
        		vm.status = paramsInfo.status;
        		vm.searchQuery = paramsInfo.searchQuery;
        		vm.page = paramsInfo.page;
        		vm.totalItems = paramsInfo.totalItems;
        		vm.pageSize = paramsInfo.pageSize;
        		vm.predicate = paramsInfo.predicate;
        		vm.reverse = paramsInfo.reverse;
        	}
        	vm.loadAll();
        }

        vm.goToPatientDetails = function(id) {
        	var paramsInfo = getSearchPatientParamsInfo();
        	$state.go('patient-detail', {id: id, searchPatientParamsInfo: paramsInfo});
        }

        function getSearchPatientParamsInfo() {
        	var paramsInfo = {
        		status: vm.status,
        		searchQuery: vm.searchQuery,
            	page: vm.page,
            	totalItems: vm.totalItems,
            	pageSize: vm.pageSize,
            	predicate: vm.predicate,
            	reverse: vm.reverse
        	}

        	return paramsInfo;
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
        }

        vm.search = function () {
            vm.page = 0;
            vm.predicate = 'p.user.firstName';
            vm.reverse = false;
            vm.loadAll();
        };

        vm.clear = function () {
            vm.patients = [];
            vm.links = null;
            vm.page = 0;
            vm.predicate = 'p.user.firstName';
            vm.reverse = true;
            vm.searchQuery = null;
            vm.loadAll();
        };

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        init();
    }
})();
