(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('TestEditorController', TestEditorController);

        TestEditorController.$inject = ['$scope', '$rootScope','$state', '$stateParams', 'isView', 'isCreateNew', 'isDuplicate', 'Test', 'BatteryTest', 'Section'];

    function TestEditorController($scope, $rootScope, $state, $stateParams, isView, isCreateNew, isDuplicate, Test, BatteryTest, Section) {
        var vm = this;
        vm.isSaving = false;
        // vm.test = entity;
        vm.isDuplicate = isDuplicate;
        vm.isCreateNew = isCreateNew;
        vm.isView = isView;
        vm.scoringTypes = vm.isView ? ['SUM', 'PERCENTAGE', 'CUSTOM', 'AVERAGE', 'NONE'] : ['SUM', 'PERCENTAGE', 'AVERAGE', 'NONE'];
        vm.thresholdTypes = ['GREATER_THAN', 'LESS_THAN', 'NONE'];
        vm.haqEvaluationTypes = ['COMM', 'SOAPP_5', 'EPWORTH', 'ISI', 'CES_D', 'GAD_7', 'FAB_Q', 'TSK', 'PCS', 'OSWESTRY', 'QUICKDASH', 'PDI', 'NDI'];
        vm.init = init;
        vm.save = save;
        vm.addNewScoreMessage = addNewScoreMessage;
        vm.handlePdfDescription = handlePdfDescription;
        vm.handleInstructionsEn = handleInstructionsEn;
        vm.handleInstructionsEs = handleInstructionsEs;
        vm.handleDescriptionEn = handleDescriptionEn;
        vm.handleDescriptionEs = handleDescriptionEs;
        vm.removeScoringMessages = removeScoringMessages;

        init();

        function init() {

          if ($stateParams.id) {
            Test.get({id : $stateParams.id}, function(res) {
              // set test data
              vm.test = res;

              vm.isEdit = !vm.isView && !vm.isDuplicate && !vm.isCreateNew;

              if (vm.isView) {
                // disable form
              } else {
                  if(vm.isDuplicate || vm.isCreateNew) {
                      if (!vm.test.scoreMessages || vm.test.scoreMessages.length === 0 ) {
                          vm.test.scoreMessages = [];
                          vm.test.scoreMessages.push({});

                      }
                      if(vm.isDuplicate && vm.test.scoringType === 'CUSTOM') {
                        // remove Scoring Type = 'CUSTOM' if is duplicate
                        vm.test.scoringType = undefined;
                      }
                  }
              }
            });
          } else {
            vm.test = {
              nameEn: null,
              nameEs: null,
              instructionsEn: null,
              instructionsEs: null,
              activated: true,
              active: true,
              id: null,
              scoreMessages: [{}]
            };
          }



        }


        function save() {
          // remove scoring message empty
          var newScoreMessagesLists = [];
          angular.forEach(vm.test.scoreMessages, function (scoreMessage) {
              if (scoreMessage.atLeast || scoreMessage.lessThan || scoreMessage.textMessage) {
                newScoreMessagesLists.push(scoreMessage);
              }
          });
          // re-assign to question object
          vm.test.scoreMessages = newScoreMessagesLists;

          vm.isSaving = true;
          if (vm.isDuplicate) {
            Test.duplicate(vm.test.id, vm.test).then(function(res) {
              vm.isSaving = false;
              $state.go('test');
            });
          } else {
            Test.save(vm.test, onSaveSuccess, onSaveError);
          }
        }

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $state.go('test');
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        function addNewScoreMessage() {
            vm.test.scoreMessages.push({});
        }

        function handleInstructionsEn(value) {
          vm.test.instructionsEn =  value;
        }

        function handleInstructionsEs(value) {
          vm.test.instructionsEs =  value;
        }

        function handleDescriptionEn(value) {
          vm.test.descriptionEn =  value;
        }

        function handleDescriptionEs(value) {
          vm.test.descriptionEs =  value;
        }

        function handlePdfDescription(value, editForm) {
            if (editForm && editForm['pdfDescription'] && !editForm['pdfDescription'].$dirty) {
                editForm['pdfDescription'].$setDirty();
            }
            editForm['pdfDescription'].$setViewValue(value);
        }

        function removeScoringMessages (index) {
          vm.test.scoreMessages = _.reject(vm.test.scoreMessages, function(v, i) { return index === i });
      }

        var unsubscribe = $rootScope.$on('ipmApp:testUpdate', function(event, result) {
          vm.test = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.back = function () {
          if ($stateParams.isFromHAQ) {
              $state.go('haqs-battery');
              return;
          }
          $state.go('test');
      };
    }
})();
