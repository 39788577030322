
(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqTracksDialogController', HaqTracksDialogController);

    HaqTracksDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance','entity', 'HaqTracksService', '$scope'];

    function HaqTracksDialogController ($stateParams, $state, $uibModalInstance, entity, HaqTracksService, $scope) {
        var vm = this;
        vm.haqTracks = entity;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.onchangeTracksAssigneds = onchangeTracksAssigneds;
        vm.haqTracksCodeExists = false;

        init();

        function init() {
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onchangeTracksAssigneds(tracksAssigneds) {
            vm.haqTracks.tracksAssigneds = tracksAssigneds ? tracksAssigneds : [];
        }

        function save() {
            vm.isSaving = true;
            if (vm.haqTracks) {
                if (vm.haqTracks.id !== null) {
                    HaqTracksService.update(vm.haqTracks, onSaveSuccess, onSaveError);
                } else {
                    HaqTracksService.save(vm.haqTracks, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.haqTracksCodeExists = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            vm.haqTracksCodeExists = true;
        }

        $scope.$watch('vm.haqTracks.code', function (newval, oldval) {
            if (newval != oldval && vm.haqTracksCodeExists) {
                vm.haqTracksCodeExists = false;
            }
        });

    }
})();
