(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('LandingPageController', LandingPageController);

    LandingPageController.$inject = ['$scope', '$state', '$window', 'LandingPageService', 'ClearLocalStorageService', 'AgreementsWorkflowService', 'ipmConstants', 'SurveyResultService',
                        'Principal', 'tmhDynamicLocale', '$rootScope'];

    function LandingPageController($scope, $state, $window, LandingPageService, ClearLocalStorageService, AgreementsWorkflowService, constants, SurveyResultService, Principal, tmhDynamicLocale, $rootScope) {
        var vm = this;
        vm.landingpage = null;
        vm.clickAction = clickAction;
        vm.slides = [];
        vm.myInterval = false;
        vm.noWrapSlides = false;
        vm.active = true;
        vm.percentCompleted = 100; // completed 100%
        vm.isProcessing = false;
        vm.step1 = 1;
        vm.step2 = 2;
        vm.step3 = 3;
        vm.step4 = 4;
        vm.currentStep = null;
        vm.isHideHAQStep = false;
        vm.isHideHAQStepBackground = false;
        vm.isShowStep = isShowStep;
        vm.getNumberOfNpqNotYetComplete = getNumberOfNpqNotYetComplete;
        vm.isCompletedAgreementStep = isCompletedAgreementStep;
        vm.isCompletedNPQStep = isCompletedNPQStep;
        vm.isCompletedHAQStep = isCompletedHAQStep;
        vm.isResumeStep = isResumeStep;
        vm.isStartStep = isStartStep;
        vm.percentTextClass = percentTextClass;
        vm.timeRemainingTextClass = timeRemainingTextClass;
        vm.isShowTextStep = isShowTextStep;
        vm.isShowRemainingText = isShowRemainingText;
        vm.isShowCompletedText = isShowCompletedText;
        vm.isShowApproxText = isShowApproxText;
        vm.isCompletedAllStep = false;
        vm.langdingPageSteps = [];
        vm.isPatientNoNPQ = false;
        vm.itemLandingPageClick = itemLandingPageClick;
        vm.itemLandingPageClickDownload = itemLandingPageClickDownload;
        vm.isLaunchAction = false;
        vm.isCompleteHAQ = false;
        vm.handleHAQBodyPartSubmitSuccess = handleHAQBodyPartSubmitSuccess;
        vm.isDisplayItemLandingPageForPatientNoNPQ = isDisplayItemLandingPageForPatientNoNPQ;
        vm.isDisplayItemLandingPageForAdhocTesting = isDisplayItemLandingPageForAdhocTesting;
        vm.isAgreementSignedAllByAPI = isAgreementSignedAllByAPI;
        vm.navigateAdhocTesting = navigateAdhocTesting;

        vm.hasHaqAdhocTesting = false;
        vm.haqAdhocId = null;
        vm.batteryTestAdhocId = null;
        vm.adhocTestName = '';
        vm.percentProgressOfHAQAdhoc = 0;
        vm.isShowAdhocTesting = isShowAdhocTesting;
        vm.isLaunchFromHaqAdhoc = false;
        vm.currentSurveyResultId = null;
        vm.isLaunching = getLaunchFrom() != null;

        init();

        function init() {
            initLandingPage();
        }

        function initLandingPage() {
            initLandingPageBackground();

            vm.isLaunchAction = getHAQDirectLink();
            vm.noWait = $window.localStorage.getItem("noWait");
            var haqId = $window.localStorage.getItem("surveyResultId");
            var _passRestriction = $window.localStorage.getItem(constants.PASS_RESTRICTION);
            if (_passRestriction && true == Boolean(_passRestriction)) {
                vm.byPassRestriction = true;
            }

            LandingPageService.getLandingPage(vm.isLaunchAction, vm.noWait, haqId).then(function (response) {
                vm.landingpage = response ? response.data : null;
                // IPMproject-B1357 Landing page: The language is EN while the language of patient is SP
                var langKeyPatient = vm.landingpage.langKey;
                if (langKeyPatient) {
                    $rootScope.$emit("changeLanguage", {langKey: langKeyPatient});
                }

                if (vm.landingpage) {
                    vm.hasHaqAdhocTesting = vm.landingpage.hasHaqAdhocTesting;
                    if (!vm.hasHaqAdhocTesting && isLaunchFromHaqAdhoc()) {
                        ClearLocalStorageService.clearLaunchFrom();
                    }
                    vm.haqAdhocId = vm.landingpage.latestSurveyResultAdhocId;
                    vm.batteryTestAdhocId = vm.landingpage.latestBatteryTestAdhocId;
                    vm.adhocTestName = vm.landingpage.latestAdhocTestName;
                    vm.percentProgressOfHAQAdhoc = vm.landingpage.percentProgressOfHAQAdhoc;
                    vm.isLaunchFromHaqAdhoc = vm.landingpage.isLaunchFromHaqAdhoc;
                    vm.currentSurveyResultId = vm.landingpage.currentSurveyResultId;
                }

                setCurrentStep();
                // set isHideHAQStep
                setIsHideHAQStep();

                if (vm.landingpage.patientType === 'NEW_PATIENT_NO_NPQ') {
                    vm.batteryTestId = vm.landingpage && vm.landingpage.haqResponseInfo ? vm.landingpage.haqResponseInfo.batteryTestId : null;
                    vm.isPatientNoNPQ = true;
                    // define common variable
                    var completedTextTranslate = 'ipmApp.landingPage.details.completed';
                    var terminadoTextTranslate = 'ipmApp.landingPage.details.terminado';
                    var approxTextTranslate = 'ipmApp.landingPage.details.approx';
                    var minTextTranslate = 'ipmApp.landingPage.details.min';
                    var remainingTextTranslate = 'ipmApp.landingPage.details.remaining';
                    var clickToStartTextTranslate = 'ipmApp.landingPage.action.clickToStart';
                    var clickToResumeTextTranslate = 'ipmApp.landingPage.action.clickToResume';
                    var clickToDownloadTextTranslate = 'ipmApp.landingPage.action.clickToDownload';

                    var agreementItem = { 
                        isAgreement: true,
                        image: './content/images/assets_agreement.png', 
                        titleTextTranslate: 'ipmApp.landingPage.details.newPatientAgreements', 
                        stepNumber: 1, 
                        isCurrentStep: vm.landingpage.percentProgressOfAgreement !== vm.percentCompleted,
                        percentProgress: vm.landingpage.percentProgressOfAgreement,
                        numberTimeRemaining: vm.landingpage.numberTimeRemainingOfAgreement,
                        canDownload: true,
                        completedTextTranslate: completedTextTranslate,
                        terminadoTextTranslate: terminadoTextTranslate,
                        approxTextTranslate: approxTextTranslate,
                        minTextTranslate: minTextTranslate,
                        remainingTextTranslate: remainingTextTranslate,
                        clickToStartTextTranslate: clickToStartTextTranslate,
                        clickToResumeTextTranslate: clickToResumeTextTranslate,
                        clickToDownloadTextTranslate: clickToDownloadTextTranslate
                    }

                    var haqItem = { 
                        isHAQ: true,
                        image: './content/images/assets_haq.png', 
                        titleTextTranslate: 'ipmApp.landingPage.details.healthAssessmentQuestionnaire', 
                        stepNumber: 2, 
                        isCurrentStep: vm.landingpage.percentProgressOfAgreement === vm.percentCompleted && vm.landingpage.percentProgressOfHAQ !== vm.percentCompleted,
                        percentProgress: vm.landingpage.percentProgressOfHAQ,
                        numberTimeRemaining: vm.landingpage.numberTimeRemainingOfHAQ,
                        completedTextTranslate: completedTextTranslate,
                        terminadoTextTranslate: terminadoTextTranslate,
                        approxTextTranslate: approxTextTranslate,
                        minTextTranslate: minTextTranslate,
                        remainingTextTranslate: remainingTextTranslate,
                        clickToStartTextTranslate: clickToStartTextTranslate,
                        clickToResumeTextTranslate: clickToResumeTextTranslate,
                        clickToDownloadTextTranslate: clickToDownloadTextTranslate
                    }

                    vm.langdingPageSteps = [agreementItem, haqItem];
                }
                
                vm.isCompletedAllStep = isCompletedAllStep();
                
            });
        }

        function setIsHideHAQStep() {
            vm.isHideHAQStep = (vm.landingpage ? vm.landingpage.haqTestServiceExclusion : false) || isLaunchFromHaqAdhoc();

            vm.isHideHAQStepBackground = vm.landingpage ? vm.landingpage.haqTestServiceExclusion && vm.currentStep === vm.step3 && !isCompletedHAQStep() : false;
        }

        function initLandingPageBackground() {
            var currIndex = 0;
            vm.slides.push({
                image: 'content/images/assets_bg.jpg',
                id: currIndex++
            });
        }

        function clickAction(step, $event) {
            if (vm.isPatientNoNPQ) {
                angular.forEach(vm.langdingPageSteps, function(step) {
            		if (step && step.isCurrentStep) {
                        itemLandingPageClick(step);
                        return;
            		}
                });
                return;
            }

            if (step && step == vm.step1 && isCompletedAgreementStep() && ! vm.isProcessing) {
                if (!vm.isAgreementSignedAllByAPI()) {
                    clickToDownload();
                }
                return;
            }
            if (step && step != vm.currentStep) {
                return;
            }
            if ((!vm.currentStep || vm.currentStep && vm.currentStep === 4) && isCompletedHAQStep() && !isLaunchFromHaq() && !isCompletedHAQAdhocStep()) {
                navigateAdhocTesting();
                return;
            }
            goToHome();
        }

        function goToHome() {
            ClearLocalStorageService.setLandingPage(true); // landing page is null or false will open landing page
            $state.go('home');
        }

        function navigateAdhocTesting() {
            ClearLocalStorageService.setSurveyResultId(vm.haqAdhocId);
            $state.go('survey-workflow', {id: vm.batteryTestAdhocId});
        }

        function clickToDownload() {
            vm.isProcessing = true;
            if (isCompletedAgreementStep()) {
                AgreementsWorkflowService.downloadAgreements().then(function (response) {
                    vm.isProcessing = false;
                });
            }
        }

        function isCompletedAgreementStep() {
            if (vm.isAgreementSignedAllByAPI()) {
                return true;
            }
            return vm.landingpage && vm.landingpage.percentProgressOfAgreement && vm.landingpage.percentProgressOfAgreement === vm.percentCompleted ? true : false;
        }

        function isCompletedNPQStep() {
            return vm.landingpage && vm.landingpage.percentProgressOfNPQ && vm.landingpage.percentProgressOfNPQ == vm.percentCompleted ? true : false;
        }

        function isCompletedHAQStep() {
            return vm.landingpage && vm.landingpage.percentProgressOfHAQ && vm.landingpage.percentProgressOfHAQ == vm.percentCompleted ? true : false;
        }

        function isCompletedHAQAdhocStep() {
            return vm.landingpage && ((vm.landingpage.percentProgressOfHAQAdhoc && vm.landingpage.percentProgressOfHAQAdhoc === vm.percentCompleted) || !vm.hasHaqAdhocTesting);
        }

        function isStartStep(step) {
            if (step && step == vm.step1) {
                return !isCompletedAgreementStep() && !isResumeStep(step);
            } else if (step && step == vm.step2) {
                return !isCompletedNPQStep() && !isResumeStep(step);
            } else if (step && step == vm.step3) {
                return !isCompletedHAQStep() && !isResumeStep(step);
            } else if (step && step == vm.step4) {
                return !isCompletedHAQAdhocStep() && !isResumeStep(step);
            }
        }

        function isResumeStep(step) {
            if (step && step == vm.step1) {
                return vm.landingpage && vm.landingpage.percentProgressOfAgreement && vm.landingpage.percentProgressOfAgreement > 0 && vm.landingpage.percentProgressOfAgreement < vm.percentCompleted ? true : false;
            } else if (step && step == vm.step2) {
                return vm.landingpage && vm.landingpage.percentProgressOfNPQ && vm.landingpage.percentProgressOfNPQ > 0 && vm.landingpage.percentProgressOfNPQ < vm.percentCompleted ? true : false;
            } else if (step && step == vm.step3) {
                return vm.landingpage && vm.landingpage.percentProgressOfHAQ && vm.landingpage.percentProgressOfHAQ > 0 && vm.landingpage.percentProgressOfHAQ < vm.percentCompleted ? true : false;
            } else if (step && step == vm.step4) {
                return vm.landingpage && vm.landingpage.percentProgressOfHAQAdhoc && vm.landingpage.percentProgressOfHAQAdhoc > 0 && vm.landingpage.percentProgressOfHAQAdhoc < vm.percentCompleted ? true : false;
            }
        }

        function isShowStep(step) {
            if (step === vm.step1 || step === vm.step2) {
                if (getKeepLandingPage() || vm.isLaunching || (!isCompletedAgreementStep() && isCompletedNPQStep())) { // IPMproject-Q223
                    return false;
                }
            }
            if (step === vm.step3) {
                return !vm.isHideHAQStep;
            }
            return true;
        }

        function isShowAdhocTesting() {
            return vm.hasHaqAdhocTesting && (!vm.isLaunching || (vm.isLaunching && isLaunchFromHaqAdhoc()));
        }

        function isLaunchFromHaqAdhoc() {
            return getLaunchFrom() === constants.HAQ_ADHOC;
        }

        function isLaunchFromHaq() {
            return getLaunchFrom() === constants.HAQ;
        }
        function isShowTextStep(step) {
            if (step === vm.step3) {
                if (getHAQDirectLink() || (!isShowStep(vm.step1) && !isShowStep(vm.step2))) {
                    return false;
                }
            }
            return true;
        }

        function isShowRemainingText(percent) {
            if (percent && percent > 0 && percent < vm.percentCompleted) {
                return true;
            }
            return false;
        }

        function isShowCompletedText(percent) {
            if (percent && percent === vm.percentCompleted) {
                return true;
            }
            return false;
        }

        function isShowApproxText(percent) {
            if (percent && percent > 0) {
                return false;
            }
            return true;
        }

        function getHAQDirectLink() {
            var _surveyResultId = getSurveyResultId();
            return _surveyResultId && true === Boolean(_surveyResultId);
        }

        function getSurveyResultId() {
            return ClearLocalStorageService.getSurveyResultId();
        }

        function getLaunchFrom() {
            return ClearLocalStorageService.getLaunchFrom();
        }

        function getKeepLandingPage() {
            var _keepLandingPage = $window.localStorage.getItem(constants.KEEP_LANDING_PAGE);
            return _keepLandingPage && true === Boolean(_keepLandingPage);
        }

        function getNumberOfNpqNotYetComplete() {
            return vm.landingpage ? vm.landingpage.numberOfNPQNotYetComplete : 0;
        }

        function percentTextClass(percent) {
            if (percent && percent == vm.percentCompleted) {
                return 'text-success';
            } else if (percent && percent > 0 && percent < vm.percentCompleted) {
                return 'text-warning';
            }
            return '';
        }

        function timeRemainingTextClass(percent) {
            if (percent && percent == vm.percentCompleted) {
                return '';
            } else if (percent && percent > 0 && percent < vm.percentCompleted) {
                return 'text-warning';
            }
            return 'text-success';
        }

        function setCurrentStep() {
            if ((!isShowStep(vm.step2) || (isShowStep(vm.step2) && isCompletedNPQStep())) && isCompletedHAQStep() && !isCompletedHAQAdhocStep()) {
                return vm.currentStep = vm.step4;
            } else if (getHAQDirectLink() || (isCompletedNPQStep() && !isCompletedHAQStep())) { // IPMproject-Q223
                return vm.currentStep = vm.step3;
            } else if (!isCompletedAgreementStep()) {
                return vm.currentStep = vm.step1;
            } else if (isCompletedAgreementStep() && !isCompletedNPQStep()) {
                return vm.currentStep = vm.step2;
            }
            return null;
        }



        function isCompletedAllStep() {
            if (vm.isPatientNoNPQ) {
                var a = [];
                angular.forEach(vm.langdingPageSteps, function (step) {
                    if (step && step.percentProgress < vm.percentCompleted) {
                        a.push(step);
                    }
                });
                return a.length === 0;
            }
            if (!isShowStep(vm.step1) && !isShowStep(vm.step2)) { // only show step 3
                return isCompletedHAQStep() && (isLaunchFromHaq() || isCompletedHAQAdhocStep());
            }
            return isCompletedAgreementStep() && isCompletedNPQStep() && isCompletedHAQStep() && (isLaunchFromHaq() || isCompletedHAQAdhocStep());
        }

        function itemLandingPageClick(item) {
            if (item && item.isCurrentStep) {
                if (item.isAgreement) {
                    // go to agreement workflow
                    $state.go('agreements-workflow');
                }
                if (item.isHAQ)  {
                    var haqInfo = vm.landingpage.haqResponseInfo;
                    if((haqInfo.allowStartNewBattery === null || haqInfo.allowStartNewBattery === true || vm.byPassRestriction) &&
                        haqInfo.isEmailConfirmed === false && haqInfo.disposition == true) {

                        // display body part
                        vm.isDisplayHaqBodyPart = true;
                        Principal.identity(false).then(function(account) {
                            vm.account = account;
                            vm.emailConfirmed = false;
                            vm.email = account.email === null ? account.email : account.email.trim();
                            vm.hasEmail = vm.email;
                            vm.injuredType = account.injuredType;
                            vm.patientInjuredTypes = vm.landingpage.injuredTypes;
                        });


                    } else {

                        $window.localStorage.removeItem("noWait"); // old code (4 year ago), don't know this bussiness

                        if (haqInfo.disposition == true) {
                            if (vm.byPassRestriction) {
                                if (haqInfo.status === 'COMPLETE' && haqInfo.courseRegisterConfirmed === false) {
                                    $state.go('course-registration-workflow');
                                } else {
                                    $state.go('survey-workflow', {id: vm.batteryTestId});
                                }
                                return;
                            }
                            if(haqInfo.status !== 'COMPLETE' && haqInfo.status !== 'INCOMPLETE' && haqInfo.status !== 'NO_RESULT' &&
                                (haqInfo.allowStartNewBattery == null || haqInfo.allowStartNewBattery == true || vm.byPassRestriction)) {
                                // go to  haq workflow

                                $state.go('survey-workflow',{id: vm.batteryTestId});
                            } else {
                                if (haqInfo.status === 'COMPLETE' && haqInfo.courseRegisterConfirmed === false) {
                                    $state.go('course-registration-workflow');
                                    return;
                                } else {
                                    vm.isCompleteHAQ = true;
                                }
                            }
                        } else {
                            vm.isInActiveHAQ = true;
                        }
                    }
                }
            }
        }

        function itemLandingPageClickDownload() {
            if (!vm.isProcessing) {
                clickToDownload();
            }
        }

        function handleHAQBodyPartSubmitSuccess() {
            $state.go('survey-workflow', {id: vm.batteryTestId});
        }

        function isDisplayItemLandingPageForPatientNoNPQ() {
            if (vm.isPatientNoNPQ) {
                return !vm.isDisplayHaqBodyPart;
            }
            return false;
        }

        function isDisplayItemLandingPageForAdhocTesting() {
            if (vm.isAdhocTesting) {
                return !vm.isDisplayHaqBodyPart;
            }
            return false;
        }

        function isAgreementSignedAllByAPI() {
            return vm.landingpage && vm.landingpage.agreementSignedAllByAPI;
        }
    }
})();
