(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('NotificationHistoryController', NotificationHistoryController);

    NotificationHistoryController.$inject = ['$scope', '$state','$stateParams', 'NotificationHistoryService', '$window', 'FormatUtilsService', 'PhoneUtilsService'];

    function NotificationHistoryController ($scope, $state, $stateParams, NotificationHistoryService, $window, FormatUtilsService, PhoneUtilsService) {
        var vm = this;
        vm.notificationHistory = [];
        vm.page = 1;
        vm.totalItems = 0;
        vm.predicate = 'dateSent';
        vm.reverse = false;
        vm.pageSize = 15;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.patientId = $stateParams.patientId ? $stateParams.patientId : null;
        vm.back = back;
        vm.formatHtml = formatHtml;
        vm.formatPhone = formatPhone;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: 6,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            if (vm.patientId) {
                NotificationHistoryService.query(vm.patientId, pageable).then(function (response) {
                    vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                    vm.notificationHistory = response && response.data ? response.data : [];
                });
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function back() {
            $window.history.back();
        }

        function formatHtml(messageContent) {
            return FormatUtilsService.removeHrefHtml(messageContent);
        }

        function formatPhone(phone) {
            return PhoneUtilsService.formatPhone(phone);
        }
    }
})();
