(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('CocAgreementService', CocAgreementService);

        CocAgreementService.$inject = ['$resource', '$http'];

    function CocAgreementService($resource, $http) {
        var resourceUrl = "api/coc-agreement";
        var service = $resource(resourceUrl, {}, {});
        service.sendInvitations = sendInvitations;
        service.getBySecretKey = getBySecretKey;

        function sendInvitations(chartNumbersText) {
            var url = resourceUrl + '/send-invitations'
            return $http.post(url, { chartNumbersText: chartNumbersText }).then(function (resp) {
                return resp.data;
            });
        }

        function getBySecretKey(secretKey) {
            var url = resourceUrl + '/get-by-secret-key'
            return $http.get(url, { params: { secretKey: secretKey }}).then(function (response) {
                return response.data;
            });
        }

        return service;

    }
})();
