(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportPTController', MDEReportPTController);

    MDEReportPTController.$inject = ['$state', '$stateParams', '$uibModal', 'MdeReportService', 'ipmConstants', 'mdeReport', 'templates', 'DataUtils', 'isView', '$window'];

    function MDEReportPTController($state, $stateParams, $uibModal, MdeReportService, ipmConstants, mdeReport, templates, DataUtils, isView, $window) {
        var vm = this;
        vm.mdeReport = mdeReport ? mdeReport : {};
        vm.templates = templates ? templates : [];
        vm.sortGoalAddNewDeletes = [];
        vm.longGoalAddNewDeletes = [];
        vm.isSaving = false;
        vm.isFinalize = false;
        vm.isDisabled = false;
        vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.templateInputType = ipmConstants.FRP_MDE_TEMPLATE;
        vm.isChanged = isChanged;
        vm.isView = isView ? true : vm.mdeReport ? vm.mdeReport.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;
        vm.subjectiveNoneRomTest = ['O1','O2','O3','O4','O5'];
        vm.subjectiveRomTest = ['O6','O7','O8','O9','O10','O11','O12','O13','O14', 'O100'];
        vm.notRequiredObjectives = ['O4','O5'];
        vm.dataHeaderReport = {};
        vm.otherObjectiveDeletes = [];
        vm.reportSubjectiveType = 'subjectives';
        vm.averageCode = 'O40';
        init();

        function init() {
            vm.mdeReportId = $stateParams.id;

            MdeReportService.getMdePtReport($stateParams.id).then(function(data) {
                vm.report = data;
                vm.reportOld = DataUtils.deepCopy(data);
                vm.isFinalize = vm.report && vm.report.finalize;
                vm.updateSelectAllCheckboxSubjective();
                _.forEach(vm.report.objectives, function(o) { o.isCollapsed = true });
            });
            setIsDisabled();
            vm.dataHeaderReport = buildDataHeaderReport(vm.mdeReport.patientFRPMDE);
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    mdeOfficeName: patientFRPMDE.mdeOfficeName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        function setIsDisabled() {
            if (vm.isView || (vm.mdeReport && (vm.mdeReport.status === 'MDE_ABANDONED' || (vm.mdeReport.patientFRPMDE && vm.mdeReport.patientFRPMDE.isViewFrpProgram)))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        vm.calculatorLR = function() {
            vm.report.objectives.forEach(function (objective) {
                if(objective.code === 'O5') {
                    var rightValue = 0;
                    var leftValue = 0;
                    var countR = 0;
                    var countL = 0;
                    var isAllCheck = true;
                    objective.childs.forEach(function (child) {
                        if(child.code != vm.averageCode) {
                            if(child.checked) {
                                    if(child.rightValue && !isNaN(child.rightValue) && Number(child.rightValue) !== 0) {
                                        rightValue += Number(child.rightValue);
                                        countR ++;
                                    }
                                    if(child.leftValue && !isNaN(child.leftValue) && Number(child.leftValue) !== 0) {
                                        leftValue += Number(child.leftValue);
                                        countL ++;
                                    }
                            } else {
                                 isAllCheck = false;
                            }
                        } else {
                            if(isAllCheck) {
                                if(countR != 0) {
                                    child.rightValue = (rightValue/countR).toFixed(2);
                                } else {
                                    child.rightValue = null;
                                }
                                if(countL != 0) {
                                    child.leftValue = (leftValue/countL).toFixed(2);
                                } else {
                                    child.leftValue = null;
                                }
                                child.checked = true;
                            } else {
                                 child.rightValue = null;
                                 child.leftValue = null;
                                 child.checked = false;
                            }
                        }
                    })
                }
            })
        }

        function checkValidation() {
            vm.editForm['subjective_complaints'].$setDirty();
            vm.editForm['physical_functional_narrative'].$setDirty();
            _.forEach(vm.report.subjectives, function(s) {
                if (vm.editForm['subjectiveRow_' + s.code]['score_' + s.code]) {
                    vm.editForm['subjectiveRow_' + s.code]['score_' + s.code].$setDirty();
                }
                if (vm.editForm['subjectiveRow_' + s.code]['severity_' + s.code]) {
                    vm.editForm['subjectiveRow_' + s.code]['severity_' + s.code].$setDirty();
                }
            });
            _.forEach(vm.report.objectives, function(o) {
                _.forEach(o.childs, function(c) {
                    var formKey = 'objectiveRow_' + c.code;
                    var inputKey = 'input_' + c.code;
                    var lefInputKey = 'left_input_' + c.code;
                    var rightInputKey = 'right_input_' + c.code;
                    if (c.type === 'NORMAL_TEXT_FIELD' && vm.editForm[formKey][inputKey]) {
                        vm.editForm[formKey][inputKey].$setDirty();
                    } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD' && vm.editForm[formKey][lefInputKey] && vm.editForm[formKey][rightInputKey]) {
                        vm.editForm[formKey][lefInputKey].$setDirty();
                        vm.editForm[formKey][rightInputKey].$setDirty();
                    }
                });
            });
            _.forEach(vm.report.otherObjectives, function(o) {
                var formKey = 'otherObjectiveRow_' + o.code;
                var inputKey = 'name_' + o.code;
                var inputValue = 'input_' + o.code;
                vm.editForm[formKey][inputKey].$setDirty();
                vm.editForm[formKey][inputValue].$setDirty();
            });
            _.forEach(vm.report.sortTermGoalResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                if (t.type === 'TEXT_FIELD' && vm.editForm[formKey][inputKey]) {
                    vm.editForm[formKey][inputKey].$setDirty();
                } else if (t.type === 'DROP_DOWN' && vm.editForm[formKey][selectKey]) {
                    vm.editForm[formKey][selectKey].$setDirty();
                }
            });
            _.forEach(vm.report.longTermGoalResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                if (t.type === 'TEXT_FIELD' && vm.editForm[formKey][inputKey]) {
                    vm.editForm[formKey][inputKey].$setDirty();
                } else if (t.type === 'DROP_DOWN' && vm.editForm[formKey][selectKey]) {
                    vm.editForm[formKey][selectKey].$setDirty();
                }
            });
            _.forEach(vm.report.sortTermGoalAddNewResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code;
                if (vm.editForm[formKey][inputKey]) {
                    vm.editForm[formKey][inputKey].$setDirty();
                }
            });
            _.forEach(vm.report.longTermGoalAddNewResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code;
                if (vm.editForm[formKey][inputKey]) {
                    vm.editForm[formKey][inputKey].$setDirty();
                }
            });
        }

        vm.save = function() {
            submitData(false);
        }

        vm.finalize = function() {
            checkValidation();
            if (vm.editForm.$invalid || !vm.hasTestingChecked()) {
                return;
            }
            if (vm.isView) {
                return;
            }
            submitData(true);
        }

        function isEqualSubjectives(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var subjectiveOld = v1[idx];
                var subjective = v2[idx];
                isEqual = DataUtils.isEqualBoolean(subjectiveOld.checked, subjective.checked);
                if (!isEqual) return false;
                if (subjectiveOld.checked && subjective.checked) {
                    isEqual = DataUtils.isEqualText(subjectiveOld.score, subjective.score);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(subjectiveOld.severity, subjective.severity);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(subjectiveOld.notes, subjective.notes);
                    if (!isEqual) return false;
                }
            }
            return isEqual;
        }

        function isEqualObjectives(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var objectiveOld = v1[idx];
                var objective = v2[idx];
                for (var index = 0; index < objectiveOld.childs.length; index++) {
                    var childOld = objectiveOld.childs[index];
                    var child = objective.childs[index];
                    isEqual = DataUtils.isEqualBoolean(childOld.checked, child.checked);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(childOld.notes, child.notes);
                    if (!isEqual) return false;
                    if (childOld.checked && child.checked) {
                        if (childOld.type === 'NORMAL_TEXT_FIELD') {
                            isEqual = DataUtils.isEqualText(childOld.value, child.value);
                            if (!isEqual) return false;
                        } else if (childOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                            isEqual = DataUtils.isEqualText(childOld.leftValue, child.leftValue);
                            if (!isEqual) return false;
                            isEqual = DataUtils.isEqualText(childOld.rightValue, child.rightValue);
                            if (!isEqual) return false;
                        }
                    }
                }
            }
            return isEqual;
        }

        function isEqualOtherObjectives(v1, v2) {
            var isEqual = true;
            if (v1.length != v2.length) {
                return false;
            }
            for (var idx = 0; idx < v1.length; idx++) {
                var otherObjectiveOld = v1[idx];
                var otherObjective = v2[idx];
                isEqual = DataUtils.isEqualBoolean(otherObjectiveOld.checked, otherObjective.checked);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(otherObjectiveOld.name, otherObjective.name);
                if (!isEqual) return false;
                if (otherObjectiveOld.checked && otherObjective.checked) {
                    if (otherObjectiveOld.type === 'NORMAL_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(otherObjectiveOld.value, otherObjective.value);
                        if (!isEqual) return false;
                    } else if (otherObjectiveOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(otherObjectiveOld.leftValue, otherObjective.leftValue);
                        if (!isEqual) return false;
                        isEqual = DataUtils.isEqualText(otherObjectiveOld.rightValue, otherObjective.rightValue);
                        if (!isEqual) return false;
                    }
                }
            }
            return isEqual;
        }
        function isEqualPhysicalDemandLevels(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var physicalDemandLevelOld = v1[idx];
                var physicalDemandLevel = v2[idx];
                isEqual = DataUtils.isEqualText(physicalDemandLevelOld.value, physicalDemandLevel.value);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(physicalDemandLevelOld.note, physicalDemandLevel.note);
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function isExistTermGoalNew(sortTermGoalAddNew, longTermGoalAddNew) {
            var sortTermGoalNew = _.find(sortTermGoalAddNew, function(s) { return s.id === null || s.id === undefined; });
            var longTermGoalNew = _.find(longTermGoalAddNew, function(l) { return l.id === null || l.id === undefined; });
            return sortTermGoalNew || longTermGoalNew;
        }

        function isExistTermGoalDeleted() {
            return vm.sortGoalAddNewDeletes.length > 0 || vm.longGoalAddNewDeletes.length > 0;
        }

        function isEqualTermGoal(v1, v2, isNewTermGoal) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var termGoalOld = v1[idx];
                var termGoal = v2[idx];
                isEqual = DataUtils.isEqualBoolean(termGoalOld.checked, termGoal.checked);
                if (!isEqual) return false;
                if (isNewTermGoal) {
                    isEqual = DataUtils.isEqualText(termGoalOld.goal, termGoal.goal);
                }
                if (!isEqual) return false;
                if (isNewTermGoal && termGoalOld.checked && termGoal.checked) {
                    isEqual = DataUtils.isEqualText(termGoalOld.inputValue, termGoal.inputValue);
                }
                if (!isEqual) return false;
                if (termGoalOld.type === 'TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(termGoalOld.value, termGoal.value);
                } else if (termGoalOld.type === 'DROP_DOWN') {
                    isEqual = DataUtils.isEqualText(termGoalOld.selectedValue, termGoal.selectedValue);
                }
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function isChanged() {
            var isEqual = true;
            // Subjective Complaints
            isEqual = DataUtils.isEqualText(vm.reportOld.subjectiveComplaints, vm.report.subjectiveComplaints);
            if (!isEqual) return true;
            // Physical Functional Narrative
            isEqual = DataUtils.isEqualText(vm.reportOld.physicalFunctionalNarrative, vm.report.physicalFunctionalNarrative);
            if (!isEqual) return true;
            // Subjectives
            isEqual = isEqualSubjectives(vm.reportOld.subjectives, vm.report.subjectives);
            if (!isEqual) return true;
            // Objectives
            isEqual = isEqualObjectives(vm.reportOld.objectives, vm.report.objectives);
            if (!isEqual) return true;
            if (!_.isEmpty(vm.reportOld.otherObjectives) || !_.isEmpty(vm.report.otherObjectives)) {
                isEqual = isEqualOtherObjectives(vm.reportOld.otherObjectives, vm.report.otherObjectives);
                if (!isEqual) return true;
            }
            // Physical Demand Levels
            isEqual = isEqualPhysicalDemandLevels(vm.reportOld.physicalDemandLevels, vm.report.physicalDemandLevels);
            if (!isEqual) return true;
            // Is exists Term Goal Add New
            var isChangeTermGoal = isExistTermGoalNew(vm.report.sortTermGoalAddNewResponses, vm.report.longTermGoalAddNewResponses);
            if (isChangeTermGoal) return true;
            // Is exists Term Goal Deleted
            isChangeTermGoal = isExistTermGoalDeleted();
            if (isChangeTermGoal) return true;
            // Term Goal
            isEqual = isEqualTermGoal(vm.reportOld.sortTermGoalResponses, vm.report.sortTermGoalResponses, false);
            if (!isEqual) return true;
            isEqual = isEqualTermGoal(vm.reportOld.longTermGoalResponses, vm.report.longTermGoalResponses, false);
            if (!isEqual) return true;
            // Term Goal Add New
            isEqual = isEqualTermGoal(vm.reportOld.sortTermGoalAddNewResponses, vm.report.sortTermGoalAddNewResponses, true);
            if (!isEqual) return true;
            isEqual = isEqualTermGoal(vm.reportOld.longTermGoalAddNewResponses, vm.report.longTermGoalAddNewResponses, true);
            if (!isEqual) return true;
            return !isEqual;
        }

        function back() {
            $state.go('mde-report', {id: vm.mdeReportId});
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        function submitData(finalize) {
            if (finalize) {
                checkValidation();
                if (vm.editForm.$invalid || !vm.hasTestingChecked()) {
                    return;
                }
            }
            if (vm.isView) {
                return;
            }

            vm.isSaving = true;
            vm.report.finalize = finalize;
            prepairDataForSave();
            MdeReportService.saveMdePtReport(vm.mdeReportId, vm.report).then(function (response) {
                onSaveSuccess(response);
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error && (error.status === 409 || error.status === 400)) {
                vm.error = 'ERROR';
                openErrorDuplicateDialog();
            }
        }

        function prepairDataForSave() {
            // Feedback before the Demo - June 09, 2022 - Feedback #22
            // goal-add-new rule
            // new, checked, not-empty        -> save text and checked state
            // new, checked, empty            -> form invalid
            // new, uncheck, not-empty        -> save text and uncheck state
            // new, uncheck, empty            -> not save
            // old, checked, not-empty        -> save text and checked state
            // old, checked, empty            -> form invalid
            // old, uncheck, not-empty        -> save text and uncheck state
            // old, uncheck, empty            -> term-goal delete (database update to inactive)

            // filter new, checked/checked, not-empty sort-term-goal
            vm.report.sortTermGoalAddNewResponses = _.filter(vm.report.sortTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // filter new, checked, not-empty long-term-goal
            vm.report.longTermGoalAddNewResponses = _.filter(vm.report.longTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // delete old, uncheck, empty sort-term-goal
            _.forEach(vm.report.sortTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            // delete old, uncheck, empty long-term-goal
            _.forEach(vm.report.longTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });

            _.forEach(vm.report.otherObjectives, function(other) {
                if (!other.checked) {
                    other.checked = false;
                }
                if (!other.checked && !other.name) {
                    other.active = false;
                }
            });
            // merge add goals add new list
            vm.report.sortTermGoalAddNewResponses.push.apply(vm.report.sortTermGoalAddNewResponses, vm.sortGoalAddNewDeletes);
            vm.report.longTermGoalAddNewResponses.push.apply(vm.report.longTermGoalAddNewResponses, vm.longGoalAddNewDeletes);
            vm.report.otherObjectives.push.apply(vm.report.otherObjectives, vm.otherObjectiveDeletes);

            // check Template content changed
            if (vm.report) {
                if (vm.report.subjectiveComplaintsTemplate && vm.report.subjectiveComplaintsTemplate.content != vm.report.subjectiveComplaints) {
                    vm.report.subjectiveComplaintsTemplate = null;
                }
            }
        }

        vm.hasItemChecked = function (listItem) {
            if (listItem) {
                return !_.isEmpty(_.filter(listItem, function (s) { return s.checked; }))
            }
            return false;
        }

        vm.hasItemCheckedInvalid = function(listItem) {
            for (var index = 0; index < listItem.length; index++) {
                var s = listItem[index];
                if (s.checked) {
                    var controlScore = vm.editForm['subjectiveRow_' + s.code]['score_' + s.code];
                    if (controlScore.$dirty && controlScore.$error.required) return true;
                    var controlSeverity = vm.editForm['subjectiveRow_' + s.code]['severity_' + s.code];
                    if (controlSeverity.$dirty && controlSeverity.$error.required) return true;
                }
            }
        }

        vm.hasObjectiveChecked = function() {
            var isChecked = false;
            for (var index = 0; index < vm.report.objectives.length; index++) {
                isChecked = vm.hasChildChecked(vm.report.objectives[index]);
                if (!isChecked) return false;
            }
            return isChecked;
        }

        vm.hasOtherObjectiveChecked = function() {
            if (_.isEmpty(vm.report.otherObjectives)) {
                return true;
            }
            var isChecked = false;
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                isChecked = vm.hasChildChecked(vm.report.otherObjectives[index]);
                if (!isChecked) return false;
            }
            return isChecked;
        }

        vm.hasObjectiveCheckedInvalid = function() {
            for (var index = 0; index < vm.report.objectives.length; index++) {
                var o = vm.report.objectives[index];
                var invalid = vm.hasChildCheckedInvalid(o);
                if (invalid) return true;
            }
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var o = vm.report.otherObjectives[index];
                var invalid = vm.hasOtherCheckedInvalid(o);
                if (invalid) return true;
            }
            return false;
        }

        vm.hasChildCheckedNotCheckRom = function(objective) {
            if (objective) {
                return !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; })) || !_.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked && o.typeReportId === objective.typeReportId; }))
            }
            return false;
        }

        vm.hasChildChecked = function(objective) {
            if (vm.notRequiredObjectives.includes(objective.code)) return true;
            if (objective) {
                if (vm.subjectiveNoneRomTest.includes(objective.code)) {
                    return !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; })) || !_.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked && o.typeReportId === objective.typeReportId; }))
                } else if (vm.subjectiveRomTest.includes(objective.code)) {
                    return vm.hasRomTestingChecked();
                }
            }
            return false;
        }

        vm.hasChildCheckedInvalid = function(objective) {
            for (var index = 0; index < objective.childs.length; index++) {
                var c = objective.childs[index];
                if (c.checked) {
                    var formKey = 'objectiveRow_' + c.code;
                    var inputKey = 'input_' + c.code;
                    var lefInputKey = 'left_input_' + c.code;
                    var rightInputKey = 'right_input_' + c.code;
                    if (c.type === 'NORMAL_TEXT_FIELD') {
                        var control = vm.editForm[formKey][inputKey];
                        if (control.$dirty && control.$error.required) return true;
                    } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        var controlLeft = vm.editForm[formKey][lefInputKey];
                        var controlRight = vm.editForm[formKey][rightInputKey];
                        if (controlLeft.$dirty && controlLeft.$error.required) return true;
                        if (controlRight.$dirty && controlRight.$error.required) return true;
                    }
                }
            }
            return false;
        }

        vm.hasOtherCheckedInvalid = function(objective) {
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var otherObjective = vm.report.otherObjectives[index];
                if (otherObjective.checked && getParentCode(otherObjective.code) === objective.code) {
                    var formKey = 'otherObjectiveRow_' + otherObjective.code;
                    var inputKey = 'name_' + otherObjective.code;
                    var inputValue = 'input_' + otherObjective.code;
                    var controlName = vm.editForm[formKey][inputKey];
                    var controlValue = vm.editForm[formKey][inputValue];
                    if (controlName && controlName.$dirty && controlName.$error.required)
                        return true;
                    if (controlValue && controlValue.$dirty && controlValue.$error.required)
                        return true;
                }
            }
            return false;
        }

        vm.hasRomTestingChecked = function() {
            for (var index = 0; index < vm.report.objectives.length; index++) {
                var objective = vm.report.objectives[index];
                if (vm.subjectiveRomTest.includes(objective.code)) {
                    var isChecked = !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; }))
                    if (isChecked) return true;
                }
            }
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var objective = vm.report.otherObjectives[index];
                if (vm.subjectiveRomTest.includes(getParentCode(objective.code))) {
                    var isChecked = objective.checked;
                    if (isChecked) return true;
                }
            }
            return false;
        }

        vm.isChildRequired = function(objective) {
            if (objective) {
                if (vm.subjectiveNoneRomTest.includes(objective.code)) {
                    return !(vm.notRequiredObjectives.includes(objective.code));
                } else if (vm.subjectiveRomTest.includes(objective.code)) {
                    if (vm.hasRomTestingChecked()) {
                        return vm.hasChildCheckedNotCheckRom(objective);
                    } else {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.hasTermGoalChecked = function(isSort) {
            var termGoalChecked;
            var termGoalAddNewChecked
            if (isSort) {
                // At least 1 Short Term Goal is required
                termGoalChecked = vm.hasItemChecked(vm.report.sortTermGoalResponses);
                termGoalAddNewChecked = vm.hasItemChecked(vm.report.sortTermGoalAddNewResponses);
            } else {
                // At least 1 Long Term Goal is required
                termGoalChecked = vm.hasItemChecked(vm.report.longTermGoalResponses);
                termGoalAddNewChecked = vm.hasItemChecked(vm.report.longTermGoalAddNewResponses);
            }
            if (!termGoalChecked && !termGoalAddNewChecked) return false;
            return true;
        }

        vm.hasTermGoalCheckedInvalid = function(isSort) {
            var termGoalCheckedInvalid;
            var termGoalAddNewCheckedInvalid;
            if (isSort) {
                termGoalCheckedInvalid = isTermGoalCheckedInvalid(vm.report.sortTermGoalResponses);
                termGoalAddNewCheckedInvalid = isTermGoalAddNewCheckedInvalid(vm.report.sortTermGoalAddNewResponses);
            } else {
                termGoalCheckedInvalid = isTermGoalCheckedInvalid(vm.report.longTermGoalResponses);
                termGoalAddNewCheckedInvalid = isTermGoalAddNewCheckedInvalid(vm.report.longTermGoalAddNewResponses);
            }
            if (termGoalCheckedInvalid || termGoalAddNewCheckedInvalid) return true;
            return false;
        }

        function isTermGoalCheckedInvalid(items) {
            for (var index = 0; index < items.length; index++) {
                var t = items[index];
                if (t.checked) {
                    var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                    var control;
                    if (t.type === 'TEXT_FIELD') {
                        var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                        control = vm.editForm[formKey][inputKey];
                        if (control.$dirty && control.$error.required) return true;
                    } else if (t.type === 'DROP_DOWN') {
                        var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                        control = vm.editForm[formKey][selectKey];
                        if (control.$dirty && control.$error.required) return true;
                    }
                }
            }
            return false;
        }

        function isTermGoalAddNewCheckedInvalid(items) {
            for (var index = 0; index < items.length; index++) {
                var t = items[index];
                if (t.checked) {
                    var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                    var inputKey = 'input_m_' + t.code;
                    var control = vm.editForm[formKey][inputKey];
                    if (control.$dirty && control.$error.required) return true;
                }
            }
            return false;
        }

        vm.hasTestingChecked = function() {
            // At least 1 Subjective Test is required
            var isChecked = vm.hasItemChecked(vm.report.subjectives);
            if (!isChecked) return false;
            // At least 1 Activity Test of Objective Test is required
            isChecked = vm.hasObjectiveChecked();
            if (!isChecked) return false;
            // At least 1 Short Term Goal is required
            isChecked = vm.hasTermGoalChecked(true);
            if (!isChecked) return false;
            // At least 1 Long Term Goal is required
            isChecked = vm.hasTermGoalChecked(false);
            if (!isChecked) return false;
            return true;
        }

        vm.addNewSortTermGoal = function() {
            var orderNumber = _.isEmpty(vm.report.sortTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.sortTermGoalAddNewResponses, function (o) { return o.orderNumber } ).orderNumber + 1;
            var code = 'STG' + orderNumber;
            var newGoal = {code: code, orderNumber: orderNumber, active: true, goalType: 'SORT_TERM'};
            vm.report.sortTermGoalAddNewResponses.push(newGoal);
        }

        vm.addNewLongTermGoal = function() {
            var orderNumber = _.isEmpty(vm.report.longTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.longTermGoalAddNewResponses, function (o) { return o.orderNumber } ).orderNumber + 1;
            var code = 'LTG' + orderNumber;
            var newGoal = {code: code, orderNumber: orderNumber, active: true, goalType: 'LONG_TERM'};
            vm.report.longTermGoalAddNewResponses.push(newGoal);
        }

        vm.deleteSortTermGoalAddNew = function(deleteItem) {

            _.remove(vm.report.sortTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            vm.sortGoalAddNewDeletes.push(deleteItem);

        }

        vm.deleteLongTermGoalAddNew = function(deleteItem) {

            _.remove(vm.report.longTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            vm.longGoalAddNewDeletes.push(deleteItem);

        }

        vm.showAddGoal = function($last, isLongTerm) {
            // filter active Goal
            var addNewItem;
            if (isLongTerm) {
                addNewItem = _.filter(vm.report.longTermGoalAddNewResponses, function (i) { return i.active; });
            } else {
                addNewItem = _.filter(vm.report.sortTermGoalAddNewResponses, function (i) { return i.active; });
            }
            return $last && _.isEmpty(addNewItem);
        }

        vm.addOtherObjective = function(objective) {
            var orderNumber = 0;
            _.forEach(vm.report.otherObjectives, function(otherObjective) {
                if (otherObjective.typeReportId == objective.typeReportId && orderNumber < otherObjective.orderNumber) {
                    orderNumber = otherObjective.orderNumber;
                }
            });
            orderNumber++;
            var code = objective.code + '_O' + orderNumber;
            var copyObjective = DataUtils.deepCopy(objective.childs[0]);
            copyObjective.name = '';
            copyObjective.orderNumber = orderNumber;
            copyObjective.code = code;
            copyObjective.type = 'NORMAL_TEXT_FIELD';
            copyObjective.checked = true;
            copyObjective.typeReportId = objective.typeReportId;
            copyObjective.id = null;
            copyObjective.active = true;
            copyObjective.value = '';
            copyObjective.rightValue = '';
            copyObjective.leftValue = '';

            vm.report.otherObjectives.push(copyObjective);
        }

        vm.deleteOtherObjectives = function(deleteItem) {
            _.remove(vm.report.otherObjectives, function (o) { return o.code === deleteItem.code; });

            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            vm.otherObjectiveDeletes.push(deleteItem);
        }

        function getParentCode(code) {
            return code.split('_')[0];
        }

        vm.updateSelectAllCheckbox = function(objective) {
            var isCheckAll = true;
            objective.childs.forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })

             vm.report.otherObjectives.forEach(function (other){
                if(other.typeReportId === objective.typeReportId) {
                    if(!other.checked) {
                        isCheckAll = false;
                        return;
                    }
                }
            })
            objective.checked = isCheckAll;
        }

        vm.updateSelectAllCheckboxSubjective = function() {
            var isCheckAll = true;
            vm.report.subjectives.forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })

            vm.report[vm.reportSubjectiveType + 'Checked'] = isCheckAll;
        }

        function openErrorDuplicateDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.mdeReport.error.saveReportDuplicateMessage"},
                    title: function () {return "ipmApp.mdeReport.error.saveReportDuplicateTitle"}
                }
            }).result.then(function() {
                $window.location.reload(); // reload page to load new data MDE
            }, function() {
            });
        }
    }
})();
