(function() {
    'use strict';

    SubjectiveRowController.$inject = ['ipmConstants'];

    function SubjectiveRowController(ipmConstants) {
        var vm = this; //jshint ignore:line
        vm.severity = ipmConstants.SEVERITY;
        init();

        function init() {
        }

        vm.checkboxChange = function() {
            // clear value;
            vm.item.score = null;
            vm.item.severity = null;
            vm.item.notes = null;
            vm.onChange();
        }
    }

    angular.module('ipmApp').component('subjectiveRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/subjective-row.html',
        controller: SubjectiveRowController,
        controllerAs: 'vm'
    })
})();
