(function () {
    'use strict';


    TracksAssignedsListController.$inject = ['$scope', '$state', '$window', 'Principal', 'ipmConstants', 'HaqTracksService', '$uibModal'];

    function TracksAssignedsListController($scope, $state, $window, Principal, constants, HaqTracksService, $uibModal) {
        var vm = this;//jshint ignore:line
        vm.batteries = []; // list batteries is Original, not change data
        vm.unassignedBatteries = [];
        vm.tracksAssignedsOriginal = [];
        vm.trackAssignedBatteryEvent = trackAssignedBatteryEvent;
        vm.assignBatteryClick = assignBatteryClick;
        vm.unassignBatteryClick = unassignBatteryClick;
        vm.sortableOptions = {
            placeholder: "ui-state-highlight",
            helper: function (event, ui) {
                var table = '<table style="position: absolute;" class="jh-table table table-striped"></table>';
                var draggedItem = $(table).append(ui[0].outerHTML);
                return draggedItem;
            },
            update: function (e, ui) {

            },
            stop: function (e, ui) {
                var tracksAssigneds = ui.item.sortable.sourceModel;
                tracksAssigneds.forEach(function (tracksAssigned, index) {
                    tracksAssigned.orderNumber = (index + 1);
                });
            }
        };

        init();

        function init() {
            vm.isAdHoc = vm.isAdHoc ? vm.isAdHoc : false;
            vm.tracksAssigneds = vm.tracksAssigneds ? _.orderBy(vm.tracksAssigneds, ['orderNumber'], ['esc']) : [];
            vm.tracksAssignedsOriginal = _.cloneDeep(vm.tracksAssigneds); // init clone data tracksAssigneds
            initData();
        }

        function initData() {
            HaqTracksService.getAllBatteryDefault().then(function (response) {
                vm.batteries = response ? response : [];
                if (!vm.tracksAssigneds || vm.tracksAssigneds.length == 0) {
                    vm.unassignedBatteries = _.cloneDeep(vm.batteries);
                } else {
                    vm.unassignedBatteries = _.filter(vm.batteries, function (item) {
                        return findExitsBatteriesInTrackAssign(item) == -1;
                    });
                }
            });
        }

        /**
         *
         * @param battery
         * @returns {number} -1 not found
         */
        function findExitsBatteriesInTrackAssign(battery) {
            return _.findIndex(vm.tracksAssigneds, function (o) {
                return o && battery && o.batteryId == battery.id;
            });
        }

        function assignBatteryClick(battery) {
            if (vm.isAdHoc) {
                return;
            }
            if (battery) {
                var indextracksAssigneds = _.findIndex(vm.tracksAssignedsOriginal, function (o) {
                    return o && o.batteryId == battery.id;
                });
                var haqTracksAssignedTemp;
                if (indextracksAssigneds != -1) { // find battery in tracksAssignedsOriginal if exists add from tracksAssignedsOriginal else add new HAQTracksAssigned
                    haqTracksAssignedTemp = vm.tracksAssignedsOriginal.slice(indextracksAssigneds, indextracksAssigneds + 1)[0];
                    haqTracksAssignedTemp.orderNumber = null;
                } else { // add new HAQTracksAssigned
                    haqTracksAssignedTemp = {
                        id: null,
                        batteryName: battery.nameEn,
                        batteryAssignStatus: "ASSIGNED",
                        orderNumber: battery.orderNumber ? battery.orderNumber : vm.tracksAssigneds.length + 1,
                        batteryId: battery && battery.id ? battery.id : null,
                        battery: battery
                    };
                }
                trackAssignedBatteryEvent(haqTracksAssignedTemp);
            }
        }

        function trackAssignedBatteryEvent(trackAssigned) {
            vm.tracksAssigneds.push(trackAssigned);
            tracksAssignedEmit();
            unassignedBatteries(trackAssigned.batteryId);
            reOrderNumberTracksAssigned();
        }

        function unassignedBatteries(batteryId) {
            _.remove(vm.unassignedBatteries, function (n) {
                return batteryId && n.id === batteryId;
            });
        }

        function tracksAssignedEmit() {
            vm.onChange({tracksAssigneds: vm.tracksAssigneds ? vm.tracksAssigneds : []});
        }

        function unassignBatteryClick(trackAssigned) {
            if (vm.isAdHoc) {
                return;
            }
            if (trackAssigned) {
                var index = _.findIndex(vm.tracksAssigneds, function (n) {
                    return trackAssigned.batteryId && n.batteryId === trackAssigned.batteryId;
                });
                if (index != -1) { // trackAssigned is exists, update trackAssigned
                    // remove tracksAssigneds
                    vm.tracksAssigneds.splice(index, 1);

                    // add to list unassignedBatteries
                    var unassignedBatteriesExist = _.filter(vm.batteries, function (o) {
                        return o.id && o.id === trackAssigned.batteryId;
                    });
                    if (unassignedBatteriesExist && unassignedBatteriesExist.length > 0) {
                        vm.unassignedBatteries.push(unassignedBatteriesExist[0]);
                    }

                }
                reOrderNumberTracksAssigned();
            }
        }

        function reOrderNumberTracksAssigned() {
            vm.tracksAssigneds.forEach(function (tracksAssigned, index) {
                tracksAssigned.orderNumber = (index + 1);
            });
        }
    }

    angular.module('ipmApp').component('tracksAssignedsList', {
        bindings: {
            tracksAssigneds: '<',
            isAdHoc: '<',
            onChange: '&'
        },
        templateUrl: 'app/entities/haq-tracks/tracks-assigneds/tracks-assigneds-list.html',
        controller: TracksAssignedsListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
})();
