(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('InputPatientCaseDialogController', InputPatientCaseDialogController);

    InputPatientCaseDialogController.$inject = ['$uibModalInstance', '$uibModal', 'PatientService', '$q', 'SurveyResultService', 'surveyResultId', 'patientId'];

    function InputPatientCaseDialogController($uibModalInstance, $uibModal, PatientService, $q, SurveyResultService, surveyResultId, patientId) {
        var vm = this;

        // Variables
        vm.searchResultEle = null;
        vm.patientId = patientId || null;
        vm.patientCases = [];
        vm.patientCaseSelect = null;
        vm.searchText = '';
        vm.predicate = 'id';
        vm.pageSize = 15;
        vm.loadedData = false;

        vm.modalLoaded = modalLoaded;
        vm.clear = clear;
        vm.clickOk = clickOk;
        vm.checkValidInputCase = checkValidInputCase;
        vm.searchPatientCase = searchPatientCase;
        vm.checkCaseHasBeenOptedOut = checkCaseHasBeenOptedOut;
        vm.updateSearchText = updateSearchText;

        // Initialize the controller
        init();

        function init() {
            searchPatientCase();
        }


        function modalLoaded() {
            vm.searchResultEle = angular.element("#searchEnrollPatientAutoComplete");
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function clickOk() {
            var haqTestServiceExclusion = vm.patientCaseSelect && vm.patientCaseSelect.billableConfig && vm.patientCaseSelect.billableConfig.haqTestServiceExclusion ? vm.patientCaseSelect.billableConfig.haqTestServiceExclusion : false;
            // IPMproject-Q627: We are going to remove the WHOLE "override" process. So if a HAQ_test flag is turned on
            // if (vm.searchText && vm.patientCaseSelect && vm.patientCaseSelect.id && haqTestServiceExclusion) {
            //     openDialogOptedOutHAQTest();
            // } else
            if (vm.patientCaseSelect && !haqTestServiceExclusion) { // save CaseSelected && continue
                saveCaseSelected();
            } else { // not selected case
                openConfirmDialog();
            }
        }

        function checkValidInputCase() {
            if (vm.patientCaseSelect) {
                return vm.patientCaseSelect.id
                    && vm.patientCaseSelect.billableConfig
                    && !vm.patientCaseSelect.billableConfig.haqTestServiceExclusion;
            }
            return true;
        }

        function checkCaseHasBeenOptedOut() {
            return vm.patientCaseSelect && vm.patientCaseSelect.id
                && vm.patientCaseSelect.billableConfig
                && vm.patientCaseSelect.billableConfig.haqTestServiceExclusion;
        }

        function openConfirmDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/confirm-dialog/confirm-yes-no-dialog.controller.html',
                controller: 'ConfirmYesNoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    title: function () {
                        return '';
                    },
                    isTranslate: function () {
                        return true;
                    },
                    message: function () {
                        return 'ipmApp.patient.patientCase.messageNotSelectedACase';
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    saveCaseSelected(null);
                }
            });
        }

        // We are going to remove the WHOLE "override" process. So if a HAQ_test flag is turned on
        /*function openDialogOptedOutHAQTest() {
            $uibModal.open({
                templateUrl: 'app/patient/input-patient-case-dialog/opted-out-haq-test-dialog.html',
                controller: 'OptedOutHAQTestDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    patientId: function () {
                        return patientId;
                    },
                    patientCase: function () {
                        return vm.patientCaseSelect;
                    }
                }
            }).result.then(function (result) {
                if (result && result.patientCase && result.exclusionReason) { // result = patientExclusionReason
                    saveCaseSelected(result);
                }
            });
        }*/

        function saveCaseSelected(patientExclusionReason) {
            vm.isSaving = true;
            var patientCaseRequest = {
                id: vm.patientCaseSelect ? vm.patientCaseSelect.id : null,
                surveyResultId: surveyResultId,
                patientExclusionReason: patientExclusionReason ? patientExclusionReason : null
            };
            SurveyResultService.savePatientCaseSelected(surveyResultId, patientCaseRequest)
                .then(onSaveSuccess)
                .catch(onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
            vm.clear();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            $uibModalInstance.close(error);
        }

        vm.searchTextOnChange = function () {
            vm.searchText = null;
        };

        vm.clearPatientCaseSelection = function() {
            if (vm.patientCaseSelect) {
                vm.patientCaseSelect = null;
            }
        };

        function updateSearchText(searchText) {
            vm.searchText = searchText ? searchText : null;
        }

        function searchPatientCase($item) {
            vm.searchText = $item ? $item : '';
            var pageable = {
                page: 0,
                size: vm.pageSize,
                sort: sort()
            };

            return $q(function (resolve, reject) {
                PatientService.searchPatientCase(patientId, vm.searchText, false, pageable)
                    .then(function (response) {
                        if (response && response.length > 0) {
                            resolve(response);
                            vm.patientCases = response;
                        } else {
                            resolve([]);
                        }
                        vm.loadedData = true;
                    }, function () {
                        reject([]);
                    });
            });
        }

        function sort() {
            var result = [vm.predicate + ',desc'];
            return result;
        }
    }
})();
