(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('question-option', {
            parent: 'entity',
            url: '/question-option',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.questionOption.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/question-option/question-options.html',
                    controller: 'QuestionOptionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('questionOption');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('question-option-detail', {
            parent: 'entity',
            url: '/question-option/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.questionOption.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/question-option/question-option-detail.html',
                    controller: 'QuestionOptionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('questionOption');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'QuestionOption', function($stateParams, QuestionOption) {
                    return QuestionOption.get({id : $stateParams.id});
                }]
            }
        })
        .state('question-option.new', {
            parent: 'question-option',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question-option/question-option-dialog.html',
                    controller: 'QuestionOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nameEn: null,
                                nameEs: null,
                                orderNumber: null,
                                value: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('question-option', null, { reload: true });
                }, function() {
                    $state.go('question-option');
                });
            }]
        })
        .state('question-option.edit', {
            parent: 'question-option',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question-option/question-option-dialog.html',
                    controller: 'QuestionOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['QuestionOption', function(QuestionOption) {
                            return QuestionOption.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('question-option', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('question-option.delete', {
            parent: 'question-option',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/question-option/question-option-delete-dialog.html',
                    controller: 'QuestionOptionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['QuestionOption', function(QuestionOption) {
                            return QuestionOption.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('question-option', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
