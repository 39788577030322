(function (){
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig)

    stateConfig.$inject = ['$stateProvider'];


    function stateConfig($stateProvider) {
        $stateProvider.state('presentation-view-patient', {
            url: '/presentation-view/{csId}',
            data: {
                authorities: ['ROLE_ADMIN','ROLE_STAFF', 'ROLE_MANAGER', 'ROLE_QHP'],
                pageTitle: 'ipmApp.patient.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/presentation-controls-instructor/presentation-view-patient/presentation-view-patient.html',
                    controller: 'PresentationViewPatientController',
                    controllerAs: 'vm'
                },
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('presentationControls');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
            }
        })
    }
})();
