
(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientRequestDialogController', PatientRequestDialogController);

    PatientRequestDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance','entity', 'NPQQuestionnaireService', 'PatientService', '$q'];

    function PatientRequestDialogController ($stateParams, $state, $uibModalInstance, entity, NPQQuestionnaireService, PatientService, $q) {
        var vm = this;
        vm.npqQuestionnaire = entity;
        vm.npqQuestionnaire.patientId = null;
        vm.notAllowCreateQuestionnaire = false;
        vm.isSaving = false;
        vm.patientCases = [];
        vm.patientCaseSelect = null;
        vm.patientCaseNoCaseNumber = {id: null, caseNumber: "No Case Number"};
        vm.predicate = 'id';
        vm.pageSize = 20;
        vm.patientId = $stateParams.id;

        vm.clear = clear;
        vm.saveNPQQuestionnaire = saveNPQQuestionnaire;
        vm.searchPatientCase = searchPatientCase;

        init();

        function init() {
            searchPatientCase();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function saveNPQQuestionnaire(){
            vm.isSaving = true;
            vm.npqQuestionnaire.patientId = $stateParams.id;
            vm.npqQuestionnaire.patientCase = vm.patientCaseSelect ? vm.patientCaseSelect : null;
            NPQQuestionnaireService.saveNPQQuestionnaire(vm.npqQuestionnaire).then(function(res) {
                onSaveSuccess(res);
            }, function errorSave(error) {
                onSaveError(error);
            });
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (error) {
            var errorMessage = error.headers('X-ipmApp-error-message');
            if(error.status === 400 ) {
                if (errorMessage === 'notAllowCreateQuestionnaire'){
                    $uibModalInstance.close({notAllowCreateQuestionnaire:true});
                    vm.isSaving = false;
                }
            }
        }

        function sort() {
            var result = [vm.predicate + ',desc'];
            return result;
        }

        function searchPatientCase($item) {
            vm.searchText = $item ? $item : '';
            var pageable = {
                page: 0,
                size: vm.pageSize,
                sort: sort()
            };
            return $q(function (resolve, reject) {
                PatientService.searchPatientCase(vm.patientId, vm.searchText, false, pageable)
                    .then(function (response) {
                        if (response && response.length > 0) {
                            resolve(response);
                            vm.patientCases = response;
                            vm.patientCases.unshift(vm.patientCaseNoCaseNumber);
                        } else {
                            vm.patientCases.push(vm.patientCaseNoCaseNumber);
                            resolve([]);
                        }
                    }, function () {
                        reject([]);
                    });
            });
        }

        vm.checkDisableSave = function () {
            return !vm.patientCaseSelect || !vm.patientCaseSelect.caseNumber;
        };

        vm.searchTextOnChange = function () {
        };

    }
})();
