(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Course', Course);

    Course.$inject = ['$resource', '$q', 'halClient', '$http'];

    function Course ($resource, $q, halClient, $http) {
        var baseUrl = 'api/courses';
        var resourceUrl =  'api/courses/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
        service.findAllByActive = findAllByActive;
        service.findAllCoursesRegistrationsByPatient = findAllCoursesRegistrationsByPatient;

        function findAllByActive() {
            var deferred = $q.defer();

            var url = baseUrl + '/find-all-by-active';
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function findAllCoursesRegistrationsByPatient(patientId) {
            var deferred = $q.defer();

            var url = baseUrl + '/find-all-courses-registrations/' + patientId;
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        return service;
    }
})();
