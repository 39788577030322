(function () {
    'use strict';


    PatientSummaryController.$inject = ['$scope', 'PatientService'];

    function PatientSummaryController($scope, PatientService) {
        var vm = this; //jshint ignore:line
        vm.patient = null;

        init();

        function init() {
            PatientService.get(vm.patientId).then(function (response) {
                vm.patient = response ? response : null;
            });
        }
    }

    angular.module('ipmApp').component('patientSummary', {
        bindings: {
            patientId: '<'
        },
        templateUrl: 'app/notification-history/patient-summary/patient-summary.html',
        controller: PatientSummaryController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                $translatePartialLoader.addPart('surveyResult');
                return $translate.refresh();
            }]
        }
    });
})();
