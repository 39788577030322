(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AgreementsController', AgreementsController);

    AgreementsController.$inject = ['$scope', 'AgreementsWorkflowService', '$state', 'ipmConstants'];

    function AgreementsController($scope, AgreementsWorkflowService, $state, constants) {
        var vm = this;

        vm.pageSize = 10;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'position';
        vm.reverse = false;
        vm.agreements = [];
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.download = download;
        vm.back = back;
        vm.isShowDownloadAll = isShowDownloadAll;
        vm.isAgreementSignedAllByAPI = true;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: 10,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            AgreementsWorkflowService.getCurrentAgreementWorkflow(null).then(function (res) {
                setIsAgreementSignedAllByAPI(res);
            }).catch(function () {
                vm.isAgreementSignedAllByAPI = false;
            });
            AgreementsWorkflowService.findAllByPatientId(null, pageable, true, ['INTERNAL', 'EXTERNAL_NO_DATE_SIGN']).then(function (response) {
                vm.agreements = response && response.data ? response.data : [];
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            return result;
        }

        function download() {
            AgreementsWorkflowService.downloadAgreements().then(function(response) {
                vm.isProcessing = false;
            });
        }

        function back() {
            $state.go('home');
        }

        function isShowDownloadAll() {
            return vm.agreements && vm.agreements.length > 0 && vm.agreements[0].isFinishAllAgreement;
        }

        function setIsAgreementSignedAllByAPI(patientAgreement) {
            vm.isAgreementSignedAllByAPI = patientAgreement && patientAgreement.agreementSignedAllByAPI;
        }
    }
})();
