(function() {
    'use strict';


    InstructionDetailListController.$inject = ['$rootScope', '$scope', 'SurveyWorkflowService'];

    function InstructionDetailListController($rootScope, $scope, SurveyWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.select = select;
        vm.noThank = noThank;
        vm.goBack = goBack;

        init();

        function init() {
            // get data
            SurveyWorkflowService.coursesRegistration(vm.patientCourseRegistration).then(function(response) {
                $rootScope.$broadcast('UpdateCourseRegistration', response.data);
            });
        }

        function select(instructorSchedule) {
            console.log('InstructorSchedule selected', instructorSchedule);
        	vm.patientCourseRegistration.instructorScheduleSelected = instructorSchedule;
            vm.next();

        }

        function noThank() {
            vm.skip();
        }

        function goBack() {
            vm.back();
        }
    }

    angular.module('ipmApp').component('instructionDetailListStep', {
        bindings: {
            patientCourseRegistration: "=",
            update: '&onUpdate',
            next: '&',
            skip: '&',
            back: '&'
        },
        templateUrl: 'app/survey-workflow/partial/course-register-workflow/step/instruction-detail-list/instruction-detail-list.html',
        controller: InstructionDetailListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();
