(function() {
    'use strict';

    angular
        .module('ipmApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window'];

    function DataUtils ($window) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            timerTextClass: timerTextClass,
            secondsToMinute: secondsToMinute,
            deepCopy: deepCopy,
            isEqualText: isEqualText,
            isEqualBoolean: isEqualBoolean,
            countAttendanceTrackerNotAbsent: countAttendanceTrackerNotAbsent
        };

        return service;

        function abbreviate (text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize (base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile (type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64 (file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function timerTextClass(timerSeconds) {
            var timerMinutes = timerSeconds ? parseInt(timerSeconds / 60) : 0;
            if (timerMinutes && timerMinutes >= 35) {
                return 'text-danger';
            } else if (timerMinutes && timerMinutes >= 31 && timerMinutes < 35) {
                return 'text-success';
            }
            return 'text-warning';
        }

        function secondsToMinute(timerSeconds) {
            if (isNaN(timerSeconds)) {
                return 0;
            }
            var minutes = parseInt(timerSeconds / 60);
            var extraSeconds = timerSeconds % 60;
            if (extraSeconds >= 30) { // apply extraSeconds same component timeSpent CarePlanReview
                minutes += 1;
            }
            return minutes;
        }

        function deepCopy(obj) {
            var copy;
            // Handle the 3 simple types, and null or undefined
            if (null == obj || "object" != typeof obj) return obj;
            // Handle Date
            if (obj instanceof Date) {
                copy = new Date();
                copy.setTime(obj.getTime());
                return copy;
            }
            // Handle Array
            if (obj instanceof Array) {
                copy = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    copy[i] = deepCopy(obj[i]);
                }
                return copy;
            }
            // Handle Object
            if (obj instanceof Object) {
                copy = {};
                for (var attr in obj) {
                    if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
                }
                return copy;
            }
            throw new Error("Unable to copy obj! Its type isn't supported.");
        }

        function isEqualText(v1, v2) {
            return _.isEqualWith(v1, v2, function (a, b) {
                a = a ? a : null;
                b = b ? b : null;
                return angular.equals(a, b);
                // if ((_.isNull(a) || a === '') && (_.isNull(b) || b === '')) {
                //     return true;
                // }
            });
        }

        function isEqualBoolean(v1, v2) {
            return _.isEqualWith(v1, v2, function (a, b) {
                if ((_.isNull(a) || a === false) && (_.isNull(b) || b === false)) {
                    return true;
                }
            });
        }

        /**
         * calcCountAttendanceTrackerNotAbsent.
         * @param attendanceTracker
         * @return days have check is true
         */
        function countAttendanceTrackerNotAbsent(attendanceTracker) {
            var countAttendanceTracker = 0;
            if (attendanceTracker) {
                if (attendanceTracker.mon && attendanceTracker.mon === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.thu && attendanceTracker.thu === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.tue && attendanceTracker.tue === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.wed && attendanceTracker.wed === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.fri && attendanceTracker.fri === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.sat && attendanceTracker.sat === true) {
                    countAttendanceTracker += 1
                }
                if (attendanceTracker.sun && attendanceTracker.sun === true) {
                    countAttendanceTracker += 1
                }
            }
            return countAttendanceTracker;
        }

    }
})();
