(function() {
    'use strict';

    HeaderReportController.$inject = [];

    function HeaderReportController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

    }

    angular.module('ipmApp').component('headerReport', {
        bindings: {
            report: '<',
            titleTextTranslate: '<',
            isShowMdeOffice: '<'
        },
        templateUrl: 'app/frp-mde/header/header-report.html',
        controller: HeaderReportController,
        controllerAs: 'vm'
    })
})();
