(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SectionDeleteController',SectionDeleteController);

    SectionDeleteController.$inject = ['$uibModalInstance', 'entity', 'Section'];

    function SectionDeleteController($uibModalInstance, entity, Section) {
        var vm = this;
        vm.section = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Section.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
