(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqsBatteryController', HaqsBatteryController);

    HaqsBatteryController.$inject = ['$scope', '$state','$stateParams', '$window', 'HaqsBatteryService', 'ipmConstants'];

    function HaqsBatteryController ($scope, $state, $stateParams, $window, HaqsBatteryService, constants) {
        var vm = this;
        vm.haqsBattery = [];
        vm.page = 1;
        vm.totalItems = 0;
        vm.predicate = 'id';
        vm.reverse = false;
        vm.pageSize = constants.PAGE_SIZE;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.back = back;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: constants.PAGE_SIZE,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            HaqsBatteryService.query(pageable).then(function (response) {
                vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                vm.haqsBattery = response && response.data ? response.data : [];
            });
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            return [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
        }

        function back() {
            $window.history.back();
        }

    }
})();
