 (function (){
     'use strict'

     angular.module('ipmApp').controller('classSessionModalController',ClassSessionModalController)

     ClassSessionModalController.$inject = ['$uibModalInstance','coursename','classsequence','classlist','orderByFilter']

     function ClassSessionModalController($uibModalInstance,coursename,classsequence,classlist, orderBy) {
        var vm = this;
        vm.courseName = coursename;
        vm.classSequence  = classsequence;
        vm.classList = orderBy(classlist,'classStart') ;
        vm.cancel = closeClassDialog;
        vm.selectedClass = selectedClass;
        function closeClassDialog() {
            $uibModalInstance.dismiss();
        }
        function selectedClass(selectedClass) {
            $uibModalInstance.close(selectedClass);
        }
     }
 })();