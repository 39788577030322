(function() {
    'use strict';


    AgreementsOpioidMonographController.$inject = ['$scope', 'AgreementsWorkflowService'];

    function AgreementsOpioidMonographController($scope, AgreementsWorkflowService) {
        var vm = this; //jshint ignore:line
        vm.save = save;
        vm.isSaving = false;
        init();

        function init() {

        }

        function save() {
            vm.isSaving = true;
            agreementsEmit();
        }

        function agreementsEmit() {
            vm.onChange({agreement: vm.agreement ? vm.agreement : null});
        }

    }

    angular.module('ipmApp').component('agreementsOpioidMonograph', {
        bindings: {
            agreement: '<',
            onChange: '&'
        },
        templateUrl: 'app/agreements-workflow/agreements-opioid-monograph/agreements-opioid-monograph.html',
        controller: AgreementsOpioidMonographController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
