(function() {
    'use strict';

    PsychObjectiveTestingController.$inject = ['ipmConstants', '$uibModal', 'DateUtils', '$scope'];

    function PsychObjectiveTestingController(constants, $uibModal, DateUtils, $scope) {
        var vm = this; //jshint ignore:line

        vm.addMidsessionClick = addMidsessionClick;
        vm.colLimit = 1000;
        vm.dateFormat = 'MM/dd/yyyy';
        vm.colMidsessions = [];
        vm.colSubjectiveDateChange = colSubjectiveDateChange;
        vm.updateSelectAllCheckbox = updateSelectAllCheckbox;
        vm.colspanNotes = 4;
        vm.isMidSessionReport = false;
        vm.deletingMidsessionColumnMessage = 'ipmApp.frpReport.pt.deletingMidsessionColumn';

        init();

        function init() {
            if (vm.objectiveResponses) {
                // set the first subjectiveResponses colMidsessions
                setColMidsessionSubjective(vm.objectiveResponses[0].midsessions);
                // updateSeverity(vm.objectiveResponses);
            }
            vm.isMidSessionReport = vm.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT;
            vm.isOtherReport = vm.reportType === constants.FRP_REPORT_TYPE.OTHER_REPORT;
            vm.isWeeklyReport = vm.reportType === constants.FRP_REPORT_TYPE.WEEKLY_REPORT;
            vm.isDischargeReport = vm.reportType === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT;
            vm.isFirstReport = vm.reportType === constants.FRP_REPORT_TYPE.FIRST_REPORT;
            updateSelectAllCheckbox();
        }

        function setColMidsessionSubjective(midsessions) {
            if (midsessions) {
                _.forEach(midsessions, function (o) {
                    o.dated = DateUtils.convertDateTimeFromServer(o.dated);
                });
            }
            vm.colMidsessions = midsessions;
        }

        function addNewMidsession(orderNumber, objectiveResponseId) {
            return {id: null,
                frpReportPsychObjectiveResponseId: objectiveResponseId,
                dated: new Date(),
                testScore: "",
                orderNumber: orderNumber,
                active: true,
                $isOpenCalendar: false};
        }

        function addMidsessionClick() {
            if (vm.objectiveResponses && vm.colMidsessions.length < vm.colLimit) {
                var orderNumber = _.isEmpty(vm.colMidsessions) ? 0 : vm.colMidsessions.length + 1;
                vm.objectiveResponses.forEach(function (item) {
                        var midsession = addNewMidsession(orderNumber, item.id);
                        item.midsessions.push(midsession);
                });
                setColMidsessionSubjective(vm.objectiveResponses[0].midsessions);
                vm.colspanNotes++;
                emitOnchangeMidsessions(vm.objectiveResponses);
            }
        }

        function emitOnchangeMidsessions(objectiveResponses) {
            vm.onChangeMidsessions({objectiveResponses: objectiveResponses});
        }

        function colSubjectiveDateChange(colSub) {
            updateSubjectiveResponses(colSub);
        }

        function updateSubjectiveResponses(colSub) {
            // comment out because effect to isChanged(), updated on prepareMidssessionDated before submit data
            // vm.objectiveResponses.forEach(function (item) {
            //     if (item.midsessions.length > 0) {
            //         item.midsessions.forEach(function (itemMidsessions) {
            //             if (itemMidsessions && colSub && itemMidsessions.orderNumber === colSub.orderNumber) {
            //                 itemMidsessions.dated = colSub.dated;
            //             }
            //         });
            //     }
            // });
        }

        vm.openCalendar = function (colSub) {
            colSub.$isOpenCalendar = !colSub.$isOpenCalendar;
        };

        vm.checkDisableMidsessions = function (rowMidIndex, midsessions) {
            // If there is more than 1 Midsession column then the most recent one should be editable
            if (midsessions && midsessions.length > 0) {
                if (midsessions.length === 1) {
                    return false;
                } else {
                    if (midsessions.length <= 1 || rowMidIndex !== midsessions.length - 1) {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.checkDisableColMidsessions = function ($index) {
            return vm.disableFields.testingColMidsession || vm.checkDisableMidsessions($index, vm.colMidsessions);
        };

        vm.checkRequiredDateMidsession = function ($index) {
            if (vm.checkDisableColMidsessions($index)) {
                return false;
            }
            var hasDataMidsession = _.some(vm.objectiveResponses, function (item) {
                return item.midsessions && item.midsessions[$index] &&
                    (item.midsessions[$index].testScore || item.midsessions[$index].testScoreLeft || item.midsessions[$index].testScoreRight);
            });
            return hasDataMidsession;
        };

        // vm.checkRequiredColMidsessions = function ($index) {
        //     // If the report type is "First Report", the "Initial Score" column will be required.
        //     return !vm.isFirstReport && !vm.isOtherReport && !vm.disableFields.testingColMidsession && !vm.checkDisableMidsessions($index, vm.colMidsessions)
        //         && checkRequiredMidByWeeklyReport();
        // }

        vm.checkRequiredFieldScore = function() {
            return !vm.isOtherReport;
        }

        vm.checkRequiredFieldDischarge= function() {
            return vm.isDischargeReport;
        }

        vm.checkRequiredFields = function() {
            return vm.isMidSessionReport;
        }

        vm.checkDisableRemoveColMidsessions = function () {
            return (!vm.isMidSessionReport && !vm.isOtherReport) || vm.disableFields.testingColMidsession;
        }

        vm.removeColMidsessions = function (colSub, $index) {
            vm.confirmDialogRemoveMidsessions(vm.deletingMidsessionColumnMessage, colSub, $index);
        }

        vm.removeColMidsessionsCallBack = function (colSub, $index) {
            if (vm.objectiveResponses) {
                vm.objectiveResponses.forEach(function (item) {
                    if (item && item.midsessions) {
                        if (item.midsessions[$index] && item.midsessions[$index].id) {
                            addToMidsessionsRemoved(item.midsessions[$index]);
                        }
                        item.midsessions.splice($index, 1);
                        // update OrderNumber Midsessions
                        updateOrderNumberMidsession(item.midsessions);
                    }
                });
                setIsMidSessionObjectiveUpdated();
            }
        };

        function updateOrderNumberMidsession(midsessions) {
            if (midsessions) {
                midsessions.forEach(function (ms, index) {
                    ms.orderNumber = index;
                });
            }
        }

        function setIsMidSessionObjectiveUpdated() {
            if (!vm.isMidSessionObjectiveUpdated) {
                vm.isMidSessionObjectiveUpdated = true;
            }
        }

        function addToMidsessionsRemoved(midsessions) {
            if (midsessions) {
                midsessions.active = false;
                vm.midsessionsRemovedList.push(midsessions);
            }
        }

        function checkRequiredMidByWeeklyReport() {
            if (vm.isWeeklyReport) {
                return vm.haveMidsessionPrevious ? true : false;
            }
            return true; //other WeeklyReport
        }

        vm.confirmDialogRemoveMidsessions = function (message, object, $index) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'center-dialog-message vertical-center',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    vm.removeColMidsessionsCallBack(object, $index);
                }
            });
        }

        vm.onClickSelectAll = function() {
            vm.objectiveResponses.forEach(function (child) {
                child.checked = vm.checked;
            });
        }

         function updateSelectAllCheckbox() {
            var isCheckAll = true;
            vm.objectiveResponses.forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })

            vm.checked = isCheckAll;
        }

        vm.getIdInitialScore = function (sr) {
            return 'initialScore_' + sr.instrument;
        }

        vm.getIdDischarge = function (sr) {
            return 'discharge_' + sr.instrument;
        }

        vm.getIdMidSession = function (sr, $index) {
            return 'rowMid_' + sr.instrument + '_' + $index;
        }

        $scope.$watch('vm.objectiveImportMdeScores', function (newValue) {
            updateSelectAllCheckbox();
        });
    }

    angular.module('ipmApp').component('psychObjectiveTestingController', {
        bindings: {
            objectiveImportMdeScores: '=',
            objectiveResponses: '=',
            midsessionsRemovedList: '=',
            isMidSessionObjectiveUpdated: '=',
            disableFields: '<',
            reportType: '<',
            parentForm: '<',
            haveMidsessionPrevious: '<',
            onChange: '&',
            severity: '<',
            onChangeMidsessions: '&'
        },
        templateUrl: 'app/frp-mde/frp-report/psych/components/psych-objective-testing.html',
        controller: PsychObjectiveTestingController,
        controllerAs: 'vm'
    })
})();
