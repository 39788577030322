(function() {
    'use strict';


    frpController.$inject = ['$state', '$rootScope', '$scope', 'frpMdeService', 'FrpReportService', '$timeout', 'ipmConstants', '$filter', 'DateUtils', '$uibModal', 'DataUtils', 'ClearLocalStorageService'];

    function frpController($state, $rootScope, $scope, frpMdeService, FrpReportService, $timeout, constants, $filter, DateUtils, $uibModal, DataUtils, ClearLocalStorageService) {
        var vm = this; //jshint ignore:line
        vm.page = 1;
        vm.pageSize = 15;
        vm.predicate = 'pfm.id';
        vm.reverse = false;
        vm.totalItems = 0;
        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.startDate = moment();
        vm.endDate = moment();
        vm.dateFormat = "MM/dd/yyyy";
        vm.datePickerOpenStatus = {startDate: false, endDate: false};
        vm.dateOptions = {
            dateDisabled: dateDisabled,
            formatYear: 'yyyy',
            startingDay: 6
        };

        vm.frpStatus = constants.FRP_STATUS_FILTER;
        vm.isLoading = false;
        vm.isDownLoading = false;
        vm.isSaving = false;
        vm.loadAll = loadAll;
        vm.frps = [];
        vm.attendanceTrackers = [];
        vm.checkDisableAttendanceTracker = checkDisableAttendanceTracker;
        vm.checkEditReport = checkEditReport;
        vm.formatStartDatePrevious = formatStartDatePrevious;
        vm.downloadReport = downloadReport;
        vm.openEnrollPatient = openEnrollPatient;
        vm.daysExtend = 6;
        vm.numberDayOfWeekEnable = 6; // saturday
        vm.numberDayOfWeekStartDate = 6;

        init();

        function init() {
            setDefaultStatus();
            setStartDate();
            setEndDate(vm.startDate);
            vm.loadAll();
        }

        vm.openStartDate = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.changedTime = function () {
            if (vm.startDate) {
                $timeout(function () {
                    if (vm.startDate) {
                        setEndDate(vm.startDate);
                        vm.loadAll();
                    }
                }, 500);
            }
        };

        function setDefaultStatus() {
            vm.status = 'FRP_ENROLLED';
        }

        function setStartDate() {
            var numDayOfWeek = vm.startDate.day();
            if (numDayOfWeek === vm.numberDayOfWeekStartDate) { // Check if the current day is already Saturday
                vm.startDate = vm.startDate.toDate();
                return;
            }
            // Calculate the previous week's Saturday
            var theSaturdayOfPreviousWeek = vm.startDate.clone().subtract(1, 'week').day(vm.numberDayOfWeekStartDate);
            // Update startDate if theSaturdayOfPreviousWeek is a valid moment object
            if (theSaturdayOfPreviousWeek) {
                vm.startDate = theSaturdayOfPreviousWeek.toDate();
            }
        }

        function setEndDate(startDate) {
            vm.endDate = moment(new Date(startDate)).add(vm.daysExtend, 'd').toDate(); // add 6 days
        }

        function frpReportAdminIsCompleted(frp) {
            return frp && frp.frpReport && frp.frpReport.frpReportAdmin && frp.frpReport.frpReportAdmin.status === 'COMPLETE';

        }
        function checkDisableAttendanceTracker(frp) {
            return checkIsFRPHold(frp) || !checkEditReport(frp) || frpReportAdminIsCompleted(frp);
        }

        function checkEditReport(frp) {
            return frp && frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.EDIT_REPORT;
        }
        function checkIsFRPHold(frp) {
            // 4.	Disable these buttons if the status is FRP Hold
            return frp && frp.frpStatus === 'FRP_ACTIVE_HOLD';
        }

        vm.search = function () {
            return vm.loadAll();
        };

        vm.filterStatus = function () {
                vm.loadAll();
        };

        vm.filterByDate = function () {
            vm.loadAll();
        };

        function filterFrpProgramIds() {
            if (vm.frpPrograms) {
                return $filter('filter')(vm.frpPrograms).map(function (item) {
                    return item.id;
                });
            }
            return [];
        }

        function getFrpProgramIds() {
            if (vm.frpProgramId) {
                return [vm.frpProgramId];
            } else {
                return filterFrpProgramIds();
            }
        }

        function loadAll() {
            if (!vm.startDate) {
                return;
            }
            vm.isLoading = true;
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize,
                sort: sort()
            };
            var startDate = null;
            var endDate = null;
            if (vm.startDate) {
                startDate = $filter('date')(vm.startDate, vm.dateFormatToServer);
            }
            if (vm.endDate) {
                endDate = $filter('date')(vm.endDate, vm.dateFormatToServer);
            }
            var param = {
                patientId: null,
                frpProgramIds: getFrpProgramIds(),
                frpStatus: vm.status ? [vm.status] : [],
                startDate: startDate ? startDate : null,
                endDate: endDate ? endDate : null,
                query: vm.searchQuery
            };
            frpMdeService.getFPR(param, pageable).then(function (response) {
                vm.frps = response && response.data ? response.data : [];
                _.forEach(vm.frps, function(frp) {
                    if (frp.frpReport && frp.frpReport.attendanceTracker) {
                        frp.frpReport.attendanceTracker.isInValidAttendance = isInValidAttendance(frp.frpReport.attendanceTracker, frp.frpReport.patientFRPMDE, frp.frpReport.sumAtHours);
                    }
                });
                vm.isLoading = false;
                vm.totalItems = response.headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                setWarningMessagePreviousWeek(vm.frps)
            }, function error(error) {
                if (vm.frpProgramId) {
                    ClearLocalStorageService.addFRPProgramsError(vm.frpProgramId);
                }
                openErrorDialog();
            });
        };

        function sort() {
            var result = [];
            if (vm.predicate == 'authorizedHours') {
                result.push('sumAuthorizedHours' + ',' + (vm.reverse ? 'asc' : 'desc'));
            } else if (vm.predicate == 'remainingHours') {
                result.push('remainingHours' + ',' + (vm.reverse ? 'asc' : 'desc'));
            } else if (vm.predicate == 'patient') {
                result.push('pa.user.firstName' + ',' + (vm.reverse ? 'desc' : 'asc'));
                result.push('pa.user.lastName' + ',' + (vm.reverse ? 'desc' : 'asc'));
                result.push('pa.patientId' + ',' + (vm.reverse ? 'desc' : 'asc'));
            } else {
                result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            }
            return result;
        }

        vm.btnBackClick = function () {
            reloadAttendanceTrackersChange();
            setWeekPrevious();

            // start filter
            vm.filterByDate();
        };

        vm.btnNextClick = function () {
            reloadAttendanceTrackersChange();
            setWeekNext();
            // start filter
            vm.filterByDate();
        };

        function setWeekPrevious() {
            vm.startDate = moment(new Date(vm.startDate)).subtract(1, "w").toDate();
            vm.endDate = moment(new Date(vm.endDate)).subtract(1, "w").toDate();
        };

        function setWeekNext() {
            vm.startDate = moment(new Date(vm.startDate)).add(1, "w").toDate();
            vm.endDate = moment(new Date(vm.endDate)).add(1, "w").toDate();
        };

        // only Enable saturday selection
        function dateDisabled(data) {
            var date = data.date,
                mode = data.mode;
            return mode === 'day' && (date.getDay() !== vm.numberDayOfWeekEnable); // 6 is Saturday
        }

        function reloadAttendanceTrackersChange() {
            if (vm.frps && vm.frps.length > 0) {
                vm.frps.forEach(function (item) {
                    if (item && item.frpReport) {
                        item.frpReport.attendanceTracker = createNewAttendanceTracker();
                    }
                });
            }
        }

        vm.attendanceTrackersChange = function (attendanceTracker, frp) {
            if (attendanceTracker) {
                if (!isInValidAttendance(attendanceTracker, frp.frpReport.patientFRPMDE, frp.frpReport.sumAtHours)) {
                    frp.frpReport.attendanceTracker = attendanceTracker;
                    saveAttendanceTracker(frp);
                } else {
                    frp.frpReport.attendanceTracker.isInValidAttendance = true;
                }
            }
        };

        vm.onChangeStatusFRP = function(data) {
            if (data) {
                FrpReportService.updateStatusFRP(data).then(function (res) {
                    if (res) {
                        loadAll();
                    }
                });
            }
        }

        function saveAttendanceTracker(frp) {
            FrpReportService.savedAttendanceTracker(frp).then( function (res) {
                if (res) {
                    loadAll();
                }
            });
        }

        function createNewAttendanceTracker() {
            var attendanceTracker = {
                id: null,
                mon: null,
                tue: null,
                wed: null,
                thu: null,
                fri: null,
                sat: null, 
                sun: null
            };
            return attendanceTracker;
        }

        function getPoint(attendanceTracker) {
            if (attendanceTracker) {
                return constants.NUMBER_HRS_ATTENDED * DataUtils.countAttendanceTrackerNotAbsent(attendanceTracker);
            }
            return 0;
        }
        
        function isInValidAttendance(attendanceTracker, patientFRPMDE, initCheckedAttended) {
            var totalHoursAttended = patientFRPMDE.previouslyCompletedHours + patientFRPMDE.sumHoursDaysAttended;
            return totalHoursAttended - initCheckedAttended + getPoint(attendanceTracker) > constants.HOURS_ATTENDED_CAN_NOT_EXCEED;
        }

        $scope.$on("CHANGE_PROGRAM_FRP_MDE_DROPDOWN", function(event, res) {
            vm.frpProgramId = res;
            loadAll();
        });

        $scope.$on(constants.ADD_ENROLL_SUCCESS, function() {
            loadAll();
        });

        vm.checkDisableStatus = function(frp) {
            if (frp && vm.isView) {
                return frp.reportStatus !== constants.FRP_REPORT_STATUS_VIEW.EDIT_REPORT && frp.reportStatus !== constants.FRP_REPORT_STATUS_VIEW.VIEW_REPORT;
            }
            return false;
        }

        vm.openReport = function (frp) {
            if (frp) {
                if (frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.FRP_REPORT_PREVIOUS_NOT_FINALIZED) {
                    createFrpReport(frp);
                } else if (frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.EDIT_REPORT && frp.frpReport) {
                    goToFrpReportBuilder(frp.frpReport.id)
                } else if (frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.VIEW_REPORT && frp.frpReport) {
                    goToFrpReportBuilder(frp.frpReport.id)
                } else { // ADD_NEW_REPORT
                    createFrpReport(frp);
                }
            }
        }

        function createNewFRPReportBySelectedDate(reportStatus) {
            return {
                startDate: DateUtils.convertLocalDateToServer(vm.startDate),
                endDate: DateUtils.convertLocalDateToServer(vm.endDate),
                reportStatus: reportStatus
            };
        }

        function createFrpReport(frp) {
            frp.frpReport = createNewFRPReportBySelectedDate(constants.FRP_REPORT_STATUS_VIEW.ADD_NEW_REPORT);
            vm.isSaving = true;
            FrpReportService.createFrpReport(frp).then(function(res) {
                goToFrpReportBuilder(res.id);
                vm.isSaving = false;
            });
        }

        function goToFrpReportBuilder(id) {
            $state.go('frp-report', { id: id });
        }

        function goToHome() {
            $state.go('home');
        }

        function formatStartDatePrevious(date) {
            if (date) {
                var dateFormat = moment(date).format("MMMM Do"); // ex: February 14th
                if (dateFormat) {
                    return dateFormat.replace(/(\d)(st|nd|rd|th)/g, '$1<sup>$2</sup>');
                }
            }
            return '';
        }

        function downloadReport(frp) {
            if (frp && frp.frpReport && frp.frpReport.id && !vm.isDownLoading) {
                vm.isDownLoading = true;
                FrpReportService.downloadFrpReport(frp.frpReport.id).then(function (response) {
                    vm.isDownLoading = false;
                });
            }
        }

        function setWarningMessagePreviousWeek(frps) {
            vm.isShowWarningMessagePreviousWeekNotFinalized = !_.isEmpty(_.filter(frps, function (frp) {
                return frp && frp.id && frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.FRP_REPORT_PREVIOUS_NOT_FINALIZED;
            }));
        }

        vm.checkPreviousWeekNotFinalized = function (frp) {
            if (frp && frp.id && frp.reportStatus === constants.FRP_REPORT_STATUS_VIEW.FRP_REPORT_PREVIOUS_NOT_FINALIZED) {
                return 'link-danger';
            }
            return '';
        }

        function openEnrollPatient() {
            $uibModal.open({
                templateUrl: 'app/frp-mde/tab/frp/enroll-patient/enroll-patient-dialog.html',
                controller: 'EnrollPatientDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowClass: 'absolute-center vertical-center',
                resolve: {
                    frpProgramId: function () {
                        return vm.frpProgramId
                    },
                    frpStartDate: function () {
                        return vm.startDate;
                    }

                }
            }).result.then(function (result) {
                if (result) {
                    loadAll();
                }
            }, function () {
            });
        }

        /**
         * @param frp
         * @returns reportStatus
         */
        vm.getReportStatus = function (frp) {
            if (frp && frp.frpReport && frp.frpReport.id) {
                if (frp.frpReport.statusFinal === 'FINALIZED') {
                    return constants.FRP_REPORT_STATUS_VIEW.VIEW_REPORT;
                } else if (frp.frpReport.statusFinal === 'OPEN') {
                    return constants.FRP_REPORT_STATUS_VIEW.EDIT_REPORT;
                }
            }
            return constants.FRP_REPORT_STATUS_VIEW.ADD_NEW_REPORT;
        }

        vm.checkShowDownload = function (frp) {
            return frp && frp.frpReport && frp.reportStatus === 'VIEW_REPORT' && frp.frpReport.statusFinal === 'FINALIZED';
        }

        function openErrorDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.frpReport.error.loadReportErrorMessage"},
                    title: function () {return "ipmApp.frpReport.error.title"}
                }
            }).result.then(function() {
                goToHome();
            }, function() {
            });
        }
    }

    angular.module('ipmApp').component('frpTab', {
        bindings: {
            frpProgramId: '<',
            frpPrograms: '<',
            isView: '<',
            isShowWarningMessagePreviousWeekNotFinalized: '='
        },
        templateUrl: 'app/frp-mde/tab/frp/frp.html',
        controller: frpController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate','$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('attendanceTracker');
                return $translate.refresh();
            }]
        },
        bindToController: true
    })
})();

