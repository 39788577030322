(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal', '$state'];

    function PasswordController (Auth, Principal, $state) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.back = back;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function back() {
            // fix bug IPMproject-B1593: Should go back to class sessions list when clicking Back button on change password page
            if ($state.previous.name === 'presentation-controls-instructor') { // previous state is presentation-controls
                $state.go('wellness-class-list');
                return;
            }
        	$state.go( $state.previous.name, $state.previous.params );
        }
    }
})();
