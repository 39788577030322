(function() {
    'use strict';

    selectAllCheckboxController.$inject = [];

    function selectAllCheckboxController() {
        var vm = this; //jshint ignore:line
        init();

        function init() {

        }

        vm.onClick = function() {
           vm.item[vm.reportType].forEach(function (child) {
               child.checked = vm.item[vm.reportType + 'Checked'];
               if(!child.checked) {
                   clearValue(child);
               }
           })
        }

        function clearValue(child) {
            // clear value
            child.score = null;
            child.severity = null;
            child.mdeReport = null;
        }
    }

    angular.module('ipmApp').component('selectAllCheckbox', {
        bindings: {
            item: '<',
            isDisabled: '<',
            reportType:'<'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/select-all-checkbox.html',
        controller: selectAllCheckboxController,
        controllerAs: 'vm'
    })
})();
