(function () {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('presentation-controls-instructor', {
                parent: 'entity',
                url: '/presentation-controls/{csId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_QHP'],
                    pageTitle: 'ipmApp.patient.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/presentation-controls-instructor/presentation-controls-instructor.html',
                        controller: 'PresentationControlsInstructorController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('presentationControls');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    wellnessClass: ['$stateParams', 'WellnessClassService', function($stateParams, WellnessClassService) {
                        return WellnessClassService.findByClassSessionId($stateParams && $stateParams.csId ? $stateParams.csId : null);
                    }]
                }
            });
    }

})();
