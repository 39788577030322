(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ProviderDeleteController',ProviderDeleteController);

    ProviderDeleteController.$inject = ['$uibModalInstance', 'entity', 'Provider'];

    function ProviderDeleteController($uibModalInstance, entity, Provider) {
        var vm = this;
        vm.provider = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Provider.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
