(function () {
    'use strict';

    CallLogListController.$inject = ['$scope'];

    function CallLogListController($scope) {
        var vm = this;//jshint ignore:line

        init();

        function init() {
            vm.callLogs = vm.callLogs ? vm.callLogs : [];
        }
    }

    angular.module('ipmApp').component('callLogList', {
        bindings: {
            callLogs: '<',
            onChange: '&'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/call-log/partial/call-log-list.html',
        controller: CallLogListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }]
        }
    })
})();
