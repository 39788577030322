(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ProviderDetailController', ProviderDetailController);

    ProviderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Provider'];

    function ProviderDetailController($scope, $rootScope, $stateParams, entity, Provider) {
        var vm = this;
        vm.provider = entity;
        vm.load = function (id) {
            Provider.get({id: id}, function(result) {
                vm.provider = result;
            });
        };
        var unsubscribe = $rootScope.$on('ipmApp:providerUpdate', function(event, result) {
            vm.provider = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
