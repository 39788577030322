(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('WellnessQuestionDeleteController',WellnessQuestionDeleteController);

    WellnessQuestionDeleteController.$inject = ['$uibModalInstance', 'entity', 'WellnessQuestion'];

    function WellnessQuestionDeleteController($uibModalInstance, entity, WellnessQuestion) {
        var vm = this;
        vm.question = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            WellnessQuestion.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
