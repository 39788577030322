(function(){
    'use strict';

    angular.module('ipmApp').config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider.state('wellness-class-list', {
            parent: 'app',
            url: '/wellness-class-list',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_QHP'],
            },

            views: {
                'content@': {
                    templateUrl: 'app/qhp-workflow/wellness-class-list/wellness-class-list.html',
                    controller: 'WellnessClassListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('qhpWorkflow');
                    $translatePartialLoader.addPart('carePlanReviewStatus');
                    $translatePartialLoader.addPart('callLog');
                    $translatePartialLoader.addPart('partyType');
                    $translatePartialLoader.addPart('class-session');
                    return $translate.refresh();
                }]
            }
        })
        .state('wellness-class-list.view', {
            parent: 'wellness-class-list',
            url: '/{classSessionId}/view',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_QHP'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/class-session/class-session-editor.html',
                    controller: 'ClassSessionEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        isEdit: function () {
                            return false;
                        },
                        isCreateNew: function () {
                            return false;
                        },
                        isView: function () {
                            return true;
                        },
                        entity: ['ClassSessionService', function (ClassSessionService) {
                            return ClassSessionService.get($stateParams.classSessionId);
                        }],
                        course: function() {
                            return {};
                        },
                        instructors: ['User', function (User) {
                            return User.getAllQHPUsersWithInstructorTask();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
