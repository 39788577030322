(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ConfirmController', ConfirmController);

    ConfirmController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$q',
        '$state',
        'message'
    ];

    function ConfirmController($scope, $uibModalInstance, $q, $state, message) {
        var vm = this;
        vm.message = message;

        vm.yes = function () {
            $uibModalInstance.close(true);
            $uibModalInstance.dismiss('cancel');
        }

        vm.no = function(){
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
