(function() {
    'use strict';


    CPRCheckboxQuestionController.$inject = ['$scope', '$state', 'ipmConstants'];

    function CPRCheckboxQuestionController($scope, $state, ipmConstants) {
        var vm = this; //jshint ignore:line
        vm.handleCheckboxChange = handleCheckboxChange;
        vm.isOtherCheckboxTextInputRequired = isOtherCheckboxTextInputRequired;
        vm.checkClearChildByCheckboxValues = checkClearChildByCheckboxValues;
        vm.isOtherCheckboxTextInputRequired = isOtherCheckboxTextInputRequired;
        vm.nameCheckBoxOther = 'Other'; // used to show required label checkbox other
        vm.cprQuestionItemConductedId = 16;
        init();

        function init() {
            vm.checkboxValues = [];
            // order child by order number
            vm.question.questionItems = _.orderBy(vm.question.questionItems, ['orderNumber'], ['esc']);

            // init answer
            if (vm.answers) {
                vm.checkboxValues = vm.answers.get(vm.question.code) ? vm.answers.get(vm.question.code) : [];
                _.forEach(vm.question.questionItems, function (q) {
                    q.checked = false;
                    var k = _.find(vm.checkboxValues, function(l) { return l.value === q.value});
                    if(k) {
                        q.checked = true;
                    } else if (q.name === 'Other') {
                        var y = _.find(vm.checkboxValues, function(l) { return l.name === q.name});
                        if (y) {
                            q.checked = true;
                            vm.valueOtherCheckboxTextInputValue = y.value.split('---')[1];
                        }
                    }
                });
            }
        }

        function handleCheckboxChange(item, checked) {
            var k = undefined;
            if (item.name === 'Other') {
                k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other'});
                if (!checked) {
                    vm.valueOtherCheckboxTextInputValue = undefined;
                }
            } else {
                k = _.find(vm.checkboxValues, function(l) { return l.value === item.value});
            }
            if (k) {
                _.remove(vm.checkboxValues, function(n) {
                    return n.value === item.value || (k.name === 'Other' && n.name === 'Other');
                  });
            } else {
                if (checked) {
                    vm.checkboxValues.push({name: item.name, value: item.value, orderNumber: item.orderNumber});
                }
            }
            var isClearChilds = vm.checkClearChildByCheckboxValues();
            vm.onChange({ data: { question: vm.question, value: vm.checkboxValues, clearChilds: isClearChilds }});
        }

        function checkClearChildByCheckboxValues() {
            var isClearChilds = false;
            if (_.isEmpty(vm.checkboxValues)) {
                isClearChilds = true;
            }
            return isClearChilds;
        }

        vm.handleOtherCheckboxTextInput = function () {
            var k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other' });
            k.value = k.name + '---' + vm.valueOtherCheckboxTextInputValue;
            var isClearChilds = vm.checkClearChildByCheckboxValues();
            vm.onChange({ data: { question: vm.question, value: vm.checkboxValues, clearChilds: isClearChilds } });
        }

        vm.checkBoxRequired = function() {
            if (ipmConstants.DISPLAY_MESSAGE_REQUIRED_SUB_CHECKBOX.includes(vm.question.code)) {
            var result = true;
            for(var i = 0; i < vm.question.questionItems.length; i++) {
                var n = vm.question.questionItems[i];
                if (n.checked) {
                    result = false;
                    break;
                }
            }
            return result;
            }
            
            return false
        }

        function isOtherCheckboxTextInputRequired() {
            if (!vm.question.haveAutoComplete) {
                var k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other' });
                return k ? true : false
            }
            return false;
        }

        function isOtherCheckboxTextInputRequired() {
            if (!vm.question.haveAutoComplete) {
                var k = _.find(vm.checkboxValues, function(l) { return l.name === 'Other' });
                return k ? true : false
            }
            return false;
        }

        vm.isDisplayLabelCheckbox = function() {
            if (isOtherCheckboxTextInputRequired()) {
                if (ipmConstants.DISPLAY_LABEL_CODE.includes(vm.question.code)) {
                    return true;
                }
            }
            return false;
        }

        vm.isDisplayTextAreaOtherCheckbox = function() {
            if (isOtherCheckboxTextInputRequired()) {
                if (!ipmConstants.DISPLAY_OTHER_TEXT_FIELD_CODE.includes(vm.question.code)) {
                    return true;
                }
            }
            return false;
        }

        vm.isDisplayTextFieldOtherCheckbox = function() {
            if (isOtherCheckboxTextInputRequired()) {
                if (ipmConstants.DISPLAY_OTHER_TEXT_FIELD_CODE.includes(vm.question.code)) {
                    return true;
                }
            }
            return false;
        }

        vm.checkShowLabelRequiredCheckBox= function(optionItem) {
           return optionItem.checked && (optionItem.name === vm.nameCheckBoxOther || ipmConstants.OPTION_ITEM_ID_REQUIRED.includes(optionItem.id));
        }
    }

    angular.module('ipmApp').component('cprCheckboxQuestion', {
        bindings: {
            question: '<',
            answers: '<',
            onChange: '&',
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/question-component/checkbox/checkbox.html',
        controller: CPRCheckboxQuestionController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', function ($translate) {
                return $translate.refresh();
            }]
        }
    })
})();
