(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('download-haq-activity-report', {
            parent: 'app',
            url: '/download-haq-activity-report',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BILLER'],
                pageTitle: 'global.menu.entities.downloadActivityReport'
            },
            views: {
                'content@': {
                    templateUrl: 'app/download-activity-report/download-activity-report.html',
                    controller: 'DownloadActivityReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadActivityReport');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                serviceType: function () {
                    return "HAQ";
                }
            }
        }).state('download-cpr-activity-report', {
                parent: 'app',
                url: '/download-cpr-activity-report',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BILLER'],
                    pageTitle: 'global.menu.entities.downloadActivityReport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/download-activity-report/download-activity-report.html',
                        controller: 'DownloadActivityReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('downloadActivityReport');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    serviceType: function () {
                        return "CPR";
                    }
                }
            })
        .state('download-haq-activity-report.requested', {
            parent: 'download-haq-activity-report',
            url: '/requested/{id}?user',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.entities.downloadActivityReport'
            },
            views: {
                'content@': {
                    templateUrl: 'app/download-activity-report/request-activity-report.html',
                    controller: 'RequestActivityReportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('downloadActivityReport');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                serviceType: function () {
                    return "HAQ";
                }
            }
        })
            .state('download-cpr-activity-report.requested', {
                parent: 'download-cpr-activity-report',
                url: '/requested/{id}?user',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'global.menu.entities.downloadActivityReport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/download-activity-report/request-activity-report.html',
                        controller: 'RequestActivityReportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('downloadActivityReport');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    serviceType: function () {
                        return "CPR";
                    }
                }
            });

    }

})();
