(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('patientBillableConfigController', patientBillableConfigController);

    patientBillableConfigController.$inject = ['$scope'];

    function patientBillableConfigController($scope) {
        var vm = this;
        vm.isDisabled = vm.isDisabled ? vm.isDisabled : false;
        vm.reasonFieldDisable = {
            haqTestServiceExclusionReason: false,
            haqTestBillingExclusionReason: false,
            haqEvalServiceExclusionReason: false,
            haqEvalBillingExclusionReason: false,
            cprServiceExclusionReason: false,
            cprBillingExclusionReason: false,
            pneServiceExclusionReason: false
        };

        vm.checkDisabledReason = checkDisabledReason;

        init();

        function init() {
            if (vm.patientBillableConfig && vm.patientBillableConfig.id) {
                setExclusionReasonFieldDisable();
            }
        }

        function setExclusionReasonFieldDisable() {
            for (var reason in vm.reasonFieldDisable) {
                vm.reasonFieldDisable[reason] = !!vm.patientBillableConfig[reason]; // convert the value to boolean
            }
        }

        function checkDisabledReason(property) {
            if (vm.reasonFieldDisable && vm.patientBillableConfig) {
                return  !!vm.patientBillableConfig[property] && vm.reasonFieldDisable[property];
            }
        }

        function toggleProperty(property) {
            if (vm.patientBillableConfig) {
                vm.patientBillableConfig[property] = !vm.patientBillableConfig[property];
            }
        }

        function updatePatientExclusionReason(property, value) {
            if (vm.patientBillableConfig) {
                vm.patientBillableConfig[property] = value;
            }
        }

        function changeExclusion(property, reason) {
            toggleProperty(property);
            updatePatientExclusionReason(reason, '');
        }

        // Function to change HAQ Test Service Exclusion
        vm.changeHaqTestServiceExclusion = function () {
            changeExclusion('haqTestServiceExclusion', 'haqTestServiceExclusionReason');
        };

        // Function to change HAQ Test Billing Exclusion
        vm.changeHaqTestBillingExclusion = function () {
            changeExclusion('haqTestBillingExclusion', 'haqTestBillingExclusionReason');
        };

        // Function to change HAQ Eval Service Exclusion
        vm.changeHaqEvalServiceExclusion = function () {
            changeExclusion('haqEvalServiceExclusion', 'haqEvalServiceExclusionReason');
        };

        // Function to change HAQ Eval Billing Exclusion
        vm.changeHaqEvalBillingExclusion = function () {
            changeExclusion('haqEvalBillingExclusion', 'haqEvalBillingExclusionReason');
        };

        // Function to change CPR Service Exclusion
        vm.changeCprServiceExclusion = function () {
            changeExclusion('cprServiceExclusion', 'cprServiceExclusionReason');
        };

        // Function to change CPR Billing Exclusion
        vm.changeCprBillingExclusion = function () {
            changeExclusion('cprBillingExclusion', 'cprBillingExclusionReason');
        };

        // Function to change PNE Service Exclusion
        vm.changePneServiceExclusion = function () {
            changeExclusion('pneServiceExclusion', 'pneServiceExclusionReason');
        };
    }

    angular.module('ipmApp').component('patientBillableConfig', {
        bindings: {
            isDisabled: '<',
            patientBillableConfig: '='
        },
        templateUrl: 'app/admin/user-management/patient-case-table/patient-billable-config.html',
        controller: patientBillableConfigController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('patient');
                return $translate.refresh();
            }]
        }
    });
})();
