(function() {
    'use strict';


    PatientResponseSummarySlideController.$inject = ['$scope'];

    function PatientResponseSummarySlideController($scope) {
        var vm = this;

        init();

        function init() {
            vm.slidesCountAll = vm.slidesCountAll ? vm.slidesCountAll : 0;
            vm.slide = vm.patientPresentationResponse ? vm.patientPresentationResponse.slide : null;
        }
    }

    angular.module('ipmApp').component('patientResponseSummarySlide', {
        bindings: {
            patientPresentationResponse: '=',
            slidesCountAll: '<'
        },
        templateUrl: 'app/presentation-controls-instructor/presentation-patient-response/patient-response-summary/patient-response-summary-slide.html',
        controller: PatientResponseSummarySlideController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                return $translate.refresh();
            }]
        }
    })
})();
