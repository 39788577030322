(function() {
    'use strict';


    AppointmentListController.$inject = ['$scope', '$state', '$localStorage' , '$stateParams', '$window', 'Principal', 'ipmConstants', 'AppointmentService'];

    function AppointmentListController($scope, $state, $localStorage, $stateParams, $window, Principal, constants, AppointmentService) {
        var vm = this;//jshint ignore:line

        vm.pageSize = 6;
        vm.totalItems = 0;
        vm.page = 1;
        vm.predicate = 'dateOfAppointment';
        vm.predicateSecond = 'timeOfAppointment';
        vm.reverse = true;
        vm.appointments = [];
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;

        init();

        function init() {
            vm.id = $stateParams.id;
            vm.pageableAP = {
                page: 1,
                size: 6,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };
            if (vm.patientId) {
                AppointmentService.findAllByPatientId(vm.patientId, pageable).then(function (response) {
                        vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                        vm.appointments = response && response.data ? response.data : [];
                });
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.loadAll();
        }

        function sort() {
            var result;
            if (vm.predicate === 'officeName' || vm.predicate === 'providerTitleFullName') {
                result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            } else {
                result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            }
            if (vm.predicateSecond && vm.predicate == 'dateOfAppointment') {
                result.push(vm.predicateSecond + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }
    }

    angular.module('ipmApp').component('appointmentList', {
        bindings: {
            patientId: '<',
            isBiller: '<'
        },
        templateUrl: 'app/patient/appointment/appointment-list.html',
        controller: AppointmentListController,
        controllerAs: 'vm',
        resolve: {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('configuration');
                $translatePartialLoader.addPart('patient');
                return $translate.refresh();
            }]
        }
    })
})();
