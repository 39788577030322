
(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('ClassSessionRegistrationAddDialogController', ClassSessionRegistrationAddDialogController);

        ClassSessionRegistrationAddDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance','entity', 'courses', 'ipmConstants', 'ClassSessionRegistrationService'];

    function ClassSessionRegistrationAddDialogController ($stateParams, $state, $uibModalInstance, entity, courses, constants, ClassSessionRegistrationService) {
        var vm = this;
        vm.classSessionRegistration = entity;
        vm.courses = courses ? courses : [];
        vm.wellnessClassCurrent = null;

        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.courseChange = courseChange;
        vm.registerClick = registerClick;
        vm.isMaximumNumberRegister = isMaximumNumberRegister;

        init();

        function init() {
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.classSessionRegistration) {
                if (vm.classSessionRegistration.id !== null) {
                    ClassSessionRegistrationService.update(vm.classSessionRegistration, onSaveSuccess, onSaveError);
                } else {
                    ClassSessionRegistrationService.save(vm.classSessionRegistration, onSaveSuccess, onSaveError);
                }
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
                $uibModalInstance.close(error);
            }
        }

        function registerClick(classSessions) {
            if (classSessions && !isMaximumNumberRegister(classSessions)) {
                vm.classSessionRegistration.classSession = classSessions;
                this.save();
            }
        }

        function courseChange() {
            var courseSelected = _.find(vm.courses, function(c) { return c.id === vm.classSessionRegistration.course.id; });
            setNextClass(courseSelected);
        }

        function setNextClass(courseSelected) {
            if (courseSelected) {
                vm.wellnessClassCurrent = getTheFirstWellnessClasses(courseSelected)
            } else { // reset wellnessClassCurrent
                vm.wellnessClassCurrent = null;
            }
        }

        function getTheFirstWellnessClasses(courseSelected) {
            return courseSelected.wellnessClasses && courseSelected.wellnessClasses.length > 0 ? courseSelected.wellnessClasses[0] : null;
        }

        function isMaximumNumberRegister(classSession) {
            if (classSession && (classSession.numOfRegistered >= constants.MAXIMUM_NUMBER_REGISTER)) {
                return true;
            }
            return false;
        }
    }
})();
