(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionnaireCountDialogController', QuestionnaireCountDialogController);

    QuestionnaireCountDialogController.$inject = ['$uibModalInstance', 'countQuestionnaire'];

    function QuestionnaireCountDialogController($uibModalInstance, countQuestionnaire) {
        var vm = this;
        vm.countQuestionnaire = countQuestionnaire;

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.yes = function() {
            $uibModalInstance.close(true);
        };
    }
})();
