(function () {
    'use strict';
    var module = angular.module('ipmApp')

    module.directive('mouseChange', ['$document', function ($document) {

        function mouseChangeCtrl($scope, $element) {
            $element.on('mousedown', mouseMoveHandler);
            $element.on('mousewheel', mouseMoveHandler);
            $element.on('keydown keyup keypress', mouseMoveHandler);

            function mouseMoveHandler(e) {
                if (e) {
                    $scope.onChangeMouseMove({'position': {x: e.clientX, y: e.clientY}});
                }
            };

        }

        return {
            link: mouseChangeCtrl,
            scope: {
                onChangeMouseMove: '&',
            }
        };
    }]);


})();
