(function() {
    'use strict';


    CPRDateInputQuestionController.$inject = ['$scope', '$state'];

    function CPRDateInputQuestionController($scope, $state) {
        var vm = this; //jshint ignore:line
        vm.datePickerOpenStatus = {};
        vm.dateFormat = 'MM/DD/YYYY';

        init();

        function init() {
            // init answer
            if (vm.answers) {
                var a = vm.answers.get(vm.question.code);
                if (a) {
                    vm.valueAnswer = new Date(a);
                }
            }
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.handleTextChange = function() {
            var valueToString = moment(vm.valueAnswer).format(vm.dateFormat);
            vm.onChange({ data: { question: vm.question, value: valueToString }});
        }
    }

    angular.module('ipmApp').component('cprDateInputQuestion', {
        bindings: {
            question: '<',
            answers: '<',
            onChange: '&',
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/question-component/date-input/date-input.html',
        controller: CPRDateInputQuestionController,
        controllerAs: 'vm',
    })
})();
