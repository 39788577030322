(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportPsychController', MDEReportPsychController);

    MDEReportPsychController.$inject = ['$uibModal', '$state', '$stateParams', 'MdeReportService', 'Principal', 'ipmConstants', 'DataUtils', 'templates', 'isView'];

    function MDEReportPsychController($uibModal, $state, $stateParams, MdeReportService, Principal, ipmConstants, DataUtils, templates, isView) {
        var vm = this;
        vm.templates = templates ? templates : [];
        vm.sortGoalAddNewDeletes = [];
        vm.longGoalAddNewDeletes = [];
        vm.isSaving = false;
        vm.isFinalize = false;
        vm.isDisabled = false;
		vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.templateInputType = ipmConstants.FRP_MDE_TEMPLATE;
        vm.updateSelectAllCheckbox = updateSelectAllCheckbox;
        vm.isChanged = isChanged;
        vm.isView = isView ? true : false;
        vm.fieldRequrieds = [
            'subjectiveComplaints',
            'pyschosocialHistory',
            'mentalStatus',
            'psychologicalSequelaeOfWorkInjury',
            'psychiatricDiagnosis',
            'psychologicalGoalsOfTreatment',
            'summaryAndImpressions',
            {formKey: 'checkBoxRow_CES_D', controlKey: ['score_CES_D']},
            {formKey: 'checkBoxRow_GAD_7', controlKey: ['score_GAD_7']},
            {formKey: 'checkBoxRow_TSK', controlKey: ['score_TSK']},
            {formKey: 'checkBoxRow_BPI', controlKey: ['score_BPI']},
            {formKey: 'checkBoxRow_BPI', controlKey: ['scoreRight_BPI']},
            {formKey: 'checkBoxRow_BPI', controlKey: ['scoreLeft_BPI']},
            // {formKey: 'checkBoxRow_BPI', controlKey: ['severity_right_BPI']},
            // {formKey: 'checkBoxRow_BPI', controlKey: ['severity_left_BPI']},
            {formKey: 'checkBoxRow_ISI', controlKey: ['score_ISI']},
            {formKey: 'checkBoxRow_PHQ_9', controlKey: ['score_PHQ_9']},
            // {formKey: 'checkBoxRow_CES_D', controlKey: ['severity_CES_D']},
            // {formKey: 'checkBoxRow_GAD_7', controlKey: ['severity_GAD_7']},
            // {formKey: 'checkBoxRow_TSK', controlKey: ['severity_TSK']},
            // {formKey: 'checkBoxRow_BPI', controlKey: ['severity_BPI']},
            // {formKey: 'checkBoxRow_ISI', controlKey: ['severity_ISI']},
            // {formKey: 'checkBoxRow_PHQ_9', controlKey: ['severity_PHQ_9']}
        ];

        vm.dataHeaderReport = {};
        vm.objectiveChecked = false;
        vm.subjectiveChecked = false;
        vm.reportType = {
            OBJECTIVE: "objectives",
            SUBJECTIVE : "subjectives"
        }

        vm.severity = angular.copy(ipmConstants.FRP_PSYCH_SEVERITY);
        vm.severitySubjectives = vm.severity ? vm.severity.subjectives : {};
        vm.severityObjectives = vm.severity ? vm.severity.objectives : {};

        init();

        function init() {
            vm.mdeReportId = $stateParams.id;

            MdeReportService.getMdePsychReport($stateParams.id).then(function(data) {
                vm.report = data;
                vm.reportOld = DataUtils.deepCopy(data);
                vm.isView = vm.isView ? true : vm.report ? vm.report.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;
                setIsDisabled();
                vm.isFinalize = vm.report && vm.report.status === 'COMPLETE' ? true : false;
                vm.dataHeaderReport = buildDataHeaderReport(vm.report);
                updateSelectAllCheckbox(vm.reportType.OBJECTIVE);
                updateSelectAllCheckbox(vm.reportType.SUBJECTIVE);
            });
        }

        function setIsDisabled() {
            if (vm.isView || (vm.report && (vm.report.mdeStatus === 'MDE_ABANDONED' || vm.report.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        vm.save = function() {
            submitData(false);
        }

        vm.finalize = function() {
            submitData(true);
        }

        function isEqualPsychTesting(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var psychTestingOld = v1[idx];
                var psychTesting = v2[idx];
                isEqual = DataUtils.isEqualBoolean(psychTestingOld.checked, psychTesting.checked);
                if (!isEqual) return false;
                if (psychTestingOld.checked && psychTesting.checked) {
                    isEqual = DataUtils.isEqualText(psychTestingOld.score, psychTesting.score);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(psychTestingOld.scoreLeft, psychTesting.scoreLeft);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(psychTestingOld.scoreRight, psychTesting.scoreRight);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(psychTestingOld.severity, psychTesting.severity);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(psychTestingOld.notes, psychTesting.notes);
                    if (!isEqual) return false;
                }
            }
            return isEqual;
        }

        function isExistTermGoalNew(sortTermGoalAddNew, longTermGoalAddNew) {
            var sortTermGoalNew = _.find(sortTermGoalAddNew, function(s) { return s.id === null || s.id === undefined; });
            var longTermGoalNew = _.find(longTermGoalAddNew, function(l) { return l.id === null || l.id === undefined; });
            return sortTermGoalNew || longTermGoalNew;
        }

        function isExistTermGoalDeleted() {
            return vm.sortGoalAddNewDeletes.length > 0 || vm.longGoalAddNewDeletes.length > 0;
        }

        function isEqualTermGoal(v1, v2, isNewTermGoal) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var termGoalOld = v1[idx];
                var termGoal = v2[idx];
                isEqual = DataUtils.isEqualBoolean(termGoalOld.checked, termGoal.checked);
                if (!isEqual) return false;
                if (isNewTermGoal) {
                    isEqual = DataUtils.isEqualText(termGoalOld.goal, termGoal.goal);
                }
                if (!isEqual) return false;
                if (isNewTermGoal && termGoalOld.checked && termGoal.checked) {
                    isEqual = DataUtils.isEqualText(termGoalOld.inputValue, termGoal.inputValue);
                }
                if (!isEqual) return false;
                if (termGoalOld.type === 'TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(termGoalOld.value, termGoal.value);
                } else if (termGoalOld.type === 'DROP_DOWN') {
                    isEqual = DataUtils.isEqualText(termGoalOld.selectedValue, termGoal.selectedValue);
                }
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function isChanged() {
            var isEqual = true;
            // Subjective Complaints and History
            isEqual = DataUtils.isEqualText(vm.reportOld.subjectiveComplaints, vm.report.subjectiveComplaints);
            if (!isEqual) return true;
            // Subjective Complaints and History
            isEqual = DataUtils.isEqualText(vm.reportOld.pyschosocialHistory, vm.report.pyschosocialHistory);
            if (!isEqual) return true;
            // Mental Status
            isEqual = DataUtils.isEqualText(vm.reportOld.mentalStatus, vm.report.mentalStatus);
            if (!isEqual) return true;
            // Psychological Sequelae of Work Injury
            isEqual = DataUtils.isEqualText(vm.reportOld.psychologicalSequelaeOfWorkInjury, vm.report.psychologicalSequelaeOfWorkInjury);
            if (!isEqual) return true;
            // Psychiatric Diagnosis
            isEqual = DataUtils.isEqualText(vm.reportOld.psychiatricDiagnosis, vm.report.psychiatricDiagnosis);
            if (!isEqual) return true;
            // Psychological Goals of Treatment Narrative
            isEqual = DataUtils.isEqualText(vm.reportOld.psychologicalGoalsOfTreatment, vm.report.psychologicalGoalsOfTreatment);
            if (!isEqual) return true;
            // Summary and Impressions
            isEqual = DataUtils.isEqualText(vm.reportOld.summaryAndImpressions, vm.report.summaryAndImpressions);
            if (!isEqual) return true;
            // Subjective Testing
            isEqual = isEqualPsychTesting(vm.reportOld.subjectives, vm.report.subjectives);
            if (!isEqual) return true;
            // Objective Testing
            isEqual = isEqualPsychTesting(vm.reportOld.objectives, vm.report.objectives);
            if (!isEqual) return true;
            // Is exists Term Goal Add New
            var isChangeTermGoal = isExistTermGoalNew(vm.report.sortTermGoalAddNewResponses, vm.report.longTermGoalAddNewResponses);
            if (isChangeTermGoal) return true;
            // Is exists Term Goal Deleted
            isChangeTermGoal = isExistTermGoalDeleted();
            if (isChangeTermGoal) return true;
            // Term Goal
            isEqual = isEqualTermGoal(vm.reportOld.sortTermGoalResponses, vm.report.sortTermGoalResponses, false);
            if (!isEqual) return true;
            isEqual = isEqualTermGoal(vm.reportOld.longTermGoalResponses, vm.report.longTermGoalResponses, false);
            if (!isEqual) return true;
            // Term Goal Add New
            isEqual = isEqualTermGoal(vm.reportOld.sortTermGoalAddNewResponses, vm.report.sortTermGoalAddNewResponses, true);
            if (!isEqual) return true;
            isEqual = isEqualTermGoal(vm.reportOld.longTermGoalAddNewResponses, vm.report.longTermGoalAddNewResponses, true);
            if (!isEqual) return true;
            return !isEqual;
        }

        function back() {
            $state.go('mde-report', {id: vm.mdeReportId});
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        function checkValidation() {
            vm.fieldRequrieds.forEach(function (field) {
                if (field) {
                    if (typeof field === 'string' && vm.psychReportForm[field]) {
                        vm.psychReportForm[field].$setDirty();
                    } else if (typeof field === 'object' && vm.psychReportForm[field.formKey]) {
                        field.controlKey.forEach(function (f) {
                            if (f && vm.psychReportForm[field.formKey][f]) {
                                vm.psychReportForm[field.formKey][f].$setDirty();
                            }
                        });
                    }
                }
            });
            _.forEach(vm.report.sortTermGoalResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                if (t.type === 'TEXT_FIELD' && vm.psychReportForm[formKey][inputKey]) {
                    vm.psychReportForm[formKey][inputKey].$setDirty();
                } else if (t.type === 'DROP_DOWN' && vm.psychReportForm[formKey][selectKey]) {
                    vm.psychReportForm[formKey][selectKey].$setDirty();
                }
            });
            _.forEach(vm.report.longTermGoalResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                if (t.type === 'TEXT_FIELD' && vm.psychReportForm[formKey][inputKey]) {
                    vm.psychReportForm[formKey][inputKey].$setDirty();
                } else if (t.type === 'DROP_DOWN' && vm.psychReportForm[formKey][selectKey]) {
                    vm.psychReportForm[formKey][selectKey].$setDirty();
                }
            });
            _.forEach(vm.report.sortTermGoalAddNewResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code;
                if (vm.psychReportForm[formKey][inputKey]) {
                    vm.psychReportForm[formKey][inputKey].$setDirty();
                }
            });
            _.forEach(vm.report.longTermGoalAddNewResponses, function(t) {
                var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                var inputKey = 'input_m_' + t.code;
                if (vm.psychReportForm[formKey][inputKey]) {
                    vm.psychReportForm[formKey][inputKey].$setDirty();
                }
            });
        }

        function submitData(finalize) {
            if (vm.isView) {
                return;
            }
            if (finalize) {
                checkValidation();
                if (vm.psychReportForm.$invalid || !vm.hasRequiredCheck()) {
                    return;
                }
            }

            if(vm.report.subjectiveComplaintsAndHistoryTemplate && vm.report.subjectiveComplaintsAndHistoryTemplate.content != vm.report.subjectiveComplaintsAndHistory) {
                vm.report.subjectiveComplaintsAndHistoryTemplate = null;
            }

            if(vm.report.mentalStatusTemplate && vm.report.mentalStatusTemplate.content != vm.report.mentalStatus) {
                vm.report.mentalStatusTemplate = null;
            }

            vm.isSaving = true;
            vm.report.finalize = finalize;
            prepairDataForSave();
            MdeReportService.saveMdePsychReport(vm.mdeReportId, vm.report).then(function (response) {
                onSaveSuccess(response)
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error && (error.status === 409 || error.status === 400)) {
                vm.error = 'ERROR';
                openErrorDuplicateDialog();
            }
        }

        function prepairDataForSave() {
            // goal-add-new rule
            // new, checked, not-empty        -> save text and checked state
            // new, checked, empty            -> form invalid
            // new, uncheck, not-empty        -> save text and uncheck state
            // new, uncheck, empty            -> not save
            // old, checked, not-empty        -> save text and checked state
            // old, checked, empty            -> form invalid
            // old, uncheck, not-empty        -> save text and uncheck state
            // old, uncheck, empty            -> term-goal delete (database update to inactive)

            // filter new, checked/checked, not-empty sort-term-goal
            vm.report.sortTermGoalAddNewResponses = _.filter(vm.report.sortTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // filter new, checked, not-empty long-term-goal
            vm.report.longTermGoalAddNewResponses = _.filter(vm.report.longTermGoalAddNewResponses, function(termGoal) {
                return termGoal.id || termGoal.goal;
            });
            // delete old, uncheck, empty sort-term-goal
            _.forEach(vm.report.sortTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            // delete old, uncheck, empty long-term-goal
            _.forEach(vm.report.longTermGoalAddNewResponses, function(termGoal) {
                if (termGoal.id && !termGoal.checked && !termGoal.goal) {
                    termGoal.active = false;
                }
            });
            // merge add goals add new list
            vm.report.sortTermGoalAddNewResponses.push.apply(vm.report.sortTermGoalAddNewResponses, vm.sortGoalAddNewDeletes);
            vm.report.longTermGoalAddNewResponses.push.apply(vm.report.longTermGoalAddNewResponses, vm.longGoalAddNewDeletes);
        }

        function hasItemChecked(listItem) {
            if (listItem) {
                return !_.isEmpty(_.filter(listItem, function (s) { return s.checked; }))
            }
            return false;
        }

        vm.hasTestingChecked = function() {
            // At least 1 Subjective Test is required
            var isChecked = hasItemChecked(vm.report.subjectives);
            if (!isChecked) return false;
            return true;
        }

        vm.hasRequiredCheck = function() {
            // At least 1 Subjective Test is required
            var isChecked = hasItemChecked(vm.report.subjectives);
            if (!isChecked) return false;
            // At least 1 Short Term Goal is required
            isChecked = vm.hasTermGoalChecked(true);
            if (!isChecked) return false;
            // At least 1 Long Term Goal is required
            isChecked = vm.hasTermGoalChecked(false);
            if (!isChecked) return false;
            return true;
        }

        vm.hasItemCheckedInvalid = function() {
            for (var index = 0; index < vm.report.subjectives.length; index++) {
                var s = vm.report.subjectives[index];
                if (s.checked) {
                    var convertItemCode = s.code.replace("-", "_");
                    var controlScore = vm.psychReportForm['checkBoxRow_' + convertItemCode]['score_' + convertItemCode];
                    if (controlScore.$dirty && controlScore.$error.required) return true;
                    var controlScore = vm.psychReportForm['checkBoxRow_' + convertItemCode]['severity_' + convertItemCode];
                    if (controlScore.$dirty && controlScore.$error.required) return true;
                }
            }
        }

        vm.hasTermGoalChecked = function(isSort) {
            var termGoalChecked;
            var termGoalAddNewChecked
            if (isSort) {
                // At least 1 Short Term Goal is required
                termGoalChecked = hasItemChecked(vm.report.sortTermGoalResponses);
                termGoalAddNewChecked = hasItemChecked(vm.report.sortTermGoalAddNewResponses);
            } else {
                // At least 1 Long Term Goal is required
                termGoalChecked = hasItemChecked(vm.report.longTermGoalResponses);
                termGoalAddNewChecked = hasItemChecked(vm.report.longTermGoalAddNewResponses);
            }
            if (!termGoalChecked && !termGoalAddNewChecked) return false;
            return true;
        }

        vm.hasTermGoalCheckedInvalid = function(isSort) {
            var termGoalCheckedInvalid;
            var termGoalAddNewCheckedInvalid;
            if (isSort) {
                termGoalCheckedInvalid = isTermGoalCheckedInvalid(vm.report.sortTermGoalResponses);
                termGoalAddNewCheckedInvalid = isTermGoalAddNewCheckedInvalid(vm.report.sortTermGoalAddNewResponses);
            } else {
                termGoalCheckedInvalid = isTermGoalCheckedInvalid(vm.report.longTermGoalResponses);
                termGoalAddNewCheckedInvalid = isTermGoalAddNewCheckedInvalid(vm.report.longTermGoalAddNewResponses);
            }
            if (termGoalCheckedInvalid || termGoalAddNewCheckedInvalid) return true;
            return false;
        }

        function isTermGoalCheckedInvalid(items) {
            for (var index = 0; index < items.length; index++) {
                var t = items[index];
                if (t.checked) {
                    var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                    var control;
                    if (t.type === 'TEXT_FIELD') {
                        var inputKey = 'input_m_' + t.code + '_' + t.goalType;
                        control = vm.psychReportForm[formKey][inputKey];
                        if (control.$dirty && control.$error.required) return true;
                    } else if (t.type === 'DROP_DOWN') {
                        var selectKey = 'select_m_' + t.code + '_' + t.goalType;
                        control = vm.psychReportForm[formKey][selectKey];
                        if (control.$dirty && control.$error.required) return true;
                    }
                }
            }
            return false;
        }

        function isTermGoalAddNewCheckedInvalid(items) {
            for (var index = 0; index < items.length; index++) {
                var t = items[index];
                if (t.checked) {
                    var formKey = 'termGoalRow_' + t.code + '_' + t.goalType;
                    var inputKey = 'input_m_' + t.code;
                    var control = vm.psychReportForm[formKey][inputKey];
                    if (control.$dirty && control.$error.required) return true;
                }
            }
            return false;
        }

        vm.addNewSortTermGoal = function() {
            var orderNumber = _.isEmpty(vm.report.sortTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.sortTermGoalAddNewResponses, function (o) { return o.orderNumber } ).orderNumber + 1;
            var code = 'STG' + orderNumber;
            var newGoal = {code: code, orderNumber: orderNumber, active: true, goalType: 'SORT_TERM'};
            vm.report.sortTermGoalAddNewResponses.push(newGoal);
        }

        vm.addNewLongTermGoal = function() {
            var orderNumber = _.isEmpty(vm.report.longTermGoalAddNewResponses) ? 0 : _.maxBy(vm.report.longTermGoalAddNewResponses, function (o) { return o.orderNumber } ).orderNumber + 1;
            var code = 'LTG' + orderNumber;
            var newGoal = {code: code, orderNumber: orderNumber, active: true, goalType: 'LONG_TERM'};
            vm.report.longTermGoalAddNewResponses.push(newGoal);
        }

        vm.deleteSortTermGoalAddNew = function(deleteItem) {

            _.remove(vm.report.sortTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            vm.sortGoalAddNewDeletes.push(deleteItem);

        }

        vm.deleteLongTermGoalAddNew = function(deleteItem) {

            _.remove(vm.report.longTermGoalAddNewResponses, function (o) { return o.code === deleteItem.code; });

            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            vm.longGoalAddNewDeletes.push(deleteItem);

        }

        vm.showAddGoal = function($last, isLongTerm) {
            // filter active Goal
            var addNewItem;
            if (isLongTerm) {
                addNewItem = _.filter(vm.report.longTermGoalAddNewResponses, function (i) { return i.active; });
            } else {
                addNewItem = _.filter(vm.report.sortTermGoalAddNewResponses, function (i) { return i.active; });
            }
            return $last && _.isEmpty(addNewItem);
        }

        function buildDataHeaderReport(data) {
            if (data) {
                return {
                    patientName: data.patientName,
                    chartNumber: data.chartNumber,
                    authorizedProviderName: data.authorizedProviderName,
                    frpProgramName: data.frpProgramName,
                    mdeOfficeName: data.mdeOfficeName,
                    caseNumber: data.caseNumber,
                    dateOfBirth: data.dateOfBirth
                };
            }
        };


        function updateSelectAllCheckbox(type) {
            var isCheckAll = true;
            vm.report[type].forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })

            vm.report[type + 'Checked'] = isCheckAll;
        }

        function openErrorDuplicateDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.mdeReport.error.saveReportDuplicateMessage"},
                    title: function () {return "ipmApp.mdeReport.error.saveReportDuplicateTitle"}
                }
            }).result.then(function() {
                $window.location.reload(); // reload page to load new data MDE
            }, function() {
            });
        }
    }
})();
