(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientCaseEditController', PatientCaseEditController);

    PatientCaseEditController.$inject = ['$uibModalInstance', 'User', 'patientCases', 'caseNumber', 'isEdit', 'patientCaseId'];

    function PatientCaseEditController($uibModalInstance, User, patientCases, caseNumber, isEdit, patientCaseId) {
        var vm = this;
        vm.patientCaseId = patientCaseId;
        vm.patientCases = patientCases ? patientCases : [];
        vm.caseNumber = caseNumber ? caseNumber : '';
        vm.isEdit = isEdit ? isEdit : false;
        vm.error = false;

        // Public Functions
        vm.save = save;
        vm.clear = clear;
        vm.namePatientCaseOnChange = namePatientCaseOnChange;

        function checkExistsCaseNumberInCurrent(caseNumber) {
            return vm.patientCases.some(function (patientCase) {
                return patientCase.caseNumber.toLowerCase() === caseNumber.toLowerCase()
                    && (!patientCase.id || (patientCase.id && patientCase.id !== vm.patientCaseId));
            });
        }

        function save() {
            if (!vm.caseNumber) {
                return;
            }
            // check exists caseNumber in current patientCases
            if (checkExistsCaseNumberInCurrent(vm.caseNumber)) {
                vm.error = true;
                return;
            }

            User.checkUniquePatientCase(vm.caseNumber, vm.patientCaseId).then(function (response) {
                if (response && response === true) {
                    vm.error = false;
                    saveCloseModal();
                } else {
                    vm.error = true;
                }
            }).catch(function (error) {
                vm.error = true;
            });
        }
        function saveCloseModal() {
            $uibModalInstance.close(vm.caseNumber);
            $uibModalInstance.dismiss('cancel');
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function namePatientCaseOnChange() {
            vm.error = false;
        }
    }
})();
