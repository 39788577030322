(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CocAgreementInvitationController', CocAgreementInvitationController);

        CocAgreementInvitationController.$inject = ['$timeout', 'ipmConstants', '$stateParams', 'CocAgreementService', '$rootScope', '$state'];

    function CocAgreementInvitationController($timeout, constants, $stateParams, CocAgreementService, $rootScope, $state) {
        var vm = this;
        



        init();

        function init() {

            

            if ($stateParams.secretKey === null || $stateParams.secretKey === '') {
                $state.go('error');
            }

            vm.secretKey = $stateParams.secretKey;

            CocAgreementService.getBySecretKey(vm.secretKey).then(function(res) {
                $rootScope.$broadcast(constants.EVT_AUTHENTICATION_SUCCESS_BY_INVITATION);
                // change language
                $rootScope.$emit("changeLanguage", {langKey: res.langkeyOfPatient});

                $timeout(function(){
                    $state.go('agreements-workflow');
                }, 1000);

                
            }, function(error) {
                vm.isPageLoadFinish = true;
                if (error.status = 404) {
                    vm.errorNotFound = true;
                } else {
                    $state.go('error');
                }
            });
        }
    }

})();
