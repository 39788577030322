(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('ClassSessionRegistrationService', ClassSessionRegistrationService);

    ClassSessionRegistrationService.$inject = ['$resource', '$q', 'halClient', '$http'];

    function ClassSessionRegistrationService($resource,$q, halClient, $http) {
        var baseUrl = 'api/class-session-registrations';
        var resourceUrl = "api/class-session-registrations/:id";
        var service = $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'delete': {method: 'DELETE'}
        });

        service.getRegisteredCourses = getRegisteredCourses;
        service.getAllRegisteredCourses = getAllRegisteredCourses;
        service.findById = findById;

        function getRegisteredCourses(patientId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/get-by-patient';
            $http.get(url, {
                params: {
                    patientId: patientId,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            })
                .success(function (data, status, headers) {
                    var result = new Object();
                    result.data = data && data !== undefined ? data : [];
                    result.totalItems = headers('X-Total-Count');
                    deferred.resolve(result);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function getAllRegisteredCourses(patientId) {
            var deferred = $q.defer();

            var url = baseUrl + '/get-all-registered-courses-by-patient/' + patientId;
            $http.get(url).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        function findById(id) {
            var deferred = $q.defer();
            var url = baseUrl + "/" + id;
            halClient.$get(url).then(function success(result) {
                deferred.resolve(result);
            }, function error(e) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    };
})();
