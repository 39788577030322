(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPReportHomeController', FRPReportHomeController);

    FRPReportHomeController.$inject = ['$state', 'FrpReportService', '$stateParams', '$uibModal'];

    function FRPReportHomeController($state, FrpReportService, $stateParams, $uibModal) {
        var vm = this;
        vm.frpReport = {};
        vm.patient = {};
        vm.reports = [];
        vm.isView = false;
        vm.checkCompletedAllReport = checkCompletedAllReport;
        vm.dataHeaderReport = {};

        init();

        function init() {
            // Data sample for show UI - This Data incorrect
        	FrpReportService.get($stateParams.id).then(function(response) {
                vm.frpReport = response.data ? response.data : {};
                setIsView();
                vm.patient = response.data.patient;
                vm.patient.dateOfBirth =  moment(vm.patient.dateOfBirth).format('MM/DD/YYYY');
                vm.reports =  response.data.reports;
                vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);
            });
        }

        function setIsView() {
            vm.isView = (vm.frpReport && vm.frpReport.statusFinal === 'FINALIZED') ? true : false;
        }

        vm.editPage = function(name){
            if(name)
                $state.go('frp-report.' + name);
        }

        vm.viewPage = function (name) {
            if (name) {
                $state.go('frp-report.' + name + '.VIEW');
                return;
            }
        }

        function checkCompletedAllReport() {
            if (vm.frpReport && vm.frpReport.reports && vm.frpReport.reports.length > 0) {
                return _.isEmpty(_.filter(vm.frpReport.reports, function (item) {
                    return item.status !== 'COMPLETE' && item.role !== 'MEDICAL' && item.role !== 'ADDENDUM';
                }));
            }
            return false;
        }

        function checkSignedProvider() {
            return vm.frpReport && vm.frpReport.patientFRPMDE && vm.frpReport.patientFRPMDE.signature && vm.frpReport.patientFRPMDE.signature.id && vm.frpReport.patientFRPMDE.signature.data;
        }

        function finalizeFrpReport(signature) {
            signature = signature ? signature : {};
            FrpReportService.finalizeFrpReport(vm.frpReport.id, signature).then(function (response) {
                onSaveSuccess(response)
            }, function error(e) {
                onSaveError(e);
            });
        }

        vm.finalAndSignOffClick = function () {
            if (checkCompletedAllReport()) {
                if (!checkSignedProvider()) { // final with signature
                    openProviderDialog(angular.copy(vm.frpReport.patientFRPMDE.signature)); // fix reload signature when open again
                } else { // final without signature
                    finalizeFrpReport(null);
                }
            }
        }

        vm.preview = function() {
            $state.go('frp-report-preview');
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            goToFRPTab();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        function goToFRPTab() {
            $state.go('frp-mde', {tab:0});
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        function openProviderDialog(signature) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/frp-report/home/upload-signature-dialog/upload-signature-dialog.html',
                controller: 'UploadSignatureDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass: 'center-modal',
                size: 'md',
                resolve: {
                    signature: function () {
                        return signature;
                    }
                }
            }).result.then(function(result) {
                finalizeFrpReport(result);
            }, function() {
            });
        }
    }
})();
