(function () {
	'use strict';

	angular
	.module('ipmApp')
	.controller('MDEReportAdminController', MDEReportAdminController);

	MDEReportAdminController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$q', '$uibModal', 'Principal', 'ipmConstants', 'userDetails', 'MdeReportService', 'supportedDataList', 'qhpUsers', 'staffUsers', 'isView'];

	function MDEReportAdminController($scope, $state, $stateParams, $filter, $q, $uibModal,Principal, ipmConstants, userDetails, MdeReportService, supportedDataList, qhpUsers, staffUsers, isView) {
		var vm = this;
		vm.datePickerOpenStatus = {};
		vm.users = qhpUsers;
		vm.adminUsers = staffUsers.concat(qhpUsers);
		vm.save = save;
		vm.submitted = false;
		vm.isDisabled = false;
		vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
		vm.abandonedMessage = 'ipmApp.mdeReport.admin.confirmAbandoned';
		vm.providers = supportedDataList.providerList;
		vm.states = ipmConstants.STATES_OF_AMERICA;
        vm.isView = isView ? true : false;
        vm.requiredFields = [
            'caseNumber',
            'provider',
            'medical',
            'pt',
            'psych',
            'dateOfService'
        ];
        vm.dataHeaderReport = {};

		init();

		function init() {
			MdeReportService.getMdeAdminReport($stateParams.id).then(function(response) {
			    vm.admin = response;
                vm.isView = vm.isView ? true : vm.admin ? vm.admin.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;

                vm.admin.injuryDate = vm.admin.injuryDate ? moment(vm.admin.injuryDate)._d : null;
                vm.admin.dateOfService = vm.admin.dateOfService ? moment(vm.admin.dateOfService)._d : null;
				vm.admin.dateOfBirthStr = vm.admin.dateOfBirth ?  moment(vm.admin.dateOfBirth).format('MM/DD/YYYY') : "";
				vm.adminOriginal = Object.assign({}, vm.admin);
				setIsDisabled(vm.admin.mdeStatus);
                vm.dataHeaderReport = buildDataHeaderReport(vm.admin.patientFRPMDE);
			});
			getFrpProgramList();
		}

        function getFrpProgramList() {
            Principal.identity(false).then(function(res) {
                if (Principal.hasAnyAuthority([ipmConstants.ROLE_STAFF]) === true) {
                    vm.frpPrograms  = userDetails.staffUserDetail ? userDetails.staffUserDetail.frpPrograms : [];
                } else if (Principal.hasAnyAuthority([ipmConstants.ROLE_QHP]) === true) {
                    vm.frpPrograms = userDetails.qhpUserDetail ? userDetails.qhpUserDetail.frpPrograms : [];
                }
            });
        }

        vm.updateAdminAbandoned = function(){
            MdeReportService.updateAdminAbandoned($stateParams.id).then(function(res) {
                $state.go('frp-mde', {tab:2});
            })
        }

        function setIsDisabled(status){
            if(vm.isView || status === 'MDE_ABANDONED' || (vm.admin.patientFRPMDE && vm.admin.patientFRPMDE.isViewFrpProgram)) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

		vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.submitButton = function(){
            vm.submitted = true;
        }

        function checkValidation() {
            vm.requiredFields.forEach(function (field) {
                if (field && vm.editForm[field]) {
                    vm.editForm[field].$setDirty();
                }
            });
        }

		function save(status){
            if (vm.isView) {
                return;
            }
            vm.admin.status = 'INPROGRESS';
            MdeReportService.saveMdeAdminReport($stateParams.id, vm.admin).then(function(res) {
                $state.go('mde-report');
            })
		}

        vm.final = function(){
           if (vm.isView) {
               return;
           }
           checkValidation();
           if (vm.editForm.$invalid) {
               return;
           }
           vm.admin.status = 'COMPLETE';
           MdeReportService.saveMdeAdminReport($stateParams.id, vm.admin).then(function(res) {
               $state.go('mde-report');
           })
        }

        vm.confirmDialog = function (message, callback) {
            if (vm.isView) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'absolute-center warning-dialog',
                size: 'sm',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if(result) {
                    callback();
                }
            });
        }

        vm.checkCancel = function(edited){
            if(edited) {
                vm.confirmDialog(vm.cancelMessage, vm.backHome);
            } else {
                vm.backHome();
            }

        }

        vm.backHome = function() {
            $state.go('mde-report');
        }

        vm.isChanged = function() {
        	for (var prop in vm.admin) {
        		  if(!vm.admin[prop]) {
        			  vm.admin[prop] = null;
        		  }
        	}
            for (var prop in vm.adminOriginal) {
                if (!vm.adminOriginal[prop]) {
                    vm.adminOriginal[prop] = null;
                }
            }
        	return !angular.equals(vm.admin, vm.adminOriginal);
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    mdeOfficeName: patientFRPMDE.mdeOfficeName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

		}
	})();
