(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SurveyResponseController', SurveyResponseController);

    SurveyResponseController.$inject = ['$scope', '$state', 'SurveyResponse', 'SurveyResponseSearch'];

    function SurveyResponseController ($scope, $state, SurveyResponse, SurveyResponseSearch) {
        var vm = this;
        vm.surveyResponses = [];
        vm.loadAll = function() {
            SurveyResponse.query(function(result) {
                vm.surveyResponses = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            SurveyResponseSearch.query({query: vm.searchQuery}, function(result) {
                vm.surveyResponses = result;
            });
        };
        vm.loadAll();
        
    }
})();
