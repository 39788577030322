(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('AdhocTestingService', AdhocTestingService);

    AdhocTestingService.$inject = ['$http', '$q', 'DownloadBlobService'];

    function AdhocTestingService ($http, $q, DownloadBlobService) {
        var resourceUrl = "api/adhoc-testing";

        var service = {
            'downloadCsvAdhocTesting': downloadCsvAdhocTesting,
            'downloadCsvDetailedAdhocTesting': downloadCsvDetailedAdhocTesting,
            'downloadReport': downloadReport
        };

        function downloadCsvAdhocTesting(fromDate, toDate, selectedTestIds) {
            var deferred = $q.defer();
            var url = resourceUrl + '/download';
            var body = {fromDate: fromDate, toDate: toDate, testIds: selectedTestIds};
            $http.post(url, body).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadCsvDetailedAdhocTesting(fromDate, toDate, selectedTestId) {
            var deferred = $q.defer();
            var url = resourceUrl + '/download/detailed';
            var body = {fromDate: fromDate, toDate: toDate, testId: selectedTestId};
            $http.post(url, body).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function downloadReport(fileId) {
            var deferred = $q.defer();
            var url = 'api/adhoc-testing/download-report/' + fileId;
            $http({
                url: url,
                method: "GET",
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if(status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], {type: contentType});
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        return service;
    }
})();
