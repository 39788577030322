(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Section', Section);

    Section.$inject = ['$resource', '$q', '$http'];
    // SectionFilter.$inject = ['$resource'];

    function Section ($resource, $q, $http) {


        var baseUrl = 'api/sections';
        var resourceUrl =  'api/sections/:id';

        var service =  $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });

        service.findByTest = findByTest;
        service.updateOrderNumber = updateOrderNumber;

        function findByTest(testId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + "/find-by-test/" + testId;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };


        function updateOrderNumber(testId, sections) {
            var url = baseUrl + "/update-order-number/" + testId;
            return $http.post(url, sections).then(function (resp) {
                return resp.data;
            });
        };

        return service;

        // var resourceUrl =  'api/sections/:id';

        // return $resource(resourceUrl, {}, {
        //     'query': { method: 'GET', isArray: true},
        //     'get': {
        //         method: 'GET',
        //         transformResponse: function (data) {
        //             data = angular.fromJson(data);
        //             return data;
        //         }
        //     },
        //     'update': { method:'PUT' }
        // });
    }
    // function SectionFilter ($resource) {
    //     var resourceUrl =  'api/sections/search';

    //     return $resource(resourceUrl, {}, {
    //         'search': { method: 'POST', isArray: true}
    //     });
    // }
})();
