(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CourseEditorController', CourseEditorController);

        CourseEditorController.$inject = ['$scope', '$timeout','$state', '$stateParams', '$translate', '$uibModal', 'isCreateNew', 'Course', 'User', 'Deck', 'Test'];

    function CourseEditorController($scope, $timeout, $state, $stateParams, $translate, $uibModal, isCreateNew, Course, User, Deck, Test) {
        var vm = this;
        vm.isSaving = false;
        vm.isCreateNew = isCreateNew;
        vm.isSaving = false;
        vm.isDuplicateSequence = false;
        vm.duplicateSequence = "";
        vm.isDuplicateIndicator = false;
        vm.duplicateIndicator = "";
        vm.dayOfWeeks = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        vm.recommendationTypes = ['RECOMMENDED', 'CONSIDER'];
        vm.amPms = ['AM', 'PM'];
        vm.hourOfDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        vm.minuteOfHours = [
            {
                label: '00',
                value: 0
            },
            {
                label: '15',
                value: 15
            },
            {
                label: '30',
                value: 30
            },
            {
                label: '45',
                value: 45
            }
        ];
        vm.users = [];
        vm.decks = [];
        vm.tests = Test.query();

        vm.init = init;

        vm.addClass = addClass;
        vm.removeClass = removeClass;
        vm.addInstructor = addInstructor;
        vm.removeInstructor = removeInstructor;
        vm.addHaqIndicator = addHaqIndicator;
        vm.addRecommendation = addRecommendation;
        vm.removeRecommendation = removeRecommendation;
        vm.onChangeSequence = onChangeSequence;

        init();
        function init() {
          findAllDecks();
          findQHPUsers();

          // sort haq tests
          $timeout(function(){
              vm.tests = _.sortBy(vm.tests, ['nameEn']);
          }, 1000);

          if ($stateParams.id) {
            Course.get({id : $stateParams.id}, function(res) {
              // set course data
              vm.course = res;
            });
          } else {
            vm.course = {
              name: null,
              description: null,
              active: true,
              id: null,
              haqIndicators: [{
                haqTriggers: [{}]
              }],
              classes: [{sequence: 1}],
              instructors: [{}]
            };
          }
        }

        function findAllDecks() {
            Deck.findAllByActive().then(function (response) {
                vm.decks = response ? response : [];
            });
        }

        function findQHPUsers() {
            User.getAllQHPUsersWithInstructorTask().then(function (response) {
                vm.users = response ? response : [];
            });
        }

        vm.onChangeIndicator = function () {
            checkDuplicateIndicator();
            checkDuplicateRecommendationType ();
        }

        vm.onChangeRecommendationType = function () {
            checkDuplicateRecommendationType();
        }

        function onChangeSequence(sequence, index) {
            checkDuplicateSequence();
        }

        var onSaveSuccess = function (result) {
            vm.isSaving = false;
            $state.go('course');
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            _.forEach(vm.course.classes, function(o) {
                o.sequence = parseInt(o.sequence);
            });
            vm.course.classes = _.sortBy(vm.course.classes, ['sequence']);

            _.forEach(vm.course.haqIndicators, function(i) {
                _.forEach(i.haqTriggers, function(o) {
                    o.value = parseInt(o.value);
                });
            });

            vm.isSaving = true;
            if (vm.course.id !== null) {
                Course.update(vm.course, onSaveSuccess, onSaveError);
            } else {
                Course.save(vm.course, onSaveSuccess, onSaveError);
            }
        };
        function checkDuplicateRecommendationType () {
            var haqIndicatorsClone = _.cloneDeep(vm.course.haqIndicators);
            var haqIndicators = [];
            _.forEach(haqIndicatorsClone, function(i) {
                if (i.testId) {
                    var test = _.find(vm.tests, function(t) { return t.id === i.testId; });
                    if (test) {
                        var list = [];
                        _.forEach(i.haqTriggers, function(o) {
                            if (o.recommendationType) {
                                var recommendationType = $translate.instant( 'ipmApp.recommendationType.' + o.recommendationType);
                                list.push(
                                    "'" + test.nameEn + "' on '" + recommendationType + "'"
                                );
                            }
                        });
                        haqIndicators = haqIndicators.concat(list);
                    }
                }
            });
            vm.isDuplicateRecommendationType = _.uniq(haqIndicators).length !== haqIndicators.length;

            var groupped = _.groupBy(haqIndicators, function (n) {return n});
            var dup = _.uniq(_.flatten(_.filter(groupped, function (n) {return n.length > 1})));
            vm.duplicateRecommendationType = dup.join(', ');
        }

        function checkDuplicateIndicator () {
            var haqIndicatorsClone = _.cloneDeep(vm.course.haqIndicators);
            var haqIndicators = [];
            _.forEach(haqIndicatorsClone, function(i) {
                if (i.testId) {
                    var test = _.find(vm.tests, function(t) { return t.id === i.testId; });
                    if (test) {
                        var list = [];
                        list.push(
                            "'" + test.nameEn + "'"
                        );
                        var uniqList = [];
                        var groups = _.groupBy(list, function (n) {return n});
                        _.forEach(groups, function(o) {
                            uniqList.push(o[0]);
                        });
                        haqIndicators = haqIndicators.concat(uniqList);
                    }
                }
            });
            vm.isDuplicateIndicator = _.uniq(haqIndicators).length !== haqIndicators.length;

            var groupped = _.groupBy(haqIndicators, function (n) {return n});
            var dup = _.uniq(_.flatten(_.filter(groupped, function (n) {return n.length > 1})));
            vm.duplicateIndicator = dup.join(', ');
        }

        function checkDuplicateSequence () {
            var classesClone = _.cloneDeep(vm.course.classes);
            _.forEach(classesClone, function(o) {
                o.sequence = parseInt(o.sequence);
            });
            var sequences = _.map(classesClone, 'sequence', _.unary(parseInt));
            vm.isDuplicateSequence = _.uniq(sequences).length !== sequences.length;

            var groupped = _.groupBy(sequences, function (n) {return n});
            var dup = _.uniq(_.flatten(_.filter(groupped, function (n) {return n.length > 1})));
            vm.duplicateSequence = dup.join(', ');
        }

        function addClass() {
            var seq = 1;
            var maxSequence = _.maxBy(vm.course.classes, 'sequence');
            if (maxSequence !== undefined) {
                seq = parseInt(maxSequence.sequence) + 1;
            }
            vm.course.classes.push({
                sequence: seq
            });
        }

        function showRemoveConfirm(errorKey) {
            $uibModal.open({
                templateUrl: 'app/entities/course/course-remove-class-dialog.html',
                controller: function($uibModalInstance, errorKey) {
                    var vm = this;
                    vm.errorKey = errorKey;
                    vm.clear = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                },
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                resolve: {
                    errorKey: function () {
                        return errorKey;
                    }
                }
            });
        }
        function removeClass(index) {
            if(vm.course.classes[index].used) {
                showRemoveConfirm('classError');
            } else {
                vm.course.classes = _.reject(vm.course.classes, function(v, i) { return index === i });
                checkDuplicateSequence();
            }
        }

        function addInstructor() {
            vm.course.instructors.push({});
        }

        function removeInstructor(index) {
            if(vm.course.instructors[index].used) {
                showRemoveConfirm('instructorError');
            } else {
                vm.course.instructors = _.reject(vm.course.instructors, function(v, i) { return index === i });
            }
        }

        function addHaqIndicator() {
            vm.course.haqIndicators.push({
                haqTriggers: [{}]
            });
        }

        function addRecommendation(haqIdx) {
            vm.course.haqIndicators[haqIdx].haqTriggers.push({});
        }

        function removeRecommendation(haqIdx, index) {
            vm.course.haqIndicators[haqIdx].haqTriggers = _.reject(vm.course.haqIndicators[haqIdx].haqTriggers, function(v, i) { return index === i });
            if (vm.course.haqIndicators[haqIdx].haqTriggers.length === 0) {
                vm.course.haqIndicators = _.reject(vm.course.haqIndicators, function(v, i) { return haqIdx === i });
            }
            checkDuplicateIndicator();
            checkDuplicateRecommendationType ();
        }

        vm.back = function () {
          $state.go('course');
      };
    }
})();
