(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('AdhocTestingDeleteController', AdhocTestingDeleteController);

    AdhocTestingDeleteController.$inject = ['$uibModalInstance', 'entity', 'HAQBatteryAdHocService'];

    function AdhocTestingDeleteController($uibModalInstance, entity, HAQBatteryAdHocService) {
        var vm = this;

        vm.haqBatteryAdHocId = entity ? entity.id : null;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isDeleting = false;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete() {
            vm.isDeleting = true;
            if (vm.haqBatteryAdHocId) {
                HAQBatteryAdHocService.delete({id: vm.haqBatteryAdHocId},
                    function () {
                        $uibModalInstance.close(true);
                    });
            }
        }

    }
})();
