(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("ipmApp")

.constant("ENV", "prod")

.constant("VERSION", "0.0.1-SNAPSHOT")

;
})();
