(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientImportController', PatientImportController);

    PatientImportController.$inject = ['$scope', '$state', 'Upload', 'ipmConstants'];

    function PatientImportController ($scope, $state, Upload, constants) {
        var vm = this;
        vm.isSaving = false;
        vm.isNotCsvFile = false;
        vm.isError = false;
        vm.importPatient = function (file) {
            if(file.length > 0) {
                var fileName = file[0].name;
                if(fileName.indexOf(constants.CSV_EXT, fileName.length - constants.CSV_EXT.length) !== -1) {
                    vm.isNotCsvFile = false;
                    vm.isSaving = true;
                    vm.numPatientsCreated = null;
                    vm.numPatientsUpdated = null;
                    vm.errorImportInfo = null;
                    Upload.upload({
                        url: 'api/patients/import',
                        file: file[0]
                    }).progress(function (evt) {
                        //var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);                        
                    }).success(function (data, status, headers, config) {
                        vm.isSaving = false;
                        vm.isError = false;
                        if(data.errorImportInfo) {
                            vm.errorImportInfo = data.errorImportInfo;
                            vm.errorNumMore = data.errorNumMore;
                        } else {
                            vm.numPatientsCreated = data.numOfPatientCreated;
                            vm.numPatientsUpdated = data.numOfPatientUpdated;
                        }
                    }).error(function (data, status, headers, config) {
                        vm.isSaving = false;
                        vm.isError = true;
                        vm.numPatientsCreated = null;
                        vm.numPatientsUpdated = null;
                    });
                } else {
                    vm.numPatientsCreated = null;
                    vm.numPatientsUpdated = null;
                    vm.errorImportInfo = null;
                    vm.isNotCsvFile = true;
                }
            }
        };
    }
})();
