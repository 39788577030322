(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('WellnessQuestionEditorController', WellnessQuestionEditorController);

    WellnessQuestionEditorController.$inject = ['$scope', '$rootScope','$state', '$stateParams', 'isCreateNew', 'WellnessQuestion', 'Upload'];

    function WellnessQuestionEditorController($scope, $rootScope, $state, $stateParams, isCreateNew, WellnessQuestion, Upload) {
        var vm = this;
        vm.isSaving = false;
        vm.isCreateNew = isCreateNew;
        vm.questionTypes = ['SELECT_ONE', 'MULTI_SELECT', 'FREE_TEXT'] ;
        vm.init = init;
        vm.save = save;
        vm.hasChanged = false;
        vm.addOption = addOption;
        vm.removeOption = removeOption;
        vm.fileId = null;
        vm.hasChanged = false;
        init();

        function init() {

            if ($stateParams.id) {
                WellnessQuestion.get({id : $stateParams.id}, function(res) {
                    // set test data
                    vm.question = res;
                    vm.fileId = vm.question.fileId;
                    if (!vm.question.options || vm.question.options.length === 0 ) {
                        vm.question.options = [];
                        vm.question.options.push({});
                        vm.question.options.push({});
                    } else if(vm.question.options.length === 1) {
                        vm.question.options = [];
                        vm.question.options.push({});
                    }
                });
            } else {
                vm.question = {
                    name: null,
                    prompt: null,
                    type: null,
                    id: null,
                    options: [{}, {}]
                };
            }
        }

        vm.uploadChanged = function (file) {
            if(file.length > 0) {
                vm.file = file[0];
                vm.hasChanged = true;
            }
        }

        function save() {
            // remove scoring message empty
            var options = [];
            angular.forEach(vm.question.options, function (option) {
                if (option.name || option.output ) {
                    options.push(option);
                }
            });
            // re-assign to question object
            vm.question.options = options;

            vm.isSaving = true;
            if(vm.isCreateNew) {
                WellnessQuestion.save(vm.question, onSaveSuccess, onSaveError);
            } else {
                WellnessQuestion.update(vm.question, onSaveSuccess, onSaveError);
            }

        }

        var onSaveSuccess = function (result) {
            if(vm.hasChanged) {
                Upload.upload({
                    url: 'api/wellness-questions/'+result.id+'/upload',
                    data: {file: vm.file}
                }).progress(function (evt) {
                    //var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                }).success(function (data, status, headers, config) {
                    vm.isSaving = false;
                    $state.go('wellness-question');
                }).error(function (data, status, headers, config) {

                });
            } else {
                vm.isSaving = false;
                $state.go('wellness-question');
            }
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        function addOption() {
            vm.question.options.push({});
        }

        function removeOption (index) {
            vm.question.options = _.reject(vm.question.options, function(v, i) { return index === i });
        }

        vm.removePdf = function() {
            vm.file = null;
            vm.fileId = null;
            vm.hasChanged = true;
        };

        vm.downloadPdf = function () {
            WellnessQuestion.downloadPatientFacingContent( vm.question.id).then(function(res) {

            });
        };

        vm.back = function () {
            $state.go('wellness-question');
        };
    }
})();
