(function () {
    'use strict';

    PdfOnlyViewComponent.$inject = ['$scope', 'pdfDelegate', 'ipmConstants'];

    function PdfOnlyViewComponent($scope, pdfDelegate, ipmConstants) {
        var vm = this; //jshint ignore:line
        vm.PDF_DELEGATE = 'pdf-container';
        vm.zoomToDefault = ipmConstants.PDF_DEFAULT_SCALE;
        vm.convertDataPdf = convertDataPdf;
        vm.isShowPdfCanvas = true;

        init();

        function init() {
            vm.currentPage =
                vm.currentPage && vm.currentPage > 0 ? vm.currentPage : 1;
            vm.zoomToScale = vm.zoomToDefault;
        }

        function convertDataPdf(pdfData) {
            if (pdfData) {
                if (vm.uint8Enable) {
                    return new Uint8Array(pdfData);
                }
                return pdfData;
            }
        }

        function zoomIn() {
            pdfDelegate.$getByHandle(vm.PDF_DELEGATE).zoomIn(Number(vm.zoomInScale));
        }

        function zoomOut() {
            pdfDelegate
                .$getByHandle(vm.PDF_DELEGATE)
                .zoomOut(Number(vm.zoomOutScale));
        }

        function zoomTo() {
            pdfDelegate
                .$getByHandle(vm.PDF_DELEGATE)
                .zoomTo(vm.zoomToScale > 0 ? vm.zoomToScale : vm.zoomToDefault);
        }

        function goToPage(currentPage) {
            pdfDelegate
                .$getByHandle(vm.PDF_DELEGATE)
                .goToPage(currentPage > 0 ? currentPage : vm.currentPage);
        }

        function reloadViewPdf(pdfData) {
            if (pdfData && pdfData.length > 0) {
                // have data
                pdfDelegate.$getByHandle(vm.PDF_DELEGATE).load(pdfData);
                zoomTo();
                vm.isShowPdfCanvas = true;
            } else {
                loadNewBlankFile();
            }
        }

        function loadNewBlankFile() {
            vm.isShowPdfCanvas = false;
        }

        // used reload Pdf when next or back slide
        $scope.$watch('vm.pdfData', function (newValue) {
            reloadViewPdf(convertDataPdf(vm.pdfData));
            if (vm.pdfData && vm.pdfData.length > 0) {
                goToPage();
            }
        });

        $scope.$watch('vm.currentPage', function (newValue) {
            if (vm.currentPage > 0) {
                goToPage();
            }
        });

        $scope.$watch('vm.zoomInScale', function (newValue) {
            if (vm.zoomInScale) {
                zoomIn();
            }
        });

        $scope.$watch('vm.zoomOutScale', function (newValue) {
            if (vm.zoomOutScale) {
                zoomOut();
            }
        });
        $scope.$watch('vm.zoomToScale', function (newValue) {
            if (vm.zoomToScale) {
                zoomTo();
            }
        });
    }

    angular.module('ipmApp').component('pdfOnlyView', {
        bindings: {
            pdfData: '<',
            uint8Enable: '<',
            currentPage: '<',
            zoomInScale: '<',
            zoomOutScale: '<',
            zoomToScale: '<',
        },
        template: '<div ng-if="vm.isShowPdfCanvas">' +
            '<pdf-viewer ng-if="vm.pdfData" ' +
            'delegate-handle="pdf-container" ' +
            'url="vm.convertDataPdf(vm.pdfData)" ' +
            'scale="1.3" ' +
            'show-toolbar="false" ' +
            "headers=\"{ 'x-you-know-whats-awesome': 'EVERYTHING' }\"></pdf-viewer>" +
            '</div>',
        controller: PdfOnlyViewComponent,
        controllerAs: 'vm',
    });
})();
