(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('addRequestPatientDetailController', addRequestPatientDetailController);

    addRequestPatientDetailController.$inject = ['$scope', '$uibModalInstance', 'entity', 'frpMdeService', '$uibModal'];

    function addRequestPatientDetailController($scope, $uibModalInstance, entity, frpMdeService, $uibModal) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.authorizedHour = {};
        init(entity);
        vm.files = [];


        function init(data) {
            vm.authorizedHour = data;
            vm.authorizedHour.deleteFiles = [];
            if (data.dateRequested)
                vm.authorizedHour.dateRequested = moment(data.dateRequested)._d;
            if (data.dateAuthorized)
                vm.authorizedHour.dateAuthorized = moment(data.dateAuthorized)._d;

            vm.oldData = angular.copy(vm.authorizedHour);
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.openCalendar = function (date) {
            vm.datePickerOpenStatus[date] = true;
        };

        vm.save = function () {
            if (!vm.checkValidation()) {
                return;
            }
            vm.authorizedHour.files = vm.files;
            frpMdeService.addUpdateRequestAuthorization(vm.authorizedHour);
            $uibModalInstance.close('saved');
        }

        vm.checkValidation = function(){
            if(vm.authorizedHour.dateRequested && vm.authorizedHour.hoursRequested) {
                return true;
            }

            if(vm.authorizedHour.dateAuthorized && vm.authorizedHour.hoursAuthorized) {
                 return true;
            }

            return false;
        }

        vm.unChanged = function() {
            return angular.equals(vm.oldData, vm.authorizedHour);
        }

        vm.uploadFile = function (files) {
            var countFileValid = 0;
            var listType = ['tiff', 'docx', 'pdf'];
            files.forEach(function (element) {
                var names = element.name.split('.');
                var fileType = names[names.length - 1];
                listType.forEach(function (type) {
                    if(fileType == type) {
                        countFileValid ++;
                    }
                });
            });
            if(countFileValid != files.length){
                vm.errorDialog();
                return;
            }
            files.forEach(function (element) {
                var names = element.name.split('.');
                vm.authorizedHour.attachments.push({
                    fileName: element.name,
                    fileExt: names[names.length - 1]
                })
                vm.files.push(element);
            });
        }

        vm.confirmDialog = function (value) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                size: 'sm',
                resolve: {
                    message: function () {
                        var message = {
                        'content': "ipmApp.patient.authorizedHours.message",
                        'param': value.fileName
                         };
                        return message;
                    }
                }
            }).result.then(function (result) {
                if(result) {
                    vm.deleteFile(value);
                }
            });
        }

       vm.errorDialog = function () {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/notify-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                size: 'sm',
                resolve: {
                    message: function () {
                        var message = {
                        'content': "ipmApp.patient.authorizedHours.errorMessage",
                         };
                        return message;
                    }
                }
            })
        }

        vm.deleteFile = function (item) {
            vm.authorizedHour.deleteFiles.push(item);
            vm.authorizedHour.attachments = vm.authorizedHour.attachments.filter(function(value, index, arr){
               return !angular.equals(value, item);
            });
        }
    }
})();
