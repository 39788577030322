(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('Patient', Patient);

    Patient.$inject = ['$resource', 'DateUtils', '$http'];

    function Patient ($resource, DateUtils, $http) {
        var resourceUrl =  'api/patients/:id';

        var service = $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.dateOfBirth = DateUtils.convertLocalDateFromServer(data.dateOfBirth);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
                    return angular.toJson(data);
                }
            }
        });

        service.findByNameOrChartNumber = findByNameOrChartNumber;

        function findByNameOrChartNumber(query, officeId, pageable) {
            var params = {
                query: query,
                officeId:officeId,
                page: pageable.page,
                size: pageable.size,
                sort: pageable.sort
            };
            return $http.get('api/patients/search/by-name-or-chart-number', {
                params: params
            }).then(function (response) {
                return response;
            });
        }

        return service;
    }
})();
