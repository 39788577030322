(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadActivityReportController', DownloadActivityReportController);

    DownloadActivityReportController.$inject = ['$filter', 'ActivityReportService', '$uibModal', 'serviceType', '$interval', '$scope', 'DateUtils'];

    function DownloadActivityReportController ($filter, ActivityReportService, $uibModal, serviceType, $interval, $scope, DateUtils) {
        var vm = this;
        vm.download = download;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;
        vm.serviceType = serviceType;
        vm.invalidDates = false;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        vm.limitTimeMillisSecondsDownload = 1000*30; // 30 seconds
        vm.limitTimeStart = null;
        vm.hasSubmitedRequestDownloadCsv = false;
        vm.hasOpenningDialogRequest = false;
        var intervalLimitTimeDownload = null;

        init();

        function init() {

            // set to Monday of this week
            vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
            // set to previous Monday
            vm.fromDate.setDate(vm.fromDate.getDate() - 7);

            // set to previous Friday
            vm.toDate = new Date(vm.fromDate);
            vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        function download() {
            vm.isProcessing = true;
            resetIntervalLimitTime();
            startIntervalLimitTimeDownload();
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            if (vm.serviceType === 'HAQ') {
                downloadCsvHAQ(fromDate, toDate);
            } else if (vm.serviceType === 'CPR') {
                downloadCsvCPR(fromDate, toDate);
            }
        }

        function requestDownloadCsvHAQ(fromDate, toDate) {
            if (!vm.hasSubmitedRequestDownloadCsv) {
                vm.isProcessing = true;
                ActivityReportService.requestDownloadCsvHAQ(fromDate, toDate).then(function (res) {
                    vm.isProcessing = false;
                    vm.noPdf = false;
                    vm.hasSubmitedRequestDownloadCsv = true;
                });
            }
            stopIntervalLimitTimeDownload();
        }

        function requestDownloadCsvCPR(fromDate, toDate) {
            if (!vm.hasSubmitedRequestDownloadCsv) {
                ActivityReportService.requestDownloadCsvCPR(fromDate, toDate).then(function(res) {
                    vm.isProcessing = false;
                    vm.noPdf = false;
                    vm.hasSubmitedRequestDownloadCsv = true;
                });
            }
            stopIntervalLimitTimeDownload();
        }

        function downloadCsvHAQ(fromDate, toDate) {
            vm.noPdf = false;
            ActivityReportService.downloadCsvHAQ(fromDate, toDate).then(function(res) {
                vm.isProcessing = false;
                if (res === 204) { // SC_NO_CONTENT
                    vm.noPdf = true;
                }
                if (res === 509) { // BANDWIDTH_LIMIT_EXCEEDED
                    if (!vm.hasOpenningDialogRequest)
                        openDialogRequestDownloadCsvHAQ(fromDate, toDate);
                }
                // call stop interval when download empty < 30s
                stopIntervalLimitTimeDownload();
            });
        }

        function downloadCsvCPR(fromDate, toDate) {
            vm.noPdf = false;
            ActivityReportService.downloadCsvCPR(fromDate, toDate).then(function(res) {
                vm.isProcessing = false;
                if (res === 204) { // SC_NO_CONTENT
                    vm.noPdf = true;
                }
                if (res === 509) { // BANDWIDTH_LIMIT_EXCEEDED
                    if (!vm.hasOpenningDialogRequest)
                        openDialogRequestDownloadCsvCPR(fromDate, toDate);
                }
                // call stop interval when download empty < 30s
                stopIntervalLimitTimeDownload();
            });
        }

        function openDialogRequestDownloadCsvHAQ(fromDate, toDate) {
            vm.hasOpenningDialogRequest = true;
            $uibModal.open({
                templateUrl: 'app/shared/confirm-download-dialog/confirm-download-dialog.controller.html',
                controller: 'ConfirmDownloadController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                backdrop: 'static',
                size: 'sm'
            }).result.then(function (result) {
                if (result) { // Yes
                    requestDownloadCsvHAQ(fromDate, toDate);
                }
                // case No
                stopIntervalLimitTimeDownload();
                resetProcessingDownload();
            });
        }

        function openDialogRequestDownloadCsvCPR(fromDate, toDate) {
            vm.hasOpenningDialogRequest = true;
            $uibModal.open({
                templateUrl: 'app/shared/confirm-download-dialog/confirm-download-dialog.controller.html',
                controller: 'ConfirmDownloadController',
                controllerAs: 'vm',
                windowClass: 'center-dialog-message warning-dialog',
                backdrop: 'static',
                size: 'sm'
            }).result.then(function (result) {
                if (result) { // Yes
                    requestDownloadCsvCPR(fromDate, toDate);
                }
                // case No
                stopIntervalLimitTimeDownload();
                resetProcessingDownload();
            });
        }

        function resetProcessingDownload() {
            vm.isProcessing = false;
        }

        function resetIntervalLimitTime() {
            vm.hasSubmitedRequestDownloadCsv = false;
            vm.limitTimeStart = moment();
            vm.hasOpenningDialogRequest = false;
        }

        function stopIntervalLimitTimeDownload() {
            if (intervalLimitTimeDownload) {
                clearInterval(intervalLimitTimeDownload);
                $interval.cancel(intervalLimitTimeDownload);
            }
        }

        function startIntervalLimitTimeDownload() {
            intervalLimitTimeDownload = setInterval(function () {
                runIntervalLimitTimeDownload();
            }, 2000); // 2 seconds
        }

        function runIntervalLimitTimeDownload() {
            var millisDiff = moment().diff(vm.limitTimeStart);
            if (millisDiff > vm.limitTimeMillisSecondsDownload) {
                var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
                var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
                if (vm.serviceType === 'HAQ') {
                    if (!vm.hasOpenningDialogRequest)
                        openDialogRequestDownloadCsvHAQ(fromDate, toDate);
                } else if (vm.serviceType === 'CPR') {
                    if (!vm.hasOpenningDialogRequest)
                        openDialogRequestDownloadCsvCPR(fromDate, toDate);
                }
                // stop interval
                stopIntervalLimitTimeDownload();
            }
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };

        $scope.$on('$destroy', function () {
            stopIntervalLimitTimeDownload();
        });
    }
})();
