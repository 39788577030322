(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('MDEReportConclusionController', MDEReportConclusionController);

    MDEReportConclusionController.$inject = ['$uibModal', '$state', '$scope', '$stateParams', 'MdeReportService', 'Principal', 'ipmConstants', 'DataUtils', 'templates', 'isView'];

    function MDEReportConclusionController($uibModal, $state, $scope, $stateParams, MdeReportService, Principal, ipmConstants, DataUtils, templates, isView) {
        var vm = this;
        vm.templates = templates ? templates : [];
        vm.isSaving = false;
        vm.isDisabled = false;
		vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.templateInputType = ipmConstants.FRP_MDE_TEMPLATE;
        vm.isView = isView ? true : false;
        vm.requiredFields = [
            'field_conclusion',
            'field_frp',
            'field_description'
        ];
        vm.dataHeaderReport = {};

        init();

        function init() {
            vm.mdeReportId = $stateParams.id;
            MdeReportService.getMdeConclusionReport($stateParams.id).then(function(data) {
                vm.report = data;
                vm.reportOld = DataUtils.deepCopy(data);
                vm.isView = vm.isView ? true : vm.report ? vm.report.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;
                if (vm.report.mdeStatus === 'MDE_ABANDONED' || vm.isView || vm.report.isViewFrpProgram) {
                    vm.isDisabled = true;
                }
                vm.dataHeaderReport = buildDataHeaderReport(vm.report);
            });
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patientName,
                    chartNumber: patientFRPMDE.chartNumber,
                    authorizedProviderName: patientFRPMDE.providerName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    mdeOfficeName: patientFRPMDE.mdeOfficeName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.dateOfBirth
                };
            }
        }

        vm.frpChange = function() {
        	if (vm.report.frp === null) {
                vm.report.hours = null;
        	} else {
                vm.report.hours = 'MDE_HOURS_180';
            }

            if(DataUtils.isEqualText(vm.reportOld.frp, vm.report.frp)) {
                vm.report.treatmentContent = vm.reportOld.treatmentContent;
            } else {
                vm.report.treatmentContent = null;
            }
        }

        function checkValidation() {
            vm.requiredFields.forEach(function (field) {
                if (field && vm.editForm[field]) {
                    vm.editForm[field].$setDirty();
                }
            });
        }

        vm.save = function() {
            submitData(false);
        }

        vm.finalize = function() {
            checkValidation();
            if (vm.editForm.$invalid) {
                return;
            }
            submitData(true);
        }

        vm.isChange = function () {
            if(!vm.report)
                return false;

            var isEqual = true;
            // Conclusion
            isEqual = DataUtils.isEqualText(vm.reportOld.conclusion, vm.report.conclusion);
            if (!isEqual) return true;
            // What treatment option do you recommend?
            isEqual = DataUtils.isEqualText(vm.reportOld.frp, vm.report.frp);
            if (!isEqual) return true;
            // Hours
            isEqual = DataUtils.isEqualText(vm.reportOld.hours, vm.report.hours) && DataUtils.isEqualText(vm.reportOld.treatmentContent, vm.report.treatmentContent);
            if (!isEqual) return true;
            return !isEqual;
        }

        function back() {
            $state.go('mde-report', {id: vm.mdeReportId});
        }

        vm.cancel = function() {
            var isReportChanged = vm.isChange();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        function submitData(finalize) {
            if (vm.isView) {
                return;
            }
            vm.report.finalize = finalize;
            vm.isSaving = true;
            MdeReportService.saveMdeConclusionReport(vm.mdeReportId, vm.report).then(function (response) {
                onSaveSuccess(response)
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess (response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }
    }
})();
