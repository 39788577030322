(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('LandingPageService', LandingPageService);

    LandingPageService.$inject = ['$http', 'halClient', '$q'];

    function LandingPageService ($http, halClient, $q) {
        var baseURL = 'api/landing-page/';

        var service = {
            'getLandingPage': getLandingPage
        };

        function getLandingPage(isLaunchAction, noWait, haqId) {
            var url = baseURL;
            return $http.get(url, { params: { isLaunchAction: isLaunchAction, noWait: noWait, haqId: haqId }}).then(function (resp) {
                return resp;
            });
        };

        return service;
    };
})();
