(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('diagnosticStudiesComponent', diagnosticStudiesComponent);

    diagnosticStudiesComponent.$inject = [];

    function diagnosticStudiesComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectiveDiagnosticStudiesController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/diagnostic-studies.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveDiagnosticStudiesController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectiveDiagnosticStudiesController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        function init() {
        }

        vm.datePickerOptions = {
            maxDate: new Date()
        };

        vm.hadAnyDiagnosticStudiesResponse = function() {
            var find = _.find(vm.workflow.answer.diagnostics, { 'checked': true });
            return find;
        };

        init();
    }
})();
