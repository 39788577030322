(function() {
    'use strict';


    CPRTextInputQuestionController.$inject = ['$scope', '$state', 'ipmConstants'];

    function CPRTextInputQuestionController($scope, $state, ipmConstants) {
        var vm = this; //jshint ignore:line
        vm.isFreeTextInputRequired = isFreeTextInputRequired;
        vm.getEditorTextId = ''; // used to show quill editor
        vm.freeTextFieldId = ''; // used to check validation field
        init();

        function init() {
            if (vm.question) {
                vm.getEditorTextId = 'editorTextField_' + vm.question.id;
                vm.freeTextFieldId = 'freeTextField_' + vm.question.id;
            }
            // init answer
            if (vm.answers) {
                vm.valueAnswer = vm.answers.get(vm.question.code);
            }
        }

        function isFreeTextInputRequired() {
            return vm.question.required;
        }

        vm.minlengthInputRequired = function() {
            if (ipmConstants.MINLENGTH_FREETEXT_REQUIRED_CODE.includes(vm.question.code)) {
                return 0;
            }
            if (vm.question.code === 'CPR_040') {
                return 10;
            }
            if (vm.isFreeTextInputRequired()) {
                return 25;
            }
            return 0;
        };

        vm.isDisplayLabel = function () {
            return ipmConstants.DISPLAY_LABEL_CODE.includes(vm.question.code);
        }

        vm.handleTextChange = function(data, evaluationForm) {
            if (evaluationForm && evaluationForm[vm.freeTextFieldId] && !evaluationForm[vm.freeTextFieldId].$dirty) {
                evaluationForm[vm.freeTextFieldId].$setDirty();
            }
            vm.valueAnswer = data; // update data valueAnswer
            vm.onChange({ data: { question: vm.question, value: vm.valueAnswer }});
        };

        vm.getMaxLength = function() {
            if (vm.question.code === 'CPR_012') {
                return 10000;
            }
            return undefined;
        };
    }

    angular.module('ipmApp').component('cprTextInputQuestion', {
        bindings: {
            question: '<',
            answers: '<',
            onChange: '&'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/question-component/text-input/text-input.html',
        controller: CPRTextInputQuestionController,
        controllerAs: 'vm'
    });
})();
