(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
        $stateProvider
        .state('class-session', {
            abstract: true,
            parent: 'entity',
            data: {
                authorities: ['ROLE_ADMIN'],
            },
            params: {
                queryParams: null
            }
        })
        .state('class-session.list', {
            parent: 'class-session',
            url: '/class-session/{courseId}/list',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/class-session/class-session.html',
                    controller: 'ClassSessionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                course: ['Course', '$stateParams', function (Course, $stateParams) {
                    if (constants.HIDE_WELLNESS_CLASSES) {
                        return null;
                    }
                    return Course.get({id: $stateParams.courseId}).$promise;
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('class-session');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('class-session.list.new', {
            parent: 'class-session.list',
            url: '/new',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/class-session/class-session-editor.html',
                    controller: 'ClassSessionEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        isEdit: function () {
                            return false;
                        },
                        isCreateNew: function () {
                            return true;
                        },
                        isView: function () {
                            return false;
                        },
                        entity: ['$stateParams', function ($stateParams) {
                            return {
                                id: null,
                                courseId: parseInt($stateParams.courseId)
                            };
                        }],
                        course: ['Course', function (Course) {
                            if (constants.HIDE_WELLNESS_CLASSES) {
                                return null;
                            }
                            return Course.get({id: $stateParams.courseId}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('class-session.list.edit', {
            parent: 'class-session.list',
            url: '/{classSessionId}/edit',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/class-session/class-session-editor.html',
                    controller: 'ClassSessionEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        isEdit: function () {
                            return true;
                        },
                        isCreateNew: function () {
                            return false;
                        },
                        isView: function () {
                            return false;
                        },
                        entity: ['ClassSessionService', function (ClassSessionService) {
                            return ClassSessionService.get($stateParams.classSessionId);
                        }],
                        course: ['Course', function (Course) {
                            if (constants.HIDE_WELLNESS_CLASSES) {
                                return null;
                            }
                            return Course.get({id: $stateParams.courseId}).$promise;
                        }],
                        instructors: ['User', function (User) {
                            return User.getAllQHPUsersWithInstructorTask();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('class-session.list.view', {
            parent: 'class-session.list',
            url: '/{classSessionId}/view',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/class-session/class-session-editor.html',
                    controller: 'ClassSessionEditorController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        isEdit: function () {
                            return false;
                        },
                        isCreateNew: function () {
                            return false;
                        },
                        isView: function () {
                            return true;
                        },
                        entity: ['ClassSessionService', function (ClassSessionService) {
                            return ClassSessionService.get($stateParams.classSessionId);
                        }],
                        course: ['Course', function (Course) {
                            if (constants.HIDE_WELLNESS_CLASSES) {
                                return null;
                            }
                            return Course.get({id: $stateParams.courseId}).$promise;
                        }],
                        instructors: ['User', function (User) {
                            return User.getAllQHPUsersWithInstructorTask();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('class-session.list.delete', {
            parent: 'class-session.list',
            url: '/{classSessionId}/delete',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.classSession.home.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/class-session/class-session-delete-dialog.html',
                    controller: 'ClassSessionDeleteController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    windowClass: 'absolute-center',
                    resolve: {
                        entity: ['ClassSessionService', function (ClassSessionService) {
                            return ClassSessionService.get($stateParams.classSessionId);
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
