(function() {
    'use strict';

    CheckboxRowController.$inject = [];

    function CheckboxRowController() {
        var vm = this; //jshint ignore:line
        vm.specialTextField = 'LEFT_RIGHT_TEXT_FIELD';
        vm.isDisableClassification = true;

        init();

        function init() {
            if (vm.item) {
                vm.item.convertItemCode = vm.item.code.replace("-", "_");
            }
        }

        vm.checkboxChange = function() {
            // clear value;
            vm.item.score = null;
            vm.item.scoreLeft = null;
            vm.item.scoreRight = null;
            vm.item.severity = null;
            vm.item.notes = null;
            vm.onChange();
        }

        vm.getIdScore = function (srCode) {
            return 'score_' + srCode;
        }

        vm.getIdScoreRight = function (srCode) {
            return 'scoreRight_' + srCode;
        }

        vm.getIdScoreLeft = function (srCode) {
            return 'scoreLeft_' + srCode;
        }
    }

    angular.module('ipmApp').component('checkboxRow', {
        bindings: {
            item: '<',
            typeRow: '<',
            isDisabled: '<',
            severity: '<',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/psych/components/checkbox-row.html',
        controller: CheckboxRowController,
        controllerAs: 'vm'
    })
})();
