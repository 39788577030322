(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CompletionReportsController', CompletionReportsController);

    CompletionReportsController.$inject = ['$scope', '$filter', 'CompletionReportsService'];

    function CompletionReportsController ($scope, $filter, CompletionReportsService) {
        var vm = this;
        
        vm.isProcessing = false;
        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        
        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        
        function init() {
        	//vm.toDate.setDate(vm.toDate.getDate() - 1);
        	//vm.fromDate.setDate(vm.toDate.getDate() - 7);
        	
        	// set to Monday of this week
        	vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
        	// set to previous Monday
        	vm.fromDate.setDate(vm.fromDate.getDate() - 7);
        	
        	// set to previous Friday
        	vm.toDate = new Date(vm.fromDate);
        	vm.toDate.setDate(vm.toDate.getDate() + 4);
        }
        
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
        
        vm.report = function () {
            vm.isProcessing = true;
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            CompletionReportsService.exportPatientsCompletedCSV(fromDate, toDate).then(function(res) {
            	vm.isProcessing = false;
            });
        };
        
        init();
    }
})();
