(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SectionController', SectionTestController);

    SectionTestController.$inject = ['$scope', '$timeout', 'Section'];

    function SectionTestController ($scope, $timeout, Section) {
        var vm = this;
        vm.totalItems = 0;
        vm.pageSize = 10000;
        vm.page = 1;
        vm.sections = [];
        vm.getSectionByTest = getSectionByTest;
        vm.sortableOptions = {
            placeholder: "ui-state-highlight",
            helper: function (event, ui) {
                var table = '<table style="position: absolute;" class="jh-table table table-striped"></table>';
                var draggedItem = $(table).append(ui[0].outerHTML);
                return draggedItem;
            },
            stop: function(e, ui) {
                var sections = ui.item.sortable.sourceModel;
                sections.forEach(function(section, index) {
                    section.orderNumber = (index + 1);
                });
                // call back-end to update
                Section.updateOrderNumber(vm.idt, sections).then();
            },
            disabled: vm.batteryId ? true : false
        };

        getSectionByTest();

        function getSectionByTest() {
            var pageable = {
                page: vm.page > 0 ? vm.page - 1 : vm.page,
                size: vm.pageSize
            };
            $timeout(function(){
                if (vm.idt) {
                    Section.findByTest(vm.idt, pageable).then(function(response) {
                        vm.totalItems = response && response.totalItems ? response.totalItems : 0;
                        vm.sections = response && response.data ? response.data : [];
                    })
                }
            }, 100);
            
            
        };
        
    }

    angular.module('ipmApp').component('sectionListForTest', {
        bindings: {
            idt: '<',
        },
        templateUrl: 'app/entities/section/sections-test.html',
        controller: SectionTestController,
        controllerAs: 'vm'
    })
})();
