(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('DownloadHAQResultsReportsController', DownloadHAQResultsReportsController);

    DownloadHAQResultsReportsController.$inject = ['$state', '$filter', '$stateParams', '$window', 'SurveyResultService', 'DateUtils', 'isAdhoc'];

    function DownloadHAQResultsReportsController ($state, $filter, $stateParams, $window, SurveyResultService, DateUtils, isAdhoc) {
        var vm = this;
        vm.download = download;

        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = 'MM/dd/yyyy';
        vm.fromDate = new Date();
        vm.toDate = new Date();
        vm.toDateInvalid = null;
        vm.fromDateInvalid = null;
        vm.noPdf = false;
        vm.isAnotherDownloading = false;
        vm.inValidDates = false;
        vm.isAdhoc = isAdhoc;

        vm.datePickerOpenStatus = {fromDate: false, toDate: false};
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        init();

        function init() {

            // set to Monday of this week
        	vm.fromDate.setDate(vm.fromDate.getDate() - (vm.fromDate.getDay() + 6) % 7);
        	// set to previous Monday
        	vm.fromDate.setDate(vm.fromDate.getDate() - 7);

        	// set to previous Friday
        	vm.toDate = new Date(vm.fromDate);
        	vm.toDate.setDate(vm.toDate.getDate() + 4);
        }

        function download() {
            var fromDate = $filter('date')(vm.fromDate, vm.dateFormatToServer);
            var toDate = $filter('date')(vm.toDate, vm.dateFormatToServer);
            var adhocReport = vm.isAdhoc;
            vm.noPdf = false;
            vm.isAnotherDownloading = false;
            SurveyResultService.downloadHAQResultsReports(fromDate, toDate, adhocReport).then(function(res) {
                vm.isProcessing = false;
                if (res.status === 204 && JSON.parse(res.statusDownload)) {
                    vm.isAnotherDownloading = true;
                } else if (res.status === 204) {
                    vm.noPdf = true;
                    vm.isAnotherDownloading = false;
                }
            });
        }

        vm.onChangeDate = function () {
            vm.inValidDates = DateUtils.isValidFromDateAndToDate(vm.fromDate, vm.toDate);
        };
    }
})();
