(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('FrpMdeReportsService', FrpMdeReportsService);

    FrpMdeReportsService.$inject = ['$http', '$q', 'DownloadBlobService'];

    function FrpMdeReportsService ($http, $q, DownloadBlobService) {
    	return {
    		downloadReports: function(fromDate, toDate) {
                var deferred = $q.defer();
    			$http({
                    url: 'api/frp-mde/download-reports',
                    method: "GET",
                    params: {fromDate: fromDate, toDate: toDate},
                    headers: {
                        'Content-type': 'application/json',
                    },
                    responseType: 'arraybuffer'
                }).success(function (data, status, headers, config) {
                    if (status && status === 200) {
                        var contentType = headers('content-type');
                        var blob = new Blob([data], {type: contentType});
                        DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                        deferred.resolve({"status": status});
                    } else {
                        var reason = headers('no-content-reason');
                        deferred.resolve({"status": status, "reason": reason});
                    }
                }).error(function (data, status, headers, config) {
                    deferred.resolve({"status": status});
                });
    			return deferred.promise;
    		}
    	};
    }
})();
