(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SubmitPageHAQEvaluationController', SubmitPageHAQEvaluationController);

        SubmitPageHAQEvaluationController.$inject = ['$state', 'evaluationEntity', 'HAQEvaluationWorkflowService', 'signature', 'ClearLocalStorageService'];

    function SubmitPageHAQEvaluationController($state, evaluationEntity, HAQEvaluationWorkflowService, signature, ClearLocalStorageService) {
        var vm = this;
        vm.isSaving = false;
        vm.evaluationEntity = evaluationEntity;
        vm.mostRecentSignature = signature ? signature : null;
        vm.isShowSignaturePad = true;
        vm.signatureId = null; // if signatureId is not null used mostRecent signature
        init();

        function init() {
            ClearLocalStorageService.clearTimeSpent(); // clear & stop time spent
            vm.isShowSignaturePad = vm.mostRecentSignature && vm.mostRecentSignature.id && vm.mostRecentSignature.data ? false : true;
        }

        vm.onChangeSignature = function(data) {
            vm.signatureString = data;
        }

        vm.finishEvaluation = function() {
            vm.isSaving = true;
            if (!vm.signatureString && !vm.isShowSignaturePad && vm.mostRecentSignature && vm.mostRecentSignature.id) {
                vm.signatureId = vm.mostRecentSignature.id;
            }
            HAQEvaluationWorkflowService.finishEvaluation({ haqEvaluationId: vm.evaluationEntity.haqEvaluationId, signatureString: vm.signatureString, signatureId: vm.signatureId }).then(function() {
                $state.go('home');
            }, function errorSave(error) {
                onSaveError(error);
            });
        }

        vm.checkSignature = function() {
            if (vm.isShowSignaturePad) {
                return !vm.signatureString;
            }
            return !(vm.mostRecentSignature && vm.mostRecentSignature.id);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            if (error.status === 400) {
                ClearLocalStorageService.clearTimeSpent();
                $state.go('home');
            }
        }
    }
})();
