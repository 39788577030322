(function() {
    'use strict';

    angular
        .module('ipmApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils ($filter) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            convertLocalDateTimeToServer : convertLocalDateTimeToServer,
            convertDateTimeToDateFromServerAndUpdateHours : convertDateTimeToDateFromServerAndUpdateHours,
            dateformat : dateformat,
            convertDateIgnoreTimeZone: convertDateIgnoreTimeZone,
            isValidFromDateAndToDate: isValidFromDateAndToDate
        };

        return service;

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        function convertLocalDateTimeToServer (date) {
            if (date) {
                return $filter('date')(date, "yyyy-MM-dd HH:mm:ss Z");
            } else {
                return null;
            }
        }

        function convertDateTimeToDateFromServerAndUpdateHours(date) {
            if (date == null) {
                return null;
            }
            var convertLocalDate = new Date(date);
            convertLocalDate.setHours(0, 0, 0);
            return convertLocalDate;
        }

        function convertDateIgnoreTimeZone(date) {
            if (date == null) {
                return null;
            }
            var convertLocalDate = new Date(date);
            return convertLocalDate.setMinutes(convertLocalDate.getMinutes() + convertLocalDate.getTimezoneOffset())
        }

        function isValidFromDateAndToDate(fromDate, toDate) {
            var isValid = fromDate !== undefined && toDate !== undefined;
            if (!fromDate || !toDate) {
                return;
            }
            fromDate.setHours(0, 0, 0, 0);
            toDate.setHours(0, 0, 0, 0);
            return isValid && fromDate > toDate;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }
    }

})();
