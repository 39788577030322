(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('skipDialogController', skipDialogController);

    skipDialogController.$inject = [
        '$scope',
        '$uibModalInstance',
        '$q',
        '$state',
        'ipmConstants',
        'patientId',
        'CarePlanReviewWorkflowService'
    ];

    function skipDialogController($scope, $uibModalInstance, $q, $state, ipmConstants, patientId, CarePlanReviewWorkflowService) {
        var vm = this;
        vm.skipReasons = ipmConstants.SKIP_REASON;
        vm.skipReview = {};
        vm.skipReview.patientId = patientId;
        vm.isSaving = false;

        vm.yes = function () {
            vm.isSaving = true;
            CarePlanReviewWorkflowService.skipReviewCPR(vm.skipReview).then(function (res) {
                if (res) {
                    vm.isSaving = false;
                    $uibModalInstance.close(true);
                    $uibModalInstance.dismiss('cancel');
                    $state.go('care-plan-review');
                }
            }).catch(function () {
                vm.isSaving = false;
            });
        }

        vm.no = function(){
            $uibModalInstance.close(false);
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
