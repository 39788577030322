(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('reviewOfSystemsComponent', reviewOfSystemsComponent);

    reviewOfSystemsComponent.$inject = [];

    function reviewOfSystemsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '=',
                symptomGroups: '='
            },
            link: linkFunc,
            controller: DirectiveReviewOfSystemsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/review-of-systems.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveReviewOfSystemsController.$inject = ['$scope', '$stateParams', '$element', '$timeout'];
    function DirectiveReviewOfSystemsController($scope, $stateParams, $element, $timeout) {
        var vm = this;
        
        function init() {
            for (var i = 0; i < vm.symptomGroups.length; i++) {
                var group = vm.symptomGroups[i];
                var symptomNoneNormal = group.symptomsSorted[0];
                var disabled = vm.workflow.answer.symptomIdsSelected[symptomNoneNormal.id] || false;
                for(var j = 1; j < group.symptomsSorted.length; j ++) {
                    var symptom = group.symptomsSorted[j];
                    symptom.disabled = disabled;
                    if(disabled && symptom.id) {
                        vm.workflow.answer.symptomIdsSelected[symptom.id] = false;
                    }
                }
            }
        }

        vm.symptomChanged = function(group) {
            var symptomNoneNormal = group.symptomsSorted[0];
            var disabled = vm.workflow.answer.symptomIdsSelected[symptomNoneNormal.id] || false;
            for(var j = 1; j < group.symptomsSorted.length; j ++) {
                var symptom = group.symptomsSorted[j];
                symptom.disabled = disabled;
                if (disabled && symptom.id) {
                    vm.workflow.answer.symptomIdsSelected[symptom.id] = false;
                }
            }
        };
        init();
    }
})();
