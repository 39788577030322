(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('workRelatedInjuriesComponent', workRelatedInjuriesComponent);

    workRelatedInjuriesComponent.$inject = [];

    function workRelatedInjuriesComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            link: linkFunc,
            controller: DirectiveWorkRelatedInjuriesController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/work-related-injuries.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs) {

        }
    }

    DirectiveWorkRelatedInjuriesController.$inject = ['$scope', '$stateParams', '$element', '$timeout', 'ipmConstants'];
    function DirectiveWorkRelatedInjuriesController($scope, $stateParams, $element, $timeout, constants) {
        var vm = this;
        
        vm.datePickerOpenStatus = {
            dateOfInjury: false,
            lastDayWorked: false
        };
        
        vm.datePickerOptions = {
            maxDate: new Date()
        };
        
        function init() {
        }

        init();
    }
})();
