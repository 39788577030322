(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('currentIndicationsComponent', currentIndicationsComponent);

    currentIndicationsComponent.$inject = [];

    function currentIndicationsComponent() {
        var directive = {
            restrict: 'EA',
            scope: {
                workflow: '='
            },
            require:"^form", //inject parent form as the forth parameter to the link functio
            link: linkFunc,
            controller: DirectiveCurrentIndicationsController,
            templateUrl: 'app/npq-questionnaire/workflow/partial/directive/current-indications.directive.html',
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        function linkFunc(scope, element, attrs, form) {
            scope.form = form;            
        }
    }

    DirectiveCurrentIndicationsController.$inject = ['$scope', '$element', '$timeout'];
    function DirectiveCurrentIndicationsController($scope, $element, $timeout) {
        var vm = this;

        function init() {            
        }

        vm.numbnessTinglingResponseRequired = function() {
            var find = _.find(vm.workflow.answer.numbnessTinglingResponse, { 'checked': true });
            return vm.workflow.answer.haveAnyNumbnessOrTingling === 'YES' && !find;
        };

        vm.weaknessResponseRequired = function() {
            var find = _.find(vm.workflow.answer.weaknessResponse, { 'checked': true });
            return vm.workflow.answer.haveAnyWeakness === 'YES' && !find;
        };
        init();
    }
})();
