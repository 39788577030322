(function() {
    'use strict';
    angular
        .module('ipmApp')
        .factory('CallLogService', CallLogService);

    CallLogService.$inject = ['$resource','$q', 'halClient', '$http', 'DateUtils'];

    function CallLogService($resource, $q, halClient, $http, DateUtils) {
        var baseUrl = 'api/call-logs';
        var resourceUrl = baseUrl + '/:id';
        var service = $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                transformRequest: function (data) {
                    data.dateLog = DateUtils.convertLocalDateTimeToServer(data.dateLog);
                    return angular.toJson(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.dateLog = DateUtils.convertLocalDateTimeToServer(data.dateLog);
                    return angular.toJson(data);
                }
            },
            'delete': {method: 'DELETE'}
        });
        service.findAllByPatientId = findAllByPatientId;

        function findAllByPatientId(patientId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + '/find-all-by-patient-id/' + patientId;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        return service;

    }
})();
