(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('FRPMDETemplateService', FRPMDETemplateService);

    FRPMDETemplateService.$inject = ['$resource', '$http', '$q'];

    function FRPMDETemplateService($resource, $http, $q) {
        var resourceUrl = "api/frp-mde-template";

        var service = $resource("api/frp-mde-template/:id", {}, {
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.toJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data = angular.toJson(data);
                    return data;
                }
            },
            'delete': { method: 'DELETE' }
        });

        service.getAllTemplateByInputTypes = getAllTemplateByInputTypes;

        function getAllTemplateByInputTypes(inputTypes) {
            var deferred = $q.defer();
            var url = resourceUrl;
            $http.get(url, {
                params: {
                    inputTypes: inputTypes
                }
            }).success(function (result) {
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        return service;
    };
})();
