(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CourseRegisterWorkflowController', CourseRegisterWorkflowController);

        CourseRegisterWorkflowController.$inject = ['$rootScope', '$scope', '$state', 'ipmConstants', 'SurveyWorkflowService'];

        function CourseRegisterWorkflowController($rootScope, $scope, $state, constants, SurveyWorkflowService) {
            var vm = this;
            vm.allSteps = constants.COURSE_REGISTRATION_STEPS;
            vm.suggestCourseStep = constants.COURSE_REGISTRATION_STEPS[0];
            vm.instructionDetailListStep = constants.COURSE_REGISTRATION_STEPS[1];
            vm.classSessionDetailListStep = constants.COURSE_REGISTRATION_STEPS[2];
            vm.patientCourseRegistration = {
                suggestedCourses: [],
                courseIdSelected: null,
                instructorSchedules: [],
                instructorScheduleSelected: {},
                scheduledClassesSelected: [],
                acceptRegister: false
            };
            vm.handleSuggestCourseNext = handleSuggestCourseNext;
            vm.handleInstructionDetailListNext = handleInstructionDetailListNext;
            vm.handleClassSessionDetailListNext = handleClassSessionDetailListNext;
            vm.handleInstructionDetailListBack = handleInstructionDetailListBack;
            vm.handleClassSessionDetailListBack = handleClassSessionDetailListBack;
            vm.skip = skip;

            init();

            function init() {
                vm.currentStep = vm.suggestCourseStep;
            }

            $rootScope.$on('UpdateCourseRegistration', function(event, responseData) {
                console.log("Event-UpdateCourseRegistration ", responseData);
                updateCourseRegistration(responseData);
            });

            function updateCourseRegistration(responseData) {
                vm.patientCourseRegistration.suggestedCourses = responseData.suggestedCourses;
                vm.patientCourseRegistration.courseIdSelected = responseData.courseIdSelected;
                vm.patientCourseRegistration.courseNameSelected = responseData.courseNameSelected;
                vm.patientCourseRegistration.instructorSchedules = responseData.instructorSchedules;
                vm.patientCourseRegistration.instructorScheduleSelected = responseData.instructorScheduleSelected;
                vm.patientCourseRegistration.scheduledClassesSelected = responseData.scheduledClassesSelected;
                vm.patientCourseRegistration.acceptRegister = responseData.acceptRegister;
            }
            function handleSuggestCourseNext() {
                vm.currentStep = vm.instructionDetailListStep;
            }

            function handleInstructionDetailListNext() {
                vm.currentStep = vm.classSessionDetailListStep;
            }

            function handleClassSessionDetailListNext() {
                vm.skip();
            }

            function skip() {
                if(vm.currentStep != vm.suggestCourseStep) {
                    if(vm.patientCourseRegistration.suggestedCourses.length > 0) {
                        vm.patientCourseRegistration.suggestedCourses.shift();
                    }
                    if(vm.patientCourseRegistration.suggestedCourses.length == 0) {
                        SurveyWorkflowService.courseRegisterConfirmed().then(function() {
                            $state.go('survey-workflow-thankyou');
                        });
                    } else {
                        handleSuggestCourseNext();
                        SurveyWorkflowService.coursesRegistration(vm.patientCourseRegistration).then(function(response) {
                            updateCourseRegistration(response.data);
                            console.log("Course Registration View 1", vm.patientCourseRegistration);
                        });
                    }
                } else {
                    SurveyWorkflowService.courseRegisterConfirmed().then(function() {
                        $state.go('survey-workflow-thankyou');
                    });
                }
            }

            function handleInstructionDetailListBack() {
                vm.currentStep = vm.suggestCourseStep;
            }

            function handleClassSessionDetailListBack() {
                vm.currentStep = vm.instructionDetailListStep;
            }
        }
})();

