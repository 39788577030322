(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('SAMLLoginController', SAMLLoginController);

    SAMLLoginController.$inject = ['$scope', '$state', '$window', 'isLogin', 'SamlService'];

    function SAMLLoginController($scope, $state, $window, isLogin, SamlService) {
        init();

        function init() {
            if (!isLogin) { // step 1
                $state.go('samlReLogin', null, {reload: true});
                return;
            } else { // step 2
                SamlService.forceReLogin().then(function () {
                    $window.location.href = "saml/login";
                });
            }
        }

    }
})();
