(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('ipmApp')
        .factory('ClearLocalStorageService', ClearLocalStorageService);

    ClearLocalStorageService.$inject = ['$window', 'ipmConstants', '$localStorage', '$sessionStorage'];
    function ClearLocalStorageService ($window, constants, $localStorage, $sessionStorage) {
        var service = {
            'clearWorkflow': clearWorkflow,
            'clearAll': clearAll,
            'clearAllAndWorkflow': clearAllAndWorkflow,
            'clearLandingPage': clearLandingPage,
            'setLandingPage': setLandingPage,
            'getLandingPage': getLandingPage,
            'setKeepLandingPage': setKeepLandingPage,
            'clearKeepLandingPage': clearKeepLandingPage,
            'setTimeSpent': setTimeSpent,
            'clearTimeSpent': clearTimeSpent,
            'getTimeSpent': getTimeSpent,
            'getTimerHasElapsed': getTimerHasElapsed,
            'setTimerHasElapsed': setTimerHasElapsed,
            'clearTimerHasElapsed': clearTimerHasElapsed,
            'setPatientIdForCPRWorkflow': setPatientIdForCPRWorkflow,
            'clearPatientIdForCPRWorkflow': clearPatientIdForCPRWorkflow,
            'getPatientIdForCPRWorkflow': getPatientIdForCPRWorkflow,
            'getHighlightDays': getHighlightDays,
            'setHighlightDays': setHighlightDays,
            'getTimeSpentCPR': getTimeSpentCPR,
            'setTimeSpentCPR': setTimeSpentCPR,
            'clearTimeSpentCPR': clearTimeSpentCPR,
            'getCurrentSlideWC': getCurrentSlideWC,
            'setCurrentSlideWC': setCurrentSlideWC,
            'clearCurrentSlideWC': clearCurrentSlideWC,
            'getCurrentPagePdfViewSlide': getCurrentPagePdfViewSlide,
            'setCurrentPagePdfViewSlide': setCurrentPagePdfViewSlide,
            'clearCurrentPagePdfViewSlide': clearCurrentPagePdfViewSlide,
            'getScaleZoomIn': getScaleZoomIn,
            'setScaleZoomIn': setScaleZoomIn,
            'clearScaleZoomIn': clearScaleZoomIn,
            'getScaleZoomOut': getScaleZoomOut,
            'setScaleZoomOut': setScaleZoomOut,
            'clearScaleZoomOut': clearScaleZoomOut,
            'getNameClassPresentationView' : getNameClassPresentationView,
            'setNameClassPresentationView' : setNameClassPresentationView,
            'clearNameClassPresentationView' : clearNameClassPresentationView,
            'getFRPProgram': getFRPProgram,
            'setFRPProgram': setFRPProgram,
            'clearFRPProgram': clearFRPProgram,
            'clearTimeCPRLogin': clearTimeCPRLogin,
            'getTimeCPRLogin': getTimeCPRLogin,
            'setTimeCPRLogin': setTimeCPRLogin,
            'clearFRPProgramsError': clearFRPProgramsError,
            'getFRPProgramsError': getFRPProgramsError,
            'addFRPProgramsError': addFRPProgramsError,
            'setInitDataCPRWorkflow': setInitDataCPRWorkflow,
            'getInitDataCPRWorkflow': getInitDataCPRWorkflow,
            'clearInitDataCPRWorkflow': clearInitDataCPRWorkflow,
            'getSurveyResultId': getSurveyResultId,
            'setSurveyResultId': setSurveyResultId,
            'clearSurveyResultId': clearSurveyResultId,
            'getLaunchFrom': getLaunchFrom,
            'setLaunchFrom': setLaunchFrom,
            'clearLaunchFrom': clearLaunchFrom,
            'clearAfterLogout': clearAfterLogout,
            'getQuestionIdEvaluationWorkflow': getQuestionIdEvaluationWorkflow,
            'setQuestionIdEvaluationWorkflow': setQuestionIdEvaluationWorkflow,
            'clearQuestionIdEvaluationWorkflow': clearQuestionIdEvaluationWorkflow,
            'getActionEvaluationWorkflow': getActionEvaluationWorkflow,
            'setActionEvaluationWorkflow': setActionEvaluationWorkflow,
            'clearActionEvaluationWorkflow': clearActionEvaluationWorkflow
        };
        return service;

        function clearWorkflow() {
            $window.localStorage.removeItem(constants.SURVEY_RESULT_ID);
            $window.localStorage.removeItem(constants.NPQ_QUESTIONNAIRE_ID);
            $window.localStorage.removeItem(constants.PASS_RESTRICTION);
            $window.localStorage.removeItem(constants.LAUNCH_FROM);
        }

        function clearAll() {
            $window.localStorage.removeItem(constants.INVITATION_WORKFLOW);
            $window.localStorage.removeItem(constants.SURVEY_RESULT_ID);
            $window.localStorage.removeItem(constants.NPQ_QUESTIONNAIRE_ID);
            $window.localStorage.removeItem(constants.PASS_RESTRICTION);
            $window.localStorage.removeItem(constants.INVITATION_SECRET_KEY);
            $window.localStorage.removeItem(constants.LAUNCH_FROM);

        }

        function clearAllAndWorkflow() {
            $sessionStorage.currentSurveyResultId = null;
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            $window.localStorage.removeItem(constants.SURVEY_RESULT_ID);
            $window.localStorage.removeItem(constants.NPQ_QUESTIONNAIRE_ID);
            $window.localStorage.removeItem(constants.LAUNCH_FROM);
            clearAll();
        }

        function clearLandingPage() {
            $window.localStorage.removeItem(constants.PASS_LANDING_PAGE);
        }

        function setLandingPage(value) {
            $window.localStorage.setItem(constants.PASS_LANDING_PAGE, true === Boolean(value) ? value : false);
        }

        function setKeepLandingPage(value) {
            $window.localStorage.setItem(constants.KEEP_LANDING_PAGE, true === Boolean(value) ? value : false);
        }

        function getLandingPage() {
            return $window.localStorage.getItem(constants.PASS_LANDING_PAGE);
        }

        function clearKeepLandingPage() {
            $window.localStorage.removeItem(constants.KEEP_LANDING_PAGE);
        }

        function getTimeSpent() {
            return $window.localStorage.getItem(constants.TIME_SPENT);
        }

        function setTimeSpent() {
            $window.localStorage.setItem(constants.TIME_SPENT, true);
        }

        function clearTimeSpent() {
            $window.localStorage.removeItem(constants.TIME_SPENT);
        }

        function getTimerHasElapsed() {
            return $window.localStorage.getItem(constants.TIMER_HAS_ELAPSED);
        }

        function setTimerHasElapsed(time) {
            return $window.localStorage.setItem(constants.TIMER_HAS_ELAPSED, time);
        }

        function clearTimerHasElapsed() {
            return $window.localStorage.removeItem(constants.TIMER_HAS_ELAPSED);
        }

        function getPatientIdForCPRWorkflow() {
            var dataCPR = $window.localStorage.getItem(constants.PATIENT_ID_FOR_CPR_WORKFLOW);
            return dataCPR ? JSON.parse(dataCPR) : null;
        }

        function setPatientIdForCPRWorkflow(patientId, cprId) {
            var dataCPR = {patientId: patientId, cprId: cprId};
            $window.localStorage.setItem(constants.PATIENT_ID_FOR_CPR_WORKFLOW, JSON.stringify(dataCPR));
        }

        function clearPatientIdForCPRWorkflow() {
            $window.localStorage.removeItem(constants.PATIENT_ID_FOR_CPR_WORKFLOW);
        }

        function getHighlightDays() {
            return $window.localStorage.getItem(constants.HIGHLIGHT_DAYS);
        }

        function setHighlightDays(days) {
            $window.localStorage.setItem(constants.HIGHLIGHT_DAYS, days);
        }

        function getTimeSpentCPR() {
            return $window.localStorage.getItem(constants.TIME_SPENT_CPR_WORKFLOW);
        }

        function setTimeSpentCPR(time) {
            $window.localStorage.setItem(constants.TIME_SPENT_CPR_WORKFLOW, time);
        }

        function clearTimeCPRLogin() {
            $window.localStorage.removeItem(constants.TIME_CPR_LOGIN);
        }

        function getTimeCPRLogin() {
            return $window.localStorage.getItem(constants.TIME_CPR_LOGIN);
        }

        function setTimeCPRLogin(time) {
            $window.localStorage.setItem(constants.TIME_CPR_LOGIN, time);
        }

        function clearTimeSpentCPR() {
            $window.localStorage.removeItem(constants.TIME_SPENT_CPR_WORKFLOW);
        }

        function getCurrentSlideWC() {
            return $window.localStorage.getItem(constants.CURRENT_SLIDE_WC);
        }

        function setCurrentSlideWC(id) {
            $window.localStorage.setItem(constants.CURRENT_SLIDE_WC, id);
        }

        function clearCurrentSlideWC() {
            $window.localStorage.removeItem(constants.CURRENT_SLIDE_WC);
        }

        function getCurrentPagePdfViewSlide() {
            return $window.localStorage.getItem(constants.CURRENT_PAGE_PDF_VIEW);
        }

        function setCurrentPagePdfViewSlide(currentPage) {
            $window.localStorage.setItem(constants.CURRENT_PAGE_PDF_VIEW, currentPage);
        }

        function clearCurrentPagePdfViewSlide() {
            $window.localStorage.removeItem(constants.CURRENT_PAGE_PDF_VIEW);
        }

        function getScaleZoomIn() {
            return $window.localStorage.getItem(constants.SCALE_ZOOM_IN);
        }

        function setScaleZoomIn(currentPage) {
            $window.localStorage.setItem(constants.SCALE_ZOOM_IN, currentPage);
        }

        function clearScaleZoomIn() {
            $window.localStorage.removeItem(constants.SCALE_ZOOM_IN);
        }

        function getScaleZoomOut() {
            return $window.localStorage.getItem(constants.SCALE_ZOOM_OUT);
        }

        function setScaleZoomOut(currentPage) {
            $window.localStorage.setItem(constants.SCALE_ZOOM_OUT, currentPage);
        }

        function clearScaleZoomOut() {
            $window.localStorage.removeItem(constants.SCALE_ZOOM_OUT);
        }
        function getNameClassPresentationView () {
            return $window.localStorage.getItem(constants.PRESENTATION_VIEW);
        }

        function setNameClassPresentationView(name) {
            $window.localStorage.setItem(constants.PRESENTATION_VIEW,name);
        }

        function clearNameClassPresentationView() {
            $window.localStorage.removeItem(constants.PRESENTATION_VIEW);
        }

        function clearFRPProgram() {
            $window.localStorage.removeItem(constants.FRP_PROGRAM);
        }

        function getFRPProgram() {
            return $window.localStorage.getItem(constants.FRP_PROGRAM);
        }

        function setFRPProgram(id) {
            $window.localStorage.setItem(constants.FRP_PROGRAM, id);
        }

        function clearFRPProgramsError() {
            $window.localStorage.removeItem(constants.FRP_PROGRAMS_ERROR);
        }

        function getFRPProgramsError() {
            var frpProgramsErrorJSON = $window.localStorage.getItem(constants.FRP_PROGRAMS_ERROR);
            return frpProgramsErrorJSON ? JSON.parse(frpProgramsErrorJSON) : [];
        }

        function addFRPProgramsError(id) {
            var frpProgramsError = getFRPProgramsError();
            if (!frpProgramsError.includes(id)) {
                frpProgramsError.push(id);
            }
            $window.localStorage.setItem(constants.FRP_PROGRAMS_ERROR, JSON.stringify(frpProgramsError));
        }

        function getInitDataCPRWorkflow() {
            var initDataCPR = $window.localStorage.getItem(constants.INIT_DATA_CPR);
            return initDataCPR === 'true'; // This assumes the value is stored as a string 'true' in the localStorage.
        }

        function setInitDataCPRWorkflow(hasInitialized) {
            $window.localStorage.setItem(constants.INIT_DATA_CPR, hasInitialized);
        }

        function clearInitDataCPRWorkflow() {
            $window.localStorage.removeItem(constants.INIT_DATA_CPR);
        }

        function getSurveyResultId() {
            return $window.localStorage.getItem(constants.SURVEY_RESULT_ID);
        }

        function setSurveyResultId(surveyResultId) {
            $window.localStorage.setItem(constants.SURVEY_RESULT_ID, surveyResultId);
        }

        function clearSurveyResultId() {
            $window.localStorage.removeItem(constants.SURVEY_RESULT_ID);
        }

        function getLaunchFrom() {
            return $window.localStorage.getItem(constants.LAUNCH_FROM);
        }

        function setLaunchFrom(value) {
            $window.localStorage.setItem(constants.LAUNCH_FROM, value);
        }

        function clearLaunchFrom() {
            $window.localStorage.removeItem(constants.LAUNCH_FROM);
        }

		function clearAfterLogout() {
		    delete $localStorage.isQuestionnaireIncomplete;
		    delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            clearAll();
            clearLandingPage();
            clearKeepLandingPage();
            clearTimeSpent();
            clearTimerHasElapsed();
            clearFRPProgramsError();
		}

        function getQuestionIdEvaluationWorkflow() {
            var questionId = $window.localStorage.getItem(constants.QUESTION_ID_EVALUATION_WORKFLOW);
            return parseInt(questionId);
        }

        function setQuestionIdEvaluationWorkflow(value) {
            $window.localStorage.setItem(constants.QUESTION_ID_EVALUATION_WORKFLOW, value);
        }

        function clearQuestionIdEvaluationWorkflow() {
            $window.localStorage.removeItem(constants.QUESTION_ID_EVALUATION_WORKFLOW);
        }

        function getActionEvaluationWorkflow() {
            return $window.localStorage.getItem(constants.ACTION_EVALUATION_WORKFLOW);
        }

        function setActionEvaluationWorkflow(value) {
            $window.localStorage.setItem(constants.ACTION_EVALUATION_WORKFLOW, value);
        }

        function clearActionEvaluationWorkflow() {
            $window.localStorage.removeItem(constants.ACTION_EVALUATION_WORKFLOW);
        }
    }
})();
