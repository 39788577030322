(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('CoCAgreementManagementController', CoCAgreementManagementController);

        CoCAgreementManagementController.$inject = ['CocAgreementService'];

    function CoCAgreementManagementController(CocAgreementService) {
        var vm = this;
        vm.isSaving = false;
        init();

        function init() {}

        vm.sendInvitations = function() {
            vm.isSaving = true;
            vm.isAddedSuccess = false;
            vm.haveError = false;
            CocAgreementService.sendInvitations(vm.chartNumbersValue).then(
                function(res) {
                    vm.isSaving = false;
                    vm.haveError = res.haveError;
                    if (vm.haveError) {
                        vm.havePatientIdNotFounds = res.patientIdNotFounds && !_.isEmpty(res.patientIdNotFounds);
                        if (vm.havePatientIdNotFounds) {
                            vm.patientIdNotFounds = res.patientIdNotFounds
                        }
                        vm.havePatientIdSents = res.patientIdSents && !_.isEmpty(res.patientIdSents);
                        if (vm.havePatientIdSents) {
                            vm.patientIdSents = res.patientIdSents
                        }
                        vm.havePatientIdNoneProviders = res.patientIdNoneProviders && !_.isEmpty(res.patientIdNoneProviders);
                        if (vm.havePatientIdNoneProviders) {
                            vm.patientIdNoneProviders = res.patientIdNoneProviders
                        }
                    }

                    vm.numberAddedSuccess = res.numberAddedSuccess;
                    if (vm.numberAddedSuccess > 0) {
                        vm.isAddedSuccess = true;
                        vm.patientIdAddSuccesses = res.patientIdAddSuccesses;
                        // clear input field
                        vm.chartNumbersValue = undefined;
                    }
            }, function(error) {
                    vm.isSaving = false;
            });
        }
    }
})();
