(function() {
    'use strict';

    GoalAddNewRowController.$inject = [];

    function GoalAddNewRowController() {
        var vm = this; //jshint ignore:line

        init();

        function init() {
        }

        vm.checkboxChange = function() {
            vm.item.midSessionChecked = null;
            vm.item.dischargeChecked = null;
            vm.item.midSessionInputValue = null;
            vm.item.dischargeInputValue = null;
            vm.item.midSessionSelectedValue = null;
            vm.item.dischargeSelectedValue = null;
        }

        vm.removeGoalAddNew = function() {
            if (vm.isDisabled) return;
            vm.item.active = false;
            vm.onDelete({data: vm.item});
        }

        vm.addNewGoal = function() {
            if (vm.isDisabled) return;
            vm.onClickAddNewGoal();
        }
    }

    angular.module('ipmApp').component('goalAddNewRow', {
        bindings: {
            item: '<',
            isDisabled: '<',
            onChange: '&',
            onDelete: '&',
            lastItem: '<',
            onClickAddNewGoal: '&'
        },
        templateUrl: 'app/frp-mde/mde-report/pt/components/goal-add-new-row.html',
        controller: GoalAddNewRowController,
        controllerAs: 'vm'
    })
})();
