(function () {
    'use strict';

    PdfPreviewComponent.$inject = [
        '$scope',
        '$rootScope',
        '$sce',
        'pdfDelegate',
        'ipmConstants',
    ];

    function PdfPreviewComponent(
        $scope,
        $rootScope,
        $sce,
        pdfDelegate,
        ipmConstants
    ) {
        var vm = this; //jshint ignore:line
        vm.PDF_DELEGATE = 'pdf-container';
        vm.scaleZoom = 0.1;
        vm.scaleZoomCurrent = 1;
        vm.scaleZoomMaxDefault = 6; // zoom max 600%, can zoom max 8.6
        vm.zoomToDefault = ipmConstants.PDF_DEFAULT_SCALE;
        vm.convertDataPdf = convertDataPdf;
        vm.zoomIn = zoomIn;
        vm.zoomOut = zoomOut;
        vm.next = next;
        vm.back = back;
        vm.getPageCount = getPageCount;
        vm.currentPage = 1;
        vm.goToPageInput = goToPageInput;
        vm.isShowPdfCanvas = true;

        init();

        function init() {
            vm.scaleZoomCurrent = vm.zoomToDefault;
            vm.scaleZoomMax = vm.scaleZoomMax ? vm.scaleZoomMax : vm.scaleZoomMaxDefault;
        }

        function convertDataPdf(pdfData) {
            if (pdfData) {
                if (vm.uint8Enable) {
                    return new Uint8Array(pdfData);
                }
                return pdfData;
            }
        }

        function zoomIn() {
            if (vm.scaleZoomCurrent >= vm.scaleZoomMax) {
                return;
            }
            vm.scaleZoomCurrent += vm.scaleZoom;
            zoomTo(vm.scaleZoomCurrent);
            vm.onchangeZoomIn({
                scaleZoom: vm.scaleZoomCurrent,
            });
        }

        function zoomOut() {
            if (vm.scaleZoomCurrent <= 0) {
                return;
            }
            vm.scaleZoomCurrent -= vm.scaleZoom;
            zoomTo(vm.scaleZoomCurrent);
            vm.onchangeZoomOut({
                scaleZoom: vm.scaleZoomCurrent,
            });
        }

        function zoomTo(scale) {
            pdfDelegate
                .$getByHandle(vm.PDF_DELEGATE)
                .zoomTo(Number(scale) > 0 ? Number(scale) : vm.zoomToDefault);
        }

        function next() {
            vm.currentPage++;
            goToPage();
            vm.onchangeNext({
                currentPage: vm.currentPage,
            });
        }

        function back() {
            vm.currentPage--;
            goToPage();
            vm.onchangeBack({
                currentPage: vm.currentPage,
            });
        }

        function getPageCount() {
            var pdfHandle = pdfDelegate.$getByHandle(vm.PDF_DELEGATE);
            if (pdfHandle) {
                return pdfDelegate.$getByHandle(vm.PDF_DELEGATE).getPageCount();
            }
        }

        function getCurrentPage() {
            return pdfDelegate.$getByHandle(vm.PDF_DELEGATE).getCurrentPage();
        }

        function goToPageInput() {
            goToPage();
            vm.onchangeGoToPage({
                currentPage: vm.currentPage,
            });
        }

        function goToPage(currentPage) {
            pdfDelegate
                .$getByHandle(vm.PDF_DELEGATE)
                .goToPage(currentPage > 0 ? currentPage : vm.currentPage);
        }

        function reloadViewPdf(pdfData) {
            if (pdfData && pdfData.length > 0) {
                // have data
                pdfDelegate.$getByHandle(vm.PDF_DELEGATE).load(pdfData);
                vm.isShowPdfCanvas = true;
                vm.currentPage = 1;
                zoomTo(vm.zoomToDefault);
                vm.scaleZoomCurrent = vm.zoomToDefault;
            } else {
                loadNewBlankFile();
            }
        }

        function loadNewBlankFile() {
            vm.isShowPdfCanvas = false;
        }

        // used reload Pdf when next or back slide
        $scope.$watch('vm.pdfData', function (newValue) {
            reloadViewPdf(convertDataPdf(vm.pdfData));
            if (vm.pdfData && vm.pdfData.length > 0) {
                goToPage();
            }
        });
    }

    angular.module('ipmApp').component('pdfPreview', {
        bindings: {
            pdfData: '<',
            uint8Enable: '<',
            isShowToolbar: '<',
            onchangeBack: '&',
            onchangeNext: '&',
            onchangeZoomIn: '&',
            onchangeZoomOut: '&',
            onchangeGoToPage: '&',
            scaleZoomMax: '<'
        },
        template: '<div ng-if="vm.isShowPdfCanvas" class="pdf-viewer-container">' +
            '<div class="py-1 pdf-viewer-toolbar">' +
            '<div ng-if="vm.isShowToolbar" class="btn-group">' +
            '<span><button type="button" ng-disabled="vm.currentPage === 1" class="btn btn-default btn-sm toolbarButton" ng-click="vm.back()" ng-dis title="Previous Page"><span class="glyphicon glyphicon-arrow-up"></span></button></span>|' +
            '<span><button type="button" ng-disabled="vm.currentPage >= vm.getPageCount()" class="btn btn-default btn-sm toolbarButton" ng-click="vm.next()" title="Next Page"><span class="glyphicon glyphicon-arrow-down"></span></button></span>' +
            '<span class="px1"></span><input type="number" class="toolbarField pageNumber" min="1" ng-model="vm.currentPage" ng-change="vm.goToPageInput()" style="width: 15%">  of {{vm.getPageCount()}}</span>' +
            '<span><button type="button" ng-disabled="vm.scaleZoomCurrent <= 0" class="btn btn-default btn-sm toolbarButton" ng-click="vm.zoomOut()" title="Zoom Out"><span class="glyphicon glyphicon-minus"></span></button></span>|' +
            '<span><button type="button" ng-disabled="vm.scaleZoomCurrent >= vm.scaleZoomMax" class="btn btn-default btn-sm toolbarButton" ng-click="vm.zoomIn()" title="Zoom In"><span class="glyphicon glyphicon-plus"></span></button></span>' +
            '</div>' +
            '</div>' +
            '<pdf-viewer ng-if="vm.pdfData" ' +
            'delegate-handle="pdf-container" ' +
            'url="vm.convertDataPdf(vm.pdfData)" ' +
            'scale="1.3" ' +
            'show-toolbar="false" ' +
            "headers=\"{ 'x-you-know-whats-awesome': 'EVERYTHING' }\"></pdf-viewer>" +
            '</div>',
        controller: PdfPreviewComponent,
        controllerAs: 'vm',
    });
})();
