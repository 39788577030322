(function () {
    'use strict';
    angular
        .module('ipmApp')
        .factory('NotificationHistoryService', NotificationHistoryService);

    NotificationHistoryService.$inject = ['$q', 'halClient', '$http'];

    function NotificationHistoryService($q, halClient, $http) {
        var baseUrl = 'api/';

        var service = {
            'query': query
        };

        function query(patientId, pageable) {
            var deferred = $q.defer();

            var url = baseUrl + 'notification-histories/' + patientId;
            $http.get(url, {
                params: {
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        return service;
    };
})();
