(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('NPQQuestionnaireWorkflowController', NPQQuestionnaireWorkflowController);

        NPQQuestionnaireWorkflowController.$inject = ['$scope', '$state', '$translate', '$timeout', '$location', 'Auth', '$uibModal', '$window', 'JhiLanguageService', 'NPQQuestionnaireService', 'npqQuestionnaire', 'masterData', 'dataWorkflow', '$localStorage', 'ipmConstants', '$rootScope', 'ClearLocalStorageService'];

    function NPQQuestionnaireWorkflowController($scope, $state, $translate, $timeout, $location, Auth, $uibModal, $window, JhiLanguageService, NPQQuestionnaireService, npqQuestionnaire, masterData, dataWorkflow, $localStorage, constants, $rootScope, ClearLocalStorageService) {
        var vm = this;

        vm.medicationGroups = masterData.medicationGroups;
        vm.symptomGroups = masterData.symptomGroups;
        vm.pastMedicalHistoryGroups = masterData.pastMedicalHistoryGroups;

        vm.npqQuestionnaire = npqQuestionnaire;
        vm.dataWorkflow = dataWorkflow;

        vm.section = vm.dataWorkflow.section;
        vm.template = '';
        vm.datePickerOpenStatus = {
            theApproximateDateOfTheInjury: false,
            dateOfInjury: false
        };
        vm.isInvitation = $window.localStorage.getItem("invitationWorkflow");
        vm.isLaunchAction = $window.localStorage.getItem(constants.NPQ_QUESTIONNAIRE_ID) ? true : false;

        vm.init = function() {
            if(vm.dataWorkflow.action == 'COMPLETE') {
                vm.template = 'app/npq-questionnaire/workflow/partial/thanks.html';
                vm.npqQuestionnaire['currentSection'] = {};
            } else {
                vm.template = 'app/npq-questionnaire/workflow/partial/'+vm.dataWorkflow.section.template+'.html';
            }
        }

        vm.next = function() {
            vm.dataWorkflow.action = 'NEXT';
            vm.dataWorkflow.isInvitation = vm.isInvitation;
            vm.dataWorkflow.isLaunchAction = vm.isLaunchAction;
            NPQQuestionnaireService.submitWorkflow(vm.dataWorkflow).then(function(res) {
                vm.dataWorkflow = res;
                vm.updateCurrentSection(res);
            });
        }

        vm.back = function() {
            vm.dataWorkflow.action = 'BACK';
            vm.dataWorkflow.isInvitation = vm.isInvitation;
            vm.dataWorkflow.isLaunchAction = vm.isLaunchAction;
            NPQQuestionnaireService.submitWorkflow(vm.dataWorkflow).then(function(res) {
                vm.dataWorkflow = res;
                vm.updateCurrentSection(res);
            });
        }

        vm.updateCurrentSection = function(workflow) {
            vm.npqQuestionnaire.sectionIdsCompleted = workflow.sectionIdsCompleted;
            if(workflow.action == 'COMPLETE') {
                vm.template = 'app/npq-questionnaire/workflow/partial/thanks.html';
                vm.npqQuestionnaire['currentSection'] = {};
            } else {
                vm.template = 'app/npq-questionnaire/workflow/partial/' + workflow.section.template + '.html';
                if(!vm.npqQuestionnaire.currentSection) {
                    vm.npqQuestionnaire['currentSection'] = workflow.section;
                } else {
                    vm.npqQuestionnaire.currentSection.id = workflow.section.id;
                    vm.npqQuestionnaire.currentSection.code = workflow.section.code;
                    vm.npqQuestionnaire.currentSection.template = workflow.section.template;
                    vm.npqQuestionnaire.currentSection.name = workflow.section.name;
                    vm.npqQuestionnaire.currentSection.nameLangKey = workflow.section.nameLangKey;
                    vm.npqQuestionnaire.currentSection.orderNumber = workflow.section.orderNumber;
                }
            }

            $window.scrollTo(0, 0);
        }

        vm.logout = function() {
            Auth.logout();
            $state.go('home');
            delete $localStorage.isQuestionnaireIncomplete;
            delete $localStorage.firstIdOfAllNPQQuestionnaireNotComplete;
            delete $localStorage.isQuestionnaireCount;
            $window.localStorage.removeItem(constants.INVITATION_WORKFLOW);
            $window.localStorage.removeItem(constants.SURVEY_RESULT_ID);
            $window.localStorage.removeItem(constants.NPQ_QUESTIONNAIRE_ID);
            $rootScope.$broadcast(constants.EVT_LOG_OUT);
        }

        vm.init();

        vm.nextNPQOrLogout = function () {
            NPQQuestionnaireService.checkingStatusPatient().then(function (res) {
                if (res && (res.isOpenCountDialog || res.isOpenResumeDialog || res.forwardToLastStepOfNPQ)) {
                    ClearLocalStorageService.clearWorkflow();
                    goToNPQWorkflow();
                } else {
                    ClearLocalStorageService.clearAllAndWorkflow();
                    ClearLocalStorageService.clearLandingPage();
                    goToLandingPage();
                    // goToHome(); // open HAQ Workflow
                }
            });
        }

        function goToNPQWorkflow() {
            $state.go('npq-questionnaire.workflow', null, {reload: true});
        }

        function goToHome() {
            $state.go('home');
        }

        function goToLandingPage() {
            $state.go('landing-page');
        }
    }
})();
