(function() {
    'use strict';


    CPRQuestionController.$inject = ['ipmConstants'];

    function CPRQuestionController(ipmConstants) {
        var vm = this; //jshint ignore:line
        vm.isSingleChoiceQuestion = isSingleChoiceQuestion;
        vm.isCheckBoxQuestion = isCheckBoxQuestion;
        vm.isTextInputQuestion = isTextInputQuestion;
        vm.isDateQuestion = isDateQuestion;
        vm.isDisplayCheckboxQuestion = isDisplayCheckboxQuestion;
        init();

        function init() {
            // init answer
            if (vm.answers) {
                vm.valueAnswer = vm.answers.get(vm.question.code);
            }

            if (vm.question.code === 'CPR_002') {
                vm.question.text = vm.question.text + ' ' + vm.patientWorkStatus;
            }

            if (vm.question.code === 'CPR_017') {
                if (vm.surgicalCandidacyStatus) {
                    vm.question.text = 'Current status: ' + vm.surgicalCandidacyStatus + '. ' + vm.question.text;
                }
            }
        }

        vm.handleSingleChoiceQuestion = function(data) {
            if (data) {
                vm.valueAnswer = data.value;
                vm.onChange({data: data});
            }
        }

        vm.handleTextInputQuestion = function(data) {
            if (data) {
                vm.valueAnswer = data.value;
                vm.onChange({data: data});
            }
        }

        vm.handleCheckboxQuestion = function(data) {
            if (data) {
                vm.valueAnswer = data.value;
                vm.onChange({data: data});
            }
        }

        vm.handleDateQuestion = function(data) {
            if (data) {
                vm.valueAnswer = data.value;
                vm.onChange({data: data});
            }
        }

        vm.subQuestionChange = function(data) {
            vm.onChange({data: data});
        }


        vm.isDisplaySubQuestion = function(subQuestion) {

            if (subQuestion.code === 'CPR_002') {
                return vm.patientWorkStatus ? true : false
            }
            if (subQuestion.code === 'CPR_003') {
                return vm.patientWorkStatus ? false : true
            }

            if (vm.question.answerType === 'SINGLE_CHOICE') {
                return subQuestion.parentAnswer === vm.valueAnswer;
            }


            if (ipmConstants.DISPLAY_SCHEDULING_SUB_QUESTION_ACTION.includes(subQuestion.code)) {
                return vm.answers.get('CPR_041') && vm.answers.get('CPR_041').length > 0;
            }

            if (ipmConstants.DISPLAY_GATHER_SUB_QUESTION_ACTION.includes(subQuestion.code)) {
                return vm.answers.get('CPR_050') && vm.answers.get('CPR_050').length > 0;
            }

            if (ipmConstants.DISPLAY_SUB_QUESTION_ACTION.includes(subQuestion.code)) {
                return vm.valueAnswer && vm.valueAnswer.length > 0;
            }
        }

        function isSingleChoiceQuestion() {
            return vm.question && vm.question.answerType === 'SINGLE_CHOICE';
        }

        function isCheckBoxQuestion() {
            return vm.question && vm.question.answerType === 'CHECKBOX';
        }

        function isDisplayCheckboxQuestion() {
            return vm.question && (vm.question.code !== 'CPR_042' && vm.question.code !== 'CPR_051')
        }

        function isTextInputQuestion() {
            return vm.question && vm.question.answerType === 'FREE_TEXT_INPUT';
        }

        function isDateQuestion() {
            return vm.question && vm.question.answerType === 'DATE_INPUT';
        }

        vm.getCssClass = function() {
            if (vm.question.text || (vm.isCheckBoxQuestion() && vm.isDisplayCheckboxQuestion())) {
                return 'ml-4';
            }
            return '';
        }

        vm.isRequiredQuestion = function() {
            return vm.question.required;
        }

        vm.isDisplayMessageRequiredSubCheckbox = function() {
            if (ipmConstants.DISPLAY_MESSAGE_REQUIRED_SUB_CHECKBOX.includes(vm.question.code)) {
                if (ipmConstants.HAVE_ANSWERS_CHECKBOX_QUESTION_CODE.includes(vm.question.code)) {
                    return !checkHaveAnswersCheckboxQuestionByCode(vm.question.code);
                }
                if (ipmConstants.CONTAINER_CHECKBOX_CODE.includes(vm.question.code)) {
                    return !checkHaveAnswersCheckboxContainerSubActionQuestionByCode(vm.question.code);
                }
            }
            return false;
        }

        function checkHaveAnswersCheckboxQuestionByCode(code) {
            var a = vm.answers.get(code);
            if (a) {
                if (a.length > 0) {
                    return true;
                }
            }
            return false;
        }

        function checkHaveAnswersCheckboxContainerSubActionQuestionByCode(code) {
            return  _.some(ipmConstants.CONTAINER_SUB_ACTION_MAPPING[code], function (child) {
                return vm.answers.get(child) && vm.answers.get(child).length > 0;
            });
        }
    }

    angular.module('ipmApp').component('cprQuestion', {
        bindings: {
            question: '<',
            patientWorkStatus: '<', // just use for 'CPR_001' checking patient Work status in HAQ Workflow
            surgicalCandidacyStatus:  '<', // just use for 'CPR_017' display
            answers: '<',
            onChange: '&'
        },
        templateUrl: 'app/qhp-workflow/care-plan-review-workflow/section-component/question-component/question.html',
        controller: CPRQuestionController,
        controllerAs: 'vm',
    })
})();
