(function() {
    'use strict';


    SecondsTransferComponent.$inject = ['$scope'];

    function SecondsTransferComponent($scope) {
        var vm = this; //jshint ignore:line
        

        init();

        function init() {
            if (vm.totalSeconds) {
                $scope.minutes = parseInt(vm.totalSeconds / 60);
                var extraSeconds = vm.totalSeconds % 60;
                if (extraSeconds >= 30) {
                    $scope.minutes += 1;
                }
            }
        }

    }

    angular.module('ipmApp').component('secondsTransfer', {
        bindings: {
            totalSeconds: '<', // in seconds
            to: '<' // in minutes
        },
        template: '<span ng-if="minutes">{{minutes}} min</span>',
        controller: SecondsTransferComponent,
        controllerAs: 'vm'
    })
})();
