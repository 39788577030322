(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('QuestionnaireResumeDialogController', QuestionnaireResumeDialogController);

    QuestionnaireResumeDialogController.$inject = ['$uibModalInstance', 'NPQQuestionnaireService'];

    function QuestionnaireResumeDialogController($uibModalInstance, NPQQuestionnaireService) {
        var vm = this;
        vm.managerAddNewQuestionnaire = null;
        vm.patientAccessMultipleDevice = null;


        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.yes = function() {
            resumeQuestionnaire();
        };

        function resumeQuestionnaire() {
            vm.isSaving = true;
            NPQQuestionnaireService.resumeNPQQuestionnaire().then(function() {
                onSaveSuccess();
            }).catch(function (error) {
                onSaveError(error);
            });
        }

        function onSaveSuccess () {
            vm.isSaving = false;
            $uibModalInstance.close(true);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                if (errorMessage === 'Manager has been request new Questionnaire') {
                    vm.managerAddNewQuestionnaire = 'ERROR';
                } else if (errorMessage === 'Patient may be access multiple device') {
                    vm.patientAccessMultipleDevice = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            }
        }
    }
})();
