(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'ipmConstants'];

    function stateConfig($stateProvider, constants) {
    	$stateProvider
        .state('deck', {
            parent: 'entity',
            url: '/decks?page&sort',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.deck.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/deck/deck.html',
                    controller: 'DeckController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'name,asc',
                    squash: true
                },
                searchKey: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        searchKey: $stateParams.searchKey
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('deck');
                    return $translate.refresh();
                }]
            }
        })
        .state('deck.new', {
            parent: 'deck',
            url: '/new',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.deck.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/deck/deck-editor.html',
                    controller: 'DeckEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('deck');
                     $translatePartialLoader.addPart('slideType');
                     $translatePartialLoader.addPart('questionRepeatType');
                    return $translate.refresh();
                }],
                isCreateNew: function () {
                    return true;
                },
            }
        })
        .state('deck.edit', {
            parent: 'deck',
            url: '/{id}/edit',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES], // ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.deck.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/deck/deck-editor.html',
                    controller: 'DeckEditorController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('deck');
                    $translatePartialLoader.addPart('slideType');
                    $translatePartialLoader.addPart('questionRepeatType');
                    return $translate.refresh();
                }],
                isCreateNew: function () {
                    return false;
                },
            }
        })
        .state('deck.delete', {
            parent: 'deck',
            url: '/{id}/delete',
            data: {
                authorities: [constants.ROLE_DISABLE_WELLNESS_CLASSES] // ['ROLE_ADMIN'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({

                    templateUrl: 'app/entities/deck/deck-delete-dialog.html',
                    controller: 'DeckDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    windowClass: 'absolute-center',
                    resolve: {
                        entity: ['Deck', function(Deck) {
                            return Deck.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('deck', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
