(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('AgreementsWorkflowService', AgreementsWorkflowService);

    AgreementsWorkflowService.$inject = ['$http', 'halClient', '$q', 'DownloadBlobService', 'DateUtils'];

    function AgreementsWorkflowService ($http, halClient, $q, DownloadBlobService, DateUtils) {
        var baseURL = 'api/agreement/';

        var service = {
            'getAgreementByPatientId': getAgreementByPatientId,
            'saveAgreementWorkflow': saveAgreementWorkflow,
            'finalSignatureAgreementWorkflow': finalSignatureAgreementWorkflow,
            'downloadAgreements': downloadAgreements,
            'findAllByPatientId': findAllByPatientId,
            'downloadAllAgreementsByPatientId': downloadAllAgreementsByPatientId,
            'getCurrentAgreementWorkflow' : getCurrentAgreementWorkflow,
            'downloadAllAgreements': downloadAllAgreements
        };

        function getAgreementByPatientId() {
            var url = baseURL + 'get-agreement-workflow';
            return $http.get(url).then(function (resp) {
                convertLocalDateFromServer(resp.data);
                return resp;
            });
        };

        function saveAgreementWorkflow(agreement) {
            convertLocalDateToServer(agreement);
            var url = baseURL + 'save-agreement-workflow';
            return $http.post(url, agreement).then(function (resp) {
                convertLocalDateFromServer(resp.data);
                return resp.data;
            });
        };

        function finalSignatureAgreementWorkflow(agreement) {
            var url = baseURL + 'final-signature-agreement-workflow';
            convertLocalDateToServer(agreement);
            return $http.post(url, agreement).then(function (resp) {
                convertLocalDateFromServer(resp.data);
                return resp.data;
            });
        };

        function downloadAgreements() {
            var deferred = $q.defer();
            var url = baseURL + '/download';
            $http({
                url: url,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], { type: contentType });
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        }

        function findAllByPatientId(patientId, pageable, notDisplayedAgreementCoc, createdSources) {
            var deferred = $q.defer();
            var url = baseURL + 'find-all-agreements-by-patient';
            $http.get(url, {
                params: {
                    patientId: patientId,
                    notDisplayedAgreementCoc: notDisplayedAgreementCoc,
                    createdSources: createdSources,
                    page: pageable.page,
                    size: pageable.size,
                    sort: pageable.sort
                }
            }).success(function (data, status, headers) {
                var result = new Object();
                result.data = data && data !== undefined ? data : [];
                result.totalItems = headers('X-Total-Count');
                deferred.resolve(result);
            }).error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        function downloadAllAgreementsByPatientId(patientId) {
            var deferred = $q.defer();
            var url = baseURL + 'download-all-by-patient-id/' + patientId;
            $http.get(url, {
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    deferred.resolve(status);
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], { type: contentType });
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });
            return deferred.promise;
        };

        function getCurrentAgreementWorkflow(patientId) {
            var deferred = $q.defer();
            var url = baseURL + 'get-current-agreement-workflow';
            $http.get(url, {
                params: {patientId: patientId}
            }).success(function (result) {
                convertLocalDateFromServer(result);
                deferred.resolve(result);
            }).error(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        function downloadAllAgreements(fromDate, toDate) {
            var deferred = $q.defer();
            var url = baseURL + '/download-all-agreements';
            $http({
                url: url,
                method: "GET",
                params: { fromDate: fromDate, toDate: toDate },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'arraybuffer'
            }).success(function (data, status, headers, config) {
                if (status === 204) {
                    var reason = headers('no-content-reason');
                    deferred.resolve({"status": status, "reason": reason});
                } else {
                    var contentType = headers('content-type');
                    var blob = new Blob([data], { type: contentType });
                    DownloadBlobService.download(blob, null, headers('Content-Disposition'));
                    deferred.resolve(true);
                }
            }).error(function (data, status, headers, config) {
                deferred.resolve(status);
            });

            return deferred.promise;
        }

        function convertLocalDateFromServer(agreement) {
            if(agreement) {
                if (agreement.patientDob) {
                    agreement.patientDob = DateUtils.convertLocalDateFromServer(agreement.patientDob);
                }
                if (agreement.dateSigned) {
                    agreement.dateSigned = DateUtils.convertDateTimeFromServer(agreement.dateSigned);
                }
            }
        };

        function convertLocalDateToServer(agreement) {
            if(agreement) {
                if (agreement.patientDob) {
                    agreement.patientDob = DateUtils.convertLocalDateToServer(agreement.patientDob);
                }
                if (agreement.dateSigned) {
                    agreement.dateSigned = DateUtils.convertLocalDateTimeToServer(agreement.dateSigned);
                }
            }
        }

        return service;
    };
})();
