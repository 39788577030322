(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['$scope', '$state', 'Patient', 'PatientSearch'];

    function PatientController ($scope, $state, Patient, PatientSearch) {
        var vm = this;
        vm.patients = [];
        vm.loadAll = function() {
            Patient.query(function(result) {
                vm.patients = result;
            });
        };

        vm.search = function () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            PatientSearch.query({query: vm.searchQuery}, function(result) {
                vm.patients = result;
            });
        };
        vm.loadAll();
        
    }
})();
