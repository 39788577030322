(function () {
	'use strict';

	angular
	.module('ipmApp')
	.controller('FRPReportAdminController', FRPReportAdminController);

	FRPReportAdminController.$inject = ['$scope', '$state', '$stateParams', 'Principal', 'userDetails', 'FrpReportService', 'supportedDataList', 'ipmConstants', 'User', 'qhpUsers', 'staffUsers', 'reportAdmin', 'frpReport', 'templates', '$uibModal', 'isView', 'lectures', 'DataUtils'];

	function FRPReportAdminController($scope, $state, $stateParams, Principal, userDetails, FrpReportService, supportedDataList, ipmConstants, User, qhpUsers, staffUsers, reportAdmin, frpReport, templates, $uibModal, isView, lectures, DataUtils) {
		var vm = this;
        vm.isChanged = isChanged;
        vm.datePickerOpenStatus = {};
        reportAdmin.injuryDate = reportAdmin.injuryDate ? moment(reportAdmin.injuryDate)._d : null;
        reportAdmin.dateOfBirthStr = reportAdmin.dateOfBirth ? moment(reportAdmin.dateOfBirth).format('MM/DD/YYYY') : "";
        vm.cancelMessage = 'ipmApp.frpReport.admin.confirmCancel';
        vm.admin = angular.copy(reportAdmin);
        vm.isFinalize = vm.admin && vm.admin.status === 'COMPLETE' ? true : false;
		vm.frp = {};
        vm.attendanceTrackers = [];
		vm.users = qhpUsers;
        vm.adminUsers = staffUsers.concat(qhpUsers);
        vm.editCaseNumber = true;
        vm.states = ipmConstants.STATES_OF_AMERICA;
        vm.lectures = lectures;
        vm.reportType = ipmConstants.FRP_REPORT_TYPE_2;
        vm.checkDisableAttendanceTracker = checkDisableAttendanceTracker;
        vm.providers = supportedDataList.providerList;
        vm.frpPrograms  =  supportedDataList.frpProgramsList;
        vm.requiredFields = [
            'caseNumber',
            'medical',
            'pt',
            'psych',
            'lectures',
            'reportType',
            'introduction',
            'templateSelect_REPORT_TYPE'
        ];

        vm.attendanceTrackerInvalid = false;
        vm.trackerStartDate = vm.admin.startDate;
        vm.trackerEndDate = vm.admin.endDate;
        vm.isDisabled = false;
        vm.isView = isView ? true : frpReport ? frpReport.statusFinal === ipmConstants.STATUS_FINAL_FRP_MDE : false;
        vm.frpReport = frpReport ? frpReport : {};
        vm.templateInputType = ipmConstants.FRP_MDE_TEMPLATE;
        vm.templates = templates ? templates : [];
        vm.dataHeaderReport = {};
        vm.sumHoursDaysAttendedIgnoreReportCurrent = 0;
        vm.previouslyCompletedHours = 0;

        init();

        function init() {
            setSumHoursDaysAttendedIgnoreReportCurrent();
            setPreviouslyCompletedHours();
            setTemplateValue();
            if (vm.isView || (frpReport.patientFRPMDE && frpReport.patientFRPMDE.isViewFrpProgram)) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
            vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);
        }

        function setPreviouslyCompletedHours() {
            if (vm.frpReport && vm.frpReport.patientFRPMDE && vm.frpReport.patientFRPMDE.previouslyCompletedHours > 0) {
                vm.previouslyCompletedHours = vm.frpReport.patientFRPMDE.previouslyCompletedHours;
            }
        }

        function setSumHoursDaysAttendedIgnoreReportCurrent() {
            if (vm.frpReport && vm.frpReport.patientFRPMDE && !_.isNaN(vm.frpReport.patientFRPMDE.sumHoursDaysAttended) && !_.isNaN(vm.frpReport.sumAtHours)) {
                vm.sumHoursDaysAttendedIgnoreReportCurrent = vm.frpReport.patientFRPMDE.sumHoursDaysAttended - vm.frpReport.sumAtHours; // sum hours all - sum hours of report current
            }
        }

        function setTemplateValue() {
            if (reportAdmin && reportAdmin.introductionTemplate) {
                reportAdmin.introductionTemplate.value = reportAdmin.introductionTemplate.id.toString();
            }
        }

        vm.onReportTypeChange = function() {
            //TODO
            vm.admin.introduction = 'Get content of report type ' + vm.admin.reportType;
        }

		vm.save = function() {
            submitData(false);
        }

        vm.finalize = function() {
            submitData(true);
        }

        vm.getAttendanceTracker = function(frp) {
            if (frp && frp.attendanceTracker) {
                return frp.attendanceTracker
            }
            frp.attendanceTracker = createNewAttendanceTracker();
            return frp.attendanceTracker;
        }

        function createNewAttendanceTracker() {
            var attendanceTracker = {
                id: null,
                mon: null,
                tue: null,
                wed: null,
                thu: null,
                fri: null,
                sat: null,
                sun: null
            };
            return attendanceTracker;
        }

        vm.attendanceTrackersChange = function(attendanceTracker) {
            if (attendanceTracker) {
                vm.admin.attendanceTracker = attendanceTracker;
                checkRequiredAttendanceTracker();
            }
            // ToDo:: implement save or Update attendanceTracker
        };

        function checkRequiredAttendanceTracker() {
            var numberDayOfWeek = 7;
            if (vm.admin.attendanceTracker) {
                for (var idx = 1; idx <= numberDayOfWeek; idx++) {
                    var element  = getAttendanceTrackerElement(idx);
                    var attendanceTracker = getAttendanceTracker(idx);
                    if (attendanceTracker === null) {
                        var required = checkRequiredAt(idx);
                        if (required) {
                            addRequiredCssClass(element);
                        } else {
                            removeRequiredCssClass(element);
                        }
                    } else {
                        removeRequiredCssClass(element);
                    }
                }
            }
            vm.attendanceTrackerInvalid = isInvalidAttendanceTracker();
        }

        function checkRequiredAt(idx) {
            var now = new moment();
            var endDate = vm.admin.endDate;
            // case in the past
            if (endDate && now.isAfter(moment(endDate)))
            {
                return true;
            }
            var dayOnWeek = getDayOnWeek(idx);
            return moment(dayOnWeek).isAfter(now) ? false : true;
        }

        function removeRequiredCssClass(element) {
            if (element) {
                element.removeClass('required-at');
            }
        }

        function addRequiredCssClass(element) {
            if (element) {
                element.addClass('required-at');
            }
        }

        function getAttendanceTracker(idx) {
            switch (idx) {
                case 1:
                    return vm.admin.attendanceTracker.sat;
                case 2:
                    return vm.admin.attendanceTracker.sun;
                case 3:
                    return vm.admin.attendanceTracker.mon;
                case 4:
                    return vm.admin.attendanceTracker.tue;
                case 5:
                    return vm.admin.attendanceTracker.wed;
                case 6:
                    return vm.admin.attendanceTracker.thu;
                case 7:
                    return vm.admin.attendanceTracker.fri;
                default:
                    break;
            }
        }

        function getDayOnWeek(idx) {
            switch (idx) {
                case 1:
                    return moment().day("Saturday").toDate();
                case 2:
                    return moment().day("Sunday").toDate();
                case 3:
                    return moment().day("Monday").toDate();
                case 4:
                    return moment().day("Tuesday").toDate();
                case 5:
                    return moment().day("Wednesday").toDate();
                case 6:
                    return moment().day("Thursday").toDate();
                case 7:
                    return moment().day("Friday").toDate();
                default:
                    break;
            }
        }

        function getAttendanceTrackerElement(idx) {
            var element = angular.element(document.querySelector('#attendance-tracker_' + idx));
            if (element != null) {
                return element;
            }
        }

        function isInvalidAttendanceTracker() {
            var elements = document.querySelector('.required-at');
            if (elements != null) {
                return true;
            }
            return false;
        }

        function checkDisableAttendanceTracker() {
            return vm.isDisabled || vm.admin && vm.admin.reportStatus === 'FRP_ACTIVE_HOLD';
        }

        function checkValidation() {
            vm.requiredFields.forEach(function (field) {
                if (field && vm.editForm[field]) {
                    vm.editForm[field].$setDirty();
                }
            });
            checkRequiredAttendanceTracker();
        }

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        function submitData(finalize) {
            if(finalize) {
                checkValidation();
                if (vm.editForm.$invalid || vm.attendanceTrackerInvalid) {
                    return;
                }
            }

            vm.isSaving = true;
            vm.admin.status = finalize ? 'COMPLETE' : 'INPROGRESS';
            FrpReportService.saveAdminReport($stateParams.id, vm.admin).then(function(response) {
                onSaveSuccess(response);
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess(response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            vm.isSaving = false;
            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status === 400) {
                vm.error = 'ERROR';
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        vm.cancel = function() {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        function back() {
            $state.go('frp-report', {id: $stateParams.id});
        }

        function isChanged() {
        	for (var prop in vm.admin) {
        		if(!vm.admin[prop]) {
        		    vm.admin[prop] = null;
        		}
        	}
            for (var prop in reportAdmin) {
                if (!reportAdmin[prop]) {
                    reportAdmin[prop] = null;
                }
            }
        	return !angular.equals(vm.admin, reportAdmin);
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        vm.checkInValidHoursAttendedExceed = function () {
            if (vm.admin.attendanceTracker) {
                var sumHoursDaysAttendedAll = calcSumHourOfReport(vm.admin.attendanceTracker) + vm.sumHoursDaysAttendedIgnoreReportCurrent + vm.previouslyCompletedHours;
                return sumHoursDaysAttendedAll > ipmConstants.HOURS_ATTENDED_CAN_NOT_EXCEED;
            }
            return false;
        }

        function calcSumHourOfReport(attendanceTracker) {
            var countAttendanceTrackerNotAbsent = DataUtils.countAttendanceTrackerNotAbsent(attendanceTracker);
            return Number(ipmConstants.NUMBER_HRS_ATTENDED) * Number(countAttendanceTrackerNotAbsent);
        }
    }
})();
