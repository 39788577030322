(function () {
    'use strict';

    angular
        .module('ipmApp')
        .factory('SamlService', SamlService);

    SamlService.$inject = ['$resource', '$http', '$q'];

    function SamlService($resource, $http, $q) {
        var resourceUrl = "saml/";
        var service = $resource(resourceUrl, {}, {});
        service.forceReLogin = forceReLogin;

        function forceReLogin() {
            var url = resourceUrl + 'force-login';
            return $http.get(url).then(function (response) {
                return response.data;
            });
        }

        return service;
    }
})();
