(function() {
    'use strict';


    TimeSpentComponent.$inject = ['$timeout', '$scope', '$interval'];

    function TimeSpentComponent($timeout, $scope, $interval) {
        var vm = this; //jshint ignore:line
        vm.updateUI = updateUI;
        vm.start = start;
        vm.stop = stop;
        vm.displayNumber = displayNumber;
        vm.isDisplayHour = isDisplayHour;
        var intervalTimeSpent = null;

        init();

        function init() {
            // $scope.totalSeconds = vm.startTime;
            // calculate
            vm.updateUI(vm.startTime);

            if (vm.autoStart) {
                vm.start();
            }

            $scope.$on('startTimeSpent', function(e) {
                vm.start();
            });

            $scope.$on('stopTimeSpent', function(e) {
                stop();
            });
        }

        function start() {
            if (!vm.interval) {
                vm.interval = 1000; // set default
            }

            intervalTimeSpent = setInterval(function () {

                vm.startTime = Number(vm.startTime) + 1;
                vm.updateUI(vm.startTime);
                // emit update event
                vm.onChange( {data: { totalSeconds: vm.startTime }});
                $scope.$digest();
            }, vm.interval);
        }

        function stop() {
            clearInterval(intervalTimeSpent);
            $interval.cancel(intervalTimeSpent);
        }

        function displayNumber(number) {
            return number < 10 ? "0" + number : number;
        }

        function updateUI(seconds) {
            $scope.hour = vm.displayNumber(parseInt(seconds / 3600));
            if (vm.isDisplayHour() && seconds >= 3600) {
                $scope.minute = vm.displayNumber(parseInt((seconds - (3600 * Number($scope.hour))) / 60));
            } else {
                $scope.minute = vm.displayNumber(parseInt(seconds / 60));
            }
            $scope.second = vm.displayNumber(seconds % 60);
        }

        $scope.isDisplayHour = function() {
            return vm.isDisplayHour()
        }

        function isDisplayHour() {
            return vm.maxTimeUnit == 'hour';
        }

    }

    angular.module('ipmApp').component('timeSpent', {
        bindings: {
            interval: '<', // in millisecond default = 1000
            startTime: '=', // in seconds
            autoStart: '<', // default false
            maxTimeUnit: '<', // max is 'hour', min is 'minute', default is minute
            onChange: '&'
        },
        template: '<span> <span ng-if="isDisplayHour()">{{hour}} hours</span> {{minute}} minutes, {{second}} seconds </span>',
        controller: TimeSpentComponent,
        controllerAs: 'vm'
    })
})();
