(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPReportPTController', FRPReportPTController);

    FRPReportPTController.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'FrpReportService', 'frpReport', '$timeout', 'DataUtils', '$filter', 'ipmConstants', 'isView', 'templates', 'DateUtils', 'MdeReportService', '$window'];

    function FRPReportPTController($scope, $state, $stateParams, $uibModal, FrpReportService, frpReport, $timeout, DataUtils, $filter, constants, isView, templates, DateUtils, MdeReportService, $window) {
        var vm = this;
        vm.averageCode = 'O40';
        vm.frpReport = frpReport ? frpReport : {};
        vm.isSaving = false;
        vm.cancelMessage = 'ipmApp.mdeReport.admin.confirmCancel';
        vm.deletingMidsessionColumnMessage = 'ipmApp.frpReport.pt.deletingMidsessionColumn';
        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1
        };
        vm.dateFormatToServer = 'yyyy-MM-dd';
        vm.dateFormat = "MM/dd/yyyy";
        vm.isFinalize = false;
        vm.isChanged = isChanged;
        vm.isDisabled = getIsView();
        vm.subjectiveNoneRomTest = ['O1','O2','O3','O4','O5'];
        vm.subjectiveRomTest = ['O6','O7','O8','O9','O10','O11','O12','O13','O14', 'O100'];
        vm.templateInputType = constants.FRP_MDE_TEMPLATE;
        vm.templates = templates ? templates : [];
        vm.midsessionsRemovedList = [];
        vm.typeSubjectives = 0;
        vm.typeObjective = 1;
        vm.notRequiredObjectives = ['O4','O5'];
        vm.isSubjectiveRequired = true;
        vm.severity = constants.SEVERITY;
        vm.dataHeaderReport = {};
        vm.otherObjectiveDeletes = [];
        vm.subjectiveImportMdeScores = false;
        vm.objectiveImportMdeScores = false;
        vm.subjectiveChecked = false;
        vm.isDischargeReport = false;
        vm.isFirstReport = false;
        vm.haveMidsessionPrevious = false;
        init();

        function init() {
            vm.frpReportId = $stateParams.id;
            vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);
            vm.haveMidsessionPrevious = vm.frpReport && vm.frpReport.haveMidsessionPrevious ? true : false;
            if (vm.frpReport && vm.frpReport.frpReportAdmin && vm.frpReport.frpReportAdmin.reportType) {
                vm.reportType = vm.frpReport.frpReportAdmin.reportType;
                if (vm.reportType === constants.FRP_REPORT_TYPE.FIRST_REPORT) {
                    vm.isFirstReport = true;
                } else if (vm.reportType === constants.FRP_REPORT_TYPE.WEEKLY_REPORT) {
                    vm.isWeeklyReport = true;
                    vm.isSubjectiveRequired = false;
                } else if (vm.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT){
                    vm.isMidsessionReport = true;
                } else if (vm.reportType === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT) {
                    vm.isDischargeReport = true;
                } else if (vm.reportType === constants.FRP_REPORT_TYPE.OTHER_REPORT) {
                    vm.isOtherReport = true;
                }
            }

            FrpReportService.getFrpPtReport($stateParams.id).then(function (data) {
                vm.report = data;
                vm.isFinalize = vm.report && vm.report.finalize;
                vm.updateSelectAllCheckboxSubjective();
                if (vm.frpReport.firstReport && vm.frpReport.mdeReportId > 0) {
                    MdeReportService.getMdePtReport(vm.frpReport.mdeReportId).then(function(data) {
                        vm.mdePtReport = data;
                    });
                }

                // convert before add new midsession column
                _.forEach(vm.report.subjectives[0].midsessions, function(ms) {
                    if (ms && ms.dated) {
                        ms.dated = DateUtils.convertDateIgnoreTimeZone(ms.dated);
                    }
                    updateDateMidSession(ms); // not convertDateIgnoreTimeZone
                });
                if (vm.report && !_.isEmpty(vm.report.subjectives) && _.isEmpty(vm.report.subjectives[0].midsessions) && !vm.report.isMidSessionSubjectiveUpdated) {
                    vm.addMidsessionToSubjectiveTesting();
                }

                // convert before add new midsession column
                _.forEach(vm.report.objectives, function (o) {
                    o.$isCollapsed = true;
                    _.forEach(o.childs[0].midsessions, function(ms) {
                        if (ms && ms.dated) {
                            ms.dated = DateUtils.convertDateIgnoreTimeZone(ms.dated);
                        }
                        updateDateMidSession(ms); // not convertDateIgnoreTimeZone
                    });
                    // set checked if it is all objective checked of object parent
                    setIsAllObjectiveChecked(o, getOtherObjectiveOfObjective(o));
                });
                if (vm.report && !_.isEmpty(vm.report.objectives) && !vm.report.isMidSessionObjectiveUpdated) {
                    _.forEach(vm.report.objectives, function (objective) {
                        if (!_.isEmpty(objective.childs) && _.isEmpty(objective.childs[0].midsessions)) {
                            vm.addMidsessionToObjectiveTesting(objective);
                        } else { // init data missession for otherObjective, don't create for objectives
                            // get otherObjectives Of Objective parent by typeReportId
                            var otherObjectivesOfObjective = getOtherObjectiveOfObjective(objective);
                            // create new mission when missions is empty
                            if (!_.isEmpty(otherObjectivesOfObjective) && _.isEmpty(otherObjectivesOfObjective[0].midsessions)) {
                                addNewMidsessionsToOtherObjective(otherObjectivesOfObjective, objective);
                            }
                        }

                    });
                }

                vm.reportOld = DataUtils.deepCopy(data);

                resetDateRequest(vm.report);
                resetDateRequest(vm.reportOld);
            });
        }

        function getOtherObjectiveOfObjective(objective) {
            return _.filter(vm.report.otherObjectives, function (other) {
                return other.typeReportId === objective.typeReportId;
            });
        }

        function setIsAllObjectiveChecked(objective, otherObjectivesOfObjective) {
            var isAllObjectiveChecked = !_.isEmpty(objective.childs) && _.isEmpty(_.filter(objective.childs, function (child) { return child.code !== vm.averageCode && !child.checked; }));
            if (isAllObjectiveChecked && !_.isEmpty(otherObjectivesOfObjective)) {
                isAllObjectiveChecked = _.every(otherObjectivesOfObjective, function (child) { return child.checked; });
            }
            objective.checked = isAllObjectiveChecked;
            vm.calculatorLR(objective);
        }

        function getIsView() {
            if (isView) {
                return true;
            }
            if (frpReport) {
                return frpReport.statusFinal === constants.STATUS_FINAL_FRP_MDE || (frpReport.patientFRPMDE && frpReport.patientFRPMDE.isViewFrpProgram);
            }
            return false;
        }

        vm.calculatorLR = function (objective) {
            if (objective && objective.code === 'O5') {
                calcAndUpdateSum(objective.childs, constants.FRP_REPORT_TYPE.FIRST_REPORT);  // update first report

                for (var i = 0; i < objective.childs[0].midsessions.length; i++) {
                    calcAndUpdateSum(objective.childs, constants.FRP_REPORT_TYPE.MID_SESSION_REPORT, i); // update midsession report
                }

                calcAndUpdateSum(objective.childs, constants.FRP_REPORT_TYPE.DISCHARGE_REPORT); //update orther report
            }
        }

        function calcAndUpdateSum(childs, type, index) {
            var rightValue = 0;
            var leftValue = 0;
            var countR = 0;
            var countL = 0;
            var isAllCheck = true;
            childs.forEach(function (child) {
                var left = getLeftChild(child, type, index);
                var right = getRightChild(child, type, index);
                if (child.code != vm.averageCode) {
                    if (child.checked) { // calculate total Average
                         if(right && !isNaN(right) && Number(right) !== 0) {
                             rightValue += Number(right);
                             countR ++;
                         }
                        if (left && !isNaN(left) && Number(left) !== 0) {
                            leftValue += Number(left);
                            countL++;
                        }
                    } else if(isAllCheck){
                       isAllCheck = false;
                    }
                } else { // calculate Average
                     if(isAllCheck) {
                         right = calAverage(rightValue, countR);
                         left = calAverage(leftValue, countL);
                         // update Average
                         updateAverage(child, left, right, type, index);
                     } else {
                        updateAverage(child, null, null, type, index);  //set average is null if have a Notch uncheck
                    }
                    // show average when all checked
                    child.checked = isAllCheck;
                     return;
                }
            });
        }

        function updateAverage(child, left, right, type, index) {
            if (type === constants.FRP_REPORT_TYPE.FIRST_REPORT) {
                child.leftValue = left;
                child.rightValue = right;
            }

            if (type === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT) {
                child.midsessions[index].testScoreLeft = left;
                child.midsessions[index].testScoreRight = right;
            }

            if (type === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT) {
                child.dischargeLeft = left;
                child.dischargeRight = right;
            }
        }

        function getLeftChild(child, type, index){
             if (type === constants.FRP_REPORT_TYPE.FIRST_REPORT) {
                return child.leftValue;
             }

             if (type === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT) {
                return child.midsessions[index].testScoreLeft;
             }

             if (type === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT) {
                return child.dischargeLeft;
             }
        }

        function getRightChild(child, type, index){
            if (type === constants.FRP_REPORT_TYPE.FIRST_REPORT) {
                return child.rightValue;
            }

            if (type === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT) {
               return child.midsessions[index].testScoreRight;
            }

            if (type === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT) {
                return child.dischargeRight;
            }
        }

        function calAverage(total, count) {
            if (count > 0) {
                return (total / count).toFixed(2);
            } else {
                return null;
            }
        }
        /**
         * updateDateMidSession.
         * fix bug IPMproject-B1918: update date null = now
         * @param midsession
         */
        function updateDateMidSession(midsession) {
            if (!vm.isDisabled) {
                midsession.dated = midsession.dated ? midsession.dated : new Date();
            }
        }

        vm.isAverage = function(child) {
            return child.code === vm.averageCode;
        }

        function checkValidation() {
            vm.editForm['subjective'].$setDirty();
            vm.editForm['physical_functional_narrative'].$setDirty();
            _.forEach(vm.report.subjectives, function(s) {
                if (s.checked) {
                    vm.editForm['score_' + s.code].$setDirty();
                    vm.editForm['discharge_' + s.code].$setDirty();
                    s.midsessions.forEach(function (itemMis, index) {
                        vm.editForm[s.code + '_midsession_' + index].$setDirty();
                    });
                }
            });
            _.forEach(vm.report.objectives, function(o) {
                _.forEach(o.childs, function(c) {
                    validateObjectives(c);
                });
            });
            _.forEach(vm.report.otherObjectives, function(o) {
                validateObjectives(o);
                vm.editForm['name_' + o.code].$setDirty();
            });
            checkValidationColumnMidsessions();
        }

        function checkValidationColumnMidsessions() {
            // set $setDirty for midsessionsDate column subjectives
            var midsessionsSubFirst = vm.report.subjectives[0].midsessions;
            if (midsessionsSubFirst) {
                midsessionsSubFirst.forEach(function (item, $index) {
                    var colName = 'field_subjective_midsession_date_' + $index;
                    vm.editForm[colName].$setDirty();
                });
            }
            // set $setDirty for midsessionsDate column objectives
            vm.report.objectives.forEach(function (obj) {
                if (obj.childs) {
                    var childMidsessionsfrist = obj.childs[0].midsessions;
                    if (childMidsessionsfrist) {
                        childMidsessionsfrist.forEach(function (item, $index) {
                            var colName = 'field_objective_midsession_date_' + obj.code + "_" + $index;
                            vm.editForm[colName].$setDirty();
                        });
                    }
                }
            });
        }

        vm.save = function () {
            submitData(false);
        }

        vm.finalize = function () {
            submitData(true);
        }

        function validateObjectives(c) {
            if (c.checked) {
                if (c.type === 'NORMAL_TEXT_FIELD') {
                    vm.editForm['input_' + c.code].$setDirty();
                } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                    vm.editForm['left_input_' + c.code].$setDirty();
                    vm.editForm['right_input_' + c.code].$setDirty();
                }
                // Discharge
                if (c.type === 'NORMAL_TEXT_FIELD') {
                    vm.editForm['discharge_' + c.code].$setDirty();
                } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                    vm.editForm['discharge_left_' + c.code].$setDirty();
                    vm.editForm['discharge_right_' + c.code].$setDirty();
                }
                // Midsession
                for (var indexMs = 0; indexMs < c.midsessions.length; indexMs++) {
                    if (c.type === 'NORMAL_TEXT_FIELD') {
                        vm.editForm[c.code + '_midsession_' + indexMs].$setDirty();
                    } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        vm.editForm[c.code + '_midsession_right_' + indexMs].$setDirty();
                        vm.editForm[c.code + '_midsession_left_' + indexMs].$setDirty();
                    }
                }
            }
        }

        function isEqualSubjectives(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var subjectiveOld = v1[idx];
                var subjective = v2[idx];
                isEqual = DataUtils.isEqualBoolean(subjectiveOld.checked, subjective.checked);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(subjectiveOld.score, subjective.score);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(subjectiveOld.notes, subjective.notes);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(subjectiveOld.discharge, subjective.discharge);
                if (!isEqual) return false;
                isEqual = isEqualMidsessions(subjectiveOld.midsessions, subjective.midsessions);
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function isEqualObjectives(v1, v2) {
            var isEqual = true;
            for (var idx = 0; idx < v1.length; idx++) {
                var objectiveOld = v1[idx];
                var objective = v2[idx];
                for (var index = 0; index < objectiveOld.childs.length; index++) {
                    var childOld = objectiveOld.childs[index];
                    var child = objective.childs[index];
                    isEqual = DataUtils.isEqualBoolean(childOld.checked, child.checked);
                    if (!isEqual) return false;
                    if (childOld.type === 'NORMAL_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(childOld.value, child.value);
                        if (!isEqual) return false;
                    } else if (childOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(childOld.leftValue, child.leftValue);
                        if (!isEqual) return false;
                        isEqual = DataUtils.isEqualText(childOld.rightValue, child.rightValue);
                        if (!isEqual) return false;
                    }
                    if (childOld.type === 'NORMAL_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(childOld.discharge, child.discharge);
                        if (!isEqual) return false;
                    } else if (childOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        isEqual = DataUtils.isEqualText(childOld.dischargeLeft, child.dischargeLeft);
                        if (!isEqual) return false;
                        isEqual = DataUtils.isEqualText(childOld.dischargeRight, child.dischargeRight);
                        if (!isEqual) return false;
                    }

                    isEqual = DataUtils.isEqualText(childOld.notes, child.notes);
                    if (!isEqual) return false;
                    isEqual = isEqualMidsessions(childOld.midsessions, child.midsessions);
                    if (!isEqual) return false;
                }
            }
            return isEqual;
        }

        function isEqualOtherObjectives(v1, v2) {
            var isEqual = true;
            if (v1.length != v2.length) {
                return false;
            }
            for (var idx = 0; idx < v1.length; idx++) {
                var otherObjectiveOld = v1[idx];
                var otherObjective = v2[idx];
                isEqual = DataUtils.isEqualBoolean(otherObjectiveOld.checked, otherObjective.checked);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(otherObjectiveOld.name, otherObjective.name);
                if (!isEqual) return false;
                if (otherObjectiveOld.type === 'NORMAL_TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.value, otherObjective.value);
                    if (!isEqual) return false;
                } else if (otherObjectiveOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.leftValue, otherObjective.leftValue);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.rightValue, otherObjective.rightValue);
                    if (!isEqual) return false;
                }
                if (otherObjectiveOld.type === 'NORMAL_TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.discharge, otherObjective.discharge);
                    if (!isEqual) return false;
                } else if (otherObjectiveOld.type === 'LEFT_RIGHT_TEXT_FIELD') {
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.dischargeLeft, otherObjective.dischargeLeft);
                    if (!isEqual) return false;
                    isEqual = DataUtils.isEqualText(otherObjectiveOld.dischargeRight, otherObjective.dischargeRight);
                    if (!isEqual) return false;
                }

                isEqual = DataUtils.isEqualText(otherObjectiveOld.notes, otherObjective.notes);
                if (!isEqual) return false;
                isEqual = isEqualMidsessions(otherObjectiveOld.midsessions, otherObjective.midsessions);
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function isChanged() {
            var isEqual = true;
            // Subjective
            isEqual = DataUtils.isEqualText(vm.reportOld.subjective, vm.report.subjective);
            if (!isEqual) return true;
            // Physical Functional Narrative
            isEqual = DataUtils.isEqualText(vm.reportOld.physicalFunctionalNarrative, vm.report.physicalFunctionalNarrative);
            if (!isEqual) return true;
            // Subjectives Testing
            isEqual = isEqualSubjectives(vm.reportOld.subjectives, vm.report.subjectives);
            if (!isEqual) return true;
            // Objective Testing
            isEqual = isEqualObjectives(vm.reportOld.objectives, vm.report.objectives);
            if (!isEqual) return true;
            if (!_.isEmpty(vm.reportOld.otherObjectives) || !_.isEmpty(vm.report.otherObjectives)) {
                isEqual = isEqualOtherObjectives(vm.reportOld.otherObjectives, vm.report.otherObjectives);
                if (!isEqual) return true;
            }
            return !isEqual;
        }

        function back() {
            $state.go('frp-report', { id: vm.frpReportId });
        }

        vm.cancel = function () {
            if (vm.isDisabled) {
                back();
                return;
            }
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        vm.confirmDialogRemoveMidsessions = function (message, type, object, $index) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'center-dialog-message vertical-center',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    if (type === vm.typeSubjectives) {
                        vm.removeColMidsessionsSubjectivesCallBack(object, $index);
                        vm.report.isMidSessionSubjectiveUpdated = true;
                    }

                    if (type === vm.typeObjective) {
                        vm.removeColMidsessionsObjectivesCallBack(object, $index);
                        vm.report.isMidSessionObjectiveUpdated = true;
                    }
                }
            });
        }

        function submitData(finalize) {
            if (finalize) {
                checkValidation();
                if (vm.editForm.$invalid || !vm.hasTestingChecked()) {
                    return;
                }
            }
            vm.isSaving = true;
            vm.report.finalize = finalize;
            if(vm.report.physicalFunctionalNarrativeTemplate && vm.report.physicalFunctionalNarrativeTemplate.content != vm.report.physicalFunctionalNarrative) {
                vm.report.physicalFunctionalNarrativeTemplate = null;
            }

            if(vm.report.subjectiveTemplate && vm.report.subjectiveTemplate.content != vm.report.subjective) {
                vm.report.subjectiveTemplate = null;
            }

            if (vm.reportType) { // fix bug IPMproject-B1917: only update when have reportType
                prepairDataForSave();
                vm.report.initializedDataTesting = true; // always update initializedDataTesting is true when save frpReportPT
            } else {
                // clear midsessions when report type is not selected
                if (vm.report.objectives) {
                    vm.report.objectives.forEach(function (sr) {
                        if (sr.childs) {
                            sr.childs.forEach(function (sc) {
                                sc.midsessions = [];
                            });
                        }
                    });
                }
                if (vm.report.subjectives) {
                    vm.report.subjectives.forEach(function (sr) {
                        sr.midsessions = [];
                    });
                }
            }
            FrpReportService.saveFrpPtReport(vm.frpReportId, vm.report).then(function (response) {
                onSaveSuccess(response);
            }, function error(e) {
                onSaveError(e);
            });
        }

        function onSaveSuccess(response) {
            vm.isSaving = false;
            back();
        }

        function onSaveError(error) {
            // var errorMessage = error.headers('X-ipmApp-error-message');
            if (error && (error.status === 409 || error.status === 400)) {
                vm.error = 'ERROR';
                openErrorDuplicateDialog();
            }
            vm.isSaving = false;
        }

        function prepairDataForSave() {
            // prepair for Subjectives Testing
            prepareMidssessionDated(vm.report.subjectives);
            // prepair for Objectives Testing
            _.forEach(vm.report.objectives, function (objective) {
                prepareMidssessionDated(objective.childs);
            });

            if (vm.report.otherObjectives && !_.isEmpty(vm.report.otherObjectives)) {
                prepareMidssessionDatedOfOtherObjectives(vm.report.otherObjectives);
            }
            // update Midssession removed
            updateMidssessionRemoved();
            // prepareMidssessionDated(vm.report.midsessionsRemoved[0].dated);
            _.forEach(vm.report.midsessionsRemoved, function (item) {
                if (!Number.isNaN(item)) {
                    item.dated = $filter('date')(item.dated, vm.dateFormatToServer);
                }
            });
            $filter('date')(vm.report.midsessionsRemoved.dated, vm.dateFormatToServer)

            _.forEach(vm.report.otherObjectives, function(other) {
                if (!other.checked) {
                    other.checked = false;
                }
                if (!other.checked && !other.value && !other.discharge && !other.name && !isDirtyMs(other)) {
                    other.active = false;
                }
            });

            _.forEach(vm.otherObjectiveDeletes, function (otherObjectiveDelete) {
                _.forEach(otherObjectiveDelete.midsessions, function(item) {
                    if (!Number.isNaN(item)) {
                        item.dated = $filter('date')(item.dated, vm.dateFormatToServer);
                    }
                })
            });
            vm.report.otherObjectives.push.apply(vm.report.otherObjectives, vm.otherObjectiveDeletes);
        }

        function isDirtyMs(other) {
            _.forEach(other.midsessions, function (ms) {
                if (ms.value) {
                    other.active = true;
                    return true;
                }
            });
            return false;
        }

        function prepareMidssessionDated(items) {
            var firstItem = items[0];
            if (!_.isEmpty(firstItem.midsessions)) {
                _.forEach(items, function (item) {
                    formatDateToServer(item.midsessions, firstItem);
                });
            }
        }

        function prepareMidssessionDatedOfOtherObjectives(items) {
            var firstItem = items[0];
            if (!_.isEmpty(firstItem.midsessions)) {
                _.forEach(items, function (item) {
                    _.forEach(item.midsessions, function (ms) {
                        ms.dated = $filter('date')(ms.dated, vm.dateFormatToServer);
                    });
                });
            }
        }

        function formatDateToServer(midsessions, item) {
            _.forEach(midsessions, function (ms, $index) {
                ms.dated = $filter('date')(item.midsessions[$index].dated, vm.dateFormatToServer);
            });
        }
        vm.hasObjectiveChecked = function() {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return true;
            var isChecked = false;
            for (var index = 0; index < vm.report.objectives.length; index++) {
                isChecked = vm.hasChildChecked(vm.report.objectives[index]);
                if (!isChecked) return false;
            }
            return isChecked;
        }

        vm.hasOtherObjectiveChecked = function() {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport || _.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked; })) ) return true;
            var isChecked = false;
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                isChecked = vm.hasOtherChecked(vm.report.otherObjectives[index]);
                if (!isChecked) return false;
            }
            return isChecked;
        }

        vm.hasObjectiveCheckedInvalid = function() {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return false;
            for (var index = 0; index < vm.report.objectives.length; index++) {
                var o = vm.report.objectives[index];
                var invalid = vm.hasChildCheckedInvalid(o);
                if (invalid) return true;
            }
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var o = vm.report.otherObjectives[index];
                var invalid = vm.hasOtherCheckedInvalid(o);
                if (invalid) return true;
            }
            return false;
        }

        vm.hasOtherObjectiveCheckedInvalid = function() {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return false;
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var o = vm.report.otherObjectives[index];
                var invalid = vm.hasOtherCheckedInvalid(o);
                if (invalid) return true;
            }
            return false;
        }

        vm.hasChildChecked = function (objective) {
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport || vm.notRequiredObjectives.includes(objective.code)) return true;
            if (objective) {
                if (vm.subjectiveNoneRomTest.includes(objective.code)) {
                    return !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; })) || !_.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked && o.typeReportId === objective.typeReportId; }))
                } else if (vm.subjectiveRomTest.includes(objective.code)) {
                    return vm.hasRomTestingChecked();
                }
            }
            return false;
        }

        vm.hasOtherChecked = function (otherObjective) {
            var parentCode = getParentCode(otherObjective.code);
            if (vm.isOtherReport || vm.notRequiredObjectives.includes(parentCode)) return true;
            if (otherObjective) {
                if (vm.subjectiveNoneRomTest.includes(parentCode)) {
                    return otherObjective.checked;
                } else if (vm.subjectiveRomTest.includes(parentCode)) {
                    return vm.hasOtherRomTestingChecked();
                }
            }
            return false;
        }

        vm.isChildRequired = function(objective) {
            if (vm.isOtherReport) return false;
            if (objective) {
                if (vm.subjectiveNoneRomTest.includes(objective.code)) {
                    return !(vm.notRequiredObjectives.includes(objective.code));
                } else if (vm.subjectiveRomTest.includes(objective.code)) {
                    if (vm.hasRomTestingChecked()) {
                        return vm.hasChildCheckedNotCheckRom(objective);
                    } else {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.isOtherRequired = function(objective) {
            if (vm.isOtherReport) return false;
            var parentCode = getParentCode(objective.code);
            if (objective) {
                if (vm.subjectiveNoneRomTest.includes(parentCode)) {
                    return !(vm.notRequiredObjectives.includes(parentCode));
                } else if (vm.subjectiveRomTest.includes(parentCode)) {
                    if (vm.hasOtherRomTestingChecked()) {
                        return vm.hasOtherCheckedNotCheckRom(objective);
                    } else {
                        return true;
                    }
                }
            }
            return false;
        }

        vm.hasRomTestingChecked = function() {
            for (var index = 0; index < vm.report.objectives.length; index++) {
                var objective = vm.report.objectives[index];
                if (vm.subjectiveRomTest.includes(objective.code)) {
                    var isChecked = !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; }))
                    if (isChecked) return true;
                }
            }
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var objective = vm.report.otherObjectives[index];
                if (vm.subjectiveRomTest.includes(getParentCode(objective.code))) {
                    var isChecked = objective.checked;
                    if (isChecked) return true;
                }
            }
            return false;
        }

        vm.hasOtherRomTestingChecked = function() {
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var otherObjective = vm.report.otherObjectives[index];
                if (vm.subjectiveRomTest.includes(getParentCode(otherObjective.code))) {
                    var isChecked = otherObjective.checked;
                    if (isChecked) return true;
                }
            }
            return false;
        }

        vm.hasChildCheckedNotCheckRom = function(objective) {
            if (objective) {
                return !_.isEmpty(_.filter(objective.childs, function(o) { return o.checked; })) || !_.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked && o.typeReportId === objective.typeReportId; }))
            }
            return false;
        }

        vm.hasOtherCheckedNotCheckRom = function(objective) {
            if (objective) {
                return !_.isEmpty(_.filter(vm.report.otherObjectives, function(o) { return o.checked && o.typeReportId == objective.typeReportId; }))
            }
            return false;
        }

        vm.hasChildCheckedInvalid = function(objective) {
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return false;
            for (var index = 0; index < objective.childs.length; index++) {
                var c = objective.childs[index];
                if (c.checked) {
                        if (c.type === 'NORMAL_TEXT_FIELD') {
                            var control = vm.editForm['input_' + c.code];
                            var controlDischarge = vm.editForm['discharge_' + c.code];
                            if (control && control.$dirty && control.$error.required) return true;
                            if (controlDischarge && controlDischarge.$dirty && controlDischarge.$error.required) return true;
                        } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                            var controlLeft = vm.editForm['left_input_' + c.code];
                            var controlRight = vm.editForm['right_input_' + c.code];
                            var controlDischargeRight = vm.editForm['discharge_right_' + c.code];
                            var controlDischargeLeft = vm.editForm['discharge_left_' + c.code];
                            if (controlLeft && controlLeft.$dirty && controlLeft.$error.required) return true;
                            if (controlRight && controlRight.$dirty && controlRight.$error.required) return true;
                            if (controlDischargeRight && controlDischargeRight.$dirty && controlDischargeRight.$error.required) return true;
                            if (controlDischargeLeft && controlDischargeLeft.$dirty && controlDischargeLeft.$error.required) return true;
                        }
                    for (var indexMs = 0; indexMs < c.midsessions.length; indexMs++) {
                        if (c.type === 'NORMAL_TEXT_FIELD') {
                            var msControl = vm.editForm[c.code + '_midsession_' + indexMs]
                            if (msControl && msControl.$dirty && msControl.$error.required) return true;
                        } else if (c.type === 'LEFT_RIGHT_TEXT_FIELD') {
                            var msControlLeft = vm.editForm[c.code + '_midsession_left_' + indexMs];
                            var msControlRight = vm.editForm[c.code + '_midsession_right_' + indexMs];
                            if (msControlLeft && msControlLeft.$dirty && msControlLeft.$error.required) return true;
                            if (msControlRight && msControlRight.$dirty && msControlRight.$error.required) return true;
                        }
                    }
                }
            }
            return false;
        }

        vm.hasOtherCheckedInvalid = function(objective) {
            if (vm.isOtherReport) return false;
            for (var index = 0; index < vm.report.otherObjectives.length; index++) {
                var otherObjective = vm.report.otherObjectives[index];
                if (otherObjective.checked && otherObjective.typeReportId === objective.typeReportId) {
                    var controlName = vm.editForm['name_' + otherObjective.code];
                    if (controlName && controlName.$dirty && controlName.$error.required) return true;
                    if (otherObjective.type === 'NORMAL_TEXT_FIELD') {
                        var control = vm.editForm['input_' + otherObjective.code];
                        if (control && control.$dirty && control.$error.required) return true;
                    } else if (otherObjective.type === 'LEFT_RIGHT_TEXT_FIELD') {
                        var controlLeft = vm.editForm['left_input_' + otherObjective.code];
                        var controlRight = vm.editForm['right_input_' + otherObjective.code];
                        if (controlLeft && controlLeft.$dirty && controlLeft.$error.required) return true;
                        if (controlRight && controlRight.$dirty && controlRight.$error.required) return true;
                    }
                    for (var indexMs = 0; indexMs < otherObjective.midsessions.length; indexMs++) {
                        if (otherObjective.type === 'NORMAL_TEXT_FIELD') {
                            var msControl = vm.editForm[otherObjective.code + '_midsession_' + indexMs]
                            if (msControl && msControl.$dirty && msControl.$error.required) return true;
                        } else if (otherObjective.type === 'LEFT_RIGHT_TEXT_FIELD') {
                            var msControlLeft = vm.editForm[otherObjective.code + '_midsession_left_' + indexMs];
                            var msControlRight = vm.editForm[otherObjective.code + '_midsession_right_' + indexMs];
                            if (msControlLeft && msControlLeft.$dirty && msControlLeft.$error.required) return true;
                            if (msControlRight && msControlRight.$dirty && msControlRight.$error.required) return true;
                        }
                    }
                }
            }
            return false;
        }

        vm.hasItemChecked = function (listItem) {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return true;
            if (listItem) {
                return !_.isEmpty(_.filter(listItem, function (s) { return s.checked; }))
            }
            return false;
        }

        vm.hasItemCheckedInvalid = function(listItem) {
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return false;
            for (var index = 0; index < listItem.length; index++) {
                var s = listItem[index];
                if (s.checked) {
                    var control = vm.editForm['score_' + s.code];
                    if (control.$dirty && control.$error.required) return true;
                }
            }
        }

        vm.hasTestingChecked = function() {
            // Report Type undefined
            if (!vm.reportType) return false;
            // Other Report not required Subjective Test and Objective Test
            if (vm.isOtherReport) return true;
            // At least 1 Subjective Test is required
            var isChecked = vm.hasItemChecked(vm.report.subjectives);
            if (!isChecked) return false;
            // At least 1 Activity Test of Objective Test is required
            for (var index = 0; index < vm.report.objectives.length; index++) {
                var objective = vm.report.objectives[index];
                isChecked = vm.hasChildChecked(objective);
                if (!isChecked) return false;
            }
            return true;
        }

        function resetDateRequest(data) {
            if (data && !_.isEmpty(data.subjectives) && !_.isEmpty(data.subjectives[0].midsessions)) {
                _.forEach(data.subjectives, function (s, $idx) {
                    _.forEach(s.midsessions, function (ms) {
                        onResetDateRequest(ms, $idx === 0);
                    });
                });
            }
            if (data && !_.isEmpty(data.objectives)) {
                if (!_.isEmpty(data.objectives[0].childs) && !_.isEmpty(data.objectives[0].childs[0].midsessions)) {
                    _.forEach(data.objectives, function (o, $id) {
                        _.forEach(o.childs, function (c, $idx) {
                            _.forEach(c.midsessions, function (ms) {
                                onResetDateRequest(ms, $id === 0 && $idx === 0);
                            });
                        });
                    });
                }
            }
        }

        function onResetDateRequest(midsession, isFirstLine) {
            if (isFirstLine) {
                midsession.datePickerOpenStatus = { dateRequested: false }
            }
        }

        vm.addMidsessionToSubjectiveTesting = function () {
            var firstSubjective = vm.report.subjectives[0];
            var currentDate = vm.isMidsessionReport ? new Date() : null;
            var orderNumber = _.isEmpty(firstSubjective.midsessions) ? 0 : _.maxBy(firstSubjective.midsessions, function (ms) { return ms.orderNumber }).orderNumber + 1;

            _.forEach(vm.report.subjectives, function (s) {
                s.midsessions.push({
                    dated: currentDate,
                    testScore: '',
                    testScoreRight: '',
                    testScoreLeft: '',
                    orderNumber: orderNumber,
                    active: true,
                    frpReportPTSubjectiveResponseId: s.id
                });
            });
        }

        vm.addMidsessionToObjectiveTesting = function (objective) {
            var firstChildObjective = objective.childs[0];
            var currentDate = vm.isMidsessionReport ? new Date() : null;
            var orderNumber = _.isEmpty(firstChildObjective.midsessions) ? 0 : _.maxBy(firstChildObjective.midsessions, function (ms) { return ms.orderNumber }).orderNumber + 1;
            _.forEach(objective.childs, function (c) {
                c.midsessions.push({
                    dated: currentDate,
                    testScore: '',
                    testScoreRight: '',
                    testScoreLeft: '',
                    orderNumber: orderNumber,
                    active: true,
                    frpReportPTObjectiveResponseId: c.id
                });
            });
            _.forEach(vm.report.otherObjectives, function (other) {
                if (other.typeReportId == objective.typeReportId) {
                    other.midsessions.push(createNewMidsession(currentDate, orderNumber));
                }

            });
        }

        function addNewMidsessionsToOtherObjective(otherObjectives, objective) {
            if (otherObjectives && objective)
            var firstChildObjective = objective.childs[0];
            var currentDate = vm.isMidsessionReport ? new Date() : null;
            var orderNumber = _.isEmpty(firstChildObjective.midsessions) ? 0 : _.maxBy(firstChildObjective.midsessions, function (ms) { return ms.orderNumber }).orderNumber + 1;
            _.forEach(otherObjectives, function (other) {
                    other.midsessions.push(createNewMidsession(currentDate, orderNumber));
            })
        }

        /**
         * createNewMidsession
         * clone old logic create NewMidsession
         * @param currentDate
         * @param orderNumber
         * @return {{testScoreRight: string, orderNumber: *, testScore: string, dated: *, active: boolean, testScoreLeft: string}}
         */
        function createNewMidsession(currentDate, orderNumber) {
            return {
                dated: currentDate,
                testScore: '',
                testScoreRight: '',
                testScoreLeft: '',
                orderNumber: orderNumber,
                active: true
            }
        }

        vm.openSubjectiveCalendar = function ($idx, date) {
            if (vm.checkDisableColMidsessions($idx, vm.report.subjectives[0].midsessions)) {
                return;
            }
            if (!vm.report.subjectives[0].midsessions[$idx].datePickerOpenStatus) {
                vm.report.subjectives[0].midsessions[$idx].datePickerOpenStatus = {
                    dateRequested: true
                };
            }
            vm.report.subjectives[0].midsessions[$idx].datePickerOpenStatus[date] = true;
        };

        vm.openObjectiveCalendar = function (objective, $idx, date) {
            if (vm.checkDisableColMidsessions($idx, objective.childs[0].midsessions)) {
                return;
            }
            if (!objective.childs[0].midsessions[$idx].datePickerOpenStatus) {
                objective.childs[0].midsessions[$idx].datePickerOpenStatus = {
                    dateRequested: true
                };
            }
            objective.childs[0].midsessions[$idx].datePickerOpenStatus[date] = true;
        };

        vm.subjectiveChangedTime = function ($index) {
            $timeout(function () {
                var firstSubjective = vm.report.subjectives[0];
                if (firstSubjective.midsessions[$index].dated) {
                    _.forEach(vm.report.subjectives, function (s, $idx) {
                        if ($idx > 0) { // skip first Subjective
                            s.midsessions[$index].dated = firstSubjective.midsessions[$index].dated;
                        }
                    });
                }
            }, 300);
        };

        vm.objectiveChangedTime = function (objective, $index) {
            $timeout(function () {
                var firstChildObjective = objective.childs[0];
                if (firstChildObjective.midsessions[$index].dated) {
                    _.forEach(vm.report.objectives, function (o, $id) {
                        if ($id > 0) { // skip first Objective
                            _.forEach(o.childs, function (c, $idx) {
                                if ($idx > 0) { // skip first Child Objective
                                    c.midsessions[$index].dated = firstChildObjective.midsessions[$index].dated;
                                }
                            });
                        }
                    });
                }
            }, 300);
        };

        vm.checkboxSubjectiveTestingChange = function(subjective) {
             vm.updateSelectAllCheckboxSubjective();
            // clear value;
            // subjective.score = null;
            // subjective.discharge = null;
            // subjective.notes = null;
        }
        vm.checkboxObjectiveTestingChange = function(objective, child) {
            vm.calculatorLR(objective);
            vm.updateSelectAllCheckbox(objective);
            // clear value;
            // child.value = null;
            // child.leftValue = null;
            // child.rightValue = null;
            // child.discharge = null;
        }
        vm.checkboxOtherObjectiveTestingChange = function(objective, child) {
        }
        vm.calculatorColspan = function(child) {
            return 4 + (child.midsessions ? child.midsessions.length : 0);
        }

        vm.removeColMidsessionsSubjectives = function (colSub, $index) {
            vm.confirmDialogRemoveMidsessions(vm.deletingMidsessionColumnMessage, vm.typeSubjectives, colSub, $index);

        }

        vm.removeColMidsessionsSubjectivesCallBack = function(colSub, $index) {
            if (vm.report.subjectives) {
                vm.report.subjectives.forEach(function (item) {
                    if (item.midsessions) {
                        if (item.midsessions[$index] && item.midsessions[$index].id) {
                            addToMidsessionsRemoved(item.midsessions[$index]);
                        }
                        item.midsessions.splice($index, 1);
                    }
                });
            }
        }

        vm.removeColMidsessionsObjectives = function (objective, $index) {
            vm.confirmDialogRemoveMidsessions(vm.deletingMidsessionColumnMessage, vm.typeObjective, objective, $index);
        }

        vm.removeColMidsessionsObjectivesCallBack = function(objective, $index) {
            if (objective) {
                objective.childs.forEach(function (item) {
                    if (item.midsessions) {
                        if (item.midsessions[$index] && item.midsessions[$index].id) {
                            addToMidsessionsRemoved(item.midsessions[$index]);
                        }
                        item.midsessions.splice($index, 1);
                    }
                });
                _.forEach(vm.report.otherObjectives, function(other) {
                    if (other.typeReportId === objective.typeReportId && other.midsessions) {
                        if (other.midsessions[$index] && other.midsessions[$index].id) {
                            addToMidsessionsRemoved(other.midsessions[$index]);
                        }
                        other.midsessions.splice($index, 1);
                    }
                })
            }
        }

        vm.disableRemoveColMidsessions = function () {
            return (!vm.isMidsessionReport && !vm.isOtherReport) || vm.isDisabled;
        }

        vm.checkDisableColMidsessions = function ($index, $midsessions) {
            return vm.isDisabled || vm.checkDisableMidsessions($index, $midsessions);
        }

        vm.checkDisableMidsessions = function (rowMidIndex, midsessions) {
            // If there is more than 1 Midsession column then the most recent one should be editable
            if (midsessions && midsessions.length > 0) {
                if (midsessions.length === 1) {
                    return false;
                } else {
                    if (midsessions.length <= 1 || rowMidIndex !== midsessions.length - 1) {
                        return true;
                    }
                }
            }
        }

        /**
         * checkHasDataMidsession
         * @param dataReport is subjectives or objectives
         * @param $index
         * @return {*}
         */
        function checkHasDataMidsession(dataReport, $index) {
            if (!dataReport) {
                return false;
            }
            return _.some(dataReport, function (item) {
                // get column date of midsession by index
                return item.midsessions && item.midsessions[$index] &&
                    (item.midsessions[$index].testScore || item.midsessions[$index].testScoreLeft || item.midsessions[$index].testScoreRight);
            });
        }

        vm.checkRequiredDateMidsessionSubjectives = function ($index) {
            if (!vm.report.subjectives[0] || !vm.report.subjectives[0].midsessions || vm.checkDisableColMidsessions($index, vm.report.subjectives[0].midsessions)) {
                return false;
            }
            var hasDataMidsession = checkHasDataMidsession(vm.report.subjectives, $index);
            return hasDataMidsession;
        };

        function getOtherObjectiveChildBy(objective) {
            // get report otherObjectives child.typeReportId == objective.typeReportId
            if (vm.report.otherObjectives && vm.report.otherObjectives.length > 0) {
                var otherObjectivesChild = _.filter(vm.report.otherObjectives, function (otherObjective) {
                    return objective.typeReportId === otherObjective.typeReportId;
                });
                return otherObjectivesChild;
            }
            return [];
        }

        vm.checkRequiredDateMidsession = function (objective, $index) {
            var firstChildObjective = objective.childs[0];

            if (!firstChildObjective || !firstChildObjective.midsessions || vm.checkDisableColMidsessions($index, firstChildObjective.midsessions)) {
                return false;
            }
            var hasDataMidsession = checkHasDataMidsession(objective.childs, $index);
            if (!hasDataMidsession) { // continue check in otherObjective if hasDataMidsession is false
                var otherObjectivesChild = getOtherObjectiveChildBy(objective);
                hasDataMidsession = checkHasDataMidsession(otherObjectivesChild, $index);
            }
            return hasDataMidsession;
        };

        vm.checkRequiredColMidsessions = function ($index, midsessions) {

            // If the report type is "First Report", the "Initial Score" column will be required.
            return !vm.isFirstReport && !vm.isOtherReport && !vm.checkDisableMidsessions($index, midsessions) && checkRequiredMidByWeeklyReport();
        }

        vm.checkRequiredFieldScore = function() {
            return !vm.isOtherReport;
        }

        vm.checkRequiredFieldDischarge= function() {
            return vm.isDischargeReport;
        }

        function checkRequiredMidByWeeklyReport() {
            if (vm.isWeeklyReport) {
                return vm.haveMidsessionPrevious ? true : false;
            }
            return true; //other WeeklyReport
        }

        vm.addOtherObjective = function(objective) {
            var orderNumber = 0;
            _.forEach(vm.report.otherObjectives, function(otherObjective) {
                if (otherObjective.typeReportId == objective.typeReportId && orderNumber < otherObjective.orderNumber) {
                    orderNumber = otherObjective.orderNumber;
                }
            });
            orderNumber++;
            var code = objective.code + '_O' + orderNumber;
            var copyObjective = DataUtils.deepCopy(objective.childs[0]);
            copyObjective.name = '';
            copyObjective.orderNumber = orderNumber;
            copyObjective.code = code;
            copyObjective.type = 'NORMAL_TEXT_FIELD';
            copyObjective.checked = true;
            copyObjective.typeReportId = objective.typeReportId;
            copyObjective.id = null;
            copyObjective.active = true;
            copyObjective.value = '';
            copyObjective.rightValue = '';
            copyObjective.leftValue = '';
            copyObjective.notes = '';
            copyObjective.discharge = '';
            copyObjective.dischargeLeft = '';
            copyObjective.dischargeRight = '';

            _.forEach(copyObjective.midsessions, function(ms) {
                ms.testScore = '';
                ms.testScoreLeft = '';
                ms.testScoreRight = '';
                ms.id = null;
            })
            vm.report.otherObjectives.push(copyObjective);
        }

        vm.deleteOtherObjectives = function(deleteItem) {
            _.remove(vm.report.otherObjectives, function (o) { return o.code === deleteItem.code; });
            if (!deleteItem.id && !deleteItem.active) {
                return;
            }
            deleteItem.active = false;
            vm.otherObjectiveDeletes.push(deleteItem);
            vm.updateOtherObjective(deleteItem);
        }

        function addToMidsessionsRemoved(midsessions) {
            if (midsessions) {
                midsessions.active = false;
                vm.midsessionsRemovedList.push(midsessions);
            }
        }

        function updateMidssessionRemoved() {
            vm.report.midsessionsRemoved = vm.midsessionsRemovedList;
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        function isEqualMidsessions(midsessionsOld, midsessions) {
            var isEqual = true;
            if ((midsessionsOld && midsessions && (midsessionsOld.length != midsessions.length) && vm.reportType)) return false;
            for (var indexMs = 0; indexMs < midsessionsOld.length; indexMs++) {
                isEqual = midsessionsOld[indexMs].id === midsessions[indexMs].id;
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(midsessionsOld[indexMs].dated, midsessions[indexMs].dated);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(midsessionsOld[indexMs].testScore, midsessions[indexMs].testScore);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(midsessionsOld[indexMs].testScoreRight, midsessions[indexMs].testScoreRight);
                if (!isEqual) return false;
                isEqual = DataUtils.isEqualText(midsessionsOld[indexMs].testScoreLeft, midsessions[indexMs].testScoreLeft);
                if (!isEqual) return false;
            }
            return isEqual;
        }

        function getParentCode(code) {
            return code.split('_')[0];
        }

        vm.importMdeScores = function(type) {
            if (type === 'subjective') {
                if (vm.subjectiveImportMdeScores && vm.mdePtReport.subjectives) {
                    _.forEach(vm.report.subjectives, function (frpSub) {
                        var mdeSub = _.first(_.filter(vm.mdePtReport.subjectives, function (item) {
                            return item && frpSub && item.code === frpSub.code;
                        }));
                        if (mdeSub) {
                            frpSub.score = mdeSub.score;
                            frpSub.checked = mdeSub.checked; // clone checked
                        }
                    });
                    // update check box select all Subjective
                    vm.updateSelectAllCheckboxSubjective();
                }
            } else if (type === 'objective') {
                if (vm.objectiveImportMdeScores && vm.mdePtReport.objectives) {
                    _.forEach(vm.report.objectives, function (frpObj) {
                        var mdeObj = _.first(_.filter(vm.mdePtReport.objectives, function (item) {
                            return item && frpObj && item.code === frpObj.code;
                        }));
                        if (mdeObj) {
                            _.forEach(frpObj.childs, function (frpChild) {
                                var mdeChild = _.first(_.filter(mdeObj.childs, function (child) {
                                    return child && frpChild && child.code === frpChild.code;
                                }));
                                if (mdeChild) {
                                    frpChild.value = mdeChild.value;
                                    frpChild.leftValue = mdeChild.leftValue;
                                    frpChild.rightValue = mdeChild.rightValue;
                                    frpChild.checked = mdeChild.checked; // clone checked
                                }
                            });
                        }
                        // update check box select all objective
                        vm.updateSelectAllCheckbox(frpObj);
                    });

                    vm.report.otherObjectives = [];
                    _.forEach(vm.mdePtReport.otherObjectives, function (objective) {
                        var copyObjective = DataUtils.deepCopy(objective);
                        copyObjective.id = null;
                        copyObjective.active = true;
                        copyObjective.discharge = '';
                        copyObjective.dischargeLeft = '';
                        copyObjective.dischargeRight = '';
                        copyObjective.fromMDE = true;

                        copyObjective.midsessions = [];
                        copyObjective.midsessions.push({
                            dated: new Date(),
                            testScore: '',
                            testScoreRight: '',
                            testScoreLeft: '',
                            orderNumber: 0,
                            active: true,
                            id: null
                        });
                        vm.report.otherObjectives.push(copyObjective);
                    });
                }
            }
        }

        vm.updateSelectAllCheckbox = function(objective) {
            var isCheckAll = true;
            objective.childs.forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })
            vm.report.otherObjectives.forEach(function (other){
                if(other.typeReportId === objective.typeReportId) {
                    if(!other.checked) {
                        isCheckAll = false;
                        return;
                    }
                }
            })

            objective.checked = isCheckAll;
        }

        vm.updateOtherObjective = function (child) {
            vm.report.objectives.forEach(function (objective) {
                if(child.typeReportId === objective.typeReportId) {
                    vm.updateSelectAllCheckbox(objective);
                }
            })
        }

        vm.onClickSelectAll = function(objective) {
            objective.childs.forEach(function (child) {
                child.checked = objective.checked;
            });
            vm.report.otherObjectives.forEach(function (other){
                if(other.typeReportId === objective.typeReportId) {
                    other.checked = objective.checked;
                }
            });

            vm.calculatorLR(objective);

        }

        vm.onClickSelectAllSubjective = function() {
            vm.report.subjectives.forEach(function (child) {
                child.checked = vm.subjectiveChecked;
            });
        }

        vm.updateSelectAllCheckboxSubjective = function() {
            var isCheckAll = true;
            vm.report.subjectives.forEach(function(child) {
                if(!child.checked) {
                    isCheckAll = false;
                    return;
                }
            })

            vm.subjectiveChecked = isCheckAll;
        };

        function openErrorDuplicateDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.frpReport.error.saveReportDuplicateMessage"},
                    title: function () {return "ipmApp.frpReport.error.saveReportDuplicateTitle"}
                }
            }).result.then(function() {
                $window.location.reload(); // reload page to load new data FRP
            }, function() {
            });
        }
    }
})();
