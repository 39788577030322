(function() {
    'use strict';

    angular
        .module('ipmApp')
        .directive('numberMinMax', numberMinMax);

        numberMinMax.$inject = [];



    function numberMinMax() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            scope: false,
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs, controller) {
            var min = parseInt(attrs.min);
            var max = parseInt(attrs.max);

            controller.$parsers.unshift(function (value) {
                var INTEGER_REGEXP = /^\d+$/;
                if (!value) {
                    controller.$setValidity('invalidRange', true);
                    controller.$setValidity('pattern', true);
                    return value;
                }
                if (INTEGER_REGEXP.test(value)) { // it is valid
                    controller.$setValidity('pattern', true);
                    var number = parseInt(value);
                    if (number < min || number > max) {
                        controller.$setValidity('invalidRange', false);
                        return null;
                    }
                    controller.$setValidity('invalidRange', true);
                    return value;
                }
                controller.$setValidity('pattern', false);
                if (typeof value !== 'number') {
                    controller.$setValidity('invalidRange', true);
                }
                return null;
            });
        }
        
    }
})();
